import {
  InfiniteData,
  QueryKey,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { ErrorType, orvalAPI } from '@/services/axios';

import { InfiniteQueryFn, InfiniteQueryFnReturn, SecondParameter } from './createQuery.types';

type DataConstraint = {
  num_objects?: number;
  num_pages?: number;
  page?: number;
  size?: number;
};

export type QueryError = ErrorType<{ message?: string; error?: string }>;

type QueryOptions<TData extends DataConstraint> = UndefinedInitialDataInfiniteOptions<
  { page: number; data: TData },
  QueryError,
  InfiniteData<{ page: number; data: TData }>,
  QueryKey,
  { page: number }
>;

export const createInfiniteQuery = <TFnData extends DataConstraint, TParams>({
  key,
  queryFn,
}: {
  key: QueryKey;
  queryFn: InfiniteQueryFn<TFnData, TParams>;
}) => {
  /**
   * Returns queryKey
   */
  function getKey(params: TParams): QueryKey {
    return [...key, params];
  }

  /**
   * Returns queryOptions
   */
  function getOptions(
    params: TParams,
    queryOptions?: Partial<Omit<QueryOptions<TFnData>, 'queryKey' | 'queryFn'>>,
    apiOptions?: SecondParameter<typeof orvalAPI>
  ): QueryOptions<TFnData> {
    const options: QueryOptions<TFnData> = {
      initialPageParam: { page: 1 },
      queryKey: getKey(params),
      queryFn: async ({ pageParam: { page }, signal }) => {
        const data = await queryFn({ ...params, page }, apiOptions, signal);

        return {
          page,
          data,
        };
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.page + 1 > (lastPage.data.num_pages ?? 1)) return undefined;

        return {
          page: lastPage.page + 1,
        };
      },
      // ...defaultOptions,
      ...queryOptions,
    };

    return options;
  }

  /**
   * Returns useQuery hook
   */
  function useQueryHook(
    params: TParams,
    queryOptions?: Partial<
      Omit<
        UndefinedInitialDataInfiniteOptions<
          InfiniteQueryFnReturn<TFnData>,
          QueryError,
          InfiniteData<InfiniteQueryFnReturn<TFnData>>,
          QueryKey,
          { page: number }
        >,
        'queryKey' | 'queryFn'
      >
    >,
    apiOptions?: SecondParameter<typeof orvalAPI>
  ) {
    return useInfiniteQuery(getOptions(params, queryOptions, apiOptions));
  }

  /**
   * Bind functions to hook
   */
  useQueryHook.key = key;
  useQueryHook.getKey = getKey;
  useQueryHook.getOptions = getOptions;

  /**
   * Typecast hook
   */
  return useQueryHook as typeof useQueryHook & { getKey: typeof getKey } & {
    getOptions: typeof getOptions;
  } & { key: QueryKey };
};
