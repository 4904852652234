import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" ref={svgRef} {...props}><path d="M36.4 360.9l-23 78.1L1 481.2c-2.5 8.5-.2 17.6 6 23.8s15.3 8.5 23.7 6.1L73 498.6l78.1-23c10.4-3 20.1-8 28.6-14.5l.3.2.5-.8c1.4-1.1 2.7-2.2 4-3.3 1.4-1.2 2.7-2.5 4-3.8l304.2-304.1c21.9-21.9 24.6-55.6 8.2-80.5-2.3-3.5-5.1-6.9-8.2-10l-39.4-39.5c-25-25-65.5-25-90.5 0L58.6 323.5c-2.5 2.5-4.9 5.2-7.1 8l-.8.5.2.3c-6.5 8.5-11.4 18.2-14.5 28.6zM383 191L197.4 376.6l-49.6-12.4-12.4-49.6L321 129l62 62zM97 358.9l7.7 31c2.1 8.6 8.9 15.3 17.5 17.5l31 7.7-7.4 11.2c-2.6 1.4-5.3 2.6-8.1 3.4l-23.4 6.9-54.9 16 16.1-54.8 6.9-23.4c.8-2.8 2-5.6 3.4-8.1l11.2-7.4zm218.3-140.2c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96z" /></svg>;
});
SVGIcon.displayName = "RegularPencil";
export default SVGIcon;