import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDSubparagraafSchema } from '@/api';
import { StdParagraaf, useCreateStdSubparagraaf } from '@/api/queries/std';
import { FormLabel, Modal, TextEditor, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';
import { getRangBetween } from '@/utils/getRangBetween';

export interface StandaardtekstenToevoegenSubparagraafProps {
  paragraafId: string;
  subparagraafIndex: number;
  subparagrafen: StdParagraaf['subparagrafen'];
  onSubmitSuccess?: () => void;
}

type FormValues = Pick<STDSubparagraafSchema, 'Titel' | 'Toelichting'>;

const unwrapValue = (value: string) => {
  const element = document.createElement('div');

  element.innerHTML = value;

  const inner = element.querySelector('p');

  return inner?.innerHTML;
};

export const STANDAARDTEKSTEN_TOEVOEGEN_SUBPARAGRAAF_ID = 'standaardtekstenToevoegenSubparagraaf';

export const StandaardtekstenToevoegenSubparagraaf = modal(
  STANDAARDTEKSTEN_TOEVOEGEN_SUBPARAGRAAF_ID,
  ({ data, props }) => {
    const modalState = useModalState();
    const { paragraafId, subparagraafIndex, subparagrafen, onSubmitSuccess } = data;

    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>();

    const createStdSubparagraaf = useCreateStdSubparagraaf();

    const onSubmit: SubmitHandler<FormValues> = ({ Titel, Toelichting }) => {
      modalState.action('save');

      const previous =
        subparagraafIndex || subparagraafIndex === 0
          ? subparagrafen[subparagraafIndex]?.data.Rang
          : null;

      const next =
        subparagraafIndex || subparagraafIndex === 0
          ? subparagrafen[subparagraafIndex + 1]?.data.Rang
          : subparagrafen[0]?.data.Rang;

      const newRang = getRangBetween(previous, next);

      const unwrappedValue = unwrapValue(Titel);

      createStdSubparagraaf.mutate(
        {
          Paragraaf: paragraafId,
          Titel: unwrappedValue!,
          Toelichting,
          Transparent: 0,
          Rang: newRang,
        },
        {
          onSuccess: () => {
            modalState.success('save');
            onSubmitSuccess?.();
          },
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Subparagraaf toevoegen">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel htmlFor="nieuwe-std-subparagraaf-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'subscript',
                  'superscript',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                ]}
                initialText={Toelichting}
                id="nieuwe-std-subparagraaf-toelichting"
                testId="new-object-std-subparagraaf-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />

          <FormLabel htmlFor="nieuwe-std-subparagraaf-title" required>
            Titel (Titel van subparagraaf)
          </FormLabel>
          <Controller
            control={control}
            name="Titel"
            rules={{ required: { value: true, message: eStrings.TXT_VERPLICHT_INPUT_VELD } }}
            render={({ field: { onChange, value: Titel } }) => (
              <TextEditor
                menuOptions={['superscript', 'subscript', 'highlight']}
                error={errors.Titel}
                initialText={Titel}
                id="nieuwe-std-subparagraaf-title"
                testId="new-object-std-subparagraaf-title"
                variant="input"
                singleLine
                onUpdate={onChange}
              />
            )}
          />
          <Modal.Footer>
            <Modal.CancelButton />

            <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
              Opslaan
            </Modal.ActionButton>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
