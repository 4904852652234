import {
  postFixLineageVoorschrift,
  postFixLinkVspb,
  postFixVoorschrift,
} from '@/api/generated/digiVAPI';
import {
  VSPBSchema,
  VoorschriftLineageSchema,
  VoorschriftSchema,
} from '@/api/generated/digiVAPI.schemas';
import { getRangBetween } from '@/utils/getRangBetween';

import { CreateVoorschriftenProps } from '../useCreateObjects';

export type CreateVoorschriftResponse = {
  voorschrift: VoorschriftSchema;
  lineageVoorschrift: VoorschriftLineageSchema;
  vspb: VSPBSchema;
};

export async function createVoorschriften({
  voorschriften,
  besluitId,
  besluitLineageId,
  milieuGrondslagId,
  subparagraafId,
  previousRang,
  nextRang,
}: CreateVoorschriftenProps & { milieuGrondslagId: string; besluitLineageId: string }) {
  const voorschriftResponses: CreateVoorschriftResponse[] = [];

  let previousVoorschriftResponse: CreateVoorschriftResponse | undefined;

  for (const voorschriftProps of voorschriften) {
    const voorschriftResponse = await createVoorschrift({
      besluitId,
      besluitLineageId,
      milieuGrondslagId,
      subparagraafId,
      content: voorschriftProps.voorschrift,
      previousRang: previousVoorschriftResponse?.vspb.Rang || previousRang,
      nextRang: nextRang,
    });

    voorschriftResponses.push(voorschriftResponse);

    previousVoorschriftResponse = voorschriftResponse;
  }

  return {
    voorschriftResponses,
  };
}

export const createVoorschrift = async ({
  subparagraafId,
  content = '',
  besluitId,
  besluitLineageId,
  previousRang,
  nextRang,
  milieuGrondslagId,
}: {
  milieuGrondslagId: string;
  subparagraafId: string;
  content?: string;
  besluitId: string;
  besluitLineageId: string;
  previousRang?: string;
  nextRang?: string;
}): Promise<CreateVoorschriftResponse> => {
  /**
   * Create lineage paragraaf
   */
  const lineageVoorschrift = await postFixLineageVoorschrift({
    Besluit_lineage: besluitLineageId,
  });

  if (!lineageVoorschrift.ID) {
    throw new Error('No lineage paragraaf ID');
  }

  /**
   * Create transparent voorschrift
   */
  const voorschrift = await postFixVoorschrift({
    Grondslag: milieuGrondslagId,
    Lineage: lineageVoorschrift.ID,
    Nummer: '',
    Ontwerp: 1,
    Stop_Lineage: 0,
    Voorschrift: content,
    Index: 0,
  });

  if (!voorschrift.ID) {
    throw new Error('No voorschrift ID');
  }

  /**
   * Create VSPB
   */
  const newRang = getRangBetween(previousRang, nextRang);

  const vspb = await postFixLinkVspb({
    Besluit: besluitId,
    Subparagraaf: subparagraafId,
    Voorschrift: voorschrift.ID,
    Rang: newRang,
    Type: 'Eigen',
  });

  return {
    voorschrift,
    lineageVoorschrift,
    vspb,
  };
};
