import { useCallback, useEffect, useRef, useState } from 'react';

// To prevent typos, we allow a few specific values for the action.
// If there are more then 3 needed, we can add more here.
export type ModalStateActionOptions = 'save' | 'delete' | 'other';

const DEFAULT_WARNING = {
  title: 'Mislukt!',
  description: 'Er is een fout opgetreden. Probeer het later nog eens.',
};

type ModalStateSuccess = {
  type: 'success';
  action: ModalStateActionOptions;
  onClose?: () => void;
};

type ModalStateError = {
  type: 'error';
  action: ModalStateActionOptions;
  warning?: {
    title: string;
    description: string;
  };
};

type ModalStateAction = {
  type: 'action';
  action: ModalStateActionOptions;
};

type ModalStateActionOvertime = {
  type: 'action-overtime';
  action: ModalStateActionOptions;
};

export type ModalState =
  | ModalStateSuccess
  | ModalStateError
  | ModalStateAction
  | ModalStateActionOvertime;

/**
 * This hook is used to generate a state for the Modal component.
 */
export const useModalState = () => {
  const [state, setState] = useState<ModalState | undefined>();

  const successTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const overtimeTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  const setError = (
    action: ModalStateActionOptions,
    warning: {
      title: string;
      description: string;
    } = DEFAULT_WARNING
  ) => {
    setState({ type: 'error', action, warning });

    clearOvertimeTimeout();
  };

  const setSuccess = (
    action: ModalStateActionOptions,
    /** onClose overwrites the default onClose function after the success timeout finished */
    onClose?: () => void
  ) => {
    setState({ type: 'success', action, onClose });

    clearOvertimeTimeout();

    // Make sure the state resets after 3 seconds (Modal closes 2 seconds after success)
    // Could be cleaner
    successTimeoutRef.current = setTimeout(() => {
      setState(undefined);
    }, 3000);
  };

  const setAction = (action: ModalStateActionOptions) => {
    setState({ type: 'action', action });

    overtimeTimeoutRef.current = setTimeout(() => {
      setState({ type: 'action-overtime', action });
    }, 5000);
  };

  const clearSuccessTimeout = useCallback(() => clearTimeout(successTimeoutRef.current), []);
  const clearOvertimeTimeout = useCallback(() => clearTimeout(overtimeTimeoutRef.current), []);

  useEffect(() => {
    return () => {
      clearSuccessTimeout();
      clearOvertimeTimeout();
    };
  }, [clearSuccessTimeout, clearOvertimeTimeout]);

  return {
    error: setError,
    success: setSuccess,
    action: setAction,
    state,
  };
};
