import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LexoRank } from 'lexorank';

import { postDynStdParagraaf, postDynStdSubparagraaf } from '@/api/generated/digiVAPI';
import { useUserStore } from '@/stores/user';
import { getRangBetween } from '@/utils/getRangBetween';

import { useStdObjects } from '../stdObjects';
import { StdObjects } from '../utils/getStdObjects';

export const useCreateStdParagraaf = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      Titel,
      Nummer,
      Toelichting,
      paragraafIndex,
      stdObjects,
      sectieId,
    }: {
      Titel: string;
      Nummer: string;
      Toelichting?: string | null;
      paragraafIndex: number | null;
      stdObjects: StdObjects;
      sectieId: string;
    }) => {
      const paragrafen = stdObjects.paragrafen;

      const userId = useUserStore.getState().user?.ID ?? '';

      if (!paragrafen) throw new Error('No paragrafen');

      const previous =
        paragraafIndex || paragraafIndex === 0 ? paragrafen[paragraafIndex]?.data.Rang : null;

      const next =
        paragraafIndex || paragraafIndex === 0
          ? paragrafen[paragraafIndex + 1]?.data.Rang
          : paragrafen[0]?.data.Rang;

      const newRang = getRangBetween(previous, next);

      const newStdParagraaf = await postDynStdParagraaf({
        Map: sectieId,
        Titel,
        Nummer,
        Toelichting,
        Rang: newRang,
        Created_By: userId,
        Created_Date: new Date().toISOString(),
        Modified_By: userId,
        Modified_Date: new Date().toISOString(),
        Landelijk: 0,
      });

      if (newStdParagraaf.ID) {
        await postDynStdSubparagraaf({
          Paragraaf: newStdParagraaf.ID,
          Titel: '',
          Transparent: 1,
          Rang: LexoRank.min().toString(),
          Created_By: userId,
          Created_Date: new Date().toISOString(),
          Modified_By: userId,
          Modified_Date: new Date().toISOString(),
          Landelijk: 0,
        });
      }

      return newStdParagraaf;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: useStdObjects.key });
    },
  });
};
