import { useQuery } from '@tanstack/react-query';

import { useBesluit } from '../besluit';

type LastVigerendBesluitParams = {
  bedrijfLineageId?: string;
};

const getOptions = ({ bedrijfLineageId }: LastVigerendBesluitParams = {}) => {
  return useBesluit.getOptions(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Besluit',
          field: 'Status',
          op: 'eq',
          value: 'Publicatie',
        },
        {
          model: 'Bedrijf',
          field: 'Lineage',
          op: 'eq',
          value: bedrijfLineageId ?? '',
        },
        {
          model: 'Besluit',
          field: 'Volgend',
          op: 'is_null',
          value: '',
        },
      ],
    },
    {
      enabled: !!bedrijfLineageId,
      select: ({ objects }) => objects?.[0],
    }
  );
};

const useLastVigerendBesluit = (params: LastVigerendBesluitParams) => useQuery(getOptions(params));
useLastVigerendBesluit.getOptions = getOptions;

export { useLastVigerendBesluit };
