import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';

import { ParagraafSchema } from '@/api';
import { getFixLinkPhb, getFixParagraaf } from '@/api/generated/digiVAPI';
import { useHoofdstuk } from '@/api/queries/objects';
import { createInString } from '@/utils/createInString';
import { fetchAllPages } from '@/utils/fetchAllPages';

import { TextButton } from '../../TextButton';
import { ValidateBesluitProps } from '../ValidateBesluit.types';
import { ValidateAccordionSkeleton } from './ValidateAccordionSkeleton';
import { ValidateRow } from './ValidateRow';

/**
 * Check if there are duplicate paragraafnummers
 */
export const useValidateDuplicateParagraafNummer = (besluitId: string) => {
  return useQuery({
    queryFn: async () => {
      const paragafen = await fetchAllPages((page) =>
        getFixParagraaf({
          size: 100,
          page,
          filter: [
            {
              model: 'Besluit',
              field: 'ID',
              op: 'eq',
              value: besluitId,
            },
            {
              model: 'PHB',
              field: 'Type',
              op: 'eq',
              value: 'Eigen',
            },
          ],
        })
      );

      const phbs = await fetchAllPages((page) =>
        getFixLinkPhb({
          size: 100,
          page,
          filter: [
            {
              model: 'Besluit',
              field: 'ID',
              op: 'eq',
              value: besluitId,
            },
            {
              model: 'PHB',
              field: 'Type',
              op: 'eq',
              value: 'Eigen',
            },
          ],
        })
      );

      /**
       * Group paragrafen by hoofdstuk
       */
      const hoofdstukken = phbs.reduce<{ [hoofdstukId: string]: ParagraafSchema[] }>((acc, phb) => {
        const paragraaf = paragafen.find(({ ID }) => ID === phb.Paragraaf);

        if (!paragraaf) return acc;

        if (!acc[phb.Hoofdstuk]) {
          acc[phb.Hoofdstuk] = [paragraaf];
        } else {
          acc[phb.Hoofdstuk]!.push(paragraaf);
        }

        return acc;
      }, {});

      /**
       * Filter all paragrafen that have the same nummer
       */
      for (const hoofdstukId in hoofdstukken) {
        const paragrafen = hoofdstukken[hoofdstukId]!;

        hoofdstukken[hoofdstukId] = paragrafen.filter((paragraaf, _, self) =>
          self.find((p) => p.Nummer === paragraaf.Nummer && p !== paragraaf)
        );

        if (hoofdstukken[hoofdstukId]?.length === 0) {
          delete hoofdstukken[hoofdstukId];
        }
      }

      return {
        isValid: Object.values(hoofdstukken).every(({ length }) => length === 0),
        hoofdstukken,
      };
    },
    queryKey: ['duplicateParagrafen'],
    gcTime: 0,
    staleTime: 0,
  });
};

export const ValidateDuplicateParagraafNummer = ({ validate }: ValidateBesluitProps) => {
  const { isFetching, data } = validate.queries.validateDuplicateParagraafNummers;

  if (isFetching || !data) {
    return <ValidateRow isFetching title="Dubbele paragrafen" />;
  }

  if (!data.isValid) {
    return (
      <ValidateRow
        isValid={false}
        title="Dubbele paragraafnummers"
        accordionBody={
          <ErrorBody
            hoofdstukken={data.hoofdstukken}
            besluitId={validate.besluitId}
            bedrijfLineageId={validate.bedrijfLineageId}
          />
        }
      />
    );
  }

  return <ValidateRow isValid title="Geen dubbele paragrafen" />;
};

const ErrorBody = ({
  besluitId,
  bedrijfLineageId,
  hoofdstukken,
}: {
  besluitId: string;
  bedrijfLineageId: string;
  hoofdstukken: {
    [hoofdstukId: string]: ParagraafSchema[];
  };
}) => {
  const hoofdstukResponses = useHoofdstuk({
    page: 1,
    size: 100,
    filter: [
      {
        model: 'Hoofdstuk',
        field: 'ID',
        op: 'in',
        value: createInString(Object.keys(hoofdstukken)),
      },
    ],
  });

  if (hoofdstukResponses.isLoading) return <ValidateAccordionSkeleton />;

  return (
    <ul className="list-inside list-disc">
      {hoofdstukResponses.data?.objects?.map((hoofdstuk) => {
        const hoofdstukId = hoofdstuk.ID ?? '';
        const paragrafen = hoofdstukken[hoofdstukId];

        if (!paragrafen) return null;

        const uniqueNumbers = [...new Set(paragrafen.map((p) => p.Nummer))].map(
          (nummer) => `${hoofdstuk.Nummer}.${nummer}`
        );

        return (
          <li key={hoofdstukId}>
            <TextButton size="small" asChild>
              <Link
                to="/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId"
                params={{ bedrijfLineageId, besluitId, hoofdstukId }}
              >
                Hoofdstuk {hoofdstuk.Nummer} bevat dubbele paragraafnummers, namelijk:{' '}
                {new Intl.ListFormat('nl').format(uniqueNumbers)}
              </Link>
            </TextButton>
          </li>
        );
      })}
    </ul>
  );
};
