import {
  getFixLineageSubparagraaf,
  getFixLineageSubparagraafId,
  getFixSubparagraaf,
  getFixSubparagraafId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useSubparagraaf = createQuery({
  key: ['subparagraaf'],
  queryFn: getFixSubparagraaf,
});

export const useSubparagraafId = createQuery({
  key: ['subparagraafId'],
  queryFn: getFixSubparagraafId,
});

export const useLineageSubparagraaf = createQuery({
  key: ['lineageSubparagraaf'],
  queryFn: getFixLineageSubparagraaf,
});

export const useLineageSubparagraafId = createQuery({
  key: ['lineageSubparagraafId'],
  queryFn: getFixLineageSubparagraafId,
});
