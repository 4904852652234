import { useState } from 'react';

import {
  LinkSubparagraaf,
  LinkVoorschrift,
  useCreateObjects,
  useSubparagraafId,
} from '@/api/queries/objects';
import { EditorAddCompact } from '@/components/shared/Editor/EditorAddCompact';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { RegularAlignLeft } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ErrorType } from '@/services/axios';
import { ErrorMessage } from '@/utils/errorMessage';

import { useEditorStore } from '../../-store/useEditorStore';

interface VoorschriftAddBarProps {
  previous?: LinkVoorschrift;
  next?: LinkVoorschrift;
  subparagraafLink: LinkSubparagraaf;
  userHasReservering: boolean;
  paragraafIndex: number;
  subparagraafIndex: number;
  voorschriftIndex: number;
}

export const VoorschriftAddBar = ({
  previous,
  next,
  subparagraafLink,
  userHasReservering,
  paragraafIndex,
  subparagraafIndex,
  voorschriftIndex,
}: VoorschriftAddBarProps) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const subparagraaf = useSubparagraafId(subparagraafLink.SPPB.Subparagraaf);
  const createVoorschrift = useCreateObjects();
  const openModal = useOpenModal();

  const areAddBarsShown = useEditorStore((state) => state.areAddBarsShown);

  if (!areAddBarsShown || !userHasReservering) {
    return null;
  }

  /**
   * Callbacks for when the create mutation fails or succeeds
   */
  const handleError = (error: ErrorType<string>) => {
    setError(ErrorMessage.getLocalErrorMessage(error));
  };

  const handleSuccess = () => {
    setError(undefined);
  };

  /**
   * Validate if a paragraaf can be added
   */
  if (subparagraaf.isLoading || !subparagraaf.data) return null;

  // When the subparagraaf is ingetrokken, do not show the add bar
  if (subparagraaf.data.Stop_Lineage) return null;

  // When the subparagraaf is new and created in another besluit, do not show the add bar
  if (
    !subparagraaf.data.Vorig &&
    subparagraaf.data.Ontwerp &&
    subparagraafLink.SPPB.Type === 'MBIO'
  )
    return null;

  /**
   * If all validations pass, show the add bar
   */
  return (
    <>
      <EditorAddCompact
        testId={`add-bar-${paragraafIndex}-${subparagraafIndex}-${voorschriftIndex}`}
      >
        <EditorAddCompact.Button
          icon={RegularAlignLeft}
          isLoading={createVoorschrift.isPending}
          data-testid="add-item-button-lege-voorschrift"
          onClick={() => {
            createVoorschrift.mutate(
              {
                type: 'voorschriften',
                besluitId: subparagraafLink.SPPB.Besluit,
                subparagraafId: subparagraafLink.SPPB.Subparagraaf,
                previousRang: previous?.VSPB.Rang,
                nextRang: next?.VSPB.Rang,
                voorschriften: [{ voorschrift: '' }],
              },
              {
                onSuccess: handleSuccess,
                onError: handleError,
              }
            );
          }}
        >
          Leeg voorschrift
        </EditorAddCompact.Button>

        <EditorAddCompact.Button
          icon={RegularAlignLeft}
          data-testid="add-item-button-standaardtekst-voorschrift"
          onClick={() => {
            openModal('editorStdObjects', {
              allow: 'voorschrift',
              besluitId: subparagraafLink.SPPB.Besluit,
              subparagraafId: subparagraafLink.SPPB.Subparagraaf,
              nextRang: next?.VSPB.Rang,
              previousRang: previous?.VSPB.Rang,
            });
          }}
        >
          Standaardtekst voorschrift
        </EditorAddCompact.Button>
      </EditorAddCompact>

      {error && (
        <div className="w-full">
          <WarningInline description={error} center />
        </div>
      )}
    </>
  );
};
