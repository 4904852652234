import {
  BEDRIJFS_INFORMATIE_ID,
  BESLUIT_EIGENSCHAPPEN_ID,
  BESLUIT_EXPORTEREN_ID,
  BESLUIT_PUBLICEREN_ID,
  BESLUIT_VERWIJDEREN_ID,
  BedrijfsInformatie,
  BedrijfsInformatieProps,
  BesluitEigenschappen,
  BesluitEigenschappenProps,
  BesluitPubliceren,
  BesluitPublicerenProps,
  BesluitVerwijderen,
  BesluitVerwijderenProps,
  WIJZIGING_ID,
  Wijziging,
  WijzigingProps,
} from '@/modals/Bedrijf';

import { BesluitExporteren, BesluitExporterenProps } from './Bedrijf/BesluitExporteren';
import {
  HISTORISCH_BESLUIT_EIGENSCHAPPEN_ID,
  HistorischBesluitEigenschappen,
  HistorischBesluitEigenschappenProps,
} from './Bedrijf/HistorischBesluitEigenschappen';
import {
  BEHEER_ACTIVITEIT_ID,
  BeheerActiviteit,
  BeheerActiviteitProps,
} from './Beheer/Activiteit/Activiteit';
import {
  BEHEER_ACTIVITEIT_STATUS_ID,
  BeheerActiviteitStatus,
  BeheerActiviteitStatusProps,
} from './Beheer/Activiteit/ActiviteitStatus';
import {
  BEHEER_INSTALLATIETYPE_ID,
  BeheerInstallatietype,
  BeheerInstallatietypeProps,
} from './Beheer/Installatietype/Installatietype';
import {
  BEHEER_INSTALLATIETYPE_STATUS_ID,
  BeheerInstallatietypeStatus,
  BeheerInstallatietypeStatusProps,
} from './Beheer/Installatietype/InstallatietypeStatus';
import { BEHEER_LABEL_ID, BeheerLabel, BeheerLabelProps } from './Beheer/Label/Label';
import { BEHEER_LABELS_MAP_ID, BeheerLabelMap, BeheerLabelMapProps } from './Beheer/Label/LabelMap';
import {
  BEHEER_LABEL_MAP_DELETE_ID,
  BeheerLabelMapDelete,
  BeheerLabelMapDeleteProps,
} from './Beheer/Label/LabelMapDelete';
import {
  BEHEER_LABEL_STATUS_ID,
  BeheerLabelStatus,
  BeheerLabelStatusProps,
} from './Beheer/Label/LabelStatus';
import {
  STANDAARDTEKSTEN_DEACTIVEREN_MAP_ID,
  StandaardtekstenDeactiverenMap,
  StandaardtekstenDeactiverenMapProps,
} from './Beheer/Standaardteksten/StdDeactiverenMap';
import {
  STANDAARDTEKSTEN_DEACTIVEREN_SECTIE_ID,
  StandaardtekstenDeactiverenSectie,
  StandaardtekstenDeactiverenSectieProps,
} from './Beheer/Standaardteksten/StdDeactiverenSectie';
import {
  STANDAARDTEKSTEN_EDIT_MAP_ID,
  StandaardtekstenEditMap,
  StandaardtekstenEditMapProps,
} from './Beheer/Standaardteksten/StdEditMap';
import {
  STANDAARDTEKSTEN_EDIT_SECTIE_ID,
  StandaardtekstenEditSectie,
  StandaardtekstenEditSectieProps,
} from './Beheer/Standaardteksten/StdEditSectie';
import {
  STANDAARDTEKSTEN_EDIT_PARAGRAAF_ID,
  StandaardtekstenEditParagraaf,
  StandaardtekstenEditParagraafProps,
} from './Beheer/Standaardteksten/StdParagraaf/StdEditParagraaf';
import {
  STANDAARDTEKSTEN_TOEVOEGEN_PARAGRAAF_ID,
  StandaardtekstenToevoegenParagraaf,
  StandaardtekstenToevoegenParagraafProps,
} from './Beheer/Standaardteksten/StdParagraaf/StdToevoegenParagraaf';
import {
  STANDAARDTEKSTEN_VERWIJDEREN_PARAGRAAF_ID,
  StandaardtekstenVerwijderenParagraaf,
  StandaardtekstenVerwijderenParagraafProps,
} from './Beheer/Standaardteksten/StdParagraaf/StdVerwijderenParagraaf';
import {
  STANDAARDTEKSTEN_EDIT_SUBPARAGRAAF_ID,
  StandaardtekstenEditSubparagraaf,
  StandaardtekstenEditSubparagraafProps,
} from './Beheer/Standaardteksten/StdSubparagraaf/StdEditSubparagraaf';
import {
  STANDAARDTEKSTEN_TOEVOEGEN_SUBPARAGRAAF_ID,
  StandaardtekstenToevoegenSubparagraaf,
  StandaardtekstenToevoegenSubparagraafProps,
} from './Beheer/Standaardteksten/StdSubparagraaf/StdToevoegenSubparagraaf';
import {
  STANDAARDTEKSTEN_VERWIJDEREN_SUBPARAGRAAF_ID,
  StandaardtekstenVerwijderenSubparagraaf,
  StandaardtekstenVerwijderenSubparagraafProps,
} from './Beheer/Standaardteksten/StdSubparagraaf/StdVerwijderenSubparagraaf';
import {
  STANDAARDTEKSTEN_TOEVOEGEN_MAP_ID,
  StandaardtekstenToevoegenMap,
  StandaardtekstenToevoegenMapProps,
} from './Beheer/Standaardteksten/StdToevoegenMap';
import {
  STANDAARDTEKSTEN_TOEVOEGEN_SECTIE_ID,
  StandaardtekstenToevoegenSectie,
  StandaardtekstenToevoegenSectieProps,
} from './Beheer/Standaardteksten/StdToevoegenSectie';
import {
  STANDAARDTEKSTEN_VERWIJDEREN_MAP_ID,
  StandaardtekstenVerwijderenMap,
  StandaardtekstenVerwijderenMapProps,
} from './Beheer/Standaardteksten/StdVerwijderenMap';
import {
  STANDAARDTEKSTEN_VERWIJDEREN_SECTIE_ID,
  StandaardtekstenVerwijderenSectie,
  StandaardtekstenVerwijderenSectieProps,
} from './Beheer/Standaardteksten/StdVerwijderenSectie';
import {
  STANDAARDTEKSTEN_EDIT_VOORSCHRIFT_ID,
  StandaardtekstenEditVoorschrift,
  StandaardtekstenEditVoorschriftProps,
} from './Beheer/Standaardteksten/StdVoorschrift/StdEditVoorschrift';
import {
  STANDAARDTEKSTEN_TOEVOEGEN_VOORSCHRIFT_ID,
  StandaardtekstenToevoegenVoorschrift,
  StandaardtekstenToevoegenVoorschriftProps,
} from './Beheer/Standaardteksten/StdVoorschrift/StdToevoegenVoorschrift';
import {
  STANDAARDTEKSTEN_VERWIJDEREN_VOORSCHRIFT_ID,
  StandaardtekstenVerwijderenVoorschrift,
  StandaardtekstenVerwijderenVoorschriftProps,
} from './Beheer/Standaardteksten/StdVoorschrift/StdVerwijderenVoorschrift';
import { EDITOR_ADD_IMAGE_ID, EditorAddImage, EditorAddImageProps } from './Editor/AddImage';
import {
  DELETE_HOOFDSTUK_ID,
  DeleteHoofdstuk,
  DeleteHoofdstukProps,
} from './Editor/DeleteHoofdstuk';
import {
  DELETE_PARAGRAAF_ID,
  DeleteParagraaf,
  DeleteParagraafProps,
} from './Editor/DeleteParagraaf';
import {
  DELETE_SUBPARAGRAAF_ID,
  DeleteSubparagraaf,
  DeleteSubparagraafProps,
} from './Editor/DeleteSubparagraaf';
import {
  DELETE_VOORSCHRIFT_ID,
  DeleteVoorschrift,
  DeleteVoorschriftProps,
} from './Editor/DeleteVoorschrift';
import {
  INTREKKEN_HOOFDSTUK_ID,
  IntrekkenHoofdstuk,
  IntrekkenHoofdstukProps,
} from './Editor/IntrekkenHoofdstuk';
import {
  INTREKKEN_PARAGRAAF_ID,
  IntrekkenParagraaf,
  IntrekkenParagraafProps,
} from './Editor/IntrekkenParagraaf';
import {
  INTREKKEN_SUBPARAGRAAF_ID,
  IntrekkenSubparagraaf,
  IntrekkenSubparagraafProps,
} from './Editor/IntrekkenSubparagraaf';
import {
  INTREKKEN_VOORSCHRIFT_ID,
  IntrekkenVoorschrift,
  IntrekkenVoorschriftProps,
} from './Editor/IntrekkenVoorschrift';
import { LINK_LABELS_ID, LinkLabels, LinkLabelsProps } from './Editor/LinkLabels';
import {
  STD_INHOUDELIJKE_OVERWEGINGEN,
  StdInhoudelijkeOverwegingen,
} from './Editor/StdInhoudelijkeOverwegingen';
import { STD_MAPPEN_ID, StdMappen, StdMappenProps } from './Editor/StdMappen';
import { STD_OBJECTS_ID, StdObjects, StdObjectsProps } from './Editor/StdObjects';
import {
  UNDO_INTREKKEN_HOOFDSTUK_ID,
  UndoIntrekkenHoofdstuk,
  UndoIntrekkenHoofdstukProps,
} from './Editor/UndoIntrekkenHoofdstuk';
import {
  UNDO_INTREKKEN_PARAGRAAF_ID,
  UndoIntrekkenParagraaf,
  UndoIntrekkenParagraafProps,
} from './Editor/UndoIntrekkenParagraaf';
import {
  UNDO_INTREKKEN_SUBPARAGRAAF_ID,
  UndoIntrekkenSubparagraaf,
  UndoIntrekkenSubparagraafProps,
} from './Editor/UndoIntrekkenSubparagraaf';
import {
  UNDO_INTREKKEN_VOORSCHRIFT_ID,
  UndoIntrekkenVoorschrift,
  UndoIntrekkenVoorschriftProps,
} from './Editor/UndoIntrekkenVoorschrift';
import {
  UNDO_VOORSCHRIFT_ID,
  UndoVoorschrift,
  UndoVoorschriftProps,
} from './Editor/UndoVoorschrift';
import {
  VOORSCHRIFT_GELDIGHEID_ID,
  VoorschriftGeldigheid,
  VoorschriftGeldigheidProps,
} from './Editor/VoorschriftGeldigheid';
import { SELECT_BESLUIT_ID, SelectBesluit, SelectBesluitProps } from './Viewer/SelectBesluit';
import { SAVE_FAILED, SaveFailed, saveFailedProps } from './shared/SaveFailed';

/**
 * Key of the modal config is the id of the modal.
 * The value is the props that are passed to the modal component.
 *
 * @export
 * @interface ModalProps
 */
export interface ModalProps {
  // Shared
  [SAVE_FAILED]: saveFailedProps;

  // Standaardteksten beheer
  [STANDAARDTEKSTEN_TOEVOEGEN_MAP_ID]: StandaardtekstenToevoegenMapProps;
  [STANDAARDTEKSTEN_EDIT_MAP_ID]: StandaardtekstenEditMapProps;
  [STANDAARDTEKSTEN_DEACTIVEREN_MAP_ID]: StandaardtekstenDeactiverenMapProps;
  [STANDAARDTEKSTEN_VERWIJDEREN_MAP_ID]: StandaardtekstenVerwijderenMapProps;
  [STANDAARDTEKSTEN_DEACTIVEREN_SECTIE_ID]: StandaardtekstenDeactiverenSectieProps;
  [STANDAARDTEKSTEN_EDIT_SECTIE_ID]: StandaardtekstenEditSectieProps;
  [STANDAARDTEKSTEN_TOEVOEGEN_SECTIE_ID]: StandaardtekstenToevoegenSectieProps;
  [STANDAARDTEKSTEN_VERWIJDEREN_SECTIE_ID]: StandaardtekstenVerwijderenSectieProps;
  [BEHEER_LABELS_MAP_ID]: BeheerLabelMapProps;
  [BEHEER_LABEL_ID]: BeheerLabelProps;
  [BEHEER_LABEL_STATUS_ID]: BeheerLabelStatusProps;
  [BEHEER_INSTALLATIETYPE_ID]: BeheerInstallatietypeProps;
  [BEHEER_INSTALLATIETYPE_STATUS_ID]: BeheerInstallatietypeStatusProps;
  [BEHEER_ACTIVITEIT_ID]: BeheerActiviteitProps;
  [BEHEER_ACTIVITEIT_STATUS_ID]: BeheerActiviteitStatusProps;
  [BEHEER_LABEL_MAP_DELETE_ID]: BeheerLabelMapDeleteProps;
  [STANDAARDTEKSTEN_TOEVOEGEN_PARAGRAAF_ID]: StandaardtekstenToevoegenParagraafProps;
  [STANDAARDTEKSTEN_EDIT_PARAGRAAF_ID]: StandaardtekstenEditParagraafProps;
  [STANDAARDTEKSTEN_VERWIJDEREN_PARAGRAAF_ID]: StandaardtekstenVerwijderenParagraafProps;
  [STANDAARDTEKSTEN_TOEVOEGEN_SUBPARAGRAAF_ID]: StandaardtekstenToevoegenSubparagraafProps;
  [STANDAARDTEKSTEN_EDIT_SUBPARAGRAAF_ID]: StandaardtekstenEditSubparagraafProps;
  [STANDAARDTEKSTEN_VERWIJDEREN_SUBPARAGRAAF_ID]: StandaardtekstenVerwijderenSubparagraafProps;
  [STANDAARDTEKSTEN_TOEVOEGEN_VOORSCHRIFT_ID]: StandaardtekstenToevoegenVoorschriftProps;
  [STANDAARDTEKSTEN_EDIT_VOORSCHRIFT_ID]: StandaardtekstenEditVoorschriftProps;
  [STANDAARDTEKSTEN_VERWIJDEREN_VOORSCHRIFT_ID]: StandaardtekstenVerwijderenVoorschriftProps;

  // Editor
  [DELETE_HOOFDSTUK_ID]: DeleteHoofdstukProps;
  [DELETE_PARAGRAAF_ID]: DeleteParagraafProps;
  [DELETE_SUBPARAGRAAF_ID]: DeleteSubparagraafProps;
  [DELETE_VOORSCHRIFT_ID]: DeleteVoorschriftProps;
  [STD_OBJECTS_ID]: StdObjectsProps;
  [STD_MAPPEN_ID]: StdMappenProps;
  [STD_INHOUDELIJKE_OVERWEGINGEN]: StdInhoudelijkeOverwegingen;
  [LINK_LABELS_ID]: LinkLabelsProps;
  [VOORSCHRIFT_GELDIGHEID_ID]: VoorschriftGeldigheidProps;
  [EDITOR_ADD_IMAGE_ID]: EditorAddImageProps;
  [INTREKKEN_HOOFDSTUK_ID]: IntrekkenHoofdstukProps;
  [INTREKKEN_PARAGRAAF_ID]: IntrekkenParagraafProps;
  [INTREKKEN_SUBPARAGRAAF_ID]: IntrekkenSubparagraafProps;
  [INTREKKEN_VOORSCHRIFT_ID]: IntrekkenVoorschriftProps;
  [UNDO_INTREKKEN_SUBPARAGRAAF_ID]: UndoIntrekkenSubparagraafProps;
  [UNDO_INTREKKEN_PARAGRAAF_ID]: UndoIntrekkenParagraafProps;
  [UNDO_INTREKKEN_HOOFDSTUK_ID]: UndoIntrekkenHoofdstukProps;
  [UNDO_VOORSCHRIFT_ID]: UndoVoorschriftProps;
  [UNDO_INTREKKEN_VOORSCHRIFT_ID]: UndoIntrekkenVoorschriftProps;

  // Bedrijf
  [BEDRIJFS_INFORMATIE_ID]: BedrijfsInformatieProps;
  [BESLUIT_EIGENSCHAPPEN_ID]: BesluitEigenschappenProps;
  [BESLUIT_EXPORTEREN_ID]: BesluitExporterenProps;
  [WIJZIGING_ID]: WijzigingProps;
  [BESLUIT_VERWIJDEREN_ID]: BesluitVerwijderenProps;
  [BESLUIT_PUBLICEREN_ID]: BesluitPublicerenProps;
  [HISTORISCH_BESLUIT_EIGENSCHAPPEN_ID]: HistorischBesluitEigenschappenProps;

  // Viewer
  [SELECT_BESLUIT_ID]: SelectBesluitProps;
}

export type ModalIds = keyof ModalProps;

export const MODAL_COMPONENTS: Record<
  ModalIds,
  (props: { uuid: string }) => React.JSX.Element | null
> = {
  // Shared
  [SAVE_FAILED]: SaveFailed,

  // Standaardteksten beheer
  [STANDAARDTEKSTEN_TOEVOEGEN_MAP_ID]: StandaardtekstenToevoegenMap,
  [STANDAARDTEKSTEN_EDIT_MAP_ID]: StandaardtekstenEditMap,
  [STANDAARDTEKSTEN_DEACTIVEREN_MAP_ID]: StandaardtekstenDeactiverenMap,
  [STANDAARDTEKSTEN_VERWIJDEREN_MAP_ID]: StandaardtekstenVerwijderenMap,
  [STANDAARDTEKSTEN_DEACTIVEREN_SECTIE_ID]: StandaardtekstenDeactiverenSectie,
  [STANDAARDTEKSTEN_EDIT_SECTIE_ID]: StandaardtekstenEditSectie,
  [STANDAARDTEKSTEN_TOEVOEGEN_SECTIE_ID]: StandaardtekstenToevoegenSectie,
  [STANDAARDTEKSTEN_VERWIJDEREN_SECTIE_ID]: StandaardtekstenVerwijderenSectie,
  [BEHEER_LABELS_MAP_ID]: BeheerLabelMap,
  [BEHEER_LABEL_ID]: BeheerLabel,
  [BEHEER_LABEL_STATUS_ID]: BeheerLabelStatus,
  [BEHEER_INSTALLATIETYPE_ID]: BeheerInstallatietype,
  [BEHEER_INSTALLATIETYPE_STATUS_ID]: BeheerInstallatietypeStatus,
  [BEHEER_ACTIVITEIT_ID]: BeheerActiviteit,
  [BEHEER_ACTIVITEIT_STATUS_ID]: BeheerActiviteitStatus,
  [BEHEER_LABEL_MAP_DELETE_ID]: BeheerLabelMapDelete,
  [STANDAARDTEKSTEN_TOEVOEGEN_PARAGRAAF_ID]: StandaardtekstenToevoegenParagraaf,
  [STANDAARDTEKSTEN_EDIT_PARAGRAAF_ID]: StandaardtekstenEditParagraaf,
  [STANDAARDTEKSTEN_VERWIJDEREN_PARAGRAAF_ID]: StandaardtekstenVerwijderenParagraaf,
  [STANDAARDTEKSTEN_TOEVOEGEN_SUBPARAGRAAF_ID]: StandaardtekstenToevoegenSubparagraaf,
  [STANDAARDTEKSTEN_EDIT_SUBPARAGRAAF_ID]: StandaardtekstenEditSubparagraaf,
  [STANDAARDTEKSTEN_VERWIJDEREN_SUBPARAGRAAF_ID]: StandaardtekstenVerwijderenSubparagraaf,
  [STANDAARDTEKSTEN_TOEVOEGEN_VOORSCHRIFT_ID]: StandaardtekstenToevoegenVoorschrift,
  [STANDAARDTEKSTEN_EDIT_VOORSCHRIFT_ID]: StandaardtekstenEditVoorschrift,
  [STANDAARDTEKSTEN_VERWIJDEREN_VOORSCHRIFT_ID]: StandaardtekstenVerwijderenVoorschrift,

  // Editor
  [DELETE_HOOFDSTUK_ID]: DeleteHoofdstuk,
  [DELETE_PARAGRAAF_ID]: DeleteParagraaf,
  [DELETE_SUBPARAGRAAF_ID]: DeleteSubparagraaf,
  [DELETE_VOORSCHRIFT_ID]: DeleteVoorschrift,
  [STD_OBJECTS_ID]: StdObjects,
  [STD_MAPPEN_ID]: StdMappen,
  [STD_INHOUDELIJKE_OVERWEGINGEN]: StdInhoudelijkeOverwegingen,
  [LINK_LABELS_ID]: LinkLabels,
  [VOORSCHRIFT_GELDIGHEID_ID]: VoorschriftGeldigheid,
  [EDITOR_ADD_IMAGE_ID]: EditorAddImage,
  [INTREKKEN_HOOFDSTUK_ID]: IntrekkenHoofdstuk,
  [INTREKKEN_PARAGRAAF_ID]: IntrekkenParagraaf,
  [INTREKKEN_SUBPARAGRAAF_ID]: IntrekkenSubparagraaf,
  [INTREKKEN_VOORSCHRIFT_ID]: IntrekkenVoorschrift,
  [UNDO_INTREKKEN_SUBPARAGRAAF_ID]: UndoIntrekkenSubparagraaf,
  [UNDO_INTREKKEN_PARAGRAAF_ID]: UndoIntrekkenParagraaf,
  [UNDO_INTREKKEN_HOOFDSTUK_ID]: UndoIntrekkenHoofdstuk,
  [UNDO_VOORSCHRIFT_ID]: UndoVoorschrift,
  [UNDO_INTREKKEN_VOORSCHRIFT_ID]: UndoIntrekkenVoorschrift,

  // Bedrijf
  [BEDRIJFS_INFORMATIE_ID]: BedrijfsInformatie,
  [BESLUIT_EIGENSCHAPPEN_ID]: BesluitEigenschappen,
  [BESLUIT_EXPORTEREN_ID]: BesluitExporteren,
  [WIJZIGING_ID]: Wijziging,
  [BESLUIT_VERWIJDEREN_ID]: BesluitVerwijderen,
  [BESLUIT_PUBLICEREN_ID]: BesluitPubliceren,
  [HISTORISCH_BESLUIT_EIGENSCHAPPEN_ID]: HistorischBesluitEigenschappen,

  // Viewer
  [SELECT_BESLUIT_ID]: SelectBesluit,
};
