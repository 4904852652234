import { useQueryClient } from '@tanstack/react-query';

import { useDeleteFixSubparagraafId } from '@/api/generated/digiVAPI';

import { useLinksInHoofdstuk } from '../links';
import { useSubparagraaf, useSubparagraafId } from '../subparagraaf';

export function useDeleteSubparagraafId() {
  const queryClient = useQueryClient();

  return useDeleteFixSubparagraafId({
    mutation: {
      onSuccess: async (data) => {
        /**
         * When deleting a subparagraaf, the link will also be deleted.
         * We'll need to invalidate those queries as well.
         */
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: useSubparagraaf.key }),
          queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key }),
        ]);

        if (data.ID) {
          queryClient.setQueryData(useSubparagraafId.getKey(data.ID), undefined);
        }
      },
    },
  });
}
