import { useDeleteVoorschriftId } from '@/api/queries/objects';
import { Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface UndoVoorschriftProps {
  nummer: string;
  id: string;
}

export const UNDO_VOORSCHRIFT_ID = 'undoVoorschrift';

export const UndoVoorschrift = modal(UNDO_VOORSCHRIFT_ID, ({ data, props }) => {
  const { nummer, id } = data;

  const deleteVoorschriftId = useDeleteVoorschriftId();

  const modalState = useModalState();

  const handleUndo = () => {
    modalState.action('save');

    deleteVoorschriftId.mutate(
      { id },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) =>
          modalState.error('save', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
          }),
      }
    );
  };

  return (
    <Modal state={modalState.state} title="Bewerking terugdraaien" {...props}>
      <p>
        Weet je zeker dat je voorschrift <strong>{nummer}</strong> wilt herstellen naar de originele
        (vigerende) waarde? Deze actie is niet terug te draaien.
      </p>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton action="save" onClick={handleUndo} successLabel="Teruggedraaid">
          Terugdraaien
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
