import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

import { ErrorType, orvalAPI } from '@/services/axios';

import { QueryFn, SecondParameter } from './createQuery.types';

export type QueryError = ErrorType<{ message?: string; error?: string }>;

/**
 * @example
 *  import { getFixBedrijf } from '@/api/generated';
 *
 *  const useBedrijf = createQuery({
 *    key: ['bedrijf'],
 *    queryFn: getFixBedrijf
 *  });
 *
 *  const useLatestBedrijf = createQuery({
 *   key: ['latestBedrijf'],
 *   queryFn: (id: string) => {
 *     return fetchBedrijfId(id)
 *   },
 *  })
 *
 *
 *  const Component = () => {
 *    const bedrijf = useBedrijf({ p: 1, size: 100 });
 *    const latestBedrijf = useLatestBedrijf('123');
 *
 *    // QueryOptions object
 *    const bedrijfQueryOptions = useBedrijf.getOptions({ p: 1, size: 100 });
 *    const latestBedrijfQueryOptions = useLatestBedrijf.getOptions('123');
 *
 *    // QueryKey for all 'bedrijf' / 'latestBedrijf' queries
 *    const bedrijfKeyAll = useBedrijf.getKey();
 *    const latestBedrijfKeyAll = useLatestBedrijf.getKey();
 *
 *    // QueryKey for specific 'bedrijf' / 'latestBedrijf' query
 *    const bedrijfKey = useBedrijf.getKey({ p: 1, size: 100 });
 *    const latestBedrijfKey = useLatestBedrijf.getKey('123');
 *  };
 */
export const createQuery = <TFnData, TParams>({
  key,
  queryFn,
  defaultOptions,
}: {
  key: QueryKey;
  queryFn: QueryFn<TFnData, TParams>;
  defaultOptions?: Pick<UseQueryOptions, 'structuralSharing'> & { staleTime?: number };
}) => {
  /**
   * Returns queryKey
   */
  function getKey(params: TParams): QueryKey {
    return [...key, params];
  }

  /**
   * Returns queryOptions
   */
  function getOptions<TData = TFnData>(
    params: TParams,
    queryOptions?: Partial<
      Omit<UseQueryOptions<TFnData, QueryError, TData>, 'queryKey' | 'queryFn'>
    >,
    apiOptions?: SecondParameter<typeof orvalAPI>
  ): UseQueryOptions<TFnData, QueryError, TData> {
    return {
      queryKey: getKey(params),
      queryFn: ({ signal }) => queryFn(params, apiOptions, signal),
      ...defaultOptions,
      ...queryOptions,
    };
  }

  /**
   * Returns useQuery hook
   */
  function useQueryHook<TData = TFnData>(
    params: TParams,
    queryOptions?: Partial<
      Omit<UseQueryOptions<TFnData, QueryError, TData>, 'queryKey' | 'queryFn'>
    >,
    apiOptions?: SecondParameter<typeof orvalAPI>
  ) {
    return useQuery(getOptions(params, queryOptions, apiOptions));
  }

  /**
   * Bind functions to hook
   */
  useQueryHook.key = key;
  useQueryHook.getKey = getKey;
  useQueryHook.getOptions = getOptions;

  /**
   * Typecast hook
   */
  return useQueryHook as typeof useQueryHook & { getKey: typeof getKey } & {
    getOptions: typeof getOptions;
  } & { key: QueryKey };
};
