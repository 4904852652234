import { ReactNode } from 'react';

import { Button, ButtonProps } from '@/components';

import { useModalProps } from '../hooks/useModalProps';
import { ModalStateActionOptions } from '../hooks/useModalState';

interface ModalActionButtonProps extends ButtonProps {
  children: ReactNode;
  /** Name of the action being performed, has to be passed to `useModalState` action as well */
  action: ModalStateActionOptions;
  /** Label to show when the action is successful */
  successLabel: string;
}

export function ModalActionButton({
  children,
  action,
  successLabel,
  ...props
}: ModalActionButtonProps) {
  const modalProps = useModalProps();

  const state = modalProps?.state;

  if (!state) {
    return <Button {...props}>{children}</Button>;
  }

  if (state.action !== action) {
    return (
      <Button {...props} disabled={state.type === 'action'}>
        {children}
      </Button>
    );
  } else {
    let buttonState: ButtonProps['state'];

    buttonState = state.type === 'action' ? 'loading' : buttonState;
    buttonState = state.type === 'success' ? 'success' : buttonState;
    buttonState = state.type === 'action-overtime' ? 'loading' : buttonState;

    return (
      <Button variant="green" {...props} state={buttonState}>
        {state.type === 'success' ? successLabel : children}
      </Button>
    );
  }
}
