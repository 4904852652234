import { twMerge } from 'tailwind-merge';

export interface LoaderTitleProps {
  className?: string;
  w: `w-${string}`;
  h: `h-${string}`;
}

export const LoaderContent = ({ className, w, h }: LoaderTitleProps) => (
  <div className={twMerge('inline-block animate-pulse rounded bg-gray-100', w, h, className)} />
);
