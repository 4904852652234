import { createContext, useContext } from 'react';

import { ModalLinkProps } from '../ModalLink';

export const ModalLinkContext = createContext<ModalLinkProps | null>(null);

export const useModalLinkContext = () => {
  const context = useContext(ModalLinkContext);

  if (!context) {
    throw new Error('ModalLink components must be used within <ModalLink>');
  }

  return context;
};
