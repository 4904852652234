import { useDebouncedState, useMountEffect } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import { useReducer, useState } from 'react';

import {
  LabelMapSchema,
  LabelSchema,
  SortSchema,
  VoorschriftLabelSchema,
  VoorschriftSchema,
} from '@/api';
import {
  useDeleteVoorschriftLabel,
  useLabel,
  useLabelMap,
  usePostVoorschriftLabel,
} from '@/api/queries/label';
import {
  Checkbox,
  FeedbackMessage,
  Modal,
  Tree,
  TreeItem,
  Warning,
  useModalState,
} from '@/components';
import { ModalLink } from '@/components/shared/ModalLink';
import { RegularMagnifyingGlass, SolidBoxArchive } from '@/icons/components';
import { useUserStore } from '@/stores/user';

import { modal } from '../utils';

export interface LinkLabelsProps {
  voorschrift: VoorschriftSchema;
  nummer: string;
}

export const LINK_LABELS_ID = 'linkLabels';

const SORT_MAP: SortSchema[] = [
  {
    model: 'LabelMap',
    field: 'Naam',
    direction: 'asc',
  },
];

const SORT_LABEL: SortSchema[] = [
  {
    model: 'Label',
    field: 'Label',
    direction: 'asc',
  },
];

export const LinkLabels = modal(LINK_LABELS_ID, ({ data, props }) => {
  const { voorschrift, nummer } = data;

  const [showArchived, setShowArchived] = useState(false);
  const userId = useUserStore((state) => state.user?.ID);
  const modalState = useModalState();

  const postVoorschriftLabel = usePostVoorschriftLabel();
  const deleteVoorschriftLabel = useDeleteVoorschriftLabel();

  const { addLabel, removeLabel, selectedLabels, initialSelectedLabels, isLoading } =
    useSelectLabel(voorschrift);

  const topLevelMappen = useLabelMap({
    page: 1,
    size: 100,
    filter: [
      {
        field: 'Map',
        op: 'is_null',
        value: '',
      },
    ],
    sort: SORT_MAP,
  });

  const onSave = async () => {
    if (!userId) return;

    modalState.action('save');

    const promises: Promise<VoorschriftLabelSchema | void>[] = [];

    /**
     * Check which labels are to be removed.
     */
    initialSelectedLabels.forEach((label) => {
      const isSelected = selectedLabels.some((selectedLabel) => selectedLabel.ID === label.ID);

      /** If the label is not selected anymore, remove the link. */
      if (!isSelected) {
        promises.push(
          deleteVoorschriftLabel.mutateAsync({
            labelId: label.ID!,
            voorschriftLineageId: voorschrift.Lineage,
          })
        );
      }
    });

    /**
     * Check which labels are to be added.
     */
    selectedLabels.forEach((label) => {
      const isInitiallySelected = initialSelectedLabels.some(
        (selectedLabel) => selectedLabel.ID === label.ID
      );

      /** If the label was not selected before, create a link */
      if (!isInitiallySelected) {
        promises.push(
          postVoorschriftLabel.mutateAsync({
            data: {
              Created_By: userId,
              Modified_By: userId,
              Created_Date: new Date().toISOString(),
              Modified_Date: new Date().toISOString(),
              Label: label.ID!,
              Voorschrift_lineage: voorschrift.Lineage,
            },
          })
        );
      }
    });

    try {
      await Promise.all(promises);
    } catch {
      FeedbackMessage('error', 'Let op, sommige koppelingen konden niet worden geüpdatet');

      modalState.error('save');
      props.onClose();
    } finally {
      modalState.success('save');
    }
  };

  /**
   * Search handlers
   */
  const [query, setQuery] = useDebouncedState<string | null>(null, 500);

  const queryLabels = useLabel(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'Label',
          field: 'Label',
          op: 'like',
          value: `%${query ?? ''}%`,
        },
      ],
      sort: SORT_LABEL,
    },
    {
      enabled: !!query,
    }
  );

  const filteredQueryLabels = queryLabels.data?.objects?.filter((label) => {
    if (!showArchived && label.Status === 'Archief') {
      return false;
    }

    return true;
  });

  return (
    <ModalLink
      state={modalState.state}
      isLoading={isLoading || topLevelMappen.isLoading}
      {...props}
    >
      <ModalLink.Body>
        <ModalLink.Left
          title={`Voorschrift ${nummer}`}
          searchProps={{
            id: 'link-labels-search',
            name: 'link-labels-search',
            placeholder: 'Zoek binnen de labels',
            label: 'Zoek een label',
            icon: RegularMagnifyingGlass,
            onChange: (e) => setQuery(e.target.value),
            isLoading: queryLabels.isFetching,
          }}
          switchProps={{
            label: 'Toon gearchiveerde labels',
            enabled: showArchived,
            onChange: () => setShowArchived(!showArchived),
          }}
        >
          {query &&
            filteredQueryLabels?.map((label) => {
              if (!showArchived && label.Status === 'Archief') {
                return null;
              }

              const isChecked = selectedLabels.some(
                (selectedLabel) => selectedLabel.ID === label.ID
              );
              const id = `link-labels-checkbox-${label.ID!}`;

              return (
                <Checkbox
                  key={id}
                  label={label.Label}
                  name={id}
                  value={label.ID}
                  id={id}
                  variant="withBackground"
                  checked={isChecked}
                  disabled={isChecked}
                  onChange={() => addLabel(label)}
                  className="mb-2 w-full"
                  icon={label.Status === 'Archief' ? SolidBoxArchive : undefined}
                />
              );
            })}

          {query && !filteredQueryLabels?.length && (
            <p className="text-sm text-gray-500">
              Geen labels gevonden voor de zoekterm &apos;{query}&apos;
            </p>
          )}

          {!query &&
            topLevelMappen.data?.objects?.map((map) => (
              <Map
                addLabel={addLabel}
                selectedLabels={selectedLabels}
                key={`link-labels-map-${map.ID!}`}
                map={map}
                depth={0}
                showArchived={showArchived}
              />
            ))}
        </ModalLink.Left>

        <ModalLink.Right
          title={
            selectedLabels.length > 1
              ? `${selectedLabels.length} Gekoppelde labels`
              : selectedLabels.length === 1
                ? '1 Gekoppeld label'
                : 'Geen gekoppelde labels'
          }
        >
          <Warning
            title="Let op! Koppelingen worden altijd toegepast op alle versies van een voorschrift. Dus eventuele vorige én volgende versies van dit voorschrift krijgen deze koppeling(en)."
            variant="warning"
            size="small"
            className="mb-2"
          />

          {selectedLabels.map((label) => {
            const id = `linked-label-${label.ID}`;

            return (
              <Checkbox
                className="mb-2"
                key={id}
                label={label.Label}
                id={id}
                name={id}
                variant="withBackground"
                onChange={() => removeLabel(label)}
                checked={true}
                icon={label.Status === 'Archief' ? SolidBoxArchive : undefined}
              />
            );
          })}
        </ModalLink.Right>
      </ModalLink.Body>

      <Modal.Footer>
        <Modal.CancelButton />
        <Modal.ActionButton action="save" successLabel="Opgeslagen" onClick={() => onSave()}>
          Opslaan
        </Modal.ActionButton>
      </Modal.Footer>
    </ModalLink>
  );
});

const Map = ({
  map,
  depth,
  addLabel,
  selectedLabels,
  showArchived,
}: {
  map: LabelMapSchema;
  depth: number;
  addLabel: (label: LabelSchema) => void;
  selectedLabels: LabelSchema[];
  showArchived: boolean;
}) => {
  const nestedMappen = useLabelMap({
    page: 1,
    size: 100,
    filter: [
      {
        field: 'Map',
        op: 'eq',
        value: map.ID!,
      },
    ],
    sort: SORT_MAP,
  });

  const nestedLabels = useLabel({
    page: 1,
    size: 100,
    filter: [
      {
        model: 'Label',
        field: 'Map',
        op: 'eq',
        value: map.ID!,
      },
    ],
    sort: SORT_LABEL,
  });

  return (
    <Tree label={map.Naam} depth={depth}>
      {nestedMappen.data?.objects?.map((map) => (
        <Map
          key={`link-labels-map-${map.ID!}`}
          map={map}
          depth={depth + 1}
          addLabel={addLabel}
          selectedLabels={selectedLabels}
          showArchived={showArchived}
        />
      ))}

      {nestedLabels.data?.objects?.map((label) => {
        if (!showArchived && label.Status === 'Archief') {
          return null;
        }

        const isChecked = selectedLabels.some((selectedLabel) => selectedLabel.ID === label.ID);
        const id = `link-labels-checkbox-${label.ID!}`;

        return (
          <TreeItem depth={depth + 1} key={id}>
            <Checkbox
              label={label.Label}
              name={id}
              value={label.ID}
              id={id}
              variant="withBackground"
              checked={isChecked}
              disabled={isChecked}
              onChange={() => addLabel(label)}
              className="w-full"
              icon={label.Status === 'Archief' ? SolidBoxArchive : undefined}
            />
          </TreeItem>
        );
      })}
    </Tree>
  );
};

const useSelectLabel = (voorschrift: VoorschriftSchema) => {
  const queryClient = useQueryClient();
  const [selectedLabels, dispatch] = useReducer(reducer, []);
  const [isLoading, setIsLoading] = useState(true);
  const [initialSelectedLabels, setInitialSelectedLabels] = useState<LabelSchema[]>([]);

  /**
   * Fetch the labels once when the component mounts.
   * This is done to prevent the labels from being refetched by useQuery
   **/
  useMountEffect(() => {
    let isMounted = true;

    queryClient
      .fetchQuery(
        useLabel.getOptions({
          page: 1,
          size: 100,
          filter: [
            {
              model: 'VoorschriftLabel',
              field: 'Voorschrift_lineage',
              op: 'eq',
              value: voorschrift.Lineage,
            },
          ],
          sort: SORT_LABEL,
        })
      )
      .then((data) => {
        if (!isMounted) return;

        dispatch({ type: 'init', labels: data.objects ?? [] });
        setInitialSelectedLabels(data.objects ?? []);
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  });

  return {
    isLoading,
    selectedLabels,
    initialSelectedLabels,
    addLabel: (label: LabelSchema) => dispatch({ type: 'add', label }),
    removeLabel: (label: LabelSchema) => dispatch({ type: 'remove', label }),
  };
};

const reducer = (
  state: LabelSchema[],
  action:
    | { type: 'init'; labels: LabelSchema[] }
    | { type: 'remove'; label: LabelSchema }
    | { type: 'add'; label: LabelSchema }
) => {
  switch (action.type) {
    case 'init':
      return action.labels;
    case 'remove':
      return state.filter((label) => label.ID !== action.label.ID);
    case 'add':
      return [...state, action.label];
  }
};
