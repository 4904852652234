import { createFileRoute, notFound } from '@tanstack/react-router';

import { useBesluitId } from '@/api/queries/besluit';
import { useHoofdstukId } from '@/api/queries/objects';
import { useOverwegingByHoofdstukLineageId } from '@/api/queries/overwegingHoofdstuk';

import { ChapterNavigation } from '../-components/ChapterNavigation';
import { HTMLContent } from '../-components/HTMLContent';
import {
  ensureQueryDataViewerBesluitHoofdstukken,
  useViewerBesluitHoofdstukken,
} from './-utils/useViewerBesluitHoofdstukken';

/**
 * Route
 */
export const Route = createFileRoute(
  '/$bedrijfLineageId/viewer/besluit/$besluitId/inhoudelijke-overwegingen/$hoofdstukId'
)({
  loader: async ({ params: { besluitId, hoofdstukId }, context: { queryClient } }) => {
    const hoofdstuk = await queryClient.ensureQueryData(useHoofdstukId.getOptions(hoofdstukId));

    if (!hoofdstuk) throw notFound();

    await Promise.all([
      queryClient.ensureQueryData(useBesluitId.getOptions(besluitId)),
      queryClient.ensureQueryData(
        useOverwegingByHoofdstukLineageId.queryOptions({
          hoofdstukLineageId: hoofdstuk.Lineage,
          besluitId,
        })
      ),
      ensureQueryDataViewerBesluitHoofdstukken({ besluitId, queryClient }),
    ]);
  },
  component: InhoudelijkeOverwegingen,
});

/**
 * Route component
 */
function InhoudelijkeOverwegingen() {
  const { besluitId, hoofdstukId, bedrijfLineageId } = Route.useParams();

  const hoofdstukLineageId = useHoofdstukId(hoofdstukId, { select: ({ Lineage }) => Lineage });
  const besluit = useBesluitId(besluitId, { staleTime: Infinity });

  const { overwegingHoofdstukken, getSiblings } = useViewerBesluitHoofdstukken({
    besluitId,
  });

  const hoofdstuk = overwegingHoofdstukken.find(({ ID }) => ID === hoofdstukId);
  const overweging = useOverwegingByHoofdstukLineageId(
    { hoofdstukLineageId: hoofdstukLineageId.data ?? '', besluitId },
    {
      enabled: !!hoofdstukLineageId.data,
    }
  );

  const { nextHoofdstuk, previousHoofdstuk } = getSiblings({ hoofdstukId, type: 'overwegingen' });

  if (!besluit.data || !hoofdstuk || overweging.isLoading) return null;

  return (
    <>
      <HTMLContent
        title={<span dangerouslySetInnerHTML={{ __html: hoofdstuk.Titel }} />}
        content={overweging.data?.InhoudelijkeOverweging}
      />

      <ChapterNavigation
        previous={
          previousHoofdstuk?.ID && (
            <ChapterNavigation.Previous
              nummer={previousHoofdstuk.Nummer}
              htmlTitle={previousHoofdstuk.Titel}
              to="/$bedrijfLineageId/viewer/besluit/$besluitId/inhoudelijke-overwegingen/$hoofdstukId"
              params={{ bedrijfLineageId, besluitId, hoofdstukId: previousHoofdstuk.ID }}
            />
          )
        }
        next={
          nextHoofdstuk?.ID && (
            <ChapterNavigation.Next
              nummer={nextHoofdstuk.Nummer}
              htmlTitle={nextHoofdstuk.Titel}
              to="/$bedrijfLineageId/viewer/besluit/$besluitId/inhoudelijke-overwegingen/$hoofdstukId"
              params={{ bedrijfLineageId, besluitId, hoofdstukId: nextHoofdstuk.ID }}
            />
          )
        }
      />
    </>
  );
}
