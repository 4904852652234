import { Slot, Slottable } from '@radix-ui/react-slot';
import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { twJoin } from 'tailwind-merge';

import { SolidSpinner } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

export interface PillButtonProps extends ComponentPropsWithoutRef<'button'> {
  icon?: IconComponent;
  iconClassName?: string;
  asChild?: boolean;
  variant?: 'green' | 'white' | 'blue';
  isLoading?: boolean;
}

export const PillButton = ({
  className,
  icon,
  isLoading,
  iconClassName,
  children,
  asChild,
  variant = 'green',
  ...props
}: PillButtonProps) => {
  const Component = asChild ? Slot : 'button';
  const IconComponent = isLoading ? SolidSpinner : icon;

  let classes = '';

  switch (variant) {
    case 'green':
      classes = 'bg-theme-green text-white hover:bg-theme-green-dark';
      break;
    case 'white':
      classes = 'ring-1 ring-gray-300 bg-white text-black hover:bg-gray-200 active:bg-gray-300';
      break;
    case 'blue':
      classes = 'bg-theme-blue text-white hover:bg-theme-blue-dark';
      break;
  }

  return (
    <Component
      className={twMerge(
        classes,
        'focus-visible:ring-3 relative flex h-6 items-center rounded-[12px] px-4 text-sm leading-3 focus-visible:ring-theme-blue',
        'disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-800',
        className
      )}
      {...props}
    >
      {IconComponent && (
        <span className={twMerge(twJoin('mr-2 block', isLoading && 'rotating'), iconClassName)}>
          <IconComponent />
        </span>
      )}

      <Slottable>{children}</Slottable>
    </Component>
  );
};
