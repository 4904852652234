import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postFixLinkSppb, postFixSubparagraaf } from '@/api/generated/digiVAPI';
import { SPPBSchema, SubparagraafSchema } from '@/api/generated/digiVAPI.schemas';
import { QueryError } from '@/utils/createQuery';
import { pick } from '@/utils/pick';

import { useLinksInHoofdstuk, useSPPB } from '../links';
import { useSubparagraaf, useSubparagraafId } from '../subparagraaf';
import { LinkSubparagraaf } from '../utils/getLinksInHoofdstuk';

/**
 * This mutation can be used to start editing a published voorschrift.
 *
 * When we want to edit a published voorschrift, we first need to create a new voorschrift in the lineage.
 */
export const useCreateSubparagraafInLineage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { SPPB: SPPBSchema; subparagraaf: SubparagraafSchema },
    QueryError,
    { subparagraaf: SubparagraafSchema; link: LinkSubparagraaf }
  >({
    mutationFn: async ({ subparagraaf, link }) => {
      const newSubparagraaf = await postFixSubparagraaf({
        ...pick(subparagraaf, ['Grondslag', 'Lineage', 'Stop_Lineage', 'Transparent', 'Titel']),
        Ontwerp: 1,
        Vorig: subparagraaf.ID,
        Index: subparagraaf.Index + 1,
      });

      const SPPB = await postFixLinkSppb({
        ...pick(link.SPPB, ['Besluit', 'Rang', 'Paragraaf']),
        Subparagraaf: newSubparagraaf.ID!,
        Type: 'Eigen',
      });

      return {
        SPPB,
        subparagraaf: newSubparagraaf,
      };
    },
    onSuccess: async ({ subparagraaf }) => {
      queryClient.setQueryData<SubparagraafSchema>(
        useSubparagraafId.getKey(subparagraaf.ID!),
        subparagraaf
      );

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: useSubparagraaf.key }),
        queryClient.invalidateQueries({ queryKey: useSPPB.key }),
        queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key }),
      ]);
    },
  });
};
