import { SubmitHandler, useForm } from 'react-hook-form';

import { BedrijfSchema, HistorischBesluitSchema } from '@/api';
import {
  usePatchHistorischBesluitId,
  usePostHistorischBesluit,
} from '@/api/queries/historischBesluit';
import { Input, Modal, TextArea, useModalState } from '@/components';
import { useUserStore } from '@/stores/user';
import { DateUtils } from '@/utils/dateUtils';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface HistorischBesluitEigenschappenProps {
  bedrijf: Pick<BedrijfSchema, 'Lineage'>;
  onSuccess?: (historischBesluit: HistorischBesluitSchema) => void;
  historischBesluitToUpdate?: HistorischBesluitSchema;
}

export const HISTORISCH_BESLUIT_EIGENSCHAPPEN_ID = 'historischBesluitEigenschappen';

type FormValues = Pick<
  HistorischBesluitSchema,
  'Besluit_Nummer' | 'Besluit_Omschrijving' | 'Besluit_Datum'
>;

export const HistorischBesluitEigenschappen = modal(
  HISTORISCH_BESLUIT_EIGENSCHAPPEN_ID,
  ({ data, props }) => {
    const { bedrijf, historischBesluitToUpdate, onSuccess } = data;

    const modalState = useModalState();
    const postHistorischBesluit = usePostHistorischBesluit();
    const patchHistorischBesluitId = usePatchHistorischBesluitId();

    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm<FormValues>({
      defaultValues: {
        ...(historischBesluitToUpdate ?? {}),
        Besluit_Datum: historischBesluitToUpdate?.Besluit_Datum
          ? DateUtils.formatForInput(new Date(historischBesluitToUpdate.Besluit_Datum))
          : undefined,
      },
    });

    const onSubmit: SubmitHandler<FormValues> = (formValues) => {
      const userId = useUserStore.getState().user?.ID ?? '';
      const date = new Date().toISOString();

      modalState.action('save');

      const besluitDatum = formValues.Besluit_Datum
        ? DateUtils.formatForBE(new Date(formValues.Besluit_Datum))
        : null;

      if (historischBesluitToUpdate) {
        patchHistorischBesluitId.mutate(
          {
            id: historischBesluitToUpdate.ID!,
            data: {
              ...formValues,
              Besluit_Datum: besluitDatum,
              Modified_By: userId,
              Modified_Date: date,
            },
          },
          {
            onSuccess: (historischBesluit) => {
              onSuccess?.(historischBesluit);
              modalState.success('save');
            },
            onError: (error) => {
              const errorMessage = ErrorMessage.getLocalErrorMessage(error);

              if (errorMessage) {
                modalState.error('save', { title: 'Mislukt', description: errorMessage });
              }
            },
          }
        );
      } else {
        postHistorischBesluit.mutate(
          {
            data: {
              ...formValues,
              Besluit_Datum: besluitDatum,
              Created_By: userId,
              Created_Date: date,
              Modified_By: userId,
              Modified_Date: date,
              Bedrijf_lineage: bedrijf.Lineage,
            },
          },
          {
            onSuccess: (historischBesluit) => {
              onSuccess?.(historischBesluit);
              modalState.success('save');
            },
            onError: (error) => {
              const errorMessage = ErrorMessage.getLocalErrorMessage(error);

              if (errorMessage) {
                modalState.error('save', { title: 'Mislukt', description: errorMessage });
              }
            },
          }
        );
      }
    };

    return (
      <Modal {...props} state={modalState.state} title="Historisch besluit">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Besluit_Nummer', { required: 'Dit veldt is verplicht.' })}
            error={errors.Besluit_Nummer}
            required
            id="historisch-besluit-besluitnummer"
            label="Besluitnummer"
            maxLength={200}
          />

          <TextArea
            {...register('Besluit_Omschrijving')}
            error={errors.Besluit_Omschrijving}
            id="historisch-besluit-omschrijving"
            label="Omschrijving"
            className="mt-2"
          />

          <Input
            {...register('Besluit_Datum')}
            error={errors.Besluit_Datum}
            label="Besluitdatum"
            id="historisch-besluit-datum"
            type="date"
          />

          <Modal.Footer>
            <Modal.CancelButton />

            <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
              Opslaan
            </Modal.ActionButton>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
