import { useQuery } from '@tanstack/react-query';
import { Link, createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useBesluit, useLastVigerendBesluit } from '@/api/queries/besluit';
import { HeadingLarge } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { isUserAuthenticatedForRoute } from '@/stores/user/isUserAuthenticatedForRoute';

import BesluitCardInOntwerp from './-components/BesluitCardInOntwerp';
import { BesluitCardNew } from './-components/BesluitCardNew/BesluitCardNew';
import { WijzigingCardNew } from './-components/WijzigingCardNew';

const searchSchema = z.object({
  p: z.number().optional().default(1),
});

/**
 * Query options
 */
const inOntwerpQueryOptions = (bedrijfId?: string, page: number = 1) => {
  return useBesluit.getOptions(
    {
      page: page,
      size: 10,
      filter: [
        {
          model: 'Besluit',
          field: 'Status',
          op: 'eq',
          value: 'Ontwerp',
        },
        {
          model: 'Besluit',
          field: 'Bedrijf',
          op: 'eq',
          value: bedrijfId ?? '',
        },
      ],
    },
    {
      enabled: !!bedrijfId,
    }
  );
};

const latestBedrijfQueryOptions = (bedrijfLineageId: string) =>
  useLatestBedrijf.getOptions(bedrijfLineageId);

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/bedrijf/_layout/ontwerpen')({
  validateSearch: searchSchema.parse,
  beforeLoad: async ({ preload }) => {
    await isUserAuthenticatedForRoute({ preload, allowed: ['Vergunningverlener'] });

    return {
      getTitle: () => 'Ontwerpen',
    };
  },
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ params, context: { queryClient }, deps: { search } }) => {
    const bedrijf = await queryClient.ensureQueryData(
      latestBedrijfQueryOptions(params.bedrijfLineageId)
    );

    const [besluitenInOntwerp] = await Promise.all([
      queryClient.ensureQueryData(inOntwerpQueryOptions(bedrijf.objects?.[0]?.ID, search.p)),
      queryClient.ensureQueryData(
        useLastVigerendBesluit.getOptions({ bedrijfLineageId: bedrijf.objects?.[0]?.Lineage })
      ),
    ]);

    if (!besluitenInOntwerp.num_pages) return;

    if (besluitenInOntwerp.num_pages < search.p) {
      throw redirect({
        to: '/$bedrijfLineageId/bedrijf/ontwerpen',
        search: { p: besluitenInOntwerp.num_pages },
        params,
      });
    }
  },
  component: OntwerpenComponent,
});

/**
 * Route component
 */
function OntwerpenComponent() {
  const { bedrijfLineageId } = Route.useParams();
  const search = Route.useSearch();

  const bedrijf = useQuery(latestBedrijfQueryOptions(bedrijfLineageId));

  const besluitenInOntwerp = useQuery(inOntwerpQueryOptions(bedrijf?.data?.ID, search.p));
  const hasBesluitenInOntwerp = !!besluitenInOntwerp.data?.objects?.length;

  const lastVigerendBesluit = useLastVigerendBesluit({ bedrijfLineageId: bedrijf?.data?.Lineage });
  const hasVigerendBesluit = !!lastVigerendBesluit.data;

  if (!bedrijf.data) return null;

  return (
    <section className="pb-16" data-testid="bedrijfdasboard-tab-besluiten">
      <div className="mb-12">
        <div className="mb-10 flex space-x-4">
          <BesluitCardNew
            bedrijf={bedrijf.data}
            hasBesluitInOntwerp={hasBesluitenInOntwerp}
            hasVigerendBesluit={hasVigerendBesluit}
          />

          {hasVigerendBesluit ? <WijzigingCardNew bedrijfLineageId={bedrijfLineageId} /> : null}
        </div>

        <HeadingLarge className="mb-4" as="h2" value="Ontwerpen" />

        {hasBesluitenInOntwerp ? (
          <>
            <ul className="flex flex-col gap-4">
              {besluitenInOntwerp.data?.objects?.map((besluit) => {
                return (
                  <li key={besluit.ID}>
                    <BesluitCardInOntwerp
                      besluit={besluit}
                      bedrijf={bedrijf.data!}
                      headingAs="h3"
                      hasVigerendBesluit={hasVigerendBesluit}
                    />
                  </li>
                );
              })}
            </ul>

            {(besluitenInOntwerp.data?.num_pages ?? 0) > 1 ? (
              <div className="mt-6 flex justify-center">
                <PaginationLinks
                  renderLink={({ children, page }) => (
                    <Link
                      to="/$bedrijfLineageId/bedrijf/ontwerpen"
                      params={{ bedrijfLineageId }}
                      search={{ p: page }}
                    >
                      {children}
                    </Link>
                  )}
                  currentPage={besluitenInOntwerp.data?.page ?? 1}
                  totalPages={besluitenInOntwerp.data?.num_pages ?? 1}
                />
              </div>
            ) : null}
          </>
        ) : (
          <p className="italic text-gray-500">Er zijn geen ontwerpen voor dit bedrijf</p>
        )}
      </div>
    </section>
  );
}
