import { createContext, useContext } from 'react';

type DraggableEditorContextType = {
  allowReparenting?: boolean;
};

export const DraggableEditorContext = createContext<DraggableEditorContextType>({});

export const useDraggableEditorContext = () => {
  const context = useContext(DraggableEditorContext);

  if (!context) {
    throw new Error('useDraggableEditorContext must be used within a DraggableEditorProvider');
  }

  return context;
};
