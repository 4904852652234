import { ReactNode, forwardRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';

import { StdObjects, StdSubparagraaf } from '@/api/queries/std';
import { AddItemButton, EditorAdd, EditorSubparagraaf, InfoCard } from '@/components';
import {
  RegularAlignLeft,
  RegularHeading,
  RegularParagraph,
  RegularPenToSquare,
} from '@/icons/components';
import { useOpenModal } from '@/modals/utils/modalStore';

interface SubparagraafProps {
  children: ReactNode;
  stdObjects: StdObjects;
  sectieId: string;
  paragraafIndex: number;
  subparagraaf: StdSubparagraaf['data'];
  subparagraafIndex: number;
  dragHandleRef?: ConnectDragSource;
}

export const Subparagraaf = forwardRef<HTMLDivElement, SubparagraafProps>(
  (
    {
      children,
      stdObjects,
      sectieId,
      paragraafIndex,
      subparagraafIndex,
      subparagraaf,
      dragHandleRef,
    },
    ref
  ) => {
    const paragraaf = stdObjects.paragrafen[paragraafIndex];

    if (!paragraaf) {
      throw new Error('No paragraaf found');
    }
    const subparagrafen = paragraaf?.subparagrafen;
    const isLastSubparagraaf = subparagraafIndex === subparagrafen.length - 1;
    const voorschriften = paragraaf?.subparagrafen[subparagraafIndex]?.voorschriften;

    const openModal = useOpenModal();
    const [isOpen, setIsOpen] = useState(!voorschriften?.length);

    //Remove html tags from subparagraaf title for aria label.
    const newSubparagraafTitel = subparagraaf.Titel.replace(/(<([^>]+)>)/gi, '');

    if (subparagraaf.Transparent) {
      return (
        <>
          <EditorAdd
            asSpacer={false}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
          >
            <AddItemButton
              data-testid="add-item-button-lege-voorschrift"
              icon={RegularAlignLeft}
              label="Voorschrift"
              onClick={() =>
                openModal('standaardtekstenToevoegenVoorschrift', {
                  subparagraafId: subparagraaf.ID!,
                  voorschriften,
                  voorschriftIndex: 0,
                  onSubmitSuccess: () => setIsOpen(false),
                })
              }
            />
            {!voorschriften?.length && (
              <>
                <AddItemButton
                  data-testid="add-item-button-lege-subparagraaf"
                  icon={RegularHeading}
                  label="Subparagraaf"
                  onClick={() =>
                    openModal('standaardtekstenToevoegenSubparagraaf', {
                      paragraafId: paragraaf.data.ID!,
                      subparagraafIndex,
                      subparagrafen: subparagrafen!,
                      onSubmitSuccess: () => setIsOpen(false),
                    })
                  }
                />
                {isLastSubparagraaf && (
                  <AddItemButton
                    data-testid="add-item-button-lege-paragraaf"
                    icon={RegularParagraph}
                    label="Paragraaf"
                    onClick={() =>
                      openModal('standaardtekstenToevoegenParagraaf', {
                        sectieId,
                        paragraafIndex,
                        stdObjects,
                        onSubmitSuccess: () => setIsOpen(false),
                      })
                    }
                  />
                )}
              </>
            )}
          </EditorAdd>
          {children}
        </>
      );
    }

    return (
      <div ref={ref}>
        <InfoCard
          description={subparagraaf.Toelichting || 'Geen toelichting'}
          testId={`toelichting-${paragraafIndex}-${subparagraafIndex}`}
        />

        <EditorSubparagraaf
          dragHandleRef={dragHandleRef}
          content={subparagraaf.Titel}
          buttonProps={{
            icon: RegularPenToSquare,
            variant: 'white',
            ariaLabel: `Subparagraaf ${newSubparagraafTitel} bewerken`,
            onClick: () =>
              openModal('standaardtekstenEditSubparagraafId', {
                subparagraaf,
              }),
          }}
        >
          <EditorAdd
            asSpacer={false}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
          >
            <AddItemButton
              data-testid="add-item-button-lege-voorschrift"
              icon={RegularAlignLeft}
              label="Voorschrift"
              onClick={() =>
                openModal('standaardtekstenToevoegenVoorschrift', {
                  subparagraafId: subparagraaf.ID!,
                  voorschriften,
                  voorschriftIndex: 0,
                  onSubmitSuccess: () => setIsOpen(false),
                })
              }
            />
            {!voorschriften?.length && (
              <>
                <AddItemButton
                  data-testid="add-item-button-lege-subparagraaf"
                  icon={RegularHeading}
                  label="Subparagraaf"
                  onClick={() =>
                    openModal('standaardtekstenToevoegenSubparagraaf', {
                      paragraafId: paragraaf.data.ID!,
                      subparagraafIndex,
                      subparagrafen: subparagrafen!,
                      onSubmitSuccess: () => setIsOpen(false),
                    })
                  }
                />
                {isLastSubparagraaf && (
                  <AddItemButton
                    data-testid="add-item-button-lege-paragraaf"
                    icon={RegularParagraph}
                    label="Paragraaf"
                    onClick={() =>
                      openModal('standaardtekstenToevoegenParagraaf', {
                        sectieId,
                        paragraafIndex,
                        stdObjects,
                        onSubmitSuccess: () => setIsOpen(false),
                      })
                    }
                  />
                )}
              </>
            )}
          </EditorAdd>
          {children}
        </EditorSubparagraaf>
      </div>
    );
  }
);
