/**
 * The collection of of static content used in the application
 */
export const eStrings = {
  TXT_BEDRIJF_GEEN_GEPUBLICEERD_BESLUIT: 'Dit bedrijf heeft nog geen gepubliceerd besluit',
  LBL_BEDRIJFSINFORMATIE_BEWERKEN: 'Bedrijfsinformatie bewerken',
  LBL_BEKIJK_ALLE_BESLUITEN: 'Bekijk alle besluiten en wijzigingen',
  LBL_BESLUITDATUM: 'Besluitdatum',
  TITEL_BESLUIT_IN_ONTWERP: 'Besluit in ontwerp',
  LBL_BESLUIT_KENMERK: 'Besluit kenmerk',
  TXT_BESLUIT_TYPES_LADEN: 'Besluit types laden...',
  TXT_BESLUIT_TYPES_SELECTEREN: 'Selecteer het type',
  TXT_DATE_FORMAT: 'dd-mm-jjjj',
  LBL_FEEDBACK_NEW_AADV_LOADED: 'Nieuwe vigerende situatie ingeladen',
  TXT_GEEN_BESLUITEN_EN_WIJZIGINGEN:
    'Er zijn nog geen besluiten of wijzigingen die getoond kunnen worden. Ook is er nog geen vigerende situatie beschikbaar.',
  LBL_BESLUITEN_EN_WIJZIGINGEN: 'Besluiten & wijzigingen',
  LBL_INWERKINGTREDING: 'Inwerkingtreding',
  LBL_INLOGPAGINA_TITEL: 'Inloggen Digi-V®',
  TXT_INLOGPAGINA_SUBTITEL:
    'Welkom bij Digi-V®, de online omgeving voor het opstellen, bewerken en beheren van vergunningen.',
  LBL_BESLUIT_OMSCHRIJVING: 'Omschrijving',
  TXT_LOADING: 'Laden...',
  TXT_MBIO_WARNING:
    'Er is een ander besluit in ontwerp. Dat houdt in dat bepaalde onderdelen misschien niet aan te passen zijn. Dit geldt ook voor aanpassingen die in dit besluit worden gemaakt.',
  LBL_OLO_NUMMER: 'OLO_Nummer',
  TXT_SELECT_BESLUITTYPE: 'Selecteer een type besluit',
  LBL_STATUS: 'Status',
  TXT_SELECT_STATUS: 'Selecteer een status',
  LBL_TYPE_BESLUIT: 'Type Besluit',
  LBL_TYPE_WIJZIGING: 'Type wijziging',
  LBL_VIGERENDE_SITUATIE: 'Vigerende situatie',
  TXT_VIGERENDE_SITUATIE:
    'De vigerende situatie laat alle geldige voorschriften zien van een specifieke datum. Deze voorschriften zijn afkomstig uit alle besluiten die zijn genomen voor ',
  TXT_VOORLOPIGE_VOORZIENING: 'Dit voorschrift is een voorlopige voorziening',
  TXT_BEROEP_INGEDIEND: 'Voor dit voorschrift is een beroep ingediend',
  LBL_DIGI_V: 'Digi-V®',
  LBL_EMAIL: 'digi-v@pzh.nl',
  LBL_CONSIDERANS: 'Inhoudelijke overwegingen',
  LBL_ZAAKNUMMER: 'Zaaknummer',
  TXT_VUL_ZAAKNUMMER: 'Vul een zaaknummer in',
  TXT_VERPLICHT_INPUT_VELD: 'Dit veld is verplicht',
} as const;

/**
 * Export the strings collection as optimized enum
 */
export type eStrings = (typeof eStrings)[keyof typeof eStrings];
