import { useQueryClient } from '@tanstack/react-query';

import { useDeleteOthHoofdstukReserveringId } from '@/api/generated/digiVAPI';

import { useHoofdstukReservering } from '../useHoofdstukReservering';

/**
 * Deletes a hoofdstuk reservering. Can be used to release a hoofdstuk.
 */
export function useDeleteHoofdstukReservering() {
  const queryClient = useQueryClient();

  return useDeleteOthHoofdstukReserveringId({
    mutation: {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          queryKey: useHoofdstukReservering.key,
        });
      },
    },
  });
}
