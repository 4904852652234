import { createRouter } from '@tanstack/react-router';

import { Error404 } from '@/components/templates/Error404.tsx';

import { queryClient } from '../queryClient';
import { routeTree } from './routeTree.gen';

export const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: 'intent',
  defaultPreloadDelay: 50,
  defaultPreloadStaleTime: 0,
  defaultNotFoundComponent: () => <Error404 />,
  defaultErrorComponent: () => <Error404 />,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
