import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postDynStdVoorschrift } from '@/api/generated/digiVAPI';
import { STDVoorschriftSchema } from '@/api/generated/digiVAPI.schemas';
import { useUserStore } from '@/stores/user';
import { getRangBetween } from '@/utils/getRangBetween';

import { useStdObjects } from '../stdObjects';

export const useCreateStdVoorschrift = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      Nummer,
      Voorschrift,
      Toelichting,
      voorschriftIndex,
      voorschriften,
      Subparagraaf,
    }: {
      Nummer: string;
      Voorschrift: string;
      Toelichting?: string | null;
      voorschriftIndex: number;
      voorschriften?: STDVoorschriftSchema[];
      Subparagraaf: string;
    }) => {
      const userId = useUserStore.getState().user?.ID ?? '';

      const previous = voorschriften?.[voorschriftIndex - 1]?.Rang;

      const next = voorschriften?.[voorschriftIndex]?.Rang;

      const newRang = getRangBetween(previous, next);

      const newStdVoorschrift = postDynStdVoorschrift({
        Subparagraaf,
        Nummer,
        Voorschrift,
        Toelichting,
        Rang: newRang,
        Created_By: userId,
        Created_Date: new Date().toISOString(),
        Modified_By: userId,
        Modified_Date: new Date().toISOString(),
        Landelijk: 0,
      });

      return newStdVoorschrift;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: useStdObjects.key });
    },
  });
};
