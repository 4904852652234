import { Node, mergeAttributes } from '@tiptap/core';

import { getParentList } from '../utils/getParentList';

/**
 * @see https://www.tiptap.dev/api/nodes/list-item
 *
 * Fork of the original ListItem.ts file from the tiptap library.
 */

export interface ListItemOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HTMLAttributes: Record<string, any>;
  bulletListTypeName: string;
  orderedListTypeName: string;
}

export const ListItem = Node.create<ListItemOptions>({
  name: 'listItem',

  addOptions() {
    return {
      HTMLAttributes: {},
      bulletListTypeName: 'bulletList',
      orderedListTypeName: 'orderedList',
    };
  },

  content: 'paragraph block*',

  defining: true,

  parseHTML() {
    return [
      {
        tag: 'li',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['li', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => {
        const { isAbcList } = getParentList(this.editor);

        const result = this.editor.commands.sinkListItem(this.name);

        return isAbcList ? this.editor.commands.setAbcList() : result;
      },
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    };
  },
});
