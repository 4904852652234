import { createFileRoute } from '@tanstack/react-router';

import { useBesluitId } from '@/api/queries/besluit';

import { HTMLContent } from '../-components/HTMLContent';

/**
 * Route
 */
export const Route = createFileRoute(
  '/$bedrijfLineageId/viewer/besluit/$besluitId/overige-overwegingen'
)({
  loader: async ({ params, context: { queryClient } }) => {
    await queryClient.ensureQueryData(useBesluitId.getOptions(params.besluitId));
  },
  component: OverigeOverwegingenComponent,
});

/**
 * Route component
 */
function OverigeOverwegingenComponent() {
  const { besluitId } = Route.useParams();

  const besluit = useBesluitId(besluitId, {
    // After a besluit is published, it will never change again.
    staleTime: Infinity,
  });

  if (!besluit.data) return null;

  return <HTMLContent title="Overige overwegingen" content={besluit.data.Overige_Overweging} />;
}
