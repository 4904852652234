import { getDynInstallatieType, getDynInstallatieTypeId } from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useInstallatietype = createQuery({
  key: ['installatietype'],
  queryFn: getDynInstallatieType,
});

export const useInstallatietypeId = createQuery({
  key: ['installatietypeId'],
  queryFn: getDynInstallatieTypeId,
});
