import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import { STDMapSchema } from '@/api';
import {
  CreateHoofdstukkenProps,
  CreateObjectsResponse,
  CreateParagrafenProps,
  CreateVoorschriftenProps,
  useCreateObjects,
} from '@/api/queries/objects/mutations/useCreateObjects';
import { StdObjects as StdObjectsType, useStdObjects } from '@/api/queries/std';
import { Modal, Wizard, useModalState } from '@/components';
import { modal } from '@/modals/utils';
import { StdMapTreeNav } from '@/routes/$bedrijfLineageId.editor.$besluitId/-components/StdMapTreeNav';

import { StdObjectsForm } from './components/StdObjectsForm';

export type StdObjectsProps =
  | StdObjectsPropsHoofdstuk
  | StdObjectsPropsParagraaf
  | StdObjectsPropsVoorschrift;

type StdObjectsPropsHoofdstuk = {
  allow: 'hoofdstuk';
  besluitId: string;
  userId: string;
  onSubmit?: (response: CreateObjectsResponse) => void;
};

type StdObjectsPropsParagraaf = {
  allow: 'paragraaf';
  besluitId: string;
  hoofdstukId: string;
  previousRang?: string;
  nextRang?: string;
  onSubmit?: (response: CreateObjectsResponse) => void;
};

type StdObjectsPropsVoorschrift = {
  allow: 'voorschrift';
  besluitId: string;
  subparagraafId: string;
  previousRang?: string;
  nextRang?: string;
  onSubmit?: (response: CreateObjectsResponse) => void;
};

export const STD_OBJECTS_ID = 'editorStdObjects';
const FORM_ID = 'LRSO-form';

export const StdObjects = modal(STD_OBJECTS_ID, ({ data, props }) => {
  const { allow, besluitId } = data;

  const modalState = useModalState();
  const queryClient = useQueryClient();
  const createObjects = useCreateObjects();

  const [selectedSectie, setSelectedSectie] = useState<{
    map: STDMapSchema;
    sectie: STDMapSchema;
  } | null>(null);
  const [hasCheckedCheckboxes, setHasCheckedCheckboxes] = useState(false);

  /**
   * Reset scroll position and form checkboxes when switching between secties.
   */
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const selectAllRef = useRef<() => void>(() => {});
  const deselectAllRef = useRef<() => void>(() => {});

  useEffect(() => {
    if (selectedSectie) {
      scrollContainerRef.current?.scrollTo?.({ top: 0 });
    }
  }, [selectedSectie]);

  /**
   * Submit handlers
   */
  const handleSubmit = (selectedObjects: StdObjectsType) => {
    if (!selectedSectie) return;

    modalState.action('save');

    let createObjectProps:
      | CreateHoofdstukkenProps
      | CreateParagrafenProps
      | CreateVoorschriftenProps
      | undefined;

    switch (allow) {
      case 'hoofdstuk':
        createObjectProps = {
          type: 'hoofdstukken',
          besluitId,
          userId: data.userId,
          hoofdstukken: [
            {
              titel: selectedSectie.sectie.Naam,
              paragrafen: selectedObjects.paragrafen.map((paragraaf) => ({
                titel: paragraaf.data.Titel ?? '',
                subparagrafen: paragraaf.subparagrafen
                  .filter((subparagraaf) => !subparagraaf.data.Transparent)
                  .map((subparagraaf) => ({
                    titel: subparagraaf.data.Titel,
                    voorschriften: subparagraaf.voorschriften.map((voorschrift) => ({
                      voorschrift: voorschrift.Voorschrift,
                    })),
                  })),
                voorschriften: paragraaf.subparagrafen
                  .filter((subparagraaf) => subparagraaf.data.Transparent)
                  .map((subparagraaf) => subparagraaf.voorschriften)
                  .flat()
                  .map(({ Voorschrift }) => ({ voorschrift: Voorschrift })),
              })),
            },
          ],
        };
        break;
      case 'paragraaf':
        createObjectProps = {
          type: 'paragrafen',
          besluitId,
          hoofdstukId: data.hoofdstukId,
          previousRang: data.previousRang,
          nextRang: data.nextRang,
          paragrafen: selectedObjects.paragrafen.map((paragraaf) => ({
            titel: paragraaf.data.Titel ?? '',
            subparagrafen: paragraaf.subparagrafen
              .filter((subparagraaf) => !subparagraaf.data.Transparent)
              .map((subparagraaf) => ({
                titel: subparagraaf.data.Titel,
                voorschriften: subparagraaf.voorschriften.map((voorschrift) => ({
                  voorschrift: voorschrift.Voorschrift,
                })),
              })),
            voorschriften: paragraaf.subparagrafen
              .filter((subparagraaf) => subparagraaf.data.Transparent)
              .map((subparagraaf) => subparagraaf.voorschriften)
              .flat()
              .map(({ Voorschrift }) => ({ voorschrift: Voorschrift })),
          })),
        };
        break;
      case 'voorschrift':
        createObjectProps = {
          type: 'voorschriften',
          besluitId,
          subparagraafId: data.subparagraafId,
          previousRang: data.previousRang,
          nextRang: data.nextRang,
          voorschriften:
            selectedObjects.paragrafen
              .map((paragraaf) =>
                paragraaf.subparagrafen.map((subparagraaf) =>
                  subparagraaf.voorschriften.map((voorschrift) => ({
                    voorschrift: voorschrift.Voorschrift,
                  }))
                )
              )
              .flat(2) ?? [],
        };
        break;
    }

    if (createObjectProps) {
      createObjects.mutate(createObjectProps, {
        onSuccess: (response) => {
          data.onSubmit?.(response);

          modalState.success('save');
        },
        onError: () => {
          modalState.error('save', {
            title: 'Let op',
            description: 'Sommige onderdelen zijn niet toegevoegd.',
          });
        },
      });
    }
  };

  return (
    <Wizard
      {...props}
      state={modalState.state}
      sidebar={
        <StdMapTreeNav
          activeSectieId={selectedSectie?.sectie.ID}
          onClickSectie={({ map, sectie }) => setSelectedSectie({ map, sectie })}
          onMouseEnterSectie={({ sectie }) => {
            queryClient.ensureQueryData(useStdObjects.getOptions({ sectieId: sectie.ID! }));
          }}
        />
      }
      title="Standaardteksten invoegen"
      titleInfoCard={selectedSectie?.sectie.Toelichting}
      bodyTitle={selectedSectie?.map.Naam}
      placeholder="Navigeer via het menu aan de linkerkant naar het hoofdstuk waarvan je standaardteksten wilt invoegen.
      Selecteer daarna de onderderdelen om in te voegen."
      scrollContainerRef={scrollContainerRef}
      onSelectAll={() => selectAllRef.current?.()}
      onDeselectAll={() => deselectAllRef.current?.()}
      footer={
        <Modal.Footer>
          <Modal.CancelButton />
          <Modal.ActionButton
            type="submit"
            form={FORM_ID}
            variant="green"
            action="save"
            successLabel="Ingevoegd"
            disabled={!hasCheckedCheckboxes}
          >
            Invoegen
          </Modal.ActionButton>
        </Modal.Footer>
      }
    >
      {selectedSectie ? (
        <StdObjectsForm
          formId={FORM_ID}
          selectedSectie={selectedSectie}
          allow={allow}
          selectAllRef={selectAllRef}
          deselectAllRef={deselectAllRef}
          onValuesChange={(values) => {
            const hasCheckedCheckboxes = !!Object.values(values).some((value) => value);

            setHasCheckedCheckboxes(hasCheckedCheckboxes);
          }}
          onSubmit={handleSubmit}
        />
      ) : null}
    </Wizard>
  );
});
