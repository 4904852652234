import { ReactNode } from 'react';

import { RegularSpinner } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

export const EditorAddCompact = ({
  children,
  testId,
}: {
  children: ReactNode;
  testId?: string;
}) => {
  return (
    <div className="relative flex justify-center py-1" data-testid={testId}>
      <div className="relative flex h-6 overflow-hidden rounded-[15px] border border-gray-300 bg-white opacity-50 shadow-lg hover:opacity-100">
        {children}
      </div>
    </div>
  );
};

EditorAddCompact.Button = function EditorAddCompactButton({
  children,
  icon,
  isLoading,
  disabled,
  ...props
}: { icon: IconComponent; isLoading?: boolean } & Omit<
  React.ComponentPropsWithoutRef<'button'>,
  'className'
>) {
  const Icon = icon;

  return (
    <button
      {...props}
      className="flex h-full items-center border-r border-gray-300 px-4 text-sm last:border-r-0 hover:bg-gray-100 active:bg-gray-200"
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <RegularSpinner size={14} className="mr-2 animate-spin" />
      ) : (
        <Icon size={14} className="mr-2" />
      )}
      <span className="-mt-px block">{children}</span>
    </button>
  );
};

EditorAddCompact.Spacer = function EditorAddCompactSpacer() {
  return <div className="h-[32px]" />;
};
