import { Slot } from '@radix-ui/react-slot';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { RegularChevronRight, RegularEllipsis } from '@/icons/components';

export const Pagination = ({ children, ariaLabel }: { children: ReactNode; ariaLabel: string }) => {
  return (
    <nav role="navigation" aria-label={ariaLabel} className="flex gap-1">
      {children}
    </nav>
  );
};

Pagination.Truncate = function Truncate({ testId }: { testId?: string }) {
  return (
    <span
      className="flex size-10 items-center justify-center rounded-sm text-theme-blue-dark"
      data-testid={testId}
      aria-hidden={true}
    >
      <RegularEllipsis />
    </span>
  );
};

Pagination.Button = function ({
  children,
  active,
  ariaLabel,
  asChild,
  disabled,
  ...props
}: {
  children: ReactNode;
  active?: boolean;
  ariaLabel: string;
  asChild?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>) {
  const Component = asChild ? Slot : 'button';

  return (
    <Component
      aria-label={ariaLabel}
      className={twJoin(
        'flex h-10 w-10 items-center justify-center rounded-sm text-theme-blue-dark ring-inset',
        'transition-all duration-200 ease-out',
        'focus-visible:ring-inset',
        disabled && 'cursor-not-allowed opacity-50',
        !disabled && 'hover:bg-gray-100 hover:ring-1 hover:ring-gray-300 active:bg-gray-200',
        active && 'bg-gray-100 ring-1 ring-gray-300'
      )}
      disabled={disabled}
      aria-current={active ? 'page' : undefined}
      {...props}
    >
      {children}
    </Component>
  );
};

Pagination.NextIcon = function NextIcon() {
  return <RegularChevronRight size={16} />;
};

Pagination.PreviousIcon = function PreviousIcon() {
  return <RegularChevronRight size={16} className="rotate-180" />;
};
