import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button, ButtonProps } from '@/components';

interface SubNavigationProps {
  ariaLabel: string;
  children: ReactNode;
}

interface SubNavigationHeaderProps {
  children: ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

interface SubNavigationListProps {
  children: ReactNode;
}

type SubNavigationButtonProps = ButtonProps;

export const SubNavigation = ({ ariaLabel, children }: SubNavigationProps) => {
  return <nav aria-label={ariaLabel}>{children}</nav>;
};

SubNavigation.Header = function Header({ children, as = 'h3' }: SubNavigationHeaderProps) {
  const As = as;
  return (
    <As className="mb-4 mt-8 text-lg font-medium leading-6 text-theme-blue first:mt-0">
      {children}
    </As>
  );
};

SubNavigation.List = function List({ children }: SubNavigationListProps) {
  return <ul className="space-y-1">{children}</ul>;
};

SubNavigation.Button = function SubNavigationButton({
  className,
  ...props
}: SubNavigationButtonProps) {
  return (
    <li>
      <Button
        className={twMerge('align-left w-full', className)}
        variant="transparent"
        {...props}
      />
    </li>
  );
};
