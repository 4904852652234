import { useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { twMerge } from 'tailwind-merge';

import { TextButton } from '@/components';
import { SolidCircleInfo } from '@/icons/components';
import { useElementSize } from '@/utils';

export interface InfoCardProps {
  className?: string;
  description: string;
  testId: string;
}

export const InfoCard = ({ description, testId, className }: InfoCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const [containerRef, containerSize, updateContainerSize] = useElementSize();
  const [contentRef, contentSize, updateContentSize] = useElementSize();

  const isOverflowing = contentSize.height > containerSize.height;

  useEffect(() => {
    updateContainerSize();
    updateContentSize();
  }, [isOpen, updateContainerSize, updateContentSize, description]);

  const close = () => {
    setIsOpen(false);

    // Focus the open button after 10ms to make sure it's rendered
    setTimeout(() => {
      openButtonRef.current?.focus();
    }, 10);
  };

  const open = () => {
    setIsOpen(true);

    // Focus the close button after 10ms to make sure it's rendered
    setTimeout(() => {
      closeButtonRef.current?.focus();
    }, 10);
  };

  return (
    <div className={twMerge('flex bg-theme-blue/10 px-2 py-1 text-sm', className)}>
      <div className="mr-2 flex h-7 items-center text-theme-blue">
        <SolidCircleInfo size={12} />
      </div>

      <div className={twJoin('w-full py-1', !isOpen && 'h-7 overflow-hidden')} ref={containerRef}>
        <div
          className="richtext text-gray-700"
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {isOpen && (
          <div className="mt-4">
            <TextButton
              ref={closeButtonRef}
              onKeyDown={(e) => {
                if (e.key === 'Escape') close();
              }}
              onClick={close}
              data-testid={`${testId}-less`}
            >
              Lees minder
            </TextButton>
          </div>
        )}
      </div>

      {isOverflowing && !isOpen && (
        <div className="shrink-0 py-1">
          <TextButton data-testid={`${testId}-more`} onClick={open} ref={openButtonRef}>
            Lees meer
          </TextButton>
        </div>
      )}
    </div>
  );
};
