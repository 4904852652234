import { useForm } from 'react-hook-form';

import { NewBesluitProps } from '@/api/queries/besluit';
import { useBesluitType } from '@/api/queries/besluitType';
import { Input, Modal, Select, TextArea, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils/modal';

export type BesluitEigenschappenProps = {
  title: string;
  defaultValues?: Partial<NewBesluitProps>;
  submitLabel: string;
  submitSuccessLabel: string;
  disabledBesluitType?: boolean;
  hasVigerendBesluit: boolean;
  onSubmit: (formValues: NewBesluitProps) => Promise<void>;
};

export const BESLUIT_EIGENSCHAPPEN_ID = 'besluitEigenschappen';

export const BesluitEigenschappen = modal(BESLUIT_EIGENSCHAPPEN_ID, ({ data, props }) => {
  const {
    title,
    defaultValues,
    submitLabel,
    submitSuccessLabel,
    disabledBesluitType,
    hasVigerendBesluit,
    onSubmit,
  } = data;

  const modalState = useModalState();

  const besluitTypes = useBesluitType({});

  const filteredBesluitTypes = besluitTypes.data?.filter(
    (besluittype) =>
      !besluittype.Wijziging_zonder_besluit &&
      (hasVigerendBesluit ? !besluittype.Initieel : besluittype.Initieel)
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewBesluitProps>({
    defaultValues: defaultValues ?? undefined,
    mode: 'onChange',
  });

  return (
    <Modal {...props} state={modalState.state} isLoading={besluitTypes.isLoading} title={title}>
      <form
        className="mt-4 w-full text-gray-900"
        onSubmit={handleSubmit((formData) => {
          modalState.action('save');

          onSubmit(formData)
            .then(() => modalState.success('save'))
            .catch((error) => {
              const errorMessage =
                error instanceof Error && ErrorMessage.getLocalErrorMessage(error);

              if (errorMessage)
                modalState.error('save', {
                  title: 'Mislukt',
                  description: errorMessage,
                });
            });
        })}
      >
        <Select
          id="type"
          {...register('Type', {
            required: eStrings.TXT_SELECT_BESLUITTYPE,
          })}
          label={eStrings.LBL_TYPE_BESLUIT}
          placeholder="Type"
          error={errors.Type}
          defaultValue=""
          disabled={disabledBesluitType}
          required
          horizontal
        >
          <option disabled value="">
            {eStrings.TXT_BESLUIT_TYPES_SELECTEREN}
          </option>

          {filteredBesluitTypes?.map((besluitType) => (
            <option key={`besluit-type-option-${besluitType.ID}`} value={besluitType.ID}>
              {besluitType.Naam}
            </option>
          ))}
        </Select>

        <Input
          id="OLO_Nummer"
          {...register('OLO_Nummer')}
          label={eStrings.LBL_OLO_NUMMER}
          placeholder={eStrings.LBL_OLO_NUMMER}
          autoComplete="off"
          error={errors.OLO_Nummer}
          className="mt-2"
          maxLength={100}
          horizontal
        />

        <Input
          id="Zaaknummer"
          {...register('Zaaknummer', { required: eStrings.TXT_VUL_ZAAKNUMMER })}
          label={eStrings.LBL_ZAAKNUMMER}
          placeholder={eStrings.LBL_ZAAKNUMMER}
          error={errors.Zaaknummer}
          className="mt-2"
          maxLength={100}
          horizontal
          required
        />

        <Input
          id="Ons_Kenmerk"
          {...register('Ons_Kenmerk')}
          label={eStrings.LBL_BESLUIT_KENMERK}
          placeholder={eStrings.LBL_BESLUIT_KENMERK}
          autoComplete="off"
          className="mt-2"
          maxLength={100}
          horizontal
        />

        <Select
          id="Ontwerp_Beschikking"
          {...register('Ontwerp_Beschikking', { required: eStrings.TXT_SELECT_STATUS })}
          error={errors.Ontwerp_Beschikking}
          label={eStrings.LBL_STATUS}
          className="mt-2"
          defaultValue=""
          required
          horizontal
        >
          <option disabled hidden value="">
            {eStrings.TXT_SELECT_STATUS}
          </option>

          <option value={0}>Beschikking</option>
          <option value={1}>Ontwerpbeschikking</option>
        </Select>

        <TextArea
          {...register('Korte_Omschrijving')}
          id="Korte_Omschrijving"
          label={eStrings.LBL_BESLUIT_OMSCHRIJVING}
          className="mt-2"
          horizontal
        />

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="save"
            successLabel={submitSuccessLabel}
            type="submit"
            tabIndex={0}
          >
            {submitLabel}
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
