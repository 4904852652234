import { useDeleteVoorschriftId } from '@/api/queries/objects';
import { Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface UndoIntrekkenVoorschriftProps {
  nummer: string;
  id: string;
}

export const UNDO_INTREKKEN_VOORSCHRIFT_ID = 'undoIntrekkenVoorschrift';

export const UndoIntrekkenVoorschrift = modal(UNDO_INTREKKEN_VOORSCHRIFT_ID, ({ data, props }) => {
  const { nummer, id } = data;

  const deleteVoorschriftId = useDeleteVoorschriftId();

  const modalState = useModalState();

  const handleUndo = () => {
    modalState.action('save');

    deleteVoorschriftId.mutate(
      { id },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) =>
          modalState.error('save', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
          }),
      }
    );
  };

  return (
    <Modal state={modalState.state} title="Intrekken ongedaan maken" {...props}>
      <p>
        <strong>Voorschift {nummer}</strong> is ingetrokken, wil je deze handeling ongedaan maken?
      </p>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="save"
          onClick={handleUndo}
          successLabel="Intrekken ongedaan gemaakt"
        >
          Intrekken ongedaan maken
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
