export type Environments =
  | 'local'
  | 'development'
  | 'test'
  | 'acceptation'
  | 'droogoefen'
  | 'production'
  | 'DCMR_prod'
  | 'OZHZ_prod'
  | 'TMP_prod';

class Environment {
  LOCALHOST_URL = '127.0.0.1';
  DEV_URL = 'staadvnextdev.z6.web.core.windows.net';
  TEST_URL = 'staadvnexttst.z6.web.core.windows.net';
  ACC_URL = 'staadvnextacc.z6.web.core.windows.net';
  OZHZ_PROD_URL = 'digivozhz.z6.web.core.windows.net';
  DCMR_PROD_URL = 'digivdcmr.z6.web.core.windows.net';
  TMP_PROD_URL = 'staadvnextmt.z6.web.core.windows.net';

  // TODO: Add droogoefen url
  DROOGOEFEN_URL = 'placeholder-droogoefen';
  // TODO: Add import url
  IMPORT_URL = 'placeholder-import';

  DOMAINS: Record<string, Environments> = {
    [this.TMP_PROD_URL]: 'TMP_prod',
    [this.OZHZ_PROD_URL]: 'OZHZ_prod',
    [this.DCMR_PROD_URL]: 'DCMR_prod',
    [this.DROOGOEFEN_URL]: 'droogoefen',
    [this.ACC_URL]: 'acceptation',
    [this.TEST_URL]: 'test',
    [this.DEV_URL]: 'development',
    [this.IMPORT_URL]: 'production',
    [this.LOCALHOST_URL]: 'local',
    localhost: 'local',
  };

  get environment(): Environments {
    const environment = this.DOMAINS[window.location.hostname];

    if (!environment) {
      throw new Error(`No environment found for hostname ${window.location.hostname}`);
    }

    return environment;
  }

  get isLocal(): boolean {
    return this.environment === 'local';
  }

  get isDevelopment(): boolean {
    return this.environment === 'development';
  }

  get isTest(): boolean {
    return this.environment === 'test';
  }

  get isAcceptation(): boolean {
    return this.environment === 'acceptation';
  }

  get isDroogoefen(): boolean {
    return this.environment === 'droogoefen';
  }

  get isProduction(): boolean {
    return this.environment === 'production';
  }

  get isDcmrProd(): boolean {
    return this.environment === 'DCMR_prod';
  }

  get isOzhzProd(): boolean {
    return this.environment === 'OZHZ_prod';
  }

  get isTmpProd(): boolean {
    return this.environment === 'TMP_prod';
  }
}

const environment = new Environment();

export { environment };
