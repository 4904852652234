import {
  getFixLineageBedrijf,
  getFixLineageParagraafId,
  getFixParagraaf,
  getFixParagraafId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useParagraaf = createQuery({
  key: ['paragraaf'],
  queryFn: getFixParagraaf,
});

export const useParagraafId = createQuery({
  key: ['paragraafId'],
  queryFn: getFixParagraafId,
});

export const useLineageParagraaf = createQuery({
  key: ['lineageParagraaf'],
  queryFn: getFixLineageBedrijf,
});

export const useLineageParagraafId = createQuery({
  key: ['lineageParagraafId'],
  queryFn: getFixLineageParagraafId,
});
