import {
  TextEditor,
  TextEditorCustomOption,
  TextEditorMenuOption,
  TextEditorProps,
} from '@/components';

import { EditorContainer } from '../-components/EditorContainer';
import { useEditorStore } from '../-store/useEditorStore';

export interface ContentTemplateProps {
  id: string;
  nummer?: string;
  title: string;
  defaultValue: string;
  placeholder?: string;
  onUpdate: (value: string) => void;
  menuOptions?: TextEditorMenuOption[];
  customOptions?: TextEditorCustomOption[];
  editorRef?: TextEditorProps['editorRef'];
}

export const ContentTemplate = ({
  title,
  nummer,
  defaultValue,
  id,
  onUpdate,
  placeholder = 'Typ hier de tekst',
  menuOptions = [
    'p',
    'h1',
    'h2',
    'bold',
    'italic',
    'underline',
    'orderedList',
    'abcList',
    'bulletList',
    'subscript',
    'superscript',
    'highlight',
    'table',
  ],
  customOptions,
  editorRef,
}: ContentTemplateProps) => {
  const contentMinHeight = useEditorStore((state) => state.contentMinHeight);

  return (
    <EditorContainer nummer={nummer} title={title} ariaLabel={title}>
      <TextEditor
        id={id}
        variant="clean"
        menuPosition="inline"
        placeholder={placeholder}
        minHeight={contentMinHeight}
        menuOptions={menuOptions}
        customOptions={customOptions}
        initialText={defaultValue}
        onUpdate={onUpdate}
        editorRef={editorRef}
      />
    </EditorContainer>
  );
};
