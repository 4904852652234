import { useDeleteHoofdstukId } from '@/api/queries/objects';
import { Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface UndoIntrekkenHoofdstukProps {
  nummer: number;
  id: string;
  onSuccess?: () => void;
}

export const UNDO_INTREKKEN_HOOFDSTUK_ID = 'undoIntrekkenHoofdstuk';

export const UndoIntrekkenHoofdstuk = modal(UNDO_INTREKKEN_HOOFDSTUK_ID, ({ data, props }) => {
  const { nummer, id, onSuccess } = data;

  const deleteHoofdstukId = useDeleteHoofdstukId();

  const modalState = useModalState();

  const handleUndo = () => {
    modalState.action('save');

    deleteHoofdstukId.mutate(
      { id },
      {
        onSuccess: () => {
          modalState.success('save');
          onSuccess?.();
        },
        onError: (error) =>
          modalState.error('save', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
          }),
      }
    );
  };

  return (
    <Modal state={modalState.state} title="Intrekken ongedaan maken" {...props}>
      <p>
        <strong>Hoofdstuk {nummer}</strong> is ingetrokken, wil je deze handeling ongedaan maken?
      </p>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="save"
          onClick={handleUndo}
          successLabel="Intrekken ongedaan gemaakt"
        >
          Intrekken ongedaan maken
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
