import { LabelMapSchema } from '@/api';
import { useDeleteLabelMapId } from '@/api/queries/label';
import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';

export interface BeheerLabelMapDeleteProps {
  map: LabelMapSchema;
}

export const BEHEER_LABEL_MAP_DELETE_ID = 'beheerLabelMapDelete';

export const BeheerLabelMapDelete = modal(BEHEER_LABEL_MAP_DELETE_ID, ({ data, props }) => {
  const { map } = data;

  const modalState = useModalState();

  const deleteMapId = useDeleteLabelMapId();

  const onSubmit = () => {
    modalState.action('delete');

    deleteMapId.mutate(
      {
        id: map.ID!,
      },
      {
        onSuccess: () => modalState.success('delete'),
        onError: (error) => {
          const errorMessage = ErrorMessage.getLocalErrorMessage(error);

          if (errorMessage) {
            modalState.error('delete', { title: 'Mislukt', description: errorMessage });
          }
        },
      }
    );
  };

  return (
    <Modal {...props} state={modalState.state} title="Verwijderen">
      <p>
        Je staat op het punt om {map.Naam} te verwijderen, deze actie kan niet worden teruggedraaid.
      </p>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="delete"
          variant="red"
          successLabel="Verwijderd"
          onClick={onSubmit}
        >
          Verwijder
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
