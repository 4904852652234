import { LabelSchema } from '@/api';
import { Explorer } from '@/components';
import { useOpenModal } from '@/modals/utils';

interface ExplorerButtonLabelProps {
  label: LabelSchema;
}

export const ExplorerButtonLabel = ({ label }: ExplorerButtonLabelProps) => {
  const openModal = useOpenModal();

  return (
    <Explorer.Button
      asChild
      icon="label"
      inactive={label.Status !== 'Actief'}
      options={[
        {
          title: 'Wijzigen',
          variant: 'button',
          onClick: () => {
            openModal('beheerLabel', {
              updateLabel: { label },
            });
          },
        },
        {
          title: label.Status === 'Actief' ? 'Archiveren' : 'Herstellen',
          variant: 'button',
          onClick: () => {
            openModal('beheerLabelStatus', {
              label,
              newStatus: label.Status === 'Actief' ? 'Archief' : 'Actief',
            });
          },
        },
      ]}
    >
      <div>{label.Label}</div>
    </Explorer.Button>
  );
};
