import {
  postFixHoofdstuk,
  postFixLineageHoofdstuk,
  postFixLinkHb,
  postFixMetaOverwegingHoofdstuk,
  postOthHoofdstukReservering,
} from '@/api/generated/digiVAPI';
import {
  HBSchema,
  HoofdstukLineageSchema,
  HoofdstukReserveringSchema,
  HoofdstukSchema,
  OverwegingHoofdstukSchema,
} from '@/api/generated/digiVAPI.schemas';

import { CreateHoofdstukkenProps } from '../useCreateObjects';
import { CreateParagraafResponse, createParagrafen } from './createParagraaf';
import { CreateSubparagraafResponse } from './createSubparagraaf';
import { CreateVoorschriftResponse } from './createVoorschrift';

export type CreateHoofdstukResponse = {
  lineageHoofdstuk: HoofdstukLineageSchema;
  hoofdstuk: HoofdstukSchema;
  hb: HBSchema;
  hoofdstukReservering: HoofdstukReserveringSchema;
  overwegingHoofdstuk: OverwegingHoofdstukSchema;
};

export async function createHoofdstukken({
  besluitId,
  besluitLineageId,
  hoofdstukken,
  milieuGrondslagId,
  userId,
}: CreateHoofdstukkenProps & { milieuGrondslagId: string; besluitLineageId: string }) {
  const hoofdstukResponses: CreateHoofdstukResponse[] = [];
  const paragraafResponses: CreateParagraafResponse[] = [];
  const subparagraafResponses: CreateSubparagraafResponse[] = [];
  const voorschriftResponses: CreateVoorschriftResponse[] = [];

  for (const hoofdstuk of hoofdstukken) {
    const hoofdstukResponse = await createHoofdstuk({
      milieuGrondslagId,
      besluitId,
      besluitLineageId,
      userId,
      titel: hoofdstuk.titel,
      overweging: hoofdstuk.overweging,
    });

    if (hoofdstuk.paragrafen?.length) {
      const { paragraafResponses, subparagraafResponses, voorschriftResponses } =
        await createParagrafen({
          besluitId,
          besluitLineageId,
          hoofdstukId: hoofdstukResponse.hoofdstuk.ID!,
          milieuGrondslagId,
          paragrafen: hoofdstuk.paragrafen,
          type: 'paragrafen',
        });

      paragraafResponses.push(...paragraafResponses);
      subparagraafResponses.push(...subparagraafResponses);
      voorschriftResponses.push(...voorschriftResponses);
    }

    hoofdstukResponses.push(hoofdstukResponse);
  }

  return {
    hoofdstukResponses,
    paragraafResponses,
    subparagraafResponses,
    voorschriftResponses,
  };
}

export const createHoofdstuk = async ({
  titel,
  overweging = '',
  milieuGrondslagId,
  besluitId,
  besluitLineageId,
  userId,
}: {
  milieuGrondslagId: string;
  titel: string;
  overweging?: string;
  besluitId: string;
  besluitLineageId: string;
  userId: string;
}): Promise<CreateHoofdstukResponse> => {
  /**
   * Create lineage hoofdstuk
   */
  const lineageHoofdstuk = await postFixLineageHoofdstuk({
    Besluit_lineage: besluitLineageId,
  });

  if (!lineageHoofdstuk.ID) {
    throw new Error('No lineage hoofdstuk ID');
  }

  /**
   * Post a reservering for the newly created hoofdstuk
   */
  const hoofdstukReservering = await postOthHoofdstukReservering({
    Gebruiker: userId,
    Reservering_lineage: lineageHoofdstuk.ID,
    // Will be overwritten by the server
    Geldig_Tot: new Date().toISOString().slice(0, 19),
  });

  /**
   * Create hoofdstuk
   */
  const hoofdstuk = await postFixHoofdstuk({
    Titel: titel,
    Grondslag: milieuGrondslagId,
    // Passing 0 will trigger auto numbering in the BE
    Nummer: 0,
    Ontwerp: 1,
    Stop_Lineage: 0,
    Lineage: lineageHoofdstuk.ID,
    Index: 0,
  });

  if (!hoofdstuk.ID) {
    throw new Error('No hoofdstuk ID');
  }

  /**
   * Create Overweging hoofdstuk
   */
  const overwegingHoofdstuk = await postFixMetaOverwegingHoofdstuk({
    InhoudelijkeOverweging: overweging,
    Besluit: besluitId,
    Hoofdstuk_lineage: lineageHoofdstuk.ID,
  });

  if (!overwegingHoofdstuk?.ID) {
    throw new Error('No overweging hoofdstuk ID');
  }

  /**
   * Create HB link
   */
  const hb = await postFixLinkHb({
    Besluit: besluitId,
    Hoofdstuk: hoofdstuk.ID,
    // TODO: Fix rang
    Rang: `${new Date().getTime()}`,
    Type: 'Eigen',
  });

  return {
    hoofdstuk,
    lineageHoofdstuk,
    hb,
    hoofdstukReservering,
    overwegingHoofdstuk,
  };
};
