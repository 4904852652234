import { useDebouncedCallback } from '@react-hookz/web';
import Tippy from '@tippyjs/react';
import { useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { twJoin } from 'tailwind-merge';

import {
  getLastCreatedObject,
  useParagraafId,
  usePatchSubparagraafId,
} from '@/api/queries/objects';
import { PillButton, TextEditor } from '@/components';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { RegularTrash, SolidClockRotateLeft } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from '../EditorNavigation';
import { ObjectRow } from '../ObjectRow/ObjectRow';
import { useSubparagraafContext } from './SubparagraafContext';

const unwrapValue = (value: string) => {
  const element = document.createElement('div');

  element.innerHTML = value;

  const inner = element.querySelector('p');

  return inner?.innerHTML;
};

export const SubparagraafInput = ({ dragHandleRef }: { dragHandleRef?: ConnectDragSource }) => {
  const { link, subparagraaf, objectState, userHasReservering } = useSubparagraafContext();
  const openModal = useOpenModal();

  const id = link.SPPB.Subparagraaf;

  const [error, setError] = useState<string | undefined>();

  const { data: isParentIngetrokken } = useParagraafId(link.SPPB.Paragraaf, {
    select: ({ Stop_Lineage }) => !!Stop_Lineage,
  });

  const patchSubparagraafId = usePatchSubparagraafId({ mutationKey: AUTOSAVE_MUTATION_KEY });
  const patchSubparagraafIdDebounced = useDebouncedCallback(
    (value: string) => {
      const unwrappedValue = unwrapValue(value);

      if (unwrappedValue === subparagraaf.Titel || objectState.state === 'vigerend') return;

      patchSubparagraafId.mutate(
        {
          id,
          data: {
            Titel: unwrappedValue ?? '',
          },
        },
        {
          onSuccess: () => setError(undefined),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            setError(errorMessage ?? 'Er is iets mis gegaan.');
          },
        }
      );
    },
    [patchSubparagraafId.mutate, objectState.state, subparagraaf.Titel],
    500
  );

  const containsSlotObject = link.voorschriften.some(({ VSPB: { Type } }) => Type === 'MBIO');

  const canIntrekken =
    userHasReservering &&
    objectState.state === 'vigerend' &&
    !objectState.slotState &&
    !containsSlotObject;
  const canBeDeleted = objectState.isEditable && objectState.state === 'new';
  const isDraggable = objectState.isEditable && objectState.state === 'new';

  return (
    <>
      <ObjectRow
        id={id}
        lineageId={subparagraaf.Lineage}
        objectState={objectState}
        type="subparagraaf"
        hasError={!!error}
      >
        <ObjectRow.State state={objectState.state} />

        {isDraggable && dragHandleRef && <ObjectRow.DragHandle dragHandleRef={dragHandleRef} />}

        <ObjectRow.Body>
          <div className="w-full">
            <TextEditor
              editable={objectState.isEditable}
              deleted={objectState.state === 'deleted'}
              menuOptions={['superscript', 'subscript', 'highlight']}
              initialText={subparagraaf.Titel}
              id={id}
              testId="texteditor"
              variant="clean"
              placeholder="Voer de titel van de subparagraaf in"
              singleLine
              classes={{
                default: twJoin(
                  'w-full text-lg italic text-gray-600 ring-inset transition-all duration-100 ease-in',
                  'px-1 py-0.5',
                  !error && 'hover:ring-1 hover:ring-gray-400',
                  error && 'ring-1 ring-digi-v-color-danger hover:ring-digi-v-color-danger'
                ),
                focused: 'ring-1 ring-gray-400 bg-white',
              }}
              onUpdate={patchSubparagraafIdDebounced}
              onCreate={({ editor }) => {
                const lastCreatedObject = getLastCreatedObject();

                const isNew = lastCreatedObject?.Lineage === subparagraaf.Lineage;

                if (isNew && !subparagraaf.Titel) {
                  // There's race condition in TipTap where the editor is created but methods can't be called on it.
                  // This is a temporary workaround to focus the editor after it's created.
                  setTimeout(() => editor?.commands.focus(), 50);
                }
              }}
            />
          </div>
        </ObjectRow.Body>

        {canBeDeleted && (
          <ObjectRow.Icon
            icon={RegularTrash}
            className="text-digi-v-color-danger"
            onClick={() => openModal('deleteSubparagraaf', { subparagraafLink: link })}
            testId="delete"
          />
        )}

        {userHasReservering && objectState.state === 'deleted' && !objectState.slotState && (
          <ObjectRow.ButtonOverlay>
            {isParentIngetrokken ? (
              <Tippy content="Het is niet mogelijk om het intrekken ongedaan te maken omdat bovenliggend object ook is ingetrokken">
                <div>
                  <PillButton variant="green" disabled icon={SolidClockRotateLeft}>
                    Intrekken ongedaan maken
                  </PillButton>
                </div>
              </Tippy>
            ) : (
              <PillButton
                variant="green"
                onClick={() =>
                  openModal('undoIntrekkenSubparagraaf', { id, title: subparagraaf.Titel })
                }
                icon={SolidClockRotateLeft}
              >
                Intrekken ongedaan maken
              </PillButton>
            )}
          </ObjectRow.ButtonOverlay>
        )}

        {canIntrekken && (
          <ObjectRow.ButtonOverlay>
            <PillButton
              variant="white"
              className="shadow-md"
              onClick={() => {
                openModal('intrekkenSubparagraaf', {
                  link,
                  subparagraaf,
                });
              }}
            >
              Subparagraaf intrekken
            </PillButton>
          </ObjectRow.ButtonOverlay>
        )}
      </ObjectRow>

      {error && <WarningInline description={error} center />}
    </>
  );
};
