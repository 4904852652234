import { Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { BubbleMenu, EditorContent, Extensions, useEditor } from '@tiptap/react';
import {
  HTMLProps,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldError } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

import { FormError } from '@/components';
import { IconComponent } from '@/icons/iconTypes';

import { TextEditorMenuBar } from './components/TextEditorMenuBar';
import { CONFIG, TextEditorMenuOption } from './config/config';
import { TextEditorContext, useTextEditorProvider } from './context/useTextEditor';
import { SanitisePastedHtml } from './extensions/SanitisePastedHtml';
import { SingleLine } from './extensions/SingleLine';

export interface TextEditorCustomOption {
  label?: string;
  icon?: IconComponent;
  ariaLabel: string;
  testId?: string;
  onClick: (e: SyntheticEvent<HTMLButtonElement>, editor: Editor) => void;
  attributes?: Omit<HTMLProps<HTMLButtonElement>, 'onClick'>;
}

export interface TextEditorProps {
  /**
   * When id is updated, the initial content and event handlers are updated inside of the editor.
   **/
  id: string;
  /** Will replace the default `text-editor` testId */
  testId?: string;
  /** Sets the initial text of the editor. To update editor with new initialText, change the `id` property */
  initialText?: string | null;
  /** Disables the use of Enter key */
  singleLine?: boolean;
  /** Applies disabled styling and disables the editor */
  editable?: boolean;
  /** Applies deleted styling */
  deleted?: boolean;
  /** Applies the focus styling */
  focused?: boolean;
  /** Different variants */
  variant?: 'default' | 'clean' | 'input';
  /** Overwrite classes, note: Use in combination with `clean` variant. */
  classes?: {
    default?: string;
    focused?: string;
    unfocused?: string;
  };
  /** Error coming from `react-hook-form` */
  error?: FieldError | string;
  /** Sets the position of the menu */
  menuPosition?: 'top' | 'bottom' | 'inline';
  /** Placeholder text */
  placeholder?: string;
  /** Is called when the text is updated */
  onUpdate: (value: string) => void;
  /** Is called when the editor is clicked */
  onClick?: () => void;
  /** Callback for when the editor instance is created */
  onCreate?: (props: { editor: Editor }) => void;
  /** List of menu options that should be enabled */
  menuOptions: TextEditorMenuOption[];
  /** Custom options */
  customOptions?: TextEditorCustomOption[];
  /** Set a minHeight for the Editor */
  minHeight?: number | null;
  /** Ref to store the editor */
  editorRef?: React.MutableRefObject<Editor | null>;
}

export const TextEditor = ({
  id,
  testId,
  initialText,
  singleLine,
  editable = true,
  deleted,
  focused,
  variant = 'default',
  classes,
  error,
  menuPosition = 'top',
  onUpdate,
  onClick,
  onCreate,
  menuOptions,
  customOptions,
  placeholder,
  minHeight,
  editorRef,
}: TextEditorProps) => {
  const previousContent = useRef<string>(initialText || '');
  const [lastNodeIsTable, setLastNodeIsTable] = useState<boolean>(false);

  const isEditable = useMemo(() => {
    if (!editable || deleted) {
      return false;
    } else {
      return true;
    }
  }, [editable, deleted]);

  const editor = useEditor({
    extensions: getEditorExtensions(),
    content: initialText,
    onUpdate({ editor }) {
      handleUpdate(editor);
    },
    onCreate: (props) => onCreate?.(props),
  });

  /**
   * Store the editor in a passed ref
   */
  if (editorRef) editorRef.current = editor;

  const textEditorState = useTextEditorProvider();

  /**
   * Update handler
   */
  const handleUpdate = useCallback(
    (editor: Editor) => {
      const html = editor.getHTML();

      // When the editor is empty, it still returns <p></p>.
      // We need to make sure we return an empty string in that case.
      const newContent = html === '<p></p>' ? '' : html;

      const elements = document.createElement('div');
      elements.innerHTML = html;
      setLastNodeIsTable(elements.lastElementChild?.tagName === 'TABLE');

      // When there's no change in content, do not call onUpdate for performance reasons.
      if (newContent === previousContent.current) return;

      previousContent.current = newContent;

      onUpdate(newContent);
    },
    [onUpdate]
  );

  useEffect(() => {
    if (!editor) return;

    editor.commands.setContent(initialText || '');

    // Set content with current HTML, this removes spaces at the start or end of a line.
    // Without this, empty spaces in the text are published and removed in the next new besluit causing unwanted changes.
    editor.off('blur');
    editor.on('blur', ({ editor }) => {
      editor.commands.setContent(editor.getHTML(), true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, editor]);

  useEffect(() => {
    if (!editor) return;

    editor.setEditable(isEditable);
  }, [editor, isEditable]);

  function getEditorExtensions(): Extensions {
    const extensions: Extensions = [
      Document,
      Paragraph,
      Text,
      Gapcursor,
      History,
      Dropcursor,
      SanitisePastedHtml,
      Placeholder.configure({
        placeholder,
        showOnlyWhenEditable: false,
        showOnlyCurrent: false,
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
    ];

    if (singleLine) {
      extensions.push(SingleLine);
    } else {
      extensions.push(HardBreak);
    }

    /**
     * Add extension from the config.
     * There might be dupblicate extensions, so we need to filter those out.
     */
    CONFIG.forEach((config, option) => {
      if (menuOptions.includes(option) && config.extensions) {
        const configExtensions = config.extensions();

        configExtensions.forEach((configExtension) => {
          const extensionExists = extensions.some(({ name }) => name === configExtension.name);

          if (!extensionExists) extensions.push(configExtension);
        });
      }
    });

    return extensions;
  }

  // When the dropdown has focus, the editor does not.
  // We need to make sure the rest of the editor behaves like its in a focused state
  const isFocused = textEditorState.dropdownIsOpen || editor?.isFocused || focused;

  const errorId = `${id}-error`;

  let editorClasses = '';

  switch (variant) {
    case 'default':
      editorClasses = twJoin(
        'rounded-sm p-2 ring-1',
        !isFocused && 'ring-gray-800',
        (isFocused || focused) && 'ring-digi-v-color-info'
      );
      break;
    case 'clean':
      editorClasses = twJoin(
        classes?.default,
        isFocused && classes?.focused ? classes?.focused : classes?.unfocused
      );
      break;
    case 'input':
      editorClasses = twJoin(
        'form-input-custom rich-text transition-all duration-150',
        isFocused && error && 'ring ring-digi-v-color-danger/30',
        isFocused && !error && 'border-blue-500/60 ring ring-blue-500/30',
        !isFocused && error && 'border-6 border-solid border-digi-v-color-danger'
      );
  }

  return (
    <TextEditorContext.Provider value={textEditorState}>
      <div className="text-editor relative flex" data-testid={testId || 'text-editor'}>
        <div
          className={twJoin(
            'w-full',
            !isEditable && 'pointer-events-none',
            deleted && 'line-through opacity-50'
          )}
        >
          <EditorContent
            data-testid={testId ? `${testId}-content` : 'text-editor-content'}
            editor={editor}
            className={twJoin(
              editorClasses,
              error && 'border-6 border-solid border-digi-v-color-danger',
              error && isFocused && 'border-digi-v-color-danger ring ring-digi-v-color-danger/30',
              // When the last node is a table, we need to add some padding to the bottom
              // Otherwise the user will not be able to click and select the next line
              lastNodeIsTable && '[&>.ProseMirror]:pb-6'
            )}
            onClick={onClick}
            style={{
              minHeight: minHeight ? `${minHeight}px` : 'auto',
            }}
          />
        </div>
        <div>
          {editor && menuPosition === 'inline' && editable && (
            <BubbleMenu
              tippyOptions={{
                arrow: false,
                maxWidth: 2000,
                zIndex: 3,
              }}
              editor={editor}
              shouldShow={() => editor.isFocused}
            >
              <TextEditorMenuBar
                data-testid={testId ? `${testId}-menu` : 'text-editor-menu'}
                id={id}
                editor={editor}
                menuOptions={menuOptions}
                customOptions={customOptions}
              />
            </BubbleMenu>
          )}

          {editor && isFocused && editable && menuPosition !== 'inline' && (
            <div
              className={twJoin(
                'absolute left-2 z-[3]',
                menuPosition === 'top' && 'bottom-full',
                menuPosition === 'bottom' && 'top-full'
              )}
            >
              <TextEditorMenuBar
                data-testid={testId ? `${testId}-menu` : 'text-editor-menu'}
                id={id}
                editor={editor}
                menuOptions={menuOptions}
                customOptions={customOptions}
              />
            </div>
          )}
        </div>
      </div>
      {error && (
        <FormError id={errorId}>{typeof error === 'string' ? error : error.message}</FormError>
      )}
    </TextEditorContext.Provider>
  );
};
