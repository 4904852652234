import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';

export type StandaardtekstenVerwijderenSectieProps = {
  sectieNaam?: string;
};

export const STANDAARDTEKSTEN_VERWIJDEREN_SECTIE_ID = 'standaardtekstenVerwijderenSectie';

export const StandaardtekstenVerwijderenSectie = modal(
  STANDAARDTEKSTEN_VERWIJDEREN_SECTIE_ID,
  ({ data, props }) => {
    const modalState = useModalState();

    const { sectieNaam } = data;

    const verwijderSectie = () => {
      console.log('Sectie verwijderd');
    };

    return (
      <Modal {...props} state={modalState.state} title="Sectie verwijderen">
        <p className="text-gray-800">
          Je staat op het punt om de sectie {sectieNaam} te verwijderen
        </p>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="delete"
            successLabel="Verwijderd"
            variant="red"
            onClick={verwijderSectie}
          >
            Verwijderen
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
