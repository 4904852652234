import { InputHTMLAttributes, forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

export interface WizardCheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'type' | 'children'> {
  number?: string;
  content: string;
  type?: 'voorschrift' | 'subparagraaf' | 'paragraaf';
  minusIcon?: boolean;
}

export const WizardCheckbox = forwardRef<HTMLInputElement, WizardCheckboxProps>(
  ({ id, name, number, content, type, minusIcon, disabled, ...props }, ref) => {
    return (
      <div className="group relative mb-px flex w-full">
        <input
          className={twJoin(
            'form-checkbox-blue peer absolute left-2 top-3',
            minusIcon && 'form-checkbox-base-minimal',
            disabled ? 'cursor-not-allowed bg-gray-300' : 'cursor-pointer'
          )}
          type="checkbox"
          id={id}
          name={name}
          ref={ref}
          disabled={disabled}
          {...props}
        />

        <label
          htmlFor={id}
          className={twJoin(
            'mr-px flex w-full py-[7px] pl-11',
            type === 'paragraaf' && 'text-xl font-semibold text-theme-blue',
            type === 'subparagraaf' && 'text-lg italic text-gray-800',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            !disabled && 'group-hover:ring-1 group-hover:ring-digi-v-gray',
            'peer-checked:ring-1 peer-checked:ring-digi-v-color-info',
            'peer-focus-visible:ring-2 peer-focus-visible:ring-gray-400'
          )}
        >
          {number && <span className="mr-2 w-16 shrink-0 grow-0">{number}</span>}

          <span
            className="richtext w-full"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </label>
      </div>
    );
  }
);

export const WizardCheckboxStatic = ({
  type,
  number,
  content,
}: Pick<WizardCheckboxProps, 'type' | 'number' | 'content'>) => {
  return (
    <div className="group relative mb-px flex w-full">
      <div
        className={twJoin(
          'mr-[1px] flex w-full py-[7px] pl-11',
          type === 'paragraaf' && 'text-xl font-semibold text-theme-blue'
        )}
      >
        {number && <span className="mr-2 w-16 shrink-0 grow-0">{number}</span>}

        <span
          className="richtext w-full"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  );
};
