import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDVoorschriftSchema } from '@/api';
import { StdSubparagraaf, useCreateStdVoorschrift } from '@/api/queries/std';
import { FormError, FormLabel, Input, Modal, TextEditor, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';

export interface StandaardtekstenToevoegenVoorschriftProps {
  subparagraafId: string;
  voorschriftIndex: number;
  voorschriften?: StdSubparagraaf['voorschriften'];
  onSubmitSuccess?: () => void;
}

type FormValues = Pick<STDVoorschriftSchema, 'Nummer' | 'Voorschrift' | 'Toelichting'>;

export const STANDAARDTEKSTEN_TOEVOEGEN_VOORSCHRIFT_ID = 'standaardtekstenToevoegenVoorschrift';

export const StandaardtekstenToevoegenVoorschrift = modal(
  STANDAARDTEKSTEN_TOEVOEGEN_VOORSCHRIFT_ID,
  ({ data, props }) => {
    const modalState = useModalState();
    const { subparagraafId, voorschriftIndex, voorschriften, onSubmitSuccess } = data;

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>();

    const createStdVoorschrift = useCreateStdVoorschrift();

    const onSubmit: SubmitHandler<FormValues> = ({ Nummer, Voorschrift, Toelichting }) => {
      modalState.action('save');
      createStdVoorschrift.mutate(
        {
          Subparagraaf: subparagraafId,
          Nummer,
          Voorschrift,
          Toelichting,
          voorschriftIndex,
          voorschriften,
        },
        {
          onSuccess: () => {
            modalState.success('save');
            onSubmitSuccess?.();
          },
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Voorschrift toevoegen">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Nummer', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
            error={errors.Nummer}
            label="Nummer"
            id="nieuwe-std-voorschrift-nummer"
            required
          />
          <FormLabel htmlFor="nieuwe-std-voorschrift-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                ]}
                initialText={Toelichting}
                id="nieuwe-std-voorschrift-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />
          <FormLabel htmlFor="nieuwe-std-voorschrift" required>
            Inhoud (van voorschrift)
          </FormLabel>
          <Controller
            control={control}
            name="Voorschrift"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value: Voorschrift } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                  'superscript',
                  'subscript',
                  'table',
                ]}
                initialText={Voorschrift}
                id="nieuwe-std-voorschrift"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
                error={errors.Voorschrift}
              />
            )}
          />
          {errors.Voorschrift && (
            <FormError id={'nieuwe-std-voorschrift-id'}>
              <p>{eStrings.TXT_VERPLICHT_INPUT_VELD}</p>
            </FormError>
          )}

          <Modal.Footer>
            <Modal.CancelButton />
            <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
              Opslaan
            </Modal.ActionButton>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
