import { useQueryClient } from '@tanstack/react-query';

import { usePostFixLineageBedrijf } from '@/api/generated/digiVAPI';

import { useLineageBedrijf, useLineageBedrijfId } from '../bedrijf';

export function usePostLineageBedrijf() {
  const queryClient = useQueryClient();

  return usePostFixLineageBedrijf({
    mutation: {
      onSuccess: (data) => {
        if (data.ID) {
          queryClient.setQueryData(useLineageBedrijfId.getKey(data.ID), data);
        }

        return queryClient.invalidateQueries({ queryKey: useLineageBedrijf.key });
      },
    },
  });
}
