import { debounce } from '../debounce';

/**
 * Various utilities that are accessible from the window object to assist automated testers.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__TESTING_UTILITIES__ = {
  toggleTestIds: () => {
    const COLORS = ['red', 'green', 'blue', 'orange', 'purple'];

    let testIdElements: HTMLElement[] = [];

    let activeLabels: string[] = [];
    const activeLabelElement = document.createElement('div');
    activeLabelElement.classList.add('testid-container');

    document.body.appendChild(activeLabelElement);

    function init() {
      testIdElements = [...document.querySelectorAll<HTMLElement>('[data-testid]')];

      testIdElements.forEach((obj) => {
        obj.classList.add('testid');

        obj.addEventListener('mouseenter', handleMouseEnter);
        obj.addEventListener('mouseleave', handleMouseLeave);
      });

      window.addEventListener('resize', reinit);
      window.addEventListener('scroll', reinit);
    }

    const reinit = debounce(() => {
      activeLabels = [];
      activeLabelElement.innerHTML = '';

      testIdElements.forEach((obj) => {
        obj.classList.remove('testid');

        obj.removeEventListener('mouseenter', handleMouseEnter);
        obj.removeEventListener('mouseleave', handleMouseLeave);
      });

      window.removeEventListener('resize', reinit);
      window.removeEventListener('scroll', reinit);

      init();
    }, 300);

    function handleMouseEnter(e: MouseEvent) {
      const element = e.target as HTMLElement;

      const testid = element.getAttribute('data-testid');

      if (!testid) return;

      addLabel(testid, element);
    }

    function handleMouseLeave(e: MouseEvent) {
      const element = e.target as HTMLElement;

      const testid = element.getAttribute('data-testid');

      if (!testid) return;

      removeLabel(testid, element);
    }

    function addLabel(testid: string, obj: HTMLElement) {
      const label = document.createElement('div');
      label.id = `__TESTID__${testid}`;
      label.innerHTML = testid;

      label.style.color = 'white';

      activeLabels.push(testid);

      label.style.background = COLORS[activeLabels.length - (1 % COLORS.length)]!;

      activeLabelElement.appendChild(label);

      obj.style.borderColor = COLORS[activeLabels.length - (1 % COLORS.length)]!;
      obj.style.borderWidth = '2px';
    }

    function removeLabel(testid: string, obj: HTMLElement) {
      document.getElementById(`__TESTID__${testid}`)?.remove();
      activeLabels.splice(activeLabels.indexOf(testid), 1);

      obj.style.borderColor = '';
      obj.style.borderWidth = '';
    }

    init();
  },
};
