import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512" ref={svgRef} {...props}><path d="M290.7 48.3c-1.7-.6-3.6-.6-5.4 0l-81.2 29 83.9 32.2 83.9-32.1-81.2-29zM176 207.2l88 31.4v-86.9L176 118v89.3zm136 31.4l88-31.4v-89.3l-88 33.7v86.9zM448 93v114.5l91.9 34.8c21.8 8.2 36.1 29.1 36.1 52.4v119.2c0 22.2-13.1 42.3-33.5 51.3l-96.4 42.4c-14 6.1-29.8 6.3-43.9.5L288 460.7 173.8 508c-14.1 5.8-30 5.7-43.9-.5l-96.4-42.4C13.1 456.2 0 436.1 0 413.9V294.7c0-23.3 14.4-44.1 36.1-52.4l91.9-34.8V93c0-23.7 14.9-44.8 37.2-52.7l104-37.1c12.2-4.3 25.5-4.3 37.6 0l104 37.1c22.3 7.9 37.2 29 37.2 52.7zM264 320.2l-88 33.7v101.2l88-36.4v-98.5zm-211.2 101l75.2 33.1V353.9l-80-30.7v90.7c0 3.2 1.9 6 4.8 7.3zM400 455.1V353.9l-88-33.7v98.6l88 36.4zm48-.9l75.2-33.1c2.9-1.3 4.8-4.2 4.8-7.3v-90.6l-80 30.6v100.5zM155 250.7c-1.8-.6-3.7-.6-5.5.1l-78.7 29.8 81.2 31.1 83.9-32.1-80.9-28.9zm266 0l-80.9 28.9 83.9 32.1 81.2-31.1-78.7-29.8c-1.8-.7-3.7-.7-5.5-.1z" /></svg>;
});
SVGIcon.displayName = "RegularCubes";
export default SVGIcon;