import { ReactNode, createContext, useContext, useMemo } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { twJoin, twMerge } from 'tailwind-merge';

import { StateIcon } from '@/components/shared/StateIcon';
import { RegularGripDotsVertical } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

import { useEditorStore } from '../../-store/useEditorStore';
import { ObjectState } from '../../-utils/getObjectState';
import { SlotOverlay } from '../SlotOverlay';

export interface ObjectRowProps {
  id: string;
  type: 'hoofdstuk' | 'paragraaf' | 'subparagraaf' | 'voorschrift';
  lineageId: string;
  hasError?: boolean;
  objectState: ObjectState;
  onFocusRow?: () => void;
  onClickRow?: () => void;
  children: ReactNode;
}

const ObjectRowContext = createContext<{ isSelected?: boolean }>({});

const useObjectRowContext = () => {
  const context = useContext(ObjectRowContext);

  if (!context) {
    throw new Error('useObjectRowContext must be used within a ObjectRowContextProvider');
  }

  return context;
};

export const ObjectRow = ({
  id,
  type,
  lineageId,
  hasError,
  onFocusRow,
  onClickRow,
  objectState,
  children,
}: ObjectRowProps) => {
  const isSelected = useEditorStore(
    ({ selectedObject }) => selectedObject?.lineageId === lineageId
  );
  const selectObject = useEditorStore(({ selectObject }) => selectObject);

  const contextValue = useMemo(() => ({ isSelected }), [isSelected]);

  return (
    <ObjectRowContext.Provider value={contextValue}>
      <li
        className={twMerge(
          'group relative flex w-full items-start transition-colors duration-100 ease-out',
          'my-2 hover:bg-gray-100 hover:ring-1 hover:ring-inset',
          isSelected && 'bg-gray-100 ring-1 ring-inset ring-gray-300',
          hasError && 'ring-1 ring-inset ring-digi-v-color-danger hover:ring-digi-v-color-danger ',
          !hasError && 'hover:ring-gray-300'
        )}
        onFocus={() => {
          selectObject({ lineageId, type });
          onFocusRow?.();
        }}
      >
        {/* 
        Button is used to make the entire row clickable
      */}
        <button
          className={twJoin('absolute inset-0 cursor-pointer', !objectState.isEditable && 'z-[1]')}
          onClick={() => {
            selectObject({ lineageId, type });
            onClickRow?.();
          }}
          data-testid="select"
        />

        <div className='grid w-full [grid-template-areas:"drag_state_number_text_icon"] [grid-template-columns:28px_24px_80px_calc(100%-164px)_32px]'>
          {children}
        </div>

        {objectState.slotState && (
          <SlotOverlay slotState={objectState.slotState} type={type} id={id} />
        )}
      </li>
    </ObjectRowContext.Provider>
  );
};

ObjectRow.DragHandle = function DragHandle({
  dragHandleRef,
}: {
  dragHandleRef: ConnectDragSource;
}) {
  return (
    <div className="[grid-area:drag]">
      <button
        ref={dragHandleRef}
        className="relative ml-1 mt-0.5 flex h-6 w-4 cursor-grab items-center justify-center rounded-sm opacity-0 hover:bg-gray-300 group-hover:opacity-100"
        aria-label="Verplaats besluit onderdeel"
        data-testid="drag"
      >
        <RegularGripDotsVertical size={16} className="block text-base text-black" />
      </button>
    </div>
  );
};

ObjectRow.State = function State({ state }: { state: ObjectState['state'] }) {
  if (!state) return null;

  return (
    <div className="mt-2 [grid-area:state]">
      <StateIcon state={state} />
    </div>
  );
};

ObjectRow.Body = function Body({ children }: { children: ReactNode }) {
  return <div className="w-full py-0.5 pl-4 [grid-area:text]">{children}</div>;
};

ObjectRow.Number = function Number({ children }: { children: ReactNode }) {
  return <div className="z-[1] mt-1 text-right text-gray-500 [grid-area:number]">{children}</div>;
};

ObjectRow.Icon = function Icon({
  icon,
  onClick,
  className,
  testId,
}: {
  icon: IconComponent;
  onClick: () => void;
  className?: string;
  testId: string;
}) {
  const IconComponent = icon;
  const { isSelected } = useObjectRowContext();

  return (
    <div className={twMerge('mt-0.5 flex justify-center text-black [grid-area:icon]', className)}>
      <button
        onClick={onClick}
        className={twJoin(
          'relative z-[1] flex h-6 shrink-0 items-center rounded px-1 text-base',
          'opacity-0 group-hover:opacity-100',
          'hover:bg-gray-300',
          isSelected && 'opacity-100'
        )}
        data-testid={testId}
      >
        <IconComponent size={16} className="block" />
      </button>
    </div>
  );
};

ObjectRow.ButtonOverlay = function ButtonOverlay({ children }: { children: ReactNode }) {
  const { isSelected } = useObjectRowContext();

  return (
    <div
      className={twJoin(
        'absolute inset-0 flex items-center justify-center gap-2 group-hover:opacity-100 [&_button]:relative [&_button]:z-[1]',
        isSelected ? 'opacity-100' : 'opacity-0'
      )}
    >
      {children}
    </div>
  );
};
