import { Transition } from '@headlessui/react';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { SquareButton } from '@/components';
import { RegularBars, RegularXmark } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

export interface SidePanelProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  xPosition: 'left' | 'right';
  yPosition?: 'top' | 'bottom';
  height?: `h-${string}`;
  width: `w-${string}`;
  children: ReactNode;
  id: string;
  testId?: string;
  ariaLabel?: string;
  unmount?: boolean;
  buttonProps: {
    disabled?: boolean;
    openAriaLabel: string;
    openIcon?: IconComponent;
    closeAriaLabel: string;
    testId?: string;
  };
}

export const SidePanel = ({
  open,
  onClose,
  onOpen,
  xPosition,
  yPosition,
  height = 'h-full',
  width,
  children,
  id,
  buttonProps,
  testId,
  ariaLabel,
  unmount,
}: SidePanelProps) => {
  const panelId = `${id}-panel`;

  return (
    <div
      role="presentation"
      onKeyUp={(e) => {
        if (e.key === 'Escape') onClose();
      }}
      className={twJoin(
        'fixed bottom-0 z-10 bg-white shadow-editor transition-transform duration-300 ease-out',
        width,
        open
          ? 'translate-x-0'
          : xPosition === 'left'
            ? '-translate-x-[100%]'
            : 'translate-x-[100%]',
        yPosition === 'top' && 'top-0',
        yPosition === 'bottom' && 'bottom-0',
        xPosition === 'left' && 'sidePanel--left left-0',
        xPosition === 'right' && 'sidePanel--right right-0'
      )}
      id={id}
      data-testid={testId}
    >
      <aside aria-label={ariaLabel}>
        <div
          className={twJoin(
            'sidePanel-toggle absolute top-6',
            xPosition === 'left' && 'left-full',
            xPosition === 'right' && 'right-full'
          )}
        >
          <SquareButton
            variant={open ? 'white' : 'theme-blue'}
            label={open ? buttonProps.closeAriaLabel : buttonProps.openAriaLabel}
            icon={open ? RegularXmark : buttonProps.openIcon || RegularBars}
            onClick={() => (open ? onClose() : onOpen())}
            aria-controls={panelId}
            aria-expanded={open}
            disabled={buttonProps.disabled}
            data-testid={buttonProps.testId}
          />
        </div>

        <div className={twJoin(height, 'overflow-auto')}>
          <Transition
            unmount={unmount ?? false}
            as="div"
            className="duration-300"
            show={open}
            id={panelId}
            enter="transition-opacity"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {children}
          </Transition>
        </div>
      </aside>
    </div>
  );
};
