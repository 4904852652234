import { useForm } from 'react-hook-form';

import { BesluitSchema } from '@/api';
import { NewBesluitProps, useLastVigerendBesluit } from '@/api/queries/besluit';
import { useBesluitType } from '@/api/queries/besluitType';
import { BesluitCard, Modal, Select, TextArea, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { RegularFile } from '@/icons/components';
import { DateUtils } from '@/utils/dateUtils';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils/modal';

export type WijzigingProps = {
  title: string;
  bedrijfLineageId: string;
  defaultValues?: Partial<NewBesluitProps>;
  submitLabel: string;
  submitSuccessLabel: string;
  disabledWijzigingType?: boolean;
  onSubmit: (formValues: NewBesluitProps) => Promise<void>;
};

type FormValues = Pick<BesluitSchema, 'Type' | 'Korte_Omschrijving' | 'Ontwerp_Beschikking'>;

export const WIJZIGING_ID = 'wijziging';

export const Wijziging = modal(WIJZIGING_ID, ({ data, props }) => {
  const {
    title,
    bedrijfLineageId,
    defaultValues,
    submitLabel,
    submitSuccessLabel,
    disabledWijzigingType,
    onSubmit,
  } = data;

  const modalState = useModalState();

  const lastVigerendBesluit = useLastVigerendBesluit({ bedrijfLineageId });

  const besluitTypes = useBesluitType({});

  const wijzigingTypes = besluitTypes.data?.filter(
    (besluittype) => !!besluittype.Wijziging_zonder_besluit
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultValues ?? undefined,
    mode: 'onChange',
  });

  return (
    <Modal
      {...props}
      isLoading={besluitTypes.isLoading || lastVigerendBesluit.isLoading}
      state={modalState.state}
      testId="popup-wijziging"
      title={title}
    >
      {lastVigerendBesluit.data && (
        <div className="mt-3">
          <BesluitCard icon={RegularFile}>
            <BesluitCard.Heading
              title="Vigerende situatie"
              headingAs="h3"
              subtitle={`Laatste wijziging op ${
                lastVigerendBesluit.data.Besluit_Datum &&
                DateUtils.formatReadable(new Date(lastVigerendBesluit.data.Besluit_Datum))
              }`}
            />
          </BesluitCard>
        </div>
      )}
      <form
        className="text-gray-900"
        onSubmit={handleSubmit((formData) => {
          modalState.action('save');

          onSubmit(formData)
            .then(() => modalState.success('save'))
            .catch((error) => {
              const errorMessage =
                error instanceof Error && ErrorMessage.getLocalErrorMessage(error);

              if (errorMessage)
                modalState.error('save', {
                  title: 'Mislukt',
                  description: errorMessage,
                });
            });
        })}
      >
        <Select
          {...register('Type', { required: 'Selecteer een type' })}
          label="Type wijziging"
          id="Type_Wijziging"
          defaultValue=""
          placeholder="Type"
          className="mt-4"
          error={errors.Type}
          disabled={disabledWijzigingType}
          required
          horizontal
        >
          {wijzigingTypes?.map((wijzigingType) => (
            <option key={`besluit-type-option-${wijzigingType.ID}`} value={wijzigingType.ID}>
              {wijzigingType.Naam}
            </option>
          ))}
        </Select>

        <TextArea
          {...register('Korte_Omschrijving', {
            required: 'Vul de korte toelichting op de wijziging in',
          })}
          id="Korte_Omschrijving_Wijziging"
          label={eStrings.LBL_BESLUIT_OMSCHRIJVING}
          autoComplete="off"
          placeholder="Geef een korte toelichting op de wijziging"
          className="mt-4"
          error={errors.Korte_Omschrijving}
          horizontal
          required
        />

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton action="save" successLabel={submitSuccessLabel} type="submit">
            {submitLabel}
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
