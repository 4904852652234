import { useDebouncedCallback } from '@react-hookz/web';
import { useNavigate } from '@tanstack/react-router';
import { isBefore } from 'date-fns';
import { useEffect, useRef } from 'react';

import { BedrijfSchema } from '@/api';
import { useVoorschrift } from '@/api/queries/objects';
import { FeedbackMessage, Input, LoaderContent } from '@/components';
import { eStrings } from '@/constants/strings';
import { Header } from '@/routes/$bedrijfLineageId.viewer/-components/Header';
import { DateUtils } from '@/utils/dateUtils';

export const VigerendHeader = ({
  bedrijf,
  defaultDate,
}: {
  bedrijf: BedrijfSchema;
  defaultDate: Date;
}) => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Overwrite input value when the default date changes
   */
  const defaultDateForInput = DateUtils.formatForInput(defaultDate);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultDateForInput;
    }
  }, [defaultDateForInput]);

  /**
   * Get the first inwerkingtreding date
   */
  const minBeginGeldigheid = useVoorschrift(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Bedrijf',
          field: 'ID',
          op: 'eq',
          value: bedrijf.ID ?? '',
        },
        {
          model: 'Voorschrift',
          field: 'Begin_Geldigheid',
          op: 'is_not_null',
          value: '',
        },
      ],
      sort: [
        {
          model: 'Voorschrift',
          field: 'Begin_Geldigheid',
          direction: 'asc',
        },
      ],
    },
    {
      select: ({ objects }) => objects?.[0]?.Begin_Geldigheid,
    }
  );

  /**
   * Get the last uitwerking date
   */
  const maxBeginGeldigheid = useVoorschrift(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Bedrijf',
          field: 'ID',
          op: 'eq',
          value: bedrijf.ID ?? '',
        },
      ],
      sort: [
        {
          model: 'Voorschrift',
          field: 'Begin_Geldigheid',
          direction: 'desc',
        },
      ],
    },
    {
      select: ({ objects }) => objects?.[0]?.Begin_Geldigheid,
    }
  );

  /**
   * Get the maxdate and mindate
   */
  const now = new Date();

  const minDate = minBeginGeldigheid.data ? new Date(minBeginGeldigheid.data) : now;
  let maxDate = maxBeginGeldigheid.data ? new Date(maxBeginGeldigheid.data) : now;

  if (isBefore(maxDate, now)) {
    maxDate = now;
  }

  const onChangeDebounced = useDebouncedCallback(
    (date: string, inputElement: HTMLInputElement) => {
      const dateObject = new Date(date);

      // The time of the dateObject is automatically set to 01:00:00, so we need to set it to 00:00:00
      dateObject.setHours(0, 0, 0, 0);

      let navigateToDate = dateObject;

      /**
       * Make sure to overwrite date if it's not within the min and max date
       */
      if (isBefore(dateObject, minDate)) {
        inputElement.value = DateUtils.formatForInput(minDate);
        navigateToDate = minDate;

        FeedbackMessage(
          'info',
          'De opgegeven datum kan niet worden gebruikt, de datum is aangepast naar een beschikbaar datum.'
        );
      }

      if (isBefore(maxDate, dateObject)) {
        inputElement.value = DateUtils.formatForInput(maxDate);
        navigateToDate = maxDate;

        FeedbackMessage(
          'info',
          'De opgegeven datum kan niet worden gebruikt, de datum is aangepast naar een beschikbaar datum.'
        );
      }

      navigate({
        to: '/$bedrijfLineageId/viewer/vigerend',
        params: {
          bedrijfLineageId: bedrijf.Lineage,
        },
        search: {
          datum: DateUtils.formatForInput(navigateToDate),
        },
      });
    },
    [minDate, maxDate, bedrijf.Lineage, navigate],
    500
  );

  if (minBeginGeldigheid.isLoading || maxBeginGeldigheid.isLoading) {
    return (
      <Header bedrijf={bedrijf} mode="vigerend">
        <LoaderContent w="w-[128px]" h="h-[38px]" />
      </Header>
    );
  }

  return (
    <Header bedrijf={bedrijf} mode="vigerend">
      <Input
        ref={inputRef}
        name="select-date"
        label="Selecteer datum"
        id="aadv-date"
        className="text-black"
        hideLabel
        onChange={(e) => onChangeDebounced(e.target.value, e.target as HTMLInputElement)}
        type="date"
        placeholder={eStrings.TXT_DATE_FORMAT}
        defaultValue={defaultDateForInput}
        min={DateUtils.formatForInput(minDate)}
        max={DateUtils.formatForInput(maxDate)}
      />
    </Header>
  );
};
