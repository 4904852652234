import { create } from 'zustand';

import { GebruikerSchema } from '@/api';
import { Allowed } from '@/components';
import API from '@/services/axios';
import { queryClient } from '@/services/queryClient';

interface UserState {
  user: GebruikerSchema | null;
  userRole: Allowed[] | null;
  area: 'landelijk' | 'omgevingsdienst' | null;
  organisatieNaam: string | null;
  userIsLoading: boolean;
  isInitialised: boolean;
  isLoggedIn: boolean;
}

interface UserActions {
  loadLoggedInUser: () => Promise<void>;
  logout: () => void;
  login: ({ email, password }: { email: string; password: string }) => Promise<void>;
}

const initialState: UserState = {
  user: null,
  userRole: null,
  area: null,
  organisatieNaam: null,
  userIsLoading: false,
  isLoggedIn: false,
  isInitialised: false,
};

const parseRol = (rol: string) => rol.split(',') as Allowed[];

export const useUserStore = create<UserState & UserActions>()((set) => ({
  ...initialState,
  loadLoggedInUser: async () => {
    const hasAccessToken = !!localStorage.getItem('__AADV_access_token__');

    if (!hasAccessToken) {
      set(() => ({
        user: null,
        isLoggedIn: false,
        userRole: ['non-auth'],
        userIsLoading: false,
        isInitialised: true,
        area: null,
        organisatieNaam: null,
      }));

      return;
    }

    set(() => ({ userIsLoading: true }));

    try {
      const response = await API.get('/auth/tokeninfo');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const user = response.data.identifier as any;

      set(() => ({
        user,
        userIsLoading: false,
        userRole: user.Rol ? parseRol(user.Rol) : ['non-auth'],
        area: user.Landelijk ? 'landelijk' : 'omgevingsdienst',
        organisatieNaam: user.Organisatienaam,
        isLoggedIn: true,
        isInitialised: true,
      }));
    } catch (error) {
      set(() => ({
        user: null,
        userIsLoading: false,
        userRole: ['non-auth'],
        isInitialised: true,
        area: null,
        organisatieNaam: null,
      }));
    }
  },
  login: async ({ email, password }) => {
    set(() => ({ userIsLoading: true }));

    try {
      const {
        data: { access_token, identifier, expires },
      } = await API.post('/auth/login', {
        identifier: email,
        password,
      });

      localStorage.setItem('__AADV_identifier__', JSON.stringify(identifier));
      localStorage.setItem('__AADV_access_token__', access_token);
      localStorage.setItem('__AADV_access_token_expires__', expires);

      set(() => ({
        user: identifier,
        isLoggedIn: true,
        userIsLoading: false,
        userRole: identifier.Rol ? parseRol(identifier.Rol) : ['non-auth'],
        area: identifier.Landelijk ? 'landelijk' : 'omgevingsdienst',
        organisatieNaam: identifier.Organisatienaam,
      }));
    } catch (error) {
      set(() => ({
        user: null,
        userIsLoading: false,
        userRole: null,
        isLoggedIn: false,
        area: null,
        organisatieNaam: null,
      }));

      throw error;
    }
  },
  logout: () => {
    localStorage.removeItem('__AADV_identifier__');
    localStorage.removeItem('__AADV_access_token__');
    localStorage.removeItem('__AADV_access_token_expires__');

    // Make sure a user doesn't see any stale data from when logged in.
    queryClient.resetQueries();

    set(() => ({
      user: null,
      userIsLoading: false,
      userRole: null,
      isLoggedIn: false,
      area: null,
      organisatieNaam: null,
    }));
  },
}));
