import { createContext, useContext } from 'react';

import { ParagraafSchema } from '@/api';
import { LinkParagraaf, Links } from '@/api/queries/objects';
import { ObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';

export type ParagraafError = {
  type: 'patchTitel' | 'validateNummer' | 'patchNummer';
  message: string;
  tryAgain?: () => Promise<unknown>;
};

export const ParagraafContext = createContext<{
  objectState: ObjectState;
  nummer: string;
  hoofdstukNummer?: number;
  paragraaf: ParagraafSchema;
  link: LinkParagraaf;
  links: Links;
  userHasReservering: boolean;
  editorMode: EditorMode;
  errors: ParagraafError[];
  addError: (error: ParagraafError) => void;
  removeError: (type: ParagraafError['type']) => void;
} | null>(null);

export const useParagraafContext = () => {
  const context = useContext(ParagraafContext);

  if (!context) {
    throw new Error('useParagraafContext must be used within a ParagraafContextProvider');
  }

  return context;
};
