import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchDynStdParagraafId } from '@/api/generated/digiVAPI';
import { getRangBetween } from '@/utils/getRangBetween';

import { useStdObjects } from '../stdObjects';
import { StdObjects } from '../utils/getStdObjects';

/**
 * useMutation for ordering paragrafen
 */
export const useOrderStdParagraaf = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      from,
      to,
      method,
      paragrafen,
    }: {
      from: number;
      to: number;
      method: 'prepend' | 'append';
      paragrafen: StdObjects['paragrafen'];
    }) => {
      const newIndex = method === 'prepend' ? to - 1 : to;

      const paragraaf = paragrafen[from];
      const newPrevious = paragrafen[newIndex];
      const newNext = paragrafen[newIndex + 1];

      if (!paragraaf) {
        throw new Error('Paragraaf niet gevonden');
      }

      if (newPrevious?.data.ID === paragraaf.data.ID || newNext?.data.ID === paragraaf.data.ID) {
        throw new Error('Paragraaf mag niet naar dezelfde positie verplaatst worden');
      }

      const newRang = getRangBetween(newPrevious?.data.Rang, newNext?.data.Rang);

      /**
       * Patch PHB with new Vorig and Volgend prop
       */
      return await patchDynStdParagraafId(paragraaf.data.ID!, {
        Rang: newRang,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useStdObjects.key });
    },
  });
};
