import { useDebouncedCallback } from '@react-hookz/web';
import { useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  useDeleteHistorischBesluitLink,
  useHistorischBesluit,
  useHistorischBesluitLinkByLineageId,
  usePatchHistorischBesluitLink,
  usePostHistorischBesluitLink,
} from '@/api/queries/historischBesluit';
import { Input, Select, TextButton } from '@/components';
import { Accordion } from '@/components/shared/Accordion';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { useOpenModal } from '@/modals/utils';
import { useUserStore } from '@/stores/user';

import { useVoorschriftContext } from './VoorschriftContext';
import { SidebarError } from './VoorschriftSidebar';

type FormValues = {
  historischBesluit?: string | null;
  origineelNummer?: string | null;
};

type VoorschriftSidebarHistorischProps = {
  errors: SidebarError[];
  patchHistorischBesluitLink: ReturnType<typeof usePatchHistorischBesluitLink>;
  deleteHistorischBesluitLink: ReturnType<typeof useDeleteHistorischBesluitLink>;
  postHistorischBesluitLink: ReturnType<typeof usePostHistorischBesluitLink>;
};

export const VoorschriftSidebarHistorisch = ({
  errors,
  patchHistorischBesluitLink,
  deleteHistorischBesluitLink,
  postHistorischBesluitLink,
}: VoorschriftSidebarHistorischProps) => {
  const { voorschrift, objectState } = useVoorschriftContext();

  const { bedrijfLineageId } = useParams({ from: '/$bedrijfLineageId/editor/$besluitId' });
  const openModal = useOpenModal();

  const [isMutating, setIsMutating] = useState(false);

  /**
   * Get historisch besluit and the links
   */
  const historischBesluitLink = useHistorischBesluitLinkByLineageId({
    voorschriftLineageId: voorschrift.Lineage,
  });

  const allHistorischBesluiten = useHistorischBesluit(
    {
      page: 1,
      size: 100,
      filter: [
        {
          field: 'Bedrijf_lineage',
          op: 'eq',
          value: bedrijfLineageId,
          model: 'HistorischBesluit',
        },
      ],
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  );

  const linkedHistorischBesluit = allHistorischBesluiten.data?.objects?.find(
    (historischBesluit) => historischBesluit.ID === historischBesluitLink.data?.Historisch_besluit
  );

  /**
   * Form
   */
  const { register, setValue, getValues } = useForm<FormValues>();

  /**
   * Function for linking a historisch besluit to a voorschrift
   */
  const linkHistorischBesluit = async (historischBesluitId: string | null | undefined) => {
    setIsMutating(true);

    const userId = useUserStore.getState().user?.ID ?? '';
    const date = new Date().toISOString();

    try {
      if (historischBesluitLink.data) {
        await deleteHistorischBesluitLink.mutateAsync({
          id: historischBesluitLink.data.ID!,
        });
      }

      if (historischBesluitId) {
        await postHistorischBesluitLink.mutateAsync({
          data: {
            Historisch_besluit: historischBesluitId,
            Origineel_voorschriftnummer: getValues('origineelNummer'),
            Voorschrift_lineage: voorschrift.Lineage,
            Created_By: userId,
            Modified_By: userId,
            Created_Date: date,
            Modified_Date: date,
          },
        });
      } else {
        setValue('origineelNummer', '');
      }
    } catch {}

    setIsMutating(false);
  };

  const updateVoorschriftnummer = (voorschriftnummer: string) => {
    patchHistorischBesluitLink.mutate({
      id: historischBesluitLink.data!.ID!,
      data: {
        Origineel_voorschriftnummer: voorschriftnummer,
      },
    });
  };

  const updateVoorschriftnummerDebounced = useDebouncedCallback(
    updateVoorschriftnummer,
    [updateVoorschriftnummer],
    500
  );

  if (historischBesluitLink.isLoading || allHistorischBesluiten.isLoading) return null;

  /**
   * Mutation errors
   */
  const updateError = errors.find((error) => error.request === 'historisch-patch');
  const deleteError = errors.find((error) => error.request === 'historisch-delete');
  const postError = errors.find((error) => error.request === 'historisch-post');

  return (
    <Accordion defaultOpen>
      <Accordion.Button label="Historisch besluit" />
      <Accordion.Panel>
        <form onSubmit={(e) => e.preventDefault()}>
          <Select
            {...register('historischBesluit', {
              onChange: (e) => linkHistorischBesluit(e.target.value),
            })}
            label="Historisch besluit"
            subtitle="Kies uit de lijst van alle historische besluiten"
            id="select-historisch-besluit"
            disabled={!objectState.isEditable || isMutating || historischBesluitLink.isLoading}
            defaultValue={linkedHistorischBesluit?.ID}
          >
            <option value="">Maak een keuze</option>

            {allHistorischBesluiten.data?.objects?.map((historischBesluit) => (
              <option
                key={`select-historisch-besluit-${historischBesluit.ID}`}
                value={historischBesluit.ID}
              >
                {historischBesluit.Besluit_Nummer}
              </option>
            ))}
          </Select>

          <TextButton
            size="small"
            className="mt-2"
            type="button"
            disabled={!objectState.isEditable || isMutating || historischBesluitLink.isLoading}
            onClick={() => {
              openModal('historischBesluitEigenschappen', {
                bedrijf: {
                  Lineage: bedrijfLineageId,
                },
                onSuccess: async (historischBesluit) => {
                  await linkHistorischBesluit(historischBesluit.ID);

                  setValue('historischBesluit', historischBesluit.ID);
                },
              });
            }}
          >
            Nieuw historisch besluit aanmaken
          </TextButton>

          <Error error={deleteError} />
          <Error error={postError} />

          <Input
            {...register('origineelNummer', {
              onChange: (e) => updateVoorschriftnummerDebounced(e.target.value),
            })}
            id="origineel-voorschriftnummer"
            label="Origineel voorschriftnummer"
            subtitle="Het (complete) nummer dat dit voorschrift in het originele document had."
            placeholder="Bijv. 1.5.8"
            disabled={!objectState.isEditable || !linkedHistorischBesluit}
            defaultValue={
              historischBesluitLink.data?.Origineel_voorschriftnummer
                ? historischBesluitLink.data?.Origineel_voorschriftnummer
                : ''
            }
            className="mt-6"
          />
        </form>

        <Error error={updateError} />
      </Accordion.Panel>
    </Accordion>
  );
};

const Error = ({ error }: { error?: SidebarError }) => {
  if (!error?.message) return null;

  return <WarningInline description={error.message} tryAgain={error.tryAgain} />;
};
