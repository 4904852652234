import { ObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';

export const getObjectStatus = (
  {
    Stop_Lineage,
    Vorig,
  }: {
    Stop_Lineage: number;
    Vorig?: string | null;
  },
  Eigen: boolean
): ObjectState['state'] | undefined => {
  if (!Eigen) return;

  if (Stop_Lineage) {
    return 'deleted';
  } else if (!Vorig) {
    return 'new';
  } else {
    return 'edited';
  }
};
