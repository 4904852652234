import { ComponentPropsWithoutRef, ReactNode } from 'react';

import { Button } from '@/components';

interface ErrorProps {
  children: ReactNode;
  title: string;
  link?: {
    props: Omit<ComponentPropsWithoutRef<'a'>, 'className'>;
    label: string;
  };
}

export const Error = ({ children, title, link }: ErrorProps) => {
  return (
    <div className="flex h-screen w-full items-center justify-center overflow-y-auto bg-theme-blue">
      <div
        className="w-2/3 max-w-3xl overflow-y-auto rounded bg-white px-6 py-10"
        data-testid="error-page"
      >
        <div className="mx-4 px-5 ">
          <h1
            data-testid="error-page-title"
            className="text-pzh-blue mb-4 text-2xl font-bold md:text-4xl"
          >
            {title}
          </h1>

          {children}

          {link && (
            <Button asChild className="mt-6" variant="green">
              {/* Render a regular <a> tag to make sure the app reloads and js is initialised again */}
              <a {...link.props}>{link.label}</a>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
