import {
  Link,
  Navigate,
  Outlet,
  createFileRoute,
  notFound,
  useMatches,
} from '@tanstack/react-router';

import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useBesluitId } from '@/api/queries/besluit';
import { useBesluitType, useBesluitTypeId } from '@/api/queries/besluitType';
import { Button, DocumentTitle, TreeNav, TreeNavItem, TreeNavList } from '@/components';
import Navbar from '@/components/shared/Navbar';
import { eStrings } from '@/constants/strings';
import { useOpenModal } from '@/modals/utils';
import { DateUtils } from '@/utils/dateUtils';
import { useIsExactMatch } from '@/utils/hooks/useIsExactMatch';

import { Body } from '../-components/Body';
import { Header } from '../-components/Header';
import {
  ensureQueryDataViewerBesluitHoofdstukken,
  useViewerBesluitHoofdstukken,
} from './-utils/useViewerBesluitHoofdstukken';

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/viewer/besluit/$besluitId')({
  loader: async ({ params, context: { queryClient } }) => {
    const [latestBedrijf, besluit] = await Promise.all([
      queryClient.ensureQueryData(useLatestBedrijf.getOptions(params.bedrijfLineageId)),
      queryClient.ensureQueryData(useBesluitId.getOptions(params.besluitId)),
      queryClient.ensureQueryData(useBesluitType.getOptions({})),
      ensureQueryDataViewerBesluitHoofdstukken({ besluitId: params.besluitId, queryClient }),
    ]);

    if (!latestBedrijf || !besluit) throw notFound();
  },
  component: BesluitIdComponent,
});

/**
 * Route component
 */
function BesluitIdComponent() {
  const { bedrijfLineageId, besluitId } = Route.useParams();
  const matches = useMatches();
  const openModal = useOpenModal();

  const bedrijf = useLatestBedrijf(bedrijfLineageId);
  const besluit = useBesluitId(besluitId, {
    // After a besluit is published, it will never change again.
    staleTime: Infinity,
  });
  const besluitType = useBesluitTypeId(besluit.data?.Type);

  const {
    voorschriftHoofdstukken,
    overwegingHoofdstukken,
    isLoading: isLoadingHoofdstukken,
  } = useViewerBesluitHoofdstukken({ besluitId });

  /**
   * When the route is exactly this page, navigate to the first hoofdstuk.
   */
  const isExactMatch = useIsExactMatch('/$bedrijfLineageId/viewer/besluit/$besluitId');

  if (isExactMatch) {
    const firstHoofdstuk = voorschriftHoofdstukken[0];

    if (firstHoofdstuk) {
      return (
        <Navigate
          to="/$bedrijfLineageId/viewer/besluit/$besluitId/voorschriften/$hoofdstukId"
          params={{
            bedrijfLineageId,
            besluitId,
            hoofdstukId: firstHoofdstuk.ID!,
          }}
        />
      );
    }
  }

  if (!bedrijf.data || !besluit.data || isLoadingHoofdstukken) return null;

  /**
   * Check if the page is an inhoudelijke overweging or voorschriften page
   */
  const isVoorschriftenPage = matches.some(
    ({ routeId }) =>
      routeId === '/$bedrijfLineageId/viewer/besluit/$besluitId/voorschriften/$hoofdstukId'
  );
  const isInhoudelijkeOverwegingPage = matches.some(
    ({ routeId }) =>
      routeId ===
      '/$bedrijfLineageId/viewer/besluit/$besluitId/inhoudelijke-overwegingen/$hoofdstukId'
  );

  return (
    <>
      <DocumentTitle
        sections={[
          eStrings.LBL_DIGI_V,
          bedrijf.data.Naam ?? '',
          `${besluit.data.Zaaknummer} (${besluitType.data?.Naam})`,
        ]}
      />

      <Navbar />

      <Header bedrijf={bedrijf.data} mode="besluit">
        <Button
          onClick={() => {
            if (bedrijf.data) openModal('selectBesluit', { bedrijf: bedrijf.data });
          }}
        >
          Kies een besluit
        </Button>
      </Header>

      <Body>
        <Body.Sidebar>
          <h2
            className="mb-2 block text-sm font-medium leading-5 text-theme-blue"
            id="inhoudsopgave-id"
          >
            Inhoudsopgave
          </h2>

          <TreeNav role="menu" aria-labelledby="inhoudsopgave-id">
            <TreeNavItem role="menuitem" asChild>
              <Link
                to="/$bedrijfLineageId/viewer/besluit/$besluitId/besluittekst"
                params={{ besluitId, bedrijfLineageId }}
              >
                Besluittekst
              </Link>
            </TreeNavItem>
            <TreeNavItem role="menuitem" asChild>
              <Link
                to="/$bedrijfLineageId/viewer/besluit/$besluitId/rechtsmiddelen"
                params={{ besluitId, bedrijfLineageId }}
              >
                Rechtsmiddelen
              </Link>
            </TreeNavItem>
            <TreeNavList defaultOpen={isVoorschriftenPage} label="Hoofdstukken">
              {voorschriftHoofdstukken.map((hoofdstuk) => (
                <TreeNavItem
                  role="menuitem"
                  asChild
                  lineThrough={!!hoofdstuk.Stop_Lineage}
                  key={hoofdstuk.ID!}
                >
                  <Link
                    to="/$bedrijfLineageId/viewer/besluit/$besluitId/voorschriften/$hoofdstukId"
                    params={{
                      besluitId,
                      bedrijfLineageId,
                      hoofdstukId: hoofdstuk.ID!,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `<span>${hoofdstuk.Nummer}</span> ${hoofdstuk.Titel}`,
                    }}
                  />
                </TreeNavItem>
              ))}
            </TreeNavList>
            <TreeNavItem role="menuitem" asChild>
              <Link
                to="/$bedrijfLineageId/viewer/besluit/$besluitId/procedurele-overwegingen"
                params={{ besluitId, bedrijfLineageId }}
              >
                Procedurele overwegingen
              </Link>
            </TreeNavItem>

            <TreeNavItem role="menuitem" asChild>
              <Link
                to="/$bedrijfLineageId/viewer/besluit/$besluitId/algemeen-toetsingskader"
                params={{ besluitId, bedrijfLineageId }}
              >
                Algemeen toetsingskader
              </Link>
            </TreeNavItem>

            <TreeNavList
              defaultOpen={isInhoudelijkeOverwegingPage}
              label={eStrings.LBL_CONSIDERANS}
            >
              {overwegingHoofdstukken.map((hoofdstuk) => (
                <TreeNavItem role="menuitem" asChild key={hoofdstuk.ID!}>
                  <Link
                    to="/$bedrijfLineageId/viewer/besluit/$besluitId/inhoudelijke-overwegingen/$hoofdstukId"
                    params={{
                      besluitId,
                      bedrijfLineageId,
                      hoofdstukId: hoofdstuk.ID!,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `<span>${hoofdstuk.Nummer}</span> ${hoofdstuk.Titel}`,
                    }}
                  />
                </TreeNavItem>
              ))}
            </TreeNavList>

            <TreeNavItem role="menuitem" asChild>
              <Link
                to="/$bedrijfLineageId/viewer/besluit/$besluitId/overige-overwegingen"
                params={{ besluitId, bedrijfLineageId }}
              >
                Overige overwegingen
              </Link>
            </TreeNavItem>

            <TreeNavItem role="menuitem" asChild>
              <Link
                to="/$bedrijfLineageId/viewer/besluit/$besluitId/begrippen"
                params={{ besluitId, bedrijfLineageId }}
              >
                Begrippen
              </Link>
            </TreeNavItem>
          </TreeNav>

          <TreeNavItem role="menuitem" asChild>
            <Link
              to="/$bedrijfLineageId/viewer/besluit/$besluitId/bijlage"
              params={{ besluitId, bedrijfLineageId }}
            >
              Bijlage
            </Link>
          </TreeNavItem>
        </Body.Sidebar>

        <Body.Content>
          <dl
            className="flex justify-between border-b border-gray-200 pb-6 text-base text-digi-v-gray-light"
            title="Besluit meta data"
          >
            <div className="flex w-48 flex-col">
              <dt>{eStrings.LBL_TYPE_BESLUIT}</dt>
              <dd className="mt-3 break-words text-lg font-bold">
                {besluitType.data?.Naam ?? 'Nog niet bekend'}
              </dd>
            </div>

            <div className="flex w-48 flex-col">
              <dt>{eStrings.LBL_BESLUITDATUM}</dt>
              <dd className="mt-3 break-words text-lg font-bold">
                {besluit.data.Besluit_Datum
                  ? DateUtils.formatReadable(new Date(besluit.data.Besluit_Datum))
                  : 'Niet bekend'}
              </dd>
            </div>

            <div className="flex w-48 flex-col">
              <dt>{eStrings.LBL_BESLUIT_KENMERK}</dt>
              <dd className="mt-3 break-words text-lg font-bold">
                {besluit.data.Ons_Kenmerk ?? 'Nog niet bekend'}
              </dd>
            </div>
          </dl>

          <div className="mt-6">
            <Outlet />
          </div>
        </Body.Content>
      </Body>
    </>
  );
}
