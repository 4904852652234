import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { HoofdstukSchema } from '@/api';
import { Links, useDeleteHoofdstukId } from '@/api/queries/objects';
import { Checkbox, Modal, useModalState } from '@/components';
import { fetchHoofdstukkenInEditor } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useHoofdstukkenInEditor';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface DeleteHoofdstukProps {
  links: Links;
  besluitId: string;
  bedrijfLineageId: string;
  hoofdstuk: HoofdstukSchema;
}

export const DELETE_HOOFDSTUK_ID = 'deleteHoofdstuk';

export const DeleteHoofdstuk = modal(DELETE_HOOFDSTUK_ID, ({ data, props }) => {
  const { hoofdstuk, links, besluitId, bedrijfLineageId } = data;

  const [isChecked, setIsChecked] = useState(false);

  const modalState = useModalState();
  const navigate = useNavigate();

  const deleteHoofdstuk = useDeleteHoofdstukId();

  const hasChildren = links
    .map((link) => [
      link,
      ...link.subparagrafen.map((subparagraaf) => [subparagraaf, ...subparagraaf.voorschriften]),
    ])
    .flat(2).length;

  const handleSubmit = async () => {
    if (!hoofdstuk.ID) return;

    modalState.action('delete');

    deleteHoofdstuk.mutate(
      {
        id: hoofdstuk.ID,
      },
      {
        onSuccess: async () => {
          const hoofdstukkenInEditor = await fetchHoofdstukkenInEditor(besluitId);
          const firstHoofdstuk = hoofdstukkenInEditor[0];

          modalState.success('delete');

          if (firstHoofdstuk?.ID) {
            navigate({
              to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
              params: {
                bedrijfLineageId,
                besluitId,
                hoofdstukId: firstHoofdstuk.ID,
              },
            });

            return;
          }

          navigate({
            to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/hoofdstuk',
            params: {
              bedrijfLineageId,
              besluitId,
            },
          });
        },
        onError: (error) => {
          const errorMessage = ErrorMessage.getLocalErrorMessage(error);

          if (errorMessage) {
            modalState.error('delete', {
              title: 'Mislukt!',
              description: errorMessage,
            });
          }
        },
      }
    );
  };

  return (
    <Modal {...props} state={modalState.state} title={`Verwijderen hoofdstuk ${hoofdstuk.Nummer}`}>
      {hasChildren ? (
        <>
          <p>Onderliggende onderdelen zullen ook worden verwijderd.</p>
          <Checkbox
            name="deleteChildrenCheckbox"
            id="deleteChildrenCheckbox"
            label={`Ik wil hoofdstuk ${hoofdstuk.Nummer} inclusief onderliggende onderdelen verwijderen`}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mt-6"
            checked={isChecked}
          />
        </>
      ) : (
        <p>Je staat op het punt om hoofdstuk {hoofdstuk.Nummer} te verwijderen.</p>
      )}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="delete"
          successLabel="Verwijderd"
          variant="red"
          disabled={!!hasChildren && !isChecked}
          onClick={handleSubmit}
        >
          Verwijderen
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
