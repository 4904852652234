import { Suspense, lazy } from 'react';

import { environment } from '@/utils/environment';

const TanstackRouterDevtoolsLazy = lazy(() =>
  import('@tanstack/router-devtools').then((mod) => ({ default: mod.TanStackRouterDevtools }))
);

const TanstackQueryDevtoolsLazy = lazy(() =>
  import('@tanstack/react-query-devtools').then((res) => ({
    default: res.ReactQueryDevtools,
  }))
);

export const DevTools = () => {
  return (
    <>
      {environment.isLocal && (
        <div data-testid="devtools">
          <Suspense fallback={<div />}>
            <TanstackRouterDevtoolsLazy />
            <TanstackQueryDevtoolsLazy />
          </Suspense>
        </div>
      )}
    </>
  );
};
