import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512" ref={svgRef} {...props}><path d="M432 256a48 48 0 11-96 0 48 48 0 1196 0zm-160 0a48 48 0 11-96 0 48 48 0 1196 0zM64 304a48 48 0 110-96 48 48 0 110 96z" /></svg>;
});
SVGIcon.displayName = "RegularEllipsis";
export default SVGIcon;