import { SolidSquareMinus, SolidSquarePen, SolidSquarePlus } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';
import { ObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';

export interface StateIconProps {
  state: ObjectState['state'];
}

export const StateIcon = ({ state }: StateIconProps) => {
  let IconComponent: IconComponent | undefined;
  let className: string = '';

  switch (state) {
    case 'edited':
      IconComponent = SolidSquarePen;
      className = 'text-digi-v-color-info';
      break;
    case 'new':
      IconComponent = SolidSquarePlus;
      className = 'text-digi-v-color-success';
      break;
    case 'deleted':
      IconComponent = SolidSquareMinus;
      className = 'text-digi-v-color-danger';
      break;
  }

  if (!state || !IconComponent) {
    return null;
  }

  return <IconComponent size={12} className={className} />;
};
