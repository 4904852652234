import { useQueryClient } from '@tanstack/react-query';

import { usePatchDynLabelMapId } from '@/api/generated/digiVAPI';
import { LabelMapSchema } from '@/api/generated/digiVAPI.schemas';

import { useLabelMap, useLabelMapId } from '../label';

export function usePatchLabelMapId() {
  const queryClient = useQueryClient();

  return usePatchDynLabelMapId({
    mutation: {
      onSuccess: async (labelMap) => {
        await queryClient.invalidateQueries({ queryKey: useLabelMap.key });

        queryClient.setQueryData<LabelMapSchema>(useLabelMapId.getKey(labelMap.ID!), labelMap);
      },
    },
  });
}
