import { useQueryClient } from '@tanstack/react-query';

import { usePostFixBedrijf } from '@/api/generated/digiVAPI';

import { useBedrijf, useBedrijfId } from '../bedrijf';

export function usePostBedrijf() {
  const queryClient = useQueryClient();

  return usePostFixBedrijf({
    mutation: {
      onSuccess: (bedrijf) => {
        if (bedrijf.ID) {
          queryClient.setQueryData(useBedrijfId.getKey(bedrijf.ID), bedrijf);
        }

        return queryClient.invalidateQueries({ queryKey: useBedrijf.key });
      },
    },
  });
}
