import { InstallatieTypeSchema, InstallatieTypeSchemaStatus } from '@/api';
import { usePatchInstallatietypeId } from '@/api/queries/installatietype';
import { Modal, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface BeheerInstallatietypeStatusProps {
  newStatus: InstallatieTypeSchemaStatus;
  installatietype: InstallatieTypeSchema;
}

export const BEHEER_INSTALLATIETYPE_STATUS_ID = 'beheerInstallatietypeStatus';

export const BeheerInstallatietypeStatus = modal(
  BEHEER_INSTALLATIETYPE_STATUS_ID,
  ({ data, props }) => {
    const { newStatus, installatietype } = data;

    const userId = useUserStore((state) => state.user?.ID);

    const modalState = useModalState();

    const patchInstallatietype = usePatchInstallatietypeId();

    const updateStatus = () => {
      const date = new Date().toISOString();

      modalState.action('save');

      patchInstallatietype.mutate(
        {
          id: installatietype.ID!,
          data: {
            Status: newStatus,
            Modified_By: userId,
            Modified_Date: date,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    };

    return (
      <Modal
        {...props}
        state={modalState.state}
        title={newStatus === 'Actief' ? 'Herstellen' : 'Archiveren'}
      >
        {newStatus === 'Actief' ? (
          <p>
            Je staat op het punt om het installatietype{' '}
            <strong>{installatietype.Installatie_Type}</strong> te herstellen uit het archief. Dit
            onderdeel zal weer standaard zichtbaar worden voor gebruikers van {eStrings.LBL_DIGI_V}.
          </p>
        ) : (
          <p>
            Je staat op het punt om het installatietype{' '}
            <strong>{installatietype.Installatie_Type}</strong> te archiveren. Zolang dit onderdeel
            gearchiveerd is, zal het standaard niet zichtbaar zijn voor gebruikers van{' '}
            {eStrings.LBL_DIGI_V}.
          </p>
        )}

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="save"
            variant={newStatus === 'Actief' ? 'green' : 'red'}
            successLabel={newStatus === 'Actief' ? 'Herstelt' : 'Gearchiveerd'}
            onClick={updateStatus}
          >
            {newStatus === 'Actief' ? 'Herstellen' : 'Archiveren'}
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
