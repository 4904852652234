import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512" ref={svgRef} {...props}><path d="M352 128c0-44.2 35.8-80 80-80s80 35.8 80 80v72c0 13.3 10.7 24 24 24s24-10.7 24-24v-72C560 57.3 502.7 0 432 0S304 57.3 304 128v64H64c-35.3 0-64 28.7-64 64v192c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64h-32v-64zM64 240h320c8.8 0 16 7.2 16 16v192c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16z" /></svg>;
});
SVGIcon.displayName = "RegularLockOpen";
export default SVGIcon;