import { useQueryClient } from '@tanstack/react-query';

import { usePostDynInstallatieType } from '@/api/generated/digiVAPI';
import { InstallatieTypeSchema } from '@/api/generated/digiVAPI.schemas';

import { useInstallatietype, useInstallatietypeId } from '../installatietype';

export function usePostInstallatietype() {
  const queryClient = useQueryClient();

  return usePostDynInstallatieType({
    mutation: {
      onSuccess: async (installatieType) => {
        await queryClient.invalidateQueries({ queryKey: useInstallatietype.key });

        queryClient.setQueryData<InstallatieTypeSchema>(
          useInstallatietypeId.getKey(installatieType.ID!),
          installatieType
        );
      },
    },
  });
}
