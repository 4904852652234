import { useQuery } from '@tanstack/react-query';
import { Link, Outlet, createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { LabelMapSchema } from '@/api';
import { useLabel, useLabelMap, useLabelMapId } from '@/api/queries/label';
import { Explorer } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { useOpenModal } from '@/modals/utils';
import { useIsExactMatch } from '@/utils/hooks/useIsExactMatch';

import { ExplorerButtonLabel } from './-components/ExplorerButtonLabel';
import { ExplorerButtonLabelMap } from './-components/ExplorerButtonLabelMap';
import { SidebarLayout } from './-components/SidebarLayout';

const searchSchema = z.object({
  query: z.string().optional(),
  mapPage: z.number().optional(),
  labelPage: z.number().optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const labelMapQueryOptions = (mapId: string, search: SearchParams) => {
  return useLabelMap.getOptions({
    size: 20,
    page: search.mapPage ?? 1,
    filter: [
      {
        field: 'Map',
        value: mapId,
        op: 'eq',
      },
    ],
    sort: [
      {
        field: 'Naam',
        direction: 'asc',
      },
    ],
  });
};

const labelQueryOptions = (mapId: string, search: SearchParams) => {
  return useLabel.getOptions({
    size: 20,
    page: search.labelPage ?? 1,
    filter: [
      {
        model: 'Label',
        field: 'Map',
        value: mapId,
        op: 'eq',
      },
    ],
    sort: [
      {
        model: 'Label',
        field: 'Label',
        direction: 'asc',
      },
    ],
  });
};

const labelMapIdQueryOptions = (mapId: string) => useLabelMapId.getOptions(mapId);

/**
 * Route
 */
export const Route = createFileRoute('/beheer/labels/$mapId')({
  validateSearch: searchSchema.parse,
  beforeLoad: ({ context, params, navigate }) => {
    /**
     * TEMPORARY REDIRECT UNTIL DB SYNC PROPERLY WORKS
     **/
    navigate({ to: '/', search: { p: 1 } });

    return {
      getTitle: () => {
        const { queryKey } = labelMapIdQueryOptions(params.mapId);

        return context.queryClient.getQueryData<LabelMapSchema>(queryKey)?.Naam;
      },
    };
  },
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { queryClient }, params: { mapId }, deps }) => {
    const [labelMap, mappen, labels] = await Promise.all([
      queryClient.ensureQueryData(labelMapIdQueryOptions(mapId)),
      queryClient.ensureQueryData(labelMapQueryOptions(mapId, deps.search)),
      queryClient.ensureQueryData(labelQueryOptions(mapId, deps.search)),
    ]);

    if (!labelMap) throw notFound();

    if (deps.search.mapPage && (mappen.num_pages ?? 1) < deps.search.mapPage) {
      if (mappen.num_pages) {
        throw redirect({
          to: '/beheer/labels/$mapId',
          search: { p: 1, mapPage: mappen.num_pages, labelPage: labels.page },
          params: { mapId },
        });
      }
    }

    if (deps.search.labelPage && (labels.num_pages ?? 1) < deps.search.labelPage) {
      if (labels.num_pages) {
        throw redirect({
          to: '/beheer/labels/$mapId',
          search: { p: 1, mapPage: mappen.page, labelPage: labels.num_pages },
          params: { mapId },
        });
      }
    }
  },
  component: LabelsComponent,
});

/**
 * Route component
 */
function LabelsComponent() {
  const { mapId } = Route.useParams();

  const openModal = useOpenModal();

  const search = Route.useSearch({
    select: ({ mapPage, labelPage }) => ({ mapPage: mapPage ?? 1, labelPage: labelPage ?? 1 }),
  });

  const map = useQuery(labelMapIdQueryOptions(mapId));
  const mappen = useQuery(labelMapQueryOptions(mapId, search));
  const labels = useQuery(labelQueryOptions(mapId, search));

  /**
   * NOTE: Temporary solution for breadcrumbs
   *
   * When the route is not exactly this route, render the outlet
   */
  const isExactMatch = useIsExactMatch(Route.fullPath);

  if (!isExactMatch) {
    return <Outlet />;
  }

  return (
    <SidebarLayout>
      <Explorer title={map.data?.Naam}>
        <Explorer.ItemHeading
          title="Mappen"
          buttons={[
            {
              key: 'new-label-map',
              label: 'Nieuwe map',
              variant: 'white',
              onClick: () => {
                openModal('beheerLabelsMap', {
                  createMap: true,
                  newMapParentId: mapId,
                });
              },
            },
          ]}
        />

        {!mappen.data?.objects?.length ? (
          <Explorer.Placeholder>Deze map heeft geen mappen</Explorer.Placeholder>
        ) : (
          <>
            <ul>
              {mappen.data?.objects?.map((map) => (
                <li key={`label-map-link-${map.ID}`}>
                  <ExplorerButtonLabelMap asChild map={map}>
                    <Link
                      to="/beheer/labels/$mapId/$nestedMapId"
                      search={{ p: 1 }}
                      params={{ mapId: mapId, nestedMapId: map.ID! }}
                    >
                      {map.Naam}
                    </Link>
                  </ExplorerButtonLabelMap>
                </li>
              ))}
            </ul>

            {(mappen.data?.num_pages ?? 0) > 1 ? (
              <div className="my-8 flex w-full justify-center">
                <PaginationLinks
                  renderLink={({ children, page }) => (
                    <Link
                      to="/beheer/labels/$mapId"
                      params={{ mapId }}
                      search={{ p: 1, labelPage: search.labelPage, mapPage: page }}
                    >
                      {children}
                    </Link>
                  )}
                  currentPage={search.mapPage ?? 1}
                  totalPages={mappen.data?.num_pages ?? 0}
                />
              </div>
            ) : null}
          </>
        )}

        <Explorer.ItemHeading
          title="Labels"
          buttons={[
            {
              key: 'new-label',
              label: 'Nieuw label',
              variant: 'white',
              onClick: () => {
                openModal('beheerLabel', {
                  createLabel: { mapId },
                });
              },
            },
          ]}
        />

        {!labels.data?.objects?.length ? (
          <Explorer.Placeholder>Deze map heeft geen labels</Explorer.Placeholder>
        ) : (
          <>
            <ul>
              {labels.data?.objects?.map((label) => (
                <li key={`label-button-${label.ID}`}>
                  <ExplorerButtonLabel label={label} />
                </li>
              ))}
            </ul>

            {(labels.data?.num_pages ?? 0) > 1 ? (
              <div className="mt-8 flex w-full justify-center">
                <PaginationLinks
                  renderLink={({ children, page }) => (
                    <Link
                      to="/beheer/labels/$mapId"
                      params={{ mapId }}
                      search={{ p: 1, mapPage: search.mapPage, labelPage: page }}
                    >
                      {children}
                    </Link>
                  )}
                  currentPage={search.labelPage}
                  totalPages={labels.data?.num_pages ?? 0}
                />
              </div>
            ) : null}
          </>
        )}
      </Explorer>
    </SidebarLayout>
  );
}
