import { Button } from '@/components';

import { useModalProps } from '../hooks/useModalProps';

interface ModalCancelButtonProps {
  /** By default, the `onClose` method of the `Modal` is called. To overwrite it, pass a custom `onClose` function */
  onClose?: () => void;
  /** Button id property */
  id?: string;
}

export function ModalCancelButton({ onClose, id }: ModalCancelButtonProps) {
  const modalProps = useModalProps();

  return (
    <Button
      type="button"
      variant="white"
      id={id}
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          modalProps?.onClose();
        }
      }}
      disabled={modalProps?.state?.type === 'action'}
    >
      Annuleren
    </Button>
  );
}
