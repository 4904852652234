import { useNavigate } from '@tanstack/react-router';

import { useCreateNewBesluit } from '@/api/queries/besluit';
import { Button } from '@/components';
import { useOpenModal } from '@/modals/utils';

import { besluittekst } from '../Standaardteksten/besluittekst';
import { produreleOverwegingen } from '../Standaardteksten/procedurele-overwegingen';
import { rechtsmiddelen } from '../Standaardteksten/rechtsmiddelen';

interface BesluitCardNewButtonProps {
  bedrijfLineageId: string;
  hasBesluitInOntwerp: boolean;
  hasVigerendBesluit: boolean;
}

export function BesluitCardNewButton({
  bedrijfLineageId,
  hasBesluitInOntwerp,
  hasVigerendBesluit,
}: BesluitCardNewButtonProps) {
  const openModal = useOpenModal();
  const navigate = useNavigate();

  const createNewBesluit = useCreateNewBesluit();

  return (
    <Button
      variant="green"
      data-testid="besluit-card-new-besluit"
      onClick={() => {
        openModal('besluitEigenschappen', {
          title: 'Nieuw besluit',
          submitLabel: 'Besluit aanmaken',
          submitSuccessLabel: 'Besluit aangemaakt',
          hasVigerendBesluit,
          onSubmit: async (formValues) => {
            try {
              const { besluit } = await createNewBesluit.mutateAsync({
                besluit: {
                  ...formValues,
                  Besluit_Tekst: besluittekst,
                  Algemene_Overweging: produreleOverwegingen,
                  Duiding: rechtsmiddelen,
                },
                bedrijfLineageId,
              });

              if (besluit?.ID) {
                navigate({
                  to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/hoofdstuk',
                  params: {
                    besluitId: besluit.ID,
                    bedrijfLineageId,
                  },
                });
              }
            } catch {
              throw new Error('Besluit aanmaken mislukt');
            }
          },
        });
      }}
      disabled={hasBesluitInOntwerp && !hasVigerendBesluit}
    >
      Nieuw besluit aanmaken
    </Button>
  );
}
