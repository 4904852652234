import React, { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { twMerge } from 'tailwind-merge';

import { IconComponent } from '@/icons/iconTypes';

import { FormError } from '../FormError';

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'children'> {
  id: string;
  name: string;
  label: string | ReactNode;
  required?: boolean;
  /** Error coming from `react-hook-form` */
  error?: FieldError;
  disabled?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  variant?: 'default' | 'withBackground';
  textSize?: 'text-sm' | 'text-base';
  icon?: IconComponent;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      name,
      label,
      required,
      className,
      error,
      disabled,
      variant = 'default',
      textSize,
      icon,
      ...props
    },
    ref
  ) => {
    const errorId = `${id}-error`;
    const IconComponent = icon;

    if (!textSize) {
      textSize = variant === 'default' ? 'text-sm' : 'text-base';
    }

    return (
      <div className={twMerge('relative', className)}>
        <input
          type="checkbox"
          name={name}
          id={id}
          ref={ref}
          disabled={disabled}
          aria-describedby={error ? errorId : undefined}
          className={twJoin(
            'peer form-checkbox absolute shrink-0 grow-0 cursor-pointer',
            variant === 'withBackground' && 'left-4 top-[22px] focus:ring-0',
            variant === 'default' && 'left-0 top-0 mt-[5px]'
          )}
          {...props}
        />

        <label
          className={twJoin(
            'flex justify-between',
            disabled && 'cursor-not-allowed text-gray-500',
            !disabled && 'cursor-pointer text-gray-800',
            variant === 'withBackground' &&
              twJoin(
                'rounded-s border border-gray-200 py-4 pl-11 pr-4 transition-colors',
                'peer-checked:bg-theme-blue-light',
                'hover:bg-theme-blue-light peer-hover:bg-theme-blue-light',
                'peer-focus-visible:border-blue-500/60 peer-focus-visible:ring peer-focus-visible:ring-blue-500/30'
              ),
            variant === 'default' && 'pl-5',
            textSize
          )}
          htmlFor={id}
        >
          <span>
            {label}

            {required && <sup className="text-digi-v-color-danger">*</sup>}
          </span>

          {IconComponent && (
            <span className="ml-2 shrink-0 grow-0">
              <IconComponent size={16} className="text-gray-600" aria-hidden="true" />
            </span>
          )}
        </label>

        {error && (
          <span className="block">
            <FormError id={errorId}>{error.message}</FormError>
          </span>
        )}
      </div>
    );
  }
);
