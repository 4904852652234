import { Editor } from '@tiptap/core';
import { findParentNode, isList } from '@tiptap/react';

/** Returns the parent list, and the type of parent list */
export function getParentList(editor: Editor) {
  const extensions = editor.extensionManager.extensions;
  const selection = editor.state.selection;

  const parentList = findParentNode((node) => isList(node.type.name, extensions))(selection);

  return {
    parentList: parentList,
    isBulletList: parentList !== undefined && parentList.node.type.name === 'bulletList',
    isOrderedList: parentList !== undefined && parentList.node.type.name === 'orderedList',
    is123List: parentList !== undefined && parentList.node.attrs['data-list-type'] === '123',
    isAbcList: parentList !== undefined && parentList.node.attrs['data-list-type'] === 'abc',
  };
}
