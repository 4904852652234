import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link, createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useHistorischBesluit } from '@/api/queries/historischBesluit';
import { BesluitCard, Button, HeadingLarge, RoleBasedAccess, TextButton } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { RegularFileImport, RegularFilePlus } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { DateUtils } from '@/utils/dateUtils';

const searchSchema = z.object({
  p: z.number().optional().default(1),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const historischBesluitOptions = (lineageId: string, search: SearchParams) => {
  return useHistorischBesluit.getOptions(
    {
      page: search.p,
      size: 10,
      filter: [
        {
          model: 'HistorischBesluit',
          field: 'Bedrijf_lineage',
          op: 'eq',
          value: lineageId,
        },
      ],
    },
    {
      placeholderData: keepPreviousData,
    }
  );
};

const latestBedrijfQueryOptions = (lineageId: string) => useLatestBedrijf.getOptions(lineageId);

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/bedrijf/_layout/historische-besluiten')({
  validateSearch: searchSchema.parse,
  beforeLoad: () => ({
    getTitle: () => 'Historische besluiten',
  }),
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ params, context: { queryClient }, deps }) => {
    const [historischBesluiten] = await Promise.all([
      queryClient.ensureQueryData(historischBesluitOptions(params.bedrijfLineageId, deps.search)),
      queryClient.ensureQueryData(latestBedrijfQueryOptions(params.bedrijfLineageId)),
    ]);

    if (!historischBesluiten.num_pages) return;

    if (historischBesluiten.num_pages < deps.search.p) {
      throw redirect({
        to: '/$bedrijfLineageId/bedrijf/historische-besluiten',
        search: { p: historischBesluiten.num_pages },
        params,
      });
    }
  },
  component: HistorischeBesluitenComponent,
});

/**
 * Route component
 */
function HistorischeBesluitenComponent() {
  const { bedrijfLineageId } = Route.useParams();
  const search = Route.useSearch();

  const openModal = useOpenModal();

  const bedrijf = useQuery(latestBedrijfQueryOptions(bedrijfLineageId));
  const historischBesluiten = useQuery(historischBesluitOptions(bedrijfLineageId, search));

  if (!bedrijf.data) return null;

  return (
    <>
      <RoleBasedAccess allowed={['Vergunningverlener']} area={['omgevingsdienst']}>
        <div className="mb-10">
          <BesluitCard icon={RegularFilePlus} dashedBorder>
            <BesluitCard.Body>
              <BesluitCard.Heading
                title="Nieuw historisch besluit"
                subtitle="Maak een nieuw historisch besluit aan"
              />
            </BesluitCard.Body>
            <BesluitCard.Aside>
              <Button
                data-testid="besluit-card-new-historisch-besluit"
                variant="green"
                onClick={() => {
                  if (bedrijf.data) {
                    openModal('historischBesluitEigenschappen', {
                      bedrijf: bedrijf.data,
                    });
                  }
                }}
              >
                Aanmaken
              </Button>
            </BesluitCard.Aside>
          </BesluitCard>
        </div>
      </RoleBasedAccess>

      {historischBesluiten.data ? (
        <>
          <HeadingLarge className="mb-4" as="h2" value="Historische besluiten" />
          {historischBesluiten.data.objects?.length ? (
            <>
              <ul className="flex flex-col gap-4">
                {historischBesluiten.data.objects.map((historischBesluit) => (
                  <li key={`historisch-besluit-card-${historischBesluit.ID!}`}>
                    <BesluitCard icon={RegularFileImport}>
                      <BesluitCard.Body>
                        <BesluitCard.Heading
                          title={historischBesluit.Besluit_Nummer}
                          headingAs="h3"
                          subtitle={
                            historischBesluit.Besluit_Datum
                              ? `Besluit genomen op ${DateUtils.formatReadable(
                                  new Date(historischBesluit.Besluit_Datum)
                                )}`
                              : undefined
                          }
                        />

                        <RoleBasedAccess
                          allowed={['Vergunningverlener']}
                          area={['omgevingsdienst']}
                        >
                          <BesluitCard.ButtonRow>
                            <TextButton
                              onClick={() => {
                                if (bedrijf.data) {
                                  openModal('historischBesluitEigenschappen', {
                                    bedrijf: bedrijf.data,
                                    historischBesluitToUpdate: historischBesluit,
                                  });
                                }
                              }}
                              size="small"
                            >
                              Bewerken
                            </TextButton>
                          </BesluitCard.ButtonRow>
                        </RoleBasedAccess>
                      </BesluitCard.Body>
                    </BesluitCard>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="italic text-gray-500">
              Er zijn geen historische besluiten voor dit bedrijf
            </p>
          )}

          {(historischBesluiten.data?.num_pages ?? 0) > 1 ? (
            <div className="mt-6 flex justify-center">
              <PaginationLinks
                renderLink={({ children, page }) => (
                  <Link
                    to="/$bedrijfLineageId/bedrijf/historische-besluiten"
                    params={{ bedrijfLineageId }}
                    search={{ p: page }}
                  >
                    {children}
                  </Link>
                )}
                currentPage={search.p}
                totalPages={historischBesluiten.data?.num_pages ?? 0}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
}
