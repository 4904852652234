import { SubmitHandler, useForm } from 'react-hook-form';

import { ActiviteitCategorieSchema } from '@/api';
import {
  usePatchActiviteitCategorieId,
  usePostActiviteitCategorie,
} from '@/api/queries/activiteitCategorie';
import { Modal, TextArea, useModalState } from '@/components';
import { PLACEHOLDER_UUID } from '@/constants/placeholder-uuid';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface BeheerActiviteitProps {
  updateActiviteit?: ActiviteitCategorieSchema;
}

type FormValues = Pick<ActiviteitCategorieSchema, 'Activiteit_Categorie'>;

export const BEHEER_ACTIVITEIT_ID = 'beheerActiviteit';

export const BeheerActiviteit = modal(BEHEER_ACTIVITEIT_ID, ({ data, props }) => {
  const { updateActiviteit } = data;

  const modalState = useModalState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Activiteit_Categorie: updateActiviteit?.Activiteit_Categorie,
    },
  });

  const postActiviteitCategorie = usePostActiviteitCategorie();
  const patchActiviteitCategorie = usePatchActiviteitCategorieId();

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const userId = useUserStore.getState().user?.ID ?? '';
    const date = new Date().toISOString();

    modalState.action('save');

    if (updateActiviteit) {
      patchActiviteitCategorie.mutate(
        {
          id: updateActiviteit.ID!,
          data: {
            Activiteit_Categorie: formData.Activiteit_Categorie,
            Modified_By: userId,
            Modified_Date: date,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    } else {
      postActiviteitCategorie.mutate(
        {
          data: {
            Activiteit_Categorie: formData.Activiteit_Categorie,
            Created_By: userId,
            Modified_By: userId,
            Created_Date: date,
            Modified_Date: date,
            Status: 'Actief',
            Landelijk: 0,
            // Tenant will be replaced by BE
            Tenant: PLACEHOLDER_UUID,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    }
  };

  return (
    <Modal {...props} state={modalState.state} title="Activiteit">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          {...register('Activiteit_Categorie', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
          error={errors.Activiteit_Categorie}
          label="Titel"
          id="activiteit-categorie-title"
          placeholder="Voer een titel in"
          required
          disableLineBreak
        />
        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
            Opslaan
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
