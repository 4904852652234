import { useInfiniteQuery } from '@tanstack/react-query';

import { FilterSchema, STDMapSchema } from '@/api';
import { useStdMapInfinite } from '@/api/queries/std';
import { Infinite, LoaderContent, TreeNav, TreeNavItem, TreeNavList } from '@/components';
import { useUserStore } from '@/stores/user';

export interface StdMapTreeNavProps {
  parentMap?: STDMapSchema;
  activeSectieId?: string;
  onClickSectie?: (props: { map: STDMapSchema; sectie: STDMapSchema }) => void;
  onClickMap?: (props: { map: STDMapSchema }) => void;
  onMouseEnterSectie?: (props: { map: STDMapSchema; sectie: STDMapSchema }) => void;
}

export const getStdMapTreeNavQueryOptions = ({
  mapId,
  area,
}: { mapId?: string; area?: 'omgevingsdienst' | 'landelijk' } = {}) => {
  const filter: FilterSchema[] = [
    {
      field: 'Status',
      op: 'eq',
      value: 'Actief',
    },
  ];

  if (mapId) {
    filter.push({
      field: 'Map',
      op: 'eq',
      value: mapId,
    });
  } else {
    filter.push({
      field: 'Map',
      op: 'is_null',
      value: '',
    });
  }

  if (area === 'landelijk') {
    filter.push({
      field: 'Landelijk',
      op: 'eq',
      value: '1',
    });
  }

  if (area === 'omgevingsdienst') {
    filter.push({
      field: 'Landelijk',
      op: 'eq',
      value: '0',
    });
  }

  return useStdMapInfinite.getOptions({
    page: 1,
    size: 20,
    filter,
    sort: [{ field: 'Naam', direction: 'asc' }],
  });
};

export const StdMapTreeNav = (props: StdMapTreeNavProps) => {
  const organisatieNaam = useUserStore((state) => state.organisatieNaam);

  return (
    <>
      <Mappen {...props} area="landelijk" title="Landelijk" className="mb-4" />
      <Mappen {...props} area="omgevingsdienst" title={organisatieNaam ?? 'Omgevingsdienst'} />
    </>
  );
};

export const Mappen = ({
  parentMap,
  activeSectieId,
  onClickMap,
  onClickSectie,
  onMouseEnterSectie,
  area,
  title,
  className,
}: StdMapTreeNavProps & {
  area: 'landelijk' | 'omgevingsdienst';
  title?: string;
  className?: string;
}) => {
  const mappen = useInfiniteQuery(getStdMapTreeNavQueryOptions({ mapId: parentMap?.ID, area }));

  const mappenData =
    mappen.data?.pages
      .map((page) => page.data.objects)
      .flat(1)
      .filter((map): map is STDMapSchema => !!map) ?? [];

  if (mappen.isLoading) {
    return (
      <div className="flex flex-col gap-2">
        <LoaderContent w="w-full" h="h-6" />
        <LoaderContent w="w-full" h="h-6" />
      </div>
    );
  }

  /**
   * If it's a root level and there are no mappen, do not render anything
   * If it's a nested level and there are no mappen, a placeholder is rendered
   */
  if (!parentMap && !mappenData.length) {
    return null;
  }

  return (
    <div className={className}>
      {title && <h4 className="mb-2 font-bold">{title}</h4>}

      <Infinite
        hasMore={mappen.hasNextPage}
        onLoadMore={mappen.fetchNextPage}
        loaderClassName="mt-2"
      >
        <TreeNav>
          {mappenData.length ? (
            mappenData.map((map) => {
              if (!parentMap) {
                return (
                  <TreeNavList
                    onClick={() => onClickMap?.({ map })}
                    unmount={false}
                    label={map.Naam}
                    key={map.ID ?? ''}
                  >
                    <Mappen
                      activeSectieId={activeSectieId}
                      onClickMap={onClickMap}
                      onClickSectie={onClickSectie}
                      onMouseEnterSectie={onMouseEnterSectie}
                      area={area}
                      parentMap={map}
                    />
                  </TreeNavList>
                );
              }

              return (
                <TreeNavItem
                  onClick={() => onClickSectie?.({ map: parentMap, sectie: map })}
                  onMouseEnter={() => onMouseEnterSectie?.({ map: parentMap, sectie: map })}
                  active={activeSectieId === map.ID}
                  key={map.ID ?? ''}
                >
                  {map.Naam}
                </TreeNavItem>
              );
            })
          ) : (
            <span className="italic text-gray-500">Deze map is leeg</span>
          )}
        </TreeNav>
      </Infinite>
    </div>
  );
};
