import { useParams } from '@tanstack/react-router';
import { useState } from 'react';

import {
  LinkParagraaf,
  useCreateObjects,
  useHoofdstukHB,
  useHoofdstukId,
} from '@/api/queries/objects';
import { EditorAddCompact } from '@/components/shared/Editor/EditorAddCompact/EditorAddCompact';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { RegularParagraph } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ErrorType } from '@/services/axios';
import { ErrorMessage } from '@/utils/errorMessage';

import { useEditorStore } from '../../-store/useEditorStore';
import { EditorMode } from '../../-utils/useEditorMode';

interface ParagraafAddBarProps {
  previous?: LinkParagraaf;
  next?: LinkParagraaf;
  userHasReservering: boolean;
  editorMode: EditorMode;
  paragraafIndex: number;
}

export const ParagraafAddBar = ({
  previous,
  next,
  userHasReservering,
  editorMode,
  paragraafIndex,
}: ParagraafAddBarProps) => {
  const { besluitId, hoofdstukId } = useParams({
    from: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
  });

  const [error, setError] = useState<string | undefined>(undefined);
  const createParagraaf = useCreateObjects();
  const openModal = useOpenModal();

  const hoofdstuk = useHoofdstukId(hoofdstukId);
  const hb = useHoofdstukHB({ besluitId, hoofdstukId });

  const areAddBarsShown = useEditorStore((state) => state.areAddBarsShown);

  if (!areAddBarsShown || !userHasReservering) {
    return null;
  }

  /**
   * Callbacks for when the create mutation fails or succeeds
   */
  const handleError = (error: ErrorType<string>) => {
    setError(ErrorMessage.getLocalErrorMessage(error));
  };

  const handleSuccess = () => {
    setError(undefined);
  };

  /**
   * Validate if a paragraaf can be added
   */
  if (hoofdstuk.isLoading || hb.isLoading || !hoofdstuk.data || !hb.data) return null;

  // When its in standard mode, paragrafen can only be added at the end of the hoofdstuk
  if (editorMode === 'standard' && next) return null;

  // When the hoofdstuk is ingetrokken, do not show the add bar
  if (hoofdstuk.data.Stop_Lineage) return null;

  // When the hoofdstuk is new and created in another besluit, do not show the add bar
  if (!hoofdstuk.data.Vorig && hoofdstuk.data.Ontwerp && hb.data.Type === 'MBIO') return null;

  /**
   * If all validations pass, show the add bar
   */
  return (
    <>
      <EditorAddCompact testId={`add-bar-${paragraafIndex}`}>
        <EditorAddCompact.Button
          icon={RegularParagraph}
          isLoading={createParagraaf.isPending}
          data-testid="add-item-button-lege-paragraaf"
          onClick={() => {
            createParagraaf.mutate(
              {
                type: 'paragrafen',
                besluitId,
                hoofdstukId,
                previousRang: previous?.PHB.Rang,
                nextRang: next?.PHB.Rang,
                paragrafen: [{ titel: '' }],
              },
              {
                onSuccess: handleSuccess,
                onError: handleError,
              }
            );
          }}
        >
          Lege paragraaf
        </EditorAddCompact.Button>

        <EditorAddCompact.Button
          icon={RegularParagraph}
          data-testid="add-item-button-standaardtekst-paragraaf"
          onClick={() => {
            openModal('editorStdObjects', {
              allow: 'paragraaf',
              besluitId,
              hoofdstukId,
              previousRang: previous?.PHB.Rang,
              nextRang: next?.PHB.Rang,
              onSubmit: handleSuccess,
            });
          }}
        >
          Standaardtekst paragraaf
        </EditorAddCompact.Button>
      </EditorAddCompact>

      {error && (
        <div className="w-full">
          <WarningInline description={error} center />
        </div>
      )}
    </>
  );
};
