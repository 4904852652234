import { useMutation, useQueryClient } from '@tanstack/react-query';

import { STDSubparagraafSchema } from '@/api';
import { postDynStdSubparagraaf } from '@/api/generated/digiVAPI';
import { useUserStore } from '@/stores/user';

import { useStdObjects } from '../stdObjects';

export const useCreateStdSubparagraaf = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      Paragraaf,
      Titel,
      Transparent,
      Toelichting,
      Rang,
    }: Pick<
      STDSubparagraafSchema,
      'Paragraaf' | 'Titel' | 'Transparent' | 'Toelichting' | 'Rang'
    >) => {
      const userId = useUserStore.getState().user?.ID ?? '';

      const newStdSubparagraaf = postDynStdSubparagraaf({
        Paragraaf,
        Titel,
        Toelichting,
        Rang,
        Transparent,
        Created_By: userId,
        Created_Date: new Date().toISOString(),
        Modified_By: userId,
        Modified_Date: new Date().toISOString(),
        Landelijk: 0,
      });

      return newStdSubparagraaf;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: useStdObjects.key });
    },
  });
};
