import {
  getDynHistorischBesluit,
  getDynHistorischBesluitId,
  getDynMetaVoorschriftHistorischBesluit,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useHistorischBesluit = createQuery({
  key: ['historischBesluit'],
  queryFn: getDynHistorischBesluit,
});

export const useHistorischBesluitId = createQuery({
  key: ['historischBesluitId'],
  queryFn: getDynHistorischBesluitId,
});

export const useHistorischBesluitLink = createQuery({
  key: ['historischBesluitLink'],
  queryFn: getDynMetaVoorschriftHistorischBesluit,
});
