import { useCallback, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';

import { LinkParagraaf, Links, useHoofdstukId, useParagraafId } from '@/api/queries/objects';
import { LoaderContent } from '@/components';
import { MountWhenInView } from '@/components/shared/MountWhenInView';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { getObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';

import { ParagraafContext, ParagraafError } from './ParagraafContext';
import { ParagraafInput } from './ParagraafInput';

interface ParagraafProps {
  userHasReservering: boolean;
  link: LinkParagraaf;
  links: Links;
  dragHandleRef?: ConnectDragSource;
  editorMode: EditorMode;
  paragraafIndex: number;
}

export const Paragraaf = (props: ParagraafProps) => {
  return (
    <MountWhenInView placeholder={<Skeleton />}>
      <ParagraafInner {...props} />
    </MountWhenInView>
  );
};

const ParagraafInner = ({
  userHasReservering,
  link,
  editorMode,
  links,
  dragHandleRef,
  paragraafIndex,
}: ParagraafProps) => {
  const id = link.PHB.Paragraaf;

  /**
   * Error handlers
   */
  const [errors, setErrors] = useState<ParagraafError[]>([]);

  const addError = useCallback((error: ParagraafError) => {
    setErrors((prev) => {
      const newErrors = prev.filter((e) => e.type !== error.type);

      return [...newErrors, error];
    });
  }, []);

  const removeError = useCallback((type: ParagraafError['type']) => {
    setErrors((prev) => prev.filter((e) => e.type !== type));
  }, []);

  /**
   * Fetch data
   */
  const paragraaf = useParagraafId(id);
  const hoofdstukNummer = useHoofdstukId(link.PHB.Hoofdstuk, {
    select: ({ Nummer }) => Nummer,
  });

  /**
   * Create nummer
   */
  const nummer = `${hoofdstukNummer.data}.${paragraaf.data?.Nummer}`;

  /**
   * Return loading state when paragraaf is loading or not available
   */
  if (paragraaf.isLoading || !paragraaf.data || hoofdstukNummer.isLoading) {
    return <Skeleton />;
  }

  if (paragraaf.data.Stop_Lineage && link.PHB.Type === 'Publicatie') {
    return null;
  }

  /**
   * Check if paragraaf is editable
   */
  const objectState = getObjectState({
    object: paragraaf.data,
    linkType: link.PHB.Type,
    userHasReservering,
  });

  return (
    <ParagraafContext.Provider
      value={{
        editorMode,
        link,
        links,
        nummer,
        hoofdstukNummer: hoofdstukNummer.data,
        objectState,
        paragraaf: paragraaf.data,
        userHasReservering,
        errors,
        addError,
        removeError,
      }}
    >
      <div data-testid={`paragraaf-${paragraafIndex}`}>
        <ParagraafInput dragHandleRef={dragHandleRef} />

        {errors.map((error) => (
          <WarningInline
            key={error.type}
            description={error.message}
            tryAgain={error.tryAgain}
            center
          />
        ))}
      </div>
    </ParagraafContext.Provider>
  );
};

const Skeleton = () => (
  <>
    <div className="flex h-[38px] w-full items-center">
      <LoaderContent w="w-20" h="h-6" className="ml-[68px] mr-[14px] block" />
      <LoaderContent w="w-full" h="h-[28px]" className="block" />
    </div>
  </>
);
