import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useEffect, useRef } from 'react';
import { twJoin } from 'tailwind-merge';

import { RegularSpinner, RegularXmark } from '@/icons/components';

import { IconButton } from '../IconButton';
import { ModalActionButton } from './components/ModalActionButton';
import { ModalCancelButton } from './components/ModalCancelButton';
import { ModalFooter } from './components/ModalFooter';
import { ModalPropsContext } from './hooks/useModalProps';
import { ModalState } from './hooks/useModalState';

export interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  afterLeave?: () => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  testId?: string;
  isLoading?: boolean;
  error?: {
    title: string;
    description: string;
  };
  state?: ModalState;
  title?: string | ReactNode;
}

export const Modal = (props: ModalProps) => {
  return (
    <ModalPropsContext.Provider value={props}>
      <ModalImplementation {...props} />
    </ModalPropsContext.Provider>
  );
};

const ModalImplementation = ({
  children,
  open,
  onClose,
  afterLeave,
  testId,
  size = 'medium',
  isLoading,
  state,
  title,
}: ModalProps) => {
  const onCloseTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * When the modal is set to success state, it should close after 2 seconds.
   */
  useEffect(() => {
    if (state?.type === 'success' && !onCloseTimeoutRef.current) {
      onCloseTimeoutRef.current = setTimeout(() => {
        if (state.onClose) {
          state.onClose?.();
        } else {
          onClose();
        }

        if (onCloseTimeoutRef.current) clearTimeout(onCloseTimeoutRef.current);
      }, 2000);
    }
  }, [state, onClose]);

  return (
    <Transition appear unmount={true} show={open} as={Fragment}>
      <Dialog
        as="div"
        onClose={() => {
          // When the modal is performing an action, it should not be closeable
          if (state?.type !== 'action') {
            onClose();
          }
        }}
        open={true}
        className="relative z-modal"
      >
        <Transition.Child
          as="div"
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          afterLeave={afterLeave}
        >
          <div
            className="fixed inset-0 flex items-center justify-center p-4 transition-all"
            data-testid={testId}
          >
            {isLoading ? (
              <div
                className="mx-auto flex size-11 items-center justify-center rounded bg-white shadow-xl"
                data-testid="modal-is-loading"
              >
                <RegularSpinner
                  size={16}
                  className="animate-spin"
                  aria-label="Popup wordt geladen"
                />
              </div>
            ) : (
              <Dialog.Panel
                className={twJoin(
                  'mx-auto max-h-full w-full overflow-auto rounded bg-white shadow-xl',
                  size === 'small' && 'max-w-[320px]',
                  size === 'medium' && 'max-w-[640px]',
                  size === 'large' && 'max-w-[980px]',
                  size === 'xlarge' && 'max-w-[1280px]'
                )}
              >
                <div className="px-6 py-5">
                  {title && (
                    <div className="mb-4 flex items-center justify-between border-b border-b-gray-300 pb-4 leading-6">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-theme-blue"
                      >
                        {title}
                      </Dialog.Title>

                      <IconButton
                        icon={RegularXmark}
                        ariaLabel="Sluit venster"
                        onClick={onClose}
                        type="button"
                        disabled={state?.type === 'action'}
                      />
                    </div>
                  )}

                  {children}
                </div>
              </Dialog.Panel>
            )}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

Modal.Footer = ModalFooter;
Modal.CancelButton = ModalCancelButton;
Modal.ActionButton = ModalActionButton;
