import Bold from '@tiptap/extension-bold';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import Italic from '@tiptap/extension-italic';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Underline from '@tiptap/extension-underline';
import { AnyExtension, Editor } from '@tiptap/react';
import { SyntheticEvent } from 'react';

import {
  Custom123,
  CustomAbc,
  RegularBan,
  RegularBold,
  RegularH1,
  RegularH2,
  RegularItalic,
  RegularList,
  RegularMerge,
  RegularPencil,
  RegularSplit,
  RegularSubscript,
  RegularSuperscript,
  RegularTable,
  RegularTrash,
  RegularUnderline,
  SolidCircle,
  SolidPlus,
} from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

import { BulletList } from '../extensions/BulletList';
import { ListItem } from '../extensions/ListItem';
import { OrderedList } from '../extensions/OrderedList';
import { getParentList } from '../utils/getParentList';

/**
 * Types
 */

export type TextEditorMenuOption =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'superscript'
  | 'subscript'
  | 'bulletList'
  | 'orderedList'
  | 'abcList'
  | 'p'
  | 'h1'
  | 'h2'
  | 'table'
  | 'highlight';

interface TextEditorOptionConfig {
  /** Extensions that are required to use the option */
  extensions?: () => AnyExtension[];
  /** Creates a config with onClick handlers etc. */
  create: (editor: Editor) => {
    button?: TextEditorOptionButton;
    dropdown?: TextEditorOptionDropdown;
  };
}

export interface TextEditorOptionButton {
  /** Name of the option */
  option: TextEditorMenuOption;
  /** Icon used in the button */
  icon?: IconComponent;
  /** Color of the icon */
  iconColor?: string;
  /** Aria label for the button */
  ariaLabel: string;
  /** Optional display label for the button */
  label?: string;
  /** onClick handler for the button */
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  /** If active, the button will be shown in an active state */
  isActive?: () => boolean;
  /** Button will be disabled if return value is true */
  isDisabled?: () => boolean;
  /** Test id */
  testId?: string;
}

export interface TextEditorOptionDropdown {
  /** Label for the dropdown */
  label?: string;
  /** Aria label for the button */
  ariaLabel: string;
  /** Icon for the dropdown */
  icon?: IconComponent;
  /** If true, the dropdown will be shown */
  isShown?: () => boolean;
  /** Buttons inside the dropdown */
  buttons: Omit<TextEditorOptionButton, 'option'>[];
}

/**
 * Config
 */

/** Map of all configs */
export const CONFIG: Map<TextEditorMenuOption, TextEditorOptionConfig> = new Map();

/** All configs grouped */
const CONFIG_GROUPING: TextEditorMenuOption[][] = [
  ['p', 'h1', 'h2'],
  ['bold', 'italic', 'underline'],
  ['bulletList', 'orderedList', 'abcList'],
  ['subscript', 'superscript'],
  ['highlight'],
  ['table'],
];

/**
 * Option Config
 **/

/** Bold */
CONFIG.set('bold', {
  extensions: () => [Bold],
  create: (editor: Editor) => ({
    button: {
      option: 'bold',
      icon: RegularBold,
      ariaLabel: 'Zet bold aan/uit',
      testId: 'texteditor-bold',
      onClick: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
  }),
});

/** Italic */
CONFIG.set('italic', {
  extensions: () => [Italic],
  create: (editor: Editor) => ({
    button: {
      option: 'italic',
      icon: RegularItalic,
      ariaLabel: 'Zet italic aan/uit',
      testId: 'texteditor-italic',
      onClick: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
  }),
});

/** Underline */
CONFIG.set('underline', {
  extensions: () => [Underline],
  create: (editor: Editor) => ({
    button: {
      option: 'underline',
      icon: RegularUnderline,
      ariaLabel: 'Zet underline aan/uit',
      testId: 'texteditor-underline',
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline'),
    },
  }),
});

/** Superscript */
CONFIG.set('superscript', {
  extensions: () => [Superscript],
  create: (editor: Editor) => ({
    button: {
      option: 'superscript',
      icon: RegularSuperscript,
      ariaLabel: 'Zet superscript aan/uit',
      testId: 'texteditor-superscript',
      onClick: () => editor.chain().focus().toggleSuperscript().run(),
      isActive: () => editor.isActive('superscript'),
    },
  }),
});

/** Subscript */
CONFIG.set('subscript', {
  extensions: () => [Subscript],
  create: (editor: Editor) => ({
    button: {
      option: 'subscript',
      icon: RegularSubscript,
      ariaLabel: 'Zet subscript aan/uit',
      testId: 'texteditor-subscript',
      onClick: () => editor.chain().focus().toggleSubscript().run(),
      isActive: () => editor.isActive('subscript'),
    },
  }),
});

/** Bullet list */
CONFIG.set('bulletList', {
  extensions: () => [BulletList, ListItem],
  create: (editor: Editor) => ({
    button: {
      option: 'bulletList',
      icon: RegularList,
      ariaLabel: 'Zet bulletList aan/uit',
      testId: 'texteditor-bulletlist',
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => {
        const parentList = getParentList(editor);

        return parentList.isBulletList;
      },
    },
  }),
});

/** Ordered list */
CONFIG.set('orderedList', {
  extensions: () => [OrderedList, ListItem],
  create: (editor: Editor) => ({
    button: {
      option: 'orderedList',
      icon: Custom123,
      ariaLabel: 'Zet orderedList aan/uit',
      testId: 'texteditor-orderedlist',
      onClick: () => {
        const parentList = getParentList(editor);

        if (parentList.isOrderedList && parentList.is123List) {
          editor.chain().focus().toggleOrderedList().run();
          return;
        }

        if (parentList.isOrderedList && parentList.isAbcList) {
          editor.chain().focus().set123List().run();
          return;
        }

        if (!parentList.isOrderedList) {
          editor.chain().focus().toggleOrderedList().run();
        }
      },
      isActive: () => {
        const parentList = getParentList(editor);

        return parentList.isOrderedList && parentList.is123List;
      },
    },
  }),
});

CONFIG.set('abcList', {
  extensions: () => [OrderedList, ListItem],
  create: (editor: Editor) => ({
    button: {
      option: 'abcList',
      icon: CustomAbc,
      ariaLabel: 'Zet abcList aan/uit',
      testId: 'texteditor-abclist',
      onClick: () => {
        const parentList = getParentList(editor);

        if (parentList.isOrderedList && parentList.isAbcList) {
          editor.chain().focus().toggleOrderedList().run();
          return;
        }

        if (parentList.isOrderedList && parentList.is123List) {
          editor.chain().focus().setAbcList().run();
          return;
        }

        if (!parentList.isOrderedList) {
          editor.chain().focus().toggleOrderedList().setAbcList().run();
        }
      },
      isActive: () => {
        const parentList = getParentList(editor);

        return parentList.isOrderedList && parentList.isAbcList;
      },
    },
  }),
});

/** Heading 1 */
CONFIG.set('h1', {
  extensions: () => [Heading],
  create: (editor: Editor) => ({
    button: {
      option: 'h1',
      icon: RegularH1,
      ariaLabel: 'Zet heading 1 aan/uit',
      testId: 'texteditor-h1',
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive('heading', { level: 2 }),
    },
  }),
});

/** Heading 2 */
CONFIG.set('h2', {
  extensions: () => [Heading],
  create: (editor: Editor) => ({
    button: {
      option: 'h2',
      icon: RegularH2,
      ariaLabel: 'Zet heading 2 aan/uit',
      testId: 'texteditor-h2',
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActive: () => editor.isActive('heading', { level: 3 }),
    },
  }),
});

/** Highlight */

/**
 * To hide the highlights added by the editor, use `hide-texteditor-highlights` class on the wrapper element
 */
CONFIG.set('highlight', {
  extensions: () => [
    Highlight.configure({
      multicolor: true,
      HTMLAttributes: { class: 'texteditor-highlight' },
    }),
  ],
  create: (editor: Editor) => ({
    dropdown: {
      isShown: () => true,
      icon: RegularPencil,
      buttons: [
        {
          icon: SolidCircle,
          iconColor: 'text-highlight-yellow',
          ariaLabel: 'Markeer geel',
          onClick: () => editor.chain().focus().setHighlight({ color: 'yellow' }).run(),
        },
        {
          icon: SolidCircle,
          iconColor: 'text-highlight-red',
          ariaLabel: 'Markeer rood',
          onClick: () => editor.chain().focus().setHighlight({ color: 'red' }).run(),
        },
        {
          icon: SolidCircle,
          iconColor: 'text-highlight-green',
          ariaLabel: 'Markeer groen',
          onClick: () => editor.chain().focus().setHighlight({ color: 'green' }).run(),
        },
        {
          icon: SolidCircle,
          iconColor: 'text-highlight-blue',
          ariaLabel: 'Markeer blauw',
          onClick: () => editor.chain().focus().setHighlight({ color: 'blue' }).run(),
        },
        {
          icon: RegularBan,
          ariaLabel: 'Verwijder markering',
          onClick: () => editor.chain().focus().unsetHighlight().run(),
        },
      ],
      ariaLabel: 'Arceren',
    },
  }),
});

/** Table */
CONFIG.set('table', {
  extensions: () => [Table, TableRow, TableHeader, TableCell],
  create: (editor: Editor) => ({
    button: {
      option: 'table',
      icon: RegularTable,
      ariaLabel: 'Maak een tabel aan',
      isDisabled: () => editor.isActive('table'),
      onClick: () =>
        editor
          .chain()
          .focus()
          .insertTable({
            withHeaderRow: false,
            rows: 3,
            cols: 3,
          })
          .run(),
      isActive: () => false,
    },
    dropdown: {
      isShown: () => editor.isActive('table'),
      icon: RegularTable,
      label: 'Tabelopties',
      ariaLabel: 'Tabelopties',
      buttons: [
        {
          label: 'Kolom voor invoegen',
          ariaLabel: 'Kolom voor invoegen',
          icon: SolidPlus,
          onClick: () => editor.chain().focus().addColumnBefore().run(),
        },
        {
          label: 'Kolom na invoegen',
          ariaLabel: 'Kolom na invoegen',
          icon: SolidPlus,
          onClick: () => editor.chain().focus().addColumnAfter().run(),
        },
        {
          label: 'Rij voor invoegen',
          ariaLabel: 'Rij voor invoegen',
          icon: SolidPlus,
          onClick: () => editor.chain().focus().addRowBefore().run(),
        },
        {
          label: 'Rij na invoegen',
          ariaLabel: 'Rij na invoegen',
          icon: SolidPlus,
          onClick: () => editor.chain().focus().addRowAfter().run(),
        },
        {
          label: 'Kolom verwijderen',
          ariaLabel: 'Kolom verwijderen',
          icon: RegularTrash,
          onClick: () => editor.chain().focus().deleteColumn().run(),
        },
        {
          label: 'Rij verwijderen',
          ariaLabel: 'Rij verwijderen',
          icon: RegularTrash,
          onClick: () => editor.chain().focus().deleteRow().run(),
        },
        {
          label: 'Tabel verwijderen',
          ariaLabel: 'Tabel verwijderen',
          icon: RegularTrash,
          onClick: () => editor.chain().focus().deleteTable().run(),
        },
        {
          label: 'Cellen samenvoegen',
          ariaLabel: 'Cellen samenvoegen',
          icon: RegularMerge,
          onClick: () => editor.chain().focus().mergeCells().run(),
          isDisabled: () => !editor.can().mergeCells(),
        },
        {
          label: 'Cel splitsen',
          ariaLabel: 'Cel splitsen',
          icon: RegularSplit,
          onClick: () => editor.chain().focus().splitCell().run(),
          isDisabled: () => !editor.can().splitCell(),
        },
      ],
    },
  }),
});

/**
 * Utilities
 **/

/** Returns the config with return value null-banged for convenience */
function getConfig(option: TextEditorMenuOption): TextEditorOptionConfig {
  return CONFIG.get(option)!;
}

/** Returns the config, filtered and grouped */
export const getGroupedConfig = (options: TextEditorMenuOption[]): TextEditorOptionConfig[][] => {
  const groupedFunctions = CONFIG_GROUPING.map((group) => {
    return group
      .filter((option) => options.includes(option))
      .map(getConfig)
      .filter(Boolean);
  }).filter((group) => !!group.length);

  return groupedFunctions;
};
