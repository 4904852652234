import { useQueryClient } from '@tanstack/react-query';

import { useDeleteFixBesluitId } from '@/api/generated/digiVAPI';

import { useBesluit, useBesluitId } from '../besluit';

export function useDeleteBesluitId({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return useDeleteFixBesluitId({
    mutation: {
      mutationKey,
      onSuccess: async (besluit) => {
        return await Promise.all([
          queryClient.invalidateQueries({ queryKey: useBesluitId.getKey(besluit.ID!) }),
          queryClient.invalidateQueries({ queryKey: useBesluit.key }),
        ]);
      },
    },
  });
}
