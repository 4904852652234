import React, { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { twMerge } from 'tailwind-merge';

import { FormError } from '../FormError';

export interface RadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'children'> {
  id: string;
  name: string;
  label: string | ReactNode;
  required?: boolean;
  /** Error coming from `react-hook-form` */
  error?: FieldError;
  disabled?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  variant?: 'default' | 'withBackground';
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    { id, name, label, required, className, error, disabled, variant = 'default', ...props },
    ref
  ) => {
    const errorId = `${id}-error`;

    return (
      <div className={twMerge('relative', className)}>
        <input
          type="radio"
          name={name}
          id={id}
          ref={ref}
          disabled={disabled}
          aria-describedby={error ? errorId : undefined}
          className={twJoin(
            'peer form-radio absolute shrink-0 grow-0 cursor-pointer',
            variant === 'withBackground' && 'left-4 top-5 focus:ring-0',
            variant === 'default' && 'left-0 top-0 mt-[5px]'
          )}
          {...props}
        />

        <label
          className={twJoin(
            'block',
            disabled && 'cursor-not-allowed text-gray-500',
            !disabled && 'cursor-pointer text-gray-800',
            variant === 'withBackground' &&
              twJoin(
                'rounded-s border border-gray-200 py-4 pl-11 pr-4 transition-colors',
                'peer-checked:bg-theme-blue-light',
                'hover:bg-theme-blue-light peer-hover:bg-theme-blue-light',
                'peer-focus-visible:border-blue-500/60 peer-focus-visible:ring peer-focus-visible:ring-blue-500/30'
              ),
            variant === 'default' && 'pl-5'
          )}
          htmlFor={id}
        >
          {label}

          {required && <sup className="text-digi-v-color-danger">*</sup>}
        </label>

        {error && (
          <span className="block">
            <FormError id={errorId}>{error.message}</FormError>
          </span>
        )}
      </div>
    );
  }
);
