import { useEventListener, useThrottledCallback } from '@react-hookz/web';
import { useRef } from 'react';

import { useExtendHoofdstukReservering } from '@/api/queries/hoofdstukReservering';
import { useHoofdstukReserveringByLineageId } from '@/api/queries/hoofdstukReservering/selectors/useHoofdstukReserveringByLineageId';
import { useUserStore } from '@/stores/user';

/**
 * This hook automatically extends the hoofdstuk reservering when the user interacts with the page.
 */
export const useAutoExtendReservering = (hoofdstukLineageId?: string) => {
  const hoofdstukReservering = useHoofdstukReserveringByLineageId(hoofdstukLineageId);
  const userId = useUserStore((state) => state.user?.ID);

  const isGereserveerdByCurrentUser = hoofdstukReservering.data?.Gebruiker === userId;

  /**
   * Store extend function in ref to prevent stale closures.
   * This is necessary because the extend function is throttled, and the hoofdstukLineageId can change.
   */
  const extendHoofdstukReservering = useExtendHoofdstukReservering();
  const extendRef = useRef(() => {});

  extendRef.current = () => {
    if (!hoofdstukReservering.isLoading && hoofdstukLineageId && isGereserveerdByCurrentUser) {
      extendHoofdstukReservering.mutate(hoofdstukLineageId);
    }
  };

  /**
   * Throttle the extend function to prevent multiple calls
   */
  const extend = useThrottledCallback(() => extendRef.current(), [], 1000 * 60 * 1);

  /**
   * Trigger extend function on click and keydown
   */
  useEventListener(window, 'click', () => extend());
  useEventListener(window, 'keydown', () => extend());
};
