import { useQueryClient } from '@tanstack/react-query';

import { usePatchFixBesluitId } from '@/api/generated/digiVAPI';

import { useBesluit } from '../besluit';
import { useBesluitId } from '../besluit';

export function usePatchBesluitId({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return usePatchFixBesluitId({
    mutation: {
      mutationKey,
      onSuccess: (besluit) => {
        if (besluit.ID) {
          queryClient.setQueryData(useBesluitId.getKey(besluit.ID), besluit);
        }

        return queryClient.invalidateQueries({ queryKey: useBesluit.key });
      },
    },
  });
}
