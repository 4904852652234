import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchDynStdSubparagraafId } from '@/api/generated/digiVAPI';
import { getRangBetween } from '@/utils/getRangBetween';

import { useStdObjects } from '../stdObjects';
import { StdParagraaf } from '../utils/getStdObjects';

/**
 * useMutation for ordering subparagrafen
 */
export const useOrderStdSubparagraaf = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      from,
      to,
      method,
      subparagrafen,
    }: {
      from: number;
      to: number;
      method: 'prepend' | 'append';
      subparagrafen: StdParagraaf['subparagrafen'];
    }) => {
      const newIndex = method === 'prepend' ? to - 1 : to;

      const subparagraaf = subparagrafen[from];
      const newPrevious = subparagrafen[newIndex];
      const newNext = subparagrafen[newIndex + 1];

      if (!subparagraaf) {
        throw new Error('Subparagraaf niet gevonden');
      }

      if (
        newPrevious?.data.ID === subparagraaf.data.ID ||
        newNext?.data.ID === subparagraaf.data.ID
      ) {
        throw new Error('Paragraaf mag niet naar dezelfde positie verplaatst worden');
      }

      const newRang = getRangBetween(newPrevious?.data.Rang, newNext?.data.Rang);

      /**
       * Patch SPPB with new Vorig and Volgend prop
       */
      return await patchDynStdSubparagraafId(subparagraaf.data.ID!, {
        Rang: newRang,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useStdObjects.key });
    },
  });
};
