import { Link } from '@tanstack/react-router';
import React, { forwardRef } from 'react';

import { TextButton, Warning, WarningProps } from '@/components';
import { RegularChevronRight, SolidSpinner } from '@/icons/components';

function VoorschriftenLayoutInner(
  {
    children,
    linkProps,
    backLabel,
    testId,
    isLoading,
    warning,
  }: {
    children: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    linkProps: any;
    backLabel: string;
    testId?: string;
    isLoading?: boolean;
    warning?: WarningProps;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div className="relative bg-gray-100 pb-10 pt-6">
      {linkProps && (
        <div className="mx-auto mb-7 max-w-7xl sm:px-6 lg:px-8">
          <TextButton className="flex items-center" size="small" asChild>
            <Link aria-label="Terug naar overzicht" {...linkProps}>
              <RegularChevronRight className="mr-2 rotate-180" />

              {backLabel}
            </Link>
          </TextButton>
        </div>
      )}

      <div className="mx-auto min-h-[50vh] max-w-[896px] pb-8">
        {warning && (
          <div className="mb-4">
            <Warning {...warning} />
          </div>
        )}

        <main className="bg-white shadow-lg" ref={ref} data-testid={testId}>
          {children}
        </main>
      </div>

      {isLoading && (
        <div
          data-testid="locked-standaardteksten-overlay"
          className="absolute inset-0 mx-auto min-h-[50vh] max-w-[896px] bg-gray-100/50 pb-8"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <SolidSpinner size={16} className="rotating text-digi-v-gray-light" />
          </div>
        </div>
      )}
    </div>
  );
}

export const VoorschriftenLayout = forwardRef(VoorschriftenLayoutInner);
