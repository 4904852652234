import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';

import { DocumentTitle } from '@/components';
import { Error } from '@/components/templates/Error';
import { eStrings } from '@/constants/strings';

export const Error404 = () => {
  return (
    <>
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, '404']} />

      <Helmet>
        <meta property="og:title" content={`${eStrings.LBL_DIGI_V} • 404`} />
      </Helmet>

      {/*
        The error page might be rendered as a nested component, so we need to make sure it's
        rendered directly in the body, overlaying all other elements on the page.
      */}
      {createPortal(
        <div className="fixed inset-0 z-[9999] size-full">
          <Error
            title="Foutje!"
            link={{
              label: 'Terug naar de zoekpagina',
              props: {
                href: '/',
              },
            }}
          >
            <p className="mb-4">
              Er is iets fout gegaan. Wijzigingen die je hebt opgeslagen staan veilig in Digi-V.
              Niet opgeslagen wijzigingen zijn waarschijnlijk verloren gegaan.{' '}
            </p>
            <p>
              Uiteraard gaan we er voor zorgen dat dit in de toekomst niet meer kan gebeuren. Mocht
              je willen helpen deze fout op te lossen, dan horen we graag wat er gebeurde voor dat
              dit scherm naar voren kwam. Dit kan via{' '}
              <a
                className="text-theme-blue underline"
                href="mailto:digi-v@pzh.nl"
                aria-label="link to send an email to support"
              >
                digi-v@pzh.nl
              </a>{' '}
              t.a.v. developers.
            </p>
          </Error>
        </div>,
        document.body
      )}
    </>
  );
};
