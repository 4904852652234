import { useQuery } from '@tanstack/react-query';

import { useBedrijf } from '../bedrijf';

const getLatestBedrijfQueryOptions = (lineageId?: string) => {
  return useBedrijf.getOptions(
    {
      page: 1,
      size: 1,
      filter: [
        { model: 'Bedrijf', field: 'Lineage', op: 'eq', value: lineageId ?? '' },
        { model: 'Bedrijf', field: 'Volgend', op: 'is_null', value: '' },
      ],
    },
    {
      enabled: !!lineageId,
      select: ({ objects }) => objects?.[0],
    }
  );
};

const useLatestBedrijf = (lineageId?: string) => useQuery(getLatestBedrijfQueryOptions(lineageId));
useLatestBedrijf.getOptions = getLatestBedrijfQueryOptions;

export { useLatestBedrijf };
