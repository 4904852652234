import { Slot, Slottable } from '@radix-ui/react-slot';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { IconButton, OptionsMenu, OptionsMenuOption } from '@/components';
import {
  RegularChevronRight,
  RegularEllipsis,
  RegularFileLines,
  RegularTags,
  RegularXmark,
  SolidFolder,
  SolidFolderXmark,
} from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

export interface ExplorerButtonProps {
  onClick?: () => void;
  icon?: 'folder' | 'file' | 'label';
  inactive?: boolean;
  title?: string;
  asChild?: boolean;
  children?: ReactNode;
  options?: OptionsMenuOption[];
  testIdIcon?: string;
  'data-testid'?: string;
}

export function ExplorerButton({
  onClick,
  icon,
  inactive,
  title,
  options,
  asChild,
  children,
  testIdIcon,
  ...attributes
}: ExplorerButtonProps) {
  const Component = asChild ? Slot : 'button';

  let Icon: IconComponent | undefined;
  let iconColor: string = '';

  switch (icon) {
    case 'folder':
      Icon = inactive ? SolidFolderXmark : SolidFolder;
      iconColor = 'text-theme-yellow';
      break;
    case 'file':
      Icon = RegularFileLines;
      iconColor = 'text-theme-blue';
      break;
    case 'label':
      Icon = inactive ? RegularXmark : RegularTags;
      iconColor = 'text-theme-blue';
      break;
  }

  return (
    <div className="relative -mb-px border-y border-gray-200">
      <Component
        {...attributes}
        className={twJoin(
          'flex w-full items-center py-2 pl-2 pr-10',
          '[&:is(a)]:hover:bg-gray-50 [&:is(button)]:hover:bg-gray-50',
          inactive && 'text-gray-500',
          !inactive && 'text-gray-800'
        )}
        onClick={onClick}
      >
        {Icon ? (
          <span className="block w-7 shrink-0 justify-start">
            <Icon
              size={16}
              className={twJoin('text-lg', inactive ? 'text-gray-400' : iconColor)}
              data-testid={testIdIcon}
            />
          </span>
        ) : null}

        <Slottable>{children}</Slottable>
      </Component>

      <div
        className={twJoin(
          'absolute inset-y-0 right-2 flex items-center',
          !options?.length && 'pointer-events-none'
        )}
      >
        {options?.length ? (
          <OptionsMenu>
            <OptionsMenu.Button>
              <IconButton
                ariaLabel={`Opties menu ${title}`}
                icon={RegularEllipsis}
                data-testid="button-ellipsis"
              />
            </OptionsMenu.Button>
            <OptionsMenu.Items options={options} />
          </OptionsMenu>
        ) : (
          <RegularChevronRight
            size={16}
            className="mr-1 text-sm text-gray-600"
            data-testid="icon-chevron-right"
          />
        )}
      </div>
    </div>
  );
}
