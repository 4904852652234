import { ReactNode } from 'react';

import {
  Button,
  ButtonProps,
  HeadingLarge,
  IconButton,
  InfoCard,
  InfoCardProps,
  OptionsMenu,
  OptionsMenuOption,
} from '@/components';
import { RegularEllipsis } from '@/icons/components';

export interface ExplorerItemHeadingProps {
  title: string | ReactNode;
  options?: OptionsMenuOption[];
  buttons?: (ButtonProps & { key: string })[];
  infoCardProps?: Omit<InfoCardProps, 'className'>;
  inactive?: boolean;
}

export function ExplorerItemHeading({
  title,
  buttons,
  options,
  infoCardProps,
  inactive,
}: ExplorerItemHeadingProps) {
  return (
    <div className="relative -mb-px mt-10 border-b border-gray-200 pb-4 first:mt-0">
      {infoCardProps && <InfoCard {...infoCardProps} className="mb-3" />}

      <div className="relative flex items-center">
        {title && (
          <HeadingLarge
            as="h3"
            textColor={inactive ? 'text-gray-600' : 'text-black'}
            className="text-md font-bold"
            value={title}
          />
        )}

        {buttons && (
          <div className="grow-2 flex w-full justify-end gap-2">
            {buttons.map((props) => (
              <Button {...props} key={props.key} />
            ))}
          </div>
        )}

        {options?.length && (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <OptionsMenu>
              <OptionsMenu.Button>
                <IconButton ariaLabel={`Opties menu ${title}`} icon={RegularEllipsis} />
              </OptionsMenu.Button>
              <OptionsMenu.Items options={options} />
            </OptionsMenu>
          </div>
        )}
      </div>
    </div>
  );
}
