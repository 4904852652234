import { useQueryClient } from '@tanstack/react-query';

import { usePostDynLabel } from '@/api/generated/digiVAPI';
import { LabelSchema } from '@/api/generated/digiVAPI.schemas';

import { useLabel, useLabelId } from '../label';

export function usePostLabel() {
  const queryClient = useQueryClient();

  return usePostDynLabel({
    mutation: {
      onSuccess: async (label) => {
        await queryClient.invalidateQueries({ queryKey: useLabel.key });

        queryClient.setQueryData<LabelSchema>(useLabelId.getKey(label.ID!), label);
      },
    },
  });
}
