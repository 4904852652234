import { STDParagraafSchema } from '@/api';
import { useDeleteStdParagraafId } from '@/api/queries/std';
import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';

export type StandaardtekstenVerwijderenParagraafProps = {
  paragraaf: STDParagraafSchema;
};

export const STANDAARDTEKSTEN_VERWIJDEREN_PARAGRAAF_ID = 'standaardtekstenVerwijderenParagraaf';

export const StandaardtekstenVerwijderenParagraaf = modal(
  STANDAARDTEKSTEN_VERWIJDEREN_PARAGRAAF_ID,
  ({ data, props }) => {
    const modalState = useModalState();

    const deleteStdParagraaf = useDeleteStdParagraafId();

    const {
      paragraaf: { Nummer, ID },
    } = data;

    const verwijderParagraaf = () => {
      modalState.action('delete');
      deleteStdParagraaf.mutate(
        {
          id: ID!,
        },
        {
          onSuccess: () => {
            modalState.success('delete');
          },
          onError: (error) => {
            modalState.error('delete', {
              title: 'Mislukt',
              description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
            });
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title={`Paragraaf ${Nummer} verwijderen`}>
        <p className="text-gray-800">
          Je staat op het punt om <strong>paragraaf {Nummer}</strong> te verwijderen. Onderliggende
          onderdelen worden ook verwijderd. Deze actie kan niet ongedaan gemaakt worden
        </p>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="delete"
            successLabel="Verwijderd"
            variant="red"
            onClick={verwijderParagraaf}
          >
            Verwijderen
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
