import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" ref={svgRef} {...props}><path d="M1.8 7.1c-.2 0-.4 0-.5.2s-.2.3-.2.5 0 .4.2.5.3.2.5.2h3.4c.2 0 .4 0 .5-.2s.2-.3.2-.5 0-.4-.2-.5-.3-.2-.5-.2h-.9v-6c0-.3-.2-.5-.4-.7-.3-.1-.5-.1-.8 0L1.4 1.5c-.2.1-.3.3-.3.5v.6h.2c0-.1-.2 0-.2 0 .1.2.3.3.5.3h.6v-.2h-.1v.2h.1l.5-.3v4.6h-.9zm4 11H3.1l2.1-2c.5-.5.8-1.2.8-1.9h-.2.2c0-.8-.3-1.4-.8-1.9s-1.2-.8-1.9-.8c-.8 0-1.4.3-1.9.8l-.5.5c-.1.2-.2.3-.2.5s0 .4.2.5c.2.1.3.2.5.2s.4 0 .5-.2h-.1c0-.1.1 0 .1 0l.5-.6c.2-.2.5-.4.9-.4.3 0 .6.1.9.4s.4.5.4.9c0 .3-.1.6-.4.9L.8 18.3h.1c0 .1-.1 0-.1 0-.1.1-.2.3-.2.4v.4h.2-.2c0 .2.2.3.3.4.1 0 .3.1.4.1v-.2.2h4.5c.2 0 .4 0 .5-.2s.2-.3.2-.5 0-.4-.2-.5-.3-.2-.5-.2zm-1.6-3zm-2.8-2.8zm8.9-7.1h8.5c.5 0 .7-.3.8-.8 0-.5-.3-.7-.8-.8h-8.5c-.5 0-.7.3-.8.8 0 .5.3.7.8.8zm8.5 8.5h-8.5c-.5 0-.7.3-.8.8 0 .5.3.7.8.8h8.5c.5 0 .7-.3.8-.8 0-.5-.3-.7-.8-.8zm0-5h-8.5c-.5 0-.7.3-.8.8 0 .5.3.7.8.8h8.5c.5 0 .7-.3.8-.8 0-.5-.3-.7-.8-.8z" /></svg>;
});
SVGIcon.displayName = "Custom123";
export default SVGIcon;