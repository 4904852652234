import { ReactNode, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export const MountWhenInView = ({
  children,
  placeholder,
}: {
  children: ReactNode;
  placeholder: ReactNode;
}) => {
  const { ref: inViewRef, inView } = useInView();
  const [mount, setMount] = useState(false);

  useEffect(() => {
    if (inView) setMount(true);
  }, [inView]);

  return <div ref={inViewRef}>{mount ? children : placeholder}</div>;
};
