import { SubmitHandler, useForm } from 'react-hook-form';

import { STDMapSchema } from '@/api';
import { usePatchStdMapId } from '@/api/queries/std';
import { Input, Modal, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';

export interface StandaardtekstenEditMapProps {
  map: STDMapSchema;
}
type FormValues = Pick<STDMapSchema, 'Naam'>;

export const STANDAARDTEKSTEN_EDIT_MAP_ID = 'standaardtekstenEditMap';

export const StandaardtekstenEditMap = modal(STANDAARDTEKSTEN_EDIT_MAP_ID, ({ data, props }) => {
  const modalState = useModalState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const patchStdMap = usePatchStdMapId();

  const {
    map: { Naam, ID },
  } = data;

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const userId = useUserStore.getState().user?.ID ?? '';

    modalState.action('save');
    patchStdMap.mutate(
      {
        data: {
          ...formData,
          Modified_By: userId,
          Modified_Date: new Date().toISOString(),
        },
        id: ID!,
      },
      {
        onSuccess: () => {
          modalState.success('save');
        },
        onError: () => {
          modalState.error('save');
        },
      }
    );
  };

  return (
    <Modal {...props} state={modalState.state} title="Map wijzigen">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('Naam', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
          error={errors.Naam}
          label="Titel"
          id="nieuwe-map-title"
          defaultValue={Naam}
          required
          maxLength={100}
        />
        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
            Opslaan
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
