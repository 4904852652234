import {
  Link,
  Outlet,
  createFileRoute,
  notFound,
  useMatches,
  useNavigate,
} from '@tanstack/react-router';

import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useBesluitType } from '@/api/queries/besluitType';
import {
  BreadCrumbs,
  DocumentTitle,
  PageHeader,
  RoleBasedAccess,
  SubNavigation,
} from '@/components';
import Navbar from '@/components/shared/Navbar';
import { eStrings } from '@/constants/strings';
import { RegularFilePen, RegularFiles, RegularHouse, RegularScrollOld } from '@/icons/components';

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/bedrijf/_layout')({
  loader: async ({ params, context: { queryClient } }) => {
    const [latestBedrijf] = await Promise.all([
      queryClient.ensureQueryData(useLatestBedrijf.getOptions(params.bedrijfLineageId)),
      queryClient.ensureQueryData(useBesluitType.getOptions({})),
    ]);

    if (!latestBedrijf.objects?.[0]) throw notFound();
  },
  component: BedrijfComponent,
});

/**
 * Route component
 */
function BedrijfComponent() {
  const { bedrijfLineageId } = Route.useParams();
  const navigate = useNavigate();

  const bedrijf = useLatestBedrijf(bedrijfLineageId);

  const matches = useMatches();
  const subpageMatch = matches[matches.length - 1];

  if (bedrijf.status === 'error') {
    navigate({
      to: '/',
      search: {
        p: 1,
      },
    });
  }

  if (!bedrijf.data || !bedrijf.data.ID) return null;

  const pageTitle = [eStrings.LBL_DIGI_V, bedrijf.data.Naam ?? ''];
  const breadCrumbs = ['Zoeken', bedrijf.data?.Naam ?? ''];
  const subpageTitle = subpageMatch?.context.getTitle?.();

  if (subpageTitle) {
    pageTitle.push(subpageTitle);
    breadCrumbs.push(subpageTitle);
  }

  return (
    <>
      <DocumentTitle sections={pageTitle} />

      <Navbar />

      <PageHeader
        title={bedrijf.data.Naam ?? ''}
        description={`${bedrijf.data.Straatnaam} ${bedrijf.data.Huisnummer}, ${bedrijf.data.Postcode} ${bedrijf.data.Plaatsnaam}`}
      />

      <div className="mx-auto max-w-7xl py-8 sm:px-6 lg:px-8">
        <BreadCrumbs size="small" path={breadCrumbs.map((title) => ({ title }))} />

        <div className="mt-10 flex">
          <aside className="w-1/4 pr-8">
            <SubNavigation ariaLabel="Bedrijfsmenu">
              <SubNavigation.Header as="h2">Menu</SubNavigation.Header>
              <SubNavigation.List>
                <SubNavigation.Button id="tab-overzicht" icon={RegularHouse} asChild>
                  <Link to="/$bedrijfLineageId/bedrijf/overzicht" params={{ bedrijfLineageId }}>
                    Overzicht
                  </Link>
                </SubNavigation.Button>

                <SubNavigation.Button id="tab-besluiten" icon={RegularFiles} asChild>
                  <Link
                    to="/$bedrijfLineageId/bedrijf/besluiten"
                    activeOptions={{
                      includeSearch: false,
                    }}
                    search={{ p: 1 }}
                    params={{ bedrijfLineageId }}
                  >
                    {eStrings.LBL_BESLUITEN_EN_WIJZIGINGEN}
                  </Link>
                </SubNavigation.Button>

                <RoleBasedAccess allowed={['Vergunningverlener']} area={['omgevingsdienst']}>
                  <SubNavigation.Button id="tab-ontwerpen" icon={RegularFilePen} asChild>
                    <Link
                      to="/$bedrijfLineageId/bedrijf/ontwerpen"
                      params={{ bedrijfLineageId }}
                      activeOptions={{
                        includeSearch: false,
                      }}
                      search={{ p: 1 }}
                    >
                      Ontwerpen
                    </Link>
                  </SubNavigation.Button>
                </RoleBasedAccess>

                <SubNavigation.Button
                  id="tab-historische-besluiten"
                  icon={RegularScrollOld}
                  asChild
                >
                  <Link
                    to="/$bedrijfLineageId/bedrijf/historische-besluiten"
                    activeOptions={{
                      includeSearch: false,
                    }}
                    params={{ bedrijfLineageId }}
                    search={{ p: 1 }}
                  >
                    Historische besluiten
                  </Link>
                </SubNavigation.Button>
              </SubNavigation.List>
            </SubNavigation>
          </aside>

          <main className="w-3/4">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}
