import { createContext, useContext } from 'react';

import { VoorschriftSchema } from '@/api';
import { LinkVoorschrift, Links } from '@/api/queries/objects';
import { ObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';

export const VoorschriftContext = createContext<{
  objectState: ObjectState;
  nummer: string;
  voorschrift: VoorschriftSchema;
  link: LinkVoorschrift;
  links: Links;
  userHasReservering: boolean;
  editorMode: EditorMode;
} | null>(null);

export const useVoorschriftContext = () => {
  const context = useContext(VoorschriftContext);

  if (!context) {
    throw new Error('useVoorschriftContext must be used within a VoorschriftContextProvider');
  }

  return context;
};
