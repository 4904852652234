import { useDebouncedCallback } from '@react-hookz/web';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link, createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { z } from 'zod';

import { useInstallatietype } from '@/api/queries/installatietype';
import { DocumentTitle, Explorer, Input } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { eStrings } from '@/constants/strings';
import { RegularMagnifyingGlass } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';

import { SidebarLayout } from './-components/SidebarLayout';

const searchSchema = z.object({
  p: z.number().optional().default(1),
  query: z.string().optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const installatietypeQueryOptions = (search: SearchParams) => {
  return useInstallatietype.getOptions(
    {
      page: search.p,
      size: 20,
      filter: search.query
        ? [
            {
              model: 'InstallatieType',
              field: 'Installatie_Type',
              op: 'ilike',
              value: `%${search.query}%`,
            },
          ]
        : [],
      sort: [
        {
          model: 'InstallatieType',
          field: 'Installatie_Type',
          direction: 'asc',
        },
      ],
    },
    {
      placeholderData: keepPreviousData,
    }
  );
};

/**
 * Route
 */
export const Route = createFileRoute('/beheer/installatietypes')({
  validateSearch: searchSchema.parse,
  beforeLoad: () => {
    /**
     * TEMPORARY REDIRECT UNTIL DB SYNC PROPERLY WORKS
     **/
    redirect({ to: '/', search: { p: 1 } });

    return {
      getTitle: () => 'Installatietypes',
    };
  },
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { queryClient }, deps }) => {
    const installatietypes = await queryClient.ensureQueryData(
      installatietypeQueryOptions(deps.search)
    );

    if (!installatietypes.num_pages) return;

    if (installatietypes.num_pages < deps.search.p) {
      throw redirect({
        to: '/beheer/installatietypes',
        search: { p: installatietypes.num_pages },
      });
    }
  },
  component: InstallatietypesComponent,
});

/**
 * Route component
 */
function InstallatietypesComponent() {
  const { p, query } = Route.useSearch();

  const openModal = useOpenModal();
  const navigate = useNavigate();

  const installatietypes = useQuery(installatietypeQueryOptions({ p, query }));

  const search = useDebouncedCallback(
    (query: string) => {
      navigate({
        to: '/beheer/installatietypes',
        search: {
          p: 1,
          query,
        },
      });
    },
    [navigate],
    300
  );

  if (!installatietypes.data) return null;

  return (
    <SidebarLayout>
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Beheer Installatietypes']} />
      <Explorer
        title="Installatietypes"
        buttonProps={{
          label: 'Nieuw installatietype',
          variant: 'white',
          onClick: () => openModal('beheerInstallatietype', {}),
        }}
        showButton
      >
        <Input
          icon={RegularMagnifyingGlass}
          label="Installatietype zoeken"
          placeholder="Zoek installatietype"
          hideLabel
          id="installatietype-search"
          name="installatietype-search"
          className="mb-6"
          onChange={(e) => search(e.target.value)}
        />

        {query && !installatietypes.data.objects?.length ? (
          <p className="italic text-gray-600">
            Er zijn geen installatietypes gevonden die voldoen aan de zoekopdracht
          </p>
        ) : null}

        {installatietypes.data.objects?.length ? (
          <ul>
            {installatietypes.data.objects.map((installatietype) => (
              <li key={`installatie-type-item-${installatietype.ID}`}>
                <Explorer.Button
                  asChild
                  inactive={installatietype.Status === 'Archief'}
                  options={[
                    {
                      variant: 'button',
                      title: 'Bewerken',
                      onClick: () => {
                        openModal('beheerInstallatietype', {
                          updateInstallatietype: installatietype,
                        });
                      },
                    },
                    {
                      title: installatietype.Status === 'Actief' ? 'Archiveren' : 'Herstellen',
                      variant: 'button',
                      onClick: () => {
                        openModal('beheerInstallatietypeStatus', {
                          installatietype,
                          newStatus: installatietype.Status === 'Actief' ? 'Archief' : 'Actief',
                        });
                      },
                    },
                  ]}
                >
                  <div>{installatietype.Installatie_Type}</div>
                </Explorer.Button>
              </li>
            ))}
          </ul>
        ) : null}
      </Explorer>
      {(installatietypes.data?.num_pages ?? 0) > 1 ? (
        <div className="mt-8 flex w-full justify-center">
          <PaginationLinks
            renderLink={({ children, page }) => (
              <Link to="/beheer/installatietypes" search={{ p: page, query }}>
                {children}
              </Link>
            )}
            currentPage={p}
            totalPages={installatietypes.data?.num_pages ?? 0}
          />
        </div>
      ) : null}
    </SidebarLayout>
  );
}
