import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postFixBesluit, postFixLineageBesluit } from '@/api/generated/digiVAPI';
import { BesluitLineageSchema, BesluitSchema } from '@/api/generated/digiVAPI.schemas';
import { PLACEHOLDER_UUID } from '@/constants/placeholder-uuid';

import { useLatestBedrijf } from '../../bedrijf';
import { useBesluit, useBesluitId, useLineageBesluit, useLineageBesluitId } from '../besluit';
import { useLastVigerendBesluit } from '../selectors/useLastVigerendBesluit';

export type NewBesluitProps = Pick<
  BesluitSchema,
  | 'OLO_Nummer'
  | 'Zaaknummer'
  | 'Ons_Kenmerk'
  | 'Korte_Omschrijving'
  | 'Ontwerp_Beschikking'
  | 'Type'
  | 'Algemene_Overweging'
  | 'Duiding'
  | 'Besluit_Tekst'
>;

/**
 * Creates a new besluit and lineage if there's no lineage yet.
 */
export function useCreateNewBesluit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      besluit,
      bedrijfLineageId,
    }: {
      besluit: NewBesluitProps;
      bedrijfLineageId: string;
    }) => {
      const lastVigerendBesluitResponse = await queryClient.fetchQuery(
        useLastVigerendBesluit.getOptions({
          bedrijfLineageId,
        })
      );

      const bedrijfResponse = await queryClient.fetchQuery(
        useLatestBedrijf.getOptions(bedrijfLineageId)
      );

      const lastVigerendBesluit = lastVigerendBesluitResponse.objects?.[0];
      const bedrijf = bedrijfResponse.objects?.[0];

      let lineageId: string;
      let newLineage: BesluitLineageSchema | undefined;

      if (!bedrijf) {
        throw new Error(`Failed to fetch bedrijf with lineageId: ${bedrijfLineageId}`);
      }

      if (lastVigerendBesluit) {
        // If the bedrijf already has besluiten, fetch the lineage from the besluit
        lineageId = lastVigerendBesluit.Lineage;
      } else {
        // If the bedrijf has no besluiten, create a new lineage first
        newLineage = await postFixLineageBesluit({
          // Tenant will be replaced by BE
          Tenant: PLACEHOLDER_UUID,
        });
        lineageId = newLineage.ID!;
      }

      if (!lineageId) {
        throw new Error('Failed to create lineage or get lineageId from besluit');
      }

      /**
       * Post new besluit
       */
      const newBesluit = await postFixBesluit({
        ...besluit,
        Status: 'Ontwerp',
        Bedrijf: bedrijf.ID!,
        Lineage: lineageId,
        Vorig: lastVigerendBesluit?.ID,
      });

      return {
        besluit: newBesluit,
        newLineage,
      };
    },
    onSuccess: async ({ besluit, newLineage }) => {
      // If a new lineage is created, invalide the lineage query
      if (newLineage?.ID) {
        queryClient.setQueryData<BesluitLineageSchema>(
          useLineageBesluitId.getKey(newLineage.ID),
          newLineage
        );
        await queryClient.invalidateQueries({ queryKey: useLineageBesluit.key });
      }

      if (besluit.ID) {
        queryClient.setQueryData<BesluitSchema>(useBesluitId.getKey(besluit.ID), besluit);
      }

      return Promise.all([queryClient.invalidateQueries({ queryKey: useBesluit.key })]);
    },
  });
}
