import { useQueryClient } from '@tanstack/react-query';

import { usePostOthHoofdstukReservering } from '@/api/generated/digiVAPI';

import { useHoofdstukReservering } from '../useHoofdstukReservering';

/**
 * Posts a new hoofdstuk reservering. Can be used to reserve a hoofdstuk.
 */
export function usePostHoofdstukReservering() {
  const queryClient = useQueryClient();

  return usePostOthHoofdstukReservering({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: useHoofdstukReservering.key });
      },
    },
  });
}
