import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';

export type StandaardtekstenVerwijderenMapProps = {
  mapNaam?: string;
};

export const STANDAARDTEKSTEN_VERWIJDEREN_MAP_ID = 'standaardtekstenVerwijderenMap';

export const StandaardtekstenVerwijderenMap = modal(
  STANDAARDTEKSTEN_VERWIJDEREN_MAP_ID,
  ({ data, props }) => {
    const modalState = useModalState();

    const { mapNaam } = data;

    const verwijderMap = () => {
      console.log('map verwijderd');
    };

    return (
      <Modal {...props} state={modalState.state} title="Map verwijderen">
        <p className="text-gray-800">Je staat op het punt om de map {mapNaam} te verwijderen</p>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="delete"
            successLabel="Verwijderd"
            variant="red"
            onClick={verwijderMap}
          >
            Verwijderen
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
