import { BedrijfSchema, HoofdstukSchema } from '@/api';
import { LinkVoorschrift, useVoorschriftId } from '@/api/queries/objects';
import { EditorMeta, EditorVoorschrift, Transitions } from '@/components';
import { MountWhenInView } from '@/components/shared/MountWhenInView';
import { RegularChevronDown } from '@/icons/components';

import { Mode } from './Objects';
import { ObjectsVoorschriftMeta } from './ObjectsVoorschriftMeta';
import { getObjectStatus } from './utils/getObjectState';

export interface ObjectsVoorschriftProps {
  hoofdstuk: HoofdstukSchema;
  link: LinkVoorschrift;
  paragraafNummer: number;
  bedrijf: BedrijfSchema;
  mode: Mode;
}

export const ObjectsVoorschrift = (props: ObjectsVoorschriftProps) => {
  return (
    <MountWhenInView placeholder={<Placeholder />}>
      <ObjectsVoorschriftInner {...props} />
    </MountWhenInView>
  );
};

/**
 * Voorschrift Row
 */
const ObjectsVoorschriftInner = (props: ObjectsVoorschriftProps) => {
  const { link, hoofdstuk, paragraafNummer, mode } = props;

  const voorschrift = useVoorschriftId(link.VSPB.Voorschrift, {
    staleTime: Infinity,
  });

  const isLoading = !!(voorschrift.isLoading || !voorschrift.data);

  if (isLoading) return <Placeholder />;

  return (
    <Transitions.FadeIn show={!isLoading} appear unmount>
      <EditorMeta>
        <EditorMeta.Button>
          {({ props, isOpen }) => (
            <>
              <EditorVoorschrift
                number={`${hoofdstuk.Nummer}.${paragraafNummer}.${voorschrift.data.Nummer}`}
                status={
                  mode === 'besluit'
                    ? getObjectStatus(voorschrift.data, link.VSPB.Type === 'Eigen')
                    : undefined
                }
                buttonProps={{
                  ariaLabel: `Bekijk voorschrift ${voorschrift.data.Nummer} meta data`,
                  icon: RegularChevronDown,
                  className: isOpen ? 'rotate-180' : undefined,
                  variant: 'white',
                  ...props,
                }}
                htmlContent={voorschrift.data.Voorschrift}
                testId={`paragraaf-${voorschrift.data.Nummer}`}
                active={isOpen}
                className={isOpen ? undefined : 'mb-2'}
              />
            </>
          )}
        </EditorMeta.Button>

        <EditorMeta.Panel closeAriaLabel={`Sluit voorschrift ${voorschrift.data.Nummer} meta data`}>
          <ObjectsVoorschriftMeta {...props} voorschrift={voorschrift.data} />
        </EditorMeta.Panel>
      </EditorMeta>
    </Transitions.FadeIn>
  );
};

const Placeholder = () => <div className="h-10 w-full" />;
