import { Disclosure, DisclosureProps } from '@headlessui/react';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { RegularChevronDown } from '@/icons/components';

import { LoaderContent, LoaderSpinningIcon } from '../Loader';

export interface AccordionProps extends DisclosureProps<'div'> {}

export interface AccordionButtonProps {
  isLoading?: boolean;
  label: ReactNode | string;
  count?: number;
}

export interface AccordionPanelProps {
  isLoading?: boolean;
  children: ReactNode;
}

export const Accordion = (props: AccordionProps) => {
  return <Disclosure {...props} />;
};

Accordion.Button = function AccordionButton({ label, count, isLoading }: AccordionButtonProps) {
  return (
    <Disclosure.Button className="flex w-full items-center justify-between border-b border-gray-300 bg-gray-100 px-6 py-4 hover:bg-gray-200">
      {({ open }) => (
        <>
          <span className="flex items-center gap-2">
            <span className="text-md font-medium">{label}</span>

            {isLoading && <LoaderSpinningIcon />}

            {!isLoading && typeof count === 'number' ? (
              <span
                className={twJoin(
                  'flex h-6 w-6 items-center justify-center rounded-full text-sm leading-tight ',
                  count === 0 && 'bg-gray-300 text-theme-blue',
                  count !== 0 && 'bg-theme-blue text-white'
                )}
              >
                <span>{count}</span>
              </span>
            ) : null}
          </span>

          <span className={twJoin('w-4', open && 'rotate-180')}>
            <RegularChevronDown />
          </span>
        </>
      )}
    </Disclosure.Button>
  );
};

Accordion.Panel = function AccordionPanel({ children, isLoading }: AccordionPanelProps) {
  return (
    <Disclosure.Panel className="px-6 py-4">
      {isLoading ? <LoaderContent w="w-full" h="h-8"></LoaderContent> : children}
    </Disclosure.Panel>
  );
};
