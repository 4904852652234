import { create } from 'zustand';

type SelectedObject = {
  lineageId: string;
  type: 'hoofdstuk' | 'paragraaf' | 'subparagraaf' | 'voorschrift';
};

interface EditorState {
  isSidebarLeftOpen: boolean;
  isSidebarRightOpen: boolean;
  isSidebarRightDisabled: boolean;
  isOrderingLink: boolean;
  areAddBarsShown: boolean;
  contentMinHeight: number | null;
  /**
   * We store the lineage id of the selected object in the editor.
   * We do this instead of the id, because a new object is created when a published voorschrift is edited.
   * When a new object is created, the id will be different, but the lineage id will be the same.
   */
  selectedObject: SelectedObject | null;
}

interface EditorActions {
  enableSidebarRight: () => void;
  disableSidebarRight: () => void;
  setIsSidebarLeftOpen: (isSidebarLeftOpen: boolean) => void;
  setIsSidebarRightOpen: (isSidebarRightOpen: boolean) => void;
  setIsOrderingLink: (isOrderingLink: boolean) => void;
  setContentMinHeight: (contentMinHeight: number) => void;
  selectObject: (selectedObject: SelectedObject) => void;
  showAddBars: () => void;
  hideAddBars: () => void;
  reset: () => void;
}

const initialState: EditorState = {
  isSidebarLeftOpen: false,
  isSidebarRightOpen: false,
  isSidebarRightDisabled: false,
  isOrderingLink: false,
  contentMinHeight: null,
  selectedObject: null,
  areAddBarsShown: false,
};

export const useEditorStore = create<EditorState & EditorActions>((set) => ({
  ...initialState,
  enableSidebarRight: () => set({ isSidebarRightDisabled: false }),
  disableSidebarRight: () => set({ isSidebarRightDisabled: true }),
  setIsSidebarLeftOpen: (isSidebarLeftOpen) => set({ isSidebarLeftOpen }),
  setIsSidebarRightOpen: (isSidebarRightOpen) => set({ isSidebarRightOpen }),
  setIsOrderingLink: (isOrderingLink) => set({ isOrderingLink }),
  setContentMinHeight: (contentMinHeight) => set({ contentMinHeight }),
  selectObject: (selectedObject) => set({ selectedObject }),
  showAddBars: () => set({ areAddBarsShown: true }),
  hideAddBars: () => set({ areAddBarsShown: false }),
  reset: () => set({ ...initialState }),
}));
