import { ValidateBesluitProps } from './ValidateBesluit.types';
import {
  ValidateDuplicateParagraafNummer,
  useValidateDuplicateParagraafNummer,
} from './components/ValidateDuplicateParagraafNummer';
import {
  ValidateHoofdstukTitle,
  useValidateHoofdstukTitle,
} from './components/ValidateHoofdstukTitle';
import {
  ValidateParagraafTitle,
  useValidateParagraafTitles,
} from './components/ValidateParagraafTitle';
import {
  ValidateReservering,
  useValidateBesluitReservering,
} from './components/ValidateReservering';
import {
  ValidateSubparagraafTitle,
  useValidateSubparagraafTitles,
} from './components/ValidateSubparagraafTitle';
import { ValidateVoorschrift, useValidateVoorschrift } from './components/ValidateVoorschrift';

/**
 * Hook that validates a besluit.
 */
export const useValidateBesluit = ({
  besluitId,
  bedrijfLineageId,
}: {
  besluitId: string;
  bedrijfLineageId: string;
}) => {
  const validateHoofdstukTitles = useValidateHoofdstukTitle(besluitId);
  const validateParagraafTitles = useValidateParagraafTitles(besluitId);
  const validateSubparagraafTitles = useValidateSubparagraafTitles(besluitId);
  const validateVoorschiften = useValidateVoorschrift(besluitId);
  const validateBesluitReservering = useValidateBesluitReservering(besluitId);
  const validateDuplicateParagraafNummers = useValidateDuplicateParagraafNummer(besluitId);

  return {
    isFetching:
      validateHoofdstukTitles.isFetching ||
      validateSubparagraafTitles.isFetching ||
      validateParagraafTitles.isFetching ||
      validateVoorschiften.isFetching ||
      validateBesluitReservering.isFetching ||
      validateDuplicateParagraafNummers.isFetching,
    isValid:
      validateHoofdstukTitles.data?.isValid &&
      validateSubparagraafTitles.data?.isValid &&
      validateParagraafTitles.data?.isValid &&
      validateVoorschiften.data?.isValid &&
      validateBesluitReservering.data?.isValid &&
      validateDuplicateParagraafNummers.data?.isValid,
    queries: {
      validateHoofdstukTitles,
      validateSubparagraafTitles,
      validateParagraafTitles,
      validateVoorschiften,
      validateDuplicateParagraafNummers,
      validateBesluitReservering,
    },
    besluitId,
    bedrijfLineageId,
  };
};

export const ValidateBesluit = ({ validate }: ValidateBesluitProps) => {
  return (
    <ul className="flex flex-col gap-2">
      <li>
        <ValidateReservering validate={validate} />
      </li>
      <li>
        <ValidateHoofdstukTitle validate={validate} />
      </li>
      <li>
        <ValidateParagraafTitle validate={validate} />
      </li>
      <li>
        <ValidateSubparagraafTitle validate={validate} />
      </li>
      <li>
        <ValidateVoorschrift validate={validate} />
      </li>
      <li>
        <ValidateDuplicateParagraafNummer validate={validate} />
      </li>
    </ul>
  );
};
