import { useState } from 'react';

import { SubparagraafSchema } from '@/api';
import { LinkSubparagraaf } from '@/api/queries/objects';
import { useVSPB } from '@/api/queries/objects';
import { useCreateSubparagraafInLineage } from '@/api/queries/objects/mutations/useCreateSubparagraafInLineage';
import { Checkbox, Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface IntrekkenSubparagraafProps {
  subparagraaf: SubparagraafSchema;
  link: LinkSubparagraaf;
}

export const INTREKKEN_SUBPARAGRAAF_ID = 'intrekkenSubparagraaf';

export const IntrekkenSubparagraaf = modal(INTREKKEN_SUBPARAGRAAF_ID, ({ data, props }) => {
  const { link, subparagraaf } = data;

  const [ontwerpValue, setOntwerpValue] = useState(false);

  const modalState = useModalState();
  const createSubparagraafInLineage = useCreateSubparagraafInLineage();

  const handleIntrekken = () => {
    modalState.action('save');

    createSubparagraafInLineage.mutate(
      {
        link,
        subparagraaf: {
          ...subparagraaf,
          Stop_Lineage: 1,
        },
      },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) =>
          modalState.error('save', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
          }),
      }
    );
  };

  const containsOntwerpObject = useVSPB(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'VSPB',
          field: 'Subparagraaf',
          op: 'eq',
          value: link.SPPB.Subparagraaf,
        },
        {
          model: 'Voorschrift',
          field: 'Ontwerp',
          op: 'eq',
          value: '1',
        },
      ],
    },
    {
      select: (data) => {
        return !!data.num_objects;
      },
    }
  );

  const titel = (
    <>
      &quot;
      <span dangerouslySetInnerHTML={{ __html: subparagraaf.Titel }} />
      &quot;
    </>
  );

  return (
    <Modal
      state={modalState.state}
      title={<>Intrekken subparagraaf {titel}</>}
      {...props}
      isLoading={containsOntwerpObject.isLoading}
    >
      <p>
        Je staat op het punt om subparagraaf <strong>{titel}</strong> in te trekken. Alle
        onderliggende voorschriften zullen worden ingetrokken.
      </p>

      {containsOntwerpObject.data && (
        <form className="mt-4">
          <Checkbox
            id="contains-ontwerp-object"
            name="contains-ontwerp-object"
            label="Ik begrijp dat bewerkingen onder dit object zullen worden verwijderd."
            checked={ontwerpValue}
            onChange={() => setOntwerpValue(!ontwerpValue)}
          />
        </form>
      )}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="save"
          onClick={handleIntrekken}
          disabled={containsOntwerpObject.data && !ontwerpValue}
          successLabel="Subparagraaf ingetrokken"
        >
          Subparagraaf intrekken
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
