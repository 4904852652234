import { twJoin, twMerge } from 'tailwind-merge';

import { TextButton, TextButtonProps } from '@/components';

export interface TreeNavItemProps extends Omit<TextButtonProps, 'variant' | 'underline'> {
  lineThrough?: boolean;
}

export const TreeNavItem = ({ children, lineThrough, className, ...props }: TreeNavItemProps) => {
  return (
    <li className="list-none text-left">
      <TextButton
        className={twMerge(
          twJoin('mb-1 block w-full', lineThrough ? 'line-through' : ''),
          className
        )}
        variant="black"
        underline={false}
        {...props}
      >
        {children}
      </TextButton>
    </li>
  );
};
