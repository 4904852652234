import { useQueryClient } from '@tanstack/react-query';

import { usePostDynHistorischBesluit } from '@/api/generated/digiVAPI';
import { HistorischBesluitSchema } from '@/api/generated/digiVAPI.schemas';

import { useHistorischBesluit, useHistorischBesluitId } from '../historischBesluit';

export function usePostHistorischBesluit() {
  const queryClient = useQueryClient();

  return usePostDynHistorischBesluit({
    mutation: {
      onSuccess: async (historischBesluit) => {
        await queryClient.invalidateQueries({ queryKey: useHistorischBesluit.key });

        queryClient.setQueryData<HistorischBesluitSchema>(
          useHistorischBesluitId.getKey(historischBesluit.ID!),
          historischBesluit
        );
      },
    },
  });
}
