import {
  getFixHoofdstuk,
  getFixHoofdstukId,
  getFixLineageHoofdstuk,
  getFixLineageHoofdstukId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useHoofdstuk = createQuery({
  key: ['hoofdstuk'],
  queryFn: getFixHoofdstuk,
});

export const useHoofdstukId = createQuery({
  key: ['hoofdstukId'],
  queryFn: getFixHoofdstukId,
});

export const useHoofdstukLineage = createQuery({
  key: ['hoofdstukLineage'],
  queryFn: getFixLineageHoofdstuk,
});

export const useHoofdstukLineageId = createQuery({
  key: ['hoofdstukLineageId'],
  queryFn: getFixLineageHoofdstukId,
});
