import { Extension } from '@tiptap/react';

/**
 * This extension turns the TextEditor into a single line input.
 */
export const SingleLine = Extension.create({
  addKeyboardShortcuts(this) {
    return {
      Enter: () => true,
    };
  },
});
