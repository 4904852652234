import { createFileRoute, notFound } from '@tanstack/react-router';

import { FilterSchema } from '@/api';
import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useBesluitId } from '@/api/queries/besluit';
import { filterEigenLinks, useHoofdstukHB, useLinksInHoofdstuk } from '@/api/queries/objects';

import { ChapterNavigation } from '../-components/ChapterNavigation';
import { Objects } from '../-components/Objects/Objects';
import {
  ensureQueryDataViewerBesluitHoofdstukken,
  useViewerBesluitHoofdstukken,
} from './-utils/useViewerBesluitHoofdstukken';

const VSPBFilter: FilterSchema[] = [{ model: 'VSPB', field: 'Type', op: 'eq', value: 'Eigen' }];

/**
 * Route
 */
export const Route = createFileRoute(
  '/$bedrijfLineageId/viewer/besluit/$besluitId/voorschriften/$hoofdstukId'
)({
  loader: async ({
    params: { besluitId, bedrijfLineageId, hoofdstukId },
    context: { queryClient },
  }) => {
    try {
      const [{ voorschriftHoofdstukken }] = await Promise.all([
        ensureQueryDataViewerBesluitHoofdstukken({ besluitId, queryClient }),
        queryClient.ensureQueryData(useBesluitId.getOptions(besluitId)),
        queryClient.ensureQueryData(useLatestBedrijf.getOptions(bedrijfLineageId)),
        queryClient.ensureQueryData(
          useLinksInHoofdstuk.getOptions({ hoofdstukId, besluitId, VSPBFilter })
        ),
      ]);

      if (!voorschriftHoofdstukken.some((hoofdstuk) => hoofdstuk.ID === hoofdstukId))
        throw notFound();
    } catch {
      throw notFound();
    }
  },
  component: VoorschriftenComponent,
});

/**
 * Route component
 */
function VoorschriftenComponent() {
  const { besluitId, hoofdstukId, bedrijfLineageId } = Route.useParams();

  /**
   * Fetch data
   */
  const bedrijf = useLatestBedrijf(bedrijfLineageId);
  const besluit = useBesluitId(besluitId, { staleTime: Infinity });
  const viewerHoofdstukken = useViewerBesluitHoofdstukken({ besluitId });
  const links = useLinksInHoofdstuk(
    { hoofdstukId, besluitId, VSPBFilter },
    { staleTime: Infinity }
  );
  const HB = useHoofdstukHB({ besluitId, hoofdstukId }, { staleTime: Infinity });

  /**
   * Catch loading states
   */
  if (!besluit.data || !bedrijf.data || !links.data || !HB.data) return null;

  /**
   * Find next and previous hoofdstuk
   */
  const hoofdstuk = viewerHoofdstukken.voorschriftHoofdstukken.find(({ ID }) => ID === hoofdstukId);
  const { nextHoofdstuk, previousHoofdstuk } = viewerHoofdstukken.getSiblings({
    hoofdstukId,
    type: 'voorschriften',
  });

  if (!hoofdstuk) return null;

  /**
   * Filter links that have or contain changes
   */
  const eigenLinks = filterEigenLinks(links.data);

  return (
    <>
      <Objects
        hoofdstuk={hoofdstuk}
        HB={HB.data}
        links={eigenLinks}
        bedrijf={bedrijf.data}
        mode="besluit"
      />

      <ChapterNavigation
        previous={
          previousHoofdstuk?.ID && (
            <ChapterNavigation.Previous
              nummer={previousHoofdstuk.Nummer}
              htmlTitle={previousHoofdstuk.Titel}
              to="/$bedrijfLineageId/viewer/besluit/$besluitId/voorschriften/$hoofdstukId"
              params={{ bedrijfLineageId, besluitId, hoofdstukId: previousHoofdstuk.ID }}
            />
          )
        }
        next={
          nextHoofdstuk?.ID && (
            <ChapterNavigation.Next
              nummer={nextHoofdstuk.Nummer}
              htmlTitle={nextHoofdstuk.Titel}
              to="/$bedrijfLineageId/viewer/besluit/$besluitId/voorschriften/$hoofdstukId"
              params={{ bedrijfLineageId, besluitId, hoofdstukId: nextHoofdstuk.ID }}
            />
          )
        }
      />
    </>
  );
}
