import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

interface SidebarRightHeadingProps {
  title: string | ReactNode;
  children?: ReactNode;
  direction?: 'row' | 'column';
}

export const SidebarRightHeading = ({
  children,
  title,
  direction = 'column',
}: SidebarRightHeadingProps) => {
  return (
    <div
      className={twJoin(
        'flex justify-between px-6',
        direction === 'column' && 'h-14 items-center',
        direction === 'row' && 'flex-col'
      )}
    >
      <h3 className={twJoin('text-md font-bold text-theme-blue', direction === 'row' && 'py-4')}>
        {title}
      </h3>

      <div>{children}</div>
    </div>
  );
};
