import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { FeedbackMessage } from '@/components';
import { useUserStore } from '@/stores/user';
import { environment } from '@/utils/environment';

import { router } from './router/router';

const getApiURL = (): string | undefined => {
  if (environment.isLocal) {
    return 'next';
  }

  if (environment.isDevelopment) {
    return 'next';
  }

  if (environment.isTest) {
    return 'next-tst';
  }

  if (environment.isAcceptation) {
    return 'next-acc';
  }

  if (environment.isDroogoefen) {
    // TODO: return droogoefen url
  }

  if (environment.isProduction) {
    // TODO: return production url
  }

  if (environment.isDcmrProd) {
    return 'next-prd-dcmr';
  }

  if (environment.isOzhzProd) {
    return 'next-prd-ozhz';
  }

  if (environment.isTmpProd) {
    return 'next-prd-mt';
  }
};

export const API = Axios.create({
  baseURL: `https://api-aadv-${getApiURL()}.azurewebsites.net/api/v1.0`,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    const serializedParams: string[] = [];

    Object.entries(params).forEach(([key, value]) => {
      let serializedValue = value;

      // The API requires objects and arrays to be JSON strings as encodedURIComponents
      if (typeof value === 'object' || Array.isArray(value)) {
        serializedValue = JSON.stringify(value);
      }

      // Only add the param if the value is not undefined
      if (typeof value !== 'undefined') {
        serializedParams.push(`${key}=${encodeURIComponent(serializedValue)}`);
      }
    });

    return serializedParams.join('&');
  },
});

API.interceptors.request.use(function (config) {
  const expires = localStorage.getItem('__AADV_access_token_expires__');

  /**
   * Check if the access token is expired, if so logout the user
   */
  if (expires && new Date(expires) < new Date()) {
    const logout = useUserStore.getState().logout;

    logout();

    return config;
  }

  /**
   * Add the access token to the request headers
   */
  const access_token = localStorage.getItem('__AADV_access_token__');

  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`;
  }

  return config;
});

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    /**
     * Log error in the console
     */
    if (!Axios.isCancel(error)) {
      console.log('----------------------------');
      console.log('---- API RESPONSE ERROR ----');
      console.log('----------------------------');
      console.error(error);
      console.log('----------------------------');
      console.log('----------------------------');
      console.log('----------------------------');
    }

    const status = error.response?.status;

    if (
      error.response?.status === 401 &&
      error.response.config.url !== '/login' &&
      error.response.config.url !== '/auth/tokeninfo' &&
      window.location.pathname !== '/login'
    ) {
      FeedbackMessage('error', 'Je moet ingelogd zijn voor deze actie');
      window.location.href = '/login';
    }

    if (status === 500) {
      router.navigate({ to: '/500' });
    }

    return Promise.reject(error);
  }
);

// add a second `options` argument here if you want to pass extra options to each generated query
export const orvalAPI = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = API({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

const baseURL = API.defaults.baseURL;

export { baseURL };
export default API;
