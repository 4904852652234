import { Link } from '@tanstack/react-router';

import { SubNavigation } from '@/components';
import {
  // RegularChartNetwork,
  RegularCubes, // RegularIndustry,
  // RegularTags,
} from '@/icons/components';

export const BeheerSideBar = () => {
  return (
    <aside className="w-1/4 pr-4">
      <SubNavigation ariaLabel="Beheer menu">
        <SubNavigation.List>
          {/* <SubNavigation.Button icon={RegularTags} asChild>
            <Link
              to="/beheer/labels"
              search={{ p: 1 }}
              activeOptions={{
                includeSearch: false,
              }}
            >
              Labels
            </Link>
          </SubNavigation.Button> */}
          {/* <SubNavigation.Button icon={RegularIndustry} asChild>
            <Link
              to="/beheer/installatietypes"
              search={{ p: 1 }}
              activeOptions={{ includeSearch: false }}
            >
              Installatietypes
            </Link>
          </SubNavigation.Button>
          <SubNavigation.Button icon={RegularChartNetwork} asChild>
            <Link
              to="/beheer/activiteiten"
              search={{ p: 1 }}
              activeOptions={{ includeSearch: false }}
            >
              Activiteiten
            </Link>
          </SubNavigation.Button> */}
          <SubNavigation.Button id="tab-standaardteksten" icon={RegularCubes} asChild>
            <Link
              to="/beheer/standaardteksten"
              search={{ p: 1 }}
              activeOptions={{ includeSearch: false }}
            >
              Standaardteksten
            </Link>
          </SubNavigation.Button>
        </SubNavigation.List>
      </SubNavigation>
    </aside>
  );
};

export default BeheerSideBar;
