import { ReactNode } from 'react';

import BeheerSideBar from './BeheerSidebar';

interface SidebarLayoutProps {
  children: ReactNode;
  testId?: string;
}

export const SidebarLayout = ({ children, testId }: SidebarLayoutProps) => {
  return (
    <div className="mx-auto mt-4 max-w-7xl pb-8 sm:px-6 lg:px-8">
      <div className="flex">
        <BeheerSideBar />

        <main className="w-3/4" data-testid={testId}>
          {children}
        </main>
      </div>
    </div>
  );
};
