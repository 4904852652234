import { useDebouncedCallback } from '@react-hookz/web';

import { usePatchVoorschriftId } from '@/api/queries/objects';
import { Input } from '@/components';
import { Accordion } from '@/components/shared/Accordion';

import { useVoorschriftContext } from './VoorschriftContext';
import { SidebarError } from './VoorschriftSidebar';

type VoorschriftSidebarNummerProps = {
  errors: SidebarError[];
  patchVoorschriftNummer: ReturnType<typeof usePatchVoorschriftId>;
};

export const VoorschriftSidebarNummer = ({
  patchVoorschriftNummer,
  errors,
}: VoorschriftSidebarNummerProps) => {
  const { voorschrift, objectState } = useVoorschriftContext();

  const nummerError = errors.find((error) => error.request === 'nummer');

  const patchNummer = (newNummer: string) => {
    patchVoorschriftNummer.mutate({
      data: {
        Nummer: newNummer,
      },
      id: voorschrift.ID!,
    });
  };

  const patchVoorschriftNummerDebounced = useDebouncedCallback(patchNummer, [patchNummer], 100);

  if (objectState.state !== 'new') {
    return null;
  }

  return (
    <Accordion defaultOpen>
      <Accordion.Button label="Nummer" />
      <Accordion.Panel>
        <form>
          <Input
            name="Nummer"
            id="voorschrift-sidebar-nummer"
            label="Pas handmatig voorschrift nummer aan"
            error={nummerError?.message}
            disabled={!objectState.isEditable}
            defaultValue={voorschrift.Nummer}
            maxLength={3}
            onChange={(e) => {
              patchVoorschriftNummerDebounced(e.target.value);
            }}
          />
        </form>
      </Accordion.Panel>
    </Accordion>
  );
};
