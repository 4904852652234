import { Link, useMatches } from '@tanstack/react-router';
import { twJoin } from 'tailwind-merge';

import { useBesluitId } from '@/api/queries/besluit';
import { useHoofdstukReservering } from '@/api/queries/hoofdstukReservering';
import { useHoofdstukkenWithEdits } from '@/api/queries/objects';
import { SidePanel, TippyWhenOverflowing, TreeNav, TreeNavItem, TreeNavList } from '@/components';
import { RegularLock, RegularPenLine, RegularUserLock } from '@/icons/components';
import { useUserStore } from '@/stores/user';

import { useEditorStore } from '../-store/useEditorStore';
import { useHoofdstukkenInEditor } from '../-utils/useHoofdstukkenInEditor';
import { Route as AlgemeenToetsingskaderRoute } from '../algemeen-toetsingskader';
import { Route as BegrippenRoute } from '../begrippen';
import { Route as BesluittekstRoute } from '../besluittekst';
import { Route as BijlageRoute } from '../bijlage';
import { Route as InhoudelijkeOverweginRoute } from '../inhoudelijke-overwegingen.$hoofdstukId';
import { Route as OverigeOverwegingenRoute } from '../overige-overwegingen';
import { Route as ProcedureleOverwegingenRoute } from '../procedurele-overwegingen';
import { Route as RechtsmiddelenRoute } from '../rechtsmiddelen';
import { Route } from '../route';
import { Route as VoorschriftenHoofdstukIdRoute } from '../voorschriften.$hoofdstukId';
import { Route as VoorschriftenHoofdstukRoute } from '../voorschriften.hoofdstuk';

export const SidebarLeft = () => {
  const isOpen = useEditorStore((state) => state.isSidebarLeftOpen);
  const setIsOpen = useEditorStore((state) => state.setIsSidebarLeftOpen);

  return (
    <>
      <SidePanel
        id="sidebar-left"
        xPosition="left"
        yPosition="bottom"
        width="w-[310px]"
        height="h-[calc(100vh-theme(spacing.editor-navigation-height))]"
        open={isOpen}
        buttonProps={{
          openAriaLabel: 'Open navigatiebalk',
          closeAriaLabel: 'Sluit navigatiebalk',
          testId: 'sidebar-left-toggle',
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        ariaLabel="Indeling sidebar"
        unmount
      >
        <section className="px-6 py-4">
          <h3 className="mb-2 font-semibold text-theme-blue">Indeling</h3>

          <SidebarNavigation />
        </section>
      </SidePanel>
    </>
  );
};

const SidebarNavigation = () => {
  const { besluitId, bedrijfLineageId } = Route.useParams();
  const userId = useUserStore((state) => state.user?.ID);

  /**
   * Check the type of current page
   */
  const matches = useMatches();

  const isVoorschriftenPage = matches.some(
    ({ routeId }) => routeId === '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId'
  );
  const isInhoudelijkeOverwegingenPage = matches.some(
    ({ routeId }) =>
      routeId === '/$bedrijfLineageId/editor/$besluitId/inhoudelijke-overwegingen/$hoofdstukId'
  );

  /**
   * Fetch hoofdstukken in besluit, that are not ingetrokken and published
   */
  const hoofdstukken = useHoofdstukkenInEditor(besluitId);

  /**
   * Fetch hoofdstukken containing edits
   */
  const hoofdstukkenContainingEdits = useHoofdstukkenWithEdits(
    { besluitId },
    // Refetch every 15 seconds
    { refetchInterval: 1000 * 15 }
  );
  const editedHoofdstukIds =
    hoofdstukkenContainingEdits.data?.map((hoofdstuk) => hoofdstuk.ID) || [];

  /**
   * Fetch hoofdstuk reserveringen
   */
  const besluitLineageId = useBesluitId(besluitId, { select: ({ Lineage }) => Lineage });
  const hoofdstukReserveringen = useHoofdstukReservering(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'HoofdstukLineage',
          field: 'Besluit_lineage',
          op: 'eq',
          value: besluitLineageId.data ?? '',
        },
      ],
    },
    {
      select: ({ objects }) => objects,
      enabled: !!besluitLineageId.data,
    }
  );

  if (
    besluitLineageId.isLoading ||
    hoofdstukken.isLoading ||
    hoofdstukkenContainingEdits.isLoading ||
    hoofdstukReserveringen.isLoading
  ) {
    return <div>Laden...</div>;
  }

  return (
    <nav aria-label="Editor subnavigatie">
      <TreeNav>
        <TreeNavItem id="besluittekst" aria-label="Open besluittekst" asChild>
          <Link to={BesluittekstRoute.fullPath} params={{ besluitId, bedrijfLineageId }}>
            Besluittekst
          </Link>
        </TreeNavItem>

        <TreeNavItem id="rechtsmiddelen" aria-label="Open rechtsmiddelen" asChild>
          <Link to={RechtsmiddelenRoute.fullPath} params={{ besluitId, bedrijfLineageId }}>
            Rechtsmiddelen
          </Link>
        </TreeNavItem>

        {!hoofdstukken.data.length ? (
          <TreeNavItem asChild>
            <Link
              to={VoorschriftenHoofdstukRoute.fullPath}
              params={{ besluitId, bedrijfLineageId }}
            >
              Voorschriften
            </Link>
          </TreeNavItem>
        ) : (
          <TreeNavList
            defaultOpen={isVoorschriftenPage}
            label="Voorschriften"
            listTestId="sublist-voorschriften"
          >
            {hoofdstukken.data.map((hoofdstuk, index) => {
              if (!hoofdstuk.ID) return null;

              const hoofdstukReservering = hoofdstukReserveringen.data?.find(
                ({ Reservering_lineage }) => Reservering_lineage === hoofdstuk.Lineage
              );
              const isGereserveerdByCurrentUser =
                hoofdstukReservering && hoofdstukReservering.Gebruiker === userId;
              const isGereserveerdByOtherUser =
                hoofdstukReservering && hoofdstukReservering.Gebruiker !== userId;

              return (
                <TreeNavItem asChild key={`sidebar-left-hoofdstuk-${hoofdstuk.ID}`}>
                  <Link
                    to={VoorschriftenHoofdstukIdRoute.fullPath}
                    params={{ besluitId, hoofdstukId: hoofdstuk.ID, bedrijfLineageId }}
                    data-testid={`hoofdstuk-${index}`}
                  >
                    <ButtonInner
                      nummer={hoofdstuk.Nummer}
                      title={hoofdstuk.Titel}
                      isLockedByCurrentUser={isGereserveerdByCurrentUser}
                      isLockedByOtherUser={isGereserveerdByOtherUser}
                      showIsEdited={editedHoofdstukIds.includes(hoofdstuk.ID)}
                      isIngetrokken={!!hoofdstuk.Stop_Lineage}
                    />
                  </Link>
                </TreeNavItem>
              );
            })}
          </TreeNavList>
        )}

        <TreeNavItem id="toelichting" aria-label="Bekijk procedurele overwegingen" asChild>
          <Link to={ProcedureleOverwegingenRoute.fullPath} params={{ besluitId, bedrijfLineageId }}>
            Procedurele overwegingen
          </Link>
        </TreeNavItem>

        <TreeNavItem
          id="algemeen-toetsingskader"
          aria-label="Bekijk algemeen toetsingskader"
          asChild
        >
          <Link to={AlgemeenToetsingskaderRoute.fullPath} params={{ besluitId, bedrijfLineageId }}>
            Algemeen toetsingskader
          </Link>
        </TreeNavItem>

        {!hoofdstukken.data.length ? (
          <TreeNavItem disabled>Inhoudelijke overwegingen</TreeNavItem>
        ) : (
          <TreeNavList
            label="Inhoudelijke overwegingen"
            listTestId="sublist-inhoudelijke-overwegingen"
            defaultOpen={isInhoudelijkeOverwegingenPage}
            disabled={hoofdstukken.data.length === 0}
          >
            {hoofdstukken.data.map((hoofdstuk, index) => (
              <TreeNavItem asChild key={`inhoudelijke-overwegingen-${hoofdstuk.ID}`}>
                <Link
                  to={InhoudelijkeOverweginRoute.fullPath}
                  params={{ besluitId, hoofdstukId: hoofdstuk.ID!, bedrijfLineageId }}
                  data-testid={`inhoudelijke-overwegingen-${index}`}
                >
                  <ButtonInner nummer={hoofdstuk.Nummer} title={hoofdstuk.Titel} />
                </Link>
              </TreeNavItem>
            ))}
          </TreeNavList>
        )}

        <TreeNavItem id="overige-overwegingen" aria-label="Bekijk overige overwegingen" asChild>
          <Link to={OverigeOverwegingenRoute.fullPath} params={{ besluitId, bedrijfLineageId }}>
            Overige overwegingen
          </Link>
        </TreeNavItem>

        <TreeNavItem id="begrippen" aria-label="Bekijk begrippen" asChild>
          <Link to={BegrippenRoute.fullPath} params={{ besluitId, bedrijfLineageId }}>
            Begrippen
          </Link>
        </TreeNavItem>

        <TreeNavItem id="bijlage" aria-label="Bekijk bijlage" asChild>
          <Link to={BijlageRoute.fullPath} params={{ besluitId, bedrijfLineageId }}>
            Bijlage
          </Link>
        </TreeNavItem>
      </TreeNav>
    </nav>
  );
};

const ButtonInner = ({
  nummer,
  title,
  isLockedByCurrentUser,
  isLockedByOtherUser,
  showIsEdited,
  isIngetrokken,
}: {
  nummer?: number;
  title?: string;
  isLockedByCurrentUser?: boolean;
  isLockedByOtherUser?: boolean;
  showIsEdited?: boolean;
  isIngetrokken?: boolean;
}) => {
  title = title ? title : '<i>Titel ontbreekt</i>';

  return (
    <span className={twJoin('flex', isIngetrokken && 'line-through')}>
      <span className="mr-1 flex w-6 shrink-0 justify-start break-normal align-baseline">
        {Number.isInteger(nummer) ? nummer : '-'}
      </span>

      <TippyWhenOverflowing content={title} asHTML>
        <span
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </TippyWhenOverflowing>

      {(showIsEdited || isLockedByCurrentUser || isLockedByOtherUser) && (
        <span className="ml-2 flex items-center">
          {showIsEdited && (
            <span className="text-digi-v-color-danger">
              <RegularPenLine size={16} />
            </span>
          )}

          {isLockedByCurrentUser && (
            <span className="ml-1">
              <RegularLock size={16} />
            </span>
          )}

          {isLockedByOtherUser && (
            <span className="ml-1">
              <RegularUserLock size={16} />
            </span>
          )}
        </span>
      )}
    </span>
  );
};
