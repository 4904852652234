import { Outlet, createFileRoute, notFound } from '@tanstack/react-router';

import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useBesluitId } from '@/api/queries/besluit';
import { DocumentTitle } from '@/components';
import { eStrings } from '@/constants/strings';
import { isUserAuthenticatedForRoute } from '@/stores/user/isUserAuthenticatedForRoute';

import { EditorNavigation } from './-components/EditorNavigation';
import { SidebarLeft } from './-components/SidebarLeft';
import { SidebarRight } from './-components/SidebarRight';
import { useEditorStore } from './-store/useEditorStore';

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/editor/$besluitId')({
  beforeLoad: async ({ preload }) => {
    await isUserAuthenticatedForRoute({ preload, allowed: ['Vergunningverlener'] });
  },
  loader: async ({ context: { queryClient }, params: { besluitId, bedrijfLineageId } }) => {
    const [besluit, latestBedrijf] = await Promise.all([
      queryClient.ensureQueryData(useBesluitId.getOptions(besluitId)),
      queryClient.ensureQueryData(useLatestBedrijf.getOptions(bedrijfLineageId)),
    ]);

    if (!besluit || !latestBedrijf.objects?.[0]) throw notFound();
  },
  onLeave: () => {
    // Reset the editor state when the user leaves the editor
    const { reset } = useEditorStore.getState();

    reset();
  },
  component: EditorComponent,
});

/**
 * Route component
 */
function EditorComponent() {
  const { besluitId, bedrijfLineageId } = Route.useParams();

  const besluit = useBesluitId(besluitId);
  const bedrijf = useLatestBedrijf(bedrijfLineageId);

  if (!bedrijf.data || !besluit.data) return null;

  return (
    <div className="min-h-screen bg-gray-200">
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Editor']} />

      <div className="fixed z-20 w-screen">
        <EditorNavigation besluit={besluit.data} bedrijf={bedrijf.data} />
      </div>

      <div className="relative z-20">
        <SidebarLeft />

        <SidebarRight />
      </div>

      <div className="relative z-10 pb-6 pt-32">
        <div id="editor" className="max-w-5xl px-4 sm:px-6 lg:px-8 2xl:mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
