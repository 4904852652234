import { SubmitHandler, useForm } from 'react-hook-form';

import { LabelMapSchema } from '@/api';
import { usePatchLabelMapId, usePostLabelMap } from '@/api/queries/label';
import { Input, Modal, useModalState } from '@/components';
import { PLACEHOLDER_UUID } from '@/constants/placeholder-uuid';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface BeheerLabelMapProps {
  createMap?: boolean;
  updateMap?: LabelMapSchema;
  newMapParentId?: string;
}

type FormValues = Pick<LabelMapSchema, 'Naam'>;

export const BEHEER_LABELS_MAP_ID = 'beheerLabelsMap';

export const BeheerLabelMap = modal(BEHEER_LABELS_MAP_ID, ({ data, props }) => {
  const { createMap, updateMap, newMapParentId } = data;

  const modalState = useModalState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: updateMap,
  });

  const postLabelMap = usePostLabelMap();
  const patchLabelMapId = usePatchLabelMapId();

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const userId = useUserStore.getState().user?.ID ?? '';
    const date = new Date().toISOString();

    modalState.action('save');

    if (createMap) {
      postLabelMap.mutate(
        {
          data: {
            Naam: formData.Naam,
            Created_By: userId,
            Modified_By: userId,
            Created_Date: date,
            Modified_Date: date,
            Map: newMapParentId || null,
            Landelijk: 0,
            // Tenant will be replaced by BE
            Tenant: PLACEHOLDER_UUID,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    }

    if (updateMap) {
      patchLabelMapId.mutate(
        {
          id: updateMap.ID!,
          data: {
            Naam: formData.Naam,
            Modified_By: userId,
            Modified_Date: date,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    }
  };

  return (
    <Modal {...props} state={modalState.state} title="Map">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('Naam', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
          error={errors.Naam}
          label="Titel"
          id="nieuwe-map-title"
          placeholder="Voer een titel in"
          required
          maxLength={200}
        />
        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
            Opslaan
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
