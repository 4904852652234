import { Transition } from '@headlessui/react';
import { useDebouncedCallback, useResizeObserver } from '@react-hookz/web';
import { useMutationState } from '@tanstack/react-query';
import { Link, useBlocker, useParams } from '@tanstack/react-router';
import Tippy from '@tippyjs/react';
import { useCallback, useLayoutEffect, useRef } from 'react';

import { BedrijfSchema, BesluitSchema } from '@/api';
import { useBesluitTypeId } from '@/api/queries/besluitType';
import { IconButton, LoaderContent, LoaderSpinningIcon } from '@/components';
import { RegularArrowLeft, RegularCircleInfo, RegularFileExport } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { useIsExactMatch } from '@/utils/hooks/useIsExactMatch';

import { useEditorMode } from '../-utils/useEditorMode';
import { ReserveringBar } from './ReserveringBar';

export const AUTOSAVE_MUTATION_KEY = ['autosave'];

/**
 * Check if there's any autosave mutations going on.
 *
 * This is a workaround because useIsMutating breaks when adding standaardteksten voorschriften from different paragrafen.
 */
const useIsMutatingAutosave = () => {
  const variables = useMutationState({
    filters: {
      mutationKey: AUTOSAVE_MUTATION_KEY,
    },
    select: (mutation) => mutation.state.status === 'pending',
  });

  const isMutating = !!variables.find(Boolean);

  return isMutating;
};

export const EditorNavigation = ({
  besluit,
  bedrijf,
}: {
  besluit: BesluitSchema;
  bedrijf: BedrijfSchema;
}) => {
  const openModal = useOpenModal();
  const editorMode = useEditorMode(besluit.ID!);
  const besluitType = useBesluitTypeId(besluit.Type);

  const isMutatingAutosave = useIsMutatingAutosave();

  const isVoorschriftenHoofdstukRoute = useIsExactMatch(
    '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId'
  );
  const { hoofdstukId, besluitId } = useParams({
    strict: false,
  });

  /**
   * Check if there's any mutation going on. If so, block the user from leaving the page.
   */
  useBlocker(
    () => window.confirm('Weet je zeker dat je deze pagina wilt verlaten?'),
    isMutatingAutosave
  );

  /**
   * This is a hack to make sure the editor navigation height is set correctly
   * The height is used to position the sidebars correctly
   */
  const containerRef = useRef<HTMLDivElement>(null);
  const updateHeight = useCallback(() => {
    const container = containerRef.current;

    if (!container) return;

    const containerHeight = container.getBoundingClientRect().height;

    const r = document.querySelector<HTMLBaseElement>(':root');
    r?.style.setProperty('--editor-navigation-height', `${containerHeight}px`);
  }, []);

  const updateHeightDebounced = useDebouncedCallback(updateHeight, [], 500);

  useLayoutEffect(updateHeight, [updateHeight, isVoorschriftenHoofdstukRoute]);

  /**
   * Update the height when the container is resized
   */
  useResizeObserver(containerRef, updateHeightDebounced);

  return (
    <div ref={containerRef}>
      <nav data-testid="editor-nav-bar" id="editor-nav" className="inset-x-0 top-0 shadow">
        <div className="pointer-events-auto flex w-full items-center justify-between bg-theme-blue pr-10">
          <div className="flex items-center">
            <Link
              to="/$bedrijfLineageId/bedrijf/ontwerpen"
              aria-label="Sluit editor"
              className="border-theme-blue-lighter -mr-px flex items-center justify-center border-r p-6 text-white transition duration-100
              ease-in hover:-mb-px hover:border-b hover:bg-white hover:text-theme-blue"
              params={{
                bedrijfLineageId: bedrijf.Lineage,
              }}
              search={{
                p: 1,
              }}
            >
              <RegularArrowLeft size={16} />
            </Link>

            <span className="ml-6 font-semibold text-white">{bedrijf.Naam}</span>

            <span className="ml-6 text-sm text-white opacity-75">
              {besluitType.isLoading ? (
                <LoaderContent className="mr-2 bg-gray-300" h="h-3" w="w-20" />
              ) : (
                besluitType.data?.Naam
              )}{' '}
              {besluit.Zaaknummer} {besluit.OLO_Nummer}
            </span>
          </div>

          <div className="flex items-center justify-end text-white">
            <Transition
              show={isMutatingAutosave}
              appear={true}
              className="mr-2 transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Tippy content="Momenteel wordt er een actie uitgevoerd">
                <div>
                  <LoaderSpinningIcon className="text-white" />
                </div>
              </Tippy>
            </Transition>

            <div className="flex items-center opacity-75">
              <p className="text-sm">
                Modus: {editorMode === 'extended' ? 'Uitgebreid' : 'Standaard'}
              </p>

              <Tippy
                content={
                  editorMode === 'extended'
                    ? 'De uitgebreide modus is voor oprichtingen en revisies. In deze modus ben je vrij om te bewerken, verplaatsen, toe te voegen en te verwijderen.'
                    : 'De standaardmodus is voor wijzigingsvergunningen. In deze modus ben je beperkt in het (ver)plaatsen van voorschriften en paragrafen.'
                }
              >
                <div className="ml-2">
                  <RegularCircleInfo size={16} />
                </div>
              </Tippy>
            </div>

            <IconButton
              icon={RegularFileExport}
              variant="white-ghost"
              ariaLabel="Exporteren"
              type="button"
              className="ml-6"
              onClick={() =>
                openModal('besluitExporteren', { besluit, bedrijfLineageId: bedrijf.Lineage })
              }
            />
          </div>
        </div>
      </nav>

      {isVoorschriftenHoofdstukRoute && besluitId && hoofdstukId ? (
        <ReserveringBar besluitId={besluitId} hoofdstukId={hoofdstukId} />
      ) : null}
    </div>
  );
};
