import { Link, createFileRoute, notFound } from '@tanstack/react-router';
import { useRef, useState } from 'react';

import { STDMapSchema } from '@/api';
import { usePatchStdMapId, useStdMapId } from '@/api/queries/std';
import { Button, ButtonState, TextEditor } from '@/components';
import { useOpenModal } from '@/modals/utils';
import { useUserStore } from '@/stores/user';
import { useElementSize } from '@/utils/hooks/useElementSize';

import { VoorschriftenLayout } from './-components/voorschriftenLayout';

/**
 * Route
 */
export const Route = createFileRoute(
  '/beheer/standaardteksten/$mapId/$sectieId/inhoudelijke-overwegingen'
)({
  beforeLoad: ({ context, params }) => ({
    getTitle: () => {
      const sectie = context.queryClient.getQueryData<STDMapSchema>(
        useStdMapId.getKey(params.sectieId)
      );

      if (!sectie) return;

      return `${sectie?.Naam} (inhoudelijke overwegingen)`;
    },
  }),
  loader: async ({ context: { queryClient }, params: { sectieId, mapId } }) => {
    const [sectie, map] = await Promise.all([
      queryClient.ensureQueryData(useStdMapId.getOptions(sectieId)),
      queryClient.ensureQueryData(useStdMapId.getOptions(mapId)),
    ]);

    if (!sectie || !map) throw notFound();
  },
  component: InhoudelijkeOverwegingenComponent,
});

/**
 * Route component
 */
function InhoudelijkeOverwegingenComponent() {
  const [containerRef, { height: containerHeight }] = useElementSize();

  const openModal = useOpenModal();

  const [content, setContent] = useState<string | null>();
  const [buttonState, setButtonState] = useState<ButtonState | undefined>();

  const successTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  const { mapId, sectieId } = Route.useParams();

  const map = useStdMapId(mapId);
  const sectie = useStdMapId(sectieId);
  const patchStdMapId = usePatchStdMapId();

  if (!sectie.data || !map.data) return null;

  const handleUpdate = (value: string) => {
    setContent(value);
  };

  const handleSubmit = () => {
    const userId = useUserStore.getState().user?.ID ?? '';

    clearTimeout(successTimeoutRef.current);

    setButtonState('loading');

    patchStdMapId.mutate(
      {
        data: {
          InhoudelijkeOverweging: content ?? null,
          Modified_By: userId,
          Modified_Date: new Date().toISOString(),
        },
        id: sectieId,
      },
      {
        onSuccess: () => {
          setButtonState('success');

          successTimeoutRef.current = setTimeout(() => {
            setButtonState(undefined);
          }, 3000);
        },
        onError: () => {
          setButtonState(undefined);
          openModal('saveFailed', {
            tryAgain: async () => {
              return patchStdMapId.mutateAsync({
                data: {
                  InhoudelijkeOverweging: content ?? null,
                  Modified_By: userId,
                  Modified_Date: new Date().toISOString(),
                },
                id: sectieId,
              });
            },
          });
        },
      }
    );
  };

  return (
    <VoorschriftenLayout
      backLabel="Terug naar overzicht"
      linkProps={{
        to: `/beheer/standaardteksten/$mapId`,
        params: { mapId },
      }}
      testId={Route.fullPath}
      warning={
        map.data?.Status === 'Archief'
          ? {
              title: `Let op! De bovenliggende map “${map.data.Naam}” inclusief alle inhoud is onzichtbaar voor de gebruiker.`,
              variant: 'warning',
              size: 'small',
            }
          : undefined
      }
      ref={containerRef}
    >
      <div className="px-8 pb-16 pt-6">
        <div className="mb-10 flex items-center justify-between">
          <h1 className="text-lg font-bold">
            Inhoudelijke overwegingen
            {sectie.data?.Status === 'Archief' ? (
              <span className="font-normal">{` `}(Onzichtbaar voor gebruikers)</span>
            ) : (
              ''
            )}
          </h1>

          <Button
            variant="white"
            onClick={() => {
              patchStdMapId.mutate({
                id: sectieId,
                data: {
                  Status: sectie.data?.Status === 'Actief' ? 'Archief' : 'Actief',
                },
              });
            }}
          >
            {sectie.data?.Status === 'Actief' ? 'Onzichtbaar maken' : 'Zichtbaar maken'}
          </Button>
        </div>

        <h2 className="mb-4 text-2xl font-bold">{sectie.data.Naam}</h2>

        <TextEditor
          id="inhoudelijke-overwegingen"
          onUpdate={handleUpdate}
          menuPosition="inline"
          variant="clean"
          editable
          classes={{
            default: 'hover:ring-1 hover:ring-offset-4 hover:ring-gray-300',
            focused: 'ring-1 ring-offset-4 ring-gray-300',
          }}
          minHeight={containerHeight - 300}
          placeholder="Voeg hier de inhoudelijke overwegingen toe"
          initialText={sectie.data.InhoudelijkeOverweging || ''}
          menuOptions={[
            'bold',
            'italic',
            'orderedList',
            'abcList',
            'bulletList',
            'subscript',
            'superscript',
            'underline',
            'table',
            'highlight',
          ]}
        />
      </div>

      <div className="fixed bottom-10 right-10 flex gap-2 bg-white p-2 shadow-2xl">
        <Button variant="white" asChild>
          <Link to="/beheer/standaardteksten/$mapId" params={{ mapId }} search={{ p: 1 }}>
            Annuleren
          </Link>
        </Button>
        <Button variant="green" onClick={handleSubmit} state={buttonState}>
          {buttonState === 'success' ? 'Opgeslagen' : 'Opslaan'}
        </Button>
      </div>
    </VoorschriftenLayout>
  );
}
