import { MutationKey, useQueryClient } from '@tanstack/react-query';

import {
  PatchFixVoorschriftIdMutationError,
  usePatchFixVoorschriftId,
} from '@/api/generated/digiVAPI';
import { VoorschriftSchema } from '@/api/generated/digiVAPI.schemas';

import { useVoorschrift, useVoorschriftId } from '../voorschrift';

type Variables = NonNullable<ReturnType<typeof usePatchFixVoorschriftId>['variables']>;
type Error = PatchFixVoorschriftIdMutationError;

export function usePatchVoorschriftId({
  mutationKey,
  onSuccess,
  onError,
}: {
  mutationKey?: MutationKey;
  onSuccess?: () => void;
  onError?: (error: Error, variables: Variables) => void;
} = {}) {
  const queryClient = useQueryClient();

  return usePatchFixVoorschriftId({
    mutation: {
      mutationKey,
      onSuccess: async (voorschrift) => {
        queryClient.setQueryData<VoorschriftSchema>(
          useVoorschriftId.getKey(voorschrift.ID!),
          voorschrift
        );

        await queryClient.invalidateQueries({ queryKey: useVoorschrift.key });

        onSuccess?.();
      },
      onError: (error, variables) => {
        onError?.(error, variables);
      },
    },
  });
}
