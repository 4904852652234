import { ReactNode, forwardRef } from 'react';
import { ConnectDragSource } from 'react-dnd';

import { StdObjects, StdParagraaf } from '@/api/queries/std';
import { EditorParagraaf, InfoCard } from '@/components';
import { RegularPenToSquare } from '@/icons/components';
import { useOpenModal } from '@/modals/utils/modalStore';

interface ParagraafProps {
  children?: ReactNode;
  stdObjects: StdObjects;
  paragraaf: StdParagraaf['data'];
  paragraafIndex: number;
  sectieId: string;
  dragHandleRef?: ConnectDragSource;
}

export const Paragraaf = forwardRef<HTMLDivElement, ParagraafProps>(
  ({ children, paragraafIndex, sectieId, paragraaf, dragHandleRef }, ref) => {
    const openModal = useOpenModal();

    return (
      <div ref={ref}>
        <InfoCard
          description={paragraaf.Toelichting || 'Geen toelichting'}
          testId={`toelichting-${paragraafIndex}`}
        />

        <EditorParagraaf
          dragHandleRef={dragHandleRef}
          number={paragraaf.Nummer}
          content={paragraaf.Titel}
          buttonProps={{
            icon: RegularPenToSquare,
            variant: 'white',
            ariaLabel: `Paragraaf ${paragraaf.Nummer} bewerken`,
            onClick: () =>
              openModal('standaardtekstenEditParagraafId', {
                sectieId,
                paragraaf,
              }),
          }}
        >
          {children}
        </EditorParagraaf>
      </div>
    );
  }
);
