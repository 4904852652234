import { useQueryClient } from '@tanstack/react-query';

import { usePatchDynStdMapId } from '@/api/generated/digiVAPI';
import { STDMapSchema } from '@/api/generated/digiVAPI.schemas';

import { useStdMap, useStdMapId } from '../stdMappen';

export function usePatchStdMapId({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return usePatchDynStdMapId({
    mutation: {
      mutationKey,
      onSuccess: async (map) => {
        queryClient.setQueryData<STDMapSchema>(useStdMapId.getKey(map.ID!), map);

        return queryClient.invalidateQueries({ queryKey: useStdMap.key });
      },
    },
  });
}
