import { keepPreviousData } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';

import { BedrijfSchema } from '@/api';
import { useBesluit } from '@/api/queries/besluit';
import { Modal, PaginationButtons } from '@/components';
import { eStrings } from '@/constants/strings';
import { BesluitCardVigerend } from '@/routes/$bedrijfLineageId.bedrijf/-components/BesluitCardVigerend';

import { modal } from '../utils';

export interface SelectBesluitProps {
  bedrijf: BedrijfSchema;
}

export const SELECT_BESLUIT_ID = 'selectBesluit';

export const SelectBesluit = modal(SELECT_BESLUIT_ID, ({ data, props }) => {
  const { bedrijf } = data;

  const [currentPage, setCurrentPage] = useState(1);
  const { besluitId } = useParams({
    from: '/$bedrijfLineageId/viewer/besluit/$besluitId',
  });

  const initialBesluitId = useRef(besluitId);

  useEffect(() => {
    if (besluitId !== initialBesluitId.current && props.open) {
      props.onClose();
    }
  }, [besluitId, props]);

  const besluiten = useBesluit(
    {
      page: currentPage,
      size: 8,
      filter: [
        {
          model: 'Bedrijf',
          field: 'Lineage',
          op: 'eq',
          value: bedrijf.Lineage,
        },
        {
          model: 'Besluit',
          field: 'Status',
          op: 'eq',
          value: 'Publicatie',
        },
      ],
      sort: [
        {
          model: 'Besluit',
          direction: 'desc',
          field: 'Besluit_Datum',
        },
      ],
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  return (
    <Modal
      {...props}
      title={eStrings.LBL_BESLUITEN_EN_WIJZIGINGEN}
      isLoading={besluiten.isInitialLoading}
    >
      <ul>
        {besluiten.data?.objects?.map((besluit) => {
          return (
            <li key={besluit.ID} className="[&+&]:mt-4">
              <BesluitCardVigerend bedrijf={bedrijf} besluit={besluit} />
            </li>
          );
        })}
      </ul>

      <div className="flex justify-center">
        <PaginationButtons
          currentPage={currentPage}
          totalLength={besluiten.data?.num_pages ?? 1}
          onSelect={setCurrentPage}
          className="mt-4"
        />
      </div>
    </Modal>
  );
});
