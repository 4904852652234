import { useDebouncedCallback } from '@react-hookz/web';
import { createFileRoute } from '@tanstack/react-router';
import { Editor } from '@tiptap/react';
import { useRef } from 'react';

import { useBesluitId, usePatchBesluitId } from '@/api/queries/besluit';
import { RegularImage } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from './-components/EditorNavigation';
import { ContentTemplate } from './-template/ContentTemplate';

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/editor/$besluitId/bijlage')({
  loader: async ({ context: { queryClient }, params: { besluitId } }) => {
    await queryClient.ensureQueryData(useBesluitId.getOptions(besluitId));
  },
  component: BijlageComponent,
});

/**
 * Route component
 */
function BijlageComponent() {
  const { besluitId } = Route.useParams();

  const openModal = useOpenModal();

  const textEditorRef = useRef<Editor>(null);

  const besluit = useBesluitId(besluitId);
  const patchBesluit = usePatchBesluitId({ mutationKey: AUTOSAVE_MUTATION_KEY });

  const onUpdateDebounced = useDebouncedCallback(
    (value: string) => {
      patchBesluit.mutate(
        {
          id: besluitId,
          data: {
            Bijlage: value,
          },
        },
        {
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              openModal('saveFailed', {
                tryAgain: () =>
                  patchBesluit.mutateAsync({
                    id: besluitId,
                    data: {
                      Bijlage: value,
                    },
                  }),
              });
            }
          },
        }
      );
    },
    [patchBesluit, besluitId],
    300
  );

  if (!besluit.data) {
    return null;
  }

  return (
    <ContentTemplate
      id="page-bijlage-editor"
      title="Bijlage"
      defaultValue={besluit.data.Bijlage ?? ''}
      onUpdate={onUpdateDebounced}
      editorRef={textEditorRef}
      customOptions={[
        {
          icon: RegularImage,
          testId: 'texteditor-add-image',
          ariaLabel: 'Afbeelding toevoegen',
          onClick: () => {
            const initialSelection =
              textEditorRef.current?.isActive('tableCell') ||
              textEditorRef.current?.isActive('paragraph')
                ? textEditorRef.current?.view.state.selection.$anchor.pos
                : null;
            openModal('editorAddImage', {
              onAddImage: (base64Image, alt) => {
                textEditorRef.current
                  ?.chain()
                  .focus(initialSelection)
                  .setImage({ src: base64Image, alt })
                  .run();
              },
            });
          },
        },
      ]}
    />
  );
}
