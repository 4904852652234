import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  deleteDynMetaVoorschriftLabelId,
  getDynMetaVoorschriftLabel,
} from '@/api/generated/digiVAPI';
import { ErrorType } from '@/services/axios';

import { useLabel, useLabelId } from '../label';

export function useDeleteVoorschriftLabel({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return useMutation<
    Awaited<ReturnType<typeof deleteDynMetaVoorschriftLabelId>>,
    ErrorType<string>,
    { voorschriftLineageId: string; labelId: string }
  >({
    mutationKey,
    mutationFn: async ({
      voorschriftLineageId,
      labelId,
    }: {
      voorschriftLineageId: string;
      labelId: string;
    }) => {
      const voorschriftLabel = await getDynMetaVoorschriftLabel({
        page: 1,
        size: 1,
        filter: [
          {
            field: 'Label',
            op: 'eq',
            value: labelId,
          },
          {
            field: 'Voorschrift_lineage',
            op: 'eq',
            value: voorschriftLineageId,
          },
        ],
      });

      const voorschriftLabelId = voorschriftLabel.objects?.[0]?.ID;

      if (!voorschriftLabelId) {
        throw Error('Voorschrift label bestaat niet');
      }

      return await deleteDynMetaVoorschriftLabelId(voorschriftLabelId);
    },
    onSuccess: async (voorschriftLabel) => {
      if (voorschriftLabel) {
        return Promise.all([
          queryClient.invalidateQueries({ queryKey: useLabel.key }),
          queryClient.invalidateQueries({ queryKey: useLabelId.getKey(voorschriftLabel.Label) }),
        ]);
      }
    },
  });
}
