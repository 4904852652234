import { useForm } from 'react-hook-form';

import { VoorschriftSchema } from '@/api';
import { usePatchVoorschriftId } from '@/api/queries/objects';
import { Checkbox } from '@/components';
import { Accordion } from '@/components/shared/Accordion';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';

import { useVoorschriftContext } from './VoorschriftContext';
import { SidebarError } from './VoorschriftSidebar';

type FormValues = Pick<VoorschriftSchema, 'Beroep_Ingediend' | 'Voorlopige_Voorziening'>;

type VoorschriftSidebarMetaProps = {
  errors: SidebarError[];
  patchVoorschriftId: ReturnType<typeof usePatchVoorschriftId>;
};

export const VoorschriftSidebarMeta = ({
  patchVoorschriftId,
  errors,
}: VoorschriftSidebarMetaProps) => {
  const { voorschrift, objectState } = useVoorschriftContext();

  const { register } = useForm<FormValues>({
    // Using the values property will update the checkbox when a new value is fetched from the server
    values: voorschrift,
  });

  const metaError = errors.find((error) => error.request === 'meta');

  return (
    <Accordion defaultOpen>
      <Accordion.Button label="Eigenschappen" />
      <Accordion.Panel>
        <Checkbox
          {...register('Voorlopige_Voorziening')}
          id="voorschrift-sidebar-voorlopige-voorziening"
          label="Dit voorschrift is een voorlopige voorziening"
          disabled={!objectState.isEditable}
          onChange={(e) => {
            patchVoorschriftId.mutate({
              data: {
                Voorlopige_Voorziening: e.target.checked ? 1 : 0,
              },
              id: voorschrift.ID!,
            });
          }}
        />

        <Checkbox
          {...register('Beroep_Ingediend')}
          id="voorschrift-sidebar-voorschrift-beroep"
          label="Voor dit voorschrift is een beroep ingediend"
          disabled={!objectState.isEditable}
          onChange={(e) => {
            patchVoorschriftId.mutate({
              data: {
                Beroep_Ingediend: e.target.checked ? 1 : 0,
              },
              id: voorschrift.ID!,
            });
          }}
        />

        {metaError?.message && (
          <WarningInline description={metaError.message} tryAgain={metaError.tryAgain} />
        )}
      </Accordion.Panel>
    </Accordion>
  );
};
