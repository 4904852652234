import { useQueryClient } from '@tanstack/react-query';

import { usePatchFixBedrijfId } from '@/api/generated/digiVAPI';

import { useBedrijf, useBedrijfId } from '../bedrijf';

export function usePatchBedrijfId() {
  const queryClient = useQueryClient();

  return usePatchFixBedrijfId({
    mutation: {
      onSuccess: (bedrijf) => {
        if (bedrijf.ID) {
          queryClient.setQueryData(useBedrijfId.getKey(bedrijf.ID), bedrijf);
        }

        return queryClient.invalidateQueries({ queryKey: useBedrijf.key });
      },
    },
  });
}
