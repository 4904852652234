import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  BesluitSchema,
  HBSchema,
  HoofdstukLineageSchema,
  HoofdstukSchema,
  OverwegingHoofdstukSchema,
  PHBSchema,
  ParagraafLineageSchema,
  ParagraafSchema,
  SPPBSchema,
  SubparagraafLineageSchema,
  SubparagraafSchema,
  VSPBSchema,
  VoorschriftLineageSchema,
  VoorschriftSchema,
} from '@/api/generated/digiVAPI.schemas';
import { useBesluitId } from '@/api/queries/besluit';
import { useGrondslag } from '@/api/queries/grondslag/useGrondslag';
import { useHoofdstukReservering } from '@/api/queries/hoofdstukReservering';
import {
  useHBId,
  useHoofdstuk,
  useHoofdstukId,
  useHoofdstukLineage,
  useHoofdstukLineageId,
  useLineageParagraaf,
  useLineageParagraafId,
  useLineageSubparagraaf,
  useLineageSubparagraafId,
  useLineageVoorschrift,
  useLineageVoorschriftId,
  useLinksInHoofdstuk,
  usePHB,
  usePHBId,
  useParagraaf,
  useParagraafId,
  useSPPB,
  useSPPBId,
  useSubparagraaf,
  useSubparagraafId,
  useVSPB,
  useVSPBId,
  useVoorschrift,
  useVoorschriftId,
} from '@/api/queries/objects';
import {
  useOverwegingHoofdstuk,
  useOverwegingHoofdstukId,
} from '@/api/queries/overwegingHoofdstuk';
import { ErrorType } from '@/services/axios';

import { CreateHoofdstukResponse, createHoofdstukken } from './utils/createHoofdstuk';
import { CreateParagraafResponse, createParagrafen } from './utils/createParagraaf';
import { CreateSubparagraafResponse, createSubparagrafen } from './utils/createSubparagraaf';
import { CreateVoorschriftResponse, createVoorschriften } from './utils/createVoorschrift';

export type CreateHoofdstukkenProps = {
  type: 'hoofdstukken';
  besluitId: string;
  userId: string;
  hoofdstukken: HoofdstukValues[];
};

export type CreateParagrafenProps = {
  type: 'paragrafen';
  besluitId: string;
  hoofdstukId: string;
  previousRang?: string;
  nextRang?: string;
  paragrafen: ParagraafValues[];
};

export type CreateSubparagrafenProps = {
  type: 'subparagrafen';
  besluitId: string;
  paragraafId: string;
  previousRang?: string;
  nextRang?: string;
  subparagrafen: SubparagraafValues[];
};

export type CreateVoorschriftenProps = {
  type: 'voorschriften';
  besluitId: string;
  subparagraafId: string;
  previousRang?: string;
  nextRang?: string;
  voorschriften: { voorschrift: string }[];
};

export type CreateObjectsResponse = {
  hoofdstukResponses: CreateHoofdstukResponse[];
  paragraafResponses: CreateParagraafResponse[];
  subparagraafResponses: CreateSubparagraafResponse[];
  voorschriftResponses: CreateVoorschriftResponse[];
};

type HoofdstukValues = {
  titel: string;
  overweging?: string;
  paragrafen?: ParagraafValues[];
};

type ParagraafValues = {
  titel: string;
  voorschriften?: VoorschriftValues[];
  subparagrafen?: SubparagraafValues[];
};

type SubparagraafValues = {
  titel: string;
  voorschriften?: VoorschriftValues[];
};

type VoorschriftValues = {
  voorschrift: string;
};

export const USE_CREATE_OBJECTS_MUTATION_KEY = ['useCreateObjects'];

export function useCreateObjects() {
  const queryClient = useQueryClient();

  return useMutation<
    CreateObjectsResponse,
    ErrorType<string>,
    | CreateHoofdstukkenProps
    | CreateParagrafenProps
    | CreateSubparagrafenProps
    | CreateVoorschriftenProps
  >({
    mutationKey: USE_CREATE_OBJECTS_MUTATION_KEY,
    mutationFn: async (props) => {
      /**
       * Fetch besluit to get the lineage ID
       */
      let besluit: BesluitSchema;

      try {
        besluit = await queryClient.fetchQuery(useBesluitId.getOptions(props.besluitId));
      } catch {
        throw new Error(`Besluit ${props.besluitId} not found`);
      }

      const besluitLineageId = besluit.Lineage;

      /**
       * Get milieu grondslag
       *
       * This feature will be added later in the development process. For now, all voorschriften have the "Milieu" grondslag, which will be the first grondslag in the list.
       */
      const [milieuGrondslag] = await queryClient.fetchQuery(useGrondslag.queryOptions());
      const milieuGrondslagId = milieuGrondslag?.ID;

      if (!milieuGrondslagId) {
        throw new Error('No milieu grondslag');
      }

      /**
       * Store response values, used for invalidating the cache
       */
      const hoofdstukResponses: CreateHoofdstukResponse[] = [];
      const paragraafResponses: CreateParagraafResponse[] = [];
      const subparagraafResponses: CreateSubparagraafResponse[] = [];
      const voorschriftResponses: CreateVoorschriftResponse[] = [];

      /**
       * Recursively create objects, based on the type of object
       */
      if (props.type === 'hoofdstukken') {
        const responses = await createHoofdstukken({
          ...props,
          besluitLineageId,
          milieuGrondslagId,
        });

        hoofdstukResponses.push(...responses.hoofdstukResponses);
        paragraafResponses.push(...responses.paragraafResponses);
        voorschriftResponses.push(...responses.voorschriftResponses);
      }

      if (props.type === 'paragrafen') {
        const responses = await createParagrafen({
          ...props,
          besluitLineageId,
          milieuGrondslagId,
        });

        paragraafResponses.push(...responses.paragraafResponses);
        voorschriftResponses.push(...responses.voorschriftResponses);
      }

      if (props.type === 'subparagrafen') {
        const responses = await createSubparagrafen({
          ...props,
          besluitLineageId,
          milieuGrondslagId,
        });

        subparagraafResponses.push(...responses.subparagraafResponses);
        voorschriftResponses.push(...responses.voorschriftResponses);
      }

      if (props.type === 'voorschriften') {
        const responses = await createVoorschriften({
          ...props,
          besluitLineageId,
          milieuGrondslagId,
        });

        voorschriftResponses.push(...responses.voorschriftResponses);
      }

      return {
        hoofdstukResponses,
        paragraafResponses,
        subparagraafResponses,
        voorschriftResponses,
      };
    },
    onSuccess: ({
      hoofdstukResponses,
      paragraafResponses,
      subparagraafResponses,
      voorschriftResponses,
    }) => {
      const invalidateKeys: QueryKey[] = [];

      /**
       * Invalidate hoofdstuk
       */
      hoofdstukResponses.forEach((hoofdstukResponse) => {
        queryClient.setQueryData<HoofdstukSchema>(
          useHoofdstukId.getKey(hoofdstukResponse.hoofdstuk.ID!),
          hoofdstukResponse.hoofdstuk
        );
        queryClient.setQueryData<HoofdstukLineageSchema>(
          useHoofdstukLineageId.getKey(hoofdstukResponse.lineageHoofdstuk.ID!),
          hoofdstukResponse.lineageHoofdstuk
        );
        queryClient.setQueryData<OverwegingHoofdstukSchema>(
          useOverwegingHoofdstukId.getKey(hoofdstukResponse.overwegingHoofdstuk.ID!),
          hoofdstukResponse.overwegingHoofdstuk
        );
        queryClient.setQueryData<HBSchema>(
          useHBId.getKey(hoofdstukResponse.hb.ID!),
          hoofdstukResponse.hb
        );
      });

      if (hoofdstukResponses.length) {
        invalidateKeys.push(
          useHoofdstuk.key,
          useHoofdstukLineage.key,
          useHoofdstukReservering.key,
          useOverwegingHoofdstuk.key
        );
      }

      /**
       * Invalidate paragrafen
       */
      paragraafResponses.forEach(({ lineageParagraaf, paragraaf, phb }) => {
        queryClient.setQueryData<ParagraafSchema>(useParagraafId.getKey(paragraaf.ID!), paragraaf);
        queryClient.setQueryData<ParagraafLineageSchema>(
          useLineageParagraafId.getKey(lineageParagraaf.ID!),
          lineageParagraaf
        );
        queryClient.setQueryData<PHBSchema>(usePHBId.getKey(phb.ID!), phb);
      });

      if (paragraafResponses.length) {
        invalidateKeys.push(useParagraaf.key, useLineageParagraaf.key, usePHB.key);
      }

      /**
       * Invalidate subparagrafen
       */
      subparagraafResponses.forEach(({ lineageSubparagraaf, subparagraaf, sppb }) => {
        queryClient.setQueryData<SubparagraafSchema>(
          useSubparagraafId.getKey(subparagraaf.ID!),
          subparagraaf
        );
        queryClient.setQueryData<SubparagraafLineageSchema>(
          useLineageSubparagraafId.getKey(lineageSubparagraaf.ID!),
          lineageSubparagraaf
        );
        queryClient.setQueryData<SPPBSchema>(useSPPBId.getKey(sppb.ID!), sppb);
      });

      if (subparagraafResponses.length) {
        invalidateKeys.push(useSubparagraaf.key, useLineageSubparagraaf.key, useSPPB.key);
      }

      /**
       * Invalidate voorschriften
       */
      voorschriftResponses.forEach(({ lineageVoorschrift, voorschrift, vspb }) => {
        queryClient.setQueryData<VoorschriftSchema>(
          useVoorschriftId.getKey(voorschrift.ID!),
          voorschrift
        );
        queryClient.setQueryData<VoorschriftLineageSchema>(
          useLineageVoorschriftId.getKey(lineageVoorschrift.ID!),
          lineageVoorschrift
        );
        queryClient.setQueryData<VSPBSchema>(useVSPBId.getKey(vspb.ID!), vspb);
      });

      if (voorschriftResponses.length) {
        invalidateKeys.push(useVoorschrift.key, useLineageVoorschrift.key, useVSPB.key);
      }

      invalidateKeys.push(useLinksInHoofdstuk.key);

      return Promise.all(
        invalidateKeys.map((queryKey) => queryClient.invalidateQueries({ queryKey }))
      );
    },
  });
}
