import { Link } from '@tanstack/react-router';

import { BedrijfSchema, BesluitSchema } from '@/api';
import { useBesluitTypeId } from '@/api/queries/besluitType';
import { BesluitCard, BesluitCardHeadingProps, TextButton } from '@/components';
import { RegularFile, RegularPencil } from '@/icons/components';
import { DateUtils } from '@/utils/dateUtils';

interface BesluitCardBestaandBesluitProps {
  bedrijf: BedrijfSchema;
  besluit: BesluitSchema;
  headingAs?: BesluitCardHeadingProps['headingAs'];
  hoofdstukId?: string;
}

export const BesluitCardVigerend = ({
  bedrijf,
  besluit,
  headingAs,
  hoofdstukId,
}: BesluitCardBestaandBesluitProps) => {
  const besluitType = useBesluitTypeId(besluit.Type);

  const isWijziging = besluitType.data?.Wijziging_zonder_besluit === 1;

  return (
    <>
      <BesluitCard icon={isWijziging ? RegularPencil : RegularFile}>
        <BesluitCard.Body>
          <BesluitCard.Heading
            title={`${besluitType.data?.Naam} ${!isWijziging ? besluit.Zaaknummer : ''}`}
            subtitle={`${isWijziging ? 'Wijziging is doorgevoerd ' : 'Besluit is genomen '}op ${
              besluit.Besluit_Datum && DateUtils.formatReadable(new Date(besluit.Besluit_Datum))
            }`}
            headingAs={headingAs}
          />

          {besluit.Korte_Omschrijving ? (
            <BesluitCard.Description>{besluit.Korte_Omschrijving}</BesluitCard.Description>
          ) : null}

          <BesluitCard.ButtonRow>
            <TextButton asChild size="small">
              {hoofdstukId ? (
                <Link
                  to="/$bedrijfLineageId/viewer/besluit/$besluitId/voorschriften/$hoofdstukId"
                  params={{
                    bedrijfLineageId: bedrijf.Lineage,
                    besluitId: besluit.ID!,
                    hoofdstukId,
                  }}
                >
                  Bekijken
                </Link>
              ) : (
                <Link
                  to="/$bedrijfLineageId/viewer/besluit/$besluitId"
                  params={{ bedrijfLineageId: bedrijf.Lineage, besluitId: besluit.ID! }}
                >
                  Bekijken
                </Link>
              )}
            </TextButton>
          </BesluitCard.ButtonRow>
        </BesluitCard.Body>
      </BesluitCard>
    </>
  );
};
