/**
 * Fetches all pages of data from a paginated API endpoint.
 *
 * @example
 * const data = fetchAllPages((page) => getFixBedrijf({ p: page, size: 100 }));
 */
export const fetchAllPages = async <
  TResponse extends {
    num_objects?: number;
    num_pages?: number;
    objects?: TData[];
    page?: number;
    size?: number;
  },
  TData = NonNullable<TResponse['objects']>[number],
>(
  fetchFunction: (page: number) => Promise<TResponse>
): Promise<TData[]> => {
  const data: TData[] = [];

  let page = 1;
  let maxPages = 1;

  while (page <= maxPages) {
    const response = await fetchFunction(page);

    if (maxPages === 1) {
      maxPages = response.num_pages ?? 1;
    }

    data.push(...(response.objects ?? []));

    if (page >= maxPages) {
      break;
    }

    page++;
  }

  return data;
};
