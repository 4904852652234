import {
  type ILevelsOptions,
  type IParagraphOptions,
  type ImageRun as ImageRunType,
  type Paragraph as ParagraphType,
  type TableCell as TableCellType,
  type TableRow as TableRowType,
  type Table as TableType,
  type TextRun as TextRunType,
} from 'docx';
import { imageDimensionsFromData } from 'image-dimensions';

import {
  BedrijfSchema,
  BesluitSchema,
  BesluitTypeSchema,
  HoofdstukSchema,
  OverwegingHoofdstukSchema,
  ParagraafSchema,
  SubparagraafSchema,
} from '@/api';

import { DocxHoofdstuk, DocxVoorschrift, getEntireVergunning } from './getEntireVergunning';

const parser = new DOMParser();

export const docExport = async ({
  bedrijf,
  besluit,
  besluitType,
  includeHistorisch,
}: {
  bedrijf: BedrijfSchema;
  besluit: BesluitSchema;
  besluitType?: BesluitTypeSchema;
  includeHistorisch: boolean;
}) => {
  const {
    Document,
    LevelFormat,
    AlignmentType,
    NumberFormat,
    Footer,
    PageNumber,
    HeadingLevel,
    ImageRun,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    TextRun,
    WidthType,
  } = await import('docx');

  console.log({ includeHistorisch });

  let instanceLevel = 0;

  const createTable = (table: ChildNode) => {
    const tableData: TableType[] = [];

    function createTableCells(tableCells: HTMLCollectionOf<HTMLTableCellElement>) {
      const tableCellData: TableCellType[] = [];

      function createTableCellContent(tableCell: ChildNode) {
        const tableCellContent: ParagraphType[] = [];

        for (const node of tableCell.childNodes) {
          const isLastNode = node === tableCell.childNodes[tableCell.childNodes.length - 1];

          if (node.nodeName === 'OL' || node.nodeName === 'UL') {
            tableCellContent.push(...createList(node, !isLastNode));
            if (node.nodeName === 'OL') instanceLevel++;
          } else {
            tableCellContent.push(
              new Paragraph({
                children: createTextRun(node),
                spacing: !isLastNode ? { after: size(12) } : undefined,
              })
            );
          }
        }
        return tableCellContent;
      }

      for (const tableCell of tableCells) {
        tableCellData.push(
          new TableCell({
            children: createTableCellContent(tableCell),
            margins: {
              top: 50,
              left: 100,
              bottom: 50,
              right: 100,
            },
            width: {
              size: 100 / tableCells.length,
              type: WidthType.PERCENTAGE,
            },
            rowSpan: tableCell.rowSpan,
            columnSpan: tableCell.colSpan,
          })
        );
      }

      return tableCellData;
    }

    function createTableRows(tableRows: HTMLCollectionOf<HTMLTableRowElement>) {
      const TableRowData: TableRowType[] = [];

      for (const tableRow of tableRows) {
        TableRowData.push(
          new TableRow({ children: createTableCells(tableRow.cells), cantSplit: true })
        );
      }
      return TableRowData;
    }

    tableData.push(
      new Table({
        rows: createTableRows((table as HTMLTableElement).rows),
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
      }),
      new Paragraph({ children: [] })
    );

    return tableData;
  };

  /**
   * Create list
   */
  const createList = (node: ChildNode, addSpacing: boolean) => {
    const paragraphOptions: IParagraphOptions[] = [];

    const parseList = (node: ChildNode, level: number, listRootLevel: number) => {
      const type = getListType(node as Element);

      const parentList = node.parentElement?.closest('UL, OL');
      const parentListType = parentList ? getListType(parentList) : undefined;

      const newListRootLevel = parentListType && parentListType !== type ? level : listRootLevel;

      node.childNodes.forEach((childNode) => {
        if (childNode.nodeName === 'LI') {
          parseListItem({ listItem: childNode, level, type, listRootLevel: newListRootLevel });
        }
      });
    };

    const parseListItem = ({
      listItem,
      level,
      listRootLevel,
      type,
    }: {
      listItem: ChildNode;
      level: number;
      listRootLevel: number;
      type: 'ul' | '123' | 'abc';
    }) => {
      level = Math.min(level, 9);

      listItem.childNodes.forEach((childNode) => {
        if (childNode.nodeName === 'UL' || childNode.nodeName === 'OL') {
          parseList(childNode, level + 1, listRootLevel);
          return;
        }

        if (type === '123') {
          paragraphOptions.push({
            children: createTextRun(childNode),
            numbering: {
              reference: `123-${Math.min(listRootLevel, 3)}`,
              level: level - listRootLevel,
              instance: instanceLevel,
            },
          });
          return;
        }

        if (type === 'abc') {
          paragraphOptions.push({
            children: createTextRun(childNode),
            numbering: {
              reference: `abc-${Math.min(listRootLevel, 3)}`,
              level: level - listRootLevel,
              instance: instanceLevel,
            },
          });
          return;
        }

        if (type === 'ul') {
          paragraphOptions.push({
            children: createTextRun(childNode),
            bullet: { level },
            numbering: { reference: 'my-bullet-points', level, instance: instanceLevel },
          });
          return;
        }
      });
    };

    const getListType = (node: Element) => {
      let type: 'ul' | '123' | 'abc' = 'ul';

      if (node.nodeName === 'UL') {
        type = 'ul';
      } else if (node.nodeName === 'OL') {
        if ((node as Element).getAttribute('data-list-type') === 'abc') {
          type = 'abc';
        } else {
          type = '123';
        }
      }

      return type;
    };

    parseList(node, 0, 0);

    /**
     * Set spacing after the last option
     */
    const lastOption = paragraphOptions[paragraphOptions.length - 1];
    if (addSpacing && lastOption) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (lastOption as any).spacing = {
        after: size(12),
      };
    }

    return paragraphOptions.map((options) => new Paragraph(options));
  };

  const createTextRun = (nodes: ChildNode) => {
    const textData: TextRunType[] & ImageRunType[] = [];

    loopThroughNodes(nodes);

    function loopThroughNodes(nodes: ChildNode) {
      for (const node of nodes.childNodes) {
        if (node.nodeName === 'IMG') {
          const imageBase64 = (node as HTMLImageElement).src.slice(23);

          const maxImageWidth = 680;
          const maxImageHeight = 900;

          const dimensions = imageDimensionsFromData(
            Uint8Array.from(atob(imageBase64), (c) => c.charCodeAt(0))
          );

          let imageHeight = dimensions?.height ?? 0;

          let imageWidth = dimensions?.width ?? 0;

          if (node.parentElement?.closest('tr')) {
            const tableRowLength = node.parentElement?.closest('tr')?.childNodes.length;

            imageHeight = imageHeight / (tableRowLength ?? 1);
            imageWidth = imageWidth / (tableRowLength ?? 1);
          }

          textData.push(
            new ImageRun({
              data: imageBase64,
              transformation: {
                width: imageWidth < maxImageWidth ? imageWidth : maxImageWidth,
                height: imageHeight < maxImageHeight ? imageHeight : maxImageHeight,
              },
            })
          );
        } else if (node.nodeName === 'BR') {
          textData.push(new TextRun({ break: 1 }));
        } else if (node.childNodes.length > 0) {
          loopThroughNodes(node);
        } else {
          textData.push(
            new TextRun({
              text: node?.nodeValue ?? '',
              bold: !!node?.parentElement?.closest('STRONG'),
              italics: !!node?.parentElement?.closest('EM'),
              underline: node?.parentElement?.closest('U') ? {} : undefined,
              superScript: !!node?.parentElement?.closest('SUP'),
              subScript: !!node?.parentElement?.closest('SUB'),
            })
          );
        }
      }
    }
    return textData;
  };

  const createHoofdstuk = (hoofdstuk: HoofdstukSchema) => {
    if (!hoofdstuk.Titel) {
      return [
        new Paragraph({
          heading: HeadingLevel.HEADING_2,
          children: [
            new TextRun({ text: `${hoofdstuk.Nummer} ` }),
            new TextRun({
              text: 'Geen titel voor hoofdstuk ingevuld',
              italics: true,
              color: '#6B6B6B',
            }),
          ],
        }),
      ];
    }

    return createSection(
      parser.parseFromString(`<h2>${hoofdstuk.Nummer} ${hoofdstuk.Titel}</h2>`, 'text/html')
    );
  };

  const createParagraaf = (hoofdstukSchema: HoofdstukSchema, paragraaf: ParagraafSchema) => {
    if (!paragraaf.Titel) {
      return [
        new Paragraph({
          heading: HeadingLevel.HEADING_3,
          children: [
            new TextRun({ text: `${hoofdstukSchema.Nummer}.${paragraaf.Nummer} ` }),
            new TextRun({
              text: 'Geen titel voor paragraaf ingevuld',
              italics: true,
              color: '#6B6B6B',
            }),
          ],
        }),
      ];
    }

    return createSection(
      parser.parseFromString(
        `<h3>${hoofdstukSchema.Nummer}.${paragraaf.Nummer} ${paragraaf.Titel}</h3>`,
        'text/html'
      )
    );
  };

  const createSubparagraaf = (subparagraaf: SubparagraafSchema) => {
    if (!subparagraaf.Titel) {
      return [
        new Paragraph({
          heading: HeadingLevel.HEADING_4,
          children: [
            new TextRun({
              text: 'Geen titel voor subparagraaf ingevuld',
              italics: true,
              color: '#6B6B6B',
            }),
          ],
        }),
      ];
    }

    return createSection(parser.parseFromString(`<h4>${subparagraaf.Titel}</h4>`, 'text/html'));
  };

  const createVoorschrift = (
    hoofdstuk: HoofdstukSchema,
    paragraaf: ParagraafSchema,
    { voorschrift, historischBesluitLink }: DocxVoorschrift
  ) => {
    if (!voorschrift.Voorschrift) {
      return [
        new Paragraph({
          heading: HeadingLevel.HEADING_5,
          children: [
            new TextRun({ text: `${hoofdstuk.Nummer}.${paragraaf.Nummer}.${voorschrift.Nummer} ` }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: 'Dit voorschrift heeft geen inhoud',
              italics: true,
              color: '#6B6B6B',
            }),
          ],
        }),
      ];
    }

    return createSection(
      parser.parseFromString(
        `<h5>${hoofdstuk.Nummer}.${paragraaf.Nummer}.${voorschrift.Nummer}${
          includeHistorisch && historischBesluitLink?.Origineel_voorschriftnummer
            ? ` (voorheen ${historischBesluitLink.Origineel_voorschriftnummer})`
            : ''
        }</h5>${voorschrift.Voorschrift}`,
        'text/html'
      )
    );
  };

  const createSection = (elements: globalThis.Document) => {
    const sectionData: ParagraphType[] & TableType[] = [];

    for (const node of elements.body.childNodes) {
      if (node.nodeName.match(/^[h][\d]$/i)) {
        sectionData.push(
          new Paragraph({
            heading:
              node.nodeName === 'H1'
                ? HeadingLevel.HEADING_1
                : node.nodeName === 'H2'
                  ? HeadingLevel.HEADING_2
                  : node.nodeName === 'H3'
                    ? HeadingLevel.HEADING_3
                    : node.nodeName === 'H4'
                      ? HeadingLevel.HEADING_4
                      : HeadingLevel.HEADING_5,
            children: createTextRun(node),
          })
        );
      } else if (node.nodeName === 'TABLE') {
        sectionData.push(...createTable(node));
      } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
        sectionData.push(...createList(node as Element, true));
        if (node.nodeName === 'OL') instanceLevel++;
      } else {
        sectionData.push(
          new Paragraph({ children: createTextRun(node), spacing: { after: size(12) } })
        );
      }
    }

    return sectionData;
  };

  const createHoofdstukSection = (hoofdstukData: DocxHoofdstuk) => {
    const hoofdstuk = hoofdstukData.hoofdstuk;
    const paragrafen = hoofdstukData.paragrafen;

    const hoofdstukSection: ParagraphType[] & TableType[] = [];

    hoofdstukSection.push(...createHoofdstuk(hoofdstuk));

    for (const paragraafData of paragrafen) {
      const paragraaf = paragraafData.paragraaf;

      hoofdstukSection.push(...createParagraaf(hoofdstuk, paragraaf));

      for (const subparagraafData of paragraafData.subparagrafen) {
        if (!subparagraafData.subparagraaf.Transparent) {
          hoofdstukSection.push(...createSubparagraaf(subparagraafData.subparagraaf));
        }

        for (const voorschrift of subparagraafData.voorschriften) {
          hoofdstukSection.push(...createVoorschrift(hoofdstuk, paragraaf, voorschrift));
        }
      }
    }

    return hoofdstukSection;
  };

  const createInhoudelijkeOverwegingSections = (
    hoofdstuk: HoofdstukSchema,
    Overweging: OverwegingHoofdstukSchema
  ) => {
    const parsedInhoudelijkeOverweging = parser.parseFromString(
      `<h2>${hoofdstuk.Nummer}.  ${hoofdstuk.Titel}</h2>${Overweging.InhoudelijkeOverweging}`,
      'text/html'
    );

    return createSection(parsedInhoudelijkeOverweging);
  };

  const besluitTekst = parser.parseFromString(
    `<h1>Besluittekst</h1>${besluit.Besluit_Tekst}`,
    'text/html'
  );

  const rechtsmiddelen = parser.parseFromString(
    `<h1>Rechtsmiddelen</h1>${besluit.Duiding}`,
    'text/html'
  );

  const procedureleOverwegingen = parser.parseFromString(
    `<h1>Procedurele overwegingen</h1>${besluit.Algemene_Overweging}`,
    'text/html'
  );

  const algemeenToetsingskader = parser.parseFromString(
    `<h1>Algemeen toetsingskader</h1>${besluit.Toetsingskader}`,
    'text/html'
  );

  const overigeOverwegingen = parser.parseFromString(
    `<h1>Overige overwegingen</h1>${besluit.Overige_Overweging}`,
    'text/html'
  );

  const begrippen = parser.parseFromString(`<h1>Begrippen</h1>${besluit.Begrippen}`, 'text/html');

  const bijlage = parser.parseFromString(`<h1>Bijlage</h1>${besluit.Bijlage}`, 'text/html');

  const hoofdstukken = await getEntireVergunning(besluit.ID!);

  const hoofdstukSections = [];

  const inhoudelijkeOverwegingSections = [];

  for (const hoofdstukData of hoofdstukken ?? []) {
    if (hoofdstukData.hoofdstuk.Ontwerp || hoofdstukData.paragrafen.length) {
      hoofdstukSections.push({
        properties: {},
        children: createHoofdstukSection(hoofdstukData),
      });
    }
    if (hoofdstukData.overweging?.InhoudelijkeOverweging) {
      inhoudelijkeOverwegingSections.push({
        properties: {},
        children: createInhoudelijkeOverwegingSections(
          hoofdstukData.hoofdstuk,
          hoofdstukData.overweging
        ),
      });
    }
  }

  hoofdstukSections[0]?.children.unshift(
    new Paragraph({
      heading: HeadingLevel.HEADING_1,
      text: 'Voorschriften',
    })
  );

  inhoudelijkeOverwegingSections[0]?.children.unshift(
    new Paragraph({
      heading: HeadingLevel.HEADING_1,
      text: 'Inhoudelijke overwegingen',
    })
  );

  const frontPageSection = [
    new Paragraph({
      children: [
        new TextRun({
          children: ['Bedrijfsnaam en adres'],
          bold: true,
        }),
        new TextRun({
          children: [bedrijf.Naam || ''],
          break: 2,
        }),
        new TextRun({
          children: [`${bedrijf.Straatnaam} ${bedrijf.Huisnummer}`],
          break: 2,
        }),
        new TextRun({
          children: [bedrijf.Postcode || ''],
          break: 1,
        }),
        new TextRun({
          children: [bedrijf.Plaatsnaam || ''],
          break: 1,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({ children: ['Type besluit'], bold: true, break: 2 }),
        new TextRun({ children: [besluitType?.Naam || ''], break: 2 }),
      ],
    }),
  ];

  if (!besluitType?.Wijziging_zonder_besluit) {
    frontPageSection.push(
      new Paragraph({
        children: [
          new TextRun({ children: ['Zaaknummer'], bold: true, break: 2 }),
          new TextRun({ children: [besluit.Zaaknummer || ''], break: 2 }),
        ],
      }),
      new Paragraph({
        children: [
          new TextRun({ children: ['OLO_Nummer'], bold: true, break: 2 }),
          new TextRun({
            children: [besluit.OLO_Nummer || 'Niet ingevuld'],
            break: 2,
            color: besluit.Ons_Kenmerk ? '' : '#6B6B6B',
            italics: !besluit.OLO_Nummer,
          }),
        ],
      }),
      new Paragraph({
        children: [
          new TextRun({ children: ['Besluit Kenmerk'], bold: true, break: 2 }),
          new TextRun({
            children: [besluit.Ons_Kenmerk || 'Niet ingevuld'],
            break: 2,
            color: besluit.Ons_Kenmerk ? '' : '#6B6B6B',
            italics: !besluit.Ons_Kenmerk,
          }),
        ],
      }),
      new Paragraph({
        children: [
          new TextRun({ children: ['Status'], bold: true, break: 2 }),
          new TextRun({
            children: [besluit.Ontwerp_Beschikking === 0 ? 'Beschikking' : 'Ontwerpbeschikking'],
            break: 2,
          }),
        ],
      })
    );
  }

  frontPageSection.push(
    new Paragraph({
      children: [
        new TextRun({ children: ['Omschrijving'], bold: true, break: 2 }),
        new TextRun({
          children: [besluit.Korte_Omschrijving || 'Niet ingevuld'],
          color: besluit.Ons_Kenmerk ? '' : '#6B6B6B',
          italics: !besluit.Korte_Omschrijving,
          break: 2,
        }),
      ],
    })
  );

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: {
            size: '10pt',
            font: 'Arial',
          },
          paragraph: {
            spacing: {
              line: size(14),
            },
          },
        },
        listParagraph: {
          paragraph: {
            spacing: {
              line: size(14),
            },
          },
        },
        heading1: {
          run: {
            size: '16pt',
            color: '#000000',
          },
          paragraph: {
            spacing: {
              after: size(12),
            },
          },
        },
        heading2: {
          run: {
            size: '13pt',
            color: '#000000',
          },
          paragraph: {
            spacing: {
              after: size(12),
            },
          },
        },
        heading3: {
          run: {
            size: '12pt',
            color: '#000000',
          },
          paragraph: {
            spacing: {
              after: size(12),
            },
          },
        },
        heading4: {
          run: {
            size: '11pt',
            color: '#000000',
            italics: true,
          },
          paragraph: {
            spacing: {
              after: size(12),
            },
          },
        },
        heading5: {
          run: {
            size: '10pt',
            color: '#000000',
          },
        },
      },
    },
    numbering: {
      config: [
        ...createOrderedListConfig({
          levels: [
            {
              format: LevelFormat.DECIMAL,
              text: '%1.',
            },
            {
              format: LevelFormat.LOWER_LETTER,
              text: '%2.',
            },
            {
              format: LevelFormat.DECIMAL,
              text: '%3°.',
            },
            {
              format: LevelFormat.DECIMAL,
              text: '%4.',
            },
            {
              format: LevelFormat.DECIMAL,
              text: '%5.',
            },
          ],
          reference: '123',
        }),
        ...createOrderedListConfig({
          levels: [
            {
              format: LevelFormat.LOWER_LETTER,
              text: '%1.',
            },
            {
              format: LevelFormat.DECIMAL,
              text: '%2.',
            },
            {
              format: LevelFormat.DECIMAL,
              text: '%3°.',
            },
            {
              format: LevelFormat.DECIMAL,
              text: '%4.',
            },
            {
              format: LevelFormat.DECIMAL,
              text: '%5.',
            },
          ],
          reference: 'abc',
        }),
        {
          reference: 'my-bullet-points',
          levels: [
            {
              level: 0,
              format: LevelFormat.BULLET,
              text: '-',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: 260, hanging: 260 },
                },
              },
            },
            {
              level: 1,
              format: LevelFormat.BULLET,
              text: '-',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: 500, hanging: 260 },
                },
              },
            },
            {
              level: 2,
              format: LevelFormat.BULLET,
              text: '-',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: 740, hanging: 260 },
                },
              },
            },
            {
              level: 3,
              format: LevelFormat.BULLET,
              text: '-',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: 980, hanging: 260 },
                },
              },
            },
            {
              level: 4,
              format: LevelFormat.BULLET,
              text: '-',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: 1220, hanging: 260 },
                },
              },
            },
          ],
        },
      ],
    },
    sections: [
      {
        properties: {
          titlePage: true,
          page: {
            pageNumbers: {
              start: 1,
              formatType: NumberFormat.DECIMAL,
            },
          },
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: ['Blad ', PageNumber.CURRENT],
                  }),
                  new TextRun({
                    children: [' van ', PageNumber.TOTAL_PAGES],
                  }),
                ],
              }),
            ],
          }),
        },
        children: frontPageSection,
      },
      {
        children: createSection(besluitTekst),
      },
      {
        children: createSection(rechtsmiddelen),
      },
      ...hoofdstukSections,
      {
        children: createSection(procedureleOverwegingen),
      },
      {
        children: createSection(algemeenToetsingskader),
      },
      ...inhoudelijkeOverwegingSections,
      {
        children: createSection(overigeOverwegingen),
      },
      {
        children: createSection(begrippen),
      },
      {
        children: createSection(bijlage),
      },
    ],
  });

  return doc;
};

const size = (times: number) => 20 * times;

const createOrderedListConfig = ({
  reference,
  levels,
}: {
  reference: string;
  levels: Pick<ILevelsOptions, 'text' | 'format'>[];
}): {
  levels: ILevelsOptions[];
  reference: string;
}[] => {
  const configs: {
    levels: ILevelsOptions[];
    reference: string;
  }[] = Array.from({ length: 3 }).map((_, rootLevel) => ({
    reference: `${reference}-${rootLevel}`,
    levels: levels.map((level, index) => ({
      level: index,
      format: level.format,
      text: level.text,
      alignment: 'left',
      style: {
        paragraph: {
          indent: { left: 20 + 240 * (rootLevel + index + 1), hanging: 260 },
        },
      },
    })),
  }));

  return configs;
};
