import { Slot } from '@radix-ui/react-slot';
import { Link, useNavigate } from '@tanstack/react-router';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { Button, Logo, OptionsMenu, RoleBasedAccess } from '@/components';
import { useOpenModal } from '@/modals/utils';
import { useUserStore } from '@/stores/user';

const Navbar = () => {
  const openModal = useOpenModal();

  const loggedIn = useUserStore((state) => state.isLoggedIn);

  return (
    <>
      <nav className="relative z-10 bg-white shadow" id="main-nav" aria-label="Hoofdnavigatie">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 justify-between">
            <Link
              to="/"
              search={{ p: 1 }}
              className="py-1 focus:outline-none focus-visible:ring"
              aria-label="Ga naar homepagina"
            >
              <Logo />
            </Link>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-end">
              <div className="hidden sm:ml-6 sm:flex">
                <NavbarButton asChild>
                  <Link to="/" search={{ p: 1 }} activeOptions={{ includeSearch: false }}>
                    Zoeken
                  </Link>
                </NavbarButton>

                {loggedIn ? (
                  <>
                    <RoleBasedAccess
                      allowed={['Beheerder']}
                      area={['landelijk', 'omgevingsdienst']}
                    >
                      <NavbarButton asChild>
                        <Link to="/beheer" activeOptions={{ exact: false }}>
                          Beheer
                        </Link>
                      </NavbarButton>
                    </RoleBasedAccess>

                    <RoleBasedAccess allowed={['Beheerder']} area={['omgevingsdienst']}>
                      <div className="ml-8 flex items-center justify-center">
                        <Button
                          variant="green"
                          onClick={() => {
                            openModal('bedrijfsInformatie', {
                              title: 'Bedrijf toevoegen',
                            });
                          }}
                        >
                          Bedrijf toevoegen
                        </Button>
                      </div>
                    </RoleBasedAccess>

                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                      <div className="relative">
                        <UserDropdown />
                      </div>
                    </div>
                  </>
                ) : (
                  <NavbarButton asChild>
                    <Link to="/login">Login</Link>
                  </NavbarButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

const UserDropdown = () => {
  const user = useUserStore((state) => state.user);
  const userRole = useUserStore((state) => state.userRole);
  const logout = useUserStore((state) => state.logout);

  const navigate = useNavigate();

  const getUserInitials = (username: string) => {
    return username.charAt(0).toUpperCase();
  };

  return (
    <OptionsMenu>
      <OptionsMenu.Button>
        <button
          aria-label="Gebruikersmenu"
          className="flex rounded-full border-2 border-transparent text-sm transition duration-150 ease-in-out focus:border-gray-300 focus:outline-none"
        >
          <div
            id="user-icon-initials"
            className="flex size-10 items-center justify-center rounded-full bg-theme-blue text-center text-base font-medium text-white"
          >
            {user && user.Gebruikersnaam && <span>{getUserInitials(user.Gebruikersnaam)}</span>}
          </div>
        </button>
      </OptionsMenu.Button>

      <OptionsMenu.Items
        options={[
          {
            variant: 'button',
            title: `Rol: ${userRole?.join(', ')}`,
          },
          {
            variant: 'button',
            title: 'Uitloggen',
            onClick: () => {
              logout();
              navigate({
                to: '/login',
              });
            },
          },
        ]}
      />
    </OptionsMenu>
  );
};

function NavbarButton({ children, asChild }: { asChild?: boolean; children: ReactNode }) {
  const Component = (asChild ? Slot : 'button') as 'button';

  return (
    <Component
      className={twJoin(
        'ml-8 inline-flex items-center border-b-2 px-1 text-sm font-medium leading-5 transition duration-150 ease-in-out ',
        'focus:outline-none focus-visible:text-gray-700 focus-visible:ring',
        'border-transparent text-gray-500 hover:text-gray-700',
        '[&.active]:border-theme-green [&.active]:text-gray-900'
      )}
    >
      {children}
    </Component>
  );
}

export default Navbar;
