import { createContext, useContext } from 'react';

import { ModalProps } from '../Modal';

/**
 * Context is used to store the props of the Modal component.
 *
 * Compound components can use this context to access the props of the Modal component.
 */
export const ModalPropsContext = createContext<ModalProps | null>(null);
export const useModalProps = () => useContext(ModalPropsContext);
