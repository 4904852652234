import {
  getGetOthGebruikerQueryKey,
  getGetOthGebruikerQueryOptions,
  useGetOthGebruiker,
} from '@/api/generated/digiVAPI';

export const useGebruiker = {
  get: useGetOthGebruiker,
  getById: useGetGebruikerById,
  queryKey: getGetOthGebruikerQueryKey,
  queryOptions: getGetOthGebruikerQueryOptions,
};

function useGetGebruikerById(id?: string) {
  return useGebruiker.get({
    query: {
      enabled: !!id,
      select: (gebruikers) => {
        if (!id || !gebruikers.length) return null;

        return gebruikers.find((gebruiker) => gebruiker.ID === id) ?? null;
      },
    },
  });
}
