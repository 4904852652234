import { Editor } from '@tiptap/react';
import { Fragment } from 'react';
import { twJoin } from 'tailwind-merge';

import { TextEditorProps } from '@/components/shared/TextEditor';
import { getGroupedConfig } from '@/components/shared/TextEditor/config/config';

import { TextEditorMenuButton } from '../TextEditorMenuButton';
import { TextEditorMenuDropdown } from '../TextEditorMenuDropdown';

export type TextEditorMenuBarProps = Pick<
  TextEditorProps,
  'id' | 'menuOptions' | 'customOptions'
> & {
  editor?: Editor;
};

export const TextEditorMenuBar = ({
  id,
  editor,
  menuOptions,
  customOptions,
}: TextEditorMenuBarProps) => {
  if (!editor) {
    return null;
  }

  const groupedConfig = getGroupedConfig(menuOptions);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        data-testid="text-editor-menu-bar"
        className="inline-flex rounded-md bg-gray-900 p-1"
        /** To prevent the document blur event from triggering: */
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {groupedConfig.map((group, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-group-${index}`}
            className={twJoin(
              'flex gap-1',
              groupedConfig.length - 1 !== index && 'mr-1 border-r-2 border-r-gray-800 pr-1'
            )}
          >
            {group.map((config) => {
              const { button, dropdown } = config.create(editor);

              return (
                <Fragment key={`text-editor-menu-bar-button-${button?.option}`}>
                  {button && <TextEditorMenuButton {...button} />}
                  {dropdown?.isShown?.() && <TextEditorMenuDropdown {...dropdown} />}
                </Fragment>
              );
            })}
          </div>
        ))}

        {customOptions?.length && (
          <div className="ml-1 flex gap-1 border-l-2 border-l-gray-800 pl-1">
            {customOptions.map(({ label, onClick, icon, ariaLabel, testId }) => (
              <TextEditorMenuButton
                key={`text-editor-menu-custom-option-${ariaLabel}`}
                testId={testId}
                onClick={(e) => {
                  onClick(e, editor);
                }}
                ariaLabel={ariaLabel}
                label={label}
                icon={icon}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
