import {
  getDynStdParagraaf,
  getDynStdSubparagraaf,
  getDynStdVoorschrift,
} from '@/api/generated/digiVAPI';
import {
  STDParagraafSchema,
  STDSubparagraafSchema,
  STDVoorschriftSchema,
} from '@/api/generated/digiVAPI.schemas';

export type StdObjects = {
  paragrafen: StdParagraaf[];
};

export type StdParagraaf = {
  data: STDParagraafSchema;
  subparagrafen: StdSubparagraaf[];
};

export type StdSubparagraaf = {
  data: STDSubparagraafSchema;
  voorschriften: STDVoorschriftSchema[];
};

export const getStdObjects = async ({ sectieId }: { sectieId: string }) => {
  /**
   * Fetch all paragrafen and subparagrafen based on sectieId
   */
  const [paragrafen, subparagrafen] = await Promise.all([
    getDynStdParagraaf({
      page: 1,
      size: 100,
      sort: [{ field: 'Rang', direction: 'asc' }],
      filter: [
        {
          field: 'Map',
          op: 'eq',
          value: sectieId,
        },
      ],
    }),
    getDynStdSubparagraaf({
      page: 1,
      size: 100,
      sort: [{ model: 'STDSubparagraaf', field: 'Rang', direction: 'asc' }],
      filter: [
        {
          model: 'STDParagraaf',
          field: 'Map',
          op: 'eq',
          value: sectieId,
        },
      ],
    }),
  ]);

  /**
   * Recursively fetch voorschriften for each subparagraaf
   */
  const voorschriften = (
    await Promise.all(
      subparagrafen.objects?.map(({ ID }) =>
        getDynStdVoorschrift({
          page: 1,
          size: 100,
          sort: [{ field: 'Rang', direction: 'asc' }],
          filter: [
            {
              field: 'Subparagraaf',
              op: 'eq',
              value: ID!,
            },
          ],
        })
      ) ?? []
    )
  )
    .map((voorschriften) => voorschriften.objects ?? [])
    .flat();

  /**
   * Created a nested object structure of the fetched data
   */
  const stdObjects: StdObjects = {
    paragrafen: [],
  };

  paragrafen.objects?.forEach((data) => {
    const paragraaf: StdParagraaf = {
      data,
      subparagrafen: [],
    };

    const filteredSubparagrafen =
      subparagrafen.objects?.filter((subparagraaf) => subparagraaf.Paragraaf === data.ID) ?? [];

    filteredSubparagrafen.forEach((data) => {
      const subparagraaf: StdSubparagraaf = {
        data,
        voorschriften: [],
      };

      const filteredVoorschriften =
        voorschriften.filter((voorschrift) => voorschrift.Subparagraaf === subparagraaf.data.ID) ??
        [];

      filteredVoorschriften.forEach((voorschrift) => {
        subparagraaf.voorschriften.push(voorschrift);
      });

      paragraaf.subparagrafen.push(subparagraaf);
    });

    stdObjects.paragrafen.push(paragraaf);
  });

  return stdObjects;
};
