import { HoofdstukSchema, ParagraafSchema, VSPBSchemaType, VoorschriftSchema } from '@/api';

type ObjectProps =
  | Pick<HoofdstukSchema, 'Stop_Lineage' | 'Ontwerp' | 'Vorig'>
  | Pick<ParagraafSchema, 'Stop_Lineage' | 'Ontwerp' | 'Vorig'>
  | Pick<VoorschriftSchema, 'Stop_Lineage' | 'Ontwerp' | 'Vorig'>;

export type ObjectState = {
  /** Current status of the object */
  state: 'new' | 'edited' | 'deleted' | 'vigerend' | null;
  /** Current slot (DHEPO) status of the object */
  slotState: 'new' | 'edited' | 'deleted' | null;
  /** When true, the contents of the object can be edited */
  isEditable: boolean;
};

export const getObjectState = ({
  object,
  linkType,
  userHasReservering,
}: {
  object?: ObjectProps;
  linkType?: VSPBSchemaType;
  userHasReservering?: boolean;
}): ObjectState => {
  if (!object) {
    return {
      state: null,
      slotState: null,
      isEditable: false,
    };
  }

  const { Stop_Lineage, Vorig } = object;

  /**
   * Generate state
   */
  let state: ObjectState['state'] = null;

  if (Stop_Lineage) {
    state = 'deleted';
  } else if (!Vorig && object.Ontwerp) {
    state = 'new';
  } else if (object.Ontwerp) {
    state = 'edited';
  } else {
    state = 'vigerend';
  }

  /**
   * Generate slot state
   */
  const slotState = linkType === 'MBIO' && state !== 'vigerend' ? state : null;

  /**
   * Generate isEditable boolean
   */
  const isEditable = !!(
    userHasReservering &&
    !slotState &&
    (state === 'new' || state === 'edited')
  );

  return {
    state,
    slotState,
    isEditable,
  };
};
