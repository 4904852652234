import { LexoRank } from 'lexorank';

import {
  getFixParagraaf,
  postFixLineageParagraaf,
  postFixLinkPhb,
  postFixParagraaf,
} from '@/api/generated/digiVAPI';
import {
  PHBSchema,
  ParagraafLineageSchema,
  ParagraafSchema,
} from '@/api/generated/digiVAPI.schemas';
import { getRangBetween } from '@/utils/getRangBetween';

import { CreateParagrafenProps } from '../useCreateObjects';
import {
  CreateSubparagraafResponse,
  createSubparagraaf,
  createSubparagrafen,
} from './createSubparagraaf';
import { CreateVoorschriftResponse, createVoorschriften } from './createVoorschrift';

export type CreateParagraafResponse = {
  lineageParagraaf: ParagraafLineageSchema;
  paragraaf: ParagraafSchema;
  phb: PHBSchema;
};

export async function createParagrafen({
  besluitId,
  besluitLineageId,
  hoofdstukId,
  milieuGrondslagId,
  paragrafen,
  previousRang,
  nextRang,
}: CreateParagrafenProps & { milieuGrondslagId: string; besluitLineageId: string }) {
  const paragraafResponses: CreateParagraafResponse[] = [];
  const subparagraafResponses: CreateSubparagraafResponse[] = [];
  const voorschriftResponses: CreateVoorschriftResponse[] = [];

  let previousParagraafResponse: CreateParagraafResponse | undefined;

  for (const paragraafProps of paragrafen) {
    const paragraafResponse = await createParagraaf({
      besluitId,
      besluitLineageId,
      hoofdstukId,
      milieuGrondslagId,
      previousRang: previousParagraafResponse?.phb.Rang || previousRang,
      nextRang: nextRang,
      titel: paragraafProps.titel,
    });

    paragraafResponses.push(paragraafResponse);

    /**
     * Create voorschriften in transparent subparagraaf
     */
    const transparantSubparagraafResponse = await createSubparagraaf({
      besluitId,
      besluitLineageId,
      milieuGrondslagId,
      paragraafId: paragraafResponse.paragraaf.ID!,
      titel: '',
      previousRang: LexoRank.min().toString(),
      transparent: true,
    });

    subparagraafResponses.push(transparantSubparagraafResponse);

    if (paragraafProps.voorschriften?.length) {
      const voorschriften = await createVoorschriften({
        besluitId,
        besluitLineageId,
        milieuGrondslagId,
        subparagraafId: transparantSubparagraafResponse.subparagraaf.ID!,
        type: 'voorschriften',
        voorschriften: paragraafProps.voorschriften,
      });

      voorschriftResponses.push(...voorschriften.voorschriftResponses);
    }

    /**
     * Create non-transparent subparagrafen
     */
    if (paragraafProps.subparagrafen?.length) {
      const { subparagraafResponses, voorschriftResponses } = await createSubparagrafen({
        besluitId,
        besluitLineageId,
        milieuGrondslagId,
        paragraafId: paragraafResponse.paragraaf.ID!,
        subparagrafen: paragraafProps.subparagrafen,
        previousRang: transparantSubparagraafResponse.sppb.Rang,
        transparent: false,
        type: 'subparagrafen',
      });

      subparagraafResponses.push(...subparagraafResponses);
      voorschriftResponses.push(...voorschriftResponses);
    }

    previousParagraafResponse = paragraafResponse;
  }

  return {
    paragraafResponses,
    subparagraafResponses,
    voorschriftResponses,
  };
}

export const createParagraaf = async ({
  titel,
  milieuGrondslagId,
  besluitId,
  besluitLineageId,
  hoofdstukId,
  previousRang,
  nextRang,
}: {
  milieuGrondslagId: string;
  titel: string;
  besluitId: string;
  besluitLineageId: string;
  hoofdstukId: string;
  previousRang?: string;
  nextRang?: string;
}): Promise<CreateParagraafResponse> => {
  /**
   * Create lineage paragraaf
   */
  const lineageParagraaf = await postFixLineageParagraaf({
    Besluit_lineage: besluitLineageId,
  });

  if (!lineageParagraaf.ID) {
    throw new Error('No lineage paragraaf ID');
  }

  /**
   * Get highest paragraaf nummer
   */
  const paragraafWithHighestNummer = await getFixParagraaf({
    page: 1,
    size: 1,
    filter: [
      {
        model: 'PHB',
        field: 'Hoofdstuk',
        op: 'eq',
        value: hoofdstukId,
      },
    ],
    sort: [
      {
        model: 'Paragraaf',
        field: 'Nummer',
        direction: 'desc',
      },
    ],
  });

  const highestParagraafNummer = paragraafWithHighestNummer.objects?.[0]?.Nummer ?? 0;

  /**
   * Create Paragraaf
   */
  const paragraaf = await postFixParagraaf({
    Grondslag: milieuGrondslagId,
    Lineage: lineageParagraaf.ID,
    Nummer: highestParagraafNummer ? highestParagraafNummer + 1 : 1,
    Ontwerp: 1,
    Stop_Lineage: 0,
    Titel: titel,
    Index: 0,
  });

  if (!paragraaf.ID) {
    throw new Error('No paragraaf ID');
  }

  /**
   * Create PHB
   */
  const newRang = getRangBetween(previousRang, nextRang);

  const phb = await postFixLinkPhb({
    Besluit: besluitId,
    Hoofdstuk: hoofdstukId,
    Paragraaf: paragraaf.ID,
    Rang: newRang,
    Type: 'Eigen',
  });

  return {
    lineageParagraaf,
    paragraaf,
    phb,
  };
};
