// ErrorType is a simplified version of the Axios ErrorType that matches the Error interface generated by Orval and the ErrorType<string>
type ErrorTypeSimplified = {
  response?: { status: number };
  message: string;
};

class ErrorMessageSingleton {
  GLOBAL_ERROR_CODES = [500, 401];

  MESSAGE_401 = 'Je moet ingelogd zijn om deze actie uit te voeren';
  MESSAGE_500 = 'Neem contact op met de systeembeheerder';
  MESSAGE_DEFAULT_LOCAL = 'Probeer het later opnieuw';

  /**
   * Returns an error message that should be showed locally, in a modal or underneath a form for example
   */
  getLocalErrorMessage(error: ErrorTypeSimplified): string | undefined {
    const status = error.response?.status;

    /**
     * If the status is a global error code, we don't want to show a local error message
     */
    if (status && this.GLOBAL_ERROR_CODES.includes(status)) {
      return;
    }

    /**
     * If the status is 400, we want to show the error message from the server
     */
    if (status === 400) {
      return error.message;
    }

    /**
     * With any other status code, we want to show a generic error message
     */
    return this.MESSAGE_DEFAULT_LOCAL;
  }

  /**
   * An error that should be displayed globally, for example in a toast or as an overlay
   */
  getGlobalErrorMessage(error: ErrorTypeSimplified): string | void {
    const status = error.response?.status;

    if (status === 401) {
      return this.MESSAGE_401;
    }

    if (status === 500) {
      return this.MESSAGE_500;
    }
  }
}

export const ErrorMessage = new ErrorMessageSingleton();
