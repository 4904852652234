import { Dialog } from '@headlessui/react';
import { ReactNode } from 'react';

import { RegularXmark } from '@/icons/components';

import { Input, InputProps } from '../Form';
import { IconButton } from '../IconButton';
import { Modal, ModalProps } from '../Modal/Modal';
import { Switch, SwitchProps } from '../Switch';
import { ModalLinkContext, useModalLinkContext } from './utils/ModalLinkContext';

export type ModalLinkProps = Omit<ModalProps, 'maxWidth'>;

export const ModalLink = (props: ModalLinkProps) => {
  return (
    <ModalLinkContext.Provider value={props}>
      <Modal size="xlarge" {...props} />
    </ModalLinkContext.Provider>
  );
};

ModalLink.Body = function ModalLinkBody({ children }: { children: ReactNode }) {
  return (
    <div className="-m-6">
      <div className="flex min-h-[450px]">{children}</div>
    </div>
  );
};

ModalLink.Left = function ModalLinkLeft({
  title,
  children,
  searchProps,
  switchProps,
}: {
  title: string;
  children: ReactNode;
  searchProps: InputProps;
  switchProps?: SwitchProps;
}) {
  return (
    <div className="grow-1 w-full p-6 pr-4">
      <div className="mb-4 flex items-center justify-between">
        <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-theme-blue">
          {title}
        </Dialog.Title>

        {switchProps && <Switch {...switchProps} />}
      </div>

      <Input {...searchProps} />

      <hr className="my-4 h-px w-full bg-gray-400" />

      <div className="overflow-y-scroll">{children}</div>
    </div>
  );
};

ModalLink.Right = function ModalLinkRight({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  const { onClose } = useModalLinkContext();

  return (
    <div className="w-80 shrink-0 grow-0 bg-gray-100 p-6 pl-4">
      <div className="mb-4 flex justify-between border-b border-gray-200 pb-2">
        <h3 className="text-lg font-semibold leading-6 text-theme-blue">{title}</h3>

        <IconButton icon={RegularXmark} ariaLabel="Sluit venster" onClick={onClose} type="button" />
      </div>

      {children}
    </div>
  );
};
