import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postFixHoofdstuk, postFixLinkHb } from '@/api/generated/digiVAPI';
import { HBSchema, HoofdstukSchema } from '@/api/generated/digiVAPI.schemas';
import { QueryError } from '@/utils/createQuery';
import { pick } from '@/utils/pick';

import { useHoofdstuk, useHoofdstukId } from '../hoofdstuk';
import { useHB, useLinksInHoofdstuk } from '../links';

/**
 * This mutation can be used to start editing a published hoofdstuk.
 *
 * When we want to edit a published hoofdstuk, we first need to create a new hoofdstuk in the lineage.
 */
export const useCreateHoofdstukInLineage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { hb: HBSchema; hoofdstuk: HoofdstukSchema },
    QueryError,
    { hoofdstuk: HoofdstukSchema; hb: HBSchema }
  >({
    mutationFn: async ({ hoofdstuk, hb }) => {
      const newHoofdstuk = await postFixHoofdstuk({
        ...pick(hoofdstuk, ['Grondslag', 'Lineage', 'Nummer', 'Stop_Lineage', 'Titel']),
        Ontwerp: 1,
        Vorig: hoofdstuk.ID,
        Index: hoofdstuk.Index + 1,
      });

      const newHb = await postFixLinkHb({
        ...pick(hb, ['Besluit', 'Rang']),
        Hoofdstuk: newHoofdstuk.ID!,
        Type: 'Eigen',
      });

      return {
        hb: newHb,
        hoofdstuk: newHoofdstuk,
      };
    },
    onSuccess: async ({ hoofdstuk }) => {
      queryClient.setQueryData<HoofdstukSchema>(useHoofdstukId.getKey(hoofdstuk.ID!), hoofdstuk);

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: useHoofdstuk.key }),
        queryClient.invalidateQueries({ queryKey: useHB.key }),
        queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key }),
      ]);
    },
  });
};
