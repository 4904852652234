import { queryClient } from '@/services/queryClient';

import {
  CreateObjectsResponse,
  USE_CREATE_OBJECTS_MUTATION_KEY,
} from '../mutations/useCreateObjects/useCreateObjects';

/**
 * Returns the last created object
 */
export const getLastCreatedObject = (
  { ignoreTransparentSubparagraaf }: { ignoreTransparentSubparagraaf: boolean } = {
    ignoreTransparentSubparagraaf: true,
  }
) => {
  const mutationCache = queryClient.getMutationCache();

  /**
   * Find the last successful useCreateObjects mutation
   */
  const lastUseCreateObjectMutation = [...mutationCache.getAll()].reverse().find((mutation) => {
    return (
      mutation.state.status === 'success' &&
      mutation.options.mutationKey === USE_CREATE_OBJECTS_MUTATION_KEY
    );
  });

  /**
   * Objects are posted in the following order: hoofdstuk, paragraaf, voorschrift.
   * We need to return the last created object.
   */
  const responses = lastUseCreateObjectMutation?.state.data as CreateObjectsResponse;

  // We do not want to include Transparant subparagrafen, because they are not visible
  if (ignoreTransparentSubparagraaf && responses?.subparagraafResponses) {
    responses.subparagraafResponses = responses.subparagraafResponses.filter(
      ({ subparagraaf }) => !subparagraaf.Transparent
    );
  }

  if (responses?.voorschriftResponses.length)
    return responses.voorschriftResponses[responses.voorschriftResponses.length - 1]?.voorschrift;
  if (responses?.subparagraafResponses.length)
    return responses.subparagraafResponses[responses.subparagraafResponses.length - 1]
      ?.subparagraaf;
  if (responses?.paragraafResponses.length)
    return responses.paragraafResponses[responses.paragraafResponses.length - 1]?.paragraaf;
  if (responses?.hoofdstukResponses.length)
    return responses.hoofdstukResponses[responses.hoofdstukResponses.length - 1]?.hoofdstuk;

  return;
};
