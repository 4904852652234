import { Slot, Slottable } from '@radix-ui/react-slot';
import { ComponentPropsWithRef, ReactNode, forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { SolidCheck, SolidSpinner } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

type ButtonVariant =
  | 'blue'
  | 'green'
  | 'green-ghost'
  | 'white'
  | 'white-ghost'
  | 'red'
  | 'transparent';

export type ButtonState = 'loading' | 'success';

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  label?: string;
  children?: ReactNode;
  disabled?: boolean;
  state?: ButtonState;
  variant?: ButtonVariant;
  icon?: IconComponent;
  iconClasses?: string;
  active?: boolean;
  asChild?: boolean;
  'data-testid'?: string;
  testIdIcon?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      disabled,
      state,
      className,
      icon,
      iconClasses,
      children,
      active,
      asChild,
      label,
      testIdIcon,
      ...attributes
    },
    ref
  ) => {
    const Component = (asChild ? Slot : 'button') as 'button';
    let IconComponent = icon;

    const buttonClasses = getButtonClasses({ variant, className, disabled, active, state });

    if (state === 'loading') {
      IconComponent = SolidSpinner;
    } else if (state === 'success') {
      IconComponent = SolidCheck;
    }

    return (
      <Component
        {...attributes}
        className={buttonClasses}
        disabled={!!(disabled || state)}
        data-loading={state === 'loading'}
        // For testing purposes
        data-state={state}
        ref={ref}
      >
        {IconComponent && (
          <IconComponent
            size={16}
            className={twJoin('mr-3', state === 'loading' && 'animate-spin', iconClasses)}
            data-testid={testIdIcon}
          />
        )}

        <Slottable>{children || label}</Slottable>
      </Component>
    );
  }
);

const getButtonClasses = ({
  variant = 'green',
  className,
  disabled,
  active,
  state,
}: {
  variant?: ButtonVariant;
  className?: string;
  disabled?: boolean;
  active?: boolean;
  state?: ButtonState;
}) => {
  let stateClasses = '';
  let activeClasses = '';
  let nonActiveClasses = '';

  switch (variant) {
    case 'green':
      nonActiveClasses = 'bg-theme-green';
      stateClasses =
        '[&.active]:bg-theme-green-dark text-white hover:bg-theme-green-dark focus-visible:ring focus-visible:ring-theme-green/30 focus-visible:border-theme-green/60';
      activeClasses = 'bg-theme-green-dark';
      break;
    case 'green-ghost':
      nonActiveClasses = 'bg-transparent text-theme-green';
      stateClasses =
        '[&.active]:bg-theme-green text-white border border-theme-green hover:bg-theme-green hover:text-white focus-visible:ring focus-visible:ring-theme-green/30 focus-visible:border-theme-green/60';
      activeClasses = 'bg-theme-green text-white';
      break;
    case 'blue':
      nonActiveClasses = 'bg-theme-blue';
      stateClasses =
        '[&.active]:bg-theme-blue-dark text-white hover:bg-theme-blue-dark focus-visible:ring focus-visible:ring-theme-blue/30 focus-visible:border-theme-blue/60';
      activeClasses = 'bg-theme-blue-dark';
      break;
    case 'white':
      nonActiveClasses = 'text-gray-700 bg-white border border-gray-300 shadow-sm';
      stateClasses =
        '[&.active]:text-gray-500 hover:text-gray-500 focus-visible:border-blue-300 focus-visible:ring';
      activeClasses = 'text-gray-500';
      break;
    case 'white-ghost':
      nonActiveClasses = 'bg-transparent text-white';
      stateClasses =
        '[&.active]:bg-white text-theme-blue-dark border border-white hover:bg-white hover:text-theme-blue-dark focus-visible:ring focus-visible:ring-white/30';
      activeClasses = 'bg-white text-theme-blue-dark';
      break;
    case 'transparent':
      stateClasses =
        '[&.active]:bg-gray-100 text-gray-700 hover:bg-gray-100 focus-visible:ring focus-visible:border-theme-blue/60';
      activeClasses = 'bg-gray-100';
      break;
    case 'red':
      nonActiveClasses = 'bg-red-600';
      stateClasses =
        '[&.active]:bg-red-500 text-white hover:bg-red-500 focus-visible:ring focus-visible:ring-red-600/60';
      activeClasses = 'bg-red-500';
      break;
  }

  if (disabled) {
    stateClasses = ' cursor-not-allowed';

    if (!state) stateClasses += ' bg-gray-300';
  }

  return twMerge(
    twJoin(
      'relative inline-flex items-center px-3 py-2',
      'text-base font-medium leading-6 sm:text-sm sm:leading-5',
      'rounded-md transition duration-150 ease-in-out focus-visible:outline-none',
      !state && 'disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-black',
      state === 'loading' && 'cursor-wait',
      state === 'success' && 'cursor-default',
      stateClasses,
      active ? activeClasses : nonActiveClasses
    ),
    className
  );
};
