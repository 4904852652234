import { toast } from 'react-toastify';

import { RegularCircleInfo, SolidCircleInfo, SolidTriangleExclamation } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

type FeedbackMessageType = 'success' | 'info' | 'error';

function FeedbackMessageSwitch(type: FeedbackMessageType, description?: string, title = '') {
  const green = '#618C03';
  const blue = '#0785F2';
  const red = '#BE2302';

  let iconColor = '';
  let Icon: undefined | IconComponent;
  let defaultTitle: string = title;

  switch (type) {
    case 'success':
      iconColor = green;
      Icon = RegularCircleInfo;
      defaultTitle = defaultTitle || 'Gelukt!';
      break;
    case 'info':
      iconColor = blue;
      Icon = SolidCircleInfo;
      defaultTitle = defaultTitle || 'Opmerking';
      break;
    case 'error':
    default:
      iconColor = red;
      Icon = SolidTriangleExclamation;
      defaultTitle = defaultTitle || 'Er ging iets mis, probeer het later nog eens';
      break;
  }

  return (
    <>
      <p className="font-bold">
        <span className="fa-layers fa-fw float-left -ml-1 mt-1 block">
          <Icon style={{ color: iconColor }} />
        </span>
        <span className="ml-6 block text-base">{defaultTitle}</span>
      </p>
      <div className="ml-6 text-sm text-gray-500">{description ? description : ''}</div>
    </>
  );
}

export function FeedbackMessage(type: FeedbackMessageType, description?: string, title?: string) {
  toast(FeedbackMessageSwitch(type, description, title), {
    autoClose: false,
    hideProgressBar: true,
  });
}
