const validateFunction =
  <ValueType>(fn: (value: ValueType) => boolean | string, defaultMessage: string) =>
  (message: string = defaultMessage) =>
  (value: ValueType | null | undefined) =>
    value && !fn(value) ? message : true;

/**
 * Object with validate functions for react-hook-form
 *
 * @example
 * <Input
 *  ref={register({
 *   validate: validate.numbersOnly('Error message when validation fails')
 *  })}
 * />
 **/
export const validate = {
  /** 4 numbers and 2 letters */
  zipcode: validateFunction<string>(
    (value) => /^[1-9][0-9]{3} ?[a-z]{2}$/i.test(value.trim()),
    'Incorrecte postcode. Voorbeeld: 1000AA'
  ),
  /** Numbers and letters */
  houseNumber: validateFunction<string>(
    (value) => /^[a-zA-Z0-9]*$/.test(value),
    'Huisnummer mag alleen nummers en letters bevatten'
  ),
  /** Only numbers and max 8 numbers in total */
  KVK: validateFunction<string>(
    (value) => value.length <= 8 && /^[0-9]*$/.test(value),
    'KVK mag alleen nummers bevatten en maximaal 8 karakters lang zijn'
  ),
  /** Numbers only */
  locationNumber: validateFunction<string>(
    (value) => /^[0-9]*$/.test(value),
    'Locatienummer mag enkel nummers bevatten.'
  ),
  paragraafNummer: validateFunction<string>(
    (value) => /^\d+\.\d+[a-zA-Z]?$/.test(value),
    'Incorrect paragraaf nummer. Voorbeeld: 1.1, 4.3, 5.4a etc.'
  ),
  voorschriftNummer: validateFunction<string>(
    (value) => /^\d+\.\d+\.\d+[a-zA-Z]?$/.test(value),
    'Incorrect voorschrift nummer. Voorbeeld: 1.1.1, 4.3.2, 5.4.1a etc.'
  ),
};
