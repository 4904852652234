import { Navigate, createFileRoute, useNavigate } from '@tanstack/react-router';

import { useCreateObjects } from '@/api/queries/objects';
import { AddItemButton } from '@/components';
import { RegularFilePlus } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { useUserStore } from '@/stores/user';

import { useHoofdstukkenInEditor } from './-utils/useHoofdstukkenInEditor';

/**
 * Route
 */
export const Route = createFileRoute(
  '/$bedrijfLineageId/editor/$besluitId/voorschriften/hoofdstuk'
)({
  component: VoorschriftenComponent,
});

/**
 * Route component
 */
function VoorschriftenComponent() {
  const { besluitId, bedrijfLineageId } = Route.useParams();
  const navigate = useNavigate();
  const openModal = useOpenModal();

  const createObjects = useCreateObjects();

  const hoofdstukken = useHoofdstukkenInEditor(besluitId);

  if (
    hoofdstukken.isLoading ||
    hoofdstukken.hoofdstukkenQuery.isFetching ||
    hoofdstukken.hbQuery.isFetching
  ) {
    return null;
  }

  if (hoofdstukken.data?.[0]?.ID) {
    return (
      <Navigate
        to="/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId"
        replace
        params={{ bedrijfLineageId, besluitId, hoofdstukId: hoofdstukken.data[0].ID }}
      />
    );
  }

  return (
    <div className="flex items-center justify-center py-8">
      <div className="text-digi-v-gray">
        <p>
          Het besluit is helemaal leeg, voeg eenvoudig een hoofdstuk toe of voeg standaardteksten
          in.
        </p>
        <div className="flex gap-2 py-4 text-theme-blue">
          <AddItemButton
            icon={RegularFilePlus}
            testId="empty-besluit-add-hoofdstuk"
            className="bg-white"
            isLoading={createObjects.isPending}
            label={
              <>
                Leeg
                <br /> hoofdstuk
              </>
            }
            onClick={() => {
              const userId = useUserStore.getState().user?.ID ?? '';

              createObjects.mutate(
                {
                  type: 'hoofdstukken',
                  besluitId,
                  userId,
                  hoofdstukken: [{ titel: '' }],
                },
                {
                  onSuccess: ({ hoofdstukResponses }) => {
                    const newHoofdstuk = hoofdstukResponses[0]?.hoofdstuk;

                    if (newHoofdstuk) {
                      navigate({
                        to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
                        params: {
                          besluitId,
                          bedrijfLineageId,
                          hoofdstukId: newHoofdstuk.ID!,
                        },
                      });
                    }
                  },
                }
              );
            }}
          />

          <AddItemButton
            icon={RegularFilePlus}
            testId="empty-besluit-add-standaardtekst-hoofdstuk"
            className="bg-white"
            label={
              <>
                Standaardtekst <br /> hoofdstuk
              </>
            }
            onClick={() => {
              openModal('editorStdMappen', {
                besluitId,
                onSubmit: (newHoofdstuk) => {
                  navigate({
                    to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
                    params: {
                      besluitId,
                      bedrijfLineageId,
                      hoofdstukId: newHoofdstuk.ID!,
                    },
                  });
                },
              });
            }}
          />

          {createObjects.isError && (
            <p className="text-digi-v-color-danger">
              {createObjects.error.message
                ? createObjects.error.message
                : 'Er is iets mis gegaan bij het aanmaken van een nieuw hoofdstuk'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
