import { FilterSchema, PHBSchema, SPPBSchema, VSPBSchema } from '@/api/generated/digiVAPI.schemas';
import { queryClient } from '@/services/queryClient';

import { usePHB, useSPPB, useVSPB } from '../links';

export type Links = LinkParagraaf[];

export type LinkParagraaf = {
  type: 'paragraaf';
  PHB: PHBSchema;
  subparagrafen: LinkSubparagraaf[];
};

export type LinkSubparagraaf = {
  type: 'subparagraaf';
  SPPB: SPPBSchema;
  paragraaf: LinkParagraaf;
  voorschriften: LinkVoorschrift[];
};

export type LinkVoorschrift = {
  type: 'voorschrift';
  VSPB: VSPBSchema;
  paragraaf: LinkParagraaf;
  subparagraaf: LinkSubparagraaf;
};

export async function getLinksInHoofdstuk({
  hoofdstukId,
  besluitId,
  VSPBFilter = [],
  SPPBFilter = [],
  PHBFilter = [],
}: {
  hoofdstukId: string;
  besluitId: string;
  VSPBFilter?: FilterSchema[];
  SPPBFilter?: FilterSchema[];
  PHBFilter?: FilterSchema[];
}) {
  /**
   * Get all PHB items in hoofdstuk
   */
  const [PHBResponse, SPPBResponse, VSPBResponse] = await Promise.all([
    queryClient.fetchQuery(
      usePHB.getOptions({
        page: 1,
        size: 1000,
        sort: [{ field: 'Rang', direction: 'asc', model: 'PHB' }],
        filter: [
          {
            model: 'PHB',
            field: 'Besluit',
            op: 'eq',
            value: besluitId,
          },
          {
            model: 'PHB',
            field: 'Hoofdstuk',
            op: 'eq',
            value: hoofdstukId,
          },
          ...PHBFilter,
        ],
      })
    ),
    queryClient.fetchQuery(
      useSPPB.getOptions({
        page: 1,
        size: 1000,
        sort: [{ field: 'Rang', direction: 'asc', model: 'SPPB' }],
        filter: [
          {
            model: 'SPPB',
            field: 'Besluit',
            op: 'eq',
            value: besluitId,
          },
          ...SPPBFilter,
        ],
      })
    ),
    queryClient.fetchQuery(
      useVSPB.getOptions({
        page: 1,
        size: 1000,
        sort: [{ field: 'Rang', direction: 'asc', model: 'VSPB' }],
        filter: [
          {
            field: 'Besluit',
            op: 'eq',
            value: besluitId,
          },
          ...VSPBFilter,
        ],
      })
    ),
  ]);

  /**
   * Store objects in variable
   */
  const PHBItems = PHBResponse.objects ?? [];
  const SPPBItems = SPPBResponse.objects ?? [];
  const VSPBItems = VSPBResponse.objects ?? [];

  /**
   * Transform all links into a flat structure
   */
  const links: Links = [];

  PHBItems.forEach((PHB) => {
    const paragraafLink: LinkParagraaf = {
      type: 'paragraaf',
      PHB,
      subparagrafen: [],
    };

    const filteredSPPB = SPPBItems.filter((SPPB) => SPPB.Paragraaf === PHB.Paragraaf);

    filteredSPPB.forEach((SPPB) => {
      const subparagraafLink: LinkSubparagraaf = {
        type: 'subparagraaf',
        paragraaf: paragraafLink,
        SPPB,
        voorschriften: [],
      };

      const filteredVSPB = VSPBItems.filter((VSPB) => VSPB.Subparagraaf === SPPB.Subparagraaf);

      filteredVSPB.forEach((VSPB) => {
        subparagraafLink.voorschriften.push({
          type: 'voorschrift',
          paragraaf: paragraafLink,
          subparagraaf: subparagraafLink,
          VSPB,
        });
      });

      paragraafLink.subparagrafen.push(subparagraafLink);
    });

    links.push(paragraafLink);
  });

  return links;
}
