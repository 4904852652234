import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';

export type StandaardtekstenDeactiverenSectieProps = {
  sectieNaam?: string;
};

export const STANDAARDTEKSTEN_DEACTIVEREN_SECTIE_ID = 'standaardtekstenDeactiverenSectie';

export const StandaardtekstenDeactiverenSectie = modal(
  STANDAARDTEKSTEN_DEACTIVEREN_SECTIE_ID,
  ({ data, props }) => {
    const modalState = useModalState();

    const { sectieNaam } = data;

    const deactiveerSectie = () => {
      console.log('sectie gedeactiveerd');
    };

    return (
      <Modal {...props} state={modalState.state} title="Sectie deactiveren">
        <p className="text-gray-800">
          Je staat op het punt om de sectie {sectieNaam} te deactiveren. Zolang dit onderdeel
          gedeactiveerd is, zal het niet zichtbaar zijn voor vergunningverleners.
        </p>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="delete"
            successLabel="Gedeactiveerd"
            variant="green"
            onClick={deactiveerSectie}
          >
            Sectie deactiveren
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
