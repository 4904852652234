import { Link } from '@tanstack/react-router';

import { FilterSchema, ParagraafSchema } from '@/api';
import { useHoofdstuk, usePHB, useParagraaf } from '@/api/queries/objects';
import { createInString } from '@/utils/createInString';

import { TextButton } from '../../TextButton';
import { ValidateBesluitProps } from '../ValidateBesluit.types';
import { ValidateAccordionSkeleton } from './ValidateAccordionSkeleton';
import { ValidateRow } from './ValidateRow';

/**
 * Check if there are paragrafen with empty titles
 */
export const useValidateParagraafTitles = (besluitId: string) => {
  return useParagraaf(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'Besluit',
          field: 'ID',
          op: 'eq',
          value: besluitId,
        },
        {
          model: 'Paragraaf',
          field: 'Titel',
          op: 'eq',
          value: '',
        },
        {
          model: 'PHB',
          field: 'Type',
          op: 'eq',
          value: 'Eigen',
        },
      ],
    },
    {
      select: ({ num_objects, objects }) => ({ isValid: num_objects === 0, objects }),
      gcTime: 0,
      staleTime: 0,
    }
  );
};

export const ValidateParagraafTitle = ({ validate }: ValidateBesluitProps) => {
  const { isFetching, data } = validate.queries.validateParagraafTitles;
  const paragrafen = data?.objects ?? [];

  if (isFetching || !data) {
    return <ValidateRow isFetching title="Lege paragraaftitels" />;
  }

  if (!data.isValid) {
    return (
      <ValidateRow
        isValid={false}
        title={`${paragrafen.length} lege paragraaftitel${paragrafen.length > 1 ? 's' : ''}`}
        accordionBody={
          <ErrorBody
            paragrafen={paragrafen}
            besluitId={validate.besluitId}
            bedrijfLineageId={validate.bedrijfLineageId}
          />
        }
      />
    );
  }

  return <ValidateRow isValid title="Geen lege paragraaftitels" />;
};

const ErrorBody = ({
  paragrafen,
  besluitId,
  bedrijfLineageId,
}: {
  paragrafen: ParagraafSchema[];
  besluitId: string;
  bedrijfLineageId: string;
}) => {
  const filter: FilterSchema[] = [
    {
      model: 'Besluit',
      field: 'ID',
      op: 'eq',
      value: besluitId,
    },
    {
      model: 'Paragraaf',
      field: 'ID',
      op: 'in',
      value: createInString(paragrafen.map(({ ID }) => ID ?? '')),
    },
  ];

  const hoofdstukken = useHoofdstuk({
    page: 1,
    size: 100,
    filter,
    sort: [
      {
        model: 'Hoofdstuk',
        field: 'Nummer',
        direction: 'asc',
      },
    ],
  });
  const PHB = usePHB({
    page: 1,
    size: 100,
    filter,
  });

  if (hoofdstukken.isLoading || PHB.isLoading) return <ValidateAccordionSkeleton />;

  return (
    <ul>
      {hoofdstukken.data?.objects?.map((hoofdstuk) => {
        const hoofdstukId = hoofdstuk.ID ?? '';
        const paragrafen =
          PHB.data?.objects?.filter(({ Hoofdstuk }) => Hoofdstuk === hoofdstukId) ?? [];

        return (
          <li className="list-inside list-disc" key={hoofdstuk.ID}>
            <TextButton size="small" asChild>
              <Link
                to="/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId"
                params={{ bedrijfLineageId, besluitId, hoofdstukId }}
              >
                Hoofdstuk {hoofdstuk.Nummer} bevat {paragrafen.length}{' '}
                {paragrafen.length > 1 ? 'paragrafen' : 'paragraaf'} zonder titel
              </Link>
            </TextButton>
          </li>
        );
      })}
    </ul>
  );
};
