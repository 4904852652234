import { useQuery } from '@tanstack/react-query';

import { useHB, useHoofdstuk } from '@/api/queries/objects';
import { queryClient } from '@/services/queryClient';

export const useHoofdstukkenInEditor = (besluitId: string) => {
  const hoofdstukken = useQuery(getHoofdstukQueryOptions(besluitId));
  const HB = useQuery(getHBQueryOptions(besluitId));

  const filteredHoofdstukken =
    hoofdstukken.data?.objects?.filter((hoofdstuk) => {
      const hb = HB.data?.objects?.find((hb) => hb.Hoofdstuk === hoofdstuk.ID);

      if (hoofdstuk.Stop_Lineage && hb?.Type === 'Publicatie') return false;

      return true;
    }) ?? [];

  return {
    data: filteredHoofdstukken,
    isLoading: hoofdstukken.isLoading || HB.isLoading,
    hoofdstukkenQuery: hoofdstukken,
    hbQuery: HB,
  };
};

export const fetchHoofdstukkenInEditor = async (besluitId: string) => {
  const [hoofdstukken, HB] = await Promise.all([
    queryClient.fetchQuery(getHoofdstukQueryOptions(besluitId)),
    queryClient.fetchQuery(getHBQueryOptions(besluitId)),
  ]);

  const filteredHoofdstukken =
    hoofdstukken.objects?.filter((hoofdstuk) => {
      const hb = HB.objects?.find((hb) => hb.Hoofdstuk === hoofdstuk.ID);

      if (hoofdstuk.Stop_Lineage && hb?.Type === 'Publicatie') return false;

      return true;
    }) ?? [];

  return filteredHoofdstukken;
};

const getHoofdstukQueryOptions = (besluitId: string) => {
  return useHoofdstuk.getOptions({
    page: 1,
    size: 100,
    sort: [{ field: 'Nummer', model: 'Hoofdstuk', direction: 'asc' }],
    filter: [
      {
        model: 'Besluit',
        field: 'ID',
        op: 'eq',
        value: besluitId,
      },
      { model: 'Hoofdstuk', field: 'Volgend', op: 'is_null', value: '' },
    ],
  });
};

const getHBQueryOptions = (besluitId: string) => {
  return useHB.getOptions({
    page: 1,
    size: 100,
    filter: [
      { model: 'HB', field: 'Besluit', op: 'eq', value: besluitId },
      { model: 'Hoofdstuk', field: 'Volgend', op: 'is_null', value: '' },
    ],
  });
};
