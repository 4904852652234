import {
  getFixLinkHb,
  getFixLinkHbId,
  getFixLinkPhb,
  getFixLinkPhbId,
  getFixLinkSppb,
  getFixLinkSppbId,
  getFixLinkVspb,
  getFixLinkVspbId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

import { getLinksInHoofdstuk } from './utils/getLinksInHoofdstuk';

/**
 * All links in a hoofdstuk
 */
export const useLinksInHoofdstuk = createQuery({
  key: ['links-in-hoofdstuk'],
  queryFn: getLinksInHoofdstuk,
  defaultOptions: {
    // New responses are compared to older responses to prevent unnecessary updates
    // But because this data structure is deeply nested, the comparison doesn't work and
    // the data isn't refreshed when the query is invalidated
    structuralSharing: false,
  },
});

/**
 * Hoofdstuk Besluit Link
 */
export const useHB = createQuery({
  key: ['hb'],
  queryFn: getFixLinkHb,
});

export const useHBId = createQuery({
  key: ['hbId'],
  queryFn: getFixLinkHbId,
});

/**
 * Paragraaf Hoofdstuk Besluit Link
 */
export const usePHB = createQuery({
  key: ['phb'],
  queryFn: getFixLinkPhb,
});

export const usePHBId = createQuery({
  key: ['phbId'],
  queryFn: getFixLinkPhbId,
});

/**
 * Subparagraaf Paragraaf Besluit Link
 */
export const useSPPB = createQuery({
  key: ['sppb'],
  queryFn: getFixLinkSppb,
});

export const useSPPBId = createQuery({
  key: ['sppbId'],
  queryFn: getFixLinkSppbId,
});

/**
 * Voorschrift Subparagraaf Besluit Link
 */
export const useVSPB = createQuery({
  key: ['vspb'],
  queryFn: getFixLinkVspb,
});

export const useVSPBId = createQuery({
  key: ['vspbId'],
  queryFn: getFixLinkVspbId,
});
