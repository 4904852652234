import { LoaderContent } from '../../Loader';

export const ValidateAccordionSkeleton = () => (
  <ul className="list-inside list-disc">
    <li>
      <LoaderContent
        w="w-[120px]"
        h="h-[21px]"
        className="inline-block bg-gray-900/25 align-middle"
      />
    </li>
  </ul>
);
