import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512" ref={svgRef} {...props}><path d="M13.4 439l23-78.1c4.2-14.1 11.8-27 22.2-37.4L362.7 19.3c25-25 65.5-25 90.5 0l39.4 39.4c3.1 3.1 5.9 6.5 8.2 10 16.4 24.8 13.7 58.6-8.2 80.5L188.5 453.4c-1.3 1.3-2.6 2.6-4 3.8-9.6 8.5-21 14.8-33.4 18.4l-78.1 23L30.8 511c-8.4 2.5-17.5.2-23.7-6.1s-8.6-15.2-6.1-23.7L13.4 439zm62.2-41.2l-16.2 54.8 54.8-16.1 23.4-6.9c6.4-1.9 12.3-5.4 17-10.1L383 191l-62-62L92.5 357.4c-.6.6-1.2 1.2-1.7 1.8-3.9 4.4-6.7 9.6-8.4 15.2l-6.9 23.4zM248 464h304c13.3 0 24 10.7 24 24s-10.7 24-24 24H248c-13.3 0-24-10.7-24-24s10.7-24 24-24z" /></svg>;
});
SVGIcon.displayName = "RegularPenLine";
export default SVGIcon;