import { InputHTMLAttributes, forwardRef, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';

import { LoaderSpinningIcon, Transitions } from '@/components';
import { RegularCalendar } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

import { FormError } from '../FormError';
import { FormLabel } from '../FormLabel';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  id: string;
  name: string;
  label: string;
  subtitle?: string;
  hideLabel?: boolean;
  required?: boolean;
  labelClasses?: string;
  inputClasses?: string;
  /** Error coming from `react-hook-form` */
  error?: FieldError | string;
  disabled?: boolean;
  readOnly?: boolean;
  horizontal?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  icon?: IconComponent;
  isLoading?: boolean;
  type?:
    | 'text'
    | 'number'
    | 'password'
    | 'email'
    | 'tel'
    | 'url'
    | 'search'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week';
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      label,
      subtitle,
      hideLabel,
      className,
      labelClasses,
      inputClasses,
      required,
      error,
      disabled,
      horizontal,
      readOnly,
      type = 'text',
      icon,
      isLoading,
      ...props
    },
    ref
  ) => {
    const IconComponent = icon;
    const [firefoxVersion, setFirefoxVersion] = useState<number | undefined>();
    const containerRef = useRef<HTMLDivElement>(null);

    /*
      There's a bug in Firefox 109 where the calendar icon cant be hidden using ::-webkit-calendar-picker-indicator.
      https://bugzilla.mozilla.org/show_bug.cgi?id=1812397

      We temporarily show the browser icon, untill the Firefox bug is fixed.
    */
    useEffect(() => {
      if (type !== 'date') return;

      const userAgent = navigator.userAgent;
      const regex = /Firefox\/(?<majorRelease>\d+(\.\d+)?)/;
      const match = userAgent.match(regex);
      const FirefoxVersion = match ? Number(match?.groups?.majorRelease) : null;

      if (FirefoxVersion) {
        setFirefoxVersion(FirefoxVersion);
      }
    }, [type]);

    const showCalendarIcon = type === 'date' && (!firefoxVersion || firefoxVersion < 109);
    const errorId = `${id}-error`;

    return (
      <div
        className={twMerge(
          twJoin(horizontal && 'grid w-full grid-cols-3', !horizontal && 'flex flex-col'),
          className
        )}
        ref={containerRef}
      >
        <div className={twJoin(hideLabel && 'sr-only', horizontal && 'col-span-1 pr-2 pt-1')}>
          <FormLabel htmlFor={id} className={labelClasses} required={required}>
            {label}

            {subtitle && (
              <span className="mb-2 block py-1 text-sm font-normal text-gray-900">{subtitle}</span>
            )}
          </FormLabel>
        </div>

        <div className={twJoin('w-full', horizontal && 'col-span-2')}>
          <div className="relative">
            {IconComponent && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <IconComponent className="text-gray-400" />
              </div>
            )}

            <input
              id={id}
              name={name}
              ref={ref}
              type={type}
              disabled={disabled}
              readOnly={readOnly}
              aria-describedby={error ? errorId : undefined}
              aria-invalid={error ? true : undefined}
              className={twJoin(
                'form-input-custom w-full rounded-md shadow-sm transition',
                error &&
                  'border-6 border-solid border-digi-v-color-danger focus:border-digi-v-color-danger focus:ring focus:ring-digi-v-color-danger/30',
                !error &&
                  'border-gray-300 focus:border-blue-500/60 focus:ring focus:ring-blue-500/30',
                (disabled || readOnly) && 'cursor-not-allowed bg-gray-100 text-gray-500',
                icon && 'pl-9',
                showCalendarIcon && 'pr-6',
                inputClasses
              )}
              {...props}
            />

            {showCalendarIcon && (
              <button
                onClick={() => {
                  const input = containerRef.current?.querySelector('input');
                  input?.showPicker?.();
                }}
                type="button"
                className="absolute inset-y-2 right-0 flex items-center border-l pl-2 pr-3"
              >
                <RegularCalendar className="w-4" />
              </button>
            )}

            <Transitions.FadeIn show={!!isLoading} unmount appear>
              <div className="absolute inset-y-2 right-0 flex items-center pl-2 pr-3">
                <LoaderSpinningIcon className="text-gray-600" />
              </div>
            </Transitions.FadeIn>
          </div>

          {error && (
            <FormError id={errorId}>{typeof error === 'string' ? error : error.message}</FormError>
          )}
        </div>
      </div>
    );
  }
);
Input.displayName = 'Input';
