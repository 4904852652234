import { twMerge } from 'tailwind-merge';

export interface FormLabelProps
  extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  required?: boolean;
}

export const FormLabel = ({ children, className, required, ...props }: FormLabelProps) => {
  return (
    <label {...props} className={twMerge('text-sm font-medium leading-5 text-gray-700', className)}>
      {children}
      {required && <sup className="text-digi-v-color-danger">*</sup>}
    </label>
  );
};
