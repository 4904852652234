import { useQuery } from '@tanstack/react-query';
import { Link, Outlet, createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { FilterSchema } from '@/api';
import { usePatchStdMapId, useStdMap } from '@/api/queries/std';
import { DocumentTitle, Explorer } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { eStrings } from '@/constants/strings';
import { useOpenModal } from '@/modals/utils';
import { useUserStore } from '@/stores/user';
import { useIsExactMatch } from '@/utils/hooks/useIsExactMatch';

import { SidebarLayout } from './-components/SidebarLayout';

const searchSchema = z.object({
  p: z.number().optional().default(1),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const mapQueryOptions = (search: SearchParams, isOD: boolean) => {
  const filters: FilterSchema[] = [
    {
      field: 'Map',
      op: 'is_null',
      value: '',
    },
  ];

  if (isOD) {
    filters.push({
      field: 'Landelijk',
      op: 'eq',
      value: '0',
    });
  }

  return useStdMap.getOptions({
    page: search.p,
    size: 20,
    sort: [{ field: 'Naam', direction: 'asc' }],
    filter: filters,
  });
};

/**
 * Route
 */
export const Route = createFileRoute('/beheer/standaardteksten')({
  validateSearch: searchSchema.parse,
  beforeLoad: () => ({
    getTitle: () => 'Standaardteksten',
  }),
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { queryClient }, deps: { search } }) => {
    const isOD = useUserStore.getState().area === 'omgevingsdienst';

    try {
      await queryClient.ensureQueryData(mapQueryOptions(search, isOD));
    } catch {}
  },
  component: StandaardtekstenComponent,
});

/**
 * Route component
 */
function StandaardtekstenComponent() {
  const search = Route.useSearch();

  const isOD = useUserStore(({ area }) => area === 'omgevingsdienst');

  const mappen = useQuery(mapQueryOptions(search, isOD));
  const patchMapId = usePatchStdMapId();
  const openModal = useOpenModal();

  /**
   * NOTE: Temporary solution for breadcrumbs
   *
   * When the route is not exactly this route, render the outlet
   */
  const isExactMatch = useIsExactMatch(Route.fullPath);

  if (!isExactMatch) {
    return (
      <>
        <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Beheer Standaardteksten']} />
        <Outlet />
      </>
    );
  }

  if (!mappen.data) return null;

  return (
    <SidebarLayout testId={Route.fullPath}>
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Beheer Standaardteksten']} />
      <Explorer title="Mappen">
        <Explorer.ItemHeading
          title="Hoofdstuk"
          buttons={[
            {
              variant: 'white',
              key: 'landelijk-standaardteksten-new-map',
              label: 'Nieuwe map',
              onClick: () => openModal('standaardtekstenToevoegenMap', {}),
            },
          ]}
        />

        <ul>
          {mappen.data.objects?.map((map) => (
            <li key={map.ID}>
              <Explorer.Button
                asChild
                icon="folder"
                inactive={map.Status === 'Archief'}
                options={[
                  {
                    variant: 'button',
                    title: 'Bewerken',
                    onClick: () => {
                      openModal('standaardtekstenEditMap', {
                        map,
                      });
                    },
                  },
                  {
                    variant: 'button',
                    title: map.Status === 'Actief' ? 'Onzichtbaar maken' : 'Zichtbaar maken',
                    onClick: () => {
                      patchMapId.mutate({
                        id: map.ID!,
                        data: {
                          Status: map.Status === 'Actief' ? 'Archief' : 'Actief',
                        },
                      });
                    },
                  },
                ]}
              >
                <Link
                  to="/beheer/standaardteksten/$mapId"
                  params={{ mapId: map.ID! }}
                  search={{ p: 1 }}
                >
                  {map.Naam}
                  {map.Status === 'Archief' ? ' (onzichtbaar voor gebruikers)' : ''}
                </Link>
              </Explorer.Button>
            </li>
          ))}
        </ul>
      </Explorer>

      {(mappen.data.num_pages ?? 0) > 1 ? (
        <div className="mt-6 flex justify-center">
          <PaginationLinks
            renderLink={({ children, page }) => (
              <Link to="/beheer/standaardteksten" search={{ p: page }}>
                {children}
              </Link>
            )}
            currentPage={search.p}
            totalPages={mappen.data.num_pages ?? 0}
          />
        </div>
      ) : null}
    </SidebarLayout>
  );
}
