import { BedrijfSchema, HoofdstukSchema } from '@/api';
import { LinkSubparagraaf, useSubparagraafId } from '@/api/queries/objects';
import { EditorSubparagraaf, Transitions } from '@/components';
import { MountWhenInView } from '@/components/shared/MountWhenInView';

import { Mode } from './Objects';
import { ObjectsVoorschrift } from './ObjectsVoorschrift';
import { getObjectStatus } from './utils/getObjectState';

interface ObjectsSubparagraafProps {
  hoofdstuk: HoofdstukSchema;
  paragraafNummer: number;
  bedrijf: BedrijfSchema;
  link: LinkSubparagraaf;
  mode: Mode;
}

export const ObjectsSubparagraaf = (props: ObjectsSubparagraafProps) => {
  return (
    <MountWhenInView placeholder={<Placeholder />}>
      <ObjectsSubparagraafInner {...props} />
    </MountWhenInView>
  );
};

/**
 * Subparagraaf Row
 */
const ObjectsSubparagraafInner = ({
  link,
  hoofdstuk,
  paragraafNummer,
  bedrijf,
  mode,
}: ObjectsSubparagraafProps) => {
  const subparagraaf = useSubparagraafId(link.SPPB.Subparagraaf, { staleTime: Infinity });

  const isLoaded = !!(!subparagraaf.isLoading && subparagraaf.data);

  if (!isLoaded) return <Placeholder />;

  return (
    <Transitions.FadeIn show={isLoaded} appear unmount>
      {!subparagraaf.data.Transparent && (
        <EditorSubparagraaf
          status={
            mode === 'besluit'
              ? getObjectStatus(subparagraaf.data, link.SPPB.Type === 'Eigen')
              : undefined
          }
          content={subparagraaf.data.Titel}
          testId={`subparagraaf-${subparagraaf.data.ID}`}
          className="mb-2"
        />
      )}

      {link.voorschriften.map((linkVoorschrift) => {
        return (
          <ObjectsVoorschrift
            key={`besluit-voorschrift-${linkVoorschrift.VSPB.Voorschrift}`}
            hoofdstuk={hoofdstuk}
            paragraafNummer={paragraafNummer}
            bedrijf={bedrijf}
            link={linkVoorschrift}
            mode={mode}
          />
        );
      })}
    </Transitions.FadeIn>
  );
};

const Placeholder = () => <div className="h-[42px] w-full" />;
