import { ReactNode } from 'react';

import { EditorRow, EditorRowProps } from '../EditorRow';

export interface EditorParagraafProps
  extends Pick<
    EditorRowProps,
    | 'buttonProps'
    | 'testId'
    | 'number'
    | 'status'
    | 'dragHandleRef'
    | 'dragPreviewRef'
    | 'className'
  > {
  content?: string;
  children?: ReactNode;
}

export const EditorParagraaf = ({ content, children, ...props }: EditorParagraafProps) => {
  return (
    <EditorRow
      {...props}
      fontClasses="text-xl font-semibold text-theme-blue"
      buttonTestId="editor-paragraaf"
      content={
        <h3
          className="text-left text-xl font-semibold text-theme-blue"
          dangerouslySetInnerHTML={{ __html: content ?? '' }}
        />
      }
    >
      {children}
    </EditorRow>
  );
};
