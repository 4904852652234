import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { usePatchParagraafId } from '@/api/queries/objects';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from '../EditorNavigation';
import { useParagraafContext } from './ParagraafContext';

export const ParagraafNumber = () => {
  const {
    objectState,
    link,
    nummer,
    hoofdstukNummer,
    paragraaf,
    addError,
    removeError,
    errors,
    editorMode,
  } = useParagraafContext();
  const id = link.PHB.Paragraaf;

  const patchParagraafId = usePatchParagraafId({ mutationKey: AUTOSAVE_MUTATION_KEY });

  const [nummerValue, setNummerValue] = useState<number | null>(paragraaf.Nummer);
  const [isEditingNummer, setIsEditingNummer] = useState(false);

  /**
   * Patch for the paragraaf nummer
   */
  const patchNummer = async () => {
    /**
     * Validate if nummer is empty
     */
    if (nummerValue === null) {
      addError({ type: 'validateNummer', message: 'Het nummer mag niet leeg zijn.' });
      return;
    }

    /**
     * Patch nummer
     */
    setIsEditingNummer(false);
    removeError('validateNummer');

    patchParagraafId.mutate(
      {
        id,
        data: {
          Nummer: nummerValue,
        },
      },
      {
        onSuccess: () => removeError('patchNummer'),
        onError: (error) =>
          addError({
            type: 'patchNummer',
            message: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
          }),
      }
    );
  };

  const parseValue = (value: string) => {
    // Filter out all non-numeric characters
    const onlyNumbers = value.replace(/\D/g, '');
    const nummer = parseInt(onlyNumbers);

    // Check if the value is a number, if not, set the value to null
    const parsedValue = Number.isInteger(nummer) ? nummer : null;

    return parsedValue;
  };

  const hasNummerError = errors.some(({ type }) =>
    ['patchNummer', 'validateNummer'].includes(type)
  );

  const canEditNummer = objectState.isEditable && editorMode === 'extended';

  if (isEditingNummer && canEditNummer) {
    return (
      <>
        <label htmlFor={`hoofdstuk-nummer-${id}`} className="sr-only">
          Paragraaf nummer
        </label>

        <div className="-mr-1 mt-1 flex ring-1 ring-inset ring-gray-400">
          <div className="px-1">{hoofdstukNummer ?? '-'}.</div>

          <input
            autoComplete="off"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            id={`paragraaf-nummer-${id}`}
            data-testid="number-input"
            className={twJoin(
              'numbering-input border-0 bg-transparent pr-1 text-right text-gray-500 outline-0',
              'ring-1 ring-inset focus:bg-white',
              hasNummerError && 'ring-digi-v-color-danger',
              !hasNummerError && 'ring-gray-400',
              objectState.slotState && 'bg-gray-200/25'
            )}
            placeholder="-"
            type="text"
            maxLength={2}
            value={`${Number.isInteger(nummerValue) ? nummerValue : ''}`}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              const newNummer = parseValue(e.target.value);

              setNummerValue(newNummer);

              // Clear the errors when the value is changed
              if (newNummer !== null) removeError('validateNummer');
            }}
            onBlur={patchNummer}
          />
        </div>
      </>
    );
  }

  return (
    <button
      className={twJoin(
        'numbering-input -mr-1 mt-1 cursor-text border-0 bg-transparent pr-1 text-right text-gray-500 outline-0',
        canEditNummer && 'hover:ring-1 hover:ring-inset hover:ring-gray-400',
        canEditNummer && 'focus:ring-1 focus:ring-inset focus:ring-gray-400',
        hasNummerError &&
          'ring-1 ring-inset ring-digi-v-color-danger hover:ring-digi-v-color-danger',
        objectState.slotState && 'bg-gray-200/25',
        objectState.state === 'deleted' && 'line-through'
      )}
      aria-label="Nummer van onderdeel wijzigen"
      data-testid="toggle-number-input"
      onClick={() => setIsEditingNummer(true)}
      onFocus={() => setIsEditingNummer(true)}
      disabled={!canEditNummer}
    >
      {nummer}
    </button>
  );
};
