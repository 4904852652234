import { useDebouncedCallback } from '@react-hookz/web';
import { Editor } from '@tiptap/react';
import { Fragment, useEffect, useRef } from 'react';

import {
  useOverwegingByHoofdstukLineageId,
  usePatchOverwegingHoofdstukId,
  usePostOverwegingHoofdstuk,
} from '@/api/queries/overwegingHoofdstuk';
import { LoaderSpinningIcon, TextEditor, Transitions } from '@/components';
import { RegularFile, RegularImage } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from './EditorNavigation';

interface InhoudelijkeOverwegingenEditorProps {
  hoofdstukLineageId: string;
  besluitId: string;
  textEditorMinHeight?: number | null;
}

export const InhoudelijkeOverwegingenEditor = ({
  hoofdstukLineageId,
  besluitId,
  textEditorMinHeight,
}: InhoudelijkeOverwegingenEditorProps) => {
  const openModal = useOpenModal();
  const textEditorRef = useRef<Editor | null>(null);

  const overweging = useOverwegingByHoofdstukLineageId({ hoofdstukLineageId, besluitId });
  const postOverwegingHoofdstuk = usePostOverwegingHoofdstuk();

  const overwegingExists = !!overweging.data?.ID;

  /**
   * When a new besluit is created, there are no OverwegingHoofdstuk linked to the hoofdstuk yet.
   * Before the user can start editing, we need to create a OverwegingHoofdstuk.
   */
  useEffect(() => {
    if (!overwegingExists && !overweging.isLoading && !postOverwegingHoofdstuk.isPending) {
      postOverwegingHoofdstuk.mutate({
        data: {
          Besluit: besluitId,
          Hoofdstuk_lineage: hoofdstukLineageId,
          InhoudelijkeOverweging: '',
        },
      });
    }
  }, [
    overwegingExists,
    overweging.isLoading,
    postOverwegingHoofdstuk,
    besluitId,
    hoofdstukLineageId,
  ]);

  /*
   * Debounced callback to update the inhoudelijke overweging
   */
  const patchOverwegingHoofdstuk = usePatchOverwegingHoofdstukId({
    mutationKey: AUTOSAVE_MUTATION_KEY,
  });

  const onUpdatedDebounced = useDebouncedCallback(
    (value: string) => {
      if (!overweging.data?.ID) return;

      patchOverwegingHoofdstuk.mutate(
        {
          id: overweging.data.ID!,
          data: {
            InhoudelijkeOverweging: value,
          },
        },
        {
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              openModal('saveFailed', {
                tryAgain: () =>
                  patchOverwegingHoofdstuk.mutateAsync({
                    id: overweging.data?.ID ?? '',
                    data: {
                      InhoudelijkeOverweging: value,
                    },
                  }),
              });
            }
          },
        }
      );
    },
    [patchOverwegingHoofdstuk, overweging.data?.ID],
    300
  );

  return (
    // Add key to force rerender when hoofdstukId changes
    // Without it, the textEditor initialText will not update
    <Fragment key={hoofdstukLineageId}>
      {overweging.isLoading || !overweging.data ? (
        <div className="flex size-full items-center justify-center">
          <LoaderSpinningIcon />
        </div>
      ) : (
        <Transitions.FadeIn show appear>
          <TextEditor
            id={overweging.data?.ID ?? ''}
            editorRef={textEditorRef}
            placeholder="Typ hier de tekst"
            variant="clean"
            minHeight={textEditorMinHeight}
            menuOptions={[
              'p',
              'h1',
              'h2',
              'bold',
              'italic',
              'orderedList',
              'abcList',
              'bulletList',
              'subscript',
              'superscript',
              'underline',
              'table',
              'highlight',
            ]}
            customOptions={[
              {
                icon: RegularImage,
                testId: 'texteditor-add-image',
                ariaLabel: 'Afbeelding toevoegen',
                onClick: () => {
                  const initialSelection =
                    textEditorRef.current?.isActive('tableCell') ||
                    textEditorRef.current?.isActive('paragraph')
                      ? textEditorRef.current?.view.state.selection.$anchor.pos
                      : null;
                  openModal('editorAddImage', {
                    onAddImage: (base64Image, alt) => {
                      textEditorRef.current
                        ?.chain()
                        .focus(initialSelection)
                        .setImage({ src: base64Image, alt })
                        .run();
                    },
                  });
                },
              },
              {
                ariaLabel: 'Voeg standaard tekst toe',
                label: 'Standaardteksten',
                onClick: () => {
                  const initialSelection = textEditorRef.current?.view.state.selection.$anchor.pos;

                  openModal('editorStdInhoudelijkeOverwegingen', {
                    onSubmit: (inhoudelijkeOverwegingen) => {
                      textEditorRef.current
                        ?.chain()
                        .focus(initialSelection)
                        .insertContent(inhoudelijkeOverwegingen)
                        .run();
                    },
                  });
                },
                icon: RegularFile,
              },
            ]}
            initialText={overweging.data?.InhoudelijkeOverweging ?? ''}
            menuPosition="inline"
            testId="editor-inhoudelijke-overweging"
            onUpdate={onUpdatedDebounced}
          />
        </Transitions.FadeIn>
      )}
    </Fragment>
  );
};
