import { EditorRow, EditorRowProps } from '../EditorRow';

export interface EditorVoorschriftProps
  extends Pick<
    EditorRowProps,
    | 'testId'
    | 'buttonProps'
    | 'status'
    | 'active'
    | 'buttonTestId'
    | 'dragHandleRef'
    | 'dragPreviewRef'
    | 'className'
    | 'number'
    | 'children'
  > {
  content?: string;
  htmlContent?: string;
}

export const EditorVoorschrift = ({ content, htmlContent, ...props }: EditorVoorschriftProps) => {
  return (
    <EditorRow
      {...props}
      fontClasses="text-base"
      content={
        htmlContent ? (
          <div
            className="richtext text-left text-base"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        ) : (
          <p className="text-left text-base">{content}</p>
        )
      }
    />
  );
};
