import { createFileRoute, notFound } from '@tanstack/react-router';
import { Fragment } from 'react';

import { FilterSchema } from '@/api';
import { useBesluitId } from '@/api/queries/besluit';
import { useHoofdstukReservering } from '@/api/queries/hoofdstukReservering';
import { useHoofdstukReserveringByLineageId } from '@/api/queries/hoofdstukReservering/selectors/useHoofdstukReserveringByLineageId';
import {
  LinkParagraaf,
  LinkSubparagraaf,
  Links,
  useHoofdstukId,
  useLinksInHoofdstuk,
  useOrderParagraafLink,
  useOrderSubparagraafLink,
  useOrderVoorschriftLink,
} from '@/api/queries/objects';
import {
  DraggableEditor,
  DraggableParagraaf,
  DraggableSubparagraaf,
  DraggableVoorschrift,
  DroppableParagraaf,
  DroppableSubparagraaf,
  DroppableVoorschrift,
} from '@/components';
import { useUserStore } from '@/stores/user';

import { EditorAddMenuVoorschriftenRoute } from './-components/EditorAddMenuVoorschriftenRoute';
import { EditorContainer } from './-components/EditorContainer';
import { Hoofdstuk } from './-components/Hoofdstuk/Hoofdstuk';
import { InhoudelijkeOverwegingenPanel } from './-components/InhoudelijkeOverwegingenPanel';
import { Paragraaf } from './-components/Paragraaf/Paragraaf';
import { ParagraafAddBar } from './-components/Paragraaf/ParagraafAddBar';
import { RenumberButton } from './-components/RenumberButton';
import { Subparagraaf } from './-components/Subparagraaf/Subparagraaf';
import { SubparagraafAddBar } from './-components/Subparagraaf/SubparagraafAddBar';
import { Voorschrift } from './-components/Voorschrift/Voorschrift';
import { VoorschriftAddBar } from './-components/Voorschrift/VoorschriftAddBar';
import { useEditorStore } from './-store/useEditorStore';
import { useAutoExtendReservering } from './-utils/useAutoExtendReservering';
import { EditorMode, useEditorMode } from './-utils/useEditorMode';

const VSPBFilter: FilterSchema[] = [
  { model: 'Voorschrift', field: 'Volgend', op: 'is_null', value: '' },
];

const SPPBFilter: FilterSchema[] = [
  { model: 'Subparagraaf', field: 'Volgend', op: 'is_null', value: '' },
];

const PHBFilter: FilterSchema[] = [
  { model: 'Paragraaf', field: 'Volgend', op: 'is_null', value: '' },
];

/**
 * Route
 */
export const Route = createFileRoute(
  '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId'
)({
  loader: async ({ context: { queryClient }, params: { hoofdstukId, besluitId } }) => {
    const [hoofdstuk] = await Promise.all([
      queryClient.ensureQueryData(useHoofdstukId.getOptions(hoofdstukId)),
      queryClient.ensureQueryData(
        useLinksInHoofdstuk.getOptions({
          hoofdstukId,
          besluitId,
          VSPBFilter,
          SPPBFilter,
          PHBFilter,
        })
      ),
      queryClient.ensureQueryData(useBesluitId.getOptions(besluitId)),
    ]);

    if (!hoofdstuk) throw notFound();
  },
  component: VoorschriftenHoofdstukComponent,
  onEnter: ({ context: { queryClient } = {} }) => {
    queryClient?.invalidateQueries({ queryKey: useHoofdstukReservering.key });
  },
});

/**
 * Route component
 */
function VoorschriftenHoofdstukComponent() {
  const { hoofdstukId, besluitId, bedrijfLineageId } = Route.useParams();

  const links = useLinksInHoofdstuk({ hoofdstukId, besluitId, VSPBFilter, SPPBFilter, PHBFilter });
  const isOrderingLink = useEditorStore(({ isOrderingLink }) => isOrderingLink);

  /**
   * Check if user has reservering
   */
  const hoofdstuk = useHoofdstukId(hoofdstukId);
  const hoofdstukReservering = useHoofdstukReserveringByLineageId(hoofdstuk.data?.Lineage);
  const userId = useUserStore((state) => state.user?.ID);

  const userHasReservering = hoofdstukReservering.data?.Gebruiker === userId;

  /**
   * Get editorMode
   */
  const editorMode = useEditorMode(besluitId);

  /**
   * Auto extend hoofdstuk reservering
   */
  useAutoExtendReservering(hoofdstuk.data?.Lineage);

  if (!links.data || !editorMode) return null;

  return (
    <>
      <DraggableEditor allowReparenting={editorMode === 'extended'}>
        <EditorContainer isLoading={isOrderingLink}>
          <RenumberButton
            className="mb-8"
            userHasReservering={userHasReservering}
            editorMode={editorMode}
            hoofdstukId={hoofdstukId}
          />

          <Hoofdstuk
            id={hoofdstukId}
            links={links.data}
            editorMode={editorMode}
            userHasReservering={userHasReservering}
          />

          <Paragrafen
            links={links.data}
            userHasReservering={userHasReservering}
            editorMode={editorMode}
          />
        </EditorContainer>

        <EditorAddMenuVoorschriftenRoute
          bedrijfLineageId={bedrijfLineageId}
          besluitId={besluitId}
        />
      </DraggableEditor>

      <InhoudelijkeOverwegingenPanel hoofdstukId={hoofdstukId} besluitId={besluitId} />
    </>
  );
}

const Paragrafen = ({
  links,
  userHasReservering,
  editorMode,
}: {
  links: Links;
  userHasReservering: boolean;
  editorMode: EditorMode;
}) => {
  const setIsOrderingLink = useEditorStore(({ setIsOrderingLink }) => setIsOrderingLink);

  /**
   * Drag handlers for paragraaf
   */
  const orderParagraafLink = useOrderParagraafLink();

  const onDropParagraaf = ({
    from,
    to,
    method,
  }: {
    from: number;
    to: number;
    method: 'prepend' | 'append';
  }) => {
    setIsOrderingLink(true);

    orderParagraafLink.mutate(
      {
        from,
        to,
        method,
        links,
      },
      {
        onSettled: () => setIsOrderingLink(false),
      }
    );
  };

  return (
    <>
      {links.map((paragraafLink, paragraafIndex) => (
        <Fragment key={`paragraaf-${paragraafLink.PHB.ID}`}>
          <DroppableParagraaf
            index={paragraafIndex}
            method="prepend"
            onDrop={(from) => {
              onDropParagraaf({
                from: from.index,
                to: paragraafIndex,
                method: 'prepend',
              });
            }}
          />

          <ParagraafAddBar
            previous={links[paragraafIndex - 1]}
            next={paragraafLink}
            userHasReservering={userHasReservering}
            editorMode={editorMode}
            paragraafIndex={paragraafIndex}
          />

          <DraggableParagraaf index={paragraafIndex}>
            {({ dragHandleRef, dragPreviewRef }) => (
              <div ref={dragPreviewRef}>
                <Paragraaf
                  dragHandleRef={dragHandleRef}
                  link={paragraafLink}
                  links={links}
                  userHasReservering={userHasReservering}
                  editorMode={editorMode}
                  paragraafIndex={paragraafIndex}
                />

                <Subparagrafen
                  paragraafLink={paragraafLink}
                  paragraafIndex={paragraafIndex}
                  userHasReservering={userHasReservering}
                  links={links}
                  editorMode={editorMode}
                />
              </div>
            )}
          </DraggableParagraaf>

          {paragraafIndex === links.length - 1 && (
            <DroppableParagraaf
              method="append"
              index={paragraafIndex}
              onDrop={(from) => {
                onDropParagraaf({
                  from: from.index,
                  to: paragraafIndex,
                  method: 'append',
                });
              }}
            />
          )}
        </Fragment>
      ))}

      <ParagraafAddBar
        paragraafIndex={links.length}
        previous={links[links.length - 1]}
        userHasReservering={userHasReservering}
        editorMode={editorMode}
      />
    </>
  );
};

const Subparagrafen = ({
  paragraafLink,
  paragraafIndex,
  userHasReservering,
  links,
  editorMode,
}: {
  paragraafLink: LinkParagraaf;
  paragraafIndex: number;
  userHasReservering: boolean;
  links: Links;
  editorMode: EditorMode;
}) => {
  const setIsOrderingLink = useEditorStore(({ setIsOrderingLink }) => setIsOrderingLink);
  const orderSubparagraafLink = useOrderSubparagraafLink();

  const onDropSubparagraaf = ({
    from,
    to,
    method,
  }: {
    from: [number, number];
    to: [number, number];
    method: 'prepend' | 'append';
  }) => {
    setIsOrderingLink(true);

    orderSubparagraafLink.mutate(
      {
        from,
        to,
        method,
        links,
      },
      {
        onSettled: () => setIsOrderingLink(false),
      }
    );
  };

  if (!paragraafLink.subparagrafen.length) {
    return (
      <SubparagraafAddBar
        paragraafLink={paragraafLink}
        userHasReservering={userHasReservering}
        paragraafIndex={paragraafIndex}
        subparagraafIndex={1}
      />
    );
  }

  return (
    <>
      {paragraafLink.subparagrafen.map((subparagraafLink, subparagraafIndex) => {
        const path: [number, number] = [paragraafIndex, subparagraafIndex];

        return (
          <Fragment key={`subparagraaf-${subparagraafLink.SPPB.ID}`}>
            <DraggableSubparagraaf path={path}>
              {({ dragHandleRef, dragPreviewRef }) => (
                <div ref={dragPreviewRef}>
                  <Subparagraaf
                    dragHandleRef={dragHandleRef}
                    editorMode={editorMode}
                    link={subparagraafLink}
                    links={links}
                    paragraafIndex={paragraafIndex}
                    subparagraafIndex={subparagraafIndex}
                    userHasReservering={userHasReservering}
                  />

                  <Voorschriften
                    subparagraafIndex={subparagraafIndex}
                    paragraafIndex={paragraafIndex}
                    subparagraafLink={subparagraafLink}
                    userHasReservering={userHasReservering}
                    links={links}
                    editorMode={editorMode}
                  />
                </div>
              )}
            </DraggableSubparagraaf>

            <DroppableSubparagraaf
              method="append"
              path={path}
              onDrop={(from) => {
                onDropSubparagraaf({
                  from,
                  to: path,
                  method: 'append',
                });
              }}
            />

            <SubparagraafAddBar
              paragraafLink={paragraafLink}
              userHasReservering={userHasReservering}
              previous={subparagraafLink}
              next={paragraafLink.subparagrafen[subparagraafIndex + 1]}
              paragraafIndex={paragraafIndex}
              subparagraafIndex={subparagraafIndex}
            />
          </Fragment>
        );
      })}
    </>
  );
};

const Voorschriften = ({
  subparagraafLink,
  paragraafIndex,
  subparagraafIndex,
  userHasReservering,
  links,
  editorMode,
}: {
  subparagraafLink: LinkSubparagraaf;
  paragraafIndex: number;
  subparagraafIndex: number;
  userHasReservering: boolean;
  links: Links;
  editorMode: EditorMode;
}) => {
  const orderVoorschriftLink = useOrderVoorschriftLink();
  const setIsOrderingLink = useEditorStore(({ setIsOrderingLink }) => setIsOrderingLink);

  const onDropVoorschrift = ({
    from,
    to,
    method,
  }: {
    from: [number, number, number];
    to: [number, number, number];
    method: 'prepend' | 'append';
  }) => {
    setIsOrderingLink(true);

    orderVoorschriftLink.mutate(
      {
        from,
        to,
        method,
        links,
      },
      {
        onSettled: () => setIsOrderingLink(false),
      }
    );
  };

  if (!subparagraafLink.voorschriften.length && editorMode === 'extended') {
    return (
      <>
        <DroppableVoorschrift
          path={[paragraafIndex, subparagraafIndex, 0]}
          method="prepend"
          onDrop={(from) => {
            onDropVoorschrift({
              from,
              to: [paragraafIndex, subparagraafIndex, 0],
              method: 'prepend',
            });
          }}
        />

        <VoorschriftAddBar
          subparagraafLink={subparagraafLink}
          userHasReservering={userHasReservering}
          paragraafIndex={paragraafIndex}
          subparagraafIndex={subparagraafIndex}
          voorschriftIndex={0}
        />
      </>
    );
  }

  return (
    <>
      <VoorschriftAddBar
        subparagraafLink={subparagraafLink}
        userHasReservering={userHasReservering}
        next={subparagraafLink.voorschriften[0]}
        paragraafIndex={paragraafIndex}
        subparagraafIndex={subparagraafIndex}
        voorschriftIndex={0}
      />

      {subparagraafLink.voorschriften.map((voorschriftLink, voorschriftIndex) => {
        const path: [number, number, number] = [
          paragraafIndex,
          subparagraafIndex,
          voorschriftIndex,
        ];

        return (
          /**
           * Rang is used as a key to prevent (un)mounting of the component
           * when a new voorschrift + link is created in the lineage of the voorschrift.
           */
          <Fragment key={`${voorschriftLink.VSPB.Subparagraaf}-${voorschriftLink.VSPB.Rang}`}>
            <DroppableVoorschrift
              method="prepend"
              path={path}
              onDrop={(from) => {
                onDropVoorschrift({
                  from,
                  to: path,
                  method: 'prepend',
                });
              }}
            />

            <DraggableVoorschrift path={[paragraafIndex, subparagraafIndex, voorschriftIndex]}>
              {({ dragHandleRef, dragPreviewRef }) => (
                <div ref={dragPreviewRef}>
                  <Voorschrift
                    dragHandleRef={dragHandleRef}
                    link={voorschriftLink}
                    links={links}
                    userHasReservering={userHasReservering}
                    editorMode={editorMode}
                    paragraafIndex={paragraafIndex}
                    subparagraafIndex={subparagraafIndex}
                    voorschriftIndex={voorschriftIndex}
                  />
                </div>
              )}
            </DraggableVoorschrift>

            {voorschriftIndex === subparagraafLink.voorschriften.length - 1 && (
              <DroppableVoorschrift
                method="append"
                path={path}
                onDrop={(from) => {
                  onDropVoorschrift({
                    from,
                    to: path,
                    method: 'append',
                  });
                }}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};
