import { FilterSchema } from '@/api';
import { useBesluit } from '@/api/queries/besluit';
import { useBesluitTypeId } from '@/api/queries/besluitType';
import { RegularSpinner, SolidLock } from '@/icons/components';
import { ObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';

type SlotOverlayProps = {
  slotState: ObjectState['slotState'];
  id: string;
  type: 'hoofdstuk' | 'paragraaf' | 'subparagraaf' | 'voorschrift';
};

const SLOT_STATE_LABEL: Record<NonNullable<ObjectState['slotState']>, string> = {
  deleted: 'ingetrokken',
  edited: 'gewijzigd',
  new: 'toegevoegd',
};

export const SlotOverlay = ({ slotState, type, id }: SlotOverlayProps) => {
  const getBesluitFilters: FilterSchema[] = [];

  if (type === 'voorschrift') {
    getBesluitFilters.push(
      {
        field: 'Type',
        value: 'Eigen',
        op: 'eq',
        model: 'VSPB',
      },
      {
        field: 'Voorschrift',
        value: id,
        op: 'eq',
        model: 'VSPB',
      }
    );
  }

  if (type === 'paragraaf') {
    getBesluitFilters.push(
      {
        field: 'Type',
        value: 'Eigen',
        op: 'eq',
        model: 'PHB',
      },
      {
        field: 'Paragraaf',
        value: id,
        op: 'eq',
        model: 'PHB',
      }
    );
  }

  if (type === 'subparagraaf') {
    getBesluitFilters.push(
      {
        field: 'Type',
        value: 'Eigen',
        op: 'eq',
        model: 'SPPB',
      },
      {
        field: 'Subparagraaf',
        value: id,
        op: 'eq',
        model: 'SPPB',
      }
    );
  }

  if (type === 'hoofdstuk') {
    getBesluitFilters.push(
      {
        field: 'Type',
        value: 'Eigen',
        op: 'eq',
        model: 'HB',
      },
      {
        field: 'Hoofdstuk',
        value: id,
        op: 'eq',
        model: 'HB',
      }
    );
  }

  const originalBesluit = useBesluit({
    page: 1,
    size: 1,
    filter: getBesluitFilters,
  });

  const besluitType = useBesluitTypeId(originalBesluit.data?.objects?.[0]?.Type);

  return (
    <div
      data-testid="locked-overlay"
      className="absolute inset-px flex cursor-pointer items-center justify-center bg-gray-100/50 p-2 text-sm font-normal text-gray-200"
    >
      <div className="z-[2] flex items-center justify-center rounded bg-[#333333] p-2 hover:opacity-0">
        {originalBesluit.isLoading || besluitType.isLoading ? (
          <RegularSpinner className="rotating" />
        ) : (
          <>
            <SolidLock className="mr-2 inline text-gray-200" />
            <p>
              Dit onderdeel is {slotState ? SLOT_STATE_LABEL[slotState] : ''} in{' '}
              {besluitType.data?.Naam}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
