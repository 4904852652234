import { useDebouncedCallback } from '@react-hookz/web';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/react';
import { useCallback, useEffect, useRef } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { twJoin } from 'tailwind-merge';

import { getLastCreatedObject, useHoofdstukId, usePatchParagraafId } from '@/api/queries/objects';
import { PillButton, TextEditor } from '@/components';
import { RegularTrash, SolidClockRotateLeft } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from '../EditorNavigation';
import { ObjectRow } from '../ObjectRow/ObjectRow';
import { useParagraafContext } from './ParagraafContext';
import { ParagraafNumber } from './ParagraafNumber';

const unwrapValue = (value: string) => {
  const element = document.createElement('div');

  element.innerHTML = value;

  const inner = element.querySelector('p');

  return inner?.innerHTML;
};

export const ParagraafInput = ({ dragHandleRef }: { dragHandleRef?: ConnectDragSource }) => {
  const {
    objectState,
    nummer,
    addError,
    removeError,
    errors,
    paragraaf,
    link,
    userHasReservering,
    editorMode,
  } = useParagraafContext();

  const openModal = useOpenModal();

  const id = link.PHB.Paragraaf;

  const textEditorRef = useRef<Editor | null>(null);

  const { data: isParentIngetrokken } = useHoofdstukId(link.PHB.Hoofdstuk, {
    select: ({ Stop_Lineage }) => !!Stop_Lineage,
  });

  const patchParagraafId = usePatchParagraafId({ mutationKey: AUTOSAVE_MUTATION_KEY });

  /**
   * Patch for the paragraaf titel
   */
  const updateTitelDebounced = useDebouncedCallback(
    (value: string) => {
      const unwrappedValue = unwrapValue(value);

      if (unwrappedValue === paragraaf.Titel) return;

      patchParagraafId.mutate(
        {
          id,
          data: {
            Titel: unwrappedValue ?? '',
          },
        },
        {
          onSuccess: () => removeError('patchTitel'),
          onError: (error) =>
            addError({
              message: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
              type: 'patchTitel',
            }),
        }
      );
    },
    [id, patchParagraafId],
    500
  );

  /**
   * Update TextEditor when the editor is not editable
   */
  useEffect(() => {
    if (textEditorRef.current && !userHasReservering) {
      textEditorRef.current.commands.setContent(paragraaf.Titel ?? '');
    }
  }, [paragraaf.Titel, userHasReservering]);

  /**
   * Callback to delete the paragraaf
   */
  const onDelete = useCallback(() => {
    openModal('deleteParagraaf', {
      paragraafLink: link,
      nummer,
    });
  }, [link, nummer, openModal]);

  const isDraggable =
    userHasReservering &&
    editorMode === 'extended' &&
    objectState.isEditable &&
    objectState.state === 'new' &&
    !objectState.slotState;

  const containsSlotObject = link.subparagrafen.some((subparagraafLink) => {
    if (subparagraafLink.SPPB.Type === 'MBIO') return true;

    return subparagraafLink.voorschriften.some(
      (voorschriftLink) => voorschriftLink.VSPB.Type === 'MBIO'
    );
  });

  const canIntrekken =
    userHasReservering &&
    objectState.state === 'vigerend' &&
    !objectState.slotState &&
    !containsSlotObject;
  const canBeDeleted = userHasReservering && objectState.state === 'new' && !objectState.slotState;
  const hasError = !!errors.length;
  const hasTitelError = errors.some((error) => error.type === 'patchTitel');

  return (
    <ObjectRow
      id={id}
      lineageId={paragraaf.Lineage}
      type="paragraaf"
      objectState={objectState}
      hasError={hasError}
    >
      <ObjectRow.State state={objectState.state} />

      {isDraggable && dragHandleRef && <ObjectRow.DragHandle dragHandleRef={dragHandleRef} />}

      <ObjectRow.Number>
        <ParagraafNumber />
      </ObjectRow.Number>

      <ObjectRow.Body>
        <div className={twJoin('grow-2 w-full', objectState.state === 'deleted' && 'line-through')}>
          <TextEditor
            editorRef={textEditorRef}
            editable={objectState.isEditable}
            deleted={objectState.state === 'deleted'}
            menuOptions={['superscript', 'subscript', 'highlight']}
            initialText={paragraaf.Titel}
            id={id}
            testId="texteditor"
            variant="clean"
            placeholder="Voer de titel van de paragraaf in"
            classes={{
              default: twJoin(
                'ring-inset transition-all duration-100 ease-in',
                'px-1 py-0.5 text-xl font-semibold text-theme-blue',
                !hasTitelError && 'hover:ring-1 hover:ring-gray-400',
                hasTitelError && 'ring-1 ring-digi-v-color-danger hover:ring-digi-v-color-danger'
              ),
              focused: 'ring-1 ring-gray-400 bg-white',
            }}
            singleLine
            onUpdate={updateTitelDebounced}
            onCreate={({ editor }) => {
              const lastCreatedObject = getLastCreatedObject();

              const isNew = lastCreatedObject?.Lineage === paragraaf.Lineage;

              if (isNew && !paragraaf.Titel) {
                // There's race condition in TipTap where the editor is created but methods can't be called on it.
                // This is a temporary workaround to focus the editor after it's created.
                setTimeout(() => editor?.commands.focus(), 50);
              }
            }}
          />
        </div>
      </ObjectRow.Body>

      {canBeDeleted && (
        <ObjectRow.Icon
          icon={RegularTrash}
          className="text-digi-v-color-danger"
          onClick={onDelete}
          testId="delete"
        />
      )}

      {userHasReservering && objectState.state === 'deleted' && !objectState.slotState && (
        <ObjectRow.ButtonOverlay>
          {isParentIngetrokken ? (
            <Tippy content="Het is niet mogelijk om het intrekken ongedaan te maken omdat bovenliggend object ook is ingetrokken">
              <div>
                <PillButton variant="green" disabled icon={SolidClockRotateLeft}>
                  Intrekken ongedaan maken
                </PillButton>
              </div>
            </Tippy>
          ) : (
            <PillButton
              variant="green"
              onClick={() => openModal('undoIntrekkenParagraaf', { id, nummer })}
              icon={SolidClockRotateLeft}
            >
              Intrekken ongedaan maken
            </PillButton>
          )}
        </ObjectRow.ButtonOverlay>
      )}

      {canIntrekken && (
        <ObjectRow.ButtonOverlay>
          <PillButton
            className="shadow-md"
            variant="white"
            onClick={() => {
              openModal('intrekkenParagraaf', {
                link,
                nummer,
                paragraaf,
              });
            }}
          >
            Paragraaf intrekken
          </PillButton>
        </ObjectRow.ButtonOverlay>
      )}
    </ObjectRow>
  );
};
