import { LinkParagraaf } from './getLinksInHoofdstuk';

/**
 * Filters all links that are Eigen.
 *
 * When a link is Eigen, all parents will also be included.
 */
export const filterEigenLinks = (links: LinkParagraaf[]) => {
  return links.filter((link) => {
    if (link.PHB.Type === 'Eigen') return true;

    link.subparagrafen = link.subparagrafen.filter((subparagraafLink) => {
      if (subparagraafLink.SPPB.Type === 'Eigen') return true;

      subparagraafLink.voorschriften = subparagraafLink.voorschriften.filter((voorschrift) => {
        return voorschrift.VSPB.Type === 'Eigen';
      });

      return subparagraafLink.voorschriften.length > 0;
    });

    return link.subparagrafen.length > 0;
  });
};
