import { ActiviteitCategorieSchema, ActiviteitCategorieSchemaStatus } from '@/api';
import { usePatchActiviteitCategorieId } from '@/api/queries/activiteitCategorie';
import { Modal, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface BeheerActiviteitStatusProps {
  newStatus: ActiviteitCategorieSchemaStatus;
  activiteit: ActiviteitCategorieSchema;
}

export const BEHEER_ACTIVITEIT_STATUS_ID = 'beheerActiviteitStatus';

export const BeheerActiviteitStatus = modal(BEHEER_ACTIVITEIT_STATUS_ID, ({ data, props }) => {
  const { newStatus, activiteit } = data;

  const userId = useUserStore((state) => state.user?.ID);

  const modalState = useModalState();

  const patchActiviteitCategorie = usePatchActiviteitCategorieId();

  const updateStatus = () => {
    const date = new Date().toISOString();

    modalState.action('save');

    patchActiviteitCategorie.mutate(
      {
        id: activiteit.ID!,
        data: {
          Status: newStatus,
          Modified_By: userId,
          Modified_Date: date,
        },
      },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) => {
          const errorMessage = ErrorMessage.getLocalErrorMessage(error);

          if (errorMessage) {
            modalState.error('save', { title: 'Mislukt', description: errorMessage });
          }
        },
      }
    );
  };

  return (
    <Modal
      {...props}
      state={modalState.state}
      title={newStatus === 'Actief' ? 'Herstellen' : 'Archiveren'}
    >
      {newStatus === 'Actief' ? (
        <p>
          Je staat op het punt om de activiteit <strong>{activiteit.Activiteit_Categorie}</strong>{' '}
          te herstellen uit het archief. Dit onderdeel zal weer zichtbaar worden voor gebruikers van{' '}
          {eStrings.LBL_DIGI_V}.
        </p>
      ) : (
        <p>
          Je staat op het punt om de activiteit <strong>{activiteit.Activiteit_Categorie}</strong>{' '}
          te archiveren. Zolang dit onderdeel gearchiveerd is, zal het niet zichtbaar zijn voor
          gebruikers van {eStrings.LBL_DIGI_V}.
        </p>
      )}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="save"
          variant={newStatus === 'Actief' ? 'green' : 'red'}
          successLabel={newStatus === 'Actief' ? 'Herstelt' : 'Gearchiveerd'}
          onClick={updateStatus}
        >
          {newStatus === 'Actief' ? 'Herstellen' : 'Archiveren'}
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
