import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512" ref={svgRef} {...props}><path d="M0 56c0-13.3 10.7-24 24-24h112c13.3 0 24 10.7 24 24s-10.7 24-24 24h-32v144h240V80h-32c-13.3 0-24-10.7-24-24s10.7-24 24-24h112c13.3 0 24 10.7 24 24s-10.7 24-24 24h-32v352h32c13.3 0 24 10.7 24 24s-10.7 24-24 24H312c-13.3 0-24-10.7-24-24s10.7-24 24-24h32V272H104v160h32c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h32V80H24C10.7 80 0 69.3 0 56z" /></svg>;
});
SVGIcon.displayName = "RegularHeading";
export default SVGIcon;