import { Ref, TextareaHTMLAttributes, forwardRef, useEffect, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';

import { FormUtils } from '@/utils/formUtils';

import { FormError } from '../FormError';
import { FormLabel } from '../FormLabel';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  name: string;
  label: string;
  hideLabel?: boolean;
  required?: boolean;
  labelClasses?: string;
  inputClasses?: string;
  /** Error coming from `react-hook-form` */
  error?: FieldError | string;
  disabled?: boolean;
  horizontal?: boolean;
  /** This will disable line breaks and make the input field grow according to the amount of content */
  disableLineBreak?: boolean;
  ref?: Ref<HTMLTextAreaElement>;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      name,
      label,
      hideLabel,
      className,
      labelClasses,
      inputClasses,
      required,
      error,
      disabled,
      horizontal,
      disableLineBreak,
      onChange,
      ...props
    },
    ref
  ) => {
    const errorId = `${id}-error`;

    /*
     * This makes sure the textarea grows according to the amount of content
     */
    const containerRef = useRef<HTMLDivElement>(null);

    const resizeTextArea = (textArea: HTMLTextAreaElement) => {
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    };

    useEffect(() => {
      const textArea = containerRef.current?.querySelector('textarea');

      if (textArea) {
        resizeTextArea(textArea);
      }
    }, []);

    return (
      <div
        className={twMerge(
          twJoin(horizontal && 'grid w-full grid-cols-3', !horizontal && 'flex flex-col'),
          className
        )}
      >
        <div className={twJoin(hideLabel && 'sr-only', horizontal && 'col-span-1 pr-2 pt-1')}>
          <FormLabel htmlFor={id} className={labelClasses} required={required}>
            {label}
          </FormLabel>
        </div>

        <div ref={containerRef} className={twJoin('w-full', horizontal && 'col-span-2')}>
          <textarea
            id={id}
            name={name}
            ref={ref}
            disabled={disabled}
            aria-invalid={error ? true : undefined}
            aria-describedby={error ? errorId : undefined}
            rows={disableLineBreak ? 1 : 2}
            className={twJoin(
              'form-input-custom w-full resize-none rounded-md shadow-sm transition',
              error &&
                'border-6 border-solid border-digi-v-color-danger focus:border-digi-v-color-danger focus:ring focus:ring-digi-v-color-danger/30',
              !error &&
                'border-gray-300 focus:border-blue-500/60 focus:ring focus:ring-blue-500/30',
              inputClasses
            )}
            onChange={(e) => {
              if (disableLineBreak) {
                e.target.value = FormUtils.removeLinebreaks(e.target.value);
              }

              resizeTextArea(e.target);

              if (onChange) {
                onChange(e);
              }
            }}
            {...props}
          />

          {error && (
            <FormError id={errorId}>{typeof error === 'string' ? error : error.message}</FormError>
          )}
        </div>
      </div>
    );
  }
);
TextArea.displayName = 'TextArea';
