import { ReactNode } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { twJoin, twMerge } from 'tailwind-merge';

import { IconButton, IconButtonProps } from '@/components';
import { RegularGripDotsVertical } from '@/icons/components';

import { StateIcon, StateIconProps } from '../../StateIcon';

export interface EditorRowProps {
  children?: ReactNode;
  number?: string;
  status?: StateIconProps['state'];
  content: ReactNode;
  buttonTestId?: string;
  testId?: string;
  fontClasses: string;
  buttonProps?: Exclude<IconButtonProps, 'className' | 'data-testid' | 'children'>;
  dragHandleRef?: ConnectDragSource;
  dragPreviewRef?: ConnectDragPreview;
  active?: boolean;
  className?: string;
}

export const EditorRow = ({
  children,
  number,
  status,
  content,
  testId,
  fontClasses,
  buttonProps,
  dragHandleRef,
  dragPreviewRef,
  active,
  className,
}: EditorRowProps) => {
  return (
    <>
      <div
        ref={dragPreviewRef}
        className={twMerge(
          'group relative flex w-full py-2 pl-2 align-top',
          active && 'is-active ring-1 ring-inset ring-theme-blue',
          className
        )}
        data-testid={testId}
      >
        {dragHandleRef && (
          <button
            ref={dragHandleRef}
            className={twJoin(
              'absolute right-full flex h-6 w-4 cursor-grab items-center justify-center rounded-sm opacity-0',
              'hover:bg-gray-300 group-hover:opacity-100'
            )}
            aria-label="Verplaats besluit onderdeel"
            data-testid={`editor-row-drag-${testId}`}
          >
            <RegularGripDotsVertical size={16} className="block text-base text-black" />
          </button>
        )}

        <div className="mb-auto mr-2 flex w-[84px] shrink-0 grow-0 items-center">
          {status && (
            <div
              data-testid={`editor-row-status-${testId}`}
              className="mr-2 shrink-0 grow-0 basis-[11px]"
            >
              <StateIcon state={status} />
            </div>
          )}

          {number && (
            <div
              className={twJoin(
                'ml-auto w-16',
                fontClasses,
                status === 'deleted' && 'line-through'
              )}
            >
              {number}
            </div>
          )}
        </div>

        <div className={twJoin(status === 'deleted' && 'line-through', 'grow-2 basis-full')}>
          {content}
        </div>

        <div
          className={twJoin(
            '-my-2 shrink-0 basis-14 border-r border-theme-blue px-4 pt-2',
            buttonProps &&
              'focus-within-visible:opacity-100 opacity-0 transition-opacity group-hover:opacity-100 group-[.is-active]:opacity-100',
            !buttonProps && 'opacity-0'
          )}
        >
          {buttonProps && <IconButton {...buttonProps} />}
        </div>
      </div>

      {children}
    </>
  );
};
