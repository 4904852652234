import { redirect } from '@tanstack/react-router';

import { Allowed } from '@/components';

import { useUserStore } from './useUserStore';

/**
 * This function is used to check if a user is authenticated to access a route.
 *
 * To be used in the `beforeLoad` function of a route.
 *
 * @example
 * new Route({
 *   beforeLoad: async ({ preload }) => {
 *      await isUserAuthenticatedForRoute({ preload, allowed: ['Vergunningverlener'] });
 *   }
 * })
 */
export const isUserAuthenticatedForRoute = async ({
  preload,
  allowed,
}: {
  preload: boolean;
  allowed: Allowed[];
}) => {
  if (!preload) {
    const { isInitialised, loadLoggedInUser } = useUserStore.getState();

    if (!isInitialised) {
      await loadLoggedInUser();
    }

    const { userRole } = useUserStore.getState();

    if (!userRole || !allowed.some((role) => userRole.includes(role))) {
      throw redirect({ to: '/login' });
    }
  }
};
