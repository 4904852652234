import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useParagraaf, usePatchParagraafId } from '@/api/queries/objects';
import { Button } from '@/components';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { Custom123 } from '@/icons/components';
import { fetchAllPages } from '@/utils/fetchAllPages';

import { EditorMode } from '../-utils/useEditorMode';

export const RenumberButton = ({
  editorMode,
  userHasReservering,
  hoofdstukId,
  className,
}: {
  userHasReservering: boolean;
  editorMode: EditorMode;
  hoofdstukId: string;
  className?: string;
}) => {
  const queryClient = useQueryClient();
  const patchParagraafId = usePatchParagraafId();
  const [state, setState] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    if (state === 'success') {
      setTimeout(() => {
        if (isMounted) {
          setState('idle');
        }
      }, 3000);
    }

    return () => {
      isMounted = false;
    };
  }, [state]);

  if (editorMode !== 'extended' || !userHasReservering) {
    return null;
  }

  const renumber = async () => {
    setState('pending');

    const paragrafen = await fetchAllPages((page) =>
      queryClient.fetchQuery(
        useParagraaf.getOptions({
          page,
          size: 100,
          filter: [
            {
              model: 'PHB',
              field: 'Hoofdstuk',
              value: hoofdstukId,
              op: 'eq',
            },
          ],
          sort: [
            {
              model: 'PHB',
              field: 'Rang',
              direction: 'asc',
            },
          ],
        })
      )
    );

    try {
      await Promise.all(
        paragrafen.map((paragraaf, index) => {
          return patchParagraafId.mutateAsync({
            id: paragraaf.ID!,
            data: {
              Nummer: index + 1,
            },
          });
        })
      );
    } catch (error) {
      setError((error as Error).message);
      setState('error');
      return;
    }

    setState('success');
  };

  return (
    <div className={className}>
      <Button
        variant="white"
        icon={Custom123}
        onClick={renumber}
        state={state === 'pending' ? 'loading' : state === 'success' ? 'success' : undefined}
      >
        Paragrafen hernummeren
      </Button>

      {error && <WarningInline description={error} />}
    </div>
  );
};
