import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512" ref={svgRef} {...props}><path d="M144 128a80 80 0 11160 0 80 80 0 11-160 0zm208 0a128 128 0 10-256 0 128 128 0 10256 0zM49.3 464c8.9-63.3 63.3-112 129-112h91.4c49.3 0 92.1 27.3 114.3 67.7V352c0-2.1.1-4.2.3-6.3-31-26-71-41.7-114.6-41.7h-91.4C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h362.9c-5.4-9.4-8.6-20.3-8.6-32v-16H49.3zM528 240c17.7 0 32 14.3 32 32v48h-64v-48c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32v128c0 17.7 14.3 32 32 32h160c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32v-48c0-44.2-35.8-80-80-80s-80 35.8-80 80z" /></svg>;
});
SVGIcon.displayName = "RegularUserLock";
export default SVGIcon;