import { useState } from 'react';

import {
  LinkParagraaf,
  LinkSubparagraaf,
  useCreateObjects,
  useParagraafId,
} from '@/api/queries/objects';
import { EditorAddCompact } from '@/components/shared/Editor/EditorAddCompact';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { RegularHeading } from '@/icons/components';
import { ErrorType } from '@/services/axios';
import { ErrorMessage } from '@/utils/errorMessage';

import { useEditorStore } from '../../-store/useEditorStore';

interface SubparagraafAddBarProps {
  userHasReservering: boolean;
  paragraafLink: LinkParagraaf;
  previous?: LinkSubparagraaf;
  next?: LinkSubparagraaf;
  paragraafIndex: number;
  subparagraafIndex: number;
}

export const SubparagraafAddBar = ({
  userHasReservering,
  paragraafLink,
  previous,
  next,
  paragraafIndex,
  subparagraafIndex,
}: SubparagraafAddBarProps) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const createSubparagraaf = useCreateObjects();
  const paragraaf = useParagraafId(paragraafLink.PHB.Paragraaf);

  const areAddBarsShown = useEditorStore((state) => state.areAddBarsShown);

  if (!areAddBarsShown || !userHasReservering) {
    return null;
  }

  /**
   * Callbacks for when the create mutation fails or succeeds
   */
  const handleError = (error: ErrorType<string>) => {
    setError(ErrorMessage.getLocalErrorMessage(error));
  };

  const handleSuccess = () => {
    setError(undefined);
  };

  /**
   * Validate if a paragraaf can be added
   */
  if (paragraaf.isLoading || !paragraaf.data) return null;

  // When the paragraaf is ingetrokken, do not show the add bar
  if (paragraaf.data.Stop_Lineage) return null;

  // When the paragraaf is new and created in another besluit, do not show the add bar
  if (!paragraaf.data.Vorig && paragraaf.data.Ontwerp && paragraafLink.PHB.Type === 'MBIO')
    return null;

  /**
   * If all validations pass, show the add bar
   */
  return (
    <>
      <EditorAddCompact testId={`add-bar-${paragraafIndex}-${subparagraafIndex}`}>
        <EditorAddCompact.Button
          icon={RegularHeading}
          isLoading={createSubparagraaf.isPending}
          data-testid="add-item-button-lege-subparagraaf"
          onClick={() => {
            createSubparagraaf.mutate(
              {
                type: 'subparagrafen',
                besluitId: paragraafLink.PHB.Besluit,
                paragraafId: paragraafLink.PHB.Paragraaf,
                previousRang: previous?.SPPB.Rang,
                nextRang: next?.SPPB.Rang,
                subparagrafen: [{ titel: '' }],
              },
              {
                onSuccess: handleSuccess,
                onError: handleError,
              }
            );
          }}
        >
          Lege subparagraaf
        </EditorAddCompact.Button>
      </EditorAddCompact>

      {error && (
        <div className="w-full">
          <WarningInline description={error} center />
        </div>
      )}
    </>
  );
};
