import { MutationKey, useQueryClient } from '@tanstack/react-query';

import {
  PatchFixSubparagraafIdMutationError,
  usePatchFixSubparagraafId,
} from '@/api/generated/digiVAPI';
import { SubparagraafSchema } from '@/api/generated/digiVAPI.schemas';

import { useSubparagraaf, useSubparagraafId } from '../subparagraaf';

type Variables = NonNullable<ReturnType<typeof usePatchFixSubparagraafId>['variables']>;
type Error = PatchFixSubparagraafIdMutationError;

export function usePatchSubparagraafId({
  mutationKey,
  onSuccess,
  onError,
}: {
  mutationKey?: MutationKey;
  onSuccess?: () => void;
  onError?: (error: Error, variables: Variables) => void;
} = {}) {
  const queryClient = useQueryClient();

  return usePatchFixSubparagraafId({
    mutation: {
      mutationKey,
      onSuccess: async (subparagraaf) => {
        queryClient.setQueryData<SubparagraafSchema>(
          useSubparagraafId.getKey(subparagraaf.ID!),
          subparagraaf
        );

        await queryClient.invalidateQueries({ queryKey: useSubparagraaf.key });

        onSuccess?.();
      },
      onError: (error, variables) => {
        onError?.(error, variables);
      },
    },
  });
}
