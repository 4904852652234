import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDParagraafSchema } from '@/api';
import { StdObjects, useCreateStdParagraaf } from '@/api/queries/std';
import { FormLabel, Input, Modal, TextEditor, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';

export interface StandaardtekstenToevoegenParagraafProps {
  sectieId: string;
  paragraafIndex?: number;
  stdObjects: StdObjects;
  onSubmitSuccess?: () => void;
}

type FormValues = Pick<STDParagraafSchema, 'Nummer' | 'Titel' | 'Toelichting'>;

export const STANDAARDTEKSTEN_TOEVOEGEN_PARAGRAAF_ID = 'standaardtekstenToevoegenParagraaf';

export const StandaardtekstenToevoegenParagraaf = modal(
  STANDAARDTEKSTEN_TOEVOEGEN_PARAGRAAF_ID,
  ({ data, props }) => {
    const modalState = useModalState();
    const { sectieId, paragraafIndex, stdObjects, onSubmitSuccess } = data;

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>();

    const createStdParagraaf = useCreateStdParagraaf();

    const onSubmit: SubmitHandler<FormValues> = ({ Nummer, Titel, Toelichting }) => {
      modalState.action('save');
      createStdParagraaf.mutate(
        {
          Nummer,
          Toelichting,
          paragraafIndex: paragraafIndex ?? null,
          sectieId,
          stdObjects,
          Titel,
        },
        {
          onSuccess: () => {
            modalState.success('save');
            onSubmitSuccess?.();
          },
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Paragraaf toevoegen">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Nummer', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
            error={errors.Nummer}
            label="Nummer"
            id="nieuwe-std-paragraaf-nummer"
            required
          />
          <FormLabel htmlFor="nieuwe-std-paragraaf-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                ]}
                initialText={Toelichting}
                id="nieuwe-std-paragraaf-toelichting"
                testId="new-object-std-paragraaf-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />

          <FormLabel htmlFor="nieuwe-std-paragraaf-title" required>
            Titel (titel van de paragraaf)
          </FormLabel>
          <Controller
            control={control}
            name="Titel"
            rules={{ required: { value: true, message: eStrings.TXT_VERPLICHT_INPUT_VELD } }}
            render={({ field: { onChange, value: Titel } }) => (
              <TextEditor
                menuOptions={['superscript', 'subscript', 'highlight']}
                error={errors.Titel}
                initialText={Titel}
                id="nieuwe-std-paragraaf-title"
                testId="new-object-std-paragraaf-title"
                variant="input"
                singleLine
                onUpdate={onChange}
              />
            )}
          />
          <Modal.Footer>
            <Modal.CancelButton />

            <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
              Opslaan
            </Modal.ActionButton>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
