import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512" ref={svgRef} {...props}><path d="M16 56c0-13.3 10.7-24 24-24h96c13.3 0 24 10.7 24 24s-10.7 24-24 24h-24v144c0 61.9 50.1 112 112 112s112-50.1 112-112V80h-24c-13.3 0-24-10.7-24-24s10.7-24 24-24h96c13.3 0 24 10.7 24 24s-10.7 24-24 24h-24v144c0 88.4-71.6 160-160 160S64 312.4 64 224V80H40c-13.3 0-24-10.7-24-24zM0 456c0-13.3 10.7-24 24-24h400c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24z" /></svg>;
});
SVGIcon.displayName = "RegularUnderline";
export default SVGIcon;