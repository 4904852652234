import { ComponentPropsWithRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconComponent } from '@/icons/iconTypes';

export interface IconButtonProps extends ComponentPropsWithRef<'button'> {
  icon: IconComponent;
  ariaLabel: string;
  variant?: 'default' | 'white' | 'white-ghost';
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, ariaLabel, className, variant = 'default', ...props }, ref) => {
    const IconComponent = icon;
    let variantClasses = '';

    switch (variant) {
      case 'white':
        variantClasses =
          'text-theme-blue bg-white ring-inset ring-1 ring-gray-200 hover:bg-gray-200 focus-visible:border-theme-blue/60';
        break;
      case 'white-ghost':
        variantClasses =
          'text-white bg-transparent ring-white ring-inset ring-1 hover:bg-white hover:text-theme-blue focus-visible:bg-white focus-visible:text-theme-blue';
        break;
      default:
        variantClasses = 'text-theme-blue hover:bg-gray-200 focus-visible:border-theme-blue/60';
        break;
    }

    return (
      <button
        ref={ref}
        className={twMerge(
          'flex h-6 w-6 items-center justify-center rounded-md',
          'transition-colors duration-150 ease-in-out',
          'focus-visible:outline-none focus-visible:ring',
          variantClasses,
          className
        )}
        aria-label={ariaLabel}
        {...props}
      >
        <IconComponent size={16} className="block" />
      </button>
    );
  }
);
