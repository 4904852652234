import { useQueryClient } from '@tanstack/react-query';

import { useDeleteFixParagraafId } from '@/api/generated/digiVAPI';

import { useLinksInHoofdstuk } from '../links';
import { useParagraaf } from '../paragraaf';
import { useSubparagraaf } from '../subparagraaf';
import { useVoorschrift } from '../voorschrift';

export function useDeleteParagraafId() {
  const queryClient = useQueryClient();

  return useDeleteFixParagraafId({
    mutation: {
      onSuccess: () => {
        /**
         * When a paragraaf is deleted, all child objects are deleted as well.
         * We'll need to invalidate those queries as well.
         */
        return Promise.all([
          queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key }),
          queryClient.invalidateQueries({ queryKey: useParagraaf.key }),
          queryClient.invalidateQueries({ queryKey: useSubparagraaf.key }),
          queryClient.invalidateQueries({ queryKey: useVoorschrift.key }),
        ]);
      },
    },
  });
}
