import { useQuery } from '@tanstack/react-query';
import { Link, createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { LabelMapSchema } from '@/api';
import { useLabel, useLabelMapId } from '@/api/queries/label';
import { Explorer } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { useOpenModal } from '@/modals/utils';

import { ExplorerButtonLabel } from './-components/ExplorerButtonLabel';
import { SidebarLayout } from './-components/SidebarLayout';

const searchSchema = z.object({
  query: z.string().optional(),
  p: z.number().optional().default(1),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const labelMapIdQueryOptions = (nestedMapId: string) => useLabelMapId.getOptions(nestedMapId);

const labelQueryOptions = (nestedMapId: string, search: SearchParams) => {
  return useLabel.getOptions({
    size: 20,
    page: search.p,
    filter: [
      {
        model: 'Label',
        field: 'Map',
        value: nestedMapId,
        op: 'eq',
      },
    ],
    sort: [
      {
        model: 'Label',
        field: 'Label',
        direction: 'asc',
      },
    ],
  });
};

/**
 * Route
 */
export const Route = createFileRoute('/beheer/labels/$mapId/$nestedMapId')({
  validateSearch: searchSchema.parse,
  beforeLoad: async ({ context, params, navigate }) => {
    /**
     * TEMPORARY REDIRECT UNTIL DB SYNC PROPERLY WORKS
     **/
    navigate({ to: '/', search: { p: 1 } });

    return {
      getTitle: () => {
        const { queryKey } = labelMapIdQueryOptions(params.nestedMapId);

        return context.queryClient.getQueryData<LabelMapSchema>(queryKey)?.Naam;
      },
    };
  },
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { queryClient }, params: { mapId, nestedMapId }, deps }) => {
    const [labels, nestedMap] = await Promise.all([
      queryClient.ensureQueryData(labelQueryOptions(nestedMapId, deps.search)),
      queryClient.ensureQueryData(labelMapIdQueryOptions(nestedMapId)),
    ]);

    if (!nestedMap) throw notFound();
    if (!labels.num_pages) return;

    if (labels.num_pages < deps.search.p) {
      throw redirect({
        to: '/beheer/labels/$mapId/$nestedMapId',
        search: { p: labels.num_pages },
        params: { mapId: mapId, nestedMapId: nestedMapId },
      });
    }
  },
  component: LabelsComponent,
});

/**
 * Route component
 */
function LabelsComponent() {
  const { mapId, nestedMapId } = Route.useParams();

  const openModal = useOpenModal();

  const search = Route.useSearch();

  const map = useQuery(labelMapIdQueryOptions(nestedMapId));
  const labels = useQuery(labelQueryOptions(nestedMapId, search));

  if (!map.data || !labels.data) return null;

  return (
    <SidebarLayout>
      <Explorer title={map.data.Naam}>
        <Explorer.ItemHeading
          title="Labels"
          buttons={[
            {
              key: 'new-label',
              label: 'Nieuw label',
              variant: 'white',
              onClick: () => {
                openModal('beheerLabel', {
                  createLabel: { mapId: nestedMapId },
                });
              },
            },
          ]}
        />

        {!labels.data.objects?.length ? (
          <Explorer.Placeholder>Deze map heeft geen labels</Explorer.Placeholder>
        ) : (
          <>
            <ul>
              {labels.data.objects.map((label) => (
                <li key={`label-button-${label.ID}`}>
                  <ExplorerButtonLabel label={label} />
                </li>
              ))}
            </ul>

            {(labels.data?.num_pages ?? 0) > 1 ? (
              <div className="mt-8 flex w-full justify-center">
                <PaginationLinks
                  renderLink={({ children, page }) => (
                    <Link
                      to="/beheer/labels/$mapId/$nestedMapId"
                      params={{ mapId, nestedMapId }}
                      search={{ p: page }}
                    >
                      {children}
                    </Link>
                  )}
                  currentPage={search.p}
                  totalPages={labels.data?.num_pages ?? 0}
                />
              </div>
            ) : null}
          </>
        )}
      </Explorer>
    </SidebarLayout>
  );
}
