import { useQuery } from '@tanstack/react-query';
import { Link, Outlet, createFileRoute, notFound } from '@tanstack/react-router';
import { Fragment } from 'react';
import { z } from 'zod';

import { STDMapSchema } from '@/api';
import { usePatchStdMapId, useStdMap, useStdMapId } from '@/api/queries/std';
import { Button, Explorer } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { useOpenModal } from '@/modals/utils';
import { useIsExactMatch } from '@/utils/hooks/useIsExactMatch';

import { SidebarLayout } from './-components/SidebarLayout';

const searchSchema = z.object({
  p: z.number().optional().default(1),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const sectiesQueryOptions = (search: SearchParams, mapId: string) => {
  return useStdMap.getOptions({
    page: search.p,
    size: 20,
    sort: [{ field: 'Naam', direction: 'asc' }],
    filter: [
      {
        field: 'Map',
        op: 'eq',
        value: mapId,
      },
    ],
  });
};

/**
 * Route
 */
export const Route = createFileRoute('/beheer/standaardteksten/$mapId')({
  validateSearch: searchSchema.parse,
  loaderDeps: ({ search }) => ({ search }),
  beforeLoad: ({ context, params }) => ({
    getTitle: () => {
      const map = context.queryClient.getQueryData<STDMapSchema>(useStdMapId.getKey(params.mapId));

      return map?.Naam;
    },
  }),
  loader: async ({ context, params, deps: { search } }) => {
    const [map] = await Promise.all([
      context.queryClient.ensureQueryData(useStdMapId.getOptions(params.mapId)),
      context.queryClient.ensureQueryData(sectiesQueryOptions(search, params.mapId)),
    ]);

    if (!map) throw notFound();
  },
  component: SectiesComponent,
});

/**
 * Route component
 */
function SectiesComponent() {
  const search = Route.useSearch();
  const { mapId } = Route.useParams();
  const openModal = useOpenModal();

  const secties = useQuery(sectiesQueryOptions(search, mapId));
  const map = useStdMapId(mapId);

  const patchMapId = usePatchStdMapId();

  /**
   * NOTE: Temporary solution for breadcrumbs
   *
   * When the route is not exactly this route, render the outlet
   */
  const isExactMatch = useIsExactMatch(Route.fullPath);

  if (!isExactMatch) {
    return <Outlet />;
  }

  if (!secties.data || !map.data) return null;

  return (
    <SidebarLayout testId={Route.fullPath}>
      <Explorer
        title={
          <>
            {map.data.Naam}
            {map.data.Status === 'Archief' ? (
              <span className="font-normal">{` `}(onzichtbaar voor gebruikers)</span>
            ) : (
              ''
            )}
          </>
        }
        showButton
        buttonProps={{
          variant: 'white',
          onClick: () => {
            patchMapId.mutate({
              id: mapId,
              data: { Status: map.data.Status === 'Archief' ? 'Actief' : 'Archief' },
            });
          },
          children: map.data.Status === 'Archief' ? 'Maak map zichtbaar' : 'Maak map onzichtbaar',
        }}
      >
        {!secties.data.objects?.length ? (
          <Explorer.Placeholder>Deze map heeft geen secties</Explorer.Placeholder>
        ) : (
          secties.data.objects.map((sectie) => (
            <Fragment key={`sectie-${sectie.ID}`}>
              <Explorer.ItemHeading
                infoCardProps={
                  sectie.Toelichting
                    ? { description: sectie.Toelichting, testId: `${sectie.Naam}-toelichting` }
                    : undefined
                }
                inactive={sectie.Status === 'Archief'}
                title={
                  <>
                    {sectie.Naam}
                    {sectie.Status === 'Archief' ? (
                      <span>{` `}(onzichtbaar voor gebruikers)</span>
                    ) : (
                      ''
                    )}
                  </>
                }
                options={[
                  {
                    variant: 'button',
                    title: 'Wijzigen',
                    onClick: () => {
                      openModal('standaardtekstenEditSectie', { sectie });
                    },
                  },
                  {
                    variant: 'button',
                    title: sectie.Status === 'Archief' ? 'Zichtbaar maken' : 'Onzichtbaar maken',
                    onClick: () => {
                      patchMapId.mutate({
                        id: sectie.ID!,
                        data: { Status: sectie.Status === 'Archief' ? 'Actief' : 'Archief' },
                      });
                    },
                  },
                ]}
              />
              <Explorer.Button asChild icon="file" inactive={sectie.Status === 'Archief'}>
                <Link
                  to="/beheer/standaardteksten/$mapId/$sectieId/voorschriften"
                  params={{ mapId, sectieId: sectie.ID! }}
                  search={{ p: 1 }}
                >
                  Voorschriften
                </Link>
              </Explorer.Button>

              <Explorer.Button icon="file" asChild inactive={sectie.Status === 'Archief'}>
                <Link
                  to="/beheer/standaardteksten/$mapId/$sectieId/inhoudelijke-overwegingen"
                  params={{ mapId, sectieId: sectie.ID! }}
                  search={{ p: 1 }}
                >
                  Inhoudelijke overwegingen
                </Link>
              </Explorer.Button>
            </Fragment>
          ))
        )}
      </Explorer>

      {(secties.data.num_pages ?? 0) > 1 ? (
        <div className="mt-6 flex justify-center">
          <PaginationLinks
            renderLink={({ children, page }) => (
              <Link to="/beheer/standaardteksten/$mapId" search={{ p: page }} params={{ mapId }}>
                {children}
              </Link>
            )}
            currentPage={search.p}
            totalPages={secties.data.num_pages ?? 0}
          />
        </div>
      ) : null}

      <Button
        className="mt-10"
        variant="white"
        onClick={() => openModal('standaardtekstenToevoegenSectie', { mapId })}
      >
        Nieuwe sectie
      </Button>
    </SidebarLayout>
  );
}
