import { getOthBesluitType } from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

// Data is not expected to change, so it can be cached for a long time
const STALE_TIME = Infinity;

export const useBesluitType = createQuery({
  key: ['besluitType'],
  queryFn: () => getOthBesluitType(),
  defaultOptions: {
    staleTime: STALE_TIME,
  },
});
