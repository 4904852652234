import * as Popover from '@radix-ui/react-popover';
import { ReactNode } from 'react';

import { OptionsMenuButton } from './components/OptionsMenuButton';
import { OptionsMenuItems } from './components/OptionsMenuItems';

interface OptionsMenuProps {
  children: ReactNode;
}

interface OptionsMenuButtonOption {
  variant: 'button';
  title: string;
  onClick?: () => void;
}

interface OptionsMenuLinkOption {
  variant: 'link';
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linkProps?: any;
}

export type OptionsMenuOption = OptionsMenuButtonOption | OptionsMenuLinkOption;

export const OptionsMenu = ({ children }: OptionsMenuProps) => {
  return <Popover.Root>{children}</Popover.Root>;
};

OptionsMenu.Button = OptionsMenuButton;
OptionsMenu.Items = OptionsMenuItems;
