import { useQuery } from '@tanstack/react-query';

import { HoofdstukSchema } from '@/api/generated/digiVAPI.schemas';

import { useHoofdstuk } from '../hoofdstuk';

type HoofdstukkenWithOverwegingProps = {
  besluitId: string;
};

/**
 * Returns a list of hoofdstukken that have an Inhoudelijke overweging
 */
const useHoofdstukkenWithOverweging = (props: HoofdstukkenWithOverwegingProps) => {
  const overwegingHoofdstukken = useQuery(getHoofdstukkenWithOverwegingQueryOptions(props));
  return overwegingHoofdstukken;
};

const getHoofdstukkenWithOverwegingQueryOptions = ({
  besluitId,
}: HoofdstukkenWithOverwegingProps) => {
  return useHoofdstuk.getOptions(
    {
      page: 1,
      size: 100,
      filter: [
        { model: 'OverwegingHoofdstuk', field: 'Besluit', op: 'eq', value: besluitId },
        { model: 'HB', field: 'Besluit', op: 'eq', value: besluitId },
      ],
      // Sort by Besluit_Datum descending, to get the most recent hoofdstuk first.
      // This is necessary for the the filter in select below
      sort: [{ model: 'Besluit', field: 'Besluit_Datum', direction: 'desc' }],
    },
    {
      select: ({ objects }) => {
        // Because inhoudelijke overweging is linked to a hoofdstuk lineage, we could get multiple hoofdstukken in the same lineage.
        // We only want to show one hoofdstuk per lineage, so we use a map to filter out duplicates.
        const map = new Map<string, HoofdstukSchema>();

        objects?.forEach((hoofdstuk) => map.set(hoofdstuk.Lineage, hoofdstuk));

        return [...map.values()].sort((a, b) => a.Nummer - b.Nummer);
      },
    }
  );
};

useHoofdstukkenWithOverweging.getOptions = getHoofdstukkenWithOverwegingQueryOptions;
export { useHoofdstukkenWithOverweging };
