import * as Popover from '@radix-ui/react-popover';
import { PopperContentProps } from '@radix-ui/react-popover';
import { Link } from '@tanstack/react-router';

import { TextButton } from '@/components';

import { OptionsMenuOption } from '..';

export interface OptionsMenuItemsProps {
  options: OptionsMenuOption[];
  align?: PopperContentProps['align'];
  side?: PopperContentProps['side'];
}

export function OptionsMenuItems({
  options,
  align = 'center',
  side = 'bottom',
}: OptionsMenuItemsProps) {
  return (
    <Popover.Portal>
      <Popover.Content align={align} side={side} className="shadow-popover z-20 rounded bg-white">
        <ul className="flex flex-col py-1">
          {options.map(
            (option) =>
              (option.variant === 'link' && (
                <li key={`options-menu-${option.title}`} className="py-2 pl-2 pr-4">
                  {option.linkProps ? (
                    <TextButton asChild>
                      <Link {...option.linkProps}>{option.title}</Link>
                    </TextButton>
                  ) : (
                    <p className="w-full whitespace-nowrap px-2 py-1">{option.title}</p>
                  )}
                </li>
              )) ||
              (option.variant === 'button' && (
                <li
                  key={`options-menu-${option.title}`}
                  className="border-b border-b-gray-200 last:border-b-0"
                >
                  {option.onClick ? (
                    <button
                      onClick={option.onClick}
                      className="w-full whitespace-nowrap px-2 py-1 hover:bg-theme-blue/20"
                    >
                      {option.title}
                    </button>
                  ) : (
                    <p className="w-full whitespace-nowrap px-2 py-1">{option.title}</p>
                  )}
                </li>
              ))
          )}
        </ul>

        <Popover.Arrow className="fill-white" width={16} height={8} />
      </Popover.Content>
    </Popover.Portal>
  );
}
