import { useQuery } from '@tanstack/react-query';

import { useOverwegingHoofdstuk } from '../overwegingHoofdstuk';

type QueryParams = {
  besluitId: string;
  hoofdstukLineageId: string;
};

const queryOptions = (
  { besluitId, hoofdstukLineageId }: QueryParams,
  options: { enabled?: boolean } = {}
) => {
  return useOverwegingHoofdstuk.getOptions(
    {
      page: 1,
      size: 1,
      filter: [
        {
          field: 'Besluit',
          op: 'eq',
          value: besluitId,
        },
        {
          field: 'Hoofdstuk_lineage',
          op: 'eq',
          value: hoofdstukLineageId,
        },
      ],
    },
    {
      select: ({ objects }) => objects?.[0],
      ...options,
    }
  );
};

const useOverwegingByHoofdstukLineageId = (
  params: QueryParams,
  options: { enabled?: boolean } = {}
) => useQuery(queryOptions(params, options));
useOverwegingByHoofdstukLineageId.queryOptions = queryOptions;

export { useOverwegingByHoofdstukLineageId };
