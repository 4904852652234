import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512" ref={svgRef} {...props}><path d="M48 88c0-13.3-10.7-24-24-24S0 74.7 0 88v336c0 13.3 10.7 24 24 24s24-10.7 24-24V272h224v152c0 13.3 10.7 24 24 24s24-10.7 24-24V88c0-13.3-10.7-24-24-24s-24 10.7-24 24v136H48V88zm397.3 40.8C457.9 118 474 112 490.7 112h9.2c42.1 0 76.2 34.1 76.2 76.2 0 21.3-8.9 41.5-24.5 56L375.7 406.4c-7.3 6.7-9.7 17.2-6.1 26.4s12.5 15.3 22.4 15.3h224c13.3 0 24-10.7 24-24s-10.7-24-24-24H453.4l130.7-120.7c25.5-23.5 39.9-56.6 39.9-91.2C624 119.6 568.4 64 499.8 64h-9.2c-28.1 0-55.3 10.1-76.6 28.3l-29.7 25.4c-10.1 8.6-11.2 23.8-2.6 33.8s23.8 11.2 33.8 2.6l29.7-25.4z" /></svg>;
});
SVGIcon.displayName = "RegularH2";
export default SVGIcon;