import {
  postFixLineageSubparagraaf,
  postFixLinkSppb,
  postFixSubparagraaf,
} from '@/api/generated/digiVAPI';
import {
  SPPBSchema,
  SubparagraafLineageSchema,
  SubparagraafSchema,
} from '@/api/generated/digiVAPI.schemas';
import { getRangBetween } from '@/utils/getRangBetween';

import { CreateSubparagrafenProps } from '../useCreateObjects';
import { CreateVoorschriftResponse, createVoorschriften } from './createVoorschrift';

export type CreateSubparagraafResponse = {
  lineageSubparagraaf: SubparagraafLineageSchema;
  subparagraaf: SubparagraafSchema;
  sppb: SPPBSchema;
};

export async function createSubparagrafen({
  besluitId,
  besluitLineageId,
  paragraafId,
  milieuGrondslagId,
  subparagrafen,
  previousRang,
  nextRang,
  transparent,
}: CreateSubparagrafenProps & {
  milieuGrondslagId: string;
  besluitLineageId: string;
  transparent?: boolean;
}) {
  const subparagraafResponses: CreateSubparagraafResponse[] = [];
  const voorschriftResponses: CreateVoorschriftResponse[] = [];

  let previousSubparagraafResponse: CreateSubparagraafResponse | undefined;

  for (const subparagraafProps of subparagrafen) {
    const subparagraafResponse = await createSubparagraaf({
      besluitId,
      besluitLineageId,
      paragraafId,
      milieuGrondslagId,
      previousRang: previousSubparagraafResponse?.sppb.Rang || previousRang,
      nextRang: nextRang,
      titel: subparagraafProps.titel ?? '',
      transparent,
    });

    subparagraafResponses.push(subparagraafResponse);

    if (subparagraafProps.voorschriften?.length) {
      const voorschriften = await createVoorschriften({
        besluitId,
        besluitLineageId,
        milieuGrondslagId,
        subparagraafId: subparagraafResponse.subparagraaf.ID!,
        type: 'voorschriften',
        voorschriften: subparagraafProps.voorschriften,
      });

      voorschriftResponses.push(...voorschriften.voorschriftResponses);
    }

    previousSubparagraafResponse = subparagraafResponse;
  }

  return {
    subparagraafResponses,
    voorschriftResponses,
  };
}

export const createSubparagraaf = async ({
  titel,
  milieuGrondslagId,
  besluitId,
  besluitLineageId,
  paragraafId,
  previousRang,
  nextRang,
  transparent,
}: {
  milieuGrondslagId: string;
  titel: string;
  besluitId: string;
  besluitLineageId: string;
  paragraafId: string;
  previousRang?: string;
  nextRang?: string;
  transparent?: boolean;
}): Promise<CreateSubparagraafResponse> => {
  /**
   * Create lineage paragraaf
   */
  const lineageSubparagraaf = await postFixLineageSubparagraaf({
    Besluit_lineage: besluitLineageId,
  });

  if (!lineageSubparagraaf.ID) {
    throw new Error('No lineage subparagraaf ID');
  }

  /**
   * Create Paragraaf
   */
  const subparagraaf = await postFixSubparagraaf({
    Grondslag: milieuGrondslagId,
    Lineage: lineageSubparagraaf.ID,
    Ontwerp: 1,
    Transparent: transparent ? 1 : 0,
    Stop_Lineage: 0,
    Titel: titel,
    Index: 0,
  });

  if (!subparagraaf.ID) {
    throw new Error('No subparagraaf ID');
  }

  /**
   * Create PHB
   */
  const newRang = getRangBetween(previousRang, nextRang);

  const sppb = await postFixLinkSppb({
    Besluit: besluitId,
    Paragraaf: paragraafId,
    Subparagraaf: subparagraaf.ID,
    Rang: newRang,
    Type: 'Eigen',
  });

  return {
    lineageSubparagraaf,
    subparagraaf,
    sppb,
  };
};
