import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { useLayoutEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { DocumentTitle } from '@/components';
import { eStrings } from '@/constants/strings';
import { queryClient } from '@/services/queryClient';
import { router } from '@/services/router/router';
import { useUserStore } from '@/stores/user';

import './styles/generated/tailwind.css';
import './styles/styles.scss';

export const App = () => {
  const loadLoggedInUser = useUserStore(({ loadLoggedInUser }) => loadLoggedInUser);

  useLayoutEffect(() => {
    loadLoggedInUser();
  }, [loadLoggedInUser]);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />

        <DocumentTitle sections={[eStrings.LBL_DIGI_V]} />

        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      </QueryClientProvider>
    </HelmetProvider>
  );
};
