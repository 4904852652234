import { STDVoorschriftSchema } from '@/api';
import { useDeleteStdVoorschriftId } from '@/api/queries/std';
import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';

export type StandaardtekstenVerwijderenVoorschriftProps = {
  voorschrift: STDVoorschriftSchema;
};

export const STANDAARDTEKSTEN_VERWIJDEREN_VOORSCHRIFT_ID = 'standaardtekstenVerwijderenVoorschrift';

export const StandaardtekstenVerwijderenVoorschrift = modal(
  STANDAARDTEKSTEN_VERWIJDEREN_VOORSCHRIFT_ID,
  ({ data, props }) => {
    const modalState = useModalState();

    const deleteStdVoorschrift = useDeleteStdVoorschriftId();

    const {
      voorschrift: { ID, Nummer },
    } = data;

    const verwijderVoorschrift = () => {
      modalState.action('delete');
      deleteStdVoorschrift.mutate(
        {
          id: ID!,
        },
        {
          onSuccess: () => {
            modalState.success('delete');
          },
          onError: (error) => {
            modalState.error('delete', {
              title: 'Mislukt',
              description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
            });
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title={`Voorschrift ${Nummer} verwijderen`}>
        <p className="text-gray-800">
          Je staat op het punt om <strong>voorschrift {Nummer}</strong> te verwijderen. Deze actie
          kan niet ongedaan gemaakt worden.
        </p>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="delete"
            successLabel="Verwijderd"
            variant="red"
            onClick={verwijderVoorschrift}
          >
            Verwijderen
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
