import { useNavigate } from '@tanstack/react-router';

import { useCreateNewBesluit } from '@/api/queries/besluit';
import { BesluitCard, Button } from '@/components';
import { RegularFilePlus } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';

interface WijzigingCardNewProps {
  bedrijfLineageId: string;
}

export const WijzigingCardNew = ({ bedrijfLineageId }: WijzigingCardNewProps) => {
  const openModal = useOpenModal();
  const navigate = useNavigate();

  const createNewBesluit = useCreateNewBesluit();

  return (
    <>
      <BesluitCard icon={RegularFilePlus} dashedBorder className="flex-1">
        <BesluitCard.Body>
          <BesluitCard.Heading
            title="Wijziging zonder besluit"
            subtitle="Maak een wijziging aan zoals een 'Besluit van de rechter'"
          />
          <BesluitCard.ButtonRow>
            <Button
              variant="white"
              data-testid="besluit-card-new-wijziging"
              onClick={() =>
                openModal('wijziging', {
                  title: 'Nieuwe wijziging',
                  defaultValues: {
                    Ontwerp_Beschikking: 0,
                    Besluit_Tekst: '',
                    Algemene_Overweging: '',
                    Duiding: '',
                  },
                  onSubmit: async (formValues) => {
                    try {
                      const { besluit } = await createNewBesluit.mutateAsync({
                        besluit: formValues,
                        bedrijfLineageId,
                      });

                      if (besluit?.ID) {
                        navigate({
                          to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/hoofdstuk',
                          params: {
                            besluitId: besluit.ID,
                            bedrijfLineageId,
                          },
                        });
                      }
                    } catch {
                      throw new Error('Besluit aanmaken mislukt');
                    }
                  },
                  submitLabel: 'Wijziging aanmaken',
                  submitSuccessLabel: 'Wijziging aangemaakt',
                  bedrijfLineageId,
                })
              }
            >
              Wijziging aanmaken
            </Button>
          </BesluitCard.ButtonRow>
        </BesluitCard.Body>
      </BesluitCard>
    </>
  );
};
