import { createContext, useContext } from 'react';

import { SubparagraafSchema } from '@/api';
import { LinkSubparagraaf, Links } from '@/api/queries/objects';
import { ObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';

export const SubparagraafContext = createContext<{
  objectState: ObjectState;
  subparagraaf: SubparagraafSchema;
  link: LinkSubparagraaf;
  links: Links;
  userHasReservering: boolean;
  editorMode: EditorMode;
} | null>(null);

export const useSubparagraafContext = () => {
  const context = useContext(SubparagraafContext);

  if (!context) {
    throw new Error('useSubparagraafContext must be used within a SubparagraafContextProvider');
  }

  return context;
};
