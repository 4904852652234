import { Node, mergeAttributes, wrappingInputRule } from '@tiptap/core';
import TextStyle from '@tiptap/extension-text-style';

import { ListItem } from './ListItem';

/**
 * @see https://www.tiptap.dev/api/nodes/ordered-list
 *
 * Fork of the original OrderedList.ts file from the tiptap library. Extended with abc/123 list types.
 */

export interface OrderedListOptions {
  itemTypeName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HTMLAttributes: Record<string, any>;
  keepMarks: boolean;
  keepAttributes: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    orderedList: {
      /**
       * Toggle an ordered list
       * @example editor.commands.toggleOrderedList()
       */
      toggleOrderedList: () => ReturnType;
      setAbcList: () => ReturnType;
      set123List: () => ReturnType;
    };
  }
}

/**
 * Matches an ordered list to a 1. on input (or any number followed by a dot).
 */
export const inputRegex = /^(\d+)\.\s$/;

export const OrderedList = Node.create<OrderedListOptions>({
  name: 'orderedList',

  addOptions() {
    return {
      itemTypeName: 'listItem',
      HTMLAttributes: {},
      keepMarks: false,
      keepAttributes: false,
    };
  },

  group: 'block list',

  content() {
    return `${this.options.itemTypeName}+`;
  },

  addAttributes() {
    return {
      start: {
        default: 1,
        parseHTML: (element) => {
          return element.hasAttribute('start')
            ? parseInt(element.getAttribute('start') || '', 10)
            : 1;
        },
      },
      'data-list-type': {
        default: '123',
        parseHTML: (element) => {
          return element.hasAttribute('data-list-type')
            ? element.getAttribute('data-list-type')
            : '123';
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'ol',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { start, ...attributesWithoutStart } = HTMLAttributes;

    return start === 1
      ? ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0]
      : ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      toggleOrderedList:
        () =>
        ({ commands, chain }) => {
          if (this.options.keepAttributes) {
            return chain()
              .toggleList(this.name, this.options.itemTypeName, this.options.keepMarks)
              .updateAttributes(ListItem.name, this.editor.getAttributes(TextStyle.name))
              .run();
          }
          return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks);
        },
      setAbcList:
        () =>
        ({ chain }) => {
          return chain().updateAttributes(this.name, { 'data-list-type': 'abc' }).run();
        },
      set123List:
        () =>
        ({ chain }) => {
          return chain().updateAttributes(this.name, { 'data-list-type': '123' }).run();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-7': () => this.editor.commands.toggleOrderedList(),
    };
  },

  addInputRules() {
    let inputRule = wrappingInputRule({
      find: inputRegex,
      type: this.type,
      getAttributes: (match) => ({ start: +match[1]! }),
      joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1]!,
    });

    if (this.options.keepMarks || this.options.keepAttributes) {
      inputRule = wrappingInputRule({
        find: inputRegex,
        type: this.type,
        keepMarks: this.options.keepMarks,
        keepAttributes: this.options.keepAttributes,
        getAttributes: (match) => ({
          start: +match[1]!,
          ...this.editor.getAttributes(TextStyle.name),
        }),
        joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1]!,
        editor: this.editor,
      });
    }
    return [inputRule];
  },
})!;
