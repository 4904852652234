import { STDSubparagraafSchema } from '@/api';
import { useDeleteStdSubparagraafId } from '@/api/queries/std';
import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';

export type StandaardtekstenVerwijderenSubparagraafProps = {
  subparagraaf: STDSubparagraafSchema;
};

export const STANDAARDTEKSTEN_VERWIJDEREN_SUBPARAGRAAF_ID =
  'standaardtekstenVerwijderenSubparagraaf';

export const StandaardtekstenVerwijderenSubparagraaf = modal(
  STANDAARDTEKSTEN_VERWIJDEREN_SUBPARAGRAAF_ID,
  ({ data, props }) => {
    const modalState = useModalState();

    const deleteStdSubparagraaf = useDeleteStdSubparagraafId();

    const {
      subparagraaf: { Titel, ID },
    } = data;

    const verwijderSubparagraaf = () => {
      modalState.action('delete');
      deleteStdSubparagraaf.mutate(
        {
          id: ID!,
        },
        {
          onSuccess: () => {
            modalState.success('delete');
          },
          onError: (error) => {
            modalState.error('delete', {
              title: 'Mislukt',
              description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
            });
          },
        }
      );
    };

    //Remove <p> tags from titel
    const htmlTitel = Titel.replace(/<p>|<\/p>/gim, '');

    return (
      <Modal
        {...props}
        state={modalState.state}
        title={
          <span
            dangerouslySetInnerHTML={{
              __html: `Subparagraaf ${htmlTitel} verwijderen`,
            }}
          />
        }
      >
        <p
          className="text-gray-800"
          dangerouslySetInnerHTML={{
            __html: `Je staat op het punt om <strong>Subparagraaf ${htmlTitel}</strong> te verwijderen. Onderliggende voorschriften worden ook verwijderd. Deze actie kan niet ongedaan gemaakt worden.`,
          }}
        />

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="delete"
            successLabel="Verwijderd"
            variant="red"
            onClick={verwijderSubparagraaf}
          >
            Verwijderen
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
