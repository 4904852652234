import { useBesluitId } from '@/api/queries/besluit';
import { useBesluitTypeId } from '@/api/queries/besluitType';

export type EditorMode = 'standard' | 'extended';

export const useEditorMode = (besluitId: string, enabled: boolean = true) => {
  const besluitTypeId = useBesluitId(besluitId, {
    enabled,
    // Besluit.Type property and besluitType list will not change, so we can cache it forever
    gcTime: Infinity,
    select: (besluit) => besluit.Type,
  });

  const besluitType = useBesluitTypeId(besluitTypeId.data);

  if (besluitType.data?.Initieel) {
    return 'extended';
  }

  return 'standard';
};
