import {
  useDeleteHistorischBesluitLink,
  usePatchHistorischBesluitLink,
  usePostHistorischBesluitLink,
} from '@/api/queries/historischBesluit';
import { usePatchVoorschriftId } from '@/api/queries/objects';
import { useEditorStore } from '@/routes/$bedrijfLineageId.editor.$besluitId/-store/useEditorStore';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from '../EditorNavigation';
import { SidebarRightContent } from '../SidebarRight/SidebarRightContent';
import { SidebarRightHeading } from '../SidebarRight/SidebarRightHeading';
import { useVoorschriftContext } from './VoorschriftContext';
import { VoorschriftSidebarGeldigheid } from './VoorschriftSidebarGeldigheid';
import { VoorschriftSidebarHistorisch } from './VoorschriftSidebarHistorisch';
import { VoorschriftSidebarMeta } from './VoorschriftSidebarMeta';
import { VoorschriftSidebarNummer } from './VoorschriftSidebarNummer';

export type SidebarRequests =
  | 'meta'
  | 'historisch-patch'
  | 'historisch-delete'
  | 'historisch-post'
  | 'nummer';

export type SidebarError = {
  request: SidebarRequests;
  message?: string;
  tryAgain?: () => Promise<unknown>;
};

export interface VoorschriftSidebarProps {
  errors: SidebarError[];
  onSuccess: (request: SidebarRequests) => void;
  onError: (sidebarError: SidebarError) => void;
}

export const VoorschriftSidebar = ({ errors, onSuccess, onError }: VoorschriftSidebarProps) => {
  const { voorschrift, nummer } = useVoorschriftContext();

  const isSelectedObject = useEditorStore(
    (state) => state.selectedObject?.lineageId === voorschrift.Lineage
  );

  /**
   * Because the nested VoorschriftSidebar components are unmounted/mounted,
   * we need to track the errors outside of the sidebar to make sure errors are still shown when switching between voorschriften
   */

  // <VoorschriftSidebarLabels />
  const patchMetaVoorschriftId = usePatchVoorschriftId({
    mutationKey: AUTOSAVE_MUTATION_KEY,
    onSuccess: () => {
      onSuccess('meta');
    },
    onError: (error, variables) => {
      onError({
        request: 'meta',
        message: ErrorMessage.getLocalErrorMessage(error),
        tryAgain: () => patchMetaVoorschriftId.mutateAsync(variables),
      });
    },
  });

  // <VoorschriftSidebarHistorisch />
  const patchHistorischBesluitLink = usePatchHistorischBesluitLink({
    mutationKey: AUTOSAVE_MUTATION_KEY,
    onSuccess: () => {
      onSuccess('historisch-patch');
    },
    onError: (error, variables) => {
      onError({
        request: 'historisch-patch',
        message: ErrorMessage.getLocalErrorMessage(error),
        tryAgain: () => patchHistorischBesluitLink.mutateAsync(variables),
      });
    },
  });

  const deleteHistorischBesluitLink = useDeleteHistorischBesluitLink({
    mutationKey: AUTOSAVE_MUTATION_KEY,
    onSuccess: () => {
      onSuccess('historisch-delete');
    },
    onError: (error, variables) => {
      onError({
        request: 'historisch-delete',
        message: ErrorMessage.getLocalErrorMessage(error),
        tryAgain: () => deleteHistorischBesluitLink.mutateAsync(variables),
      });
    },
  });

  const postHistorischBesluitLink = usePostHistorischBesluitLink({
    mutationKey: AUTOSAVE_MUTATION_KEY,
    onSuccess: () => {
      onSuccess('historisch-post');
    },
    onError: (error, variables) => {
      onError({
        request: 'historisch-post',
        message: ErrorMessage.getLocalErrorMessage(error),
        tryAgain: () => postHistorischBesluitLink.mutateAsync(variables),
      });
    },
  });

  const patchVoorschriftnummer = usePatchVoorschriftId({
    mutationKey: AUTOSAVE_MUTATION_KEY,
    onSuccess: () => {
      onSuccess('nummer');
    },
    onError: (error) => {
      onError({
        request: 'nummer',
        message: error.response?.data?.message,
      });
    },
  });

  if (!isSelectedObject) return null;

  return (
    <SidebarRightContent>
      <SidebarRightHeading title={`Voorschrift ${nummer}`} />

      <VoorschriftSidebarNummer patchVoorschriftNummer={patchVoorschriftnummer} errors={errors} />
      {/* <VoorschriftSidebarLabels /> */}
      <VoorschriftSidebarMeta patchVoorschriftId={patchMetaVoorschriftId} errors={errors} />
      <VoorschriftSidebarHistorisch
        patchHistorischBesluitLink={patchHistorischBesluitLink}
        deleteHistorischBesluitLink={deleteHistorischBesluitLink}
        postHistorischBesluitLink={postHistorischBesluitLink}
        errors={errors}
      />
      <VoorschriftSidebarGeldigheid />
    </SidebarRightContent>
  );
};
