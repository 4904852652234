import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import { TextButton } from '@/components';
import { Accordion } from '@/components/shared/Accordion';
import { useOpenModal } from '@/modals/utils';

import { useVoorschriftContext } from './VoorschriftContext';

export const VoorschriftSidebarGeldigheid = () => {
  const { link, voorschrift, objectState } = useVoorschriftContext();

  const openModal = useOpenModal();

  const isVoorschriftEigen = link.VSPB.Type === 'Eigen';

  return (
    <Accordion defaultOpen>
      <Accordion.Button label="Geldigheid" />
      <Accordion.Panel>
        <div className="truncate text-sm" id="section-custom-dates">
          <dl className="flex flex-wrap justify-between">
            <dt className="w-1/2">Inwerkingtreding</dt>
            <dd className="w-1/2">
              {isVoorschriftEigen && voorschrift.Begin_Geldigheid
                ? format(new Date(voorschrift.Begin_Geldigheid), 'dd MMMM yyyy', {
                    locale: nl,
                  })
                : 'Gelijk aan besluit'}
            </dd>

            <dt className="w-1/2">Uitwerkingtreding</dt>
            <dd className="w-1/2">
              {isVoorschriftEigen && voorschrift.Eind_Geldigheid
                ? format(new Date(voorschrift.Eind_Geldigheid), 'dd MMMM yyyy', {
                    locale: nl,
                  })
                : 'Gelijk aan besluit'}
            </dd>
          </dl>
        </div>

        <TextButton
          onClick={() => openModal('voorschriftGeldigheid', { voorschrift, link })}
          size="small"
          className="mt-4"
          disabled={!objectState.isEditable}
        >
          Geldigheid aanpassen
        </TextButton>
      </Accordion.Panel>
    </Accordion>
  );
};
