import { useQuery } from '@tanstack/react-query';

import {
  getGetOthGrondslagQueryKey,
  getGetOthGrondslagQueryOptions,
} from '@/api/generated/digiVAPI';

export const useGrondslag = {
  get: useGetGrondslag,
  queryOptions: getGrondslagQueryOptions,
  queryKey: getGetOthGrondslagQueryKey,
};

function useGetGrondslag() {
  return useQuery(getGrondslagQueryOptions());
}

/** Grondslag will never change, so the query can be cached infinitely */
function getGrondslagQueryOptions() {
  return getGetOthGrondslagQueryOptions({
    query: {
      staleTime: Infinity,
    },
  });
}
