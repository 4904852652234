import { ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { useGebruiker } from '@/api/queries/gebruiker/useGebruiker';
import {
  useDeleteHoofdstukReservering,
  usePostHoofdstukReservering,
} from '@/api/queries/hoofdstukReservering';
import { useHoofdstukReserveringByLineageId } from '@/api/queries/hoofdstukReservering/selectors/useHoofdstukReserveringByLineageId';
import { useHoofdstukHB, useHoofdstukId } from '@/api/queries/objects';
import { FeedbackMessage, Switch, TextButton } from '@/components';
import {
  RegularLockOpen,
  RegularSpinner,
  SolidLock,
  SolidTriangleExclamation,
} from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';
import { getObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

import { useEditorStore } from '../-store/useEditorStore';

export const ReserveringBar = ({
  besluitId,
  hoofdstukId,
}: {
  besluitId: string;
  hoofdstukId: string;
}) => {
  /**
   * Fetch data
   */
  const hoofdstuk = useHoofdstukId(hoofdstukId);
  const loggedInUser = useUserStore((state) => state.user);

  const hoofdstukReservering = useHoofdstukReserveringByLineageId(hoofdstuk.data?.Lineage);
  const hoofdstukReserveringGebruiker = useGebruiker.getById(hoofdstukReservering.data?.Gebruiker);

  const gereserveerdByLoggedInUser = hoofdstukReservering.data?.Gebruiker === loggedInUser?.ID;
  const gereserveerdByOtherUser = !!(hoofdstukReservering.data && !gereserveerdByLoggedInUser);

  /** Get hoofdstuk object state */
  const HB = useHoofdstukHB({ besluitId, hoofdstukId });
  const hoofdstukState = getObjectState({
    object: hoofdstuk.data,
    linkType: HB.data?.Type,
  });

  /**
   * Mutations
   */
  const postHoofdstukReservering = usePostHoofdstukReservering();
  const deleteHoofdstukReservering = useDeleteHoofdstukReservering();

  /**
   * Render logic
   */
  if (hoofdstuk.isLoading || HB.isLoading || hoofdstukReservering.isLoading) {
    return (
      <Bar variant="loading" icon={RegularSpinner} rotateIcon>
        Hoofdstuk reservering wordt geladen...
      </Bar>
    );
  }

  if (postHoofdstukReservering.isPending) {
    return (
      <Bar variant="loading" icon={RegularSpinner} rotateIcon>
        Hoofdstuk reserveren...
      </Bar>
    );
  }

  if (deleteHoofdstukReservering.isPending) {
    return (
      <Bar variant="loading" icon={RegularSpinner} rotateIcon>
        Hoofdstukreservering wordt vrijgegeven...
      </Bar>
    );
  }

  if (gereserveerdByOtherUser) {
    return (
      <Bar variant="unavailable" icon={SolidTriangleExclamation}>
        Dit hoofdstuk nr. {hoofdstuk.data?.Nummer || '-'} is gereserveerd door{' '}
        {hoofdstukReserveringGebruiker.data?.Gebruikersnaam}, daarom kan je op dit moment geen
        wijzigingen doorvoeren.
      </Bar>
    );
  }

  if (hoofdstukState.slotState === 'deleted') {
    return (
      <Bar variant="unavailable" icon={SolidTriangleExclamation}>
        Dit hoofdstuk is ingetrokken in een ander ontwerp.
      </Bar>
    );
  }

  if (hoofdstukState.slotState === 'new') {
    return (
      <Bar variant="unavailable" icon={SolidTriangleExclamation}>
        Dit hoofdstuk is toegevoegd in een ander ontwerp. Het is niet mogelijk om dit hoofdstuk nu
        te reserveren.
      </Bar>
    );
  }

  if (gereserveerdByLoggedInUser) {
    return (
      <Bar
        variant="reserved"
        icon={SolidLock}
        renderSwitch
        button={{
          label: 'Vrijgeven',
          id: 'reservering-vrijgeven',
          onClick: () => {
            deleteHoofdstukReservering.mutate(
              {
                id: hoofdstukReservering.data?.ID || '',
              },
              {
                onError: (error) => {
                  const errorMessage = ErrorMessage.getLocalErrorMessage(error);

                  if (errorMessage) {
                    FeedbackMessage('error', errorMessage);
                  }
                },
              }
            );
          },
        }}
      >
        Jij hebt dit hoofdstuk gereserveerd.
      </Bar>
    );
  }

  return (
    <Bar
      variant="available"
      icon={RegularLockOpen}
      button={{
        label: 'Reserveer hoofdstuk',
        id: 'reserveer-hoofdstuk',
        onClick: () => {
          postHoofdstukReservering.mutate(
            {
              data: {
                Gebruiker: loggedInUser?.ID ?? '',
                Reservering_lineage: hoofdstuk.data?.Lineage || '',
                /**
                 * This value will be overwritten by BE, but is required in the post request model
                 */
                Geldig_Tot: new Date().toISOString().slice(0, 19),
              },
            },
            {
              onError: (error) => {
                const errorMessage = ErrorMessage.getLocalErrorMessage(error);

                if (errorMessage) {
                  FeedbackMessage('error', errorMessage);
                }
              },
            }
          );
        },
      }}
    >
      Dit hoofdstuk is niet gereserveerd, je kan geen veranderingen maken.
    </Bar>
  );
};

const Bar = ({
  children,
  button,
  icon,
  rotateIcon,
  renderSwitch,
  variant,
}: {
  children: ReactNode;
  variant: 'available' | 'reserved' | 'unavailable' | 'loading';
  button?: { label: string; id: string; onClick: () => void };
  icon?: IconComponent;
  rotateIcon?: boolean;
  renderSwitch?: boolean;
}) => {
  const IconComponent = icon;

  let variantClassName = '';

  /**
   * Note: All variant classnames have to have a border-b class to ensure the border is included in the height,
   * because the height is used to determine the top position of the sidebars.
   */
  switch (variant) {
    case 'available':
      variantClassName = 'bg-theme-green text-white border-b border-b-theme-green';
      break;
    case 'reserved':
      variantClassName = 'bg-white text-black border-b border-b-gray-300';
      break;
    case 'unavailable':
      variantClassName = 'bg-red-700 text-white border-b border-b-red-700';
      break;
    case 'loading':
      variantClassName = 'bg-gray-700 text-white border-b border-b-gray-700';
      break;
  }

  return (
    <section
      data-testid="lock-menu-bar"
      aria-label="Reservering bar"
      className={twMerge(
        'flex w-full justify-center border-t border-t-white px-10 py-2 text-sm',
        variantClassName
      )}
    >
      <div className="flex w-full max-w-[1100px]">
        {/* Div is only used to reserve space */}
        <div className="grow-2 w-full">{renderSwitch && <AddBarSwitch />}</div>

        <div className="flex shrink-0 grow-0 items-center">
          {IconComponent && (
            <IconComponent className={twJoin('mr-2', rotateIcon && 'animate-spin')} />
          )}

          {children}
        </div>

        <div className="grow-2 flex w-full justify-end">
          {button && (
            <TextButton variant="inherit" size="small" onClick={button.onClick} id={button.id}>
              {button.label}
            </TextButton>
          )}
        </div>
      </div>
    </section>
  );
};

const AddBarSwitch = () => {
  const { areAddBarsShown, showAddBars, hideAddBars } = useEditorStore(
    ({ areAddBarsShown, showAddBars, hideAddBars }) => ({
      areAddBarsShown,
      showAddBars,
      hideAddBars,
    })
  );

  return (
    <Switch
      enabled={areAddBarsShown}
      label="Toevoegopties tonen"
      onChange={() => {
        if (areAddBarsShown) {
          hideAddBars();
        } else {
          showAddBars();
        }
      }}
    />
  );
};
