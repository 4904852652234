import { useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from '@tanstack/react-router';
import { ReactNode } from 'react';

import { BedrijfSchema } from '@/api';
import { useLastVigerendBesluit } from '@/api/queries/besluit';
import { Select } from '@/components';
import { RegularArrowLeft } from '@/icons/components';

interface HeaderProps {
  bedrijf: BedrijfSchema;
  mode: 'besluit' | 'vigerend';
  children: ReactNode;
}

export const Header = ({ bedrijf, mode, children }: HeaderProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <header className="bg-theme-blue pb-[176px] pt-[72px] text-white">
      <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <Link
          to="/$bedrijfLineageId/bedrijf/overzicht"
          params={{
            bedrijfLineageId: bedrijf.Lineage,
          }}
          className="group flex"
          aria-label={`Ga terug naar ${bedrijf.Naam}`}
          data-testid="back-to-bedrijf-dashboard"
        >
          <div className="mr-4 mt-2 shrink-0 grow-0">
            <RegularArrowLeft
              size={16}
              className="relative text-base text-white opacity-50 transition duration-200 ease-in group-hover:opacity-100"
            />
          </div>

          <div>
            <h1 className="text-2xl font-bold">
              {mode === 'vigerend' && 'Vigerende situatie'}
              {mode === 'besluit' && 'Besluit'}
            </h1>

            <p>{bedrijf.Naam}</p>
          </div>
        </Link>

        <div className="flex gap-2">
          <Select
            id="aadv-type"
            name="aadv-type"
            onChange={async (e) => {
              if (e.target.value === 'besluit') {
                const lastVigerendBesluitResponse = await queryClient.fetchQuery(
                  useLastVigerendBesluit.getOptions({ bedrijfLineageId: bedrijf.Lineage })
                );

                const lastVigerendBesluit = lastVigerendBesluitResponse.objects?.[0];

                if (lastVigerendBesluit) {
                  navigate({
                    to: '/$bedrijfLineageId/viewer/besluit/$besluitId',
                    params: {
                      bedrijfLineageId: bedrijf.Lineage,
                      besluitId: lastVigerendBesluit.ID!,
                    },
                  });
                }
              } else {
                navigate({
                  to: '/$bedrijfLineageId/viewer/vigerend',
                  params: {
                    bedrijfLineageId: bedrijf.Lineage,
                  },
                });
              }
            }}
            value={mode}
            label="Type weergave"
            hideLabel
          >
            <option value="vigerend">Vigerende situatie</option>
            <option value="besluit">Besluit</option>
          </Select>

          {children}
        </div>
      </div>
    </header>
  );
};
