import { ReactNode } from 'react';

import { EditorRow, EditorRowProps } from '../EditorRow';

export interface EditorHoofdstukProps
  extends Pick<EditorRowProps, 'buttonProps' | 'status' | 'number' | 'testId' | 'className'> {
  content?: string;
  children?: ReactNode;
}

export const EditorHoofdstuk = ({ content, children, ...props }: EditorHoofdstukProps) => {
  return (
    <EditorRow
      {...props}
      fontClasses="text-2xl font-semibold"
      buttonTestId="editor-hoofdstuk"
      content={
        <h2
          className="text-left text-2xl font-semibold text-theme-blue"
          dangerouslySetInnerHTML={{ __html: content ?? '' }}
        />
      }
    >
      {children}
    </EditorRow>
  );
};
