import { useState } from 'react';

import {
  LinkSubparagraaf,
  useDeleteSubparagraafId,
  useSubparagraafId,
} from '@/api/queries/objects';
import { Checkbox, Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface DeleteSubparagraafProps {
  subparagraafLink: LinkSubparagraaf;
}

export const DELETE_SUBPARAGRAAF_ID = 'deleteSubparagraaf';

export const DeleteSubparagraaf = modal(DELETE_SUBPARAGRAAF_ID, ({ data, props }) => {
  const { subparagraafLink } = data;

  const [isChecked, setIsChecked] = useState(false);

  const subparagraaf = useSubparagraafId(subparagraafLink.SPPB.Subparagraaf);
  const deleteSubparagraaf = useDeleteSubparagraafId();

  const modalState = useModalState();

  const hasChildren = !!subparagraafLink.voorschriften.length;

  return (
    <Modal
      {...props}
      isLoading={subparagraaf.isLoading}
      state={modalState.state}
      title={
        <>
          Verwijderen <SubparagraafTitle title={subparagraaf.data?.Titel} />
        </>
      }
    >
      <p>
        Je staat op het punt om{' '}
        <strong>
          <SubparagraafTitle title={subparagraaf.data?.Titel} />
        </strong>{' '}
        te verwijderen
      </p>

      {hasChildren ? (
        <>
          <p>Onderliggende onderdelen zullen ook worden verwijderd</p>
          <Checkbox
            name="deleteChildrenCheckbox"
            id="deleteChildrenCheckbox"
            label={
              <>
                Ik wil <SubparagraafTitle title={subparagraaf.data?.Titel} /> inclusief
                onderliggende onderdelen verwijderen
              </>
            }
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mt-6"
            checked={isChecked}
          />
        </>
      ) : null}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="delete"
          successLabel="Verwijderd"
          variant="red"
          disabled={hasChildren && !isChecked}
          onClick={() => {
            modalState.action('delete');

            deleteSubparagraaf.mutate(
              {
                id: subparagraafLink.SPPB.Subparagraaf,
              },
              {
                onSuccess: () => {
                  modalState.success('delete');
                },
                onError: (error) => {
                  const errorMessage = ErrorMessage.getLocalErrorMessage(error);

                  if (errorMessage) {
                    modalState.error('delete', {
                      title: 'Mislukt!',
                      description: errorMessage,
                    });
                  }
                },
              }
            );
          }}
        >
          Verwijderen
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});

const SubparagraafTitle = ({ title }: { title?: string }) => {
  return (
    <>
      subparagraaf &quot;
      <span dangerouslySetInnerHTML={{ __html: title ?? '' }} />
      &quot;
    </>
  );
};
