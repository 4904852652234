import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BesluitSchema } from '@/api';
import { usePatchBesluitId } from '@/api/queries/besluit';
import { useHoofdstukReservering } from '@/api/queries/hoofdstukReservering';
import { useVoorschrift, useVoorschriftId } from '@/api/queries/objects';
import { Button, Input, Modal, useModalState } from '@/components';
import { ValidateBesluit, useValidateBesluit } from '@/components/shared/ValidateBesluit';
import { eStrings } from '@/constants/strings';
import { queryClient } from '@/services/queryClient';
import { useUserStore } from '@/stores/user';
import { DateUtils } from '@/utils/dateUtils';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface BesluitPublicerenProps {
  besluit: BesluitSchema;
  bedrijfLineageId: string;
}

export const BESLUIT_PUBLICEREN_ID = 'besluitPubliceren';

interface FormValues {
  Besluit_Datum: string;
  Ons_Kenmerk: string;
  Begin_Geldigheid: string;
}

export const BesluitPubliceren = modal(BESLUIT_PUBLICEREN_ID, ({ data, props }) => {
  const modalState = useModalState();

  const [isValidated, setIsValidated] = useState(false);

  return (
    <Modal
      {...props}
      state={modalState.state}
      title="Besluit afronden"
      afterLeave={() => {
        // Reset voorschrift queries to make sure cache is cleared.
        // This will make sure that the cached "Ontwerp" version of the voorschrift is cleared and the new "Vigerend" version is fetched.
        if (modalState.state?.type === 'success') {
          queryClient.resetQueries({ queryKey: useVoorschrift.key });
          queryClient.resetQueries({ queryKey: useVoorschriftId.key });
        }

        props.afterLeave();
      }}
    >
      {!isValidated ? (
        <ValidationBody {...data} onContinue={() => setIsValidated(true)} />
      ) : (
        <PublicerenBody {...data} modalState={modalState} />
      )}
    </Modal>
  );
});

const ValidationBody = ({
  besluit,
  bedrijfLineageId,
  onContinue,
}: BesluitPublicerenProps & { onContinue: () => void }) => {
  const validateBesluit = useValidateBesluit({ besluitId: besluit.ID ?? '', bedrijfLineageId });

  return (
    <>
      <ValidateBesluit validate={validateBesluit} />

      <Modal.Footer>
        <Modal.CancelButton />

        <Button
          disabled={validateBesluit.isFetching || !validateBesluit.isValid}
          onClick={onContinue}
        >
          Doorgaan naar publiceren
        </Button>
      </Modal.Footer>
    </>
  );
};

const PublicerenBody = ({
  besluit,
  modalState,
}: BesluitPublicerenProps & { modalState: ReturnType<typeof useModalState> }) => {
  const patchBesluitId = usePatchBesluitId();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid: isValidForm },
  } = useForm<FormValues>({
    defaultValues: {
      Ons_Kenmerk: besluit.Ons_Kenmerk || '',
    },
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const userId = useUserStore(({ user }) => user?.ID);

  const hasLockedChapter = useHoofdstukReservering(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Besluit',
          field: 'ID',
          op: 'eq',
          value: besluit.ID ?? '',
        },
        {
          model: 'Gebruiker',
          field: 'ID',
          op: 'ne',
          value: userId ?? '',
        },
      ],
    },
    {
      enabled: !!besluit.ID,
      select: ({ objects }) => !!objects?.length,
    }
  );

  const onSubmit: SubmitHandler<FormValues> = async ({
    Begin_Geldigheid,
    Besluit_Datum,
    Ons_Kenmerk,
  }) => {
    modalState.action('save');

    /**
     * Revalidate before publiceren
     */
    const hasLockedChapterRefetch = await hasLockedChapter.refetch();

    if (hasLockedChapterRefetch.data) {
      return;
    }

    /**
     * Publiceer besluit
     */
    patchBesluitId.mutate(
      {
        data: {
          Begin_Geldigheid: DateUtils.formatForBE(new Date(Begin_Geldigheid)),
          Besluit_Datum: DateUtils.formatForBE(new Date(Besluit_Datum)),
          Ons_Kenmerk,
          Status: 'Publicatie',
        },
        id: besluit.ID!,
      },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) => {
          modalState.error('save', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
          });
        },
      }
    );
  };

  return (
    <>
      <div className="mt-4">
        <p className="text-gray-800">
          Je staat op het punt om dit besluit af te ronden. Zodra het besluit is afgerond in{' '}
          {eStrings.LBL_DIGI_V} is het niet meer mogelijk aanpassingen te doen aan dit besluit.
        </p>
      </div>

      {/* Input fields */}
      <form data-testid="besluit-popup-publiceren-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <Input
            {...register('Besluit_Datum', {
              required: 'Vul een besluitdatum in',
            })}
            label="Besluitdatum"
            id="Besluit_Datum"
            type="date"
            error={errors.Besluit_Datum}
            horizontal
            required
          />

          <Input
            {...register('Begin_Geldigheid', {
              required: 'Vul een inwerkingtreding datum in',
            })}
            label="Datum inwerkingtreding"
            id="Begin_Geldigheid"
            type="date"
            className="mt-2"
            error={errors.Begin_Geldigheid}
            horizontal
            required
          />

          <Input
            {...register('Ons_Kenmerk', {
              required: `${eStrings.LBL_BESLUIT_KENMERK} is vereist`,
            })}
            id="Ons_Kenmerk"
            maxLength={100}
            label={eStrings.LBL_BESLUIT_KENMERK}
            placeholder={eStrings.LBL_BESLUIT_KENMERK}
            className="mt-3"
            error={errors.Ons_Kenmerk}
            horizontal
            required
          />
        </div>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            type="submit"
            action="save"
            successLabel="Afgerond"
            disabled={hasLockedChapter.data || !isValidForm}
          >
            Ja, ik wil afronden
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </>
  );
};
