import { twMerge } from 'tailwind-merge';

import { RegularSpinner } from '@/icons/components';

export interface LoaderSpinningIconProps {
  fullWidth?: boolean;
  className?: string;
  iconClassName?: string;
  iconSize?: number;
}

export const LoaderSpinningIcon = ({
  fullWidth,
  className,
  iconClassName,
  iconSize = 16,
}: LoaderSpinningIconProps) => (
  <div className={twMerge('text-gray-600', className)}>
    <div className={fullWidth ? 'flex w-full items-center justify-center' : undefined}>
      <RegularSpinner size={iconSize} className={twMerge('animate-spin', iconClassName)} />
    </div>
  </div>
);
