import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Pagination } from '../Pagination/Pagination';

export interface PaginationLinksProps {
  renderLink: (props: { page: number; children: ReactNode }) => ReactNode;
  totalPages: number;
  currentPage: number;
  /** Optional extra className */
  className?: string;
}
export const PaginationLinks = ({
  renderLink,
  currentPage,
  totalPages,
  className,
}: PaginationLinksProps) => {
  const firstPage = 1;
  const lastPage = totalPages;

  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;

  return (
    <nav
      role="navigation"
      aria-label="Besluiten navigatie"
      className={twMerge('flex gap-1', className)}
    >
      <Pagination.Button asChild ariaLabel="Vorige pagina" disabled={currentPage === firstPage}>
        {renderLink({ page: previousPage, children: <Pagination.PreviousIcon /> })}
      </Pagination.Button>

      {firstPage !== currentPage && (
        <Pagination.Button asChild ariaLabel={`Pagina ${firstPage}`}>
          {renderLink({ page: firstPage, children: firstPage })}
        </Pagination.Button>
      )}

      {previousPage > 2 && <Pagination.Truncate testId="pagination-truncate-previous" />}

      {previousPage !== firstPage && previousPage > 0 && (
        <Pagination.Button asChild ariaLabel={`Pagina ${previousPage}`}>
          {renderLink({ page: previousPage, children: previousPage })}
        </Pagination.Button>
      )}

      <Pagination.Button asChild ariaLabel={`Pagina ${currentPage}`} active>
        {renderLink({ page: currentPage, children: currentPage })}
      </Pagination.Button>

      {nextPage < lastPage && (
        <Pagination.Button asChild ariaLabel={`Pagina ${nextPage}`}>
          {renderLink({ page: nextPage, children: nextPage })}
        </Pagination.Button>
      )}

      {lastPage - nextPage > 1 && <Pagination.Truncate testId="pagination-truncate-next" />}

      {lastPage !== currentPage && (
        <Pagination.Button asChild ariaLabel={`Pagina ${lastPage}`}>
          {renderLink({ page: lastPage, children: lastPage })}
        </Pagination.Button>
      )}

      <Pagination.Button asChild ariaLabel="Volgende pagina" disabled={currentPage === lastPage}>
        {renderLink({ page: nextPage, children: <Pagination.NextIcon /> })}
      </Pagination.Button>
    </nav>
  );
};
