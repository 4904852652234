import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDParagraafSchema } from '@/api';
import { usePatchStdParagraafId } from '@/api/queries/std/';
import { Button, FormLabel, Input, Modal, TextEditor, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { useOpenModal } from '@/modals/utils';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface StandaardtekstenEditParagraafProps {
  sectieId: string;
  paragraaf: STDParagraafSchema;
}

type FormValues = Pick<STDParagraafSchema, 'Nummer' | 'Titel' | 'Toelichting'>;

export const STANDAARDTEKSTEN_EDIT_PARAGRAAF_ID = 'standaardtekstenEditParagraafId';

export const StandaardtekstenEditParagraaf = modal(
  STANDAARDTEKSTEN_EDIT_PARAGRAAF_ID,
  ({ data, props }) => {
    const modalState = useModalState();
    const openModal = useOpenModal();

    const {
      paragraaf: { ID, Nummer, Titel, Toelichting },
      paragraaf,
    } = data;

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>({
      defaultValues: {
        Nummer,
        Titel,
        Toelichting,
      },
    });

    const patchStdParagraaf = usePatchStdParagraafId();

    const onSubmit: SubmitHandler<FormValues> = (formData) => {
      const userId = useUserStore.getState().user?.ID ?? '';

      modalState.action('save');
      patchStdParagraaf.mutate(
        {
          data: {
            ...formData,
            Modified_By: userId,
            Modified_Date: new Date().toISOString(),
          },
          id: ID!,
        },
        {
          onSuccess: () => {
            modalState.success('save');
          },
          onError: (error) => {
            modalState.error('save', {
              title: 'Mislukt',
              description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
            });
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Paragraaf bewerken">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Nummer', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
            error={errors.Nummer}
            label="Nummer"
            id="edit-std-paragraaf-nummer"
            required
          />
          <FormLabel htmlFor="bewerken-paragraaf-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                ]}
                initialText={Toelichting}
                id="bewerken-paragraaf-toelichting"
                testId="edit-object-paragraaf-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />
          <FormLabel htmlFor="bewerken-std-paragraaf-title" required>
            Titel (titel van de paragraaf)
          </FormLabel>
          <Controller
            control={control}
            name="Titel"
            rules={{ required: { value: true, message: eStrings.TXT_VERPLICHT_INPUT_VELD } }}
            render={({ field: { onChange, value: Titel } }) => (
              <TextEditor
                menuOptions={['superscript', 'subscript', 'highlight']}
                error={errors.Titel}
                initialText={Titel}
                id="bewerken-std-paragraaf-title"
                testId="edit-object-std-paragraaf-title"
                variant="input"
                singleLine
                onUpdate={onChange}
              />
            )}
          />
          <Modal.Footer>
            <div className="flex w-full justify-between">
              <Button
                variant="white"
                disabled={modalState.state?.type === 'action'}
                onClick={() => {
                  openModal('standaardtekstenVerwijderenParagraaf', { paragraaf });

                  props.onClose();
                }}
              >
                Paragraaf verwijderen
              </Button>
              <div>
                <Modal.CancelButton />

                <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
                  Opslaan
                </Modal.ActionButton>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
