import { useDebouncedCallback } from '@react-hookz/web';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link, createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { z } from 'zod';

import { useActiviteitCategorie } from '@/api/queries/activiteitCategorie/activiteitCategorie';
import { DocumentTitle, Explorer, Input } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { eStrings } from '@/constants/strings';
import { RegularMagnifyingGlass } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';

import { SidebarLayout } from './-components/SidebarLayout';

const searchSchema = z.object({
  p: z.number().optional().default(1),
  query: z.string().optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const activiteitCategorieQueryOptions = (search: SearchParams) => {
  return useActiviteitCategorie.getOptions(
    {
      page: search.p,
      size: 20,
      filter: search.query
        ? [
            {
              model: 'ActiviteitCategorie',
              field: 'Activiteit_Categorie',
              op: 'ilike',
              value: `%${search.query}%`,
            },
          ]
        : [],
      sort: [
        {
          model: 'ActiviteitCategorie',
          field: 'Activiteit_Categorie',
          direction: 'asc',
        },
      ],
    },
    {
      placeholderData: keepPreviousData,
    }
  );
};

/**
 * Route
 */
export const Route = createFileRoute('/beheer/activiteiten')({
  validateSearch: searchSchema.parse,
  beforeLoad: ({ navigate }) => {
    /**
     * TEMPORARY REDIRECT UNTIL DB SYNC PROPERLY WORKS
     **/
    navigate({ to: '/', search: { p: 1 } });

    return {
      getTitle: () => 'Activiteiten',
    };
  },
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { queryClient }, deps }) => {
    const activiteiten = await queryClient.ensureQueryData(
      activiteitCategorieQueryOptions(deps.search)
    );

    if (!activiteiten.num_pages) return;

    if (activiteiten.num_pages < deps.search.p) {
      throw redirect({
        to: '/beheer/activiteiten',
        search: { p: activiteiten.num_pages },
      });
    }
  },
  component: ActiviteitenComponent,
});

/**
 * Route component
 */
function ActiviteitenComponent() {
  const { p, query } = Route.useSearch();

  const openModal = useOpenModal();
  const navigate = useNavigate();

  const activiteiten = useQuery(activiteitCategorieQueryOptions({ p, query }));

  const search = useDebouncedCallback(
    (query: string) => {
      navigate({
        to: '/beheer/activiteiten',
        search: {
          p: 1,
          query,
        },
      });
    },
    [navigate],
    300
  );

  if (!activiteiten.data) return null;

  return (
    <SidebarLayout>
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Beheer Activiteiten']} />
      <Explorer
        title="Activiteiten"
        buttonProps={{
          label: 'Nieuwe activiteit',
          variant: 'white',
          onClick: () => openModal('beheerActiviteit', {}),
        }}
        showButton
      >
        <Input
          icon={RegularMagnifyingGlass}
          label="Activiteit zoeken"
          placeholder="Zoek activiteit"
          hideLabel
          id="activiteiten-search"
          name="activiteiten-search"
          className="mb-6"
          onChange={(e) => search(e.target.value)}
        />

        {query && !activiteiten.data.objects?.length ? (
          <p className="italic text-gray-600">
            Er zijn geen activiteiten gevonden die voldoen aan de zoekopdracht
          </p>
        ) : null}

        {activiteiten.data.objects?.length ? (
          <ul>
            {activiteiten.data.objects.map((activiteit) => (
              <li key={`installatie-type-item-${activiteit.ID}`}>
                <Explorer.Button
                  asChild
                  inactive={activiteit.Status === 'Archief'}
                  options={[
                    {
                      variant: 'button',
                      title: 'Bewerken',
                      onClick: () => {
                        openModal('beheerActiviteit', {
                          updateActiviteit: activiteit,
                        });
                      },
                    },
                    {
                      title: activiteit.Status === 'Actief' ? 'Archiveren' : 'Herstellen',
                      variant: 'button',
                      onClick: () => {
                        openModal('beheerActiviteitStatus', {
                          activiteit,
                          newStatus: activiteit.Status === 'Actief' ? 'Archief' : 'Actief',
                        });
                      },
                    },
                  ]}
                >
                  <div>{activiteit.Activiteit_Categorie}</div>
                </Explorer.Button>
              </li>
            ))}
          </ul>
        ) : null}
      </Explorer>
      {(activiteiten.data?.num_pages ?? 0) > 1 ? (
        <div className="mt-8 flex w-full justify-center">
          <PaginationLinks
            renderLink={({ children, page }) => (
              <Link to="/beheer/activiteiten" search={{ p: page, query }}>
                {children}
              </Link>
            )}
            currentPage={p}
            totalPages={activiteiten.data?.num_pages ?? 0}
          />
        </div>
      ) : null}
    </SidebarLayout>
  );
}
