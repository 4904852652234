import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Warning } from '@/components/shared/Warning';

import { useModalProps } from '../hooks/useModalProps';

export function ModalFooter({ children, className }: { children: ReactNode; className?: string }) {
  const { state } = useModalProps() || {};

  return (
    <>
      {state?.type === 'error' && state.warning && (
        <Warning
          className="mt-4"
          variant="danger"
          title={state.warning.title}
          description={state.warning.description}
        />
      )}

      <div
        className={twMerge(
          'sticky bottom-0 left-0 -mx-6 -mb-5 mt-5 overflow-hidden border-t border-t-gray-300 bg-gray-50',
          className
        )}
      >
        <div className="-mx-1 flex items-center justify-end px-7 py-3 [&_a]:mx-1 [&_button]:mx-1">
          {children}
        </div>
      </div>
    </>
  );
}
