import {
  getFixLineageVoorschrift,
  getFixVoorschrift,
  getFixVoorschriftId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

/**
 * Queries
 */
export const useVoorschrift = createQuery({
  key: ['voorschrift'],
  queryFn: getFixVoorschrift,
});

export const useVoorschriftId = createQuery({
  key: ['voorschriftId'],
  queryFn: getFixVoorschriftId,
});

export const useLineageVoorschrift = createQuery({
  key: ['lineageVoorschrift'],
  queryFn: getFixLineageVoorschrift,
});

export const useLineageVoorschriftId = createQuery({
  key: ['lineageVoorschriftId'],
  queryFn: getFixVoorschriftId,
});
