import { ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

export interface HeadingLargeProps {
  value: string | ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  textColor?: `text-${string}`;
  className?: string;
}

export const HeadingLarge = ({
  value,
  className,
  as = 'h3',
  textColor = 'text-theme-blue',
}: HeadingLargeProps) => {
  const As = as;

  return (
    <As className={twMerge(twJoin('text-lg font-medium leading-6', textColor), className)}>
      {value}
    </As>
  );
};
