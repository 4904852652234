import { Link, createFileRoute } from '@tanstack/react-router';

import { BedrijfSchema } from '@/api';
import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useLastVigerendBesluit } from '@/api/queries/besluit';
import {
  BesluitCard,
  DescriptionList,
  DescriptionListItem,
  HeadingLarge,
  RoleBasedAccess,
  TextButton,
} from '@/components';
import { eStrings } from '@/constants/strings';
import { RegularChevronRight, RegularFile } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';

import { BesluitCardVigerend } from './-components/BesluitCardVigerend';

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/bedrijf/_layout/overzicht')({
  beforeLoad: () => ({
    getTitle: () => 'Overzicht',
  }),
  loader: async ({ params: { bedrijfLineageId }, context: { queryClient } }) => {
    await Promise.all([
      queryClient.ensureQueryData(useLatestBedrijf.getOptions(bedrijfLineageId)),
      queryClient.ensureQueryData(useLastVigerendBesluit.getOptions({ bedrijfLineageId })),
    ]);
  },
  component: OverzichtComponent,
});

/**
 * Route component
 */
function OverzichtComponent() {
  const { bedrijfLineageId } = Route.useParams();

  const openModal = useOpenModal();

  const bedrijf = useLatestBedrijf(bedrijfLineageId);
  const lastVigerendBesluit = useLastVigerendBesluit({ bedrijfLineageId });

  const info: DescriptionListItem[] = [
    {
      label: 'Adresgegevens',
      value: `${bedrijf.data?.Straatnaam} ${bedrijf.data?.Huisnummer}, ${bedrijf.data?.Postcode} ${bedrijf.data?.Plaatsnaam}`,
    },
  ];

  if (bedrijf.data?.Locatienummer) {
    info.push({
      label: 'Locatienummer',
      value: bedrijf.data?.Locatienummer,
    });
  }

  if (bedrijf.data?.Bevoegd_gezag) {
    info.push({
      label: 'Bevoegd gezag',
      value: bedrijf.data?.Bevoegd_gezag,
    });
  }

  if (!bedrijf.data) return null;

  return (
    <>
      <section>
        <HeadingLarge as="h2" value="Bedrijfsinformatie" />
        <DescriptionList items={info} />
      </section>

      {lastVigerendBesluit.data && <VigerendSection bedrijf={bedrijf.data} />}

      <section className="mt-10">
        <HeadingLarge
          className="mb-4 mt-10"
          as="h2"
          value={eStrings.LBL_BESLUITEN_EN_WIJZIGINGEN}
        />
        {lastVigerendBesluit.data ? (
          <>
            <BesluitCardVigerend
              headingAs="h3"
              bedrijf={bedrijf.data}
              besluit={lastVigerendBesluit.data}
            />
            <TextButton size="small" className="mt-10 inline-block" asChild>
              <Link
                to="/$bedrijfLineageId/bedrijf/besluiten"
                activeOptions={{
                  includeSearch: false,
                }}
                search={{ p: 1 }}
                params={{ bedrijfLineageId: bedrijf.data?.Lineage ?? '' }}
              >
                <RegularChevronRight size={14} className="mr-2 inline-block" />
                {eStrings.LBL_BEKIJK_ALLE_BESLUITEN}
              </Link>
            </TextButton>
          </>
        ) : (
          <span className="italic text-gray-500">
            {eStrings.TXT_GEEN_BESLUITEN_EN_WIJZIGINGEN}{' '}
          </span>
        )}
      </section>

      <RoleBasedAccess allowed={['Beheerder']} area={['omgevingsdienst']}>
        <section className="mt-10">
          <HeadingLarge className="mb-3 mt-10" as="h2" value="Bedrijfsacties" />

          <TextButton
            size="small"
            className="inline-block"
            onClick={() =>
              openModal('bedrijfsInformatie', {
                title: 'Bedrijfsinformatie bewerken',
                bedrijfToUpdate: bedrijf.data,
              })
            }
          >
            <RegularChevronRight size={14} className="mr-2 inline-block" />
            Bedrijfsinformatie bewerken
          </TextButton>
        </section>
      </RoleBasedAccess>
    </>
  );
}

const VigerendSection = ({ bedrijf }: { bedrijf: BedrijfSchema }) => {
  return (
    <section className="mt-10">
      <HeadingLarge className="mb-3 mt-10" as="h2" value={eStrings.LBL_VIGERENDE_SITUATIE} />

      <p className="mb-5 text-gray-900">
        {eStrings.TXT_VIGERENDE_SITUATIE}
        <span className="font-bold">{bedrijf.Naam}</span>
      </p>

      <BesluitCard icon={RegularFile}>
        <BesluitCard.Body>
          <BesluitCard.Heading title="Vigerende situatie" />

          <BesluitCard.ButtonRow>
            <TextButton size="small" asChild>
              <Link
                to="/$bedrijfLineageId/viewer/vigerend"
                params={{ bedrijfLineageId: bedrijf.Lineage }}
              >
                Bekijken in de viewer
              </Link>
            </TextButton>
          </BesluitCard.ButtonRow>
        </BesluitCard.Body>
      </BesluitCard>
    </section>
  );
};
