import { ReactNode, useMemo } from 'react';

import { Warning } from '@/components';

interface HTMLContentProps {
  title: string | ReactNode;
  content?: string | null;
}

export const HTMLContent = ({ content, title }: HTMLContentProps) => {
  const hasNoContent = useMemo(() => {
    const elements = document.createElement('div');
    elements.innerHTML = content || '';

    if (elements.querySelector('img')) {
      return false;
    }

    return elements.textContent?.trim() === '';
  }, [content]);

  if (hasNoContent || !content) {
    return (
      <>
        <h2 className="text-left text-2xl font-semibold text-theme-blue">{title}</h2>
        <Warning
          size="small"
          variant="info"
          className="mt-4"
          title="Deze pagina heeft geen inhoud"
        />
      </>
    );
  }

  return (
    <>
      <h2 className="mb-4 text-left text-2xl font-semibold text-theme-blue">{title}</h2>
      <div className="richtext" id="page-with-html" dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};
