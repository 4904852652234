import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { RegularSpinner } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

export interface AddItemButtonProps extends ComponentPropsWithoutRef<'button'> {
  testId?: string;
  label?: string | ReactNode;
  icon: IconComponent;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
}

export const AddItemButton = ({
  testId,
  label,
  icon,
  disabled,
  className,
  children,
  isLoading,
  ...props
}: AddItemButtonProps) => {
  const IconComponent = icon;

  return (
    <button
      data-testid={testId}
      disabled={disabled || isLoading}
      className={twMerge(
        'flex h-[104px] min-w-[120px] flex-col items-center rounded-md bg-gray-200 px-1 pb-1 pt-2 text-black',
        !disabled ? 'hover:bg-theme-blue hover:text-white' : 'text-black-500  bg-white opacity-40',
        disabled || isLoading ? 'cursor-not-allowed' : 'cursor-pointer',
        className
      )}
      {...props}
    >
      <span className="h-12 py-2">
        {isLoading ? (
          <RegularSpinner
            size={24}
            className="h-full animate-spin"
            data-testid={`${testId}-spinner`}
          />
        ) : (
          <IconComponent size={24} className="h-full" />
        )}
      </span>
      <span className="flex h-10 w-full items-center justify-center text-center text-sm leading-5">
        {label}

        {children}
      </span>
    </button>
  );
};
