import { createContext, useContext, useState } from 'react';

interface TextEditorState {
  dropdownIsOpen: boolean;
  setDropdownIsOpen: (hasFocus: boolean) => void;
}

export const TextEditorContext = createContext<TextEditorState>({
  dropdownIsOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDropdownIsOpen: () => {},
});

export const useTextEditorProvider = (): TextEditorState => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  return {
    dropdownIsOpen,
    setDropdownIsOpen,
  };
};

export const useTextEditor = () => useContext(TextEditorContext);
