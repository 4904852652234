class FormUtilsSingleton {
  /**
   * Checks if the string is a valid date
   */
  removeLinebreaks(value: string) {
    return value.replace(/(\r\n|\n|\r)/gm, '');
  }
}

export const FormUtils = new FormUtilsSingleton();
