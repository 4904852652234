import { Link } from '@tanstack/react-router';

import { HoofdstukSchema } from '@/api';
import { useHoofdstuk } from '@/api/queries/objects';

import { TextButton } from '../../TextButton';
import { ValidateBesluitProps } from '../ValidateBesluit.types';
import { ValidateRow } from './ValidateRow';

export const useValidateHoofdstukTitle = (besluitId: string) => {
  /**
   * Check if there are hoofdstukken with empty titles
   */
  const validateHoofdstukTitles = useHoofdstuk(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'Besluit',
          field: 'ID',
          op: 'eq',
          value: besluitId,
        },
        {
          model: 'HB',
          field: 'Type',
          op: 'eq',
          value: 'Eigen',
        },
        {
          model: 'Hoofdstuk',
          field: 'Titel',
          op: 'eq',
          value: '',
        },
      ],
      sort: [
        {
          model: 'Hoofdstuk',
          field: 'Nummer',
          direction: 'asc',
        },
      ],
    },
    {
      select: ({ num_objects, objects }) => ({ isValid: num_objects === 0, objects }),
      gcTime: 0,
      staleTime: 0,
    }
  );

  return validateHoofdstukTitles;
};

export const ValidateHoofdstukTitle = ({ validate }: ValidateBesluitProps) => {
  const { isFetching, data } = validate.queries.validateHoofdstukTitles;
  const objects = data?.objects ?? [];

  if (isFetching || !data) {
    return <ValidateRow isFetching title="Lege hoofdstuktitels" />;
  }

  if (!data.isValid) {
    return (
      <ValidateRow
        isValid={false}
        title={`${objects.length} lege ${objects.length > 1 ? 'hoofdstuktitels' : 'hoofdstuktitel'}`}
        accordionBody={
          <ErrorBody
            besluitId={validate.besluitId}
            bedrijfLineageId={validate.bedrijfLineageId}
            hoofdstukken={objects}
          />
        }
      />
    );
  }

  return <ValidateRow isValid title="Geen lege hoofdstuktitels" />;
};

const ErrorBody = ({
  hoofdstukken,
  besluitId,
  bedrijfLineageId,
}: {
  hoofdstukken: HoofdstukSchema[];
  besluitId: string;
  bedrijfLineageId: string;
}) => {
  return (
    <ul>
      {hoofdstukken.map((hoofdstuk) => (
        <li className="list-inside list-disc" key={hoofdstuk.ID}>
          <TextButton size="small" asChild>
            <Link
              to="/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId"
              params={{
                besluitId,
                bedrijfLineageId,
                hoofdstukId: hoofdstuk.ID ?? '',
              }}
            >
              Hoofdstuk {hoofdstuk.Nummer} heeft geen titel
            </Link>
          </TextButton>
        </li>
      ))}
    </ul>
  );
};
