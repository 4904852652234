import { MutationKey, useQueryClient } from '@tanstack/react-query';

import { useDeleteFixVoorschriftId } from '@/api/generated/digiVAPI';

import { useLinksInHoofdstuk } from '../links';
import { useVoorschrift, useVoorschriftId } from '../voorschrift';

export function useDeleteVoorschriftId({ mutationKey }: { mutationKey?: MutationKey } = {}) {
  const queryClient = useQueryClient();

  return useDeleteFixVoorschriftId({
    mutation: {
      mutationKey,
      onSuccess: async (data) => {
        /**
         * When deleting a voorschrift, the link will also be deleted.
         * We'll need to invalidate those queries as well.
         */
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: useVoorschrift.key }),
          queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key }),
        ]);

        if (data.ID) {
          queryClient.setQueryData(useVoorschriftId.getKey(data.ID), undefined);
        }
      },
    },
  });
}
