import { useQueryClient } from '@tanstack/react-query';

import { usePostDynMetaVoorschriftLabel } from '@/api/generated/digiVAPI';

import { useLabel } from '../label';

export function usePostVoorschriftLabel({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return usePostDynMetaVoorschriftLabel({
    mutation: {
      mutationKey,
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: useLabel.key });
      },
    },
  });
}
