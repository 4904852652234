import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 384 512" ref={svgRef} {...props}><path d="M0 56c0-13.3 10.7-24 24-24h188c68.5 0 124 55.5 124 124 0 34.7-14.3 66.2-37.3 88.7 41 20.2 69.3 62.4 69.3 111.3 0 68.5-55.5 124-124 124H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h24V80H24C10.7 80 0 69.3 0 56zm212 176c42 0 76-34 76-76s-34-76-76-76H96v152h116zM96 280v152h148c42 0 76-34 76-76s-34-76-76-76H96z" /></svg>;
});
SVGIcon.displayName = "RegularBold";
export default SVGIcon;