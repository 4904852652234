import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BedrijfSchema } from '@/api';
import { usePatchBedrijfId, usePostBedrijf, usePostLineageBedrijf } from '@/api/queries/bedrijf';
import { useBesluit } from '@/api/queries/besluit';
import { Input, Modal, Select, useModalState, useWarningBeforeClose, validate } from '@/components';
import { bevoegdGezag } from '@/constants/bevoegdGezag';
import { PLACEHOLDER_UUID } from '@/constants/placeholder-uuid';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils/modal';

export type BedrijfsInformatieProps = {
  title: string;
  bedrijfToUpdate?: BedrijfSchema;
};

type FormValues = Pick<
  BedrijfSchema,
  | 'Naam'
  | 'Locatienummer'
  | 'Straatnaam'
  | 'Huisnummer'
  | 'Postcode'
  | 'Plaatsnaam'
  | 'Postcode'
  | 'KVKNummer'
  | 'Bevoegd_gezag'
  | 'Vorig'
>;

export const BEDRIJFS_INFORMATIE_ID = 'bedrijfsInformatie';
const FORM_ID = 'popup-bedrijfsinformatie-bewerken-form';

export const BedrijfsInformatie = modal(BEDRIJFS_INFORMATIE_ID, ({ data, props }) => {
  const { bedrijfToUpdate, title } = data;
  const navigate = useNavigate();

  const modalState = useModalState();

  const queryClient = useQueryClient();

  const postLineageBedrijf = usePostLineageBedrijf();
  const postBedrijf = usePostBedrijf();
  const patchBedrijfId = usePatchBedrijfId();

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    defaultValues: bedrijfToUpdate ?? {},
    mode: 'onChange',
  });

  const handleOnClose = useWarningBeforeClose({
    onClose: () => {
      props.onClose();
    },
    warn: !!(
      bedrijfToUpdate &&
      Object.keys(dirtyFields).length > 0 &&
      modalState.state?.type !== 'success'
    ),
    message:
      'Weet je zeker dat je dit venster wilt sluiten? Alle ingevulde gegevens gaan verloren.',
  });

  const onSubmit: SubmitHandler<FormValues> = async ({
    Naam,
    Locatienummer,
    Postcode,
    Straatnaam,
    Huisnummer,
    Bevoegd_gezag,
    KVKNummer,
    Plaatsnaam,
  }) => {
    modalState.action('save');

    const updatedBedrijf = {
      Naam,
      Postcode,
      Straatnaam,
      Huisnummer,
      Plaatsnaam,
      Bevoegd_gezag,
      // Set default to 0 till backend removes required field Goedgekeurd.
      Goedgekeurd: 0,
      Locatienummer: Locatienummer ?? '',
      KVKNummer: KVKNummer ?? '',
    };

    // If there's a bedrijf to update, update the bedrijf.
    if (bedrijfToUpdate?.ID) {
      const vigerendBesluit = await queryClient.fetchQuery(
        useBesluit.getOptions({
          page: 1,
          size: 1,
          filter: [
            {
              model: 'Besluit',
              field: 'Bedrijf',
              op: 'eq',
              value: bedrijfToUpdate.ID,
            },
            {
              model: 'Besluit',
              field: 'Status',
              op: 'eq',
              value: 'Publicatie',
            },
          ],
        })
      );

      if (vigerendBesluit.objects?.length) {
        postBedrijf.mutate(
          {
            data: {
              ...updatedBedrijf,
              Lineage: bedrijfToUpdate.Lineage,
              Vorig: bedrijfToUpdate.ID,
            },
          },
          {
            onSuccess: () => modalState.success('save'),
            onError: (error) => {
              modalState.error('save', {
                title: 'Mislukt',
                description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
              });
            },
          }
        );
      } else {
        patchBedrijfId.mutate(
          {
            id: bedrijfToUpdate.ID,
            data: {
              ...updatedBedrijf,
              Lineage: bedrijfToUpdate.Lineage,
            },
          },
          {
            onSuccess: () => modalState.success('save'),
            onError: (error) => {
              modalState.error('save', {
                title: 'Mislukt',
                description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
              });
            },
          }
        );
      }
    }

    // If there's no bedrijf to update, create a new bedrijf.
    if (!bedrijfToUpdate) {
      try {
        const lineage = await postLineageBedrijf.mutateAsync({
          data: {
            // Tenant will be replaced by BE
            Tenant: PLACEHOLDER_UUID,
          },
        });

        if (!lineage.ID) throw new Error('No lineage ID');

        const bedrijf = await postBedrijf.mutateAsync({
          data: {
            Lineage: lineage.ID,
            ...updatedBedrijf,
          },
        });

        modalState.success('save', () => {
          props.onClose();

          if (bedrijf.ID) {
            navigate({
              to: '/$bedrijfLineageId/bedrijf/overzicht',
              params: {
                bedrijfLineageId: bedrijf.Lineage,
              },
            });
          }
        });
      } catch (error) {
        const errorMessage = error instanceof Error && ErrorMessage.getLocalErrorMessage(error);

        if (errorMessage) {
          modalState.error('save', {
            title: 'Mislukt',
            description: errorMessage,
          });
        }
      }
    }
  };

  return (
    <Modal {...props} state={modalState.state} onClose={handleOnClose} size="medium" title={title}>
      <form
        id={FORM_ID}
        className="flex w-full flex-col gap-y-3 pt-2 text-gray-900"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          {...register('Naam', { required: 'Vul een bedrijfsnaam in' })}
          id="Naam"
          placeholder="Bedrijfsnaam"
          error={errors.Naam}
          label="Bedrijfsnaam"
          maxLength={100}
          horizontal
          required
        />

        <Input
          {...register('Locatienummer')}
          placeholder="Locatienummer"
          id="locatie_nummer"
          label="Locatienummer"
          maxLength={100}
          horizontal
          error={errors.Locatienummer}
        />

        <Input
          {...register('Straatnaam', { required: 'Vul een straatnaam in' })}
          placeholder="Straatnaam"
          id="street_address"
          label="Straatnaam"
          error={errors.Straatnaam}
          maxLength={100}
          horizontal
          required
        />

        <Input
          {...register('Huisnummer', {
            required: 'Vul een huisnummer in',
          })}
          placeholder="Nummer"
          id="huisnummer"
          error={errors.Huisnummer}
          maxLength={20}
          label="Huisnummer"
          horizontal
          required
        />

        <Input
          {...register('Postcode', {
            required: 'Vul een postcode in',
            validate: validate.zipcode(),
          })}
          id="zip"
          label="Postcode"
          maxLength={20}
          placeholder="Postcode"
          error={errors.Postcode}
          horizontal
          required
        />

        <Input
          {...register('Plaatsnaam', { required: 'Vul een plaatsnaam in' })}
          placeholder="Plaatsnaam"
          id="city"
          label="Plaatsnaam"
          maxLength={100}
          error={errors.Plaatsnaam}
          horizontal
          required
        />

        <Input
          {...register('KVKNummer', {
            validate: validate.KVK(),
          })}
          label="KvK nummer"
          maxLength={8}
          placeholder="KvK nummer"
          id="kvk_nummer"
          error={errors.KVKNummer}
          horizontal
        />

        <Select
          {...register('Bevoegd_gezag', { required: 'Vul het bevoegde gezag in' })}
          id="Bevoegd_gezag"
          label="Bevoegd gezag"
          defaultValue=""
          error={errors.Bevoegd_gezag}
          required
          horizontal
        >
          <option value={''} disabled>
            Selecteer een optie
          </option>
          {bevoegdGezag.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        <Modal.Footer>
          <Modal.CancelButton onClose={handleOnClose} />

          <Modal.ActionButton type="submit" action="save" successLabel="Opgeslagen">
            Opslaan
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
