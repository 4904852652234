import {
  getFixBesluit,
  getFixBesluitId,
  getFixLineageBesluit,
  getFixLineageBesluitId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useBesluit = createQuery({
  key: ['besluit'],
  queryFn: getFixBesluit,
});

export const useBesluitId = createQuery({
  key: ['besluitId'],
  queryFn: getFixBesluitId,
});

export const useLineageBesluit = createQuery({
  key: ['lineageBesluit'],
  queryFn: getFixLineageBesluit,
});

export const useLineageBesluitId = createQuery({
  key: ['lineageBesluitId'],
  queryFn: getFixLineageBesluitId,
});
