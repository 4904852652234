import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512" ref={svgRef} {...props}><path d="M64 464h198.5l-5.1 20.2c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0h165.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3v145.1l-48 48V160h-80c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16v384c0 8.8 7.2 16 16 16zm485.8-228.3l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417l129.2-129.2 71 71-129.2 129.1c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" /></svg>;
});
SVGIcon.displayName = "RegularFilePen";
export default SVGIcon;