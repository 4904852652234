import { ConnectDragSource } from 'react-dnd';

import { LinkSubparagraaf, Links, useSubparagraafId } from '@/api/queries/objects';
import { EditorAdd, LoaderContent } from '@/components';
import { MountWhenInView } from '@/components/shared/MountWhenInView';
import { getObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';

import { SubparagraafContext } from './SubparagraafContext';
import { SubparagraafInput } from './SubparagraafInput';

interface SubparagraafProps {
  userHasReservering: boolean;
  link: LinkSubparagraaf;
  links: Links;
  dragHandleRef?: ConnectDragSource;
  editorMode: EditorMode;
  paragraafIndex: number;
  subparagraafIndex: number;
}

export const Subparagraaf = (props: SubparagraafProps) => {
  return (
    <MountWhenInView placeholder={<Skeleton subparagraafIndex={props.subparagraafIndex} />}>
      <SubparagraafInner {...props} />
    </MountWhenInView>
  );
};

const SubparagraafInner = ({
  userHasReservering,
  link,
  editorMode,
  links,
  paragraafIndex,
  subparagraafIndex,
  dragHandleRef,
}: SubparagraafProps) => {
  const id = link.SPPB.Subparagraaf;

  /**
   * Fetch data
   */
  const subparagraaf = useSubparagraafId(id);

  /**
   * Check if paragraaf is editable
   */
  const objectState = getObjectState({
    object: subparagraaf.data,
    linkType: link.SPPB.Type,
    userHasReservering,
  });

  /**
   * Return loading state when paragraaf is loading or not available
   */
  if (subparagraaf.isLoading || !subparagraaf.data) {
    return <Skeleton subparagraafIndex={subparagraafIndex} />;
  }

  /**
   * NOTE: Temporary fix to hide publicatie links when the subparagraaf is marked as stop lineage
   * BE will provide a filter in the API to filter out these links eventually.
   */
  if (subparagraaf.data.Stop_Lineage && link.SPPB.Type === 'Publicatie') {
    return null;
  }

  const contextValue = {
    link,
    links,
    subparagraaf: subparagraaf.data,
    userHasReservering,
    editorMode,
    objectState,
  };

  if (subparagraaf.data?.Transparent) {
    return null;
  }

  return (
    <SubparagraafContext.Provider value={contextValue}>
      <div data-testid={`subparagraaf-${paragraafIndex}-${subparagraafIndex}`}>
        <SubparagraafInput dragHandleRef={dragHandleRef} />
      </div>
    </SubparagraafContext.Provider>
  );
};

const Skeleton = ({ subparagraafIndex }: { subparagraafIndex: number }) => {
  if (subparagraafIndex === 0) {
    return <EditorAdd.Spacer />;
  }

  return (
    <>
      <div className="flex h-[35px] w-full items-center">
        <div className="ml-[68px] mr-[14px] w-20" />
        <LoaderContent w="w-full" h="h-[28px]" className="block" />
      </div>

      <EditorAdd.Spacer />
    </>
  );
};
