import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512" ref={svgRef} {...props}><path d="M80 80c-17.7 0-32 14.3-32 32v48h32v48H48c-26.5 0-48-21.5-48-48v-48c0-44.2 35.8-80 80-80h320c44.2 0 80 35.8 80 80v160h-80c-8.8 0-16-7.2-16-16s7.2-16 16-16h32v-80h-32c-8.8 0-16-7.2-16-16s7.2-16 16-16h32v-16c0-17.7-14.3-32-32-32H153.3c4.3 9.8 6.7 20.6 6.7 32v112h48c8.8 0 16 7.2 16 16s-7.2 16-16 16h-48v144c0 17.7 14.3 32 32 32s32-14.3 32-32v-48c0-26.5 21.5-48 48-48h260.2c26.5 0 48 21.5 48 48v48c0 44.2-35.8 80-80 80H192c-44.2 0-80-35.8-80-80V112c0-17.7-14.3-32-32-32zm185.3 352H496c17.7 0 32-14.3 32-32v-48H400v32c0 8.8-7.2 16-16 16s-16-7.2-16-16v-32h-96v48c0 11.4-2.4 22.2-6.7 32z" /></svg>;
});
SVGIcon.displayName = "RegularScrollOld";
export default SVGIcon;