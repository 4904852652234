import { useQuery } from '@tanstack/react-query';
import { Link, Navigate, Outlet, createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { useLabelMap } from '@/api/queries/label';
import { DocumentTitle, Explorer } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { eStrings } from '@/constants/strings';
import { useOpenModal } from '@/modals/utils';
import { useIsExactMatch } from '@/utils/hooks/useIsExactMatch';

import { ExplorerButtonLabelMap } from './-components/ExplorerButtonLabelMap';
import { SidebarLayout } from './-components/SidebarLayout';

const searchSchema = z.object({
  query: z.string().optional(),
  p: z.number().optional().default(1),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const labelMapQueryOptions = (search: SearchParams) => {
  return useLabelMap.getOptions({
    size: 20,
    page: search.p,
    filter: [
      {
        field: 'Map',
        value: '',
        op: 'is_null',
      },
    ],
    sort: [
      {
        field: 'Naam',
        direction: 'asc',
      },
    ],
  });
};

/**
 * Route
 */
export const Route = createFileRoute('/beheer/labels')({
  validateSearch: searchSchema.parse,
  beforeLoad: ({ navigate }) => {
    /**
     * TEMPORARY REDIRECT UNTIL DB SYNC PROPERLY WORKS
     **/
    navigate({ to: '/', search: { p: 1 } });

    return {
      getTitle: () => 'Labels',
    };
  },
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { queryClient }, deps }) => {
    await queryClient.ensureQueryData(labelMapQueryOptions(deps.search));
  },
  component: LabelsComponent,
});

/**
 * Route component
 */
function LabelsComponent() {
  const openModal = useOpenModal();
  const search = Route.useSearch();

  const mappen = useQuery(labelMapQueryOptions(search));

  /**
   * NOTE: Temporary solution for breadcrumbs
   *
   * When the route is not exactly this route, render the outlet
   */
  const isExactMatch = useIsExactMatch(Route.fullPath);

  if (!isExactMatch) {
    return (
      <>
        <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Beheer Labels']} /> <Outlet />
      </>
    );
  }

  if (!mappen.isLoading && mappen.data?.num_pages && mappen.data.num_pages < search.p) {
    return <Navigate to="/beheer/labels" search={{ p: mappen.data.num_pages }} />;
  }

  return (
    <SidebarLayout>
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Beheer Labels']} />
      <Explorer title="Labels">
        <Explorer.ItemHeading
          title="Mappen"
          buttons={[
            {
              key: 'new-label-map',
              variant: 'white',
              label: 'Nieuwe map',
              onClick: () => {
                openModal('beheerLabelsMap', {
                  createMap: true,
                });
              },
            },
          ]}
        />

        {mappen.data?.objects?.length ? (
          <>
            <ul>
              {mappen.data.objects.map((map) => (
                <li key={`label-map-link-${map.ID}`}>
                  <ExplorerButtonLabelMap asChild map={map}>
                    <Link
                      to="/beheer/labels/$mapId"
                      search={{ p: 1, mapPage: 1, labelPage: 1 }}
                      params={{ mapId: map.ID! }}
                    >
                      {map.Naam}
                    </Link>
                  </ExplorerButtonLabelMap>
                </li>
              ))}
            </ul>
            {(mappen.data?.num_pages ?? 0) > 1 ? (
              <div className="mt-8 flex w-full justify-center">
                <PaginationLinks
                  renderLink={({ children, page }) => (
                    <Link to="/beheer/labels" search={{ p: page }}>
                      {children}
                    </Link>
                  )}
                  currentPage={search.p}
                  totalPages={mappen.data?.num_pages ?? 0}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </Explorer>
    </SidebarLayout>
  );
}
