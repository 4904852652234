import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" ref={svgRef} {...props}><path d="M0 88c0-13.3 10.7-24 24-24h120.2c17.5 0 34 8.2 44.5 22.1l108.8 142.7c1.5 2 3.9 3.2 6.4 3.2h126.2l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H303.8c-2.5 0-4.9 1.2-6.4 3.2L188.7 425.9c-10.6 13.9-27.1 22.1-44.5 22.1H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h120.2c2.5 0 4.9-1.2 6.4-3.2L257.8 256 150.5 115.2c-1.5-2-3.9-3.2-6.4-3.2H24c-13.3 0-24-10.7-24-24z" /></svg>;
});
SVGIcon.displayName = "RegularMerge";
export default SVGIcon;