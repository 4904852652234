import React, { InputHTMLAttributes, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';

import { RegularUpload } from '@/icons/components';

import { FormError } from '../FormError';
import { FormLabel } from '../FormLabel';

export interface FileProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder'> {
  id: string;
  name: string;
  label: string;
  hideLabel?: boolean;
  required?: boolean;
  labelClasses?: string;
  placeholder?: string;
  /** Error coming from `react-hook-form` */
  error?: FieldError | string;
  disabled?: boolean;
  readOnly?: boolean;
  horizontal?: boolean;
  ref?: React.Ref<HTMLInputElement>;
}

export const File = React.forwardRef<HTMLInputElement, FileProps>(
  (
    {
      id,
      name,
      label,
      hideLabel,
      labelClasses,
      required,
      error,
      disabled,
      horizontal,
      readOnly,
      onChange,
      placeholder = 'Selecteer een bestand',
      className,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [files, setFiles] = useState<FileList | null>(null);

    const handleClick = () => {
      const input = containerRef.current?.querySelector<HTMLInputElement>('input[type=file]');

      input?.click();
    };

    const errorId = `${id}-error`;

    return (
      <div
        className={twMerge(
          twJoin(horizontal && 'grid w-full grid-cols-3', !horizontal && 'flex flex-col'),
          className
        )}
        ref={containerRef}
      >
        <div className={twJoin(hideLabel && 'sr-only', horizontal && 'col-span-1 pr-2 pt-1')}>
          <FormLabel htmlFor={id} className={labelClasses} required={required}>
            {label}
          </FormLabel>
        </div>

        <button
          type="button"
          onClick={handleClick}
          className={twJoin(
            'form-input-file relative block w-full text-left',
            !files?.length && 'text-gray-500',
            error &&
              'border-6 border-solid border-digi-v-color-danger focus:border-digi-v-color-danger focus:ring focus:ring-digi-v-color-danger/30',
            (disabled || readOnly) && 'cursor-not-allowed bg-gray-100 text-gray-500'
          )}
        >
          {files?.length ? [...files].map(({ name }) => name).join(', ') : placeholder}

          <span className="absolute right-2 top-0 flex h-full items-center">
            <RegularUpload size={16} className="text-gray-400" />
          </span>
        </button>

        <input
          id={id}
          name={name}
          ref={ref}
          type="file"
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => {
            setFiles(e.target.files);
            onChange?.(e);
          }}
          aria-describedby={error ? errorId : undefined}
          aria-invalid={error ? true : undefined}
          className="hidden"
          {...props}
        />

        {error && (
          <FormError id={errorId}>{typeof error === 'string' ? error : error.message}</FormError>
        )}
      </div>
    );
  }
);
File.displayName = 'File';
