import { VoorschriftSchema } from '@/api';
import { LinkVoorschrift, useCreateVoorschriftInLineage } from '@/api/queries/objects';
import { Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface IntrekkenVoorschriftProps {
  nummer: string;
  link: LinkVoorschrift;
  voorschrift: VoorschriftSchema;
}

export const INTREKKEN_VOORSCHRIFT_ID = 'intrekkenVoorschrift';

export const IntrekkenVoorschrift = modal(INTREKKEN_VOORSCHRIFT_ID, ({ data, props }) => {
  const { nummer, voorschrift, link } = data;

  const createVoorschriftInLineage = useCreateVoorschriftInLineage();

  const modalState = useModalState();

  const handleIntrekken = () => {
    modalState.action('save');

    createVoorschriftInLineage.mutate(
      {
        link,
        voorschrift: {
          ...voorschrift,
          Stop_Lineage: 1,
        },
      },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) =>
          modalState.error('save', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
          }),
      }
    );
  };

  return (
    <Modal state={modalState.state} title={`Intrekken voorschrift ${nummer}`} {...props}>
      <p>
        Je staat op het punt om <strong>voorschrift {nummer}</strong> in te trekken.
      </p>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="save"
          onClick={handleIntrekken}
          successLabel="Voorschrift ingetrokken"
        >
          Voorschrift intrekken
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
