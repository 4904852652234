import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDVoorschriftSchema } from '@/api';
import { usePatchStdVoorschriftId } from '@/api/queries/std';
import { Button, FormLabel, Input, Modal, TextEditor, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useOpenModal } from '@/modals/utils/modalStore';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface StandaardtekstenEditVoorschriftProps {
  voorschrift: STDVoorschriftSchema;
}

type FormValues = Pick<STDVoorschriftSchema, 'Nummer' | 'Voorschrift' | 'Toelichting'>;

export const STANDAARDTEKSTEN_EDIT_VOORSCHRIFT_ID = 'standaardtekstenEditVoorschriftId';

export const StandaardtekstenEditVoorschrift = modal(
  STANDAARDTEKSTEN_EDIT_VOORSCHRIFT_ID,
  ({ data, props }) => {
    const modalState = useModalState();
    const openModal = useOpenModal();

    const {
      voorschrift: { ID, Nummer, Voorschrift, Toelichting },
      voorschrift,
    } = data;

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>({
      defaultValues: {
        Nummer,
        Voorschrift,
        Toelichting,
      },
    });

    const patchStdVoorschrift = usePatchStdVoorschriftId();

    const onSubmit: SubmitHandler<FormValues> = (formData) => {
      const userId = useUserStore.getState().user?.ID ?? '';

      modalState.action('save');
      patchStdVoorschrift.mutate(
        {
          data: {
            ...formData,
            Modified_By: userId,
            Modified_Date: new Date().toISOString(),
          },
          id: ID!,
        },
        {
          onSuccess: () => {
            modalState.success('save');
          },
          onError: (error) => {
            modalState.error('save', {
              title: 'Mislukt',
              description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
            });
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Voorschrift bewerken">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Nummer', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
            error={errors.Nummer}
            label="Nummer"
            id="edit-std-voorschrift-nummer"
            required
          />
          <FormLabel htmlFor="bewerken-std-voorschrift-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                ]}
                initialText={Toelichting}
                id="bewerken-std-voorschrift-toelichting"
                testId="edit-object-std-voorschrift-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />
          <FormLabel htmlFor="bewerken-std-voorschrift" required>
            Inhoud (van voorschrift)
          </FormLabel>
          <Controller
            control={control}
            name="Voorschrift"
            rules={{ required: { value: true, message: eStrings.TXT_VERPLICHT_INPUT_VELD } }}
            render={({ field: { onChange, value: Voorschrift } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                  'subscript',
                  'superscript',
                  'table',
                ]}
                error={errors.Voorschrift}
                initialText={Voorschrift}
                id="bewerken-std-voorschrift"
                testId="edit-object-std-voorschrift"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />
          <Modal.Footer>
            <div className="flex w-full justify-between">
              <Button
                variant="white"
                disabled={modalState.state?.type === 'action'}
                onClick={() => {
                  openModal('standaardtekstenVerwijderenVoorschrift', { voorschrift });

                  props.onClose();
                }}
              >
                Voorschrift verwijderen
              </Button>

              <div>
                <Modal.CancelButton />

                <Modal.ActionButton action="save" successLabel="Opgeslagen">
                  Opslaan
                </Modal.ActionButton>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
