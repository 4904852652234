/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Digi-V API
 * This API serves objects related to the operation of Omgevingsdiensten
 * OpenAPI spec version: 1.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ActiviteitCategorieSchema,
  ActiviteitCategorieSchemaOptional,
  BedrijfActiviteitSchema,
  BedrijfActiviteitSchemaOptional,
  BedrijfInstallatieSchema,
  BedrijfInstallatieSchemaOptional,
  BedrijfLineageSchema,
  BedrijfLineageSchemaOptional,
  BedrijfSchema,
  BedrijfSchemaOptional,
  BesluitLineageSchema,
  BesluitLineageSchemaOptional,
  BesluitSchema,
  BesluitSchemaOptional,
  BesluitTypeSchema,
  DefinitieSchema,
  DefinitieSchemaOptional,
  DeleteDynActiviteitCategorieId400,
  DeleteDynActiviteitCategorieId401,
  DeleteDynActiviteitCategorieId403,
  DeleteDynActiviteitCategorieId404,
  DeleteDynActiviteitCategorieId500,
  DeleteDynHistorischBesluitId400,
  DeleteDynHistorischBesluitId401,
  DeleteDynHistorischBesluitId403,
  DeleteDynHistorischBesluitId404,
  DeleteDynHistorischBesluitId500,
  DeleteDynInstallatieTypeId400,
  DeleteDynInstallatieTypeId401,
  DeleteDynInstallatieTypeId403,
  DeleteDynInstallatieTypeId404,
  DeleteDynInstallatieTypeId500,
  DeleteDynLabelId400,
  DeleteDynLabelId401,
  DeleteDynLabelId403,
  DeleteDynLabelId404,
  DeleteDynLabelId500,
  DeleteDynLabelMapId400,
  DeleteDynLabelMapId401,
  DeleteDynLabelMapId403,
  DeleteDynLabelMapId404,
  DeleteDynLabelMapId500,
  DeleteDynMetaBedrijfActiviteitId400,
  DeleteDynMetaBedrijfActiviteitId401,
  DeleteDynMetaBedrijfActiviteitId403,
  DeleteDynMetaBedrijfActiviteitId404,
  DeleteDynMetaBedrijfActiviteitId500,
  DeleteDynMetaBedrijfInstallatieId400,
  DeleteDynMetaBedrijfInstallatieId401,
  DeleteDynMetaBedrijfInstallatieId403,
  DeleteDynMetaBedrijfInstallatieId404,
  DeleteDynMetaBedrijfInstallatieId500,
  DeleteDynMetaStdDefinitieId400,
  DeleteDynMetaStdDefinitieId401,
  DeleteDynMetaStdDefinitieId403,
  DeleteDynMetaStdDefinitieId404,
  DeleteDynMetaStdDefinitieId500,
  DeleteDynMetaStdEmImIssieId400,
  DeleteDynMetaStdEmImIssieId401,
  DeleteDynMetaStdEmImIssieId403,
  DeleteDynMetaStdEmImIssieId404,
  DeleteDynMetaStdEmImIssieId500,
  DeleteDynMetaStdVoorschriftKoppelingId400,
  DeleteDynMetaStdVoorschriftKoppelingId401,
  DeleteDynMetaStdVoorschriftKoppelingId403,
  DeleteDynMetaStdVoorschriftKoppelingId404,
  DeleteDynMetaStdVoorschriftKoppelingId500,
  DeleteDynMetaStdVoorschriftLabelId400,
  DeleteDynMetaStdVoorschriftLabelId401,
  DeleteDynMetaStdVoorschriftLabelId403,
  DeleteDynMetaStdVoorschriftLabelId404,
  DeleteDynMetaStdVoorschriftLabelId500,
  DeleteDynMetaVoorschriftActiviteitInstallatieId400,
  DeleteDynMetaVoorschriftActiviteitInstallatieId401,
  DeleteDynMetaVoorschriftActiviteitInstallatieId403,
  DeleteDynMetaVoorschriftActiviteitInstallatieId404,
  DeleteDynMetaVoorschriftActiviteitInstallatieId500,
  DeleteDynMetaVoorschriftHistorischBesluitId400,
  DeleteDynMetaVoorschriftHistorischBesluitId401,
  DeleteDynMetaVoorschriftHistorischBesluitId403,
  DeleteDynMetaVoorschriftHistorischBesluitId404,
  DeleteDynMetaVoorschriftHistorischBesluitId500,
  DeleteDynMetaVoorschriftLabelId400,
  DeleteDynMetaVoorschriftLabelId401,
  DeleteDynMetaVoorschriftLabelId403,
  DeleteDynMetaVoorschriftLabelId404,
  DeleteDynMetaVoorschriftLabelId500,
  DeleteDynStdMapId400,
  DeleteDynStdMapId401,
  DeleteDynStdMapId403,
  DeleteDynStdMapId404,
  DeleteDynStdMapId500,
  DeleteDynStdParagraafId400,
  DeleteDynStdParagraafId401,
  DeleteDynStdParagraafId403,
  DeleteDynStdParagraafId404,
  DeleteDynStdParagraafId500,
  DeleteDynStdSubparagraafId400,
  DeleteDynStdSubparagraafId401,
  DeleteDynStdSubparagraafId403,
  DeleteDynStdSubparagraafId404,
  DeleteDynStdSubparagraafId500,
  DeleteDynStdVoorschriftId400,
  DeleteDynStdVoorschriftId401,
  DeleteDynStdVoorschriftId403,
  DeleteDynStdVoorschriftId404,
  DeleteDynStdVoorschriftId500,
  DeleteFixBedrijfId400,
  DeleteFixBedrijfId401,
  DeleteFixBedrijfId403,
  DeleteFixBedrijfId404,
  DeleteFixBedrijfId500,
  DeleteFixBesluitId400,
  DeleteFixBesluitId401,
  DeleteFixBesluitId403,
  DeleteFixBesluitId404,
  DeleteFixBesluitId500,
  DeleteFixHoofdstukId400,
  DeleteFixHoofdstukId401,
  DeleteFixHoofdstukId403,
  DeleteFixHoofdstukId404,
  DeleteFixHoofdstukId500,
  DeleteFixLineageBedrijfId400,
  DeleteFixLineageBedrijfId401,
  DeleteFixLineageBedrijfId403,
  DeleteFixLineageBedrijfId404,
  DeleteFixLineageBedrijfId500,
  DeleteFixLineageBesluitId400,
  DeleteFixLineageBesluitId401,
  DeleteFixLineageBesluitId403,
  DeleteFixLineageBesluitId404,
  DeleteFixLineageBesluitId500,
  DeleteFixLineageHoofdstukId400,
  DeleteFixLineageHoofdstukId401,
  DeleteFixLineageHoofdstukId403,
  DeleteFixLineageHoofdstukId404,
  DeleteFixLineageHoofdstukId500,
  DeleteFixLineageParagraafId400,
  DeleteFixLineageParagraafId401,
  DeleteFixLineageParagraafId403,
  DeleteFixLineageParagraafId404,
  DeleteFixLineageParagraafId500,
  DeleteFixLineageSubparagraafId400,
  DeleteFixLineageSubparagraafId401,
  DeleteFixLineageSubparagraafId403,
  DeleteFixLineageSubparagraafId404,
  DeleteFixLineageSubparagraafId500,
  DeleteFixLineageVoorschriftId400,
  DeleteFixLineageVoorschriftId401,
  DeleteFixLineageVoorschriftId403,
  DeleteFixLineageVoorschriftId404,
  DeleteFixLineageVoorschriftId500,
  DeleteFixLinkHbId400,
  DeleteFixLinkHbId401,
  DeleteFixLinkHbId403,
  DeleteFixLinkHbId404,
  DeleteFixLinkHbId500,
  DeleteFixLinkPhbId400,
  DeleteFixLinkPhbId401,
  DeleteFixLinkPhbId403,
  DeleteFixLinkPhbId404,
  DeleteFixLinkPhbId500,
  DeleteFixLinkSppbId400,
  DeleteFixLinkSppbId401,
  DeleteFixLinkSppbId403,
  DeleteFixLinkSppbId404,
  DeleteFixLinkSppbId500,
  DeleteFixLinkVspbId400,
  DeleteFixLinkVspbId401,
  DeleteFixLinkVspbId403,
  DeleteFixLinkVspbId404,
  DeleteFixLinkVspbId500,
  DeleteFixMetaDefinitieId400,
  DeleteFixMetaDefinitieId401,
  DeleteFixMetaDefinitieId403,
  DeleteFixMetaDefinitieId404,
  DeleteFixMetaDefinitieId500,
  DeleteFixMetaEmImIssieId400,
  DeleteFixMetaEmImIssieId401,
  DeleteFixMetaEmImIssieId403,
  DeleteFixMetaEmImIssieId404,
  DeleteFixMetaEmImIssieId500,
  DeleteFixMetaOverwegingHoofdstukId400,
  DeleteFixMetaOverwegingHoofdstukId401,
  DeleteFixMetaOverwegingHoofdstukId403,
  DeleteFixMetaOverwegingHoofdstukId404,
  DeleteFixMetaOverwegingHoofdstukId500,
  DeleteFixMetaVoorschriftKoppelingId400,
  DeleteFixMetaVoorschriftKoppelingId401,
  DeleteFixMetaVoorschriftKoppelingId403,
  DeleteFixMetaVoorschriftKoppelingId404,
  DeleteFixMetaVoorschriftKoppelingId500,
  DeleteFixParagraafId400,
  DeleteFixParagraafId401,
  DeleteFixParagraafId403,
  DeleteFixParagraafId404,
  DeleteFixParagraafId500,
  DeleteFixSubparagraafId400,
  DeleteFixSubparagraafId401,
  DeleteFixSubparagraafId403,
  DeleteFixSubparagraafId404,
  DeleteFixSubparagraafId500,
  DeleteFixVoorschriftId400,
  DeleteFixVoorschriftId401,
  DeleteFixVoorschriftId403,
  DeleteFixVoorschriftId404,
  DeleteFixVoorschriftId500,
  DeleteOthHoofdstukReserveringId400,
  DeleteOthHoofdstukReserveringId401,
  DeleteOthHoofdstukReserveringId403,
  DeleteOthHoofdstukReserveringId404,
  DeleteOthHoofdstukReserveringId500,
  EmImIssieSchema,
  EmImIssieSchemaOptional,
  GebruikerSchema,
  Get200,
  GetAuthTokeninfo200,
  GetDynActiviteitCategorie200,
  GetDynActiviteitCategorie400,
  GetDynActiviteitCategorie404,
  GetDynActiviteitCategorie500,
  GetDynActiviteitCategorieId404,
  GetDynActiviteitCategorieId500,
  GetDynActiviteitCategorieParams,
  GetDynHistorischBesluit200,
  GetDynHistorischBesluit400,
  GetDynHistorischBesluit404,
  GetDynHistorischBesluit500,
  GetDynHistorischBesluitId404,
  GetDynHistorischBesluitId500,
  GetDynHistorischBesluitParams,
  GetDynInstallatieType200,
  GetDynInstallatieType400,
  GetDynInstallatieType404,
  GetDynInstallatieType500,
  GetDynInstallatieTypeId404,
  GetDynInstallatieTypeId500,
  GetDynInstallatieTypeParams,
  GetDynLabel200,
  GetDynLabel400,
  GetDynLabel404,
  GetDynLabel500,
  GetDynLabelId404,
  GetDynLabelId500,
  GetDynLabelMap200,
  GetDynLabelMap400,
  GetDynLabelMap404,
  GetDynLabelMap500,
  GetDynLabelMapId404,
  GetDynLabelMapId500,
  GetDynLabelMapParams,
  GetDynLabelParams,
  GetDynMetaBedrijfActiviteit200,
  GetDynMetaBedrijfActiviteit400,
  GetDynMetaBedrijfActiviteit404,
  GetDynMetaBedrijfActiviteit500,
  GetDynMetaBedrijfActiviteitId404,
  GetDynMetaBedrijfActiviteitId500,
  GetDynMetaBedrijfActiviteitParams,
  GetDynMetaBedrijfInstallatie200,
  GetDynMetaBedrijfInstallatie400,
  GetDynMetaBedrijfInstallatie404,
  GetDynMetaBedrijfInstallatie500,
  GetDynMetaBedrijfInstallatieId404,
  GetDynMetaBedrijfInstallatieId500,
  GetDynMetaBedrijfInstallatieParams,
  GetDynMetaStdDefinitie200,
  GetDynMetaStdDefinitie400,
  GetDynMetaStdDefinitie404,
  GetDynMetaStdDefinitie500,
  GetDynMetaStdDefinitieId404,
  GetDynMetaStdDefinitieId500,
  GetDynMetaStdDefinitieParams,
  GetDynMetaStdEmImIssie200,
  GetDynMetaStdEmImIssie400,
  GetDynMetaStdEmImIssie404,
  GetDynMetaStdEmImIssie500,
  GetDynMetaStdEmImIssieId404,
  GetDynMetaStdEmImIssieId500,
  GetDynMetaStdEmImIssieParams,
  GetDynMetaStdVoorschriftKoppeling200,
  GetDynMetaStdVoorschriftKoppeling400,
  GetDynMetaStdVoorschriftKoppeling404,
  GetDynMetaStdVoorschriftKoppeling500,
  GetDynMetaStdVoorschriftKoppelingId404,
  GetDynMetaStdVoorschriftKoppelingId500,
  GetDynMetaStdVoorschriftKoppelingParams,
  GetDynMetaStdVoorschriftLabel200,
  GetDynMetaStdVoorschriftLabel400,
  GetDynMetaStdVoorschriftLabel404,
  GetDynMetaStdVoorschriftLabel500,
  GetDynMetaStdVoorschriftLabelId404,
  GetDynMetaStdVoorschriftLabelId500,
  GetDynMetaStdVoorschriftLabelParams,
  GetDynMetaVoorschriftActiviteitInstallatie200,
  GetDynMetaVoorschriftActiviteitInstallatie400,
  GetDynMetaVoorschriftActiviteitInstallatie404,
  GetDynMetaVoorschriftActiviteitInstallatie500,
  GetDynMetaVoorschriftActiviteitInstallatieId404,
  GetDynMetaVoorschriftActiviteitInstallatieId500,
  GetDynMetaVoorschriftActiviteitInstallatieParams,
  GetDynMetaVoorschriftHistorischBesluit200,
  GetDynMetaVoorschriftHistorischBesluit400,
  GetDynMetaVoorschriftHistorischBesluit404,
  GetDynMetaVoorschriftHistorischBesluit500,
  GetDynMetaVoorschriftHistorischBesluitId404,
  GetDynMetaVoorschriftHistorischBesluitId500,
  GetDynMetaVoorschriftHistorischBesluitParams,
  GetDynMetaVoorschriftLabel200,
  GetDynMetaVoorschriftLabel400,
  GetDynMetaVoorschriftLabel404,
  GetDynMetaVoorschriftLabel500,
  GetDynMetaVoorschriftLabelId404,
  GetDynMetaVoorschriftLabelId500,
  GetDynMetaVoorschriftLabelParams,
  GetDynStdMap200,
  GetDynStdMap400,
  GetDynStdMap404,
  GetDynStdMap500,
  GetDynStdMapId404,
  GetDynStdMapId500,
  GetDynStdMapParams,
  GetDynStdParagraaf200,
  GetDynStdParagraaf400,
  GetDynStdParagraaf404,
  GetDynStdParagraaf500,
  GetDynStdParagraafId404,
  GetDynStdParagraafId500,
  GetDynStdParagraafParams,
  GetDynStdSubparagraaf200,
  GetDynStdSubparagraaf400,
  GetDynStdSubparagraaf404,
  GetDynStdSubparagraaf500,
  GetDynStdSubparagraafId404,
  GetDynStdSubparagraafId500,
  GetDynStdSubparagraafParams,
  GetDynStdVoorschrift200,
  GetDynStdVoorschrift400,
  GetDynStdVoorschrift404,
  GetDynStdVoorschrift500,
  GetDynStdVoorschriftId404,
  GetDynStdVoorschriftId500,
  GetDynStdVoorschriftParams,
  GetFixBedrijf200,
  GetFixBedrijf400,
  GetFixBedrijf404,
  GetFixBedrijf500,
  GetFixBedrijfId404,
  GetFixBedrijfId500,
  GetFixBedrijfParams,
  GetFixBesluit200,
  GetFixBesluit400,
  GetFixBesluit404,
  GetFixBesluit500,
  GetFixBesluitId404,
  GetFixBesluitId500,
  GetFixBesluitParams,
  GetFixHoofdstuk200,
  GetFixHoofdstuk400,
  GetFixHoofdstuk404,
  GetFixHoofdstuk500,
  GetFixHoofdstukId404,
  GetFixHoofdstukId500,
  GetFixHoofdstukParams,
  GetFixLineageBedrijf200,
  GetFixLineageBedrijf400,
  GetFixLineageBedrijf404,
  GetFixLineageBedrijf500,
  GetFixLineageBedrijfId404,
  GetFixLineageBedrijfId500,
  GetFixLineageBedrijfParams,
  GetFixLineageBesluit200,
  GetFixLineageBesluit400,
  GetFixLineageBesluit404,
  GetFixLineageBesluit500,
  GetFixLineageBesluitId404,
  GetFixLineageBesluitId500,
  GetFixLineageBesluitParams,
  GetFixLineageHoofdstuk200,
  GetFixLineageHoofdstuk400,
  GetFixLineageHoofdstuk404,
  GetFixLineageHoofdstuk500,
  GetFixLineageHoofdstukId404,
  GetFixLineageHoofdstukId500,
  GetFixLineageHoofdstukParams,
  GetFixLineageParagraaf200,
  GetFixLineageParagraaf400,
  GetFixLineageParagraaf404,
  GetFixLineageParagraaf500,
  GetFixLineageParagraafId404,
  GetFixLineageParagraafId500,
  GetFixLineageParagraafParams,
  GetFixLineageSubparagraaf200,
  GetFixLineageSubparagraaf400,
  GetFixLineageSubparagraaf404,
  GetFixLineageSubparagraaf500,
  GetFixLineageSubparagraafId404,
  GetFixLineageSubparagraafId500,
  GetFixLineageSubparagraafParams,
  GetFixLineageVoorschrift200,
  GetFixLineageVoorschrift400,
  GetFixLineageVoorschrift404,
  GetFixLineageVoorschrift500,
  GetFixLineageVoorschriftId404,
  GetFixLineageVoorschriftId500,
  GetFixLineageVoorschriftParams,
  GetFixLinkHb200,
  GetFixLinkHb400,
  GetFixLinkHb404,
  GetFixLinkHb500,
  GetFixLinkHbId404,
  GetFixLinkHbId500,
  GetFixLinkHbParams,
  GetFixLinkPhb200,
  GetFixLinkPhb400,
  GetFixLinkPhb404,
  GetFixLinkPhb500,
  GetFixLinkPhbId404,
  GetFixLinkPhbId500,
  GetFixLinkPhbParams,
  GetFixLinkSppb200,
  GetFixLinkSppb400,
  GetFixLinkSppb404,
  GetFixLinkSppb500,
  GetFixLinkSppbId404,
  GetFixLinkSppbId500,
  GetFixLinkSppbParams,
  GetFixLinkVspb200,
  GetFixLinkVspb400,
  GetFixLinkVspb404,
  GetFixLinkVspb500,
  GetFixLinkVspbId404,
  GetFixLinkVspbId500,
  GetFixLinkVspbParams,
  GetFixMetaDefinitie200,
  GetFixMetaDefinitie400,
  GetFixMetaDefinitie404,
  GetFixMetaDefinitie500,
  GetFixMetaDefinitieId404,
  GetFixMetaDefinitieId500,
  GetFixMetaDefinitieParams,
  GetFixMetaEmImIssie200,
  GetFixMetaEmImIssie400,
  GetFixMetaEmImIssie404,
  GetFixMetaEmImIssie500,
  GetFixMetaEmImIssieId404,
  GetFixMetaEmImIssieId500,
  GetFixMetaEmImIssieParams,
  GetFixMetaOverwegingHoofdstuk200,
  GetFixMetaOverwegingHoofdstuk400,
  GetFixMetaOverwegingHoofdstuk404,
  GetFixMetaOverwegingHoofdstuk500,
  GetFixMetaOverwegingHoofdstukId404,
  GetFixMetaOverwegingHoofdstukId500,
  GetFixMetaOverwegingHoofdstukParams,
  GetFixMetaVoorschriftKoppeling200,
  GetFixMetaVoorschriftKoppeling400,
  GetFixMetaVoorschriftKoppeling404,
  GetFixMetaVoorschriftKoppeling500,
  GetFixMetaVoorschriftKoppelingId404,
  GetFixMetaVoorschriftKoppelingId500,
  GetFixMetaVoorschriftKoppelingParams,
  GetFixParagraaf200,
  GetFixParagraaf400,
  GetFixParagraaf404,
  GetFixParagraaf500,
  GetFixParagraafId404,
  GetFixParagraafId500,
  GetFixParagraafParams,
  GetFixSubparagraaf200,
  GetFixSubparagraaf400,
  GetFixSubparagraaf404,
  GetFixSubparagraaf500,
  GetFixSubparagraafId404,
  GetFixSubparagraafId500,
  GetFixSubparagraafParams,
  GetFixVoorschrift200,
  GetFixVoorschrift400,
  GetFixVoorschrift404,
  GetFixVoorschrift500,
  GetFixVoorschriftId404,
  GetFixVoorschriftId500,
  GetFixVoorschriftParams,
  GetHealth200,
  GetOthHoofdstukReservering200,
  GetOthHoofdstukReservering400,
  GetOthHoofdstukReservering404,
  GetOthHoofdstukReservering500,
  GetOthHoofdstukReserveringId404,
  GetOthHoofdstukReserveringId500,
  GetOthHoofdstukReserveringParams,
  GrondslagSchema,
  HBSchema,
  HBSchemaOptional,
  HistorischBesluitSchema,
  HistorischBesluitSchemaOptional,
  HoofdstukLineageSchema,
  HoofdstukLineageSchemaOptional,
  HoofdstukReserveringSchema,
  HoofdstukReserveringSchemaOptional,
  HoofdstukSchema,
  HoofdstukSchemaOptional,
  InstallatieTypeSchema,
  InstallatieTypeSchemaOptional,
  LabelMapSchema,
  LabelMapSchemaOptional,
  LabelSchema,
  LabelSchemaOptional,
  OverwegingHoofdstukSchema,
  OverwegingHoofdstukSchemaOptional,
  PHBSchema,
  PHBSchemaOptional,
  ParagraafLineageSchema,
  ParagraafLineageSchemaOptional,
  ParagraafSchema,
  ParagraafSchemaOptional,
  PatchDynActiviteitCategorieId400,
  PatchDynActiviteitCategorieId401,
  PatchDynActiviteitCategorieId403,
  PatchDynActiviteitCategorieId404,
  PatchDynActiviteitCategorieId500,
  PatchDynHistorischBesluitId400,
  PatchDynHistorischBesluitId401,
  PatchDynHistorischBesluitId403,
  PatchDynHistorischBesluitId404,
  PatchDynHistorischBesluitId500,
  PatchDynInstallatieTypeId400,
  PatchDynInstallatieTypeId401,
  PatchDynInstallatieTypeId403,
  PatchDynInstallatieTypeId404,
  PatchDynInstallatieTypeId500,
  PatchDynLabelId400,
  PatchDynLabelId401,
  PatchDynLabelId403,
  PatchDynLabelId404,
  PatchDynLabelId500,
  PatchDynLabelMapId400,
  PatchDynLabelMapId401,
  PatchDynLabelMapId403,
  PatchDynLabelMapId404,
  PatchDynLabelMapId500,
  PatchDynMetaBedrijfActiviteitId400,
  PatchDynMetaBedrijfActiviteitId401,
  PatchDynMetaBedrijfActiviteitId403,
  PatchDynMetaBedrijfActiviteitId404,
  PatchDynMetaBedrijfActiviteitId500,
  PatchDynMetaBedrijfInstallatieId400,
  PatchDynMetaBedrijfInstallatieId401,
  PatchDynMetaBedrijfInstallatieId403,
  PatchDynMetaBedrijfInstallatieId404,
  PatchDynMetaBedrijfInstallatieId500,
  PatchDynMetaStdDefinitieId400,
  PatchDynMetaStdDefinitieId401,
  PatchDynMetaStdDefinitieId403,
  PatchDynMetaStdDefinitieId404,
  PatchDynMetaStdDefinitieId500,
  PatchDynMetaStdEmImIssieId400,
  PatchDynMetaStdEmImIssieId401,
  PatchDynMetaStdEmImIssieId403,
  PatchDynMetaStdEmImIssieId404,
  PatchDynMetaStdEmImIssieId500,
  PatchDynMetaStdVoorschriftKoppelingId400,
  PatchDynMetaStdVoorschriftKoppelingId401,
  PatchDynMetaStdVoorschriftKoppelingId403,
  PatchDynMetaStdVoorschriftKoppelingId404,
  PatchDynMetaStdVoorschriftKoppelingId500,
  PatchDynMetaStdVoorschriftLabelId400,
  PatchDynMetaStdVoorschriftLabelId401,
  PatchDynMetaStdVoorschriftLabelId403,
  PatchDynMetaStdVoorschriftLabelId404,
  PatchDynMetaStdVoorschriftLabelId500,
  PatchDynMetaVoorschriftActiviteitInstallatieId400,
  PatchDynMetaVoorschriftActiviteitInstallatieId401,
  PatchDynMetaVoorschriftActiviteitInstallatieId403,
  PatchDynMetaVoorschriftActiviteitInstallatieId404,
  PatchDynMetaVoorschriftActiviteitInstallatieId500,
  PatchDynMetaVoorschriftHistorischBesluitId400,
  PatchDynMetaVoorschriftHistorischBesluitId401,
  PatchDynMetaVoorschriftHistorischBesluitId403,
  PatchDynMetaVoorschriftHistorischBesluitId404,
  PatchDynMetaVoorschriftHistorischBesluitId500,
  PatchDynMetaVoorschriftLabelId400,
  PatchDynMetaVoorschriftLabelId401,
  PatchDynMetaVoorschriftLabelId403,
  PatchDynMetaVoorschriftLabelId404,
  PatchDynMetaVoorschriftLabelId500,
  PatchDynStdMapId400,
  PatchDynStdMapId401,
  PatchDynStdMapId403,
  PatchDynStdMapId404,
  PatchDynStdMapId500,
  PatchDynStdParagraafId400,
  PatchDynStdParagraafId401,
  PatchDynStdParagraafId403,
  PatchDynStdParagraafId404,
  PatchDynStdParagraafId500,
  PatchDynStdSubparagraafId400,
  PatchDynStdSubparagraafId401,
  PatchDynStdSubparagraafId403,
  PatchDynStdSubparagraafId404,
  PatchDynStdSubparagraafId500,
  PatchDynStdVoorschriftId400,
  PatchDynStdVoorschriftId401,
  PatchDynStdVoorschriftId403,
  PatchDynStdVoorschriftId404,
  PatchDynStdVoorschriftId500,
  PatchFixBedrijfId400,
  PatchFixBedrijfId401,
  PatchFixBedrijfId403,
  PatchFixBedrijfId404,
  PatchFixBedrijfId500,
  PatchFixBesluitId400,
  PatchFixBesluitId401,
  PatchFixBesluitId403,
  PatchFixBesluitId404,
  PatchFixBesluitId500,
  PatchFixHoofdstukId400,
  PatchFixHoofdstukId401,
  PatchFixHoofdstukId403,
  PatchFixHoofdstukId404,
  PatchFixHoofdstukId500,
  PatchFixLineageBedrijfId400,
  PatchFixLineageBedrijfId401,
  PatchFixLineageBedrijfId403,
  PatchFixLineageBedrijfId404,
  PatchFixLineageBedrijfId500,
  PatchFixLineageBesluitId400,
  PatchFixLineageBesluitId401,
  PatchFixLineageBesluitId403,
  PatchFixLineageBesluitId404,
  PatchFixLineageBesluitId500,
  PatchFixLineageHoofdstukId400,
  PatchFixLineageHoofdstukId401,
  PatchFixLineageHoofdstukId403,
  PatchFixLineageHoofdstukId404,
  PatchFixLineageHoofdstukId500,
  PatchFixLineageParagraafId400,
  PatchFixLineageParagraafId401,
  PatchFixLineageParagraafId403,
  PatchFixLineageParagraafId404,
  PatchFixLineageParagraafId500,
  PatchFixLineageSubparagraafId400,
  PatchFixLineageSubparagraafId401,
  PatchFixLineageSubparagraafId403,
  PatchFixLineageSubparagraafId404,
  PatchFixLineageSubparagraafId500,
  PatchFixLineageVoorschriftId400,
  PatchFixLineageVoorschriftId401,
  PatchFixLineageVoorschriftId403,
  PatchFixLineageVoorschriftId404,
  PatchFixLineageVoorschriftId500,
  PatchFixLinkHbId400,
  PatchFixLinkHbId401,
  PatchFixLinkHbId403,
  PatchFixLinkHbId404,
  PatchFixLinkHbId500,
  PatchFixLinkPhbId400,
  PatchFixLinkPhbId401,
  PatchFixLinkPhbId403,
  PatchFixLinkPhbId404,
  PatchFixLinkPhbId500,
  PatchFixLinkSppbId400,
  PatchFixLinkSppbId401,
  PatchFixLinkSppbId403,
  PatchFixLinkSppbId404,
  PatchFixLinkSppbId500,
  PatchFixLinkVspbId400,
  PatchFixLinkVspbId401,
  PatchFixLinkVspbId403,
  PatchFixLinkVspbId404,
  PatchFixLinkVspbId500,
  PatchFixMetaDefinitieId400,
  PatchFixMetaDefinitieId401,
  PatchFixMetaDefinitieId403,
  PatchFixMetaDefinitieId404,
  PatchFixMetaDefinitieId500,
  PatchFixMetaEmImIssieId400,
  PatchFixMetaEmImIssieId401,
  PatchFixMetaEmImIssieId403,
  PatchFixMetaEmImIssieId404,
  PatchFixMetaEmImIssieId500,
  PatchFixMetaOverwegingHoofdstukId400,
  PatchFixMetaOverwegingHoofdstukId401,
  PatchFixMetaOverwegingHoofdstukId403,
  PatchFixMetaOverwegingHoofdstukId404,
  PatchFixMetaOverwegingHoofdstukId500,
  PatchFixMetaVoorschriftKoppelingId400,
  PatchFixMetaVoorschriftKoppelingId401,
  PatchFixMetaVoorschriftKoppelingId403,
  PatchFixMetaVoorschriftKoppelingId404,
  PatchFixMetaVoorschriftKoppelingId500,
  PatchFixParagraafId400,
  PatchFixParagraafId401,
  PatchFixParagraafId403,
  PatchFixParagraafId404,
  PatchFixParagraafId500,
  PatchFixSubparagraafId400,
  PatchFixSubparagraafId401,
  PatchFixSubparagraafId403,
  PatchFixSubparagraafId404,
  PatchFixSubparagraafId500,
  PatchFixVoorschriftId400,
  PatchFixVoorschriftId401,
  PatchFixVoorschriftId403,
  PatchFixVoorschriftId404,
  PatchFixVoorschriftId500,
  PatchOthHoofdstukReserveringId400,
  PatchOthHoofdstukReserveringId401,
  PatchOthHoofdstukReserveringId403,
  PatchOthHoofdstukReserveringId404,
  PatchOthHoofdstukReserveringId500,
  PostAuthLogin200,
  PostAuthLogin400,
  PostAuthLogin401,
  PostAuthLoginBody,
  PostDynActiviteitCategorie400,
  PostDynActiviteitCategorie401,
  PostDynActiviteitCategorie403,
  PostDynActiviteitCategorie500,
  PostDynHistorischBesluit400,
  PostDynHistorischBesluit401,
  PostDynHistorischBesluit403,
  PostDynHistorischBesluit500,
  PostDynInstallatieType400,
  PostDynInstallatieType401,
  PostDynInstallatieType403,
  PostDynInstallatieType500,
  PostDynLabel400,
  PostDynLabel401,
  PostDynLabel403,
  PostDynLabel500,
  PostDynLabelMap400,
  PostDynLabelMap401,
  PostDynLabelMap403,
  PostDynLabelMap500,
  PostDynMetaBedrijfActiviteit400,
  PostDynMetaBedrijfActiviteit401,
  PostDynMetaBedrijfActiviteit403,
  PostDynMetaBedrijfActiviteit500,
  PostDynMetaBedrijfInstallatie400,
  PostDynMetaBedrijfInstallatie401,
  PostDynMetaBedrijfInstallatie403,
  PostDynMetaBedrijfInstallatie500,
  PostDynMetaStdDefinitie400,
  PostDynMetaStdDefinitie401,
  PostDynMetaStdDefinitie403,
  PostDynMetaStdDefinitie500,
  PostDynMetaStdEmImIssie400,
  PostDynMetaStdEmImIssie401,
  PostDynMetaStdEmImIssie403,
  PostDynMetaStdEmImIssie500,
  PostDynMetaStdVoorschriftKoppeling400,
  PostDynMetaStdVoorschriftKoppeling401,
  PostDynMetaStdVoorschriftKoppeling403,
  PostDynMetaStdVoorschriftKoppeling500,
  PostDynMetaStdVoorschriftLabel400,
  PostDynMetaStdVoorschriftLabel401,
  PostDynMetaStdVoorschriftLabel403,
  PostDynMetaStdVoorschriftLabel500,
  PostDynMetaVoorschriftActiviteitInstallatie400,
  PostDynMetaVoorschriftActiviteitInstallatie401,
  PostDynMetaVoorschriftActiviteitInstallatie403,
  PostDynMetaVoorschriftActiviteitInstallatie500,
  PostDynMetaVoorschriftHistorischBesluit400,
  PostDynMetaVoorschriftHistorischBesluit401,
  PostDynMetaVoorschriftHistorischBesluit403,
  PostDynMetaVoorschriftHistorischBesluit500,
  PostDynMetaVoorschriftLabel400,
  PostDynMetaVoorschriftLabel401,
  PostDynMetaVoorschriftLabel403,
  PostDynMetaVoorschriftLabel500,
  PostDynStdMap400,
  PostDynStdMap401,
  PostDynStdMap403,
  PostDynStdMap500,
  PostDynStdParagraaf400,
  PostDynStdParagraaf401,
  PostDynStdParagraaf403,
  PostDynStdParagraaf500,
  PostDynStdSubparagraaf400,
  PostDynStdSubparagraaf401,
  PostDynStdSubparagraaf403,
  PostDynStdSubparagraaf500,
  PostDynStdVoorschrift400,
  PostDynStdVoorschrift401,
  PostDynStdVoorschrift403,
  PostDynStdVoorschrift500,
  PostFixBedrijf400,
  PostFixBedrijf401,
  PostFixBedrijf403,
  PostFixBedrijf500,
  PostFixBesluit400,
  PostFixBesluit401,
  PostFixBesluit403,
  PostFixBesluit500,
  PostFixHoofdstuk400,
  PostFixHoofdstuk401,
  PostFixHoofdstuk403,
  PostFixHoofdstuk500,
  PostFixLineageBedrijf400,
  PostFixLineageBedrijf401,
  PostFixLineageBedrijf403,
  PostFixLineageBedrijf500,
  PostFixLineageBesluit400,
  PostFixLineageBesluit401,
  PostFixLineageBesluit403,
  PostFixLineageBesluit500,
  PostFixLineageHoofdstuk400,
  PostFixLineageHoofdstuk401,
  PostFixLineageHoofdstuk403,
  PostFixLineageHoofdstuk500,
  PostFixLineageParagraaf400,
  PostFixLineageParagraaf401,
  PostFixLineageParagraaf403,
  PostFixLineageParagraaf500,
  PostFixLineageSubparagraaf400,
  PostFixLineageSubparagraaf401,
  PostFixLineageSubparagraaf403,
  PostFixLineageSubparagraaf500,
  PostFixLineageVoorschrift400,
  PostFixLineageVoorschrift401,
  PostFixLineageVoorschrift403,
  PostFixLineageVoorschrift500,
  PostFixLinkHb400,
  PostFixLinkHb401,
  PostFixLinkHb403,
  PostFixLinkHb500,
  PostFixLinkPhb400,
  PostFixLinkPhb401,
  PostFixLinkPhb403,
  PostFixLinkPhb500,
  PostFixLinkSppb400,
  PostFixLinkSppb401,
  PostFixLinkSppb403,
  PostFixLinkSppb500,
  PostFixLinkVspb400,
  PostFixLinkVspb401,
  PostFixLinkVspb403,
  PostFixLinkVspb500,
  PostFixMetaDefinitie400,
  PostFixMetaDefinitie401,
  PostFixMetaDefinitie403,
  PostFixMetaDefinitie500,
  PostFixMetaEmImIssie400,
  PostFixMetaEmImIssie401,
  PostFixMetaEmImIssie403,
  PostFixMetaEmImIssie500,
  PostFixMetaOverwegingHoofdstuk400,
  PostFixMetaOverwegingHoofdstuk401,
  PostFixMetaOverwegingHoofdstuk403,
  PostFixMetaOverwegingHoofdstuk500,
  PostFixMetaVoorschriftKoppeling400,
  PostFixMetaVoorschriftKoppeling401,
  PostFixMetaVoorschriftKoppeling403,
  PostFixMetaVoorschriftKoppeling500,
  PostFixParagraaf400,
  PostFixParagraaf401,
  PostFixParagraaf403,
  PostFixParagraaf500,
  PostFixSubparagraaf400,
  PostFixSubparagraaf401,
  PostFixSubparagraaf403,
  PostFixSubparagraaf500,
  PostFixVoorschrift400,
  PostFixVoorschrift401,
  PostFixVoorschrift403,
  PostFixVoorschrift500,
  PostOthHoofdstukReservering400,
  PostOthHoofdstukReservering401,
  PostOthHoofdstukReservering403,
  PostOthHoofdstukReservering500,
  PutDynActiviteitCategorieId400,
  PutDynActiviteitCategorieId401,
  PutDynActiviteitCategorieId403,
  PutDynActiviteitCategorieId404,
  PutDynActiviteitCategorieId500,
  PutDynHistorischBesluitId400,
  PutDynHistorischBesluitId401,
  PutDynHistorischBesluitId403,
  PutDynHistorischBesluitId404,
  PutDynHistorischBesluitId500,
  PutDynInstallatieTypeId400,
  PutDynInstallatieTypeId401,
  PutDynInstallatieTypeId403,
  PutDynInstallatieTypeId404,
  PutDynInstallatieTypeId500,
  PutDynLabelId400,
  PutDynLabelId401,
  PutDynLabelId403,
  PutDynLabelId404,
  PutDynLabelId500,
  PutDynLabelMapId400,
  PutDynLabelMapId401,
  PutDynLabelMapId403,
  PutDynLabelMapId404,
  PutDynLabelMapId500,
  PutDynMetaBedrijfActiviteitId400,
  PutDynMetaBedrijfActiviteitId401,
  PutDynMetaBedrijfActiviteitId403,
  PutDynMetaBedrijfActiviteitId404,
  PutDynMetaBedrijfActiviteitId500,
  PutDynMetaBedrijfInstallatieId400,
  PutDynMetaBedrijfInstallatieId401,
  PutDynMetaBedrijfInstallatieId403,
  PutDynMetaBedrijfInstallatieId404,
  PutDynMetaBedrijfInstallatieId500,
  PutDynMetaStdDefinitieId400,
  PutDynMetaStdDefinitieId401,
  PutDynMetaStdDefinitieId403,
  PutDynMetaStdDefinitieId404,
  PutDynMetaStdDefinitieId500,
  PutDynMetaStdEmImIssieId400,
  PutDynMetaStdEmImIssieId401,
  PutDynMetaStdEmImIssieId403,
  PutDynMetaStdEmImIssieId404,
  PutDynMetaStdEmImIssieId500,
  PutDynMetaStdVoorschriftKoppelingId400,
  PutDynMetaStdVoorschriftKoppelingId401,
  PutDynMetaStdVoorschriftKoppelingId403,
  PutDynMetaStdVoorschriftKoppelingId404,
  PutDynMetaStdVoorschriftKoppelingId500,
  PutDynMetaStdVoorschriftLabelId400,
  PutDynMetaStdVoorschriftLabelId401,
  PutDynMetaStdVoorschriftLabelId403,
  PutDynMetaStdVoorschriftLabelId404,
  PutDynMetaStdVoorschriftLabelId500,
  PutDynMetaVoorschriftActiviteitInstallatieId400,
  PutDynMetaVoorschriftActiviteitInstallatieId401,
  PutDynMetaVoorschriftActiviteitInstallatieId403,
  PutDynMetaVoorschriftActiviteitInstallatieId404,
  PutDynMetaVoorschriftActiviteitInstallatieId500,
  PutDynMetaVoorschriftHistorischBesluitId400,
  PutDynMetaVoorschriftHistorischBesluitId401,
  PutDynMetaVoorschriftHistorischBesluitId403,
  PutDynMetaVoorschriftHistorischBesluitId404,
  PutDynMetaVoorschriftHistorischBesluitId500,
  PutDynMetaVoorschriftLabelId400,
  PutDynMetaVoorschriftLabelId401,
  PutDynMetaVoorschriftLabelId403,
  PutDynMetaVoorschriftLabelId404,
  PutDynMetaVoorschriftLabelId500,
  PutDynStdMapId400,
  PutDynStdMapId401,
  PutDynStdMapId403,
  PutDynStdMapId404,
  PutDynStdMapId500,
  PutDynStdParagraafId400,
  PutDynStdParagraafId401,
  PutDynStdParagraafId403,
  PutDynStdParagraafId404,
  PutDynStdParagraafId500,
  PutDynStdSubparagraafId400,
  PutDynStdSubparagraafId401,
  PutDynStdSubparagraafId403,
  PutDynStdSubparagraafId404,
  PutDynStdSubparagraafId500,
  PutDynStdVoorschriftId400,
  PutDynStdVoorschriftId401,
  PutDynStdVoorschriftId403,
  PutDynStdVoorschriftId404,
  PutDynStdVoorschriftId500,
  PutFixBedrijfId400,
  PutFixBedrijfId401,
  PutFixBedrijfId403,
  PutFixBedrijfId404,
  PutFixBedrijfId500,
  PutFixBesluitId400,
  PutFixBesluitId401,
  PutFixBesluitId403,
  PutFixBesluitId404,
  PutFixBesluitId500,
  PutFixHoofdstukId400,
  PutFixHoofdstukId401,
  PutFixHoofdstukId403,
  PutFixHoofdstukId404,
  PutFixHoofdstukId500,
  PutFixLineageBedrijfId400,
  PutFixLineageBedrijfId401,
  PutFixLineageBedrijfId403,
  PutFixLineageBedrijfId404,
  PutFixLineageBedrijfId500,
  PutFixLineageBesluitId400,
  PutFixLineageBesluitId401,
  PutFixLineageBesluitId403,
  PutFixLineageBesluitId404,
  PutFixLineageBesluitId500,
  PutFixLineageHoofdstukId400,
  PutFixLineageHoofdstukId401,
  PutFixLineageHoofdstukId403,
  PutFixLineageHoofdstukId404,
  PutFixLineageHoofdstukId500,
  PutFixLineageParagraafId400,
  PutFixLineageParagraafId401,
  PutFixLineageParagraafId403,
  PutFixLineageParagraafId404,
  PutFixLineageParagraafId500,
  PutFixLineageSubparagraafId400,
  PutFixLineageSubparagraafId401,
  PutFixLineageSubparagraafId403,
  PutFixLineageSubparagraafId404,
  PutFixLineageSubparagraafId500,
  PutFixLineageVoorschriftId400,
  PutFixLineageVoorschriftId401,
  PutFixLineageVoorschriftId403,
  PutFixLineageVoorschriftId404,
  PutFixLineageVoorschriftId500,
  PutFixLinkHbId400,
  PutFixLinkHbId401,
  PutFixLinkHbId403,
  PutFixLinkHbId404,
  PutFixLinkHbId500,
  PutFixLinkPhbId400,
  PutFixLinkPhbId401,
  PutFixLinkPhbId403,
  PutFixLinkPhbId404,
  PutFixLinkPhbId500,
  PutFixLinkSppbId400,
  PutFixLinkSppbId401,
  PutFixLinkSppbId403,
  PutFixLinkSppbId404,
  PutFixLinkSppbId500,
  PutFixLinkVspbId400,
  PutFixLinkVspbId401,
  PutFixLinkVspbId403,
  PutFixLinkVspbId404,
  PutFixLinkVspbId500,
  PutFixMetaDefinitieId400,
  PutFixMetaDefinitieId401,
  PutFixMetaDefinitieId403,
  PutFixMetaDefinitieId404,
  PutFixMetaDefinitieId500,
  PutFixMetaEmImIssieId400,
  PutFixMetaEmImIssieId401,
  PutFixMetaEmImIssieId403,
  PutFixMetaEmImIssieId404,
  PutFixMetaEmImIssieId500,
  PutFixMetaOverwegingHoofdstukId400,
  PutFixMetaOverwegingHoofdstukId401,
  PutFixMetaOverwegingHoofdstukId403,
  PutFixMetaOverwegingHoofdstukId404,
  PutFixMetaOverwegingHoofdstukId500,
  PutFixMetaVoorschriftKoppelingId400,
  PutFixMetaVoorschriftKoppelingId401,
  PutFixMetaVoorschriftKoppelingId403,
  PutFixMetaVoorschriftKoppelingId404,
  PutFixMetaVoorschriftKoppelingId500,
  PutFixParagraafId400,
  PutFixParagraafId401,
  PutFixParagraafId403,
  PutFixParagraafId404,
  PutFixParagraafId500,
  PutFixSubparagraafId400,
  PutFixSubparagraafId401,
  PutFixSubparagraafId403,
  PutFixSubparagraafId404,
  PutFixSubparagraafId500,
  PutFixVoorschriftId400,
  PutFixVoorschriftId401,
  PutFixVoorschriftId403,
  PutFixVoorschriftId404,
  PutFixVoorschriftId500,
  PutOthHoofdstukReserveringId400,
  PutOthHoofdstukReserveringId401,
  PutOthHoofdstukReserveringId403,
  PutOthHoofdstukReserveringId404,
  PutOthHoofdstukReserveringId500,
  SPPBSchema,
  SPPBSchemaOptional,
  STDDefinitieSchema,
  STDDefinitieSchemaOptional,
  STDEmImIssieSchema,
  STDEmImIssieSchemaOptional,
  STDMapSchema,
  STDMapSchemaOptional,
  STDParagraafSchema,
  STDParagraafSchemaOptional,
  STDSubparagraafSchema,
  STDSubparagraafSchemaOptional,
  STDVoorschriftKoppelingSchema,
  STDVoorschriftKoppelingSchemaOptional,
  STDVoorschriftLabelSchema,
  STDVoorschriftLabelSchemaOptional,
  STDVoorschriftSchema,
  STDVoorschriftSchemaOptional,
  SubparagraafLineageSchema,
  SubparagraafLineageSchemaOptional,
  SubparagraafSchema,
  SubparagraafSchemaOptional,
  VSPBSchema,
  VSPBSchemaOptional,
  VoorschriftActiviteitInstallatieSchema,
  VoorschriftActiviteitInstallatieSchemaOptional,
  VoorschriftHistorischBesluitSchema,
  VoorschriftHistorischBesluitSchemaOptional,
  VoorschriftKoppelingSchema,
  VoorschriftKoppelingSchemaOptional,
  VoorschriftLabelSchema,
  VoorschriftLabelSchemaOptional,
  VoorschriftLineageSchema,
  VoorschriftLineageSchemaOptional,
  VoorschriftSchema,
  VoorschriftSchemaOptional
} from './digiVAPI.schemas'
import { orvalAPI } from '../../services/axios';
import type { ErrorType } from '../../services/axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary API health status
 */
export const get = (
    
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<Get200>(
      {url: `/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetQueryKey = () => {
    return [`/`] as const;
    }

    
export const getGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof get>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof get>>> = ({ signal }) => get(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof get>>>
export type GetInfiniteQueryError = ErrorType<unknown>

/**
 * @summary API health status
 */
export const useGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof get>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetQueryOptions = <TData = Awaited<ReturnType<typeof get>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof get>>> = ({ signal }) => get(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData> & { queryKey: QueryKey }
}

export type GetQueryResult = NonNullable<Awaited<ReturnType<typeof get>>>
export type GetQueryError = ErrorType<unknown>

/**
 * @summary API health status
 */
export const useGet = <TData = Awaited<ReturnType<typeof get>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Log in to the system
 */
export const postAuthLogin = (
    postAuthLoginBody: PostAuthLoginBody,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<PostAuthLogin200>(
      {url: `/auth/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAuthLoginBody
    },
      options);
    }
  


export const getPostAuthLoginMutationOptions = <TError = ErrorType<PostAuthLogin400 | PostAuthLogin401>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuthLogin>>, TError,{data: PostAuthLoginBody}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postAuthLogin>>, TError,{data: PostAuthLoginBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAuthLogin>>, {data: PostAuthLoginBody}> = (props) => {
          const {data} = props ?? {};

          return  postAuthLogin(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postAuthLogin>>>
    export type PostAuthLoginMutationBody = PostAuthLoginBody
    export type PostAuthLoginMutationError = ErrorType<PostAuthLogin400 | PostAuthLogin401>

    /**
 * @summary Log in to the system
 */
export const usePostAuthLogin = <TError = ErrorType<PostAuthLogin400 | PostAuthLogin401>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuthLogin>>, TError,{data: PostAuthLoginBody}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postAuthLogin>>,
        TError,
        {data: PostAuthLoginBody},
        TContext
      > => {

      const mutationOptions = getPostAuthLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get JWT token information
 */
export const getAuthTokeninfo = (
    
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetAuthTokeninfo200>(
      {url: `/auth/tokeninfo`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAuthTokeninfoQueryKey = () => {
    return [`/auth/tokeninfo`] as const;
    }

    
export const getGetAuthTokeninfoInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAuthTokeninfo>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAuthTokeninfo>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuthTokeninfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthTokeninfo>>> = ({ signal }) => getAuthTokeninfo(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAuthTokeninfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAuthTokeninfoInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAuthTokeninfo>>>
export type GetAuthTokeninfoInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get JWT token information
 */
export const useGetAuthTokeninfoInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAuthTokeninfo>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAuthTokeninfo>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAuthTokeninfoInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAuthTokeninfoQueryOptions = <TData = Awaited<ReturnType<typeof getAuthTokeninfo>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthTokeninfo>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuthTokeninfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthTokeninfo>>> = ({ signal }) => getAuthTokeninfo(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAuthTokeninfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAuthTokeninfoQueryResult = NonNullable<Awaited<ReturnType<typeof getAuthTokeninfo>>>
export type GetAuthTokeninfoQueryError = ErrorType<unknown>

/**
 * @summary Get JWT token information
 */
export const useGetAuthTokeninfo = <TData = Awaited<ReturnType<typeof getAuthTokeninfo>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthTokeninfo>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAuthTokeninfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynActiviteitCategorie = (
    params: GetDynActiviteitCategorieParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynActiviteitCategorie200>(
      {url: `/dyn/activiteit_categorie`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynActiviteitCategorieQueryKey = (params: GetDynActiviteitCategorieParams,) => {
    return [`/dyn/activiteit_categorie`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynActiviteitCategorieInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynActiviteitCategorie>>>, TError = ErrorType<GetDynActiviteitCategorie400 | GetDynActiviteitCategorie404 | GetDynActiviteitCategorie500>>(params: GetDynActiviteitCategorieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynActiviteitCategorieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynActiviteitCategorie>>> = ({ signal }) => getDynActiviteitCategorie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynActiviteitCategorieInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynActiviteitCategorie>>>
export type GetDynActiviteitCategorieInfiniteQueryError = ErrorType<GetDynActiviteitCategorie400 | GetDynActiviteitCategorie404 | GetDynActiviteitCategorie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynActiviteitCategorieInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynActiviteitCategorie>>>, TError = ErrorType<GetDynActiviteitCategorie400 | GetDynActiviteitCategorie404 | GetDynActiviteitCategorie500>>(
 params: GetDynActiviteitCategorieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynActiviteitCategorieInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynActiviteitCategorieQueryOptions = <TData = Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError = ErrorType<GetDynActiviteitCategorie400 | GetDynActiviteitCategorie404 | GetDynActiviteitCategorie500>>(params: GetDynActiviteitCategorieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynActiviteitCategorieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynActiviteitCategorie>>> = ({ signal }) => getDynActiviteitCategorie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynActiviteitCategorieQueryResult = NonNullable<Awaited<ReturnType<typeof getDynActiviteitCategorie>>>
export type GetDynActiviteitCategorieQueryError = ErrorType<GetDynActiviteitCategorie400 | GetDynActiviteitCategorie404 | GetDynActiviteitCategorie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynActiviteitCategorie = <TData = Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError = ErrorType<GetDynActiviteitCategorie400 | GetDynActiviteitCategorie404 | GetDynActiviteitCategorie500>>(
 params: GetDynActiviteitCategorieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynActiviteitCategorieQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynActiviteitCategorie = (
    activiteitCategorieSchema: ActiviteitCategorieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ActiviteitCategorieSchema>(
      {url: `/dyn/activiteit_categorie`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activiteitCategorieSchema
    },
      options);
    }
  


export const getPostDynActiviteitCategorieMutationOptions = <TError = ErrorType<PostDynActiviteitCategorie400 | PostDynActiviteitCategorie401 | PostDynActiviteitCategorie403 | PostDynActiviteitCategorie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynActiviteitCategorie>>, TError,{data: ActiviteitCategorieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynActiviteitCategorie>>, TError,{data: ActiviteitCategorieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynActiviteitCategorie>>, {data: ActiviteitCategorieSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynActiviteitCategorie(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynActiviteitCategorieMutationResult = NonNullable<Awaited<ReturnType<typeof postDynActiviteitCategorie>>>
    export type PostDynActiviteitCategorieMutationBody = ActiviteitCategorieSchema
    export type PostDynActiviteitCategorieMutationError = ErrorType<PostDynActiviteitCategorie400 | PostDynActiviteitCategorie401 | PostDynActiviteitCategorie403 | PostDynActiviteitCategorie500>

    /**
 * @summary Create a new instance
 */
export const usePostDynActiviteitCategorie = <TError = ErrorType<PostDynActiviteitCategorie400 | PostDynActiviteitCategorie401 | PostDynActiviteitCategorie403 | PostDynActiviteitCategorie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynActiviteitCategorie>>, TError,{data: ActiviteitCategorieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynActiviteitCategorie>>,
        TError,
        {data: ActiviteitCategorieSchema},
        TContext
      > => {

      const mutationOptions = getPostDynActiviteitCategorieMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynActiviteitCategorieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ActiviteitCategorieSchema>(
      {url: `/dyn/activiteit_categorie/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynActiviteitCategorieIdMutationOptions = <TError = ErrorType<DeleteDynActiviteitCategorieId400 | DeleteDynActiviteitCategorieId401 | DeleteDynActiviteitCategorieId403 | DeleteDynActiviteitCategorieId404 | DeleteDynActiviteitCategorieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynActiviteitCategorieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynActiviteitCategorieId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynActiviteitCategorieId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynActiviteitCategorieId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynActiviteitCategorieIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynActiviteitCategorieId>>>
    
    export type DeleteDynActiviteitCategorieIdMutationError = ErrorType<DeleteDynActiviteitCategorieId400 | DeleteDynActiviteitCategorieId401 | DeleteDynActiviteitCategorieId403 | DeleteDynActiviteitCategorieId404 | DeleteDynActiviteitCategorieId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynActiviteitCategorieId = <TError = ErrorType<DeleteDynActiviteitCategorieId400 | DeleteDynActiviteitCategorieId401 | DeleteDynActiviteitCategorieId403 | DeleteDynActiviteitCategorieId404 | DeleteDynActiviteitCategorieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynActiviteitCategorieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynActiviteitCategorieId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynActiviteitCategorieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynActiviteitCategorieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<ActiviteitCategorieSchema>(
      {url: `/dyn/activiteit_categorie/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynActiviteitCategorieIdQueryKey = (id: string,) => {
    return [`/dyn/activiteit_categorie/${id}`] as const;
    }

    
export const getGetDynActiviteitCategorieIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>>, TError = ErrorType<GetDynActiviteitCategorieId404 | GetDynActiviteitCategorieId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynActiviteitCategorieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>> = ({ signal }) => getDynActiviteitCategorieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynActiviteitCategorieIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>>
export type GetDynActiviteitCategorieIdInfiniteQueryError = ErrorType<GetDynActiviteitCategorieId404 | GetDynActiviteitCategorieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynActiviteitCategorieIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>>, TError = ErrorType<GetDynActiviteitCategorieId404 | GetDynActiviteitCategorieId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynActiviteitCategorieIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynActiviteitCategorieIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError = ErrorType<GetDynActiviteitCategorieId404 | GetDynActiviteitCategorieId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynActiviteitCategorieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>> = ({ signal }) => getDynActiviteitCategorieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynActiviteitCategorieIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>>
export type GetDynActiviteitCategorieIdQueryError = ErrorType<GetDynActiviteitCategorieId404 | GetDynActiviteitCategorieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynActiviteitCategorieId = <TData = Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError = ErrorType<GetDynActiviteitCategorieId404 | GetDynActiviteitCategorieId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynActiviteitCategorieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynActiviteitCategorieIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynActiviteitCategorieId = (
    id: string,
    activiteitCategorieSchemaOptional: ActiviteitCategorieSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ActiviteitCategorieSchema>(
      {url: `/dyn/activiteit_categorie/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: activiteitCategorieSchemaOptional
    },
      options);
    }
  


export const getPatchDynActiviteitCategorieIdMutationOptions = <TError = ErrorType<PatchDynActiviteitCategorieId400 | PatchDynActiviteitCategorieId401 | PatchDynActiviteitCategorieId403 | PatchDynActiviteitCategorieId404 | PatchDynActiviteitCategorieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynActiviteitCategorieId>>, TError,{id: string;data: ActiviteitCategorieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynActiviteitCategorieId>>, TError,{id: string;data: ActiviteitCategorieSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynActiviteitCategorieId>>, {id: string;data: ActiviteitCategorieSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynActiviteitCategorieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynActiviteitCategorieIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynActiviteitCategorieId>>>
    export type PatchDynActiviteitCategorieIdMutationBody = ActiviteitCategorieSchemaOptional
    export type PatchDynActiviteitCategorieIdMutationError = ErrorType<PatchDynActiviteitCategorieId400 | PatchDynActiviteitCategorieId401 | PatchDynActiviteitCategorieId403 | PatchDynActiviteitCategorieId404 | PatchDynActiviteitCategorieId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynActiviteitCategorieId = <TError = ErrorType<PatchDynActiviteitCategorieId400 | PatchDynActiviteitCategorieId401 | PatchDynActiviteitCategorieId403 | PatchDynActiviteitCategorieId404 | PatchDynActiviteitCategorieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynActiviteitCategorieId>>, TError,{id: string;data: ActiviteitCategorieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynActiviteitCategorieId>>,
        TError,
        {id: string;data: ActiviteitCategorieSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynActiviteitCategorieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynActiviteitCategorieId = (
    id: string,
    activiteitCategorieSchema: ActiviteitCategorieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ActiviteitCategorieSchema>(
      {url: `/dyn/activiteit_categorie/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: activiteitCategorieSchema
    },
      options);
    }
  


export const getPutDynActiviteitCategorieIdMutationOptions = <TError = ErrorType<PutDynActiviteitCategorieId400 | PutDynActiviteitCategorieId401 | PutDynActiviteitCategorieId403 | PutDynActiviteitCategorieId404 | PutDynActiviteitCategorieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynActiviteitCategorieId>>, TError,{id: string;data: ActiviteitCategorieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynActiviteitCategorieId>>, TError,{id: string;data: ActiviteitCategorieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynActiviteitCategorieId>>, {id: string;data: ActiviteitCategorieSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynActiviteitCategorieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynActiviteitCategorieIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynActiviteitCategorieId>>>
    export type PutDynActiviteitCategorieIdMutationBody = ActiviteitCategorieSchema
    export type PutDynActiviteitCategorieIdMutationError = ErrorType<PutDynActiviteitCategorieId400 | PutDynActiviteitCategorieId401 | PutDynActiviteitCategorieId403 | PutDynActiviteitCategorieId404 | PutDynActiviteitCategorieId500>

    /**
 * @summary Update an instance
 */
export const usePutDynActiviteitCategorieId = <TError = ErrorType<PutDynActiviteitCategorieId400 | PutDynActiviteitCategorieId401 | PutDynActiviteitCategorieId403 | PutDynActiviteitCategorieId404 | PutDynActiviteitCategorieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynActiviteitCategorieId>>, TError,{id: string;data: ActiviteitCategorieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynActiviteitCategorieId>>,
        TError,
        {id: string;data: ActiviteitCategorieSchema},
        TContext
      > => {

      const mutationOptions = getPutDynActiviteitCategorieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynHistorischBesluit = (
    params: GetDynHistorischBesluitParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynHistorischBesluit200>(
      {url: `/dyn/historisch_besluit`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynHistorischBesluitQueryKey = (params: GetDynHistorischBesluitParams,) => {
    return [`/dyn/historisch_besluit`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynHistorischBesluitInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynHistorischBesluit>>>, TError = ErrorType<GetDynHistorischBesluit400 | GetDynHistorischBesluit404 | GetDynHistorischBesluit500>>(params: GetDynHistorischBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynHistorischBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynHistorischBesluit>>> = ({ signal }) => getDynHistorischBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynHistorischBesluitInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynHistorischBesluit>>>
export type GetDynHistorischBesluitInfiniteQueryError = ErrorType<GetDynHistorischBesluit400 | GetDynHistorischBesluit404 | GetDynHistorischBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynHistorischBesluitInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynHistorischBesluit>>>, TError = ErrorType<GetDynHistorischBesluit400 | GetDynHistorischBesluit404 | GetDynHistorischBesluit500>>(
 params: GetDynHistorischBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynHistorischBesluitInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynHistorischBesluitQueryOptions = <TData = Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError = ErrorType<GetDynHistorischBesluit400 | GetDynHistorischBesluit404 | GetDynHistorischBesluit500>>(params: GetDynHistorischBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynHistorischBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynHistorischBesluit>>> = ({ signal }) => getDynHistorischBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynHistorischBesluitQueryResult = NonNullable<Awaited<ReturnType<typeof getDynHistorischBesluit>>>
export type GetDynHistorischBesluitQueryError = ErrorType<GetDynHistorischBesluit400 | GetDynHistorischBesluit404 | GetDynHistorischBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynHistorischBesluit = <TData = Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError = ErrorType<GetDynHistorischBesluit400 | GetDynHistorischBesluit404 | GetDynHistorischBesluit500>>(
 params: GetDynHistorischBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynHistorischBesluitQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynHistorischBesluit = (
    historischBesluitSchema: HistorischBesluitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HistorischBesluitSchema>(
      {url: `/dyn/historisch_besluit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: historischBesluitSchema
    },
      options);
    }
  


export const getPostDynHistorischBesluitMutationOptions = <TError = ErrorType<PostDynHistorischBesluit400 | PostDynHistorischBesluit401 | PostDynHistorischBesluit403 | PostDynHistorischBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynHistorischBesluit>>, TError,{data: HistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynHistorischBesluit>>, TError,{data: HistorischBesluitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynHistorischBesluit>>, {data: HistorischBesluitSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynHistorischBesluit(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynHistorischBesluitMutationResult = NonNullable<Awaited<ReturnType<typeof postDynHistorischBesluit>>>
    export type PostDynHistorischBesluitMutationBody = HistorischBesluitSchema
    export type PostDynHistorischBesluitMutationError = ErrorType<PostDynHistorischBesluit400 | PostDynHistorischBesluit401 | PostDynHistorischBesluit403 | PostDynHistorischBesluit500>

    /**
 * @summary Create a new instance
 */
export const usePostDynHistorischBesluit = <TError = ErrorType<PostDynHistorischBesluit400 | PostDynHistorischBesluit401 | PostDynHistorischBesluit403 | PostDynHistorischBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynHistorischBesluit>>, TError,{data: HistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynHistorischBesluit>>,
        TError,
        {data: HistorischBesluitSchema},
        TContext
      > => {

      const mutationOptions = getPostDynHistorischBesluitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynHistorischBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HistorischBesluitSchema>(
      {url: `/dyn/historisch_besluit/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynHistorischBesluitIdMutationOptions = <TError = ErrorType<DeleteDynHistorischBesluitId400 | DeleteDynHistorischBesluitId401 | DeleteDynHistorischBesluitId403 | DeleteDynHistorischBesluitId404 | DeleteDynHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynHistorischBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynHistorischBesluitId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynHistorischBesluitId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynHistorischBesluitId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynHistorischBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynHistorischBesluitId>>>
    
    export type DeleteDynHistorischBesluitIdMutationError = ErrorType<DeleteDynHistorischBesluitId400 | DeleteDynHistorischBesluitId401 | DeleteDynHistorischBesluitId403 | DeleteDynHistorischBesluitId404 | DeleteDynHistorischBesluitId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynHistorischBesluitId = <TError = ErrorType<DeleteDynHistorischBesluitId400 | DeleteDynHistorischBesluitId401 | DeleteDynHistorischBesluitId403 | DeleteDynHistorischBesluitId404 | DeleteDynHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynHistorischBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynHistorischBesluitId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynHistorischBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynHistorischBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<HistorischBesluitSchema>(
      {url: `/dyn/historisch_besluit/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynHistorischBesluitIdQueryKey = (id: string,) => {
    return [`/dyn/historisch_besluit/${id}`] as const;
    }

    
export const getGetDynHistorischBesluitIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynHistorischBesluitId>>>, TError = ErrorType<GetDynHistorischBesluitId404 | GetDynHistorischBesluitId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynHistorischBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynHistorischBesluitId>>> = ({ signal }) => getDynHistorischBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynHistorischBesluitIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynHistorischBesluitId>>>
export type GetDynHistorischBesluitIdInfiniteQueryError = ErrorType<GetDynHistorischBesluitId404 | GetDynHistorischBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynHistorischBesluitIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynHistorischBesluitId>>>, TError = ErrorType<GetDynHistorischBesluitId404 | GetDynHistorischBesluitId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynHistorischBesluitIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynHistorischBesluitIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError = ErrorType<GetDynHistorischBesluitId404 | GetDynHistorischBesluitId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynHistorischBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynHistorischBesluitId>>> = ({ signal }) => getDynHistorischBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynHistorischBesluitIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynHistorischBesluitId>>>
export type GetDynHistorischBesluitIdQueryError = ErrorType<GetDynHistorischBesluitId404 | GetDynHistorischBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynHistorischBesluitId = <TData = Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError = ErrorType<GetDynHistorischBesluitId404 | GetDynHistorischBesluitId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynHistorischBesluitIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynHistorischBesluitId = (
    id: string,
    historischBesluitSchemaOptional: HistorischBesluitSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HistorischBesluitSchema>(
      {url: `/dyn/historisch_besluit/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: historischBesluitSchemaOptional
    },
      options);
    }
  


export const getPatchDynHistorischBesluitIdMutationOptions = <TError = ErrorType<PatchDynHistorischBesluitId400 | PatchDynHistorischBesluitId401 | PatchDynHistorischBesluitId403 | PatchDynHistorischBesluitId404 | PatchDynHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynHistorischBesluitId>>, TError,{id: string;data: HistorischBesluitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynHistorischBesluitId>>, TError,{id: string;data: HistorischBesluitSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynHistorischBesluitId>>, {id: string;data: HistorischBesluitSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynHistorischBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynHistorischBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynHistorischBesluitId>>>
    export type PatchDynHistorischBesluitIdMutationBody = HistorischBesluitSchemaOptional
    export type PatchDynHistorischBesluitIdMutationError = ErrorType<PatchDynHistorischBesluitId400 | PatchDynHistorischBesluitId401 | PatchDynHistorischBesluitId403 | PatchDynHistorischBesluitId404 | PatchDynHistorischBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynHistorischBesluitId = <TError = ErrorType<PatchDynHistorischBesluitId400 | PatchDynHistorischBesluitId401 | PatchDynHistorischBesluitId403 | PatchDynHistorischBesluitId404 | PatchDynHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynHistorischBesluitId>>, TError,{id: string;data: HistorischBesluitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynHistorischBesluitId>>,
        TError,
        {id: string;data: HistorischBesluitSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynHistorischBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynHistorischBesluitId = (
    id: string,
    historischBesluitSchema: HistorischBesluitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HistorischBesluitSchema>(
      {url: `/dyn/historisch_besluit/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: historischBesluitSchema
    },
      options);
    }
  


export const getPutDynHistorischBesluitIdMutationOptions = <TError = ErrorType<PutDynHistorischBesluitId400 | PutDynHistorischBesluitId401 | PutDynHistorischBesluitId403 | PutDynHistorischBesluitId404 | PutDynHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynHistorischBesluitId>>, TError,{id: string;data: HistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynHistorischBesluitId>>, TError,{id: string;data: HistorischBesluitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynHistorischBesluitId>>, {id: string;data: HistorischBesluitSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynHistorischBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynHistorischBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynHistorischBesluitId>>>
    export type PutDynHistorischBesluitIdMutationBody = HistorischBesluitSchema
    export type PutDynHistorischBesluitIdMutationError = ErrorType<PutDynHistorischBesluitId400 | PutDynHistorischBesluitId401 | PutDynHistorischBesluitId403 | PutDynHistorischBesluitId404 | PutDynHistorischBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePutDynHistorischBesluitId = <TError = ErrorType<PutDynHistorischBesluitId400 | PutDynHistorischBesluitId401 | PutDynHistorischBesluitId403 | PutDynHistorischBesluitId404 | PutDynHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynHistorischBesluitId>>, TError,{id: string;data: HistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynHistorischBesluitId>>,
        TError,
        {id: string;data: HistorischBesluitSchema},
        TContext
      > => {

      const mutationOptions = getPutDynHistorischBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynInstallatieType = (
    params: GetDynInstallatieTypeParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynInstallatieType200>(
      {url: `/dyn/installatie_type`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynInstallatieTypeQueryKey = (params: GetDynInstallatieTypeParams,) => {
    return [`/dyn/installatie_type`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynInstallatieTypeInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynInstallatieType>>>, TError = ErrorType<GetDynInstallatieType400 | GetDynInstallatieType404 | GetDynInstallatieType500>>(params: GetDynInstallatieTypeParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynInstallatieType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynInstallatieTypeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynInstallatieType>>> = ({ signal }) => getDynInstallatieType(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynInstallatieType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynInstallatieTypeInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynInstallatieType>>>
export type GetDynInstallatieTypeInfiniteQueryError = ErrorType<GetDynInstallatieType400 | GetDynInstallatieType404 | GetDynInstallatieType500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynInstallatieTypeInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynInstallatieType>>>, TError = ErrorType<GetDynInstallatieType400 | GetDynInstallatieType404 | GetDynInstallatieType500>>(
 params: GetDynInstallatieTypeParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynInstallatieType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynInstallatieTypeInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynInstallatieTypeQueryOptions = <TData = Awaited<ReturnType<typeof getDynInstallatieType>>, TError = ErrorType<GetDynInstallatieType400 | GetDynInstallatieType404 | GetDynInstallatieType500>>(params: GetDynInstallatieTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynInstallatieType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynInstallatieTypeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynInstallatieType>>> = ({ signal }) => getDynInstallatieType(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynInstallatieType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynInstallatieTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getDynInstallatieType>>>
export type GetDynInstallatieTypeQueryError = ErrorType<GetDynInstallatieType400 | GetDynInstallatieType404 | GetDynInstallatieType500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynInstallatieType = <TData = Awaited<ReturnType<typeof getDynInstallatieType>>, TError = ErrorType<GetDynInstallatieType400 | GetDynInstallatieType404 | GetDynInstallatieType500>>(
 params: GetDynInstallatieTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynInstallatieType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynInstallatieTypeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynInstallatieType = (
    installatieTypeSchema: InstallatieTypeSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<InstallatieTypeSchema>(
      {url: `/dyn/installatie_type`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: installatieTypeSchema
    },
      options);
    }
  


export const getPostDynInstallatieTypeMutationOptions = <TError = ErrorType<PostDynInstallatieType400 | PostDynInstallatieType401 | PostDynInstallatieType403 | PostDynInstallatieType500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynInstallatieType>>, TError,{data: InstallatieTypeSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynInstallatieType>>, TError,{data: InstallatieTypeSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynInstallatieType>>, {data: InstallatieTypeSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynInstallatieType(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynInstallatieTypeMutationResult = NonNullable<Awaited<ReturnType<typeof postDynInstallatieType>>>
    export type PostDynInstallatieTypeMutationBody = InstallatieTypeSchema
    export type PostDynInstallatieTypeMutationError = ErrorType<PostDynInstallatieType400 | PostDynInstallatieType401 | PostDynInstallatieType403 | PostDynInstallatieType500>

    /**
 * @summary Create a new instance
 */
export const usePostDynInstallatieType = <TError = ErrorType<PostDynInstallatieType400 | PostDynInstallatieType401 | PostDynInstallatieType403 | PostDynInstallatieType500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynInstallatieType>>, TError,{data: InstallatieTypeSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynInstallatieType>>,
        TError,
        {data: InstallatieTypeSchema},
        TContext
      > => {

      const mutationOptions = getPostDynInstallatieTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynInstallatieTypeId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<InstallatieTypeSchema>(
      {url: `/dyn/installatie_type/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynInstallatieTypeIdMutationOptions = <TError = ErrorType<DeleteDynInstallatieTypeId400 | DeleteDynInstallatieTypeId401 | DeleteDynInstallatieTypeId403 | DeleteDynInstallatieTypeId404 | DeleteDynInstallatieTypeId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynInstallatieTypeId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynInstallatieTypeId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynInstallatieTypeId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynInstallatieTypeId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynInstallatieTypeIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynInstallatieTypeId>>>
    
    export type DeleteDynInstallatieTypeIdMutationError = ErrorType<DeleteDynInstallatieTypeId400 | DeleteDynInstallatieTypeId401 | DeleteDynInstallatieTypeId403 | DeleteDynInstallatieTypeId404 | DeleteDynInstallatieTypeId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynInstallatieTypeId = <TError = ErrorType<DeleteDynInstallatieTypeId400 | DeleteDynInstallatieTypeId401 | DeleteDynInstallatieTypeId403 | DeleteDynInstallatieTypeId404 | DeleteDynInstallatieTypeId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynInstallatieTypeId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynInstallatieTypeId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynInstallatieTypeIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynInstallatieTypeId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<InstallatieTypeSchema>(
      {url: `/dyn/installatie_type/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynInstallatieTypeIdQueryKey = (id: string,) => {
    return [`/dyn/installatie_type/${id}`] as const;
    }

    
export const getGetDynInstallatieTypeIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynInstallatieTypeId>>>, TError = ErrorType<GetDynInstallatieTypeId404 | GetDynInstallatieTypeId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynInstallatieTypeIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynInstallatieTypeId>>> = ({ signal }) => getDynInstallatieTypeId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynInstallatieTypeIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynInstallatieTypeId>>>
export type GetDynInstallatieTypeIdInfiniteQueryError = ErrorType<GetDynInstallatieTypeId404 | GetDynInstallatieTypeId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynInstallatieTypeIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynInstallatieTypeId>>>, TError = ErrorType<GetDynInstallatieTypeId404 | GetDynInstallatieTypeId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynInstallatieTypeIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynInstallatieTypeIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError = ErrorType<GetDynInstallatieTypeId404 | GetDynInstallatieTypeId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynInstallatieTypeIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynInstallatieTypeId>>> = ({ signal }) => getDynInstallatieTypeId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynInstallatieTypeIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynInstallatieTypeId>>>
export type GetDynInstallatieTypeIdQueryError = ErrorType<GetDynInstallatieTypeId404 | GetDynInstallatieTypeId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynInstallatieTypeId = <TData = Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError = ErrorType<GetDynInstallatieTypeId404 | GetDynInstallatieTypeId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynInstallatieTypeId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynInstallatieTypeIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynInstallatieTypeId = (
    id: string,
    installatieTypeSchemaOptional: InstallatieTypeSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<InstallatieTypeSchema>(
      {url: `/dyn/installatie_type/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: installatieTypeSchemaOptional
    },
      options);
    }
  


export const getPatchDynInstallatieTypeIdMutationOptions = <TError = ErrorType<PatchDynInstallatieTypeId400 | PatchDynInstallatieTypeId401 | PatchDynInstallatieTypeId403 | PatchDynInstallatieTypeId404 | PatchDynInstallatieTypeId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynInstallatieTypeId>>, TError,{id: string;data: InstallatieTypeSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynInstallatieTypeId>>, TError,{id: string;data: InstallatieTypeSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynInstallatieTypeId>>, {id: string;data: InstallatieTypeSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynInstallatieTypeId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynInstallatieTypeIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynInstallatieTypeId>>>
    export type PatchDynInstallatieTypeIdMutationBody = InstallatieTypeSchemaOptional
    export type PatchDynInstallatieTypeIdMutationError = ErrorType<PatchDynInstallatieTypeId400 | PatchDynInstallatieTypeId401 | PatchDynInstallatieTypeId403 | PatchDynInstallatieTypeId404 | PatchDynInstallatieTypeId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynInstallatieTypeId = <TError = ErrorType<PatchDynInstallatieTypeId400 | PatchDynInstallatieTypeId401 | PatchDynInstallatieTypeId403 | PatchDynInstallatieTypeId404 | PatchDynInstallatieTypeId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynInstallatieTypeId>>, TError,{id: string;data: InstallatieTypeSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynInstallatieTypeId>>,
        TError,
        {id: string;data: InstallatieTypeSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynInstallatieTypeIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynInstallatieTypeId = (
    id: string,
    installatieTypeSchema: InstallatieTypeSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<InstallatieTypeSchema>(
      {url: `/dyn/installatie_type/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: installatieTypeSchema
    },
      options);
    }
  


export const getPutDynInstallatieTypeIdMutationOptions = <TError = ErrorType<PutDynInstallatieTypeId400 | PutDynInstallatieTypeId401 | PutDynInstallatieTypeId403 | PutDynInstallatieTypeId404 | PutDynInstallatieTypeId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynInstallatieTypeId>>, TError,{id: string;data: InstallatieTypeSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynInstallatieTypeId>>, TError,{id: string;data: InstallatieTypeSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynInstallatieTypeId>>, {id: string;data: InstallatieTypeSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynInstallatieTypeId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynInstallatieTypeIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynInstallatieTypeId>>>
    export type PutDynInstallatieTypeIdMutationBody = InstallatieTypeSchema
    export type PutDynInstallatieTypeIdMutationError = ErrorType<PutDynInstallatieTypeId400 | PutDynInstallatieTypeId401 | PutDynInstallatieTypeId403 | PutDynInstallatieTypeId404 | PutDynInstallatieTypeId500>

    /**
 * @summary Update an instance
 */
export const usePutDynInstallatieTypeId = <TError = ErrorType<PutDynInstallatieTypeId400 | PutDynInstallatieTypeId401 | PutDynInstallatieTypeId403 | PutDynInstallatieTypeId404 | PutDynInstallatieTypeId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynInstallatieTypeId>>, TError,{id: string;data: InstallatieTypeSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynInstallatieTypeId>>,
        TError,
        {id: string;data: InstallatieTypeSchema},
        TContext
      > => {

      const mutationOptions = getPutDynInstallatieTypeIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynLabel = (
    params: GetDynLabelParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynLabel200>(
      {url: `/dyn/label`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynLabelQueryKey = (params: GetDynLabelParams,) => {
    return [`/dyn/label`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynLabelInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabel>>>, TError = ErrorType<GetDynLabel400 | GetDynLabel404 | GetDynLabel500>>(params: GetDynLabelParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabel>>> = ({ signal }) => getDynLabel(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabel>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabel>>>
export type GetDynLabelInfiniteQueryError = ErrorType<GetDynLabel400 | GetDynLabel404 | GetDynLabel500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynLabelInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabel>>>, TError = ErrorType<GetDynLabel400 | GetDynLabel404 | GetDynLabel500>>(
 params: GetDynLabelParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynLabelQueryOptions = <TData = Awaited<ReturnType<typeof getDynLabel>>, TError = ErrorType<GetDynLabel400 | GetDynLabel404 | GetDynLabel500>>(params: GetDynLabelParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabel>>> = ({ signal }) => getDynLabel(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynLabel>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabel>>>
export type GetDynLabelQueryError = ErrorType<GetDynLabel400 | GetDynLabel404 | GetDynLabel500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynLabel = <TData = Awaited<ReturnType<typeof getDynLabel>>, TError = ErrorType<GetDynLabel400 | GetDynLabel404 | GetDynLabel500>>(
 params: GetDynLabelParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynLabel = (
    labelSchema: LabelSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelSchema>(
      {url: `/dyn/label`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: labelSchema
    },
      options);
    }
  


export const getPostDynLabelMutationOptions = <TError = ErrorType<PostDynLabel400 | PostDynLabel401 | PostDynLabel403 | PostDynLabel500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynLabel>>, TError,{data: LabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynLabel>>, TError,{data: LabelSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynLabel>>, {data: LabelSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynLabel(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynLabelMutationResult = NonNullable<Awaited<ReturnType<typeof postDynLabel>>>
    export type PostDynLabelMutationBody = LabelSchema
    export type PostDynLabelMutationError = ErrorType<PostDynLabel400 | PostDynLabel401 | PostDynLabel403 | PostDynLabel500>

    /**
 * @summary Create a new instance
 */
export const usePostDynLabel = <TError = ErrorType<PostDynLabel400 | PostDynLabel401 | PostDynLabel403 | PostDynLabel500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynLabel>>, TError,{data: LabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynLabel>>,
        TError,
        {data: LabelSchema},
        TContext
      > => {

      const mutationOptions = getPostDynLabelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynLabelId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelSchema>(
      {url: `/dyn/label/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynLabelIdMutationOptions = <TError = ErrorType<DeleteDynLabelId400 | DeleteDynLabelId401 | DeleteDynLabelId403 | DeleteDynLabelId404 | DeleteDynLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynLabelId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynLabelId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynLabelId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynLabelId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynLabelId>>>
    
    export type DeleteDynLabelIdMutationError = ErrorType<DeleteDynLabelId400 | DeleteDynLabelId401 | DeleteDynLabelId403 | DeleteDynLabelId404 | DeleteDynLabelId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynLabelId = <TError = ErrorType<DeleteDynLabelId400 | DeleteDynLabelId401 | DeleteDynLabelId403 | DeleteDynLabelId404 | DeleteDynLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynLabelId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynLabelId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynLabelId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<LabelSchema>(
      {url: `/dyn/label/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynLabelIdQueryKey = (id: string,) => {
    return [`/dyn/label/${id}`] as const;
    }

    
export const getGetDynLabelIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabelId>>>, TError = ErrorType<GetDynLabelId404 | GetDynLabelId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabelId>>> = ({ signal }) => getDynLabelId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabelId>>>
export type GetDynLabelIdInfiniteQueryError = ErrorType<GetDynLabelId404 | GetDynLabelId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynLabelIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabelId>>>, TError = ErrorType<GetDynLabelId404 | GetDynLabelId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynLabelIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynLabelId>>, TError = ErrorType<GetDynLabelId404 | GetDynLabelId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabelId>>> = ({ signal }) => getDynLabelId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynLabelId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabelId>>>
export type GetDynLabelIdQueryError = ErrorType<GetDynLabelId404 | GetDynLabelId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynLabelId = <TData = Awaited<ReturnType<typeof getDynLabelId>>, TError = ErrorType<GetDynLabelId404 | GetDynLabelId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynLabelId = (
    id: string,
    labelSchemaOptional: LabelSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelSchema>(
      {url: `/dyn/label/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: labelSchemaOptional
    },
      options);
    }
  


export const getPatchDynLabelIdMutationOptions = <TError = ErrorType<PatchDynLabelId400 | PatchDynLabelId401 | PatchDynLabelId403 | PatchDynLabelId404 | PatchDynLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynLabelId>>, TError,{id: string;data: LabelSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynLabelId>>, TError,{id: string;data: LabelSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynLabelId>>, {id: string;data: LabelSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynLabelId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynLabelId>>>
    export type PatchDynLabelIdMutationBody = LabelSchemaOptional
    export type PatchDynLabelIdMutationError = ErrorType<PatchDynLabelId400 | PatchDynLabelId401 | PatchDynLabelId403 | PatchDynLabelId404 | PatchDynLabelId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynLabelId = <TError = ErrorType<PatchDynLabelId400 | PatchDynLabelId401 | PatchDynLabelId403 | PatchDynLabelId404 | PatchDynLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynLabelId>>, TError,{id: string;data: LabelSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynLabelId>>,
        TError,
        {id: string;data: LabelSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynLabelId = (
    id: string,
    labelSchema: LabelSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelSchema>(
      {url: `/dyn/label/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: labelSchema
    },
      options);
    }
  


export const getPutDynLabelIdMutationOptions = <TError = ErrorType<PutDynLabelId400 | PutDynLabelId401 | PutDynLabelId403 | PutDynLabelId404 | PutDynLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynLabelId>>, TError,{id: string;data: LabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynLabelId>>, TError,{id: string;data: LabelSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynLabelId>>, {id: string;data: LabelSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynLabelId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynLabelId>>>
    export type PutDynLabelIdMutationBody = LabelSchema
    export type PutDynLabelIdMutationError = ErrorType<PutDynLabelId400 | PutDynLabelId401 | PutDynLabelId403 | PutDynLabelId404 | PutDynLabelId500>

    /**
 * @summary Update an instance
 */
export const usePutDynLabelId = <TError = ErrorType<PutDynLabelId400 | PutDynLabelId401 | PutDynLabelId403 | PutDynLabelId404 | PutDynLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynLabelId>>, TError,{id: string;data: LabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynLabelId>>,
        TError,
        {id: string;data: LabelSchema},
        TContext
      > => {

      const mutationOptions = getPutDynLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynLabelMap = (
    params: GetDynLabelMapParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynLabelMap200>(
      {url: `/dyn/label_map`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynLabelMapQueryKey = (params: GetDynLabelMapParams,) => {
    return [`/dyn/label_map`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynLabelMapInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabelMap>>>, TError = ErrorType<GetDynLabelMap400 | GetDynLabelMap404 | GetDynLabelMap500>>(params: GetDynLabelMapParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelMapQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabelMap>>> = ({ signal }) => getDynLabelMap(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelMap>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelMapInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabelMap>>>
export type GetDynLabelMapInfiniteQueryError = ErrorType<GetDynLabelMap400 | GetDynLabelMap404 | GetDynLabelMap500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynLabelMapInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabelMap>>>, TError = ErrorType<GetDynLabelMap400 | GetDynLabelMap404 | GetDynLabelMap500>>(
 params: GetDynLabelMapParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelMapInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynLabelMapQueryOptions = <TData = Awaited<ReturnType<typeof getDynLabelMap>>, TError = ErrorType<GetDynLabelMap400 | GetDynLabelMap404 | GetDynLabelMap500>>(params: GetDynLabelMapParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabelMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelMapQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabelMap>>> = ({ signal }) => getDynLabelMap(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynLabelMap>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelMapQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabelMap>>>
export type GetDynLabelMapQueryError = ErrorType<GetDynLabelMap400 | GetDynLabelMap404 | GetDynLabelMap500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynLabelMap = <TData = Awaited<ReturnType<typeof getDynLabelMap>>, TError = ErrorType<GetDynLabelMap400 | GetDynLabelMap404 | GetDynLabelMap500>>(
 params: GetDynLabelMapParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabelMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelMapQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynLabelMap = (
    labelMapSchema: LabelMapSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelMapSchema>(
      {url: `/dyn/label_map`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: labelMapSchema
    },
      options);
    }
  


export const getPostDynLabelMapMutationOptions = <TError = ErrorType<PostDynLabelMap400 | PostDynLabelMap401 | PostDynLabelMap403 | PostDynLabelMap500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynLabelMap>>, TError,{data: LabelMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynLabelMap>>, TError,{data: LabelMapSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynLabelMap>>, {data: LabelMapSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynLabelMap(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynLabelMapMutationResult = NonNullable<Awaited<ReturnType<typeof postDynLabelMap>>>
    export type PostDynLabelMapMutationBody = LabelMapSchema
    export type PostDynLabelMapMutationError = ErrorType<PostDynLabelMap400 | PostDynLabelMap401 | PostDynLabelMap403 | PostDynLabelMap500>

    /**
 * @summary Create a new instance
 */
export const usePostDynLabelMap = <TError = ErrorType<PostDynLabelMap400 | PostDynLabelMap401 | PostDynLabelMap403 | PostDynLabelMap500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynLabelMap>>, TError,{data: LabelMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynLabelMap>>,
        TError,
        {data: LabelMapSchema},
        TContext
      > => {

      const mutationOptions = getPostDynLabelMapMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynLabelMapId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelMapSchema>(
      {url: `/dyn/label_map/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynLabelMapIdMutationOptions = <TError = ErrorType<DeleteDynLabelMapId400 | DeleteDynLabelMapId401 | DeleteDynLabelMapId403 | DeleteDynLabelMapId404 | DeleteDynLabelMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynLabelMapId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynLabelMapId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynLabelMapId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynLabelMapId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynLabelMapIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynLabelMapId>>>
    
    export type DeleteDynLabelMapIdMutationError = ErrorType<DeleteDynLabelMapId400 | DeleteDynLabelMapId401 | DeleteDynLabelMapId403 | DeleteDynLabelMapId404 | DeleteDynLabelMapId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynLabelMapId = <TError = ErrorType<DeleteDynLabelMapId400 | DeleteDynLabelMapId401 | DeleteDynLabelMapId403 | DeleteDynLabelMapId404 | DeleteDynLabelMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynLabelMapId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynLabelMapId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynLabelMapIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynLabelMapId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<LabelMapSchema>(
      {url: `/dyn/label_map/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynLabelMapIdQueryKey = (id: string,) => {
    return [`/dyn/label_map/${id}`] as const;
    }

    
export const getGetDynLabelMapIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabelMapId>>>, TError = ErrorType<GetDynLabelMapId404 | GetDynLabelMapId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelMapIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabelMapId>>> = ({ signal }) => getDynLabelMapId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelMapId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelMapIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabelMapId>>>
export type GetDynLabelMapIdInfiniteQueryError = ErrorType<GetDynLabelMapId404 | GetDynLabelMapId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynLabelMapIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynLabelMapId>>>, TError = ErrorType<GetDynLabelMapId404 | GetDynLabelMapId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynLabelMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelMapIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynLabelMapIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynLabelMapId>>, TError = ErrorType<GetDynLabelMapId404 | GetDynLabelMapId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabelMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynLabelMapIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynLabelMapId>>> = ({ signal }) => getDynLabelMapId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynLabelMapId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynLabelMapIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynLabelMapId>>>
export type GetDynLabelMapIdQueryError = ErrorType<GetDynLabelMapId404 | GetDynLabelMapId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynLabelMapId = <TData = Awaited<ReturnType<typeof getDynLabelMapId>>, TError = ErrorType<GetDynLabelMapId404 | GetDynLabelMapId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynLabelMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynLabelMapIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynLabelMapId = (
    id: string,
    labelMapSchemaOptional: LabelMapSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelMapSchema>(
      {url: `/dyn/label_map/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: labelMapSchemaOptional
    },
      options);
    }
  


export const getPatchDynLabelMapIdMutationOptions = <TError = ErrorType<PatchDynLabelMapId400 | PatchDynLabelMapId401 | PatchDynLabelMapId403 | PatchDynLabelMapId404 | PatchDynLabelMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynLabelMapId>>, TError,{id: string;data: LabelMapSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynLabelMapId>>, TError,{id: string;data: LabelMapSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynLabelMapId>>, {id: string;data: LabelMapSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynLabelMapId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynLabelMapIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynLabelMapId>>>
    export type PatchDynLabelMapIdMutationBody = LabelMapSchemaOptional
    export type PatchDynLabelMapIdMutationError = ErrorType<PatchDynLabelMapId400 | PatchDynLabelMapId401 | PatchDynLabelMapId403 | PatchDynLabelMapId404 | PatchDynLabelMapId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynLabelMapId = <TError = ErrorType<PatchDynLabelMapId400 | PatchDynLabelMapId401 | PatchDynLabelMapId403 | PatchDynLabelMapId404 | PatchDynLabelMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynLabelMapId>>, TError,{id: string;data: LabelMapSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynLabelMapId>>,
        TError,
        {id: string;data: LabelMapSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynLabelMapIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynLabelMapId = (
    id: string,
    labelMapSchema: LabelMapSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<LabelMapSchema>(
      {url: `/dyn/label_map/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: labelMapSchema
    },
      options);
    }
  


export const getPutDynLabelMapIdMutationOptions = <TError = ErrorType<PutDynLabelMapId400 | PutDynLabelMapId401 | PutDynLabelMapId403 | PutDynLabelMapId404 | PutDynLabelMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynLabelMapId>>, TError,{id: string;data: LabelMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynLabelMapId>>, TError,{id: string;data: LabelMapSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynLabelMapId>>, {id: string;data: LabelMapSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynLabelMapId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynLabelMapIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynLabelMapId>>>
    export type PutDynLabelMapIdMutationBody = LabelMapSchema
    export type PutDynLabelMapIdMutationError = ErrorType<PutDynLabelMapId400 | PutDynLabelMapId401 | PutDynLabelMapId403 | PutDynLabelMapId404 | PutDynLabelMapId500>

    /**
 * @summary Update an instance
 */
export const usePutDynLabelMapId = <TError = ErrorType<PutDynLabelMapId400 | PutDynLabelMapId401 | PutDynLabelMapId403 | PutDynLabelMapId404 | PutDynLabelMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynLabelMapId>>, TError,{id: string;data: LabelMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynLabelMapId>>,
        TError,
        {id: string;data: LabelMapSchema},
        TContext
      > => {

      const mutationOptions = getPutDynLabelMapIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaBedrijfActiviteit = (
    params: GetDynMetaBedrijfActiviteitParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaBedrijfActiviteit200>(
      {url: `/dyn/meta/bedrijf_activiteit`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaBedrijfActiviteitQueryKey = (params: GetDynMetaBedrijfActiviteitParams,) => {
    return [`/dyn/meta/bedrijf_activiteit`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaBedrijfActiviteitInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>>, TError = ErrorType<GetDynMetaBedrijfActiviteit400 | GetDynMetaBedrijfActiviteit404 | GetDynMetaBedrijfActiviteit500>>(params: GetDynMetaBedrijfActiviteitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfActiviteitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>> = ({ signal }) => getDynMetaBedrijfActiviteit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfActiviteitInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>>
export type GetDynMetaBedrijfActiviteitInfiniteQueryError = ErrorType<GetDynMetaBedrijfActiviteit400 | GetDynMetaBedrijfActiviteit404 | GetDynMetaBedrijfActiviteit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaBedrijfActiviteitInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>>, TError = ErrorType<GetDynMetaBedrijfActiviteit400 | GetDynMetaBedrijfActiviteit404 | GetDynMetaBedrijfActiviteit500>>(
 params: GetDynMetaBedrijfActiviteitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfActiviteitInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaBedrijfActiviteitQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError = ErrorType<GetDynMetaBedrijfActiviteit400 | GetDynMetaBedrijfActiviteit404 | GetDynMetaBedrijfActiviteit500>>(params: GetDynMetaBedrijfActiviteitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfActiviteitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>> = ({ signal }) => getDynMetaBedrijfActiviteit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfActiviteitQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>>
export type GetDynMetaBedrijfActiviteitQueryError = ErrorType<GetDynMetaBedrijfActiviteit400 | GetDynMetaBedrijfActiviteit404 | GetDynMetaBedrijfActiviteit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaBedrijfActiviteit = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError = ErrorType<GetDynMetaBedrijfActiviteit400 | GetDynMetaBedrijfActiviteit404 | GetDynMetaBedrijfActiviteit500>>(
 params: GetDynMetaBedrijfActiviteitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfActiviteitQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaBedrijfActiviteit = (
    bedrijfActiviteitSchema: BedrijfActiviteitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfActiviteitSchema>(
      {url: `/dyn/meta/bedrijf_activiteit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfActiviteitSchema
    },
      options);
    }
  


export const getPostDynMetaBedrijfActiviteitMutationOptions = <TError = ErrorType<PostDynMetaBedrijfActiviteit400 | PostDynMetaBedrijfActiviteit401 | PostDynMetaBedrijfActiviteit403 | PostDynMetaBedrijfActiviteit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaBedrijfActiviteit>>, TError,{data: BedrijfActiviteitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaBedrijfActiviteit>>, TError,{data: BedrijfActiviteitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaBedrijfActiviteit>>, {data: BedrijfActiviteitSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaBedrijfActiviteit(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaBedrijfActiviteitMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaBedrijfActiviteit>>>
    export type PostDynMetaBedrijfActiviteitMutationBody = BedrijfActiviteitSchema
    export type PostDynMetaBedrijfActiviteitMutationError = ErrorType<PostDynMetaBedrijfActiviteit400 | PostDynMetaBedrijfActiviteit401 | PostDynMetaBedrijfActiviteit403 | PostDynMetaBedrijfActiviteit500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaBedrijfActiviteit = <TError = ErrorType<PostDynMetaBedrijfActiviteit400 | PostDynMetaBedrijfActiviteit401 | PostDynMetaBedrijfActiviteit403 | PostDynMetaBedrijfActiviteit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaBedrijfActiviteit>>, TError,{data: BedrijfActiviteitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaBedrijfActiviteit>>,
        TError,
        {data: BedrijfActiviteitSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaBedrijfActiviteitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaBedrijfActiviteitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfActiviteitSchema>(
      {url: `/dyn/meta/bedrijf_activiteit/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaBedrijfActiviteitIdMutationOptions = <TError = ErrorType<DeleteDynMetaBedrijfActiviteitId400 | DeleteDynMetaBedrijfActiviteitId401 | DeleteDynMetaBedrijfActiviteitId403 | DeleteDynMetaBedrijfActiviteitId404 | DeleteDynMetaBedrijfActiviteitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaBedrijfActiviteitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaBedrijfActiviteitId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaBedrijfActiviteitId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaBedrijfActiviteitId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaBedrijfActiviteitIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaBedrijfActiviteitId>>>
    
    export type DeleteDynMetaBedrijfActiviteitIdMutationError = ErrorType<DeleteDynMetaBedrijfActiviteitId400 | DeleteDynMetaBedrijfActiviteitId401 | DeleteDynMetaBedrijfActiviteitId403 | DeleteDynMetaBedrijfActiviteitId404 | DeleteDynMetaBedrijfActiviteitId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaBedrijfActiviteitId = <TError = ErrorType<DeleteDynMetaBedrijfActiviteitId400 | DeleteDynMetaBedrijfActiviteitId401 | DeleteDynMetaBedrijfActiviteitId403 | DeleteDynMetaBedrijfActiviteitId404 | DeleteDynMetaBedrijfActiviteitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaBedrijfActiviteitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaBedrijfActiviteitId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaBedrijfActiviteitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaBedrijfActiviteitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<BedrijfActiviteitSchema>(
      {url: `/dyn/meta/bedrijf_activiteit/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaBedrijfActiviteitIdQueryKey = (id: string,) => {
    return [`/dyn/meta/bedrijf_activiteit/${id}`] as const;
    }

    
export const getGetDynMetaBedrijfActiviteitIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>>, TError = ErrorType<GetDynMetaBedrijfActiviteitId404 | GetDynMetaBedrijfActiviteitId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfActiviteitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>> = ({ signal }) => getDynMetaBedrijfActiviteitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfActiviteitIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>>
export type GetDynMetaBedrijfActiviteitIdInfiniteQueryError = ErrorType<GetDynMetaBedrijfActiviteitId404 | GetDynMetaBedrijfActiviteitId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaBedrijfActiviteitIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>>, TError = ErrorType<GetDynMetaBedrijfActiviteitId404 | GetDynMetaBedrijfActiviteitId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfActiviteitIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaBedrijfActiviteitIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError = ErrorType<GetDynMetaBedrijfActiviteitId404 | GetDynMetaBedrijfActiviteitId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfActiviteitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>> = ({ signal }) => getDynMetaBedrijfActiviteitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfActiviteitIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>>
export type GetDynMetaBedrijfActiviteitIdQueryError = ErrorType<GetDynMetaBedrijfActiviteitId404 | GetDynMetaBedrijfActiviteitId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaBedrijfActiviteitId = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError = ErrorType<GetDynMetaBedrijfActiviteitId404 | GetDynMetaBedrijfActiviteitId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfActiviteitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfActiviteitIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaBedrijfActiviteitId = (
    id: string,
    bedrijfActiviteitSchemaOptional: BedrijfActiviteitSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfActiviteitSchema>(
      {url: `/dyn/meta/bedrijf_activiteit/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfActiviteitSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaBedrijfActiviteitIdMutationOptions = <TError = ErrorType<PatchDynMetaBedrijfActiviteitId400 | PatchDynMetaBedrijfActiviteitId401 | PatchDynMetaBedrijfActiviteitId403 | PatchDynMetaBedrijfActiviteitId404 | PatchDynMetaBedrijfActiviteitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaBedrijfActiviteitId>>, TError,{id: string;data: BedrijfActiviteitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaBedrijfActiviteitId>>, TError,{id: string;data: BedrijfActiviteitSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaBedrijfActiviteitId>>, {id: string;data: BedrijfActiviteitSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaBedrijfActiviteitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaBedrijfActiviteitIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaBedrijfActiviteitId>>>
    export type PatchDynMetaBedrijfActiviteitIdMutationBody = BedrijfActiviteitSchemaOptional
    export type PatchDynMetaBedrijfActiviteitIdMutationError = ErrorType<PatchDynMetaBedrijfActiviteitId400 | PatchDynMetaBedrijfActiviteitId401 | PatchDynMetaBedrijfActiviteitId403 | PatchDynMetaBedrijfActiviteitId404 | PatchDynMetaBedrijfActiviteitId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaBedrijfActiviteitId = <TError = ErrorType<PatchDynMetaBedrijfActiviteitId400 | PatchDynMetaBedrijfActiviteitId401 | PatchDynMetaBedrijfActiviteitId403 | PatchDynMetaBedrijfActiviteitId404 | PatchDynMetaBedrijfActiviteitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaBedrijfActiviteitId>>, TError,{id: string;data: BedrijfActiviteitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaBedrijfActiviteitId>>,
        TError,
        {id: string;data: BedrijfActiviteitSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaBedrijfActiviteitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaBedrijfActiviteitId = (
    id: string,
    bedrijfActiviteitSchema: BedrijfActiviteitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfActiviteitSchema>(
      {url: `/dyn/meta/bedrijf_activiteit/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfActiviteitSchema
    },
      options);
    }
  


export const getPutDynMetaBedrijfActiviteitIdMutationOptions = <TError = ErrorType<PutDynMetaBedrijfActiviteitId400 | PutDynMetaBedrijfActiviteitId401 | PutDynMetaBedrijfActiviteitId403 | PutDynMetaBedrijfActiviteitId404 | PutDynMetaBedrijfActiviteitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaBedrijfActiviteitId>>, TError,{id: string;data: BedrijfActiviteitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaBedrijfActiviteitId>>, TError,{id: string;data: BedrijfActiviteitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaBedrijfActiviteitId>>, {id: string;data: BedrijfActiviteitSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaBedrijfActiviteitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaBedrijfActiviteitIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaBedrijfActiviteitId>>>
    export type PutDynMetaBedrijfActiviteitIdMutationBody = BedrijfActiviteitSchema
    export type PutDynMetaBedrijfActiviteitIdMutationError = ErrorType<PutDynMetaBedrijfActiviteitId400 | PutDynMetaBedrijfActiviteitId401 | PutDynMetaBedrijfActiviteitId403 | PutDynMetaBedrijfActiviteitId404 | PutDynMetaBedrijfActiviteitId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaBedrijfActiviteitId = <TError = ErrorType<PutDynMetaBedrijfActiviteitId400 | PutDynMetaBedrijfActiviteitId401 | PutDynMetaBedrijfActiviteitId403 | PutDynMetaBedrijfActiviteitId404 | PutDynMetaBedrijfActiviteitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaBedrijfActiviteitId>>, TError,{id: string;data: BedrijfActiviteitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaBedrijfActiviteitId>>,
        TError,
        {id: string;data: BedrijfActiviteitSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaBedrijfActiviteitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaBedrijfInstallatie = (
    params: GetDynMetaBedrijfInstallatieParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaBedrijfInstallatie200>(
      {url: `/dyn/meta/bedrijf_installatie`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaBedrijfInstallatieQueryKey = (params: GetDynMetaBedrijfInstallatieParams,) => {
    return [`/dyn/meta/bedrijf_installatie`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaBedrijfInstallatieInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>>, TError = ErrorType<GetDynMetaBedrijfInstallatie400 | GetDynMetaBedrijfInstallatie404 | GetDynMetaBedrijfInstallatie500>>(params: GetDynMetaBedrijfInstallatieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfInstallatieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>> = ({ signal }) => getDynMetaBedrijfInstallatie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfInstallatieInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>>
export type GetDynMetaBedrijfInstallatieInfiniteQueryError = ErrorType<GetDynMetaBedrijfInstallatie400 | GetDynMetaBedrijfInstallatie404 | GetDynMetaBedrijfInstallatie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaBedrijfInstallatieInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>>, TError = ErrorType<GetDynMetaBedrijfInstallatie400 | GetDynMetaBedrijfInstallatie404 | GetDynMetaBedrijfInstallatie500>>(
 params: GetDynMetaBedrijfInstallatieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfInstallatieInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaBedrijfInstallatieQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError = ErrorType<GetDynMetaBedrijfInstallatie400 | GetDynMetaBedrijfInstallatie404 | GetDynMetaBedrijfInstallatie500>>(params: GetDynMetaBedrijfInstallatieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfInstallatieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>> = ({ signal }) => getDynMetaBedrijfInstallatie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfInstallatieQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>>
export type GetDynMetaBedrijfInstallatieQueryError = ErrorType<GetDynMetaBedrijfInstallatie400 | GetDynMetaBedrijfInstallatie404 | GetDynMetaBedrijfInstallatie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaBedrijfInstallatie = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError = ErrorType<GetDynMetaBedrijfInstallatie400 | GetDynMetaBedrijfInstallatie404 | GetDynMetaBedrijfInstallatie500>>(
 params: GetDynMetaBedrijfInstallatieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfInstallatieQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaBedrijfInstallatie = (
    bedrijfInstallatieSchema: BedrijfInstallatieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfInstallatieSchema>(
      {url: `/dyn/meta/bedrijf_installatie`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfInstallatieSchema
    },
      options);
    }
  


export const getPostDynMetaBedrijfInstallatieMutationOptions = <TError = ErrorType<PostDynMetaBedrijfInstallatie400 | PostDynMetaBedrijfInstallatie401 | PostDynMetaBedrijfInstallatie403 | PostDynMetaBedrijfInstallatie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaBedrijfInstallatie>>, TError,{data: BedrijfInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaBedrijfInstallatie>>, TError,{data: BedrijfInstallatieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaBedrijfInstallatie>>, {data: BedrijfInstallatieSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaBedrijfInstallatie(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaBedrijfInstallatieMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaBedrijfInstallatie>>>
    export type PostDynMetaBedrijfInstallatieMutationBody = BedrijfInstallatieSchema
    export type PostDynMetaBedrijfInstallatieMutationError = ErrorType<PostDynMetaBedrijfInstallatie400 | PostDynMetaBedrijfInstallatie401 | PostDynMetaBedrijfInstallatie403 | PostDynMetaBedrijfInstallatie500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaBedrijfInstallatie = <TError = ErrorType<PostDynMetaBedrijfInstallatie400 | PostDynMetaBedrijfInstallatie401 | PostDynMetaBedrijfInstallatie403 | PostDynMetaBedrijfInstallatie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaBedrijfInstallatie>>, TError,{data: BedrijfInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaBedrijfInstallatie>>,
        TError,
        {data: BedrijfInstallatieSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaBedrijfInstallatieMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaBedrijfInstallatieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfInstallatieSchema>(
      {url: `/dyn/meta/bedrijf_installatie/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaBedrijfInstallatieIdMutationOptions = <TError = ErrorType<DeleteDynMetaBedrijfInstallatieId400 | DeleteDynMetaBedrijfInstallatieId401 | DeleteDynMetaBedrijfInstallatieId403 | DeleteDynMetaBedrijfInstallatieId404 | DeleteDynMetaBedrijfInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaBedrijfInstallatieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaBedrijfInstallatieId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaBedrijfInstallatieId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaBedrijfInstallatieId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaBedrijfInstallatieIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaBedrijfInstallatieId>>>
    
    export type DeleteDynMetaBedrijfInstallatieIdMutationError = ErrorType<DeleteDynMetaBedrijfInstallatieId400 | DeleteDynMetaBedrijfInstallatieId401 | DeleteDynMetaBedrijfInstallatieId403 | DeleteDynMetaBedrijfInstallatieId404 | DeleteDynMetaBedrijfInstallatieId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaBedrijfInstallatieId = <TError = ErrorType<DeleteDynMetaBedrijfInstallatieId400 | DeleteDynMetaBedrijfInstallatieId401 | DeleteDynMetaBedrijfInstallatieId403 | DeleteDynMetaBedrijfInstallatieId404 | DeleteDynMetaBedrijfInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaBedrijfInstallatieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaBedrijfInstallatieId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaBedrijfInstallatieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaBedrijfInstallatieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<BedrijfInstallatieSchema>(
      {url: `/dyn/meta/bedrijf_installatie/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaBedrijfInstallatieIdQueryKey = (id: string,) => {
    return [`/dyn/meta/bedrijf_installatie/${id}`] as const;
    }

    
export const getGetDynMetaBedrijfInstallatieIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>>, TError = ErrorType<GetDynMetaBedrijfInstallatieId404 | GetDynMetaBedrijfInstallatieId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfInstallatieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>> = ({ signal }) => getDynMetaBedrijfInstallatieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfInstallatieIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>>
export type GetDynMetaBedrijfInstallatieIdInfiniteQueryError = ErrorType<GetDynMetaBedrijfInstallatieId404 | GetDynMetaBedrijfInstallatieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaBedrijfInstallatieIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>>, TError = ErrorType<GetDynMetaBedrijfInstallatieId404 | GetDynMetaBedrijfInstallatieId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfInstallatieIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaBedrijfInstallatieIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError = ErrorType<GetDynMetaBedrijfInstallatieId404 | GetDynMetaBedrijfInstallatieId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaBedrijfInstallatieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>> = ({ signal }) => getDynMetaBedrijfInstallatieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaBedrijfInstallatieIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>>
export type GetDynMetaBedrijfInstallatieIdQueryError = ErrorType<GetDynMetaBedrijfInstallatieId404 | GetDynMetaBedrijfInstallatieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaBedrijfInstallatieId = <TData = Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError = ErrorType<GetDynMetaBedrijfInstallatieId404 | GetDynMetaBedrijfInstallatieId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaBedrijfInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaBedrijfInstallatieIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaBedrijfInstallatieId = (
    id: string,
    bedrijfInstallatieSchemaOptional: BedrijfInstallatieSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfInstallatieSchema>(
      {url: `/dyn/meta/bedrijf_installatie/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfInstallatieSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaBedrijfInstallatieIdMutationOptions = <TError = ErrorType<PatchDynMetaBedrijfInstallatieId400 | PatchDynMetaBedrijfInstallatieId401 | PatchDynMetaBedrijfInstallatieId403 | PatchDynMetaBedrijfInstallatieId404 | PatchDynMetaBedrijfInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaBedrijfInstallatieId>>, TError,{id: string;data: BedrijfInstallatieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaBedrijfInstallatieId>>, TError,{id: string;data: BedrijfInstallatieSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaBedrijfInstallatieId>>, {id: string;data: BedrijfInstallatieSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaBedrijfInstallatieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaBedrijfInstallatieIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaBedrijfInstallatieId>>>
    export type PatchDynMetaBedrijfInstallatieIdMutationBody = BedrijfInstallatieSchemaOptional
    export type PatchDynMetaBedrijfInstallatieIdMutationError = ErrorType<PatchDynMetaBedrijfInstallatieId400 | PatchDynMetaBedrijfInstallatieId401 | PatchDynMetaBedrijfInstallatieId403 | PatchDynMetaBedrijfInstallatieId404 | PatchDynMetaBedrijfInstallatieId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaBedrijfInstallatieId = <TError = ErrorType<PatchDynMetaBedrijfInstallatieId400 | PatchDynMetaBedrijfInstallatieId401 | PatchDynMetaBedrijfInstallatieId403 | PatchDynMetaBedrijfInstallatieId404 | PatchDynMetaBedrijfInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaBedrijfInstallatieId>>, TError,{id: string;data: BedrijfInstallatieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaBedrijfInstallatieId>>,
        TError,
        {id: string;data: BedrijfInstallatieSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaBedrijfInstallatieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaBedrijfInstallatieId = (
    id: string,
    bedrijfInstallatieSchema: BedrijfInstallatieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfInstallatieSchema>(
      {url: `/dyn/meta/bedrijf_installatie/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfInstallatieSchema
    },
      options);
    }
  


export const getPutDynMetaBedrijfInstallatieIdMutationOptions = <TError = ErrorType<PutDynMetaBedrijfInstallatieId400 | PutDynMetaBedrijfInstallatieId401 | PutDynMetaBedrijfInstallatieId403 | PutDynMetaBedrijfInstallatieId404 | PutDynMetaBedrijfInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaBedrijfInstallatieId>>, TError,{id: string;data: BedrijfInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaBedrijfInstallatieId>>, TError,{id: string;data: BedrijfInstallatieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaBedrijfInstallatieId>>, {id: string;data: BedrijfInstallatieSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaBedrijfInstallatieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaBedrijfInstallatieIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaBedrijfInstallatieId>>>
    export type PutDynMetaBedrijfInstallatieIdMutationBody = BedrijfInstallatieSchema
    export type PutDynMetaBedrijfInstallatieIdMutationError = ErrorType<PutDynMetaBedrijfInstallatieId400 | PutDynMetaBedrijfInstallatieId401 | PutDynMetaBedrijfInstallatieId403 | PutDynMetaBedrijfInstallatieId404 | PutDynMetaBedrijfInstallatieId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaBedrijfInstallatieId = <TError = ErrorType<PutDynMetaBedrijfInstallatieId400 | PutDynMetaBedrijfInstallatieId401 | PutDynMetaBedrijfInstallatieId403 | PutDynMetaBedrijfInstallatieId404 | PutDynMetaBedrijfInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaBedrijfInstallatieId>>, TError,{id: string;data: BedrijfInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaBedrijfInstallatieId>>,
        TError,
        {id: string;data: BedrijfInstallatieSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaBedrijfInstallatieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaStdDefinitie = (
    params: GetDynMetaStdDefinitieParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaStdDefinitie200>(
      {url: `/dyn/meta/std/definitie`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaStdDefinitieQueryKey = (params: GetDynMetaStdDefinitieParams,) => {
    return [`/dyn/meta/std/definitie`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaStdDefinitieInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>>, TError = ErrorType<GetDynMetaStdDefinitie400 | GetDynMetaStdDefinitie404 | GetDynMetaStdDefinitie500>>(params: GetDynMetaStdDefinitieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdDefinitieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>> = ({ signal }) => getDynMetaStdDefinitie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdDefinitieInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>>
export type GetDynMetaStdDefinitieInfiniteQueryError = ErrorType<GetDynMetaStdDefinitie400 | GetDynMetaStdDefinitie404 | GetDynMetaStdDefinitie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdDefinitieInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>>, TError = ErrorType<GetDynMetaStdDefinitie400 | GetDynMetaStdDefinitie404 | GetDynMetaStdDefinitie500>>(
 params: GetDynMetaStdDefinitieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdDefinitieInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdDefinitieQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError = ErrorType<GetDynMetaStdDefinitie400 | GetDynMetaStdDefinitie404 | GetDynMetaStdDefinitie500>>(params: GetDynMetaStdDefinitieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdDefinitieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>> = ({ signal }) => getDynMetaStdDefinitie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdDefinitieQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>>
export type GetDynMetaStdDefinitieQueryError = ErrorType<GetDynMetaStdDefinitie400 | GetDynMetaStdDefinitie404 | GetDynMetaStdDefinitie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdDefinitie = <TData = Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError = ErrorType<GetDynMetaStdDefinitie400 | GetDynMetaStdDefinitie404 | GetDynMetaStdDefinitie500>>(
 params: GetDynMetaStdDefinitieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdDefinitieQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaStdDefinitie = (
    sTDDefinitieSchema: STDDefinitieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDDefinitieSchema>(
      {url: `/dyn/meta/std/definitie`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDDefinitieSchema
    },
      options);
    }
  


export const getPostDynMetaStdDefinitieMutationOptions = <TError = ErrorType<PostDynMetaStdDefinitie400 | PostDynMetaStdDefinitie401 | PostDynMetaStdDefinitie403 | PostDynMetaStdDefinitie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdDefinitie>>, TError,{data: STDDefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdDefinitie>>, TError,{data: STDDefinitieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaStdDefinitie>>, {data: STDDefinitieSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaStdDefinitie(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaStdDefinitieMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaStdDefinitie>>>
    export type PostDynMetaStdDefinitieMutationBody = STDDefinitieSchema
    export type PostDynMetaStdDefinitieMutationError = ErrorType<PostDynMetaStdDefinitie400 | PostDynMetaStdDefinitie401 | PostDynMetaStdDefinitie403 | PostDynMetaStdDefinitie500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaStdDefinitie = <TError = ErrorType<PostDynMetaStdDefinitie400 | PostDynMetaStdDefinitie401 | PostDynMetaStdDefinitie403 | PostDynMetaStdDefinitie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdDefinitie>>, TError,{data: STDDefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaStdDefinitie>>,
        TError,
        {data: STDDefinitieSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaStdDefinitieMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaStdDefinitieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDDefinitieSchema>(
      {url: `/dyn/meta/std/definitie/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaStdDefinitieIdMutationOptions = <TError = ErrorType<DeleteDynMetaStdDefinitieId400 | DeleteDynMetaStdDefinitieId401 | DeleteDynMetaStdDefinitieId403 | DeleteDynMetaStdDefinitieId404 | DeleteDynMetaStdDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdDefinitieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdDefinitieId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaStdDefinitieId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaStdDefinitieId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaStdDefinitieIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaStdDefinitieId>>>
    
    export type DeleteDynMetaStdDefinitieIdMutationError = ErrorType<DeleteDynMetaStdDefinitieId400 | DeleteDynMetaStdDefinitieId401 | DeleteDynMetaStdDefinitieId403 | DeleteDynMetaStdDefinitieId404 | DeleteDynMetaStdDefinitieId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaStdDefinitieId = <TError = ErrorType<DeleteDynMetaStdDefinitieId400 | DeleteDynMetaStdDefinitieId401 | DeleteDynMetaStdDefinitieId403 | DeleteDynMetaStdDefinitieId404 | DeleteDynMetaStdDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdDefinitieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaStdDefinitieId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaStdDefinitieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaStdDefinitieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDDefinitieSchema>(
      {url: `/dyn/meta/std/definitie/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaStdDefinitieIdQueryKey = (id: string,) => {
    return [`/dyn/meta/std/definitie/${id}`] as const;
    }

    
export const getGetDynMetaStdDefinitieIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>>, TError = ErrorType<GetDynMetaStdDefinitieId404 | GetDynMetaStdDefinitieId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdDefinitieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>> = ({ signal }) => getDynMetaStdDefinitieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdDefinitieIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>>
export type GetDynMetaStdDefinitieIdInfiniteQueryError = ErrorType<GetDynMetaStdDefinitieId404 | GetDynMetaStdDefinitieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdDefinitieIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>>, TError = ErrorType<GetDynMetaStdDefinitieId404 | GetDynMetaStdDefinitieId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdDefinitieIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdDefinitieIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError = ErrorType<GetDynMetaStdDefinitieId404 | GetDynMetaStdDefinitieId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdDefinitieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>> = ({ signal }) => getDynMetaStdDefinitieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdDefinitieIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>>
export type GetDynMetaStdDefinitieIdQueryError = ErrorType<GetDynMetaStdDefinitieId404 | GetDynMetaStdDefinitieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdDefinitieId = <TData = Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError = ErrorType<GetDynMetaStdDefinitieId404 | GetDynMetaStdDefinitieId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdDefinitieIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaStdDefinitieId = (
    id: string,
    sTDDefinitieSchemaOptional: STDDefinitieSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDDefinitieSchema>(
      {url: `/dyn/meta/std/definitie/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDDefinitieSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaStdDefinitieIdMutationOptions = <TError = ErrorType<PatchDynMetaStdDefinitieId400 | PatchDynMetaStdDefinitieId401 | PatchDynMetaStdDefinitieId403 | PatchDynMetaStdDefinitieId404 | PatchDynMetaStdDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdDefinitieId>>, TError,{id: string;data: STDDefinitieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdDefinitieId>>, TError,{id: string;data: STDDefinitieSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaStdDefinitieId>>, {id: string;data: STDDefinitieSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaStdDefinitieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaStdDefinitieIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaStdDefinitieId>>>
    export type PatchDynMetaStdDefinitieIdMutationBody = STDDefinitieSchemaOptional
    export type PatchDynMetaStdDefinitieIdMutationError = ErrorType<PatchDynMetaStdDefinitieId400 | PatchDynMetaStdDefinitieId401 | PatchDynMetaStdDefinitieId403 | PatchDynMetaStdDefinitieId404 | PatchDynMetaStdDefinitieId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaStdDefinitieId = <TError = ErrorType<PatchDynMetaStdDefinitieId400 | PatchDynMetaStdDefinitieId401 | PatchDynMetaStdDefinitieId403 | PatchDynMetaStdDefinitieId404 | PatchDynMetaStdDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdDefinitieId>>, TError,{id: string;data: STDDefinitieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaStdDefinitieId>>,
        TError,
        {id: string;data: STDDefinitieSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaStdDefinitieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaStdDefinitieId = (
    id: string,
    sTDDefinitieSchema: STDDefinitieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDDefinitieSchema>(
      {url: `/dyn/meta/std/definitie/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDDefinitieSchema
    },
      options);
    }
  


export const getPutDynMetaStdDefinitieIdMutationOptions = <TError = ErrorType<PutDynMetaStdDefinitieId400 | PutDynMetaStdDefinitieId401 | PutDynMetaStdDefinitieId403 | PutDynMetaStdDefinitieId404 | PutDynMetaStdDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdDefinitieId>>, TError,{id: string;data: STDDefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdDefinitieId>>, TError,{id: string;data: STDDefinitieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaStdDefinitieId>>, {id: string;data: STDDefinitieSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaStdDefinitieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaStdDefinitieIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaStdDefinitieId>>>
    export type PutDynMetaStdDefinitieIdMutationBody = STDDefinitieSchema
    export type PutDynMetaStdDefinitieIdMutationError = ErrorType<PutDynMetaStdDefinitieId400 | PutDynMetaStdDefinitieId401 | PutDynMetaStdDefinitieId403 | PutDynMetaStdDefinitieId404 | PutDynMetaStdDefinitieId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaStdDefinitieId = <TError = ErrorType<PutDynMetaStdDefinitieId400 | PutDynMetaStdDefinitieId401 | PutDynMetaStdDefinitieId403 | PutDynMetaStdDefinitieId404 | PutDynMetaStdDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdDefinitieId>>, TError,{id: string;data: STDDefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaStdDefinitieId>>,
        TError,
        {id: string;data: STDDefinitieSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaStdDefinitieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaStdEmImIssie = (
    params: GetDynMetaStdEmImIssieParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaStdEmImIssie200>(
      {url: `/dyn/meta/std/em_im_issie`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaStdEmImIssieQueryKey = (params: GetDynMetaStdEmImIssieParams,) => {
    return [`/dyn/meta/std/em_im_issie`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaStdEmImIssieInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>>, TError = ErrorType<GetDynMetaStdEmImIssie400 | GetDynMetaStdEmImIssie404 | GetDynMetaStdEmImIssie500>>(params: GetDynMetaStdEmImIssieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdEmImIssieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>> = ({ signal }) => getDynMetaStdEmImIssie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdEmImIssieInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>>
export type GetDynMetaStdEmImIssieInfiniteQueryError = ErrorType<GetDynMetaStdEmImIssie400 | GetDynMetaStdEmImIssie404 | GetDynMetaStdEmImIssie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdEmImIssieInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>>, TError = ErrorType<GetDynMetaStdEmImIssie400 | GetDynMetaStdEmImIssie404 | GetDynMetaStdEmImIssie500>>(
 params: GetDynMetaStdEmImIssieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdEmImIssieInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdEmImIssieQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError = ErrorType<GetDynMetaStdEmImIssie400 | GetDynMetaStdEmImIssie404 | GetDynMetaStdEmImIssie500>>(params: GetDynMetaStdEmImIssieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdEmImIssieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>> = ({ signal }) => getDynMetaStdEmImIssie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdEmImIssieQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>>
export type GetDynMetaStdEmImIssieQueryError = ErrorType<GetDynMetaStdEmImIssie400 | GetDynMetaStdEmImIssie404 | GetDynMetaStdEmImIssie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdEmImIssie = <TData = Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError = ErrorType<GetDynMetaStdEmImIssie400 | GetDynMetaStdEmImIssie404 | GetDynMetaStdEmImIssie500>>(
 params: GetDynMetaStdEmImIssieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdEmImIssieQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaStdEmImIssie = (
    sTDEmImIssieSchema: STDEmImIssieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDEmImIssieSchema>(
      {url: `/dyn/meta/std/em_im_issie`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDEmImIssieSchema
    },
      options);
    }
  


export const getPostDynMetaStdEmImIssieMutationOptions = <TError = ErrorType<PostDynMetaStdEmImIssie400 | PostDynMetaStdEmImIssie401 | PostDynMetaStdEmImIssie403 | PostDynMetaStdEmImIssie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdEmImIssie>>, TError,{data: STDEmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdEmImIssie>>, TError,{data: STDEmImIssieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaStdEmImIssie>>, {data: STDEmImIssieSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaStdEmImIssie(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaStdEmImIssieMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaStdEmImIssie>>>
    export type PostDynMetaStdEmImIssieMutationBody = STDEmImIssieSchema
    export type PostDynMetaStdEmImIssieMutationError = ErrorType<PostDynMetaStdEmImIssie400 | PostDynMetaStdEmImIssie401 | PostDynMetaStdEmImIssie403 | PostDynMetaStdEmImIssie500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaStdEmImIssie = <TError = ErrorType<PostDynMetaStdEmImIssie400 | PostDynMetaStdEmImIssie401 | PostDynMetaStdEmImIssie403 | PostDynMetaStdEmImIssie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdEmImIssie>>, TError,{data: STDEmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaStdEmImIssie>>,
        TError,
        {data: STDEmImIssieSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaStdEmImIssieMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaStdEmImIssieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDEmImIssieSchema>(
      {url: `/dyn/meta/std/em_im_issie/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaStdEmImIssieIdMutationOptions = <TError = ErrorType<DeleteDynMetaStdEmImIssieId400 | DeleteDynMetaStdEmImIssieId401 | DeleteDynMetaStdEmImIssieId403 | DeleteDynMetaStdEmImIssieId404 | DeleteDynMetaStdEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdEmImIssieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdEmImIssieId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaStdEmImIssieId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaStdEmImIssieId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaStdEmImIssieIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaStdEmImIssieId>>>
    
    export type DeleteDynMetaStdEmImIssieIdMutationError = ErrorType<DeleteDynMetaStdEmImIssieId400 | DeleteDynMetaStdEmImIssieId401 | DeleteDynMetaStdEmImIssieId403 | DeleteDynMetaStdEmImIssieId404 | DeleteDynMetaStdEmImIssieId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaStdEmImIssieId = <TError = ErrorType<DeleteDynMetaStdEmImIssieId400 | DeleteDynMetaStdEmImIssieId401 | DeleteDynMetaStdEmImIssieId403 | DeleteDynMetaStdEmImIssieId404 | DeleteDynMetaStdEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdEmImIssieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaStdEmImIssieId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaStdEmImIssieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaStdEmImIssieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDEmImIssieSchema>(
      {url: `/dyn/meta/std/em_im_issie/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaStdEmImIssieIdQueryKey = (id: string,) => {
    return [`/dyn/meta/std/em_im_issie/${id}`] as const;
    }

    
export const getGetDynMetaStdEmImIssieIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>>, TError = ErrorType<GetDynMetaStdEmImIssieId404 | GetDynMetaStdEmImIssieId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdEmImIssieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>> = ({ signal }) => getDynMetaStdEmImIssieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdEmImIssieIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>>
export type GetDynMetaStdEmImIssieIdInfiniteQueryError = ErrorType<GetDynMetaStdEmImIssieId404 | GetDynMetaStdEmImIssieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdEmImIssieIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>>, TError = ErrorType<GetDynMetaStdEmImIssieId404 | GetDynMetaStdEmImIssieId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdEmImIssieIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdEmImIssieIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError = ErrorType<GetDynMetaStdEmImIssieId404 | GetDynMetaStdEmImIssieId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdEmImIssieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>> = ({ signal }) => getDynMetaStdEmImIssieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdEmImIssieIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>>
export type GetDynMetaStdEmImIssieIdQueryError = ErrorType<GetDynMetaStdEmImIssieId404 | GetDynMetaStdEmImIssieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdEmImIssieId = <TData = Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError = ErrorType<GetDynMetaStdEmImIssieId404 | GetDynMetaStdEmImIssieId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdEmImIssieIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaStdEmImIssieId = (
    id: string,
    sTDEmImIssieSchemaOptional: STDEmImIssieSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDEmImIssieSchema>(
      {url: `/dyn/meta/std/em_im_issie/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDEmImIssieSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaStdEmImIssieIdMutationOptions = <TError = ErrorType<PatchDynMetaStdEmImIssieId400 | PatchDynMetaStdEmImIssieId401 | PatchDynMetaStdEmImIssieId403 | PatchDynMetaStdEmImIssieId404 | PatchDynMetaStdEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdEmImIssieId>>, TError,{id: string;data: STDEmImIssieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdEmImIssieId>>, TError,{id: string;data: STDEmImIssieSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaStdEmImIssieId>>, {id: string;data: STDEmImIssieSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaStdEmImIssieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaStdEmImIssieIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaStdEmImIssieId>>>
    export type PatchDynMetaStdEmImIssieIdMutationBody = STDEmImIssieSchemaOptional
    export type PatchDynMetaStdEmImIssieIdMutationError = ErrorType<PatchDynMetaStdEmImIssieId400 | PatchDynMetaStdEmImIssieId401 | PatchDynMetaStdEmImIssieId403 | PatchDynMetaStdEmImIssieId404 | PatchDynMetaStdEmImIssieId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaStdEmImIssieId = <TError = ErrorType<PatchDynMetaStdEmImIssieId400 | PatchDynMetaStdEmImIssieId401 | PatchDynMetaStdEmImIssieId403 | PatchDynMetaStdEmImIssieId404 | PatchDynMetaStdEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdEmImIssieId>>, TError,{id: string;data: STDEmImIssieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaStdEmImIssieId>>,
        TError,
        {id: string;data: STDEmImIssieSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaStdEmImIssieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaStdEmImIssieId = (
    id: string,
    sTDEmImIssieSchema: STDEmImIssieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDEmImIssieSchema>(
      {url: `/dyn/meta/std/em_im_issie/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDEmImIssieSchema
    },
      options);
    }
  


export const getPutDynMetaStdEmImIssieIdMutationOptions = <TError = ErrorType<PutDynMetaStdEmImIssieId400 | PutDynMetaStdEmImIssieId401 | PutDynMetaStdEmImIssieId403 | PutDynMetaStdEmImIssieId404 | PutDynMetaStdEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdEmImIssieId>>, TError,{id: string;data: STDEmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdEmImIssieId>>, TError,{id: string;data: STDEmImIssieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaStdEmImIssieId>>, {id: string;data: STDEmImIssieSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaStdEmImIssieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaStdEmImIssieIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaStdEmImIssieId>>>
    export type PutDynMetaStdEmImIssieIdMutationBody = STDEmImIssieSchema
    export type PutDynMetaStdEmImIssieIdMutationError = ErrorType<PutDynMetaStdEmImIssieId400 | PutDynMetaStdEmImIssieId401 | PutDynMetaStdEmImIssieId403 | PutDynMetaStdEmImIssieId404 | PutDynMetaStdEmImIssieId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaStdEmImIssieId = <TError = ErrorType<PutDynMetaStdEmImIssieId400 | PutDynMetaStdEmImIssieId401 | PutDynMetaStdEmImIssieId403 | PutDynMetaStdEmImIssieId404 | PutDynMetaStdEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdEmImIssieId>>, TError,{id: string;data: STDEmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaStdEmImIssieId>>,
        TError,
        {id: string;data: STDEmImIssieSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaStdEmImIssieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaStdVoorschriftKoppeling = (
    params: GetDynMetaStdVoorschriftKoppelingParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaStdVoorschriftKoppeling200>(
      {url: `/dyn/meta/std/voorschrift_koppeling`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaStdVoorschriftKoppelingQueryKey = (params: GetDynMetaStdVoorschriftKoppelingParams,) => {
    return [`/dyn/meta/std/voorschrift_koppeling`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaStdVoorschriftKoppelingInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppeling400 | GetDynMetaStdVoorschriftKoppeling404 | GetDynMetaStdVoorschriftKoppeling500>>(params: GetDynMetaStdVoorschriftKoppelingParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftKoppelingQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>> = ({ signal }) => getDynMetaStdVoorschriftKoppeling(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftKoppelingInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>>
export type GetDynMetaStdVoorschriftKoppelingInfiniteQueryError = ErrorType<GetDynMetaStdVoorschriftKoppeling400 | GetDynMetaStdVoorschriftKoppeling404 | GetDynMetaStdVoorschriftKoppeling500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdVoorschriftKoppelingInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppeling400 | GetDynMetaStdVoorschriftKoppeling404 | GetDynMetaStdVoorschriftKoppeling500>>(
 params: GetDynMetaStdVoorschriftKoppelingParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftKoppelingInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdVoorschriftKoppelingQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppeling400 | GetDynMetaStdVoorschriftKoppeling404 | GetDynMetaStdVoorschriftKoppeling500>>(params: GetDynMetaStdVoorschriftKoppelingParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftKoppelingQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>> = ({ signal }) => getDynMetaStdVoorschriftKoppeling(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftKoppelingQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>>
export type GetDynMetaStdVoorschriftKoppelingQueryError = ErrorType<GetDynMetaStdVoorschriftKoppeling400 | GetDynMetaStdVoorschriftKoppeling404 | GetDynMetaStdVoorschriftKoppeling500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdVoorschriftKoppeling = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppeling400 | GetDynMetaStdVoorschriftKoppeling404 | GetDynMetaStdVoorschriftKoppeling500>>(
 params: GetDynMetaStdVoorschriftKoppelingParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftKoppelingQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaStdVoorschriftKoppeling = (
    sTDVoorschriftKoppelingSchema: STDVoorschriftKoppelingSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftKoppelingSchema>(
      {url: `/dyn/meta/std/voorschrift_koppeling`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftKoppelingSchema
    },
      options);
    }
  


export const getPostDynMetaStdVoorschriftKoppelingMutationOptions = <TError = ErrorType<PostDynMetaStdVoorschriftKoppeling400 | PostDynMetaStdVoorschriftKoppeling401 | PostDynMetaStdVoorschriftKoppeling403 | PostDynMetaStdVoorschriftKoppeling500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdVoorschriftKoppeling>>, TError,{data: STDVoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdVoorschriftKoppeling>>, TError,{data: STDVoorschriftKoppelingSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaStdVoorschriftKoppeling>>, {data: STDVoorschriftKoppelingSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaStdVoorschriftKoppeling(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaStdVoorschriftKoppelingMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaStdVoorschriftKoppeling>>>
    export type PostDynMetaStdVoorschriftKoppelingMutationBody = STDVoorschriftKoppelingSchema
    export type PostDynMetaStdVoorschriftKoppelingMutationError = ErrorType<PostDynMetaStdVoorschriftKoppeling400 | PostDynMetaStdVoorschriftKoppeling401 | PostDynMetaStdVoorschriftKoppeling403 | PostDynMetaStdVoorschriftKoppeling500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaStdVoorschriftKoppeling = <TError = ErrorType<PostDynMetaStdVoorschriftKoppeling400 | PostDynMetaStdVoorschriftKoppeling401 | PostDynMetaStdVoorschriftKoppeling403 | PostDynMetaStdVoorschriftKoppeling500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdVoorschriftKoppeling>>, TError,{data: STDVoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaStdVoorschriftKoppeling>>,
        TError,
        {data: STDVoorschriftKoppelingSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaStdVoorschriftKoppelingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaStdVoorschriftKoppelingId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftKoppelingSchema>(
      {url: `/dyn/meta/std/voorschrift_koppeling/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaStdVoorschriftKoppelingIdMutationOptions = <TError = ErrorType<DeleteDynMetaStdVoorschriftKoppelingId400 | DeleteDynMetaStdVoorschriftKoppelingId401 | DeleteDynMetaStdVoorschriftKoppelingId403 | DeleteDynMetaStdVoorschriftKoppelingId404 | DeleteDynMetaStdVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftKoppelingId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftKoppelingId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftKoppelingId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaStdVoorschriftKoppelingId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaStdVoorschriftKoppelingIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftKoppelingId>>>
    
    export type DeleteDynMetaStdVoorschriftKoppelingIdMutationError = ErrorType<DeleteDynMetaStdVoorschriftKoppelingId400 | DeleteDynMetaStdVoorschriftKoppelingId401 | DeleteDynMetaStdVoorschriftKoppelingId403 | DeleteDynMetaStdVoorschriftKoppelingId404 | DeleteDynMetaStdVoorschriftKoppelingId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaStdVoorschriftKoppelingId = <TError = ErrorType<DeleteDynMetaStdVoorschriftKoppelingId400 | DeleteDynMetaStdVoorschriftKoppelingId401 | DeleteDynMetaStdVoorschriftKoppelingId403 | DeleteDynMetaStdVoorschriftKoppelingId404 | DeleteDynMetaStdVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftKoppelingId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftKoppelingId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaStdVoorschriftKoppelingIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaStdVoorschriftKoppelingId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDVoorschriftKoppelingSchema>(
      {url: `/dyn/meta/std/voorschrift_koppeling/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaStdVoorschriftKoppelingIdQueryKey = (id: string,) => {
    return [`/dyn/meta/std/voorschrift_koppeling/${id}`] as const;
    }

    
export const getGetDynMetaStdVoorschriftKoppelingIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppelingId404 | GetDynMetaStdVoorschriftKoppelingId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftKoppelingIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>> = ({ signal }) => getDynMetaStdVoorschriftKoppelingId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftKoppelingIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>>
export type GetDynMetaStdVoorschriftKoppelingIdInfiniteQueryError = ErrorType<GetDynMetaStdVoorschriftKoppelingId404 | GetDynMetaStdVoorschriftKoppelingId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdVoorschriftKoppelingIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppelingId404 | GetDynMetaStdVoorschriftKoppelingId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftKoppelingIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdVoorschriftKoppelingIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppelingId404 | GetDynMetaStdVoorschriftKoppelingId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftKoppelingIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>> = ({ signal }) => getDynMetaStdVoorschriftKoppelingId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftKoppelingIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>>
export type GetDynMetaStdVoorschriftKoppelingIdQueryError = ErrorType<GetDynMetaStdVoorschriftKoppelingId404 | GetDynMetaStdVoorschriftKoppelingId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdVoorschriftKoppelingId = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError = ErrorType<GetDynMetaStdVoorschriftKoppelingId404 | GetDynMetaStdVoorschriftKoppelingId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftKoppelingIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaStdVoorschriftKoppelingId = (
    id: string,
    sTDVoorschriftKoppelingSchemaOptional: STDVoorschriftKoppelingSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftKoppelingSchema>(
      {url: `/dyn/meta/std/voorschrift_koppeling/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftKoppelingSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaStdVoorschriftKoppelingIdMutationOptions = <TError = ErrorType<PatchDynMetaStdVoorschriftKoppelingId400 | PatchDynMetaStdVoorschriftKoppelingId401 | PatchDynMetaStdVoorschriftKoppelingId403 | PatchDynMetaStdVoorschriftKoppelingId404 | PatchDynMetaStdVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftKoppelingId>>, TError,{id: string;data: STDVoorschriftKoppelingSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftKoppelingId>>, TError,{id: string;data: STDVoorschriftKoppelingSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftKoppelingId>>, {id: string;data: STDVoorschriftKoppelingSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaStdVoorschriftKoppelingId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaStdVoorschriftKoppelingIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftKoppelingId>>>
    export type PatchDynMetaStdVoorschriftKoppelingIdMutationBody = STDVoorschriftKoppelingSchemaOptional
    export type PatchDynMetaStdVoorschriftKoppelingIdMutationError = ErrorType<PatchDynMetaStdVoorschriftKoppelingId400 | PatchDynMetaStdVoorschriftKoppelingId401 | PatchDynMetaStdVoorschriftKoppelingId403 | PatchDynMetaStdVoorschriftKoppelingId404 | PatchDynMetaStdVoorschriftKoppelingId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaStdVoorschriftKoppelingId = <TError = ErrorType<PatchDynMetaStdVoorschriftKoppelingId400 | PatchDynMetaStdVoorschriftKoppelingId401 | PatchDynMetaStdVoorschriftKoppelingId403 | PatchDynMetaStdVoorschriftKoppelingId404 | PatchDynMetaStdVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftKoppelingId>>, TError,{id: string;data: STDVoorschriftKoppelingSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaStdVoorschriftKoppelingId>>,
        TError,
        {id: string;data: STDVoorschriftKoppelingSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaStdVoorschriftKoppelingIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaStdVoorschriftKoppelingId = (
    id: string,
    sTDVoorschriftKoppelingSchema: STDVoorschriftKoppelingSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftKoppelingSchema>(
      {url: `/dyn/meta/std/voorschrift_koppeling/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftKoppelingSchema
    },
      options);
    }
  


export const getPutDynMetaStdVoorschriftKoppelingIdMutationOptions = <TError = ErrorType<PutDynMetaStdVoorschriftKoppelingId400 | PutDynMetaStdVoorschriftKoppelingId401 | PutDynMetaStdVoorschriftKoppelingId403 | PutDynMetaStdVoorschriftKoppelingId404 | PutDynMetaStdVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdVoorschriftKoppelingId>>, TError,{id: string;data: STDVoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdVoorschriftKoppelingId>>, TError,{id: string;data: STDVoorschriftKoppelingSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaStdVoorschriftKoppelingId>>, {id: string;data: STDVoorschriftKoppelingSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaStdVoorschriftKoppelingId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaStdVoorschriftKoppelingIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaStdVoorschriftKoppelingId>>>
    export type PutDynMetaStdVoorschriftKoppelingIdMutationBody = STDVoorschriftKoppelingSchema
    export type PutDynMetaStdVoorschriftKoppelingIdMutationError = ErrorType<PutDynMetaStdVoorschriftKoppelingId400 | PutDynMetaStdVoorschriftKoppelingId401 | PutDynMetaStdVoorschriftKoppelingId403 | PutDynMetaStdVoorschriftKoppelingId404 | PutDynMetaStdVoorschriftKoppelingId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaStdVoorschriftKoppelingId = <TError = ErrorType<PutDynMetaStdVoorschriftKoppelingId400 | PutDynMetaStdVoorschriftKoppelingId401 | PutDynMetaStdVoorschriftKoppelingId403 | PutDynMetaStdVoorschriftKoppelingId404 | PutDynMetaStdVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdVoorschriftKoppelingId>>, TError,{id: string;data: STDVoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaStdVoorschriftKoppelingId>>,
        TError,
        {id: string;data: STDVoorschriftKoppelingSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaStdVoorschriftKoppelingIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaStdVoorschriftLabel = (
    params: GetDynMetaStdVoorschriftLabelParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaStdVoorschriftLabel200>(
      {url: `/dyn/meta/std/voorschrift_label`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaStdVoorschriftLabelQueryKey = (params: GetDynMetaStdVoorschriftLabelParams,) => {
    return [`/dyn/meta/std/voorschrift_label`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaStdVoorschriftLabelInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>>, TError = ErrorType<GetDynMetaStdVoorschriftLabel400 | GetDynMetaStdVoorschriftLabel404 | GetDynMetaStdVoorschriftLabel500>>(params: GetDynMetaStdVoorschriftLabelParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftLabelQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>> = ({ signal }) => getDynMetaStdVoorschriftLabel(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftLabelInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>>
export type GetDynMetaStdVoorschriftLabelInfiniteQueryError = ErrorType<GetDynMetaStdVoorschriftLabel400 | GetDynMetaStdVoorschriftLabel404 | GetDynMetaStdVoorschriftLabel500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdVoorschriftLabelInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>>, TError = ErrorType<GetDynMetaStdVoorschriftLabel400 | GetDynMetaStdVoorschriftLabel404 | GetDynMetaStdVoorschriftLabel500>>(
 params: GetDynMetaStdVoorschriftLabelParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftLabelInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdVoorschriftLabelQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError = ErrorType<GetDynMetaStdVoorschriftLabel400 | GetDynMetaStdVoorschriftLabel404 | GetDynMetaStdVoorschriftLabel500>>(params: GetDynMetaStdVoorschriftLabelParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftLabelQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>> = ({ signal }) => getDynMetaStdVoorschriftLabel(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftLabelQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>>
export type GetDynMetaStdVoorschriftLabelQueryError = ErrorType<GetDynMetaStdVoorschriftLabel400 | GetDynMetaStdVoorschriftLabel404 | GetDynMetaStdVoorschriftLabel500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaStdVoorschriftLabel = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError = ErrorType<GetDynMetaStdVoorschriftLabel400 | GetDynMetaStdVoorschriftLabel404 | GetDynMetaStdVoorschriftLabel500>>(
 params: GetDynMetaStdVoorschriftLabelParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftLabelQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaStdVoorschriftLabel = (
    sTDVoorschriftLabelSchema: STDVoorschriftLabelSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftLabelSchema>(
      {url: `/dyn/meta/std/voorschrift_label`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftLabelSchema
    },
      options);
    }
  


export const getPostDynMetaStdVoorschriftLabelMutationOptions = <TError = ErrorType<PostDynMetaStdVoorschriftLabel400 | PostDynMetaStdVoorschriftLabel401 | PostDynMetaStdVoorschriftLabel403 | PostDynMetaStdVoorschriftLabel500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdVoorschriftLabel>>, TError,{data: STDVoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdVoorschriftLabel>>, TError,{data: STDVoorschriftLabelSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaStdVoorschriftLabel>>, {data: STDVoorschriftLabelSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaStdVoorschriftLabel(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaStdVoorschriftLabelMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaStdVoorschriftLabel>>>
    export type PostDynMetaStdVoorschriftLabelMutationBody = STDVoorschriftLabelSchema
    export type PostDynMetaStdVoorschriftLabelMutationError = ErrorType<PostDynMetaStdVoorschriftLabel400 | PostDynMetaStdVoorschriftLabel401 | PostDynMetaStdVoorschriftLabel403 | PostDynMetaStdVoorschriftLabel500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaStdVoorschriftLabel = <TError = ErrorType<PostDynMetaStdVoorschriftLabel400 | PostDynMetaStdVoorschriftLabel401 | PostDynMetaStdVoorschriftLabel403 | PostDynMetaStdVoorschriftLabel500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaStdVoorschriftLabel>>, TError,{data: STDVoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaStdVoorschriftLabel>>,
        TError,
        {data: STDVoorschriftLabelSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaStdVoorschriftLabelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaStdVoorschriftLabelId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftLabelSchema>(
      {url: `/dyn/meta/std/voorschrift_label/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaStdVoorschriftLabelIdMutationOptions = <TError = ErrorType<DeleteDynMetaStdVoorschriftLabelId400 | DeleteDynMetaStdVoorschriftLabelId401 | DeleteDynMetaStdVoorschriftLabelId403 | DeleteDynMetaStdVoorschriftLabelId404 | DeleteDynMetaStdVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftLabelId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftLabelId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftLabelId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaStdVoorschriftLabelId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaStdVoorschriftLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftLabelId>>>
    
    export type DeleteDynMetaStdVoorschriftLabelIdMutationError = ErrorType<DeleteDynMetaStdVoorschriftLabelId400 | DeleteDynMetaStdVoorschriftLabelId401 | DeleteDynMetaStdVoorschriftLabelId403 | DeleteDynMetaStdVoorschriftLabelId404 | DeleteDynMetaStdVoorschriftLabelId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaStdVoorschriftLabelId = <TError = ErrorType<DeleteDynMetaStdVoorschriftLabelId400 | DeleteDynMetaStdVoorschriftLabelId401 | DeleteDynMetaStdVoorschriftLabelId403 | DeleteDynMetaStdVoorschriftLabelId404 | DeleteDynMetaStdVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftLabelId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaStdVoorschriftLabelId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaStdVoorschriftLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaStdVoorschriftLabelId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDVoorschriftLabelSchema>(
      {url: `/dyn/meta/std/voorschrift_label/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaStdVoorschriftLabelIdQueryKey = (id: string,) => {
    return [`/dyn/meta/std/voorschrift_label/${id}`] as const;
    }

    
export const getGetDynMetaStdVoorschriftLabelIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>>, TError = ErrorType<GetDynMetaStdVoorschriftLabelId404 | GetDynMetaStdVoorschriftLabelId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftLabelIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>> = ({ signal }) => getDynMetaStdVoorschriftLabelId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftLabelIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>>
export type GetDynMetaStdVoorschriftLabelIdInfiniteQueryError = ErrorType<GetDynMetaStdVoorschriftLabelId404 | GetDynMetaStdVoorschriftLabelId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdVoorschriftLabelIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>>, TError = ErrorType<GetDynMetaStdVoorschriftLabelId404 | GetDynMetaStdVoorschriftLabelId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftLabelIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaStdVoorschriftLabelIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError = ErrorType<GetDynMetaStdVoorschriftLabelId404 | GetDynMetaStdVoorschriftLabelId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaStdVoorschriftLabelIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>> = ({ signal }) => getDynMetaStdVoorschriftLabelId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaStdVoorschriftLabelIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>>
export type GetDynMetaStdVoorschriftLabelIdQueryError = ErrorType<GetDynMetaStdVoorschriftLabelId404 | GetDynMetaStdVoorschriftLabelId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaStdVoorschriftLabelId = <TData = Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError = ErrorType<GetDynMetaStdVoorschriftLabelId404 | GetDynMetaStdVoorschriftLabelId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaStdVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaStdVoorschriftLabelIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaStdVoorschriftLabelId = (
    id: string,
    sTDVoorschriftLabelSchemaOptional: STDVoorschriftLabelSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftLabelSchema>(
      {url: `/dyn/meta/std/voorschrift_label/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftLabelSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaStdVoorschriftLabelIdMutationOptions = <TError = ErrorType<PatchDynMetaStdVoorschriftLabelId400 | PatchDynMetaStdVoorschriftLabelId401 | PatchDynMetaStdVoorschriftLabelId403 | PatchDynMetaStdVoorschriftLabelId404 | PatchDynMetaStdVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftLabelId>>, TError,{id: string;data: STDVoorschriftLabelSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftLabelId>>, TError,{id: string;data: STDVoorschriftLabelSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftLabelId>>, {id: string;data: STDVoorschriftLabelSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaStdVoorschriftLabelId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaStdVoorschriftLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftLabelId>>>
    export type PatchDynMetaStdVoorschriftLabelIdMutationBody = STDVoorschriftLabelSchemaOptional
    export type PatchDynMetaStdVoorschriftLabelIdMutationError = ErrorType<PatchDynMetaStdVoorschriftLabelId400 | PatchDynMetaStdVoorschriftLabelId401 | PatchDynMetaStdVoorschriftLabelId403 | PatchDynMetaStdVoorschriftLabelId404 | PatchDynMetaStdVoorschriftLabelId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaStdVoorschriftLabelId = <TError = ErrorType<PatchDynMetaStdVoorschriftLabelId400 | PatchDynMetaStdVoorschriftLabelId401 | PatchDynMetaStdVoorschriftLabelId403 | PatchDynMetaStdVoorschriftLabelId404 | PatchDynMetaStdVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaStdVoorschriftLabelId>>, TError,{id: string;data: STDVoorschriftLabelSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaStdVoorschriftLabelId>>,
        TError,
        {id: string;data: STDVoorschriftLabelSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaStdVoorschriftLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaStdVoorschriftLabelId = (
    id: string,
    sTDVoorschriftLabelSchema: STDVoorschriftLabelSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftLabelSchema>(
      {url: `/dyn/meta/std/voorschrift_label/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftLabelSchema
    },
      options);
    }
  


export const getPutDynMetaStdVoorschriftLabelIdMutationOptions = <TError = ErrorType<PutDynMetaStdVoorschriftLabelId400 | PutDynMetaStdVoorschriftLabelId401 | PutDynMetaStdVoorschriftLabelId403 | PutDynMetaStdVoorschriftLabelId404 | PutDynMetaStdVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdVoorschriftLabelId>>, TError,{id: string;data: STDVoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdVoorschriftLabelId>>, TError,{id: string;data: STDVoorschriftLabelSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaStdVoorschriftLabelId>>, {id: string;data: STDVoorschriftLabelSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaStdVoorschriftLabelId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaStdVoorschriftLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaStdVoorschriftLabelId>>>
    export type PutDynMetaStdVoorschriftLabelIdMutationBody = STDVoorschriftLabelSchema
    export type PutDynMetaStdVoorschriftLabelIdMutationError = ErrorType<PutDynMetaStdVoorschriftLabelId400 | PutDynMetaStdVoorschriftLabelId401 | PutDynMetaStdVoorschriftLabelId403 | PutDynMetaStdVoorschriftLabelId404 | PutDynMetaStdVoorschriftLabelId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaStdVoorschriftLabelId = <TError = ErrorType<PutDynMetaStdVoorschriftLabelId400 | PutDynMetaStdVoorschriftLabelId401 | PutDynMetaStdVoorschriftLabelId403 | PutDynMetaStdVoorschriftLabelId404 | PutDynMetaStdVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaStdVoorschriftLabelId>>, TError,{id: string;data: STDVoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaStdVoorschriftLabelId>>,
        TError,
        {id: string;data: STDVoorschriftLabelSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaStdVoorschriftLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaVoorschriftActiviteitInstallatie = (
    params: GetDynMetaVoorschriftActiviteitInstallatieParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaVoorschriftActiviteitInstallatie200>(
      {url: `/dyn/meta/voorschrift_activiteit_installatie`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaVoorschriftActiviteitInstallatieQueryKey = (params: GetDynMetaVoorschriftActiviteitInstallatieParams,) => {
    return [`/dyn/meta/voorschrift_activiteit_installatie`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaVoorschriftActiviteitInstallatieInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatie400 | GetDynMetaVoorschriftActiviteitInstallatie404 | GetDynMetaVoorschriftActiviteitInstallatie500>>(params: GetDynMetaVoorschriftActiviteitInstallatieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftActiviteitInstallatieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>> = ({ signal }) => getDynMetaVoorschriftActiviteitInstallatie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftActiviteitInstallatieInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>>
export type GetDynMetaVoorschriftActiviteitInstallatieInfiniteQueryError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatie400 | GetDynMetaVoorschriftActiviteitInstallatie404 | GetDynMetaVoorschriftActiviteitInstallatie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaVoorschriftActiviteitInstallatieInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatie400 | GetDynMetaVoorschriftActiviteitInstallatie404 | GetDynMetaVoorschriftActiviteitInstallatie500>>(
 params: GetDynMetaVoorschriftActiviteitInstallatieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftActiviteitInstallatieInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaVoorschriftActiviteitInstallatieQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatie400 | GetDynMetaVoorschriftActiviteitInstallatie404 | GetDynMetaVoorschriftActiviteitInstallatie500>>(params: GetDynMetaVoorschriftActiviteitInstallatieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftActiviteitInstallatieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>> = ({ signal }) => getDynMetaVoorschriftActiviteitInstallatie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftActiviteitInstallatieQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>>
export type GetDynMetaVoorschriftActiviteitInstallatieQueryError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatie400 | GetDynMetaVoorschriftActiviteitInstallatie404 | GetDynMetaVoorschriftActiviteitInstallatie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaVoorschriftActiviteitInstallatie = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatie400 | GetDynMetaVoorschriftActiviteitInstallatie404 | GetDynMetaVoorschriftActiviteitInstallatie500>>(
 params: GetDynMetaVoorschriftActiviteitInstallatieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftActiviteitInstallatieQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaVoorschriftActiviteitInstallatie = (
    voorschriftActiviteitInstallatieSchema: VoorschriftActiviteitInstallatieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftActiviteitInstallatieSchema>(
      {url: `/dyn/meta/voorschrift_activiteit_installatie`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftActiviteitInstallatieSchema
    },
      options);
    }
  


export const getPostDynMetaVoorschriftActiviteitInstallatieMutationOptions = <TError = ErrorType<PostDynMetaVoorschriftActiviteitInstallatie400 | PostDynMetaVoorschriftActiviteitInstallatie401 | PostDynMetaVoorschriftActiviteitInstallatie403 | PostDynMetaVoorschriftActiviteitInstallatie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftActiviteitInstallatie>>, TError,{data: VoorschriftActiviteitInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftActiviteitInstallatie>>, TError,{data: VoorschriftActiviteitInstallatieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaVoorschriftActiviteitInstallatie>>, {data: VoorschriftActiviteitInstallatieSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaVoorschriftActiviteitInstallatie(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaVoorschriftActiviteitInstallatieMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaVoorschriftActiviteitInstallatie>>>
    export type PostDynMetaVoorschriftActiviteitInstallatieMutationBody = VoorschriftActiviteitInstallatieSchema
    export type PostDynMetaVoorschriftActiviteitInstallatieMutationError = ErrorType<PostDynMetaVoorschriftActiviteitInstallatie400 | PostDynMetaVoorschriftActiviteitInstallatie401 | PostDynMetaVoorschriftActiviteitInstallatie403 | PostDynMetaVoorschriftActiviteitInstallatie500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaVoorschriftActiviteitInstallatie = <TError = ErrorType<PostDynMetaVoorschriftActiviteitInstallatie400 | PostDynMetaVoorschriftActiviteitInstallatie401 | PostDynMetaVoorschriftActiviteitInstallatie403 | PostDynMetaVoorschriftActiviteitInstallatie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftActiviteitInstallatie>>, TError,{data: VoorschriftActiviteitInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaVoorschriftActiviteitInstallatie>>,
        TError,
        {data: VoorschriftActiviteitInstallatieSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaVoorschriftActiviteitInstallatieMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaVoorschriftActiviteitInstallatieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftActiviteitInstallatieSchema>(
      {url: `/dyn/meta/voorschrift_activiteit_installatie/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaVoorschriftActiviteitInstallatieIdMutationOptions = <TError = ErrorType<DeleteDynMetaVoorschriftActiviteitInstallatieId400 | DeleteDynMetaVoorschriftActiviteitInstallatieId401 | DeleteDynMetaVoorschriftActiviteitInstallatieId403 | DeleteDynMetaVoorschriftActiviteitInstallatieId404 | DeleteDynMetaVoorschriftActiviteitInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaVoorschriftActiviteitInstallatieId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaVoorschriftActiviteitInstallatieId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaVoorschriftActiviteitInstallatieIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaVoorschriftActiviteitInstallatieId>>>
    
    export type DeleteDynMetaVoorschriftActiviteitInstallatieIdMutationError = ErrorType<DeleteDynMetaVoorschriftActiviteitInstallatieId400 | DeleteDynMetaVoorschriftActiviteitInstallatieId401 | DeleteDynMetaVoorschriftActiviteitInstallatieId403 | DeleteDynMetaVoorschriftActiviteitInstallatieId404 | DeleteDynMetaVoorschriftActiviteitInstallatieId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaVoorschriftActiviteitInstallatieId = <TError = ErrorType<DeleteDynMetaVoorschriftActiviteitInstallatieId400 | DeleteDynMetaVoorschriftActiviteitInstallatieId401 | DeleteDynMetaVoorschriftActiviteitInstallatieId403 | DeleteDynMetaVoorschriftActiviteitInstallatieId404 | DeleteDynMetaVoorschriftActiviteitInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaVoorschriftActiviteitInstallatieId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaVoorschriftActiviteitInstallatieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaVoorschriftActiviteitInstallatieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<VoorschriftActiviteitInstallatieSchema>(
      {url: `/dyn/meta/voorschrift_activiteit_installatie/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaVoorschriftActiviteitInstallatieIdQueryKey = (id: string,) => {
    return [`/dyn/meta/voorschrift_activiteit_installatie/${id}`] as const;
    }

    
export const getGetDynMetaVoorschriftActiviteitInstallatieIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatieId404 | GetDynMetaVoorschriftActiviteitInstallatieId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftActiviteitInstallatieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>> = ({ signal }) => getDynMetaVoorschriftActiviteitInstallatieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftActiviteitInstallatieIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>>
export type GetDynMetaVoorschriftActiviteitInstallatieIdInfiniteQueryError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatieId404 | GetDynMetaVoorschriftActiviteitInstallatieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaVoorschriftActiviteitInstallatieIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatieId404 | GetDynMetaVoorschriftActiviteitInstallatieId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftActiviteitInstallatieIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaVoorschriftActiviteitInstallatieIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatieId404 | GetDynMetaVoorschriftActiviteitInstallatieId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftActiviteitInstallatieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>> = ({ signal }) => getDynMetaVoorschriftActiviteitInstallatieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftActiviteitInstallatieIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>>
export type GetDynMetaVoorschriftActiviteitInstallatieIdQueryError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatieId404 | GetDynMetaVoorschriftActiviteitInstallatieId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaVoorschriftActiviteitInstallatieId = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError = ErrorType<GetDynMetaVoorschriftActiviteitInstallatieId404 | GetDynMetaVoorschriftActiviteitInstallatieId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftActiviteitInstallatieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftActiviteitInstallatieIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaVoorschriftActiviteitInstallatieId = (
    id: string,
    voorschriftActiviteitInstallatieSchemaOptional: VoorschriftActiviteitInstallatieSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftActiviteitInstallatieSchema>(
      {url: `/dyn/meta/voorschrift_activiteit_installatie/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftActiviteitInstallatieSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaVoorschriftActiviteitInstallatieIdMutationOptions = <TError = ErrorType<PatchDynMetaVoorschriftActiviteitInstallatieId400 | PatchDynMetaVoorschriftActiviteitInstallatieId401 | PatchDynMetaVoorschriftActiviteitInstallatieId403 | PatchDynMetaVoorschriftActiviteitInstallatieId404 | PatchDynMetaVoorschriftActiviteitInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string;data: VoorschriftActiviteitInstallatieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string;data: VoorschriftActiviteitInstallatieSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaVoorschriftActiviteitInstallatieId>>, {id: string;data: VoorschriftActiviteitInstallatieSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaVoorschriftActiviteitInstallatieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaVoorschriftActiviteitInstallatieIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaVoorschriftActiviteitInstallatieId>>>
    export type PatchDynMetaVoorschriftActiviteitInstallatieIdMutationBody = VoorschriftActiviteitInstallatieSchemaOptional
    export type PatchDynMetaVoorschriftActiviteitInstallatieIdMutationError = ErrorType<PatchDynMetaVoorschriftActiviteitInstallatieId400 | PatchDynMetaVoorschriftActiviteitInstallatieId401 | PatchDynMetaVoorschriftActiviteitInstallatieId403 | PatchDynMetaVoorschriftActiviteitInstallatieId404 | PatchDynMetaVoorschriftActiviteitInstallatieId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaVoorschriftActiviteitInstallatieId = <TError = ErrorType<PatchDynMetaVoorschriftActiviteitInstallatieId400 | PatchDynMetaVoorschriftActiviteitInstallatieId401 | PatchDynMetaVoorschriftActiviteitInstallatieId403 | PatchDynMetaVoorschriftActiviteitInstallatieId404 | PatchDynMetaVoorschriftActiviteitInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string;data: VoorschriftActiviteitInstallatieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaVoorschriftActiviteitInstallatieId>>,
        TError,
        {id: string;data: VoorschriftActiviteitInstallatieSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaVoorschriftActiviteitInstallatieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaVoorschriftActiviteitInstallatieId = (
    id: string,
    voorschriftActiviteitInstallatieSchema: VoorschriftActiviteitInstallatieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftActiviteitInstallatieSchema>(
      {url: `/dyn/meta/voorschrift_activiteit_installatie/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftActiviteitInstallatieSchema
    },
      options);
    }
  


export const getPutDynMetaVoorschriftActiviteitInstallatieIdMutationOptions = <TError = ErrorType<PutDynMetaVoorschriftActiviteitInstallatieId400 | PutDynMetaVoorschriftActiviteitInstallatieId401 | PutDynMetaVoorschriftActiviteitInstallatieId403 | PutDynMetaVoorschriftActiviteitInstallatieId404 | PutDynMetaVoorschriftActiviteitInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string;data: VoorschriftActiviteitInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string;data: VoorschriftActiviteitInstallatieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaVoorschriftActiviteitInstallatieId>>, {id: string;data: VoorschriftActiviteitInstallatieSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaVoorschriftActiviteitInstallatieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaVoorschriftActiviteitInstallatieIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaVoorschriftActiviteitInstallatieId>>>
    export type PutDynMetaVoorschriftActiviteitInstallatieIdMutationBody = VoorschriftActiviteitInstallatieSchema
    export type PutDynMetaVoorschriftActiviteitInstallatieIdMutationError = ErrorType<PutDynMetaVoorschriftActiviteitInstallatieId400 | PutDynMetaVoorschriftActiviteitInstallatieId401 | PutDynMetaVoorschriftActiviteitInstallatieId403 | PutDynMetaVoorschriftActiviteitInstallatieId404 | PutDynMetaVoorschriftActiviteitInstallatieId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaVoorschriftActiviteitInstallatieId = <TError = ErrorType<PutDynMetaVoorschriftActiviteitInstallatieId400 | PutDynMetaVoorschriftActiviteitInstallatieId401 | PutDynMetaVoorschriftActiviteitInstallatieId403 | PutDynMetaVoorschriftActiviteitInstallatieId404 | PutDynMetaVoorschriftActiviteitInstallatieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftActiviteitInstallatieId>>, TError,{id: string;data: VoorschriftActiviteitInstallatieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaVoorschriftActiviteitInstallatieId>>,
        TError,
        {id: string;data: VoorschriftActiviteitInstallatieSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaVoorschriftActiviteitInstallatieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaVoorschriftHistorischBesluit = (
    params: GetDynMetaVoorschriftHistorischBesluitParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaVoorschriftHistorischBesluit200>(
      {url: `/dyn/meta/voorschrift_historisch_besluit`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaVoorschriftHistorischBesluitQueryKey = (params: GetDynMetaVoorschriftHistorischBesluitParams,) => {
    return [`/dyn/meta/voorschrift_historisch_besluit`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaVoorschriftHistorischBesluitInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluit400 | GetDynMetaVoorschriftHistorischBesluit404 | GetDynMetaVoorschriftHistorischBesluit500>>(params: GetDynMetaVoorschriftHistorischBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftHistorischBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>> = ({ signal }) => getDynMetaVoorschriftHistorischBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftHistorischBesluitInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>>
export type GetDynMetaVoorschriftHistorischBesluitInfiniteQueryError = ErrorType<GetDynMetaVoorschriftHistorischBesluit400 | GetDynMetaVoorschriftHistorischBesluit404 | GetDynMetaVoorschriftHistorischBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaVoorschriftHistorischBesluitInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluit400 | GetDynMetaVoorschriftHistorischBesluit404 | GetDynMetaVoorschriftHistorischBesluit500>>(
 params: GetDynMetaVoorschriftHistorischBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftHistorischBesluitInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaVoorschriftHistorischBesluitQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluit400 | GetDynMetaVoorschriftHistorischBesluit404 | GetDynMetaVoorschriftHistorischBesluit500>>(params: GetDynMetaVoorschriftHistorischBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftHistorischBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>> = ({ signal }) => getDynMetaVoorschriftHistorischBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftHistorischBesluitQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>>
export type GetDynMetaVoorschriftHistorischBesluitQueryError = ErrorType<GetDynMetaVoorschriftHistorischBesluit400 | GetDynMetaVoorschriftHistorischBesluit404 | GetDynMetaVoorschriftHistorischBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaVoorschriftHistorischBesluit = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluit400 | GetDynMetaVoorschriftHistorischBesluit404 | GetDynMetaVoorschriftHistorischBesluit500>>(
 params: GetDynMetaVoorschriftHistorischBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftHistorischBesluitQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaVoorschriftHistorischBesluit = (
    voorschriftHistorischBesluitSchema: VoorschriftHistorischBesluitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftHistorischBesluitSchema>(
      {url: `/dyn/meta/voorschrift_historisch_besluit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftHistorischBesluitSchema
    },
      options);
    }
  


export const getPostDynMetaVoorschriftHistorischBesluitMutationOptions = <TError = ErrorType<PostDynMetaVoorschriftHistorischBesluit400 | PostDynMetaVoorschriftHistorischBesluit401 | PostDynMetaVoorschriftHistorischBesluit403 | PostDynMetaVoorschriftHistorischBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftHistorischBesluit>>, TError,{data: VoorschriftHistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftHistorischBesluit>>, TError,{data: VoorschriftHistorischBesluitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaVoorschriftHistorischBesluit>>, {data: VoorschriftHistorischBesluitSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaVoorschriftHistorischBesluit(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaVoorschriftHistorischBesluitMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaVoorschriftHistorischBesluit>>>
    export type PostDynMetaVoorschriftHistorischBesluitMutationBody = VoorschriftHistorischBesluitSchema
    export type PostDynMetaVoorschriftHistorischBesluitMutationError = ErrorType<PostDynMetaVoorschriftHistorischBesluit400 | PostDynMetaVoorschriftHistorischBesluit401 | PostDynMetaVoorschriftHistorischBesluit403 | PostDynMetaVoorschriftHistorischBesluit500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaVoorschriftHistorischBesluit = <TError = ErrorType<PostDynMetaVoorschriftHistorischBesluit400 | PostDynMetaVoorschriftHistorischBesluit401 | PostDynMetaVoorschriftHistorischBesluit403 | PostDynMetaVoorschriftHistorischBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftHistorischBesluit>>, TError,{data: VoorschriftHistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaVoorschriftHistorischBesluit>>,
        TError,
        {data: VoorschriftHistorischBesluitSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaVoorschriftHistorischBesluitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaVoorschriftHistorischBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftHistorischBesluitSchema>(
      {url: `/dyn/meta/voorschrift_historisch_besluit/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaVoorschriftHistorischBesluitIdMutationOptions = <TError = ErrorType<DeleteDynMetaVoorschriftHistorischBesluitId400 | DeleteDynMetaVoorschriftHistorischBesluitId401 | DeleteDynMetaVoorschriftHistorischBesluitId403 | DeleteDynMetaVoorschriftHistorischBesluitId404 | DeleteDynMetaVoorschriftHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaVoorschriftHistorischBesluitId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaVoorschriftHistorischBesluitId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaVoorschriftHistorischBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaVoorschriftHistorischBesluitId>>>
    
    export type DeleteDynMetaVoorschriftHistorischBesluitIdMutationError = ErrorType<DeleteDynMetaVoorschriftHistorischBesluitId400 | DeleteDynMetaVoorschriftHistorischBesluitId401 | DeleteDynMetaVoorschriftHistorischBesluitId403 | DeleteDynMetaVoorschriftHistorischBesluitId404 | DeleteDynMetaVoorschriftHistorischBesluitId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaVoorschriftHistorischBesluitId = <TError = ErrorType<DeleteDynMetaVoorschriftHistorischBesluitId400 | DeleteDynMetaVoorschriftHistorischBesluitId401 | DeleteDynMetaVoorschriftHistorischBesluitId403 | DeleteDynMetaVoorschriftHistorischBesluitId404 | DeleteDynMetaVoorschriftHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaVoorschriftHistorischBesluitId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaVoorschriftHistorischBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaVoorschriftHistorischBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<VoorschriftHistorischBesluitSchema>(
      {url: `/dyn/meta/voorschrift_historisch_besluit/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaVoorschriftHistorischBesluitIdQueryKey = (id: string,) => {
    return [`/dyn/meta/voorschrift_historisch_besluit/${id}`] as const;
    }

    
export const getGetDynMetaVoorschriftHistorischBesluitIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluitId404 | GetDynMetaVoorschriftHistorischBesluitId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftHistorischBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>> = ({ signal }) => getDynMetaVoorschriftHistorischBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftHistorischBesluitIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>>
export type GetDynMetaVoorschriftHistorischBesluitIdInfiniteQueryError = ErrorType<GetDynMetaVoorschriftHistorischBesluitId404 | GetDynMetaVoorschriftHistorischBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaVoorschriftHistorischBesluitIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluitId404 | GetDynMetaVoorschriftHistorischBesluitId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftHistorischBesluitIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaVoorschriftHistorischBesluitIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluitId404 | GetDynMetaVoorschriftHistorischBesluitId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftHistorischBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>> = ({ signal }) => getDynMetaVoorschriftHistorischBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftHistorischBesluitIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>>
export type GetDynMetaVoorschriftHistorischBesluitIdQueryError = ErrorType<GetDynMetaVoorschriftHistorischBesluitId404 | GetDynMetaVoorschriftHistorischBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaVoorschriftHistorischBesluitId = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError = ErrorType<GetDynMetaVoorschriftHistorischBesluitId404 | GetDynMetaVoorschriftHistorischBesluitId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftHistorischBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftHistorischBesluitIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaVoorschriftHistorischBesluitId = (
    id: string,
    voorschriftHistorischBesluitSchemaOptional: VoorschriftHistorischBesluitSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftHistorischBesluitSchema>(
      {url: `/dyn/meta/voorschrift_historisch_besluit/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftHistorischBesluitSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaVoorschriftHistorischBesluitIdMutationOptions = <TError = ErrorType<PatchDynMetaVoorschriftHistorischBesluitId400 | PatchDynMetaVoorschriftHistorischBesluitId401 | PatchDynMetaVoorschriftHistorischBesluitId403 | PatchDynMetaVoorschriftHistorischBesluitId404 | PatchDynMetaVoorschriftHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string;data: VoorschriftHistorischBesluitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string;data: VoorschriftHistorischBesluitSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaVoorschriftHistorischBesluitId>>, {id: string;data: VoorschriftHistorischBesluitSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaVoorschriftHistorischBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaVoorschriftHistorischBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaVoorschriftHistorischBesluitId>>>
    export type PatchDynMetaVoorschriftHistorischBesluitIdMutationBody = VoorschriftHistorischBesluitSchemaOptional
    export type PatchDynMetaVoorschriftHistorischBesluitIdMutationError = ErrorType<PatchDynMetaVoorschriftHistorischBesluitId400 | PatchDynMetaVoorschriftHistorischBesluitId401 | PatchDynMetaVoorschriftHistorischBesluitId403 | PatchDynMetaVoorschriftHistorischBesluitId404 | PatchDynMetaVoorschriftHistorischBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaVoorschriftHistorischBesluitId = <TError = ErrorType<PatchDynMetaVoorschriftHistorischBesluitId400 | PatchDynMetaVoorschriftHistorischBesluitId401 | PatchDynMetaVoorschriftHistorischBesluitId403 | PatchDynMetaVoorschriftHistorischBesluitId404 | PatchDynMetaVoorschriftHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string;data: VoorschriftHistorischBesluitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaVoorschriftHistorischBesluitId>>,
        TError,
        {id: string;data: VoorschriftHistorischBesluitSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaVoorschriftHistorischBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaVoorschriftHistorischBesluitId = (
    id: string,
    voorschriftHistorischBesluitSchema: VoorschriftHistorischBesluitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftHistorischBesluitSchema>(
      {url: `/dyn/meta/voorschrift_historisch_besluit/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftHistorischBesluitSchema
    },
      options);
    }
  


export const getPutDynMetaVoorschriftHistorischBesluitIdMutationOptions = <TError = ErrorType<PutDynMetaVoorschriftHistorischBesluitId400 | PutDynMetaVoorschriftHistorischBesluitId401 | PutDynMetaVoorschriftHistorischBesluitId403 | PutDynMetaVoorschriftHistorischBesluitId404 | PutDynMetaVoorschriftHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string;data: VoorschriftHistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string;data: VoorschriftHistorischBesluitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaVoorschriftHistorischBesluitId>>, {id: string;data: VoorschriftHistorischBesluitSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaVoorschriftHistorischBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaVoorschriftHistorischBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaVoorschriftHistorischBesluitId>>>
    export type PutDynMetaVoorschriftHistorischBesluitIdMutationBody = VoorschriftHistorischBesluitSchema
    export type PutDynMetaVoorschriftHistorischBesluitIdMutationError = ErrorType<PutDynMetaVoorschriftHistorischBesluitId400 | PutDynMetaVoorschriftHistorischBesluitId401 | PutDynMetaVoorschriftHistorischBesluitId403 | PutDynMetaVoorschriftHistorischBesluitId404 | PutDynMetaVoorschriftHistorischBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaVoorschriftHistorischBesluitId = <TError = ErrorType<PutDynMetaVoorschriftHistorischBesluitId400 | PutDynMetaVoorschriftHistorischBesluitId401 | PutDynMetaVoorschriftHistorischBesluitId403 | PutDynMetaVoorschriftHistorischBesluitId404 | PutDynMetaVoorschriftHistorischBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftHistorischBesluitId>>, TError,{id: string;data: VoorschriftHistorischBesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaVoorschriftHistorischBesluitId>>,
        TError,
        {id: string;data: VoorschriftHistorischBesluitSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaVoorschriftHistorischBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynMetaVoorschriftLabel = (
    params: GetDynMetaVoorschriftLabelParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynMetaVoorschriftLabel200>(
      {url: `/dyn/meta/voorschrift_label`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynMetaVoorschriftLabelQueryKey = (params: GetDynMetaVoorschriftLabelParams,) => {
    return [`/dyn/meta/voorschrift_label`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynMetaVoorschriftLabelInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>>, TError = ErrorType<GetDynMetaVoorschriftLabel400 | GetDynMetaVoorschriftLabel404 | GetDynMetaVoorschriftLabel500>>(params: GetDynMetaVoorschriftLabelParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftLabelQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>> = ({ signal }) => getDynMetaVoorschriftLabel(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftLabelInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>>
export type GetDynMetaVoorschriftLabelInfiniteQueryError = ErrorType<GetDynMetaVoorschriftLabel400 | GetDynMetaVoorschriftLabel404 | GetDynMetaVoorschriftLabel500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaVoorschriftLabelInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>>, TError = ErrorType<GetDynMetaVoorschriftLabel400 | GetDynMetaVoorschriftLabel404 | GetDynMetaVoorschriftLabel500>>(
 params: GetDynMetaVoorschriftLabelParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftLabelInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaVoorschriftLabelQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError = ErrorType<GetDynMetaVoorschriftLabel400 | GetDynMetaVoorschriftLabel404 | GetDynMetaVoorschriftLabel500>>(params: GetDynMetaVoorschriftLabelParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftLabelQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>> = ({ signal }) => getDynMetaVoorschriftLabel(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftLabelQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>>
export type GetDynMetaVoorschriftLabelQueryError = ErrorType<GetDynMetaVoorschriftLabel400 | GetDynMetaVoorschriftLabel404 | GetDynMetaVoorschriftLabel500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynMetaVoorschriftLabel = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError = ErrorType<GetDynMetaVoorschriftLabel400 | GetDynMetaVoorschriftLabel404 | GetDynMetaVoorschriftLabel500>>(
 params: GetDynMetaVoorschriftLabelParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabel>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftLabelQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynMetaVoorschriftLabel = (
    voorschriftLabelSchema: VoorschriftLabelSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLabelSchema>(
      {url: `/dyn/meta/voorschrift_label`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftLabelSchema
    },
      options);
    }
  


export const getPostDynMetaVoorschriftLabelMutationOptions = <TError = ErrorType<PostDynMetaVoorschriftLabel400 | PostDynMetaVoorschriftLabel401 | PostDynMetaVoorschriftLabel403 | PostDynMetaVoorschriftLabel500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftLabel>>, TError,{data: VoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftLabel>>, TError,{data: VoorschriftLabelSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynMetaVoorschriftLabel>>, {data: VoorschriftLabelSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynMetaVoorschriftLabel(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynMetaVoorschriftLabelMutationResult = NonNullable<Awaited<ReturnType<typeof postDynMetaVoorschriftLabel>>>
    export type PostDynMetaVoorschriftLabelMutationBody = VoorschriftLabelSchema
    export type PostDynMetaVoorschriftLabelMutationError = ErrorType<PostDynMetaVoorschriftLabel400 | PostDynMetaVoorschriftLabel401 | PostDynMetaVoorschriftLabel403 | PostDynMetaVoorschriftLabel500>

    /**
 * @summary Create a new instance
 */
export const usePostDynMetaVoorschriftLabel = <TError = ErrorType<PostDynMetaVoorschriftLabel400 | PostDynMetaVoorschriftLabel401 | PostDynMetaVoorschriftLabel403 | PostDynMetaVoorschriftLabel500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynMetaVoorschriftLabel>>, TError,{data: VoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynMetaVoorschriftLabel>>,
        TError,
        {data: VoorschriftLabelSchema},
        TContext
      > => {

      const mutationOptions = getPostDynMetaVoorschriftLabelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynMetaVoorschriftLabelId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLabelSchema>(
      {url: `/dyn/meta/voorschrift_label/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynMetaVoorschriftLabelIdMutationOptions = <TError = ErrorType<DeleteDynMetaVoorschriftLabelId400 | DeleteDynMetaVoorschriftLabelId401 | DeleteDynMetaVoorschriftLabelId403 | DeleteDynMetaVoorschriftLabelId404 | DeleteDynMetaVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftLabelId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftLabelId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynMetaVoorschriftLabelId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynMetaVoorschriftLabelId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynMetaVoorschriftLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynMetaVoorschriftLabelId>>>
    
    export type DeleteDynMetaVoorschriftLabelIdMutationError = ErrorType<DeleteDynMetaVoorschriftLabelId400 | DeleteDynMetaVoorschriftLabelId401 | DeleteDynMetaVoorschriftLabelId403 | DeleteDynMetaVoorschriftLabelId404 | DeleteDynMetaVoorschriftLabelId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynMetaVoorschriftLabelId = <TError = ErrorType<DeleteDynMetaVoorschriftLabelId400 | DeleteDynMetaVoorschriftLabelId401 | DeleteDynMetaVoorschriftLabelId403 | DeleteDynMetaVoorschriftLabelId404 | DeleteDynMetaVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynMetaVoorschriftLabelId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynMetaVoorschriftLabelId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynMetaVoorschriftLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynMetaVoorschriftLabelId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<VoorschriftLabelSchema>(
      {url: `/dyn/meta/voorschrift_label/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynMetaVoorschriftLabelIdQueryKey = (id: string,) => {
    return [`/dyn/meta/voorschrift_label/${id}`] as const;
    }

    
export const getGetDynMetaVoorschriftLabelIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>>, TError = ErrorType<GetDynMetaVoorschriftLabelId404 | GetDynMetaVoorschriftLabelId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftLabelIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>> = ({ signal }) => getDynMetaVoorschriftLabelId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftLabelIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>>
export type GetDynMetaVoorschriftLabelIdInfiniteQueryError = ErrorType<GetDynMetaVoorschriftLabelId404 | GetDynMetaVoorschriftLabelId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaVoorschriftLabelIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>>, TError = ErrorType<GetDynMetaVoorschriftLabelId404 | GetDynMetaVoorschriftLabelId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftLabelIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynMetaVoorschriftLabelIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError = ErrorType<GetDynMetaVoorschriftLabelId404 | GetDynMetaVoorschriftLabelId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynMetaVoorschriftLabelIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>> = ({ signal }) => getDynMetaVoorschriftLabelId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynMetaVoorschriftLabelIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>>
export type GetDynMetaVoorschriftLabelIdQueryError = ErrorType<GetDynMetaVoorschriftLabelId404 | GetDynMetaVoorschriftLabelId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynMetaVoorschriftLabelId = <TData = Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError = ErrorType<GetDynMetaVoorschriftLabelId404 | GetDynMetaVoorschriftLabelId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynMetaVoorschriftLabelId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynMetaVoorschriftLabelIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynMetaVoorschriftLabelId = (
    id: string,
    voorschriftLabelSchemaOptional: VoorschriftLabelSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLabelSchema>(
      {url: `/dyn/meta/voorschrift_label/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftLabelSchemaOptional
    },
      options);
    }
  


export const getPatchDynMetaVoorschriftLabelIdMutationOptions = <TError = ErrorType<PatchDynMetaVoorschriftLabelId400 | PatchDynMetaVoorschriftLabelId401 | PatchDynMetaVoorschriftLabelId403 | PatchDynMetaVoorschriftLabelId404 | PatchDynMetaVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftLabelId>>, TError,{id: string;data: VoorschriftLabelSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftLabelId>>, TError,{id: string;data: VoorschriftLabelSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynMetaVoorschriftLabelId>>, {id: string;data: VoorschriftLabelSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynMetaVoorschriftLabelId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynMetaVoorschriftLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynMetaVoorschriftLabelId>>>
    export type PatchDynMetaVoorschriftLabelIdMutationBody = VoorschriftLabelSchemaOptional
    export type PatchDynMetaVoorschriftLabelIdMutationError = ErrorType<PatchDynMetaVoorschriftLabelId400 | PatchDynMetaVoorschriftLabelId401 | PatchDynMetaVoorschriftLabelId403 | PatchDynMetaVoorschriftLabelId404 | PatchDynMetaVoorschriftLabelId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynMetaVoorschriftLabelId = <TError = ErrorType<PatchDynMetaVoorschriftLabelId400 | PatchDynMetaVoorschriftLabelId401 | PatchDynMetaVoorschriftLabelId403 | PatchDynMetaVoorschriftLabelId404 | PatchDynMetaVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynMetaVoorschriftLabelId>>, TError,{id: string;data: VoorschriftLabelSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynMetaVoorschriftLabelId>>,
        TError,
        {id: string;data: VoorschriftLabelSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynMetaVoorschriftLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynMetaVoorschriftLabelId = (
    id: string,
    voorschriftLabelSchema: VoorschriftLabelSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLabelSchema>(
      {url: `/dyn/meta/voorschrift_label/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftLabelSchema
    },
      options);
    }
  


export const getPutDynMetaVoorschriftLabelIdMutationOptions = <TError = ErrorType<PutDynMetaVoorschriftLabelId400 | PutDynMetaVoorschriftLabelId401 | PutDynMetaVoorschriftLabelId403 | PutDynMetaVoorschriftLabelId404 | PutDynMetaVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftLabelId>>, TError,{id: string;data: VoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftLabelId>>, TError,{id: string;data: VoorschriftLabelSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynMetaVoorschriftLabelId>>, {id: string;data: VoorschriftLabelSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynMetaVoorschriftLabelId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynMetaVoorschriftLabelIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynMetaVoorschriftLabelId>>>
    export type PutDynMetaVoorschriftLabelIdMutationBody = VoorschriftLabelSchema
    export type PutDynMetaVoorschriftLabelIdMutationError = ErrorType<PutDynMetaVoorschriftLabelId400 | PutDynMetaVoorschriftLabelId401 | PutDynMetaVoorschriftLabelId403 | PutDynMetaVoorschriftLabelId404 | PutDynMetaVoorschriftLabelId500>

    /**
 * @summary Update an instance
 */
export const usePutDynMetaVoorschriftLabelId = <TError = ErrorType<PutDynMetaVoorschriftLabelId400 | PutDynMetaVoorschriftLabelId401 | PutDynMetaVoorschriftLabelId403 | PutDynMetaVoorschriftLabelId404 | PutDynMetaVoorschriftLabelId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynMetaVoorschriftLabelId>>, TError,{id: string;data: VoorschriftLabelSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynMetaVoorschriftLabelId>>,
        TError,
        {id: string;data: VoorschriftLabelSchema},
        TContext
      > => {

      const mutationOptions = getPutDynMetaVoorschriftLabelIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynStdMap = (
    params: GetDynStdMapParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynStdMap200>(
      {url: `/dyn/std/map`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynStdMapQueryKey = (params: GetDynStdMapParams,) => {
    return [`/dyn/std/map`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynStdMapInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdMap>>>, TError = ErrorType<GetDynStdMap400 | GetDynStdMap404 | GetDynStdMap500>>(params: GetDynStdMapParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdMapQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdMap>>> = ({ signal }) => getDynStdMap(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdMap>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdMapInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdMap>>>
export type GetDynStdMapInfiniteQueryError = ErrorType<GetDynStdMap400 | GetDynStdMap404 | GetDynStdMap500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdMapInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdMap>>>, TError = ErrorType<GetDynStdMap400 | GetDynStdMap404 | GetDynStdMap500>>(
 params: GetDynStdMapParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdMapInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdMapQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdMap>>, TError = ErrorType<GetDynStdMap400 | GetDynStdMap404 | GetDynStdMap500>>(params: GetDynStdMapParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdMapQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdMap>>> = ({ signal }) => getDynStdMap(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdMap>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdMapQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdMap>>>
export type GetDynStdMapQueryError = ErrorType<GetDynStdMap400 | GetDynStdMap404 | GetDynStdMap500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdMap = <TData = Awaited<ReturnType<typeof getDynStdMap>>, TError = ErrorType<GetDynStdMap400 | GetDynStdMap404 | GetDynStdMap500>>(
 params: GetDynStdMapParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdMap>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdMapQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynStdMap = (
    sTDMapSchema: STDMapSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDMapSchema>(
      {url: `/dyn/std/map`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDMapSchema
    },
      options);
    }
  


export const getPostDynStdMapMutationOptions = <TError = ErrorType<PostDynStdMap400 | PostDynStdMap401 | PostDynStdMap403 | PostDynStdMap500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdMap>>, TError,{data: STDMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynStdMap>>, TError,{data: STDMapSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynStdMap>>, {data: STDMapSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynStdMap(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynStdMapMutationResult = NonNullable<Awaited<ReturnType<typeof postDynStdMap>>>
    export type PostDynStdMapMutationBody = STDMapSchema
    export type PostDynStdMapMutationError = ErrorType<PostDynStdMap400 | PostDynStdMap401 | PostDynStdMap403 | PostDynStdMap500>

    /**
 * @summary Create a new instance
 */
export const usePostDynStdMap = <TError = ErrorType<PostDynStdMap400 | PostDynStdMap401 | PostDynStdMap403 | PostDynStdMap500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdMap>>, TError,{data: STDMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynStdMap>>,
        TError,
        {data: STDMapSchema},
        TContext
      > => {

      const mutationOptions = getPostDynStdMapMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynStdMapId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDMapSchema>(
      {url: `/dyn/std/map/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynStdMapIdMutationOptions = <TError = ErrorType<DeleteDynStdMapId400 | DeleteDynStdMapId401 | DeleteDynStdMapId403 | DeleteDynStdMapId404 | DeleteDynStdMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdMapId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdMapId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynStdMapId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynStdMapId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynStdMapIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynStdMapId>>>
    
    export type DeleteDynStdMapIdMutationError = ErrorType<DeleteDynStdMapId400 | DeleteDynStdMapId401 | DeleteDynStdMapId403 | DeleteDynStdMapId404 | DeleteDynStdMapId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynStdMapId = <TError = ErrorType<DeleteDynStdMapId400 | DeleteDynStdMapId401 | DeleteDynStdMapId403 | DeleteDynStdMapId404 | DeleteDynStdMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdMapId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynStdMapId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynStdMapIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynStdMapId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDMapSchema>(
      {url: `/dyn/std/map/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynStdMapIdQueryKey = (id: string,) => {
    return [`/dyn/std/map/${id}`] as const;
    }

    
export const getGetDynStdMapIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdMapId>>>, TError = ErrorType<GetDynStdMapId404 | GetDynStdMapId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdMapIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdMapId>>> = ({ signal }) => getDynStdMapId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdMapId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdMapIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdMapId>>>
export type GetDynStdMapIdInfiniteQueryError = ErrorType<GetDynStdMapId404 | GetDynStdMapId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdMapIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdMapId>>>, TError = ErrorType<GetDynStdMapId404 | GetDynStdMapId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdMapIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdMapIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdMapId>>, TError = ErrorType<GetDynStdMapId404 | GetDynStdMapId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdMapIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdMapId>>> = ({ signal }) => getDynStdMapId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdMapId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdMapIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdMapId>>>
export type GetDynStdMapIdQueryError = ErrorType<GetDynStdMapId404 | GetDynStdMapId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdMapId = <TData = Awaited<ReturnType<typeof getDynStdMapId>>, TError = ErrorType<GetDynStdMapId404 | GetDynStdMapId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdMapId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdMapIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynStdMapId = (
    id: string,
    sTDMapSchemaOptional: STDMapSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDMapSchema>(
      {url: `/dyn/std/map/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDMapSchemaOptional
    },
      options);
    }
  


export const getPatchDynStdMapIdMutationOptions = <TError = ErrorType<PatchDynStdMapId400 | PatchDynStdMapId401 | PatchDynStdMapId403 | PatchDynStdMapId404 | PatchDynStdMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdMapId>>, TError,{id: string;data: STDMapSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynStdMapId>>, TError,{id: string;data: STDMapSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynStdMapId>>, {id: string;data: STDMapSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynStdMapId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynStdMapIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynStdMapId>>>
    export type PatchDynStdMapIdMutationBody = STDMapSchemaOptional
    export type PatchDynStdMapIdMutationError = ErrorType<PatchDynStdMapId400 | PatchDynStdMapId401 | PatchDynStdMapId403 | PatchDynStdMapId404 | PatchDynStdMapId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynStdMapId = <TError = ErrorType<PatchDynStdMapId400 | PatchDynStdMapId401 | PatchDynStdMapId403 | PatchDynStdMapId404 | PatchDynStdMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdMapId>>, TError,{id: string;data: STDMapSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynStdMapId>>,
        TError,
        {id: string;data: STDMapSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynStdMapIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynStdMapId = (
    id: string,
    sTDMapSchema: STDMapSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDMapSchema>(
      {url: `/dyn/std/map/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDMapSchema
    },
      options);
    }
  


export const getPutDynStdMapIdMutationOptions = <TError = ErrorType<PutDynStdMapId400 | PutDynStdMapId401 | PutDynStdMapId403 | PutDynStdMapId404 | PutDynStdMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdMapId>>, TError,{id: string;data: STDMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynStdMapId>>, TError,{id: string;data: STDMapSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynStdMapId>>, {id: string;data: STDMapSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynStdMapId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynStdMapIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynStdMapId>>>
    export type PutDynStdMapIdMutationBody = STDMapSchema
    export type PutDynStdMapIdMutationError = ErrorType<PutDynStdMapId400 | PutDynStdMapId401 | PutDynStdMapId403 | PutDynStdMapId404 | PutDynStdMapId500>

    /**
 * @summary Update an instance
 */
export const usePutDynStdMapId = <TError = ErrorType<PutDynStdMapId400 | PutDynStdMapId401 | PutDynStdMapId403 | PutDynStdMapId404 | PutDynStdMapId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdMapId>>, TError,{id: string;data: STDMapSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynStdMapId>>,
        TError,
        {id: string;data: STDMapSchema},
        TContext
      > => {

      const mutationOptions = getPutDynStdMapIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynStdParagraaf = (
    params: GetDynStdParagraafParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynStdParagraaf200>(
      {url: `/dyn/std/paragraaf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynStdParagraafQueryKey = (params: GetDynStdParagraafParams,) => {
    return [`/dyn/std/paragraaf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynStdParagraafInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdParagraaf>>>, TError = ErrorType<GetDynStdParagraaf400 | GetDynStdParagraaf404 | GetDynStdParagraaf500>>(params: GetDynStdParagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdParagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdParagraaf>>> = ({ signal }) => getDynStdParagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdParagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdParagraafInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdParagraaf>>>
export type GetDynStdParagraafInfiniteQueryError = ErrorType<GetDynStdParagraaf400 | GetDynStdParagraaf404 | GetDynStdParagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdParagraafInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdParagraaf>>>, TError = ErrorType<GetDynStdParagraaf400 | GetDynStdParagraaf404 | GetDynStdParagraaf500>>(
 params: GetDynStdParagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdParagraafInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdParagraafQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdParagraaf>>, TError = ErrorType<GetDynStdParagraaf400 | GetDynStdParagraaf404 | GetDynStdParagraaf500>>(params: GetDynStdParagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdParagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdParagraaf>>> = ({ signal }) => getDynStdParagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdParagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdParagraafQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdParagraaf>>>
export type GetDynStdParagraafQueryError = ErrorType<GetDynStdParagraaf400 | GetDynStdParagraaf404 | GetDynStdParagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdParagraaf = <TData = Awaited<ReturnType<typeof getDynStdParagraaf>>, TError = ErrorType<GetDynStdParagraaf400 | GetDynStdParagraaf404 | GetDynStdParagraaf500>>(
 params: GetDynStdParagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdParagraafQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynStdParagraaf = (
    sTDParagraafSchema: STDParagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDParagraafSchema>(
      {url: `/dyn/std/paragraaf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDParagraafSchema
    },
      options);
    }
  


export const getPostDynStdParagraafMutationOptions = <TError = ErrorType<PostDynStdParagraaf400 | PostDynStdParagraaf401 | PostDynStdParagraaf403 | PostDynStdParagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdParagraaf>>, TError,{data: STDParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynStdParagraaf>>, TError,{data: STDParagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynStdParagraaf>>, {data: STDParagraafSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynStdParagraaf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynStdParagraafMutationResult = NonNullable<Awaited<ReturnType<typeof postDynStdParagraaf>>>
    export type PostDynStdParagraafMutationBody = STDParagraafSchema
    export type PostDynStdParagraafMutationError = ErrorType<PostDynStdParagraaf400 | PostDynStdParagraaf401 | PostDynStdParagraaf403 | PostDynStdParagraaf500>

    /**
 * @summary Create a new instance
 */
export const usePostDynStdParagraaf = <TError = ErrorType<PostDynStdParagraaf400 | PostDynStdParagraaf401 | PostDynStdParagraaf403 | PostDynStdParagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdParagraaf>>, TError,{data: STDParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynStdParagraaf>>,
        TError,
        {data: STDParagraafSchema},
        TContext
      > => {

      const mutationOptions = getPostDynStdParagraafMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynStdParagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDParagraafSchema>(
      {url: `/dyn/std/paragraaf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynStdParagraafIdMutationOptions = <TError = ErrorType<DeleteDynStdParagraafId400 | DeleteDynStdParagraafId401 | DeleteDynStdParagraafId403 | DeleteDynStdParagraafId404 | DeleteDynStdParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdParagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdParagraafId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynStdParagraafId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynStdParagraafId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynStdParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynStdParagraafId>>>
    
    export type DeleteDynStdParagraafIdMutationError = ErrorType<DeleteDynStdParagraafId400 | DeleteDynStdParagraafId401 | DeleteDynStdParagraafId403 | DeleteDynStdParagraafId404 | DeleteDynStdParagraafId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynStdParagraafId = <TError = ErrorType<DeleteDynStdParagraafId400 | DeleteDynStdParagraafId401 | DeleteDynStdParagraafId403 | DeleteDynStdParagraafId404 | DeleteDynStdParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdParagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynStdParagraafId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynStdParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynStdParagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDParagraafSchema>(
      {url: `/dyn/std/paragraaf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynStdParagraafIdQueryKey = (id: string,) => {
    return [`/dyn/std/paragraaf/${id}`] as const;
    }

    
export const getGetDynStdParagraafIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdParagraafId>>>, TError = ErrorType<GetDynStdParagraafId404 | GetDynStdParagraafId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdParagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdParagraafId>>> = ({ signal }) => getDynStdParagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdParagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdParagraafIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdParagraafId>>>
export type GetDynStdParagraafIdInfiniteQueryError = ErrorType<GetDynStdParagraafId404 | GetDynStdParagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdParagraafIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdParagraafId>>>, TError = ErrorType<GetDynStdParagraafId404 | GetDynStdParagraafId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdParagraafIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdParagraafIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdParagraafId>>, TError = ErrorType<GetDynStdParagraafId404 | GetDynStdParagraafId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdParagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdParagraafId>>> = ({ signal }) => getDynStdParagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdParagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdParagraafIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdParagraafId>>>
export type GetDynStdParagraafIdQueryError = ErrorType<GetDynStdParagraafId404 | GetDynStdParagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdParagraafId = <TData = Awaited<ReturnType<typeof getDynStdParagraafId>>, TError = ErrorType<GetDynStdParagraafId404 | GetDynStdParagraafId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdParagraafIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynStdParagraafId = (
    id: string,
    sTDParagraafSchemaOptional: STDParagraafSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDParagraafSchema>(
      {url: `/dyn/std/paragraaf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDParagraafSchemaOptional
    },
      options);
    }
  


export const getPatchDynStdParagraafIdMutationOptions = <TError = ErrorType<PatchDynStdParagraafId400 | PatchDynStdParagraafId401 | PatchDynStdParagraafId403 | PatchDynStdParagraafId404 | PatchDynStdParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdParagraafId>>, TError,{id: string;data: STDParagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynStdParagraafId>>, TError,{id: string;data: STDParagraafSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynStdParagraafId>>, {id: string;data: STDParagraafSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynStdParagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynStdParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynStdParagraafId>>>
    export type PatchDynStdParagraafIdMutationBody = STDParagraafSchemaOptional
    export type PatchDynStdParagraafIdMutationError = ErrorType<PatchDynStdParagraafId400 | PatchDynStdParagraafId401 | PatchDynStdParagraafId403 | PatchDynStdParagraafId404 | PatchDynStdParagraafId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynStdParagraafId = <TError = ErrorType<PatchDynStdParagraafId400 | PatchDynStdParagraafId401 | PatchDynStdParagraafId403 | PatchDynStdParagraafId404 | PatchDynStdParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdParagraafId>>, TError,{id: string;data: STDParagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynStdParagraafId>>,
        TError,
        {id: string;data: STDParagraafSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynStdParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynStdParagraafId = (
    id: string,
    sTDParagraafSchema: STDParagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDParagraafSchema>(
      {url: `/dyn/std/paragraaf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDParagraafSchema
    },
      options);
    }
  


export const getPutDynStdParagraafIdMutationOptions = <TError = ErrorType<PutDynStdParagraafId400 | PutDynStdParagraafId401 | PutDynStdParagraafId403 | PutDynStdParagraafId404 | PutDynStdParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdParagraafId>>, TError,{id: string;data: STDParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynStdParagraafId>>, TError,{id: string;data: STDParagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynStdParagraafId>>, {id: string;data: STDParagraafSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynStdParagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynStdParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynStdParagraafId>>>
    export type PutDynStdParagraafIdMutationBody = STDParagraafSchema
    export type PutDynStdParagraafIdMutationError = ErrorType<PutDynStdParagraafId400 | PutDynStdParagraafId401 | PutDynStdParagraafId403 | PutDynStdParagraafId404 | PutDynStdParagraafId500>

    /**
 * @summary Update an instance
 */
export const usePutDynStdParagraafId = <TError = ErrorType<PutDynStdParagraafId400 | PutDynStdParagraafId401 | PutDynStdParagraafId403 | PutDynStdParagraafId404 | PutDynStdParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdParagraafId>>, TError,{id: string;data: STDParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynStdParagraafId>>,
        TError,
        {id: string;data: STDParagraafSchema},
        TContext
      > => {

      const mutationOptions = getPutDynStdParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynStdSubparagraaf = (
    params: GetDynStdSubparagraafParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynStdSubparagraaf200>(
      {url: `/dyn/std/subparagraaf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynStdSubparagraafQueryKey = (params: GetDynStdSubparagraafParams,) => {
    return [`/dyn/std/subparagraaf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynStdSubparagraafInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdSubparagraaf>>>, TError = ErrorType<GetDynStdSubparagraaf400 | GetDynStdSubparagraaf404 | GetDynStdSubparagraaf500>>(params: GetDynStdSubparagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdSubparagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdSubparagraaf>>> = ({ signal }) => getDynStdSubparagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdSubparagraafInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdSubparagraaf>>>
export type GetDynStdSubparagraafInfiniteQueryError = ErrorType<GetDynStdSubparagraaf400 | GetDynStdSubparagraaf404 | GetDynStdSubparagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdSubparagraafInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdSubparagraaf>>>, TError = ErrorType<GetDynStdSubparagraaf400 | GetDynStdSubparagraaf404 | GetDynStdSubparagraaf500>>(
 params: GetDynStdSubparagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdSubparagraafInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdSubparagraafQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError = ErrorType<GetDynStdSubparagraaf400 | GetDynStdSubparagraaf404 | GetDynStdSubparagraaf500>>(params: GetDynStdSubparagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdSubparagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdSubparagraaf>>> = ({ signal }) => getDynStdSubparagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdSubparagraafQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdSubparagraaf>>>
export type GetDynStdSubparagraafQueryError = ErrorType<GetDynStdSubparagraaf400 | GetDynStdSubparagraaf404 | GetDynStdSubparagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdSubparagraaf = <TData = Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError = ErrorType<GetDynStdSubparagraaf400 | GetDynStdSubparagraaf404 | GetDynStdSubparagraaf500>>(
 params: GetDynStdSubparagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdSubparagraafQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynStdSubparagraaf = (
    sTDSubparagraafSchema: STDSubparagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDSubparagraafSchema>(
      {url: `/dyn/std/subparagraaf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDSubparagraafSchema
    },
      options);
    }
  


export const getPostDynStdSubparagraafMutationOptions = <TError = ErrorType<PostDynStdSubparagraaf400 | PostDynStdSubparagraaf401 | PostDynStdSubparagraaf403 | PostDynStdSubparagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdSubparagraaf>>, TError,{data: STDSubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynStdSubparagraaf>>, TError,{data: STDSubparagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynStdSubparagraaf>>, {data: STDSubparagraafSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynStdSubparagraaf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynStdSubparagraafMutationResult = NonNullable<Awaited<ReturnType<typeof postDynStdSubparagraaf>>>
    export type PostDynStdSubparagraafMutationBody = STDSubparagraafSchema
    export type PostDynStdSubparagraafMutationError = ErrorType<PostDynStdSubparagraaf400 | PostDynStdSubparagraaf401 | PostDynStdSubparagraaf403 | PostDynStdSubparagraaf500>

    /**
 * @summary Create a new instance
 */
export const usePostDynStdSubparagraaf = <TError = ErrorType<PostDynStdSubparagraaf400 | PostDynStdSubparagraaf401 | PostDynStdSubparagraaf403 | PostDynStdSubparagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdSubparagraaf>>, TError,{data: STDSubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynStdSubparagraaf>>,
        TError,
        {data: STDSubparagraafSchema},
        TContext
      > => {

      const mutationOptions = getPostDynStdSubparagraafMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynStdSubparagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDSubparagraafSchema>(
      {url: `/dyn/std/subparagraaf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynStdSubparagraafIdMutationOptions = <TError = ErrorType<DeleteDynStdSubparagraafId400 | DeleteDynStdSubparagraafId401 | DeleteDynStdSubparagraafId403 | DeleteDynStdSubparagraafId404 | DeleteDynStdSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdSubparagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdSubparagraafId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynStdSubparagraafId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynStdSubparagraafId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynStdSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynStdSubparagraafId>>>
    
    export type DeleteDynStdSubparagraafIdMutationError = ErrorType<DeleteDynStdSubparagraafId400 | DeleteDynStdSubparagraafId401 | DeleteDynStdSubparagraafId403 | DeleteDynStdSubparagraafId404 | DeleteDynStdSubparagraafId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynStdSubparagraafId = <TError = ErrorType<DeleteDynStdSubparagraafId400 | DeleteDynStdSubparagraafId401 | DeleteDynStdSubparagraafId403 | DeleteDynStdSubparagraafId404 | DeleteDynStdSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdSubparagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynStdSubparagraafId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynStdSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynStdSubparagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDSubparagraafSchema>(
      {url: `/dyn/std/subparagraaf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynStdSubparagraafIdQueryKey = (id: string,) => {
    return [`/dyn/std/subparagraaf/${id}`] as const;
    }

    
export const getGetDynStdSubparagraafIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdSubparagraafId>>>, TError = ErrorType<GetDynStdSubparagraafId404 | GetDynStdSubparagraafId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdSubparagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdSubparagraafId>>> = ({ signal }) => getDynStdSubparagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdSubparagraafIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdSubparagraafId>>>
export type GetDynStdSubparagraafIdInfiniteQueryError = ErrorType<GetDynStdSubparagraafId404 | GetDynStdSubparagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdSubparagraafIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdSubparagraafId>>>, TError = ErrorType<GetDynStdSubparagraafId404 | GetDynStdSubparagraafId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdSubparagraafIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdSubparagraafIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError = ErrorType<GetDynStdSubparagraafId404 | GetDynStdSubparagraafId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdSubparagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdSubparagraafId>>> = ({ signal }) => getDynStdSubparagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdSubparagraafIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdSubparagraafId>>>
export type GetDynStdSubparagraafIdQueryError = ErrorType<GetDynStdSubparagraafId404 | GetDynStdSubparagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdSubparagraafId = <TData = Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError = ErrorType<GetDynStdSubparagraafId404 | GetDynStdSubparagraafId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdSubparagraafIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynStdSubparagraafId = (
    id: string,
    sTDSubparagraafSchemaOptional: STDSubparagraafSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDSubparagraafSchema>(
      {url: `/dyn/std/subparagraaf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDSubparagraafSchemaOptional
    },
      options);
    }
  


export const getPatchDynStdSubparagraafIdMutationOptions = <TError = ErrorType<PatchDynStdSubparagraafId400 | PatchDynStdSubparagraafId401 | PatchDynStdSubparagraafId403 | PatchDynStdSubparagraafId404 | PatchDynStdSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdSubparagraafId>>, TError,{id: string;data: STDSubparagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynStdSubparagraafId>>, TError,{id: string;data: STDSubparagraafSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynStdSubparagraafId>>, {id: string;data: STDSubparagraafSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynStdSubparagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynStdSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynStdSubparagraafId>>>
    export type PatchDynStdSubparagraafIdMutationBody = STDSubparagraafSchemaOptional
    export type PatchDynStdSubparagraafIdMutationError = ErrorType<PatchDynStdSubparagraafId400 | PatchDynStdSubparagraafId401 | PatchDynStdSubparagraafId403 | PatchDynStdSubparagraafId404 | PatchDynStdSubparagraafId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynStdSubparagraafId = <TError = ErrorType<PatchDynStdSubparagraafId400 | PatchDynStdSubparagraafId401 | PatchDynStdSubparagraafId403 | PatchDynStdSubparagraafId404 | PatchDynStdSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdSubparagraafId>>, TError,{id: string;data: STDSubparagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynStdSubparagraafId>>,
        TError,
        {id: string;data: STDSubparagraafSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynStdSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynStdSubparagraafId = (
    id: string,
    sTDSubparagraafSchema: STDSubparagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDSubparagraafSchema>(
      {url: `/dyn/std/subparagraaf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDSubparagraafSchema
    },
      options);
    }
  


export const getPutDynStdSubparagraafIdMutationOptions = <TError = ErrorType<PutDynStdSubparagraafId400 | PutDynStdSubparagraafId401 | PutDynStdSubparagraafId403 | PutDynStdSubparagraafId404 | PutDynStdSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdSubparagraafId>>, TError,{id: string;data: STDSubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynStdSubparagraafId>>, TError,{id: string;data: STDSubparagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynStdSubparagraafId>>, {id: string;data: STDSubparagraafSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynStdSubparagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynStdSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynStdSubparagraafId>>>
    export type PutDynStdSubparagraafIdMutationBody = STDSubparagraafSchema
    export type PutDynStdSubparagraafIdMutationError = ErrorType<PutDynStdSubparagraafId400 | PutDynStdSubparagraafId401 | PutDynStdSubparagraafId403 | PutDynStdSubparagraafId404 | PutDynStdSubparagraafId500>

    /**
 * @summary Update an instance
 */
export const usePutDynStdSubparagraafId = <TError = ErrorType<PutDynStdSubparagraafId400 | PutDynStdSubparagraafId401 | PutDynStdSubparagraafId403 | PutDynStdSubparagraafId404 | PutDynStdSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdSubparagraafId>>, TError,{id: string;data: STDSubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynStdSubparagraafId>>,
        TError,
        {id: string;data: STDSubparagraafSchema},
        TContext
      > => {

      const mutationOptions = getPutDynStdSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getDynStdVoorschrift = (
    params: GetDynStdVoorschriftParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetDynStdVoorschrift200>(
      {url: `/dyn/std/voorschrift`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDynStdVoorschriftQueryKey = (params: GetDynStdVoorschriftParams,) => {
    return [`/dyn/std/voorschrift`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDynStdVoorschriftInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdVoorschrift>>>, TError = ErrorType<GetDynStdVoorschrift400 | GetDynStdVoorschrift404 | GetDynStdVoorschrift500>>(params: GetDynStdVoorschriftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdVoorschriftQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdVoorschrift>>> = ({ signal }) => getDynStdVoorschrift(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdVoorschriftInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdVoorschrift>>>
export type GetDynStdVoorschriftInfiniteQueryError = ErrorType<GetDynStdVoorschrift400 | GetDynStdVoorschrift404 | GetDynStdVoorschrift500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdVoorschriftInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdVoorschrift>>>, TError = ErrorType<GetDynStdVoorschrift400 | GetDynStdVoorschrift404 | GetDynStdVoorschrift500>>(
 params: GetDynStdVoorschriftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdVoorschriftInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdVoorschriftQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError = ErrorType<GetDynStdVoorschrift400 | GetDynStdVoorschrift404 | GetDynStdVoorschrift500>>(params: GetDynStdVoorschriftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdVoorschriftQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdVoorschrift>>> = ({ signal }) => getDynStdVoorschrift(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdVoorschriftQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdVoorschrift>>>
export type GetDynStdVoorschriftQueryError = ErrorType<GetDynStdVoorschrift400 | GetDynStdVoorschrift404 | GetDynStdVoorschrift500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetDynStdVoorschrift = <TData = Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError = ErrorType<GetDynStdVoorschrift400 | GetDynStdVoorschrift404 | GetDynStdVoorschrift500>>(
 params: GetDynStdVoorschriftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdVoorschriftQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postDynStdVoorschrift = (
    sTDVoorschriftSchema: STDVoorschriftSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftSchema>(
      {url: `/dyn/std/voorschrift`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftSchema
    },
      options);
    }
  


export const getPostDynStdVoorschriftMutationOptions = <TError = ErrorType<PostDynStdVoorschrift400 | PostDynStdVoorschrift401 | PostDynStdVoorschrift403 | PostDynStdVoorschrift500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdVoorschrift>>, TError,{data: STDVoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postDynStdVoorschrift>>, TError,{data: STDVoorschriftSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDynStdVoorschrift>>, {data: STDVoorschriftSchema}> = (props) => {
          const {data} = props ?? {};

          return  postDynStdVoorschrift(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDynStdVoorschriftMutationResult = NonNullable<Awaited<ReturnType<typeof postDynStdVoorschrift>>>
    export type PostDynStdVoorschriftMutationBody = STDVoorschriftSchema
    export type PostDynStdVoorschriftMutationError = ErrorType<PostDynStdVoorschrift400 | PostDynStdVoorschrift401 | PostDynStdVoorschrift403 | PostDynStdVoorschrift500>

    /**
 * @summary Create a new instance
 */
export const usePostDynStdVoorschrift = <TError = ErrorType<PostDynStdVoorschrift400 | PostDynStdVoorschrift401 | PostDynStdVoorschrift403 | PostDynStdVoorschrift500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDynStdVoorschrift>>, TError,{data: STDVoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postDynStdVoorschrift>>,
        TError,
        {data: STDVoorschriftSchema},
        TContext
      > => {

      const mutationOptions = getPostDynStdVoorschriftMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteDynStdVoorschriftId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftSchema>(
      {url: `/dyn/std/voorschrift/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDynStdVoorschriftIdMutationOptions = <TError = ErrorType<DeleteDynStdVoorschriftId400 | DeleteDynStdVoorschriftId401 | DeleteDynStdVoorschriftId403 | DeleteDynStdVoorschriftId404 | DeleteDynStdVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdVoorschriftId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdVoorschriftId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDynStdVoorschriftId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDynStdVoorschriftId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDynStdVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDynStdVoorschriftId>>>
    
    export type DeleteDynStdVoorschriftIdMutationError = ErrorType<DeleteDynStdVoorschriftId400 | DeleteDynStdVoorschriftId401 | DeleteDynStdVoorschriftId403 | DeleteDynStdVoorschriftId404 | DeleteDynStdVoorschriftId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteDynStdVoorschriftId = <TError = ErrorType<DeleteDynStdVoorschriftId400 | DeleteDynStdVoorschriftId401 | DeleteDynStdVoorschriftId403 | DeleteDynStdVoorschriftId404 | DeleteDynStdVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDynStdVoorschriftId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDynStdVoorschriftId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDynStdVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getDynStdVoorschriftId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<STDVoorschriftSchema>(
      {url: `/dyn/std/voorschrift/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDynStdVoorschriftIdQueryKey = (id: string,) => {
    return [`/dyn/std/voorschrift/${id}`] as const;
    }

    
export const getGetDynStdVoorschriftIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdVoorschriftId>>>, TError = ErrorType<GetDynStdVoorschriftId404 | GetDynStdVoorschriftId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdVoorschriftIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdVoorschriftId>>> = ({ signal }) => getDynStdVoorschriftId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdVoorschriftIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdVoorschriftId>>>
export type GetDynStdVoorschriftIdInfiniteQueryError = ErrorType<GetDynStdVoorschriftId404 | GetDynStdVoorschriftId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdVoorschriftIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getDynStdVoorschriftId>>>, TError = ErrorType<GetDynStdVoorschriftId404 | GetDynStdVoorschriftId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdVoorschriftIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDynStdVoorschriftIdQueryOptions = <TData = Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError = ErrorType<GetDynStdVoorschriftId404 | GetDynStdVoorschriftId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDynStdVoorschriftIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDynStdVoorschriftId>>> = ({ signal }) => getDynStdVoorschriftId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDynStdVoorschriftIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDynStdVoorschriftId>>>
export type GetDynStdVoorschriftIdQueryError = ErrorType<GetDynStdVoorschriftId404 | GetDynStdVoorschriftId500>

/**
 * @summary Gets an instance.
 */
export const useGetDynStdVoorschriftId = <TData = Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError = ErrorType<GetDynStdVoorschriftId404 | GetDynStdVoorschriftId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDynStdVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDynStdVoorschriftIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchDynStdVoorschriftId = (
    id: string,
    sTDVoorschriftSchemaOptional: STDVoorschriftSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftSchema>(
      {url: `/dyn/std/voorschrift/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftSchemaOptional
    },
      options);
    }
  


export const getPatchDynStdVoorschriftIdMutationOptions = <TError = ErrorType<PatchDynStdVoorschriftId400 | PatchDynStdVoorschriftId401 | PatchDynStdVoorschriftId403 | PatchDynStdVoorschriftId404 | PatchDynStdVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdVoorschriftId>>, TError,{id: string;data: STDVoorschriftSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchDynStdVoorschriftId>>, TError,{id: string;data: STDVoorschriftSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchDynStdVoorschriftId>>, {id: string;data: STDVoorschriftSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchDynStdVoorschriftId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchDynStdVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchDynStdVoorschriftId>>>
    export type PatchDynStdVoorschriftIdMutationBody = STDVoorschriftSchemaOptional
    export type PatchDynStdVoorschriftIdMutationError = ErrorType<PatchDynStdVoorschriftId400 | PatchDynStdVoorschriftId401 | PatchDynStdVoorschriftId403 | PatchDynStdVoorschriftId404 | PatchDynStdVoorschriftId500>

    /**
 * @summary Update an instance
 */
export const usePatchDynStdVoorschriftId = <TError = ErrorType<PatchDynStdVoorschriftId400 | PatchDynStdVoorschriftId401 | PatchDynStdVoorschriftId403 | PatchDynStdVoorschriftId404 | PatchDynStdVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchDynStdVoorschriftId>>, TError,{id: string;data: STDVoorschriftSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchDynStdVoorschriftId>>,
        TError,
        {id: string;data: STDVoorschriftSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchDynStdVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putDynStdVoorschriftId = (
    id: string,
    sTDVoorschriftSchema: STDVoorschriftSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<STDVoorschriftSchema>(
      {url: `/dyn/std/voorschrift/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sTDVoorschriftSchema
    },
      options);
    }
  


export const getPutDynStdVoorschriftIdMutationOptions = <TError = ErrorType<PutDynStdVoorschriftId400 | PutDynStdVoorschriftId401 | PutDynStdVoorschriftId403 | PutDynStdVoorschriftId404 | PutDynStdVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdVoorschriftId>>, TError,{id: string;data: STDVoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putDynStdVoorschriftId>>, TError,{id: string;data: STDVoorschriftSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putDynStdVoorschriftId>>, {id: string;data: STDVoorschriftSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putDynStdVoorschriftId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutDynStdVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDynStdVoorschriftId>>>
    export type PutDynStdVoorschriftIdMutationBody = STDVoorschriftSchema
    export type PutDynStdVoorschriftIdMutationError = ErrorType<PutDynStdVoorschriftId400 | PutDynStdVoorschriftId401 | PutDynStdVoorschriftId403 | PutDynStdVoorschriftId404 | PutDynStdVoorschriftId500>

    /**
 * @summary Update an instance
 */
export const usePutDynStdVoorschriftId = <TError = ErrorType<PutDynStdVoorschriftId400 | PutDynStdVoorschriftId401 | PutDynStdVoorschriftId403 | PutDynStdVoorschriftId404 | PutDynStdVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putDynStdVoorschriftId>>, TError,{id: string;data: STDVoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putDynStdVoorschriftId>>,
        TError,
        {id: string;data: STDVoorschriftSchema},
        TContext
      > => {

      const mutationOptions = getPutDynStdVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixBedrijf = (
    params: GetFixBedrijfParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixBedrijf200>(
      {url: `/fix/bedrijf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixBedrijfQueryKey = (params: GetFixBedrijfParams,) => {
    return [`/fix/bedrijf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixBedrijfInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBedrijf>>>, TError = ErrorType<GetFixBedrijf400 | GetFixBedrijf404 | GetFixBedrijf500>>(params: GetFixBedrijfParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBedrijfQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBedrijf>>> = ({ signal }) => getFixBedrijf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBedrijf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBedrijfInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBedrijf>>>
export type GetFixBedrijfInfiniteQueryError = ErrorType<GetFixBedrijf400 | GetFixBedrijf404 | GetFixBedrijf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixBedrijfInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBedrijf>>>, TError = ErrorType<GetFixBedrijf400 | GetFixBedrijf404 | GetFixBedrijf500>>(
 params: GetFixBedrijfParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBedrijfInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixBedrijfQueryOptions = <TData = Awaited<ReturnType<typeof getFixBedrijf>>, TError = ErrorType<GetFixBedrijf400 | GetFixBedrijf404 | GetFixBedrijf500>>(params: GetFixBedrijfParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBedrijfQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBedrijf>>> = ({ signal }) => getFixBedrijf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixBedrijf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBedrijfQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBedrijf>>>
export type GetFixBedrijfQueryError = ErrorType<GetFixBedrijf400 | GetFixBedrijf404 | GetFixBedrijf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixBedrijf = <TData = Awaited<ReturnType<typeof getFixBedrijf>>, TError = ErrorType<GetFixBedrijf400 | GetFixBedrijf404 | GetFixBedrijf500>>(
 params: GetFixBedrijfParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBedrijfQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixBedrijf = (
    bedrijfSchema: BedrijfSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfSchema>(
      {url: `/fix/bedrijf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfSchema
    },
      options);
    }
  


export const getPostFixBedrijfMutationOptions = <TError = ErrorType<PostFixBedrijf400 | PostFixBedrijf401 | PostFixBedrijf403 | PostFixBedrijf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixBedrijf>>, TError,{data: BedrijfSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixBedrijf>>, TError,{data: BedrijfSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixBedrijf>>, {data: BedrijfSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixBedrijf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixBedrijfMutationResult = NonNullable<Awaited<ReturnType<typeof postFixBedrijf>>>
    export type PostFixBedrijfMutationBody = BedrijfSchema
    export type PostFixBedrijfMutationError = ErrorType<PostFixBedrijf400 | PostFixBedrijf401 | PostFixBedrijf403 | PostFixBedrijf500>

    /**
 * @summary Create a new instance
 */
export const usePostFixBedrijf = <TError = ErrorType<PostFixBedrijf400 | PostFixBedrijf401 | PostFixBedrijf403 | PostFixBedrijf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixBedrijf>>, TError,{data: BedrijfSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixBedrijf>>,
        TError,
        {data: BedrijfSchema},
        TContext
      > => {

      const mutationOptions = getPostFixBedrijfMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixBedrijfId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfSchema>(
      {url: `/fix/bedrijf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixBedrijfIdMutationOptions = <TError = ErrorType<DeleteFixBedrijfId400 | DeleteFixBedrijfId401 | DeleteFixBedrijfId403 | DeleteFixBedrijfId404 | DeleteFixBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixBedrijfId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixBedrijfId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixBedrijfId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixBedrijfId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixBedrijfIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixBedrijfId>>>
    
    export type DeleteFixBedrijfIdMutationError = ErrorType<DeleteFixBedrijfId400 | DeleteFixBedrijfId401 | DeleteFixBedrijfId403 | DeleteFixBedrijfId404 | DeleteFixBedrijfId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixBedrijfId = <TError = ErrorType<DeleteFixBedrijfId400 | DeleteFixBedrijfId401 | DeleteFixBedrijfId403 | DeleteFixBedrijfId404 | DeleteFixBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixBedrijfId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixBedrijfId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixBedrijfIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixBedrijfId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<BedrijfSchema>(
      {url: `/fix/bedrijf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixBedrijfIdQueryKey = (id: string,) => {
    return [`/fix/bedrijf/${id}`] as const;
    }

    
export const getGetFixBedrijfIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBedrijfId>>>, TError = ErrorType<GetFixBedrijfId404 | GetFixBedrijfId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBedrijfIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBedrijfId>>> = ({ signal }) => getFixBedrijfId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBedrijfId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBedrijfIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBedrijfId>>>
export type GetFixBedrijfIdInfiniteQueryError = ErrorType<GetFixBedrijfId404 | GetFixBedrijfId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixBedrijfIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBedrijfId>>>, TError = ErrorType<GetFixBedrijfId404 | GetFixBedrijfId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBedrijfIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixBedrijfIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixBedrijfId>>, TError = ErrorType<GetFixBedrijfId404 | GetFixBedrijfId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBedrijfIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBedrijfId>>> = ({ signal }) => getFixBedrijfId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixBedrijfId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBedrijfIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBedrijfId>>>
export type GetFixBedrijfIdQueryError = ErrorType<GetFixBedrijfId404 | GetFixBedrijfId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixBedrijfId = <TData = Awaited<ReturnType<typeof getFixBedrijfId>>, TError = ErrorType<GetFixBedrijfId404 | GetFixBedrijfId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBedrijfIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixBedrijfId = (
    id: string,
    bedrijfSchemaOptional: BedrijfSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfSchema>(
      {url: `/fix/bedrijf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfSchemaOptional
    },
      options);
    }
  


export const getPatchFixBedrijfIdMutationOptions = <TError = ErrorType<PatchFixBedrijfId400 | PatchFixBedrijfId401 | PatchFixBedrijfId403 | PatchFixBedrijfId404 | PatchFixBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixBedrijfId>>, TError,{id: string;data: BedrijfSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixBedrijfId>>, TError,{id: string;data: BedrijfSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixBedrijfId>>, {id: string;data: BedrijfSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixBedrijfId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixBedrijfIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixBedrijfId>>>
    export type PatchFixBedrijfIdMutationBody = BedrijfSchemaOptional
    export type PatchFixBedrijfIdMutationError = ErrorType<PatchFixBedrijfId400 | PatchFixBedrijfId401 | PatchFixBedrijfId403 | PatchFixBedrijfId404 | PatchFixBedrijfId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixBedrijfId = <TError = ErrorType<PatchFixBedrijfId400 | PatchFixBedrijfId401 | PatchFixBedrijfId403 | PatchFixBedrijfId404 | PatchFixBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixBedrijfId>>, TError,{id: string;data: BedrijfSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixBedrijfId>>,
        TError,
        {id: string;data: BedrijfSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixBedrijfIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixBedrijfId = (
    id: string,
    bedrijfSchema: BedrijfSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfSchema>(
      {url: `/fix/bedrijf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfSchema
    },
      options);
    }
  


export const getPutFixBedrijfIdMutationOptions = <TError = ErrorType<PutFixBedrijfId400 | PutFixBedrijfId401 | PutFixBedrijfId403 | PutFixBedrijfId404 | PutFixBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixBedrijfId>>, TError,{id: string;data: BedrijfSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixBedrijfId>>, TError,{id: string;data: BedrijfSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixBedrijfId>>, {id: string;data: BedrijfSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixBedrijfId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixBedrijfIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixBedrijfId>>>
    export type PutFixBedrijfIdMutationBody = BedrijfSchema
    export type PutFixBedrijfIdMutationError = ErrorType<PutFixBedrijfId400 | PutFixBedrijfId401 | PutFixBedrijfId403 | PutFixBedrijfId404 | PutFixBedrijfId500>

    /**
 * @summary Update an instance
 */
export const usePutFixBedrijfId = <TError = ErrorType<PutFixBedrijfId400 | PutFixBedrijfId401 | PutFixBedrijfId403 | PutFixBedrijfId404 | PutFixBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixBedrijfId>>, TError,{id: string;data: BedrijfSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixBedrijfId>>,
        TError,
        {id: string;data: BedrijfSchema},
        TContext
      > => {

      const mutationOptions = getPutFixBedrijfIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixBesluit = (
    params: GetFixBesluitParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixBesluit200>(
      {url: `/fix/besluit`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixBesluitQueryKey = (params: GetFixBesluitParams,) => {
    return [`/fix/besluit`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixBesluitInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBesluit>>>, TError = ErrorType<GetFixBesluit400 | GetFixBesluit404 | GetFixBesluit500>>(params: GetFixBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBesluit>>> = ({ signal }) => getFixBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBesluitInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBesluit>>>
export type GetFixBesluitInfiniteQueryError = ErrorType<GetFixBesluit400 | GetFixBesluit404 | GetFixBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixBesluitInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBesluit>>>, TError = ErrorType<GetFixBesluit400 | GetFixBesluit404 | GetFixBesluit500>>(
 params: GetFixBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBesluitInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixBesluitQueryOptions = <TData = Awaited<ReturnType<typeof getFixBesluit>>, TError = ErrorType<GetFixBesluit400 | GetFixBesluit404 | GetFixBesluit500>>(params: GetFixBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBesluit>>> = ({ signal }) => getFixBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBesluitQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBesluit>>>
export type GetFixBesluitQueryError = ErrorType<GetFixBesluit400 | GetFixBesluit404 | GetFixBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixBesluit = <TData = Awaited<ReturnType<typeof getFixBesluit>>, TError = ErrorType<GetFixBesluit400 | GetFixBesluit404 | GetFixBesluit500>>(
 params: GetFixBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBesluitQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixBesluit = (
    besluitSchema: BesluitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitSchema>(
      {url: `/fix/besluit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: besluitSchema
    },
      options);
    }
  


export const getPostFixBesluitMutationOptions = <TError = ErrorType<PostFixBesluit400 | PostFixBesluit401 | PostFixBesluit403 | PostFixBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixBesluit>>, TError,{data: BesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixBesluit>>, TError,{data: BesluitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixBesluit>>, {data: BesluitSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixBesluit(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixBesluitMutationResult = NonNullable<Awaited<ReturnType<typeof postFixBesluit>>>
    export type PostFixBesluitMutationBody = BesluitSchema
    export type PostFixBesluitMutationError = ErrorType<PostFixBesluit400 | PostFixBesluit401 | PostFixBesluit403 | PostFixBesluit500>

    /**
 * @summary Create a new instance
 */
export const usePostFixBesluit = <TError = ErrorType<PostFixBesluit400 | PostFixBesluit401 | PostFixBesluit403 | PostFixBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixBesluit>>, TError,{data: BesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixBesluit>>,
        TError,
        {data: BesluitSchema},
        TContext
      > => {

      const mutationOptions = getPostFixBesluitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitSchema>(
      {url: `/fix/besluit/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixBesluitIdMutationOptions = <TError = ErrorType<DeleteFixBesluitId400 | DeleteFixBesluitId401 | DeleteFixBesluitId403 | DeleteFixBesluitId404 | DeleteFixBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixBesluitId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixBesluitId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixBesluitId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixBesluitId>>>
    
    export type DeleteFixBesluitIdMutationError = ErrorType<DeleteFixBesluitId400 | DeleteFixBesluitId401 | DeleteFixBesluitId403 | DeleteFixBesluitId404 | DeleteFixBesluitId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixBesluitId = <TError = ErrorType<DeleteFixBesluitId400 | DeleteFixBesluitId401 | DeleteFixBesluitId403 | DeleteFixBesluitId404 | DeleteFixBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixBesluitId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<BesluitSchema>(
      {url: `/fix/besluit/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixBesluitIdQueryKey = (id: string,) => {
    return [`/fix/besluit/${id}`] as const;
    }

    
export const getGetFixBesluitIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBesluitId>>>, TError = ErrorType<GetFixBesluitId404 | GetFixBesluitId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBesluitId>>> = ({ signal }) => getFixBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBesluitIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBesluitId>>>
export type GetFixBesluitIdInfiniteQueryError = ErrorType<GetFixBesluitId404 | GetFixBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixBesluitIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixBesluitId>>>, TError = ErrorType<GetFixBesluitId404 | GetFixBesluitId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBesluitIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixBesluitIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixBesluitId>>, TError = ErrorType<GetFixBesluitId404 | GetFixBesluitId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixBesluitId>>> = ({ signal }) => getFixBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixBesluitIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixBesluitId>>>
export type GetFixBesluitIdQueryError = ErrorType<GetFixBesluitId404 | GetFixBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixBesluitId = <TData = Awaited<ReturnType<typeof getFixBesluitId>>, TError = ErrorType<GetFixBesluitId404 | GetFixBesluitId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixBesluitIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixBesluitId = (
    id: string,
    besluitSchemaOptional: BesluitSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitSchema>(
      {url: `/fix/besluit/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: besluitSchemaOptional
    },
      options);
    }
  


export const getPatchFixBesluitIdMutationOptions = <TError = ErrorType<PatchFixBesluitId400 | PatchFixBesluitId401 | PatchFixBesluitId403 | PatchFixBesluitId404 | PatchFixBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixBesluitId>>, TError,{id: string;data: BesluitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixBesluitId>>, TError,{id: string;data: BesluitSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixBesluitId>>, {id: string;data: BesluitSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixBesluitId>>>
    export type PatchFixBesluitIdMutationBody = BesluitSchemaOptional
    export type PatchFixBesluitIdMutationError = ErrorType<PatchFixBesluitId400 | PatchFixBesluitId401 | PatchFixBesluitId403 | PatchFixBesluitId404 | PatchFixBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixBesluitId = <TError = ErrorType<PatchFixBesluitId400 | PatchFixBesluitId401 | PatchFixBesluitId403 | PatchFixBesluitId404 | PatchFixBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixBesluitId>>, TError,{id: string;data: BesluitSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixBesluitId>>,
        TError,
        {id: string;data: BesluitSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixBesluitId = (
    id: string,
    besluitSchema: BesluitSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitSchema>(
      {url: `/fix/besluit/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: besluitSchema
    },
      options);
    }
  


export const getPutFixBesluitIdMutationOptions = <TError = ErrorType<PutFixBesluitId400 | PutFixBesluitId401 | PutFixBesluitId403 | PutFixBesluitId404 | PutFixBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixBesluitId>>, TError,{id: string;data: BesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixBesluitId>>, TError,{id: string;data: BesluitSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixBesluitId>>, {id: string;data: BesluitSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixBesluitId>>>
    export type PutFixBesluitIdMutationBody = BesluitSchema
    export type PutFixBesluitIdMutationError = ErrorType<PutFixBesluitId400 | PutFixBesluitId401 | PutFixBesluitId403 | PutFixBesluitId404 | PutFixBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePutFixBesluitId = <TError = ErrorType<PutFixBesluitId400 | PutFixBesluitId401 | PutFixBesluitId403 | PutFixBesluitId404 | PutFixBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixBesluitId>>, TError,{id: string;data: BesluitSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixBesluitId>>,
        TError,
        {id: string;data: BesluitSchema},
        TContext
      > => {

      const mutationOptions = getPutFixBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixHoofdstuk = (
    params: GetFixHoofdstukParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixHoofdstuk200>(
      {url: `/fix/hoofdstuk`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixHoofdstukQueryKey = (params: GetFixHoofdstukParams,) => {
    return [`/fix/hoofdstuk`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixHoofdstukInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixHoofdstuk>>>, TError = ErrorType<GetFixHoofdstuk400 | GetFixHoofdstuk404 | GetFixHoofdstuk500>>(params: GetFixHoofdstukParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixHoofdstukQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixHoofdstuk>>> = ({ signal }) => getFixHoofdstuk(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixHoofdstuk>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixHoofdstukInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixHoofdstuk>>>
export type GetFixHoofdstukInfiniteQueryError = ErrorType<GetFixHoofdstuk400 | GetFixHoofdstuk404 | GetFixHoofdstuk500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixHoofdstukInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixHoofdstuk>>>, TError = ErrorType<GetFixHoofdstuk400 | GetFixHoofdstuk404 | GetFixHoofdstuk500>>(
 params: GetFixHoofdstukParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixHoofdstukInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixHoofdstukQueryOptions = <TData = Awaited<ReturnType<typeof getFixHoofdstuk>>, TError = ErrorType<GetFixHoofdstuk400 | GetFixHoofdstuk404 | GetFixHoofdstuk500>>(params: GetFixHoofdstukParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixHoofdstukQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixHoofdstuk>>> = ({ signal }) => getFixHoofdstuk(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixHoofdstuk>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixHoofdstukQueryResult = NonNullable<Awaited<ReturnType<typeof getFixHoofdstuk>>>
export type GetFixHoofdstukQueryError = ErrorType<GetFixHoofdstuk400 | GetFixHoofdstuk404 | GetFixHoofdstuk500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixHoofdstuk = <TData = Awaited<ReturnType<typeof getFixHoofdstuk>>, TError = ErrorType<GetFixHoofdstuk400 | GetFixHoofdstuk404 | GetFixHoofdstuk500>>(
 params: GetFixHoofdstukParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixHoofdstukQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixHoofdstuk = (
    hoofdstukSchema: HoofdstukSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukSchema>(
      {url: `/fix/hoofdstuk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukSchema
    },
      options);
    }
  


export const getPostFixHoofdstukMutationOptions = <TError = ErrorType<PostFixHoofdstuk400 | PostFixHoofdstuk401 | PostFixHoofdstuk403 | PostFixHoofdstuk500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixHoofdstuk>>, TError,{data: HoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixHoofdstuk>>, TError,{data: HoofdstukSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixHoofdstuk>>, {data: HoofdstukSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixHoofdstuk(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixHoofdstukMutationResult = NonNullable<Awaited<ReturnType<typeof postFixHoofdstuk>>>
    export type PostFixHoofdstukMutationBody = HoofdstukSchema
    export type PostFixHoofdstukMutationError = ErrorType<PostFixHoofdstuk400 | PostFixHoofdstuk401 | PostFixHoofdstuk403 | PostFixHoofdstuk500>

    /**
 * @summary Create a new instance
 */
export const usePostFixHoofdstuk = <TError = ErrorType<PostFixHoofdstuk400 | PostFixHoofdstuk401 | PostFixHoofdstuk403 | PostFixHoofdstuk500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixHoofdstuk>>, TError,{data: HoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixHoofdstuk>>,
        TError,
        {data: HoofdstukSchema},
        TContext
      > => {

      const mutationOptions = getPostFixHoofdstukMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixHoofdstukId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukSchema>(
      {url: `/fix/hoofdstuk/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixHoofdstukIdMutationOptions = <TError = ErrorType<DeleteFixHoofdstukId400 | DeleteFixHoofdstukId401 | DeleteFixHoofdstukId403 | DeleteFixHoofdstukId404 | DeleteFixHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixHoofdstukId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixHoofdstukId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixHoofdstukId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixHoofdstukId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixHoofdstukId>>>
    
    export type DeleteFixHoofdstukIdMutationError = ErrorType<DeleteFixHoofdstukId400 | DeleteFixHoofdstukId401 | DeleteFixHoofdstukId403 | DeleteFixHoofdstukId404 | DeleteFixHoofdstukId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixHoofdstukId = <TError = ErrorType<DeleteFixHoofdstukId400 | DeleteFixHoofdstukId401 | DeleteFixHoofdstukId403 | DeleteFixHoofdstukId404 | DeleteFixHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixHoofdstukId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixHoofdstukId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixHoofdstukId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<HoofdstukSchema>(
      {url: `/fix/hoofdstuk/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixHoofdstukIdQueryKey = (id: string,) => {
    return [`/fix/hoofdstuk/${id}`] as const;
    }

    
export const getGetFixHoofdstukIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixHoofdstukId>>>, TError = ErrorType<GetFixHoofdstukId404 | GetFixHoofdstukId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixHoofdstukIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixHoofdstukId>>> = ({ signal }) => getFixHoofdstukId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixHoofdstukId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixHoofdstukIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixHoofdstukId>>>
export type GetFixHoofdstukIdInfiniteQueryError = ErrorType<GetFixHoofdstukId404 | GetFixHoofdstukId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixHoofdstukIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixHoofdstukId>>>, TError = ErrorType<GetFixHoofdstukId404 | GetFixHoofdstukId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixHoofdstukIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixHoofdstukIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixHoofdstukId>>, TError = ErrorType<GetFixHoofdstukId404 | GetFixHoofdstukId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixHoofdstukIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixHoofdstukId>>> = ({ signal }) => getFixHoofdstukId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixHoofdstukId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixHoofdstukIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixHoofdstukId>>>
export type GetFixHoofdstukIdQueryError = ErrorType<GetFixHoofdstukId404 | GetFixHoofdstukId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixHoofdstukId = <TData = Awaited<ReturnType<typeof getFixHoofdstukId>>, TError = ErrorType<GetFixHoofdstukId404 | GetFixHoofdstukId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixHoofdstukIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixHoofdstukId = (
    id: string,
    hoofdstukSchemaOptional: HoofdstukSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukSchema>(
      {url: `/fix/hoofdstuk/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukSchemaOptional
    },
      options);
    }
  


export const getPatchFixHoofdstukIdMutationOptions = <TError = ErrorType<PatchFixHoofdstukId400 | PatchFixHoofdstukId401 | PatchFixHoofdstukId403 | PatchFixHoofdstukId404 | PatchFixHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixHoofdstukId>>, TError,{id: string;data: HoofdstukSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixHoofdstukId>>, TError,{id: string;data: HoofdstukSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixHoofdstukId>>, {id: string;data: HoofdstukSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixHoofdstukId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixHoofdstukId>>>
    export type PatchFixHoofdstukIdMutationBody = HoofdstukSchemaOptional
    export type PatchFixHoofdstukIdMutationError = ErrorType<PatchFixHoofdstukId400 | PatchFixHoofdstukId401 | PatchFixHoofdstukId403 | PatchFixHoofdstukId404 | PatchFixHoofdstukId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixHoofdstukId = <TError = ErrorType<PatchFixHoofdstukId400 | PatchFixHoofdstukId401 | PatchFixHoofdstukId403 | PatchFixHoofdstukId404 | PatchFixHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixHoofdstukId>>, TError,{id: string;data: HoofdstukSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixHoofdstukId>>,
        TError,
        {id: string;data: HoofdstukSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixHoofdstukId = (
    id: string,
    hoofdstukSchema: HoofdstukSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukSchema>(
      {url: `/fix/hoofdstuk/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukSchema
    },
      options);
    }
  


export const getPutFixHoofdstukIdMutationOptions = <TError = ErrorType<PutFixHoofdstukId400 | PutFixHoofdstukId401 | PutFixHoofdstukId403 | PutFixHoofdstukId404 | PutFixHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixHoofdstukId>>, TError,{id: string;data: HoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixHoofdstukId>>, TError,{id: string;data: HoofdstukSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixHoofdstukId>>, {id: string;data: HoofdstukSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixHoofdstukId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixHoofdstukId>>>
    export type PutFixHoofdstukIdMutationBody = HoofdstukSchema
    export type PutFixHoofdstukIdMutationError = ErrorType<PutFixHoofdstukId400 | PutFixHoofdstukId401 | PutFixHoofdstukId403 | PutFixHoofdstukId404 | PutFixHoofdstukId500>

    /**
 * @summary Update an instance
 */
export const usePutFixHoofdstukId = <TError = ErrorType<PutFixHoofdstukId400 | PutFixHoofdstukId401 | PutFixHoofdstukId403 | PutFixHoofdstukId404 | PutFixHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixHoofdstukId>>, TError,{id: string;data: HoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixHoofdstukId>>,
        TError,
        {id: string;data: HoofdstukSchema},
        TContext
      > => {

      const mutationOptions = getPutFixHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLineageBedrijf = (
    params: GetFixLineageBedrijfParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLineageBedrijf200>(
      {url: `/fix/lineage/bedrijf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLineageBedrijfQueryKey = (params: GetFixLineageBedrijfParams,) => {
    return [`/fix/lineage/bedrijf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLineageBedrijfInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBedrijf>>>, TError = ErrorType<GetFixLineageBedrijf400 | GetFixLineageBedrijf404 | GetFixLineageBedrijf500>>(params: GetFixLineageBedrijfParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBedrijfQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBedrijf>>> = ({ signal }) => getFixLineageBedrijf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBedrijfInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBedrijf>>>
export type GetFixLineageBedrijfInfiniteQueryError = ErrorType<GetFixLineageBedrijf400 | GetFixLineageBedrijf404 | GetFixLineageBedrijf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageBedrijfInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBedrijf>>>, TError = ErrorType<GetFixLineageBedrijf400 | GetFixLineageBedrijf404 | GetFixLineageBedrijf500>>(
 params: GetFixLineageBedrijfParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBedrijfInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageBedrijfQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError = ErrorType<GetFixLineageBedrijf400 | GetFixLineageBedrijf404 | GetFixLineageBedrijf500>>(params: GetFixLineageBedrijfParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBedrijfQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBedrijf>>> = ({ signal }) => getFixLineageBedrijf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBedrijfQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBedrijf>>>
export type GetFixLineageBedrijfQueryError = ErrorType<GetFixLineageBedrijf400 | GetFixLineageBedrijf404 | GetFixLineageBedrijf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageBedrijf = <TData = Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError = ErrorType<GetFixLineageBedrijf400 | GetFixLineageBedrijf404 | GetFixLineageBedrijf500>>(
 params: GetFixLineageBedrijfParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBedrijfQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLineageBedrijf = (
    bedrijfLineageSchema: BedrijfLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfLineageSchema>(
      {url: `/fix/lineage/bedrijf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfLineageSchema
    },
      options);
    }
  


export const getPostFixLineageBedrijfMutationOptions = <TError = ErrorType<PostFixLineageBedrijf400 | PostFixLineageBedrijf401 | PostFixLineageBedrijf403 | PostFixLineageBedrijf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageBedrijf>>, TError,{data: BedrijfLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLineageBedrijf>>, TError,{data: BedrijfLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLineageBedrijf>>, {data: BedrijfLineageSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLineageBedrijf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLineageBedrijfMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLineageBedrijf>>>
    export type PostFixLineageBedrijfMutationBody = BedrijfLineageSchema
    export type PostFixLineageBedrijfMutationError = ErrorType<PostFixLineageBedrijf400 | PostFixLineageBedrijf401 | PostFixLineageBedrijf403 | PostFixLineageBedrijf500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLineageBedrijf = <TError = ErrorType<PostFixLineageBedrijf400 | PostFixLineageBedrijf401 | PostFixLineageBedrijf403 | PostFixLineageBedrijf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageBedrijf>>, TError,{data: BedrijfLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLineageBedrijf>>,
        TError,
        {data: BedrijfLineageSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLineageBedrijfMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLineageBedrijfId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfLineageSchema>(
      {url: `/fix/lineage/bedrijf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLineageBedrijfIdMutationOptions = <TError = ErrorType<DeleteFixLineageBedrijfId400 | DeleteFixLineageBedrijfId401 | DeleteFixLineageBedrijfId403 | DeleteFixLineageBedrijfId404 | DeleteFixLineageBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageBedrijfId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageBedrijfId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLineageBedrijfId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLineageBedrijfId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLineageBedrijfIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLineageBedrijfId>>>
    
    export type DeleteFixLineageBedrijfIdMutationError = ErrorType<DeleteFixLineageBedrijfId400 | DeleteFixLineageBedrijfId401 | DeleteFixLineageBedrijfId403 | DeleteFixLineageBedrijfId404 | DeleteFixLineageBedrijfId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLineageBedrijfId = <TError = ErrorType<DeleteFixLineageBedrijfId400 | DeleteFixLineageBedrijfId401 | DeleteFixLineageBedrijfId403 | DeleteFixLineageBedrijfId404 | DeleteFixLineageBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageBedrijfId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLineageBedrijfId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLineageBedrijfIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLineageBedrijfId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<BedrijfLineageSchema>(
      {url: `/fix/lineage/bedrijf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLineageBedrijfIdQueryKey = (id: string,) => {
    return [`/fix/lineage/bedrijf/${id}`] as const;
    }

    
export const getGetFixLineageBedrijfIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBedrijfId>>>, TError = ErrorType<GetFixLineageBedrijfId404 | GetFixLineageBedrijfId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBedrijfIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBedrijfId>>> = ({ signal }) => getFixLineageBedrijfId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBedrijfIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBedrijfId>>>
export type GetFixLineageBedrijfIdInfiniteQueryError = ErrorType<GetFixLineageBedrijfId404 | GetFixLineageBedrijfId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageBedrijfIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBedrijfId>>>, TError = ErrorType<GetFixLineageBedrijfId404 | GetFixLineageBedrijfId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBedrijfIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageBedrijfIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError = ErrorType<GetFixLineageBedrijfId404 | GetFixLineageBedrijfId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBedrijfIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBedrijfId>>> = ({ signal }) => getFixLineageBedrijfId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBedrijfIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBedrijfId>>>
export type GetFixLineageBedrijfIdQueryError = ErrorType<GetFixLineageBedrijfId404 | GetFixLineageBedrijfId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageBedrijfId = <TData = Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError = ErrorType<GetFixLineageBedrijfId404 | GetFixLineageBedrijfId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBedrijfId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBedrijfIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLineageBedrijfId = (
    id: string,
    bedrijfLineageSchemaOptional: BedrijfLineageSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfLineageSchema>(
      {url: `/fix/lineage/bedrijf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfLineageSchemaOptional
    },
      options);
    }
  


export const getPatchFixLineageBedrijfIdMutationOptions = <TError = ErrorType<PatchFixLineageBedrijfId400 | PatchFixLineageBedrijfId401 | PatchFixLineageBedrijfId403 | PatchFixLineageBedrijfId404 | PatchFixLineageBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageBedrijfId>>, TError,{id: string;data: BedrijfLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageBedrijfId>>, TError,{id: string;data: BedrijfLineageSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLineageBedrijfId>>, {id: string;data: BedrijfLineageSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLineageBedrijfId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLineageBedrijfIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLineageBedrijfId>>>
    export type PatchFixLineageBedrijfIdMutationBody = BedrijfLineageSchemaOptional
    export type PatchFixLineageBedrijfIdMutationError = ErrorType<PatchFixLineageBedrijfId400 | PatchFixLineageBedrijfId401 | PatchFixLineageBedrijfId403 | PatchFixLineageBedrijfId404 | PatchFixLineageBedrijfId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLineageBedrijfId = <TError = ErrorType<PatchFixLineageBedrijfId400 | PatchFixLineageBedrijfId401 | PatchFixLineageBedrijfId403 | PatchFixLineageBedrijfId404 | PatchFixLineageBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageBedrijfId>>, TError,{id: string;data: BedrijfLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLineageBedrijfId>>,
        TError,
        {id: string;data: BedrijfLineageSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLineageBedrijfIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLineageBedrijfId = (
    id: string,
    bedrijfLineageSchema: BedrijfLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BedrijfLineageSchema>(
      {url: `/fix/lineage/bedrijf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bedrijfLineageSchema
    },
      options);
    }
  


export const getPutFixLineageBedrijfIdMutationOptions = <TError = ErrorType<PutFixLineageBedrijfId400 | PutFixLineageBedrijfId401 | PutFixLineageBedrijfId403 | PutFixLineageBedrijfId404 | PutFixLineageBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageBedrijfId>>, TError,{id: string;data: BedrijfLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLineageBedrijfId>>, TError,{id: string;data: BedrijfLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLineageBedrijfId>>, {id: string;data: BedrijfLineageSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLineageBedrijfId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLineageBedrijfIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLineageBedrijfId>>>
    export type PutFixLineageBedrijfIdMutationBody = BedrijfLineageSchema
    export type PutFixLineageBedrijfIdMutationError = ErrorType<PutFixLineageBedrijfId400 | PutFixLineageBedrijfId401 | PutFixLineageBedrijfId403 | PutFixLineageBedrijfId404 | PutFixLineageBedrijfId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLineageBedrijfId = <TError = ErrorType<PutFixLineageBedrijfId400 | PutFixLineageBedrijfId401 | PutFixLineageBedrijfId403 | PutFixLineageBedrijfId404 | PutFixLineageBedrijfId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageBedrijfId>>, TError,{id: string;data: BedrijfLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLineageBedrijfId>>,
        TError,
        {id: string;data: BedrijfLineageSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLineageBedrijfIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLineageBesluit = (
    params: GetFixLineageBesluitParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLineageBesluit200>(
      {url: `/fix/lineage/besluit`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLineageBesluitQueryKey = (params: GetFixLineageBesluitParams,) => {
    return [`/fix/lineage/besluit`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLineageBesluitInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBesluit>>>, TError = ErrorType<GetFixLineageBesluit400 | GetFixLineageBesluit404 | GetFixLineageBesluit500>>(params: GetFixLineageBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBesluit>>> = ({ signal }) => getFixLineageBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBesluitInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBesluit>>>
export type GetFixLineageBesluitInfiniteQueryError = ErrorType<GetFixLineageBesluit400 | GetFixLineageBesluit404 | GetFixLineageBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageBesluitInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBesluit>>>, TError = ErrorType<GetFixLineageBesluit400 | GetFixLineageBesluit404 | GetFixLineageBesluit500>>(
 params: GetFixLineageBesluitParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBesluitInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageBesluitQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageBesluit>>, TError = ErrorType<GetFixLineageBesluit400 | GetFixLineageBesluit404 | GetFixLineageBesluit500>>(params: GetFixLineageBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBesluitQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBesluit>>> = ({ signal }) => getFixLineageBesluit(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluit>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBesluitQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBesluit>>>
export type GetFixLineageBesluitQueryError = ErrorType<GetFixLineageBesluit400 | GetFixLineageBesluit404 | GetFixLineageBesluit500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageBesluit = <TData = Awaited<ReturnType<typeof getFixLineageBesluit>>, TError = ErrorType<GetFixLineageBesluit400 | GetFixLineageBesluit404 | GetFixLineageBesluit500>>(
 params: GetFixLineageBesluitParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluit>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBesluitQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLineageBesluit = (
    besluitLineageSchema: BesluitLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitLineageSchema>(
      {url: `/fix/lineage/besluit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: besluitLineageSchema
    },
      options);
    }
  


export const getPostFixLineageBesluitMutationOptions = <TError = ErrorType<PostFixLineageBesluit400 | PostFixLineageBesluit401 | PostFixLineageBesluit403 | PostFixLineageBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageBesluit>>, TError,{data: BesluitLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLineageBesluit>>, TError,{data: BesluitLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLineageBesluit>>, {data: BesluitLineageSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLineageBesluit(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLineageBesluitMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLineageBesluit>>>
    export type PostFixLineageBesluitMutationBody = BesluitLineageSchema
    export type PostFixLineageBesluitMutationError = ErrorType<PostFixLineageBesluit400 | PostFixLineageBesluit401 | PostFixLineageBesluit403 | PostFixLineageBesluit500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLineageBesluit = <TError = ErrorType<PostFixLineageBesluit400 | PostFixLineageBesluit401 | PostFixLineageBesluit403 | PostFixLineageBesluit500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageBesluit>>, TError,{data: BesluitLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLineageBesluit>>,
        TError,
        {data: BesluitLineageSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLineageBesluitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLineageBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitLineageSchema>(
      {url: `/fix/lineage/besluit/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLineageBesluitIdMutationOptions = <TError = ErrorType<DeleteFixLineageBesluitId400 | DeleteFixLineageBesluitId401 | DeleteFixLineageBesluitId403 | DeleteFixLineageBesluitId404 | DeleteFixLineageBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageBesluitId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLineageBesluitId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLineageBesluitId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLineageBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLineageBesluitId>>>
    
    export type DeleteFixLineageBesluitIdMutationError = ErrorType<DeleteFixLineageBesluitId400 | DeleteFixLineageBesluitId401 | DeleteFixLineageBesluitId403 | DeleteFixLineageBesluitId404 | DeleteFixLineageBesluitId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLineageBesluitId = <TError = ErrorType<DeleteFixLineageBesluitId400 | DeleteFixLineageBesluitId401 | DeleteFixLineageBesluitId403 | DeleteFixLineageBesluitId404 | DeleteFixLineageBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageBesluitId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLineageBesluitId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLineageBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLineageBesluitId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<BesluitLineageSchema>(
      {url: `/fix/lineage/besluit/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLineageBesluitIdQueryKey = (id: string,) => {
    return [`/fix/lineage/besluit/${id}`] as const;
    }

    
export const getGetFixLineageBesluitIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBesluitId>>>, TError = ErrorType<GetFixLineageBesluitId404 | GetFixLineageBesluitId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBesluitId>>> = ({ signal }) => getFixLineageBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBesluitIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBesluitId>>>
export type GetFixLineageBesluitIdInfiniteQueryError = ErrorType<GetFixLineageBesluitId404 | GetFixLineageBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageBesluitIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageBesluitId>>>, TError = ErrorType<GetFixLineageBesluitId404 | GetFixLineageBesluitId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBesluitIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageBesluitIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError = ErrorType<GetFixLineageBesluitId404 | GetFixLineageBesluitId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageBesluitIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageBesluitId>>> = ({ signal }) => getFixLineageBesluitId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageBesluitIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageBesluitId>>>
export type GetFixLineageBesluitIdQueryError = ErrorType<GetFixLineageBesluitId404 | GetFixLineageBesluitId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageBesluitId = <TData = Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError = ErrorType<GetFixLineageBesluitId404 | GetFixLineageBesluitId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageBesluitId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageBesluitIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLineageBesluitId = (
    id: string,
    besluitLineageSchemaOptional: BesluitLineageSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitLineageSchema>(
      {url: `/fix/lineage/besluit/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: besluitLineageSchemaOptional
    },
      options);
    }
  


export const getPatchFixLineageBesluitIdMutationOptions = <TError = ErrorType<PatchFixLineageBesluitId400 | PatchFixLineageBesluitId401 | PatchFixLineageBesluitId403 | PatchFixLineageBesluitId404 | PatchFixLineageBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageBesluitId>>, TError,{id: string;data: BesluitLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageBesluitId>>, TError,{id: string;data: BesluitLineageSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLineageBesluitId>>, {id: string;data: BesluitLineageSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLineageBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLineageBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLineageBesluitId>>>
    export type PatchFixLineageBesluitIdMutationBody = BesluitLineageSchemaOptional
    export type PatchFixLineageBesluitIdMutationError = ErrorType<PatchFixLineageBesluitId400 | PatchFixLineageBesluitId401 | PatchFixLineageBesluitId403 | PatchFixLineageBesluitId404 | PatchFixLineageBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLineageBesluitId = <TError = ErrorType<PatchFixLineageBesluitId400 | PatchFixLineageBesluitId401 | PatchFixLineageBesluitId403 | PatchFixLineageBesluitId404 | PatchFixLineageBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageBesluitId>>, TError,{id: string;data: BesluitLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLineageBesluitId>>,
        TError,
        {id: string;data: BesluitLineageSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLineageBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLineageBesluitId = (
    id: string,
    besluitLineageSchema: BesluitLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<BesluitLineageSchema>(
      {url: `/fix/lineage/besluit/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: besluitLineageSchema
    },
      options);
    }
  


export const getPutFixLineageBesluitIdMutationOptions = <TError = ErrorType<PutFixLineageBesluitId400 | PutFixLineageBesluitId401 | PutFixLineageBesluitId403 | PutFixLineageBesluitId404 | PutFixLineageBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageBesluitId>>, TError,{id: string;data: BesluitLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLineageBesluitId>>, TError,{id: string;data: BesluitLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLineageBesluitId>>, {id: string;data: BesluitLineageSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLineageBesluitId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLineageBesluitIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLineageBesluitId>>>
    export type PutFixLineageBesluitIdMutationBody = BesluitLineageSchema
    export type PutFixLineageBesluitIdMutationError = ErrorType<PutFixLineageBesluitId400 | PutFixLineageBesluitId401 | PutFixLineageBesluitId403 | PutFixLineageBesluitId404 | PutFixLineageBesluitId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLineageBesluitId = <TError = ErrorType<PutFixLineageBesluitId400 | PutFixLineageBesluitId401 | PutFixLineageBesluitId403 | PutFixLineageBesluitId404 | PutFixLineageBesluitId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageBesluitId>>, TError,{id: string;data: BesluitLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLineageBesluitId>>,
        TError,
        {id: string;data: BesluitLineageSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLineageBesluitIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLineageHoofdstuk = (
    params: GetFixLineageHoofdstukParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLineageHoofdstuk200>(
      {url: `/fix/lineage/hoofdstuk`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLineageHoofdstukQueryKey = (params: GetFixLineageHoofdstukParams,) => {
    return [`/fix/lineage/hoofdstuk`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLineageHoofdstukInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>>, TError = ErrorType<GetFixLineageHoofdstuk400 | GetFixLineageHoofdstuk404 | GetFixLineageHoofdstuk500>>(params: GetFixLineageHoofdstukParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageHoofdstukQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>> = ({ signal }) => getFixLineageHoofdstuk(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageHoofdstukInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>>
export type GetFixLineageHoofdstukInfiniteQueryError = ErrorType<GetFixLineageHoofdstuk400 | GetFixLineageHoofdstuk404 | GetFixLineageHoofdstuk500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageHoofdstukInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>>, TError = ErrorType<GetFixLineageHoofdstuk400 | GetFixLineageHoofdstuk404 | GetFixLineageHoofdstuk500>>(
 params: GetFixLineageHoofdstukParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageHoofdstukInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageHoofdstukQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError = ErrorType<GetFixLineageHoofdstuk400 | GetFixLineageHoofdstuk404 | GetFixLineageHoofdstuk500>>(params: GetFixLineageHoofdstukParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageHoofdstukQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>> = ({ signal }) => getFixLineageHoofdstuk(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageHoofdstukQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>>
export type GetFixLineageHoofdstukQueryError = ErrorType<GetFixLineageHoofdstuk400 | GetFixLineageHoofdstuk404 | GetFixLineageHoofdstuk500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageHoofdstuk = <TData = Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError = ErrorType<GetFixLineageHoofdstuk400 | GetFixLineageHoofdstuk404 | GetFixLineageHoofdstuk500>>(
 params: GetFixLineageHoofdstukParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageHoofdstukQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLineageHoofdstuk = (
    hoofdstukLineageSchema: HoofdstukLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukLineageSchema>(
      {url: `/fix/lineage/hoofdstuk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukLineageSchema
    },
      options);
    }
  


export const getPostFixLineageHoofdstukMutationOptions = <TError = ErrorType<PostFixLineageHoofdstuk400 | PostFixLineageHoofdstuk401 | PostFixLineageHoofdstuk403 | PostFixLineageHoofdstuk500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageHoofdstuk>>, TError,{data: HoofdstukLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLineageHoofdstuk>>, TError,{data: HoofdstukLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLineageHoofdstuk>>, {data: HoofdstukLineageSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLineageHoofdstuk(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLineageHoofdstukMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLineageHoofdstuk>>>
    export type PostFixLineageHoofdstukMutationBody = HoofdstukLineageSchema
    export type PostFixLineageHoofdstukMutationError = ErrorType<PostFixLineageHoofdstuk400 | PostFixLineageHoofdstuk401 | PostFixLineageHoofdstuk403 | PostFixLineageHoofdstuk500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLineageHoofdstuk = <TError = ErrorType<PostFixLineageHoofdstuk400 | PostFixLineageHoofdstuk401 | PostFixLineageHoofdstuk403 | PostFixLineageHoofdstuk500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageHoofdstuk>>, TError,{data: HoofdstukLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLineageHoofdstuk>>,
        TError,
        {data: HoofdstukLineageSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLineageHoofdstukMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLineageHoofdstukId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukLineageSchema>(
      {url: `/fix/lineage/hoofdstuk/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLineageHoofdstukIdMutationOptions = <TError = ErrorType<DeleteFixLineageHoofdstukId400 | DeleteFixLineageHoofdstukId401 | DeleteFixLineageHoofdstukId403 | DeleteFixLineageHoofdstukId404 | DeleteFixLineageHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageHoofdstukId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageHoofdstukId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLineageHoofdstukId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLineageHoofdstukId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLineageHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLineageHoofdstukId>>>
    
    export type DeleteFixLineageHoofdstukIdMutationError = ErrorType<DeleteFixLineageHoofdstukId400 | DeleteFixLineageHoofdstukId401 | DeleteFixLineageHoofdstukId403 | DeleteFixLineageHoofdstukId404 | DeleteFixLineageHoofdstukId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLineageHoofdstukId = <TError = ErrorType<DeleteFixLineageHoofdstukId400 | DeleteFixLineageHoofdstukId401 | DeleteFixLineageHoofdstukId403 | DeleteFixLineageHoofdstukId404 | DeleteFixLineageHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageHoofdstukId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLineageHoofdstukId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLineageHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLineageHoofdstukId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<HoofdstukLineageSchema>(
      {url: `/fix/lineage/hoofdstuk/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLineageHoofdstukIdQueryKey = (id: string,) => {
    return [`/fix/lineage/hoofdstuk/${id}`] as const;
    }

    
export const getGetFixLineageHoofdstukIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>>, TError = ErrorType<GetFixLineageHoofdstukId404 | GetFixLineageHoofdstukId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageHoofdstukIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>> = ({ signal }) => getFixLineageHoofdstukId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageHoofdstukIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>>
export type GetFixLineageHoofdstukIdInfiniteQueryError = ErrorType<GetFixLineageHoofdstukId404 | GetFixLineageHoofdstukId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageHoofdstukIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>>, TError = ErrorType<GetFixLineageHoofdstukId404 | GetFixLineageHoofdstukId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageHoofdstukIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageHoofdstukIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError = ErrorType<GetFixLineageHoofdstukId404 | GetFixLineageHoofdstukId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageHoofdstukIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>> = ({ signal }) => getFixLineageHoofdstukId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageHoofdstukIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>>
export type GetFixLineageHoofdstukIdQueryError = ErrorType<GetFixLineageHoofdstukId404 | GetFixLineageHoofdstukId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageHoofdstukId = <TData = Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError = ErrorType<GetFixLineageHoofdstukId404 | GetFixLineageHoofdstukId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageHoofdstukIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLineageHoofdstukId = (
    id: string,
    hoofdstukLineageSchemaOptional: HoofdstukLineageSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukLineageSchema>(
      {url: `/fix/lineage/hoofdstuk/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukLineageSchemaOptional
    },
      options);
    }
  


export const getPatchFixLineageHoofdstukIdMutationOptions = <TError = ErrorType<PatchFixLineageHoofdstukId400 | PatchFixLineageHoofdstukId401 | PatchFixLineageHoofdstukId403 | PatchFixLineageHoofdstukId404 | PatchFixLineageHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageHoofdstukId>>, TError,{id: string;data: HoofdstukLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageHoofdstukId>>, TError,{id: string;data: HoofdstukLineageSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLineageHoofdstukId>>, {id: string;data: HoofdstukLineageSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLineageHoofdstukId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLineageHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLineageHoofdstukId>>>
    export type PatchFixLineageHoofdstukIdMutationBody = HoofdstukLineageSchemaOptional
    export type PatchFixLineageHoofdstukIdMutationError = ErrorType<PatchFixLineageHoofdstukId400 | PatchFixLineageHoofdstukId401 | PatchFixLineageHoofdstukId403 | PatchFixLineageHoofdstukId404 | PatchFixLineageHoofdstukId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLineageHoofdstukId = <TError = ErrorType<PatchFixLineageHoofdstukId400 | PatchFixLineageHoofdstukId401 | PatchFixLineageHoofdstukId403 | PatchFixLineageHoofdstukId404 | PatchFixLineageHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageHoofdstukId>>, TError,{id: string;data: HoofdstukLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLineageHoofdstukId>>,
        TError,
        {id: string;data: HoofdstukLineageSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLineageHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLineageHoofdstukId = (
    id: string,
    hoofdstukLineageSchema: HoofdstukLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukLineageSchema>(
      {url: `/fix/lineage/hoofdstuk/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukLineageSchema
    },
      options);
    }
  


export const getPutFixLineageHoofdstukIdMutationOptions = <TError = ErrorType<PutFixLineageHoofdstukId400 | PutFixLineageHoofdstukId401 | PutFixLineageHoofdstukId403 | PutFixLineageHoofdstukId404 | PutFixLineageHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageHoofdstukId>>, TError,{id: string;data: HoofdstukLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLineageHoofdstukId>>, TError,{id: string;data: HoofdstukLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLineageHoofdstukId>>, {id: string;data: HoofdstukLineageSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLineageHoofdstukId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLineageHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLineageHoofdstukId>>>
    export type PutFixLineageHoofdstukIdMutationBody = HoofdstukLineageSchema
    export type PutFixLineageHoofdstukIdMutationError = ErrorType<PutFixLineageHoofdstukId400 | PutFixLineageHoofdstukId401 | PutFixLineageHoofdstukId403 | PutFixLineageHoofdstukId404 | PutFixLineageHoofdstukId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLineageHoofdstukId = <TError = ErrorType<PutFixLineageHoofdstukId400 | PutFixLineageHoofdstukId401 | PutFixLineageHoofdstukId403 | PutFixLineageHoofdstukId404 | PutFixLineageHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageHoofdstukId>>, TError,{id: string;data: HoofdstukLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLineageHoofdstukId>>,
        TError,
        {id: string;data: HoofdstukLineageSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLineageHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLineageParagraaf = (
    params: GetFixLineageParagraafParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLineageParagraaf200>(
      {url: `/fix/lineage/paragraaf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLineageParagraafQueryKey = (params: GetFixLineageParagraafParams,) => {
    return [`/fix/lineage/paragraaf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLineageParagraafInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageParagraaf>>>, TError = ErrorType<GetFixLineageParagraaf400 | GetFixLineageParagraaf404 | GetFixLineageParagraaf500>>(params: GetFixLineageParagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageParagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageParagraaf>>> = ({ signal }) => getFixLineageParagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageParagraafInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageParagraaf>>>
export type GetFixLineageParagraafInfiniteQueryError = ErrorType<GetFixLineageParagraaf400 | GetFixLineageParagraaf404 | GetFixLineageParagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageParagraafInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageParagraaf>>>, TError = ErrorType<GetFixLineageParagraaf400 | GetFixLineageParagraaf404 | GetFixLineageParagraaf500>>(
 params: GetFixLineageParagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageParagraafInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageParagraafQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError = ErrorType<GetFixLineageParagraaf400 | GetFixLineageParagraaf404 | GetFixLineageParagraaf500>>(params: GetFixLineageParagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageParagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageParagraaf>>> = ({ signal }) => getFixLineageParagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageParagraafQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageParagraaf>>>
export type GetFixLineageParagraafQueryError = ErrorType<GetFixLineageParagraaf400 | GetFixLineageParagraaf404 | GetFixLineageParagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageParagraaf = <TData = Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError = ErrorType<GetFixLineageParagraaf400 | GetFixLineageParagraaf404 | GetFixLineageParagraaf500>>(
 params: GetFixLineageParagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageParagraafQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLineageParagraaf = (
    paragraafLineageSchema: ParagraafLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafLineageSchema>(
      {url: `/fix/lineage/paragraaf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: paragraafLineageSchema
    },
      options);
    }
  


export const getPostFixLineageParagraafMutationOptions = <TError = ErrorType<PostFixLineageParagraaf400 | PostFixLineageParagraaf401 | PostFixLineageParagraaf403 | PostFixLineageParagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageParagraaf>>, TError,{data: ParagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLineageParagraaf>>, TError,{data: ParagraafLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLineageParagraaf>>, {data: ParagraafLineageSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLineageParagraaf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLineageParagraafMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLineageParagraaf>>>
    export type PostFixLineageParagraafMutationBody = ParagraafLineageSchema
    export type PostFixLineageParagraafMutationError = ErrorType<PostFixLineageParagraaf400 | PostFixLineageParagraaf401 | PostFixLineageParagraaf403 | PostFixLineageParagraaf500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLineageParagraaf = <TError = ErrorType<PostFixLineageParagraaf400 | PostFixLineageParagraaf401 | PostFixLineageParagraaf403 | PostFixLineageParagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageParagraaf>>, TError,{data: ParagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLineageParagraaf>>,
        TError,
        {data: ParagraafLineageSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLineageParagraafMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLineageParagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafLineageSchema>(
      {url: `/fix/lineage/paragraaf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLineageParagraafIdMutationOptions = <TError = ErrorType<DeleteFixLineageParagraafId400 | DeleteFixLineageParagraafId401 | DeleteFixLineageParagraafId403 | DeleteFixLineageParagraafId404 | DeleteFixLineageParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageParagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageParagraafId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLineageParagraafId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLineageParagraafId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLineageParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLineageParagraafId>>>
    
    export type DeleteFixLineageParagraafIdMutationError = ErrorType<DeleteFixLineageParagraafId400 | DeleteFixLineageParagraafId401 | DeleteFixLineageParagraafId403 | DeleteFixLineageParagraafId404 | DeleteFixLineageParagraafId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLineageParagraafId = <TError = ErrorType<DeleteFixLineageParagraafId400 | DeleteFixLineageParagraafId401 | DeleteFixLineageParagraafId403 | DeleteFixLineageParagraafId404 | DeleteFixLineageParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageParagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLineageParagraafId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLineageParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLineageParagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<ParagraafLineageSchema>(
      {url: `/fix/lineage/paragraaf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLineageParagraafIdQueryKey = (id: string,) => {
    return [`/fix/lineage/paragraaf/${id}`] as const;
    }

    
export const getGetFixLineageParagraafIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageParagraafId>>>, TError = ErrorType<GetFixLineageParagraafId404 | GetFixLineageParagraafId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageParagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageParagraafId>>> = ({ signal }) => getFixLineageParagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageParagraafIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageParagraafId>>>
export type GetFixLineageParagraafIdInfiniteQueryError = ErrorType<GetFixLineageParagraafId404 | GetFixLineageParagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageParagraafIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageParagraafId>>>, TError = ErrorType<GetFixLineageParagraafId404 | GetFixLineageParagraafId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageParagraafIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageParagraafIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError = ErrorType<GetFixLineageParagraafId404 | GetFixLineageParagraafId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageParagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageParagraafId>>> = ({ signal }) => getFixLineageParagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageParagraafIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageParagraafId>>>
export type GetFixLineageParagraafIdQueryError = ErrorType<GetFixLineageParagraafId404 | GetFixLineageParagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageParagraafId = <TData = Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError = ErrorType<GetFixLineageParagraafId404 | GetFixLineageParagraafId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageParagraafIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLineageParagraafId = (
    id: string,
    paragraafLineageSchemaOptional: ParagraafLineageSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafLineageSchema>(
      {url: `/fix/lineage/paragraaf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: paragraafLineageSchemaOptional
    },
      options);
    }
  


export const getPatchFixLineageParagraafIdMutationOptions = <TError = ErrorType<PatchFixLineageParagraafId400 | PatchFixLineageParagraafId401 | PatchFixLineageParagraafId403 | PatchFixLineageParagraafId404 | PatchFixLineageParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageParagraafId>>, TError,{id: string;data: ParagraafLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageParagraafId>>, TError,{id: string;data: ParagraafLineageSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLineageParagraafId>>, {id: string;data: ParagraafLineageSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLineageParagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLineageParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLineageParagraafId>>>
    export type PatchFixLineageParagraafIdMutationBody = ParagraafLineageSchemaOptional
    export type PatchFixLineageParagraafIdMutationError = ErrorType<PatchFixLineageParagraafId400 | PatchFixLineageParagraafId401 | PatchFixLineageParagraafId403 | PatchFixLineageParagraafId404 | PatchFixLineageParagraafId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLineageParagraafId = <TError = ErrorType<PatchFixLineageParagraafId400 | PatchFixLineageParagraafId401 | PatchFixLineageParagraafId403 | PatchFixLineageParagraafId404 | PatchFixLineageParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageParagraafId>>, TError,{id: string;data: ParagraafLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLineageParagraafId>>,
        TError,
        {id: string;data: ParagraafLineageSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLineageParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLineageParagraafId = (
    id: string,
    paragraafLineageSchema: ParagraafLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafLineageSchema>(
      {url: `/fix/lineage/paragraaf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: paragraafLineageSchema
    },
      options);
    }
  


export const getPutFixLineageParagraafIdMutationOptions = <TError = ErrorType<PutFixLineageParagraafId400 | PutFixLineageParagraafId401 | PutFixLineageParagraafId403 | PutFixLineageParagraafId404 | PutFixLineageParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageParagraafId>>, TError,{id: string;data: ParagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLineageParagraafId>>, TError,{id: string;data: ParagraafLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLineageParagraafId>>, {id: string;data: ParagraafLineageSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLineageParagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLineageParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLineageParagraafId>>>
    export type PutFixLineageParagraafIdMutationBody = ParagraafLineageSchema
    export type PutFixLineageParagraafIdMutationError = ErrorType<PutFixLineageParagraafId400 | PutFixLineageParagraafId401 | PutFixLineageParagraafId403 | PutFixLineageParagraafId404 | PutFixLineageParagraafId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLineageParagraafId = <TError = ErrorType<PutFixLineageParagraafId400 | PutFixLineageParagraafId401 | PutFixLineageParagraafId403 | PutFixLineageParagraafId404 | PutFixLineageParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageParagraafId>>, TError,{id: string;data: ParagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLineageParagraafId>>,
        TError,
        {id: string;data: ParagraafLineageSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLineageParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLineageSubparagraaf = (
    params: GetFixLineageSubparagraafParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLineageSubparagraaf200>(
      {url: `/fix/lineage/subparagraaf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLineageSubparagraafQueryKey = (params: GetFixLineageSubparagraafParams,) => {
    return [`/fix/lineage/subparagraaf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLineageSubparagraafInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>>, TError = ErrorType<GetFixLineageSubparagraaf400 | GetFixLineageSubparagraaf404 | GetFixLineageSubparagraaf500>>(params: GetFixLineageSubparagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageSubparagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>> = ({ signal }) => getFixLineageSubparagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageSubparagraafInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>>
export type GetFixLineageSubparagraafInfiniteQueryError = ErrorType<GetFixLineageSubparagraaf400 | GetFixLineageSubparagraaf404 | GetFixLineageSubparagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageSubparagraafInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>>, TError = ErrorType<GetFixLineageSubparagraaf400 | GetFixLineageSubparagraaf404 | GetFixLineageSubparagraaf500>>(
 params: GetFixLineageSubparagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageSubparagraafInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageSubparagraafQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError = ErrorType<GetFixLineageSubparagraaf400 | GetFixLineageSubparagraaf404 | GetFixLineageSubparagraaf500>>(params: GetFixLineageSubparagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageSubparagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>> = ({ signal }) => getFixLineageSubparagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageSubparagraafQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>>
export type GetFixLineageSubparagraafQueryError = ErrorType<GetFixLineageSubparagraaf400 | GetFixLineageSubparagraaf404 | GetFixLineageSubparagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageSubparagraaf = <TData = Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError = ErrorType<GetFixLineageSubparagraaf400 | GetFixLineageSubparagraaf404 | GetFixLineageSubparagraaf500>>(
 params: GetFixLineageSubparagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageSubparagraafQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLineageSubparagraaf = (
    subparagraafLineageSchema: SubparagraafLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafLineageSchema>(
      {url: `/fix/lineage/subparagraaf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subparagraafLineageSchema
    },
      options);
    }
  


export const getPostFixLineageSubparagraafMutationOptions = <TError = ErrorType<PostFixLineageSubparagraaf400 | PostFixLineageSubparagraaf401 | PostFixLineageSubparagraaf403 | PostFixLineageSubparagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageSubparagraaf>>, TError,{data: SubparagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLineageSubparagraaf>>, TError,{data: SubparagraafLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLineageSubparagraaf>>, {data: SubparagraafLineageSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLineageSubparagraaf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLineageSubparagraafMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLineageSubparagraaf>>>
    export type PostFixLineageSubparagraafMutationBody = SubparagraafLineageSchema
    export type PostFixLineageSubparagraafMutationError = ErrorType<PostFixLineageSubparagraaf400 | PostFixLineageSubparagraaf401 | PostFixLineageSubparagraaf403 | PostFixLineageSubparagraaf500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLineageSubparagraaf = <TError = ErrorType<PostFixLineageSubparagraaf400 | PostFixLineageSubparagraaf401 | PostFixLineageSubparagraaf403 | PostFixLineageSubparagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageSubparagraaf>>, TError,{data: SubparagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLineageSubparagraaf>>,
        TError,
        {data: SubparagraafLineageSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLineageSubparagraafMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLineageSubparagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafLineageSchema>(
      {url: `/fix/lineage/subparagraaf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLineageSubparagraafIdMutationOptions = <TError = ErrorType<DeleteFixLineageSubparagraafId400 | DeleteFixLineageSubparagraafId401 | DeleteFixLineageSubparagraafId403 | DeleteFixLineageSubparagraafId404 | DeleteFixLineageSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageSubparagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageSubparagraafId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLineageSubparagraafId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLineageSubparagraafId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLineageSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLineageSubparagraafId>>>
    
    export type DeleteFixLineageSubparagraafIdMutationError = ErrorType<DeleteFixLineageSubparagraafId400 | DeleteFixLineageSubparagraafId401 | DeleteFixLineageSubparagraafId403 | DeleteFixLineageSubparagraafId404 | DeleteFixLineageSubparagraafId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLineageSubparagraafId = <TError = ErrorType<DeleteFixLineageSubparagraafId400 | DeleteFixLineageSubparagraafId401 | DeleteFixLineageSubparagraafId403 | DeleteFixLineageSubparagraafId404 | DeleteFixLineageSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageSubparagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLineageSubparagraafId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLineageSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLineageSubparagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<SubparagraafLineageSchema>(
      {url: `/fix/lineage/subparagraaf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLineageSubparagraafIdQueryKey = (id: string,) => {
    return [`/fix/lineage/subparagraaf/${id}`] as const;
    }

    
export const getGetFixLineageSubparagraafIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>>, TError = ErrorType<GetFixLineageSubparagraafId404 | GetFixLineageSubparagraafId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageSubparagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>> = ({ signal }) => getFixLineageSubparagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageSubparagraafIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>>
export type GetFixLineageSubparagraafIdInfiniteQueryError = ErrorType<GetFixLineageSubparagraafId404 | GetFixLineageSubparagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageSubparagraafIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>>, TError = ErrorType<GetFixLineageSubparagraafId404 | GetFixLineageSubparagraafId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageSubparagraafIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageSubparagraafIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError = ErrorType<GetFixLineageSubparagraafId404 | GetFixLineageSubparagraafId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageSubparagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>> = ({ signal }) => getFixLineageSubparagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageSubparagraafIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>>
export type GetFixLineageSubparagraafIdQueryError = ErrorType<GetFixLineageSubparagraafId404 | GetFixLineageSubparagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageSubparagraafId = <TData = Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError = ErrorType<GetFixLineageSubparagraafId404 | GetFixLineageSubparagraafId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageSubparagraafIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLineageSubparagraafId = (
    id: string,
    subparagraafLineageSchemaOptional: SubparagraafLineageSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafLineageSchema>(
      {url: `/fix/lineage/subparagraaf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: subparagraafLineageSchemaOptional
    },
      options);
    }
  


export const getPatchFixLineageSubparagraafIdMutationOptions = <TError = ErrorType<PatchFixLineageSubparagraafId400 | PatchFixLineageSubparagraafId401 | PatchFixLineageSubparagraafId403 | PatchFixLineageSubparagraafId404 | PatchFixLineageSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageSubparagraafId>>, TError,{id: string;data: SubparagraafLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageSubparagraafId>>, TError,{id: string;data: SubparagraafLineageSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLineageSubparagraafId>>, {id: string;data: SubparagraafLineageSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLineageSubparagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLineageSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLineageSubparagraafId>>>
    export type PatchFixLineageSubparagraafIdMutationBody = SubparagraafLineageSchemaOptional
    export type PatchFixLineageSubparagraafIdMutationError = ErrorType<PatchFixLineageSubparagraafId400 | PatchFixLineageSubparagraafId401 | PatchFixLineageSubparagraafId403 | PatchFixLineageSubparagraafId404 | PatchFixLineageSubparagraafId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLineageSubparagraafId = <TError = ErrorType<PatchFixLineageSubparagraafId400 | PatchFixLineageSubparagraafId401 | PatchFixLineageSubparagraafId403 | PatchFixLineageSubparagraafId404 | PatchFixLineageSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageSubparagraafId>>, TError,{id: string;data: SubparagraafLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLineageSubparagraafId>>,
        TError,
        {id: string;data: SubparagraafLineageSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLineageSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLineageSubparagraafId = (
    id: string,
    subparagraafLineageSchema: SubparagraafLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafLineageSchema>(
      {url: `/fix/lineage/subparagraaf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: subparagraafLineageSchema
    },
      options);
    }
  


export const getPutFixLineageSubparagraafIdMutationOptions = <TError = ErrorType<PutFixLineageSubparagraafId400 | PutFixLineageSubparagraafId401 | PutFixLineageSubparagraafId403 | PutFixLineageSubparagraafId404 | PutFixLineageSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageSubparagraafId>>, TError,{id: string;data: SubparagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLineageSubparagraafId>>, TError,{id: string;data: SubparagraafLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLineageSubparagraafId>>, {id: string;data: SubparagraafLineageSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLineageSubparagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLineageSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLineageSubparagraafId>>>
    export type PutFixLineageSubparagraafIdMutationBody = SubparagraafLineageSchema
    export type PutFixLineageSubparagraafIdMutationError = ErrorType<PutFixLineageSubparagraafId400 | PutFixLineageSubparagraafId401 | PutFixLineageSubparagraafId403 | PutFixLineageSubparagraafId404 | PutFixLineageSubparagraafId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLineageSubparagraafId = <TError = ErrorType<PutFixLineageSubparagraafId400 | PutFixLineageSubparagraafId401 | PutFixLineageSubparagraafId403 | PutFixLineageSubparagraafId404 | PutFixLineageSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageSubparagraafId>>, TError,{id: string;data: SubparagraafLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLineageSubparagraafId>>,
        TError,
        {id: string;data: SubparagraafLineageSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLineageSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLineageVoorschrift = (
    params: GetFixLineageVoorschriftParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLineageVoorschrift200>(
      {url: `/fix/lineage/voorschrift`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLineageVoorschriftQueryKey = (params: GetFixLineageVoorschriftParams,) => {
    return [`/fix/lineage/voorschrift`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLineageVoorschriftInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageVoorschrift>>>, TError = ErrorType<GetFixLineageVoorschrift400 | GetFixLineageVoorschrift404 | GetFixLineageVoorschrift500>>(params: GetFixLineageVoorschriftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageVoorschriftQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageVoorschrift>>> = ({ signal }) => getFixLineageVoorschrift(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageVoorschriftInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageVoorschrift>>>
export type GetFixLineageVoorschriftInfiniteQueryError = ErrorType<GetFixLineageVoorschrift400 | GetFixLineageVoorschrift404 | GetFixLineageVoorschrift500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageVoorschriftInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageVoorschrift>>>, TError = ErrorType<GetFixLineageVoorschrift400 | GetFixLineageVoorschrift404 | GetFixLineageVoorschrift500>>(
 params: GetFixLineageVoorschriftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageVoorschriftInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageVoorschriftQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError = ErrorType<GetFixLineageVoorschrift400 | GetFixLineageVoorschrift404 | GetFixLineageVoorschrift500>>(params: GetFixLineageVoorschriftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageVoorschriftQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageVoorschrift>>> = ({ signal }) => getFixLineageVoorschrift(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageVoorschriftQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageVoorschrift>>>
export type GetFixLineageVoorschriftQueryError = ErrorType<GetFixLineageVoorschrift400 | GetFixLineageVoorschrift404 | GetFixLineageVoorschrift500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLineageVoorschrift = <TData = Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError = ErrorType<GetFixLineageVoorschrift400 | GetFixLineageVoorschrift404 | GetFixLineageVoorschrift500>>(
 params: GetFixLineageVoorschriftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageVoorschriftQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLineageVoorschrift = (
    voorschriftLineageSchema: VoorschriftLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLineageSchema>(
      {url: `/fix/lineage/voorschrift`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftLineageSchema
    },
      options);
    }
  


export const getPostFixLineageVoorschriftMutationOptions = <TError = ErrorType<PostFixLineageVoorschrift400 | PostFixLineageVoorschrift401 | PostFixLineageVoorschrift403 | PostFixLineageVoorschrift500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageVoorschrift>>, TError,{data: VoorschriftLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLineageVoorschrift>>, TError,{data: VoorschriftLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLineageVoorschrift>>, {data: VoorschriftLineageSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLineageVoorschrift(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLineageVoorschriftMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLineageVoorschrift>>>
    export type PostFixLineageVoorschriftMutationBody = VoorschriftLineageSchema
    export type PostFixLineageVoorschriftMutationError = ErrorType<PostFixLineageVoorschrift400 | PostFixLineageVoorschrift401 | PostFixLineageVoorschrift403 | PostFixLineageVoorschrift500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLineageVoorschrift = <TError = ErrorType<PostFixLineageVoorschrift400 | PostFixLineageVoorschrift401 | PostFixLineageVoorschrift403 | PostFixLineageVoorschrift500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLineageVoorschrift>>, TError,{data: VoorschriftLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLineageVoorschrift>>,
        TError,
        {data: VoorschriftLineageSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLineageVoorschriftMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLineageVoorschriftId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLineageSchema>(
      {url: `/fix/lineage/voorschrift/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLineageVoorschriftIdMutationOptions = <TError = ErrorType<DeleteFixLineageVoorschriftId400 | DeleteFixLineageVoorschriftId401 | DeleteFixLineageVoorschriftId403 | DeleteFixLineageVoorschriftId404 | DeleteFixLineageVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageVoorschriftId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageVoorschriftId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLineageVoorschriftId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLineageVoorschriftId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLineageVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLineageVoorschriftId>>>
    
    export type DeleteFixLineageVoorschriftIdMutationError = ErrorType<DeleteFixLineageVoorschriftId400 | DeleteFixLineageVoorschriftId401 | DeleteFixLineageVoorschriftId403 | DeleteFixLineageVoorschriftId404 | DeleteFixLineageVoorschriftId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLineageVoorschriftId = <TError = ErrorType<DeleteFixLineageVoorschriftId400 | DeleteFixLineageVoorschriftId401 | DeleteFixLineageVoorschriftId403 | DeleteFixLineageVoorschriftId404 | DeleteFixLineageVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLineageVoorschriftId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLineageVoorschriftId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLineageVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLineageVoorschriftId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<VoorschriftLineageSchema>(
      {url: `/fix/lineage/voorschrift/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLineageVoorschriftIdQueryKey = (id: string,) => {
    return [`/fix/lineage/voorschrift/${id}`] as const;
    }

    
export const getGetFixLineageVoorschriftIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>>, TError = ErrorType<GetFixLineageVoorschriftId404 | GetFixLineageVoorschriftId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageVoorschriftIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>> = ({ signal }) => getFixLineageVoorschriftId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageVoorschriftIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>>
export type GetFixLineageVoorschriftIdInfiniteQueryError = ErrorType<GetFixLineageVoorschriftId404 | GetFixLineageVoorschriftId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageVoorschriftIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>>, TError = ErrorType<GetFixLineageVoorschriftId404 | GetFixLineageVoorschriftId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageVoorschriftIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLineageVoorschriftIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError = ErrorType<GetFixLineageVoorschriftId404 | GetFixLineageVoorschriftId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLineageVoorschriftIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>> = ({ signal }) => getFixLineageVoorschriftId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLineageVoorschriftIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>>
export type GetFixLineageVoorschriftIdQueryError = ErrorType<GetFixLineageVoorschriftId404 | GetFixLineageVoorschriftId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLineageVoorschriftId = <TData = Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError = ErrorType<GetFixLineageVoorschriftId404 | GetFixLineageVoorschriftId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLineageVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLineageVoorschriftIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLineageVoorschriftId = (
    id: string,
    voorschriftLineageSchemaOptional: VoorschriftLineageSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLineageSchema>(
      {url: `/fix/lineage/voorschrift/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftLineageSchemaOptional
    },
      options);
    }
  


export const getPatchFixLineageVoorschriftIdMutationOptions = <TError = ErrorType<PatchFixLineageVoorschriftId400 | PatchFixLineageVoorschriftId401 | PatchFixLineageVoorschriftId403 | PatchFixLineageVoorschriftId404 | PatchFixLineageVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageVoorschriftId>>, TError,{id: string;data: VoorschriftLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageVoorschriftId>>, TError,{id: string;data: VoorschriftLineageSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLineageVoorschriftId>>, {id: string;data: VoorschriftLineageSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLineageVoorschriftId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLineageVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLineageVoorschriftId>>>
    export type PatchFixLineageVoorschriftIdMutationBody = VoorschriftLineageSchemaOptional
    export type PatchFixLineageVoorschriftIdMutationError = ErrorType<PatchFixLineageVoorschriftId400 | PatchFixLineageVoorschriftId401 | PatchFixLineageVoorschriftId403 | PatchFixLineageVoorschriftId404 | PatchFixLineageVoorschriftId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLineageVoorschriftId = <TError = ErrorType<PatchFixLineageVoorschriftId400 | PatchFixLineageVoorschriftId401 | PatchFixLineageVoorschriftId403 | PatchFixLineageVoorschriftId404 | PatchFixLineageVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLineageVoorschriftId>>, TError,{id: string;data: VoorschriftLineageSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLineageVoorschriftId>>,
        TError,
        {id: string;data: VoorschriftLineageSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLineageVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLineageVoorschriftId = (
    id: string,
    voorschriftLineageSchema: VoorschriftLineageSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftLineageSchema>(
      {url: `/fix/lineage/voorschrift/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftLineageSchema
    },
      options);
    }
  


export const getPutFixLineageVoorschriftIdMutationOptions = <TError = ErrorType<PutFixLineageVoorschriftId400 | PutFixLineageVoorschriftId401 | PutFixLineageVoorschriftId403 | PutFixLineageVoorschriftId404 | PutFixLineageVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageVoorschriftId>>, TError,{id: string;data: VoorschriftLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLineageVoorschriftId>>, TError,{id: string;data: VoorschriftLineageSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLineageVoorschriftId>>, {id: string;data: VoorschriftLineageSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLineageVoorschriftId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLineageVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLineageVoorschriftId>>>
    export type PutFixLineageVoorschriftIdMutationBody = VoorschriftLineageSchema
    export type PutFixLineageVoorschriftIdMutationError = ErrorType<PutFixLineageVoorschriftId400 | PutFixLineageVoorschriftId401 | PutFixLineageVoorschriftId403 | PutFixLineageVoorschriftId404 | PutFixLineageVoorschriftId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLineageVoorschriftId = <TError = ErrorType<PutFixLineageVoorschriftId400 | PutFixLineageVoorschriftId401 | PutFixLineageVoorschriftId403 | PutFixLineageVoorschriftId404 | PutFixLineageVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLineageVoorschriftId>>, TError,{id: string;data: VoorschriftLineageSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLineageVoorschriftId>>,
        TError,
        {id: string;data: VoorschriftLineageSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLineageVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLinkHb = (
    params: GetFixLinkHbParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLinkHb200>(
      {url: `/fix/link/hb`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLinkHbQueryKey = (params: GetFixLinkHbParams,) => {
    return [`/fix/link/hb`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLinkHbInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkHb>>>, TError = ErrorType<GetFixLinkHb400 | GetFixLinkHb404 | GetFixLinkHb500>>(params: GetFixLinkHbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkHb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkHbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkHb>>> = ({ signal }) => getFixLinkHb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkHb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkHbInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkHb>>>
export type GetFixLinkHbInfiniteQueryError = ErrorType<GetFixLinkHb400 | GetFixLinkHb404 | GetFixLinkHb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkHbInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkHb>>>, TError = ErrorType<GetFixLinkHb400 | GetFixLinkHb404 | GetFixLinkHb500>>(
 params: GetFixLinkHbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkHb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkHbInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkHbQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkHb>>, TError = ErrorType<GetFixLinkHb400 | GetFixLinkHb404 | GetFixLinkHb500>>(params: GetFixLinkHbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkHb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkHbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkHb>>> = ({ signal }) => getFixLinkHb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkHb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkHbQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkHb>>>
export type GetFixLinkHbQueryError = ErrorType<GetFixLinkHb400 | GetFixLinkHb404 | GetFixLinkHb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkHb = <TData = Awaited<ReturnType<typeof getFixLinkHb>>, TError = ErrorType<GetFixLinkHb400 | GetFixLinkHb404 | GetFixLinkHb500>>(
 params: GetFixLinkHbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkHb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkHbQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLinkHb = (
    hBSchema: HBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HBSchema>(
      {url: `/fix/link/hb`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hBSchema
    },
      options);
    }
  


export const getPostFixLinkHbMutationOptions = <TError = ErrorType<PostFixLinkHb400 | PostFixLinkHb401 | PostFixLinkHb403 | PostFixLinkHb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkHb>>, TError,{data: HBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLinkHb>>, TError,{data: HBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLinkHb>>, {data: HBSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLinkHb(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLinkHbMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLinkHb>>>
    export type PostFixLinkHbMutationBody = HBSchema
    export type PostFixLinkHbMutationError = ErrorType<PostFixLinkHb400 | PostFixLinkHb401 | PostFixLinkHb403 | PostFixLinkHb500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLinkHb = <TError = ErrorType<PostFixLinkHb400 | PostFixLinkHb401 | PostFixLinkHb403 | PostFixLinkHb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkHb>>, TError,{data: HBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLinkHb>>,
        TError,
        {data: HBSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLinkHbMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLinkHbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HBSchema>(
      {url: `/fix/link/hb/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLinkHbIdMutationOptions = <TError = ErrorType<DeleteFixLinkHbId400 | DeleteFixLinkHbId401 | DeleteFixLinkHbId403 | DeleteFixLinkHbId404 | DeleteFixLinkHbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkHbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkHbId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLinkHbId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLinkHbId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLinkHbIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLinkHbId>>>
    
    export type DeleteFixLinkHbIdMutationError = ErrorType<DeleteFixLinkHbId400 | DeleteFixLinkHbId401 | DeleteFixLinkHbId403 | DeleteFixLinkHbId404 | DeleteFixLinkHbId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLinkHbId = <TError = ErrorType<DeleteFixLinkHbId400 | DeleteFixLinkHbId401 | DeleteFixLinkHbId403 | DeleteFixLinkHbId404 | DeleteFixLinkHbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkHbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLinkHbId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLinkHbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLinkHbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<HBSchema>(
      {url: `/fix/link/hb/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLinkHbIdQueryKey = (id: string,) => {
    return [`/fix/link/hb/${id}`] as const;
    }

    
export const getGetFixLinkHbIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkHbId>>>, TError = ErrorType<GetFixLinkHbId404 | GetFixLinkHbId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkHbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkHbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkHbId>>> = ({ signal }) => getFixLinkHbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkHbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkHbIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkHbId>>>
export type GetFixLinkHbIdInfiniteQueryError = ErrorType<GetFixLinkHbId404 | GetFixLinkHbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkHbIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkHbId>>>, TError = ErrorType<GetFixLinkHbId404 | GetFixLinkHbId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkHbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkHbIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkHbIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkHbId>>, TError = ErrorType<GetFixLinkHbId404 | GetFixLinkHbId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkHbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkHbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkHbId>>> = ({ signal }) => getFixLinkHbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkHbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkHbIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkHbId>>>
export type GetFixLinkHbIdQueryError = ErrorType<GetFixLinkHbId404 | GetFixLinkHbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkHbId = <TData = Awaited<ReturnType<typeof getFixLinkHbId>>, TError = ErrorType<GetFixLinkHbId404 | GetFixLinkHbId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkHbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkHbIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLinkHbId = (
    id: string,
    hBSchemaOptional: HBSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HBSchema>(
      {url: `/fix/link/hb/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: hBSchemaOptional
    },
      options);
    }
  


export const getPatchFixLinkHbIdMutationOptions = <TError = ErrorType<PatchFixLinkHbId400 | PatchFixLinkHbId401 | PatchFixLinkHbId403 | PatchFixLinkHbId404 | PatchFixLinkHbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkHbId>>, TError,{id: string;data: HBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkHbId>>, TError,{id: string;data: HBSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLinkHbId>>, {id: string;data: HBSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLinkHbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLinkHbIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLinkHbId>>>
    export type PatchFixLinkHbIdMutationBody = HBSchemaOptional
    export type PatchFixLinkHbIdMutationError = ErrorType<PatchFixLinkHbId400 | PatchFixLinkHbId401 | PatchFixLinkHbId403 | PatchFixLinkHbId404 | PatchFixLinkHbId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLinkHbId = <TError = ErrorType<PatchFixLinkHbId400 | PatchFixLinkHbId401 | PatchFixLinkHbId403 | PatchFixLinkHbId404 | PatchFixLinkHbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkHbId>>, TError,{id: string;data: HBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLinkHbId>>,
        TError,
        {id: string;data: HBSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLinkHbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLinkHbId = (
    id: string,
    hBSchema: HBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HBSchema>(
      {url: `/fix/link/hb/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hBSchema
    },
      options);
    }
  


export const getPutFixLinkHbIdMutationOptions = <TError = ErrorType<PutFixLinkHbId400 | PutFixLinkHbId401 | PutFixLinkHbId403 | PutFixLinkHbId404 | PutFixLinkHbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkHbId>>, TError,{id: string;data: HBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLinkHbId>>, TError,{id: string;data: HBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLinkHbId>>, {id: string;data: HBSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLinkHbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLinkHbIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLinkHbId>>>
    export type PutFixLinkHbIdMutationBody = HBSchema
    export type PutFixLinkHbIdMutationError = ErrorType<PutFixLinkHbId400 | PutFixLinkHbId401 | PutFixLinkHbId403 | PutFixLinkHbId404 | PutFixLinkHbId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLinkHbId = <TError = ErrorType<PutFixLinkHbId400 | PutFixLinkHbId401 | PutFixLinkHbId403 | PutFixLinkHbId404 | PutFixLinkHbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkHbId>>, TError,{id: string;data: HBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLinkHbId>>,
        TError,
        {id: string;data: HBSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLinkHbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLinkPhb = (
    params: GetFixLinkPhbParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLinkPhb200>(
      {url: `/fix/link/phb`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLinkPhbQueryKey = (params: GetFixLinkPhbParams,) => {
    return [`/fix/link/phb`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLinkPhbInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkPhb>>>, TError = ErrorType<GetFixLinkPhb400 | GetFixLinkPhb404 | GetFixLinkPhb500>>(params: GetFixLinkPhbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkPhb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkPhbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkPhb>>> = ({ signal }) => getFixLinkPhb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkPhb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkPhbInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkPhb>>>
export type GetFixLinkPhbInfiniteQueryError = ErrorType<GetFixLinkPhb400 | GetFixLinkPhb404 | GetFixLinkPhb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkPhbInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkPhb>>>, TError = ErrorType<GetFixLinkPhb400 | GetFixLinkPhb404 | GetFixLinkPhb500>>(
 params: GetFixLinkPhbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkPhb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkPhbInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkPhbQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkPhb>>, TError = ErrorType<GetFixLinkPhb400 | GetFixLinkPhb404 | GetFixLinkPhb500>>(params: GetFixLinkPhbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkPhb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkPhbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkPhb>>> = ({ signal }) => getFixLinkPhb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkPhb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkPhbQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkPhb>>>
export type GetFixLinkPhbQueryError = ErrorType<GetFixLinkPhb400 | GetFixLinkPhb404 | GetFixLinkPhb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkPhb = <TData = Awaited<ReturnType<typeof getFixLinkPhb>>, TError = ErrorType<GetFixLinkPhb400 | GetFixLinkPhb404 | GetFixLinkPhb500>>(
 params: GetFixLinkPhbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkPhb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkPhbQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLinkPhb = (
    pHBSchema: PHBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<PHBSchema>(
      {url: `/fix/link/phb`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pHBSchema
    },
      options);
    }
  


export const getPostFixLinkPhbMutationOptions = <TError = ErrorType<PostFixLinkPhb400 | PostFixLinkPhb401 | PostFixLinkPhb403 | PostFixLinkPhb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkPhb>>, TError,{data: PHBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLinkPhb>>, TError,{data: PHBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLinkPhb>>, {data: PHBSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLinkPhb(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLinkPhbMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLinkPhb>>>
    export type PostFixLinkPhbMutationBody = PHBSchema
    export type PostFixLinkPhbMutationError = ErrorType<PostFixLinkPhb400 | PostFixLinkPhb401 | PostFixLinkPhb403 | PostFixLinkPhb500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLinkPhb = <TError = ErrorType<PostFixLinkPhb400 | PostFixLinkPhb401 | PostFixLinkPhb403 | PostFixLinkPhb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkPhb>>, TError,{data: PHBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLinkPhb>>,
        TError,
        {data: PHBSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLinkPhbMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLinkPhbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<PHBSchema>(
      {url: `/fix/link/phb/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLinkPhbIdMutationOptions = <TError = ErrorType<DeleteFixLinkPhbId400 | DeleteFixLinkPhbId401 | DeleteFixLinkPhbId403 | DeleteFixLinkPhbId404 | DeleteFixLinkPhbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkPhbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkPhbId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLinkPhbId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLinkPhbId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLinkPhbIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLinkPhbId>>>
    
    export type DeleteFixLinkPhbIdMutationError = ErrorType<DeleteFixLinkPhbId400 | DeleteFixLinkPhbId401 | DeleteFixLinkPhbId403 | DeleteFixLinkPhbId404 | DeleteFixLinkPhbId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLinkPhbId = <TError = ErrorType<DeleteFixLinkPhbId400 | DeleteFixLinkPhbId401 | DeleteFixLinkPhbId403 | DeleteFixLinkPhbId404 | DeleteFixLinkPhbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkPhbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLinkPhbId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLinkPhbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLinkPhbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<PHBSchema>(
      {url: `/fix/link/phb/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLinkPhbIdQueryKey = (id: string,) => {
    return [`/fix/link/phb/${id}`] as const;
    }

    
export const getGetFixLinkPhbIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkPhbId>>>, TError = ErrorType<GetFixLinkPhbId404 | GetFixLinkPhbId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkPhbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkPhbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkPhbId>>> = ({ signal }) => getFixLinkPhbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkPhbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkPhbIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkPhbId>>>
export type GetFixLinkPhbIdInfiniteQueryError = ErrorType<GetFixLinkPhbId404 | GetFixLinkPhbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkPhbIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkPhbId>>>, TError = ErrorType<GetFixLinkPhbId404 | GetFixLinkPhbId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkPhbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkPhbIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkPhbIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkPhbId>>, TError = ErrorType<GetFixLinkPhbId404 | GetFixLinkPhbId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkPhbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkPhbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkPhbId>>> = ({ signal }) => getFixLinkPhbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkPhbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkPhbIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkPhbId>>>
export type GetFixLinkPhbIdQueryError = ErrorType<GetFixLinkPhbId404 | GetFixLinkPhbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkPhbId = <TData = Awaited<ReturnType<typeof getFixLinkPhbId>>, TError = ErrorType<GetFixLinkPhbId404 | GetFixLinkPhbId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkPhbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkPhbIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLinkPhbId = (
    id: string,
    pHBSchemaOptional: PHBSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<PHBSchema>(
      {url: `/fix/link/phb/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: pHBSchemaOptional
    },
      options);
    }
  


export const getPatchFixLinkPhbIdMutationOptions = <TError = ErrorType<PatchFixLinkPhbId400 | PatchFixLinkPhbId401 | PatchFixLinkPhbId403 | PatchFixLinkPhbId404 | PatchFixLinkPhbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkPhbId>>, TError,{id: string;data: PHBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkPhbId>>, TError,{id: string;data: PHBSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLinkPhbId>>, {id: string;data: PHBSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLinkPhbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLinkPhbIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLinkPhbId>>>
    export type PatchFixLinkPhbIdMutationBody = PHBSchemaOptional
    export type PatchFixLinkPhbIdMutationError = ErrorType<PatchFixLinkPhbId400 | PatchFixLinkPhbId401 | PatchFixLinkPhbId403 | PatchFixLinkPhbId404 | PatchFixLinkPhbId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLinkPhbId = <TError = ErrorType<PatchFixLinkPhbId400 | PatchFixLinkPhbId401 | PatchFixLinkPhbId403 | PatchFixLinkPhbId404 | PatchFixLinkPhbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkPhbId>>, TError,{id: string;data: PHBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLinkPhbId>>,
        TError,
        {id: string;data: PHBSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLinkPhbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLinkPhbId = (
    id: string,
    pHBSchema: PHBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<PHBSchema>(
      {url: `/fix/link/phb/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: pHBSchema
    },
      options);
    }
  


export const getPutFixLinkPhbIdMutationOptions = <TError = ErrorType<PutFixLinkPhbId400 | PutFixLinkPhbId401 | PutFixLinkPhbId403 | PutFixLinkPhbId404 | PutFixLinkPhbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkPhbId>>, TError,{id: string;data: PHBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLinkPhbId>>, TError,{id: string;data: PHBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLinkPhbId>>, {id: string;data: PHBSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLinkPhbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLinkPhbIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLinkPhbId>>>
    export type PutFixLinkPhbIdMutationBody = PHBSchema
    export type PutFixLinkPhbIdMutationError = ErrorType<PutFixLinkPhbId400 | PutFixLinkPhbId401 | PutFixLinkPhbId403 | PutFixLinkPhbId404 | PutFixLinkPhbId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLinkPhbId = <TError = ErrorType<PutFixLinkPhbId400 | PutFixLinkPhbId401 | PutFixLinkPhbId403 | PutFixLinkPhbId404 | PutFixLinkPhbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkPhbId>>, TError,{id: string;data: PHBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLinkPhbId>>,
        TError,
        {id: string;data: PHBSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLinkPhbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLinkSppb = (
    params: GetFixLinkSppbParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLinkSppb200>(
      {url: `/fix/link/sppb`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLinkSppbQueryKey = (params: GetFixLinkSppbParams,) => {
    return [`/fix/link/sppb`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLinkSppbInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkSppb>>>, TError = ErrorType<GetFixLinkSppb400 | GetFixLinkSppb404 | GetFixLinkSppb500>>(params: GetFixLinkSppbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkSppb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkSppbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkSppb>>> = ({ signal }) => getFixLinkSppb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkSppb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkSppbInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkSppb>>>
export type GetFixLinkSppbInfiniteQueryError = ErrorType<GetFixLinkSppb400 | GetFixLinkSppb404 | GetFixLinkSppb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkSppbInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkSppb>>>, TError = ErrorType<GetFixLinkSppb400 | GetFixLinkSppb404 | GetFixLinkSppb500>>(
 params: GetFixLinkSppbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkSppb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkSppbInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkSppbQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkSppb>>, TError = ErrorType<GetFixLinkSppb400 | GetFixLinkSppb404 | GetFixLinkSppb500>>(params: GetFixLinkSppbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkSppb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkSppbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkSppb>>> = ({ signal }) => getFixLinkSppb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkSppb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkSppbQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkSppb>>>
export type GetFixLinkSppbQueryError = ErrorType<GetFixLinkSppb400 | GetFixLinkSppb404 | GetFixLinkSppb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkSppb = <TData = Awaited<ReturnType<typeof getFixLinkSppb>>, TError = ErrorType<GetFixLinkSppb400 | GetFixLinkSppb404 | GetFixLinkSppb500>>(
 params: GetFixLinkSppbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkSppb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkSppbQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLinkSppb = (
    sPPBSchema: SPPBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SPPBSchema>(
      {url: `/fix/link/sppb`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sPPBSchema
    },
      options);
    }
  


export const getPostFixLinkSppbMutationOptions = <TError = ErrorType<PostFixLinkSppb400 | PostFixLinkSppb401 | PostFixLinkSppb403 | PostFixLinkSppb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkSppb>>, TError,{data: SPPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLinkSppb>>, TError,{data: SPPBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLinkSppb>>, {data: SPPBSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLinkSppb(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLinkSppbMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLinkSppb>>>
    export type PostFixLinkSppbMutationBody = SPPBSchema
    export type PostFixLinkSppbMutationError = ErrorType<PostFixLinkSppb400 | PostFixLinkSppb401 | PostFixLinkSppb403 | PostFixLinkSppb500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLinkSppb = <TError = ErrorType<PostFixLinkSppb400 | PostFixLinkSppb401 | PostFixLinkSppb403 | PostFixLinkSppb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkSppb>>, TError,{data: SPPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLinkSppb>>,
        TError,
        {data: SPPBSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLinkSppbMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLinkSppbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SPPBSchema>(
      {url: `/fix/link/sppb/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLinkSppbIdMutationOptions = <TError = ErrorType<DeleteFixLinkSppbId400 | DeleteFixLinkSppbId401 | DeleteFixLinkSppbId403 | DeleteFixLinkSppbId404 | DeleteFixLinkSppbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkSppbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkSppbId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLinkSppbId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLinkSppbId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLinkSppbIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLinkSppbId>>>
    
    export type DeleteFixLinkSppbIdMutationError = ErrorType<DeleteFixLinkSppbId400 | DeleteFixLinkSppbId401 | DeleteFixLinkSppbId403 | DeleteFixLinkSppbId404 | DeleteFixLinkSppbId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLinkSppbId = <TError = ErrorType<DeleteFixLinkSppbId400 | DeleteFixLinkSppbId401 | DeleteFixLinkSppbId403 | DeleteFixLinkSppbId404 | DeleteFixLinkSppbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkSppbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLinkSppbId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLinkSppbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLinkSppbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<SPPBSchema>(
      {url: `/fix/link/sppb/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLinkSppbIdQueryKey = (id: string,) => {
    return [`/fix/link/sppb/${id}`] as const;
    }

    
export const getGetFixLinkSppbIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkSppbId>>>, TError = ErrorType<GetFixLinkSppbId404 | GetFixLinkSppbId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkSppbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkSppbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkSppbId>>> = ({ signal }) => getFixLinkSppbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkSppbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkSppbIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkSppbId>>>
export type GetFixLinkSppbIdInfiniteQueryError = ErrorType<GetFixLinkSppbId404 | GetFixLinkSppbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkSppbIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkSppbId>>>, TError = ErrorType<GetFixLinkSppbId404 | GetFixLinkSppbId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkSppbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkSppbIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkSppbIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkSppbId>>, TError = ErrorType<GetFixLinkSppbId404 | GetFixLinkSppbId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkSppbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkSppbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkSppbId>>> = ({ signal }) => getFixLinkSppbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkSppbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkSppbIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkSppbId>>>
export type GetFixLinkSppbIdQueryError = ErrorType<GetFixLinkSppbId404 | GetFixLinkSppbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkSppbId = <TData = Awaited<ReturnType<typeof getFixLinkSppbId>>, TError = ErrorType<GetFixLinkSppbId404 | GetFixLinkSppbId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkSppbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkSppbIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLinkSppbId = (
    id: string,
    sPPBSchemaOptional: SPPBSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SPPBSchema>(
      {url: `/fix/link/sppb/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: sPPBSchemaOptional
    },
      options);
    }
  


export const getPatchFixLinkSppbIdMutationOptions = <TError = ErrorType<PatchFixLinkSppbId400 | PatchFixLinkSppbId401 | PatchFixLinkSppbId403 | PatchFixLinkSppbId404 | PatchFixLinkSppbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkSppbId>>, TError,{id: string;data: SPPBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkSppbId>>, TError,{id: string;data: SPPBSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLinkSppbId>>, {id: string;data: SPPBSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLinkSppbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLinkSppbIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLinkSppbId>>>
    export type PatchFixLinkSppbIdMutationBody = SPPBSchemaOptional
    export type PatchFixLinkSppbIdMutationError = ErrorType<PatchFixLinkSppbId400 | PatchFixLinkSppbId401 | PatchFixLinkSppbId403 | PatchFixLinkSppbId404 | PatchFixLinkSppbId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLinkSppbId = <TError = ErrorType<PatchFixLinkSppbId400 | PatchFixLinkSppbId401 | PatchFixLinkSppbId403 | PatchFixLinkSppbId404 | PatchFixLinkSppbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkSppbId>>, TError,{id: string;data: SPPBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLinkSppbId>>,
        TError,
        {id: string;data: SPPBSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLinkSppbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLinkSppbId = (
    id: string,
    sPPBSchema: SPPBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SPPBSchema>(
      {url: `/fix/link/sppb/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: sPPBSchema
    },
      options);
    }
  


export const getPutFixLinkSppbIdMutationOptions = <TError = ErrorType<PutFixLinkSppbId400 | PutFixLinkSppbId401 | PutFixLinkSppbId403 | PutFixLinkSppbId404 | PutFixLinkSppbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkSppbId>>, TError,{id: string;data: SPPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLinkSppbId>>, TError,{id: string;data: SPPBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLinkSppbId>>, {id: string;data: SPPBSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLinkSppbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLinkSppbIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLinkSppbId>>>
    export type PutFixLinkSppbIdMutationBody = SPPBSchema
    export type PutFixLinkSppbIdMutationError = ErrorType<PutFixLinkSppbId400 | PutFixLinkSppbId401 | PutFixLinkSppbId403 | PutFixLinkSppbId404 | PutFixLinkSppbId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLinkSppbId = <TError = ErrorType<PutFixLinkSppbId400 | PutFixLinkSppbId401 | PutFixLinkSppbId403 | PutFixLinkSppbId404 | PutFixLinkSppbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkSppbId>>, TError,{id: string;data: SPPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLinkSppbId>>,
        TError,
        {id: string;data: SPPBSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLinkSppbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixLinkVspb = (
    params: GetFixLinkVspbParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixLinkVspb200>(
      {url: `/fix/link/vspb`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixLinkVspbQueryKey = (params: GetFixLinkVspbParams,) => {
    return [`/fix/link/vspb`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixLinkVspbInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkVspb>>>, TError = ErrorType<GetFixLinkVspb400 | GetFixLinkVspb404 | GetFixLinkVspb500>>(params: GetFixLinkVspbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkVspb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkVspbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkVspb>>> = ({ signal }) => getFixLinkVspb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkVspb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkVspbInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkVspb>>>
export type GetFixLinkVspbInfiniteQueryError = ErrorType<GetFixLinkVspb400 | GetFixLinkVspb404 | GetFixLinkVspb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkVspbInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkVspb>>>, TError = ErrorType<GetFixLinkVspb400 | GetFixLinkVspb404 | GetFixLinkVspb500>>(
 params: GetFixLinkVspbParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkVspb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkVspbInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkVspbQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkVspb>>, TError = ErrorType<GetFixLinkVspb400 | GetFixLinkVspb404 | GetFixLinkVspb500>>(params: GetFixLinkVspbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkVspb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkVspbQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkVspb>>> = ({ signal }) => getFixLinkVspb(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkVspb>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkVspbQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkVspb>>>
export type GetFixLinkVspbQueryError = ErrorType<GetFixLinkVspb400 | GetFixLinkVspb404 | GetFixLinkVspb500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixLinkVspb = <TData = Awaited<ReturnType<typeof getFixLinkVspb>>, TError = ErrorType<GetFixLinkVspb400 | GetFixLinkVspb404 | GetFixLinkVspb500>>(
 params: GetFixLinkVspbParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkVspb>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkVspbQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixLinkVspb = (
    vSPBSchema: VSPBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VSPBSchema>(
      {url: `/fix/link/vspb`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: vSPBSchema
    },
      options);
    }
  


export const getPostFixLinkVspbMutationOptions = <TError = ErrorType<PostFixLinkVspb400 | PostFixLinkVspb401 | PostFixLinkVspb403 | PostFixLinkVspb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkVspb>>, TError,{data: VSPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixLinkVspb>>, TError,{data: VSPBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixLinkVspb>>, {data: VSPBSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixLinkVspb(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixLinkVspbMutationResult = NonNullable<Awaited<ReturnType<typeof postFixLinkVspb>>>
    export type PostFixLinkVspbMutationBody = VSPBSchema
    export type PostFixLinkVspbMutationError = ErrorType<PostFixLinkVspb400 | PostFixLinkVspb401 | PostFixLinkVspb403 | PostFixLinkVspb500>

    /**
 * @summary Create a new instance
 */
export const usePostFixLinkVspb = <TError = ErrorType<PostFixLinkVspb400 | PostFixLinkVspb401 | PostFixLinkVspb403 | PostFixLinkVspb500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixLinkVspb>>, TError,{data: VSPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixLinkVspb>>,
        TError,
        {data: VSPBSchema},
        TContext
      > => {

      const mutationOptions = getPostFixLinkVspbMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixLinkVspbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VSPBSchema>(
      {url: `/fix/link/vspb/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixLinkVspbIdMutationOptions = <TError = ErrorType<DeleteFixLinkVspbId400 | DeleteFixLinkVspbId401 | DeleteFixLinkVspbId403 | DeleteFixLinkVspbId404 | DeleteFixLinkVspbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkVspbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkVspbId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixLinkVspbId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixLinkVspbId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixLinkVspbIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixLinkVspbId>>>
    
    export type DeleteFixLinkVspbIdMutationError = ErrorType<DeleteFixLinkVspbId400 | DeleteFixLinkVspbId401 | DeleteFixLinkVspbId403 | DeleteFixLinkVspbId404 | DeleteFixLinkVspbId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixLinkVspbId = <TError = ErrorType<DeleteFixLinkVspbId400 | DeleteFixLinkVspbId401 | DeleteFixLinkVspbId403 | DeleteFixLinkVspbId404 | DeleteFixLinkVspbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixLinkVspbId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixLinkVspbId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixLinkVspbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixLinkVspbId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<VSPBSchema>(
      {url: `/fix/link/vspb/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixLinkVspbIdQueryKey = (id: string,) => {
    return [`/fix/link/vspb/${id}`] as const;
    }

    
export const getGetFixLinkVspbIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkVspbId>>>, TError = ErrorType<GetFixLinkVspbId404 | GetFixLinkVspbId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkVspbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkVspbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkVspbId>>> = ({ signal }) => getFixLinkVspbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkVspbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkVspbIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkVspbId>>>
export type GetFixLinkVspbIdInfiniteQueryError = ErrorType<GetFixLinkVspbId404 | GetFixLinkVspbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkVspbIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixLinkVspbId>>>, TError = ErrorType<GetFixLinkVspbId404 | GetFixLinkVspbId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixLinkVspbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkVspbIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixLinkVspbIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixLinkVspbId>>, TError = ErrorType<GetFixLinkVspbId404 | GetFixLinkVspbId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkVspbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixLinkVspbIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixLinkVspbId>>> = ({ signal }) => getFixLinkVspbId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixLinkVspbId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixLinkVspbIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixLinkVspbId>>>
export type GetFixLinkVspbIdQueryError = ErrorType<GetFixLinkVspbId404 | GetFixLinkVspbId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixLinkVspbId = <TData = Awaited<ReturnType<typeof getFixLinkVspbId>>, TError = ErrorType<GetFixLinkVspbId404 | GetFixLinkVspbId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixLinkVspbId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixLinkVspbIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixLinkVspbId = (
    id: string,
    vSPBSchemaOptional: VSPBSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VSPBSchema>(
      {url: `/fix/link/vspb/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: vSPBSchemaOptional
    },
      options);
    }
  


export const getPatchFixLinkVspbIdMutationOptions = <TError = ErrorType<PatchFixLinkVspbId400 | PatchFixLinkVspbId401 | PatchFixLinkVspbId403 | PatchFixLinkVspbId404 | PatchFixLinkVspbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkVspbId>>, TError,{id: string;data: VSPBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkVspbId>>, TError,{id: string;data: VSPBSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixLinkVspbId>>, {id: string;data: VSPBSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixLinkVspbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixLinkVspbIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixLinkVspbId>>>
    export type PatchFixLinkVspbIdMutationBody = VSPBSchemaOptional
    export type PatchFixLinkVspbIdMutationError = ErrorType<PatchFixLinkVspbId400 | PatchFixLinkVspbId401 | PatchFixLinkVspbId403 | PatchFixLinkVspbId404 | PatchFixLinkVspbId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixLinkVspbId = <TError = ErrorType<PatchFixLinkVspbId400 | PatchFixLinkVspbId401 | PatchFixLinkVspbId403 | PatchFixLinkVspbId404 | PatchFixLinkVspbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixLinkVspbId>>, TError,{id: string;data: VSPBSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixLinkVspbId>>,
        TError,
        {id: string;data: VSPBSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixLinkVspbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixLinkVspbId = (
    id: string,
    vSPBSchema: VSPBSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VSPBSchema>(
      {url: `/fix/link/vspb/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: vSPBSchema
    },
      options);
    }
  


export const getPutFixLinkVspbIdMutationOptions = <TError = ErrorType<PutFixLinkVspbId400 | PutFixLinkVspbId401 | PutFixLinkVspbId403 | PutFixLinkVspbId404 | PutFixLinkVspbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkVspbId>>, TError,{id: string;data: VSPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixLinkVspbId>>, TError,{id: string;data: VSPBSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixLinkVspbId>>, {id: string;data: VSPBSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixLinkVspbId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixLinkVspbIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixLinkVspbId>>>
    export type PutFixLinkVspbIdMutationBody = VSPBSchema
    export type PutFixLinkVspbIdMutationError = ErrorType<PutFixLinkVspbId400 | PutFixLinkVspbId401 | PutFixLinkVspbId403 | PutFixLinkVspbId404 | PutFixLinkVspbId500>

    /**
 * @summary Update an instance
 */
export const usePutFixLinkVspbId = <TError = ErrorType<PutFixLinkVspbId400 | PutFixLinkVspbId401 | PutFixLinkVspbId403 | PutFixLinkVspbId404 | PutFixLinkVspbId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixLinkVspbId>>, TError,{id: string;data: VSPBSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixLinkVspbId>>,
        TError,
        {id: string;data: VSPBSchema},
        TContext
      > => {

      const mutationOptions = getPutFixLinkVspbIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixMetaDefinitie = (
    params: GetFixMetaDefinitieParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixMetaDefinitie200>(
      {url: `/fix/meta/definitie`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixMetaDefinitieQueryKey = (params: GetFixMetaDefinitieParams,) => {
    return [`/fix/meta/definitie`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixMetaDefinitieInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaDefinitie>>>, TError = ErrorType<GetFixMetaDefinitie400 | GetFixMetaDefinitie404 | GetFixMetaDefinitie500>>(params: GetFixMetaDefinitieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaDefinitieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaDefinitie>>> = ({ signal }) => getFixMetaDefinitie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaDefinitieInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaDefinitie>>>
export type GetFixMetaDefinitieInfiniteQueryError = ErrorType<GetFixMetaDefinitie400 | GetFixMetaDefinitie404 | GetFixMetaDefinitie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaDefinitieInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaDefinitie>>>, TError = ErrorType<GetFixMetaDefinitie400 | GetFixMetaDefinitie404 | GetFixMetaDefinitie500>>(
 params: GetFixMetaDefinitieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaDefinitieInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaDefinitieQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError = ErrorType<GetFixMetaDefinitie400 | GetFixMetaDefinitie404 | GetFixMetaDefinitie500>>(params: GetFixMetaDefinitieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaDefinitieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaDefinitie>>> = ({ signal }) => getFixMetaDefinitie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaDefinitieQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaDefinitie>>>
export type GetFixMetaDefinitieQueryError = ErrorType<GetFixMetaDefinitie400 | GetFixMetaDefinitie404 | GetFixMetaDefinitie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaDefinitie = <TData = Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError = ErrorType<GetFixMetaDefinitie400 | GetFixMetaDefinitie404 | GetFixMetaDefinitie500>>(
 params: GetFixMetaDefinitieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaDefinitieQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixMetaDefinitie = (
    definitieSchema: DefinitieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<DefinitieSchema>(
      {url: `/fix/meta/definitie`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: definitieSchema
    },
      options);
    }
  


export const getPostFixMetaDefinitieMutationOptions = <TError = ErrorType<PostFixMetaDefinitie400 | PostFixMetaDefinitie401 | PostFixMetaDefinitie403 | PostFixMetaDefinitie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaDefinitie>>, TError,{data: DefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixMetaDefinitie>>, TError,{data: DefinitieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixMetaDefinitie>>, {data: DefinitieSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixMetaDefinitie(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixMetaDefinitieMutationResult = NonNullable<Awaited<ReturnType<typeof postFixMetaDefinitie>>>
    export type PostFixMetaDefinitieMutationBody = DefinitieSchema
    export type PostFixMetaDefinitieMutationError = ErrorType<PostFixMetaDefinitie400 | PostFixMetaDefinitie401 | PostFixMetaDefinitie403 | PostFixMetaDefinitie500>

    /**
 * @summary Create a new instance
 */
export const usePostFixMetaDefinitie = <TError = ErrorType<PostFixMetaDefinitie400 | PostFixMetaDefinitie401 | PostFixMetaDefinitie403 | PostFixMetaDefinitie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaDefinitie>>, TError,{data: DefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixMetaDefinitie>>,
        TError,
        {data: DefinitieSchema},
        TContext
      > => {

      const mutationOptions = getPostFixMetaDefinitieMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixMetaDefinitieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<DefinitieSchema>(
      {url: `/fix/meta/definitie/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixMetaDefinitieIdMutationOptions = <TError = ErrorType<DeleteFixMetaDefinitieId400 | DeleteFixMetaDefinitieId401 | DeleteFixMetaDefinitieId403 | DeleteFixMetaDefinitieId404 | DeleteFixMetaDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaDefinitieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaDefinitieId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixMetaDefinitieId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixMetaDefinitieId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixMetaDefinitieIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixMetaDefinitieId>>>
    
    export type DeleteFixMetaDefinitieIdMutationError = ErrorType<DeleteFixMetaDefinitieId400 | DeleteFixMetaDefinitieId401 | DeleteFixMetaDefinitieId403 | DeleteFixMetaDefinitieId404 | DeleteFixMetaDefinitieId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixMetaDefinitieId = <TError = ErrorType<DeleteFixMetaDefinitieId400 | DeleteFixMetaDefinitieId401 | DeleteFixMetaDefinitieId403 | DeleteFixMetaDefinitieId404 | DeleteFixMetaDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaDefinitieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixMetaDefinitieId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixMetaDefinitieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixMetaDefinitieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<DefinitieSchema>(
      {url: `/fix/meta/definitie/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixMetaDefinitieIdQueryKey = (id: string,) => {
    return [`/fix/meta/definitie/${id}`] as const;
    }

    
export const getGetFixMetaDefinitieIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaDefinitieId>>>, TError = ErrorType<GetFixMetaDefinitieId404 | GetFixMetaDefinitieId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaDefinitieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaDefinitieId>>> = ({ signal }) => getFixMetaDefinitieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaDefinitieIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaDefinitieId>>>
export type GetFixMetaDefinitieIdInfiniteQueryError = ErrorType<GetFixMetaDefinitieId404 | GetFixMetaDefinitieId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaDefinitieIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaDefinitieId>>>, TError = ErrorType<GetFixMetaDefinitieId404 | GetFixMetaDefinitieId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaDefinitieIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaDefinitieIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError = ErrorType<GetFixMetaDefinitieId404 | GetFixMetaDefinitieId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaDefinitieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaDefinitieId>>> = ({ signal }) => getFixMetaDefinitieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaDefinitieIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaDefinitieId>>>
export type GetFixMetaDefinitieIdQueryError = ErrorType<GetFixMetaDefinitieId404 | GetFixMetaDefinitieId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaDefinitieId = <TData = Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError = ErrorType<GetFixMetaDefinitieId404 | GetFixMetaDefinitieId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaDefinitieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaDefinitieIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixMetaDefinitieId = (
    id: string,
    definitieSchemaOptional: DefinitieSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<DefinitieSchema>(
      {url: `/fix/meta/definitie/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: definitieSchemaOptional
    },
      options);
    }
  


export const getPatchFixMetaDefinitieIdMutationOptions = <TError = ErrorType<PatchFixMetaDefinitieId400 | PatchFixMetaDefinitieId401 | PatchFixMetaDefinitieId403 | PatchFixMetaDefinitieId404 | PatchFixMetaDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaDefinitieId>>, TError,{id: string;data: DefinitieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaDefinitieId>>, TError,{id: string;data: DefinitieSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixMetaDefinitieId>>, {id: string;data: DefinitieSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixMetaDefinitieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixMetaDefinitieIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixMetaDefinitieId>>>
    export type PatchFixMetaDefinitieIdMutationBody = DefinitieSchemaOptional
    export type PatchFixMetaDefinitieIdMutationError = ErrorType<PatchFixMetaDefinitieId400 | PatchFixMetaDefinitieId401 | PatchFixMetaDefinitieId403 | PatchFixMetaDefinitieId404 | PatchFixMetaDefinitieId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixMetaDefinitieId = <TError = ErrorType<PatchFixMetaDefinitieId400 | PatchFixMetaDefinitieId401 | PatchFixMetaDefinitieId403 | PatchFixMetaDefinitieId404 | PatchFixMetaDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaDefinitieId>>, TError,{id: string;data: DefinitieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixMetaDefinitieId>>,
        TError,
        {id: string;data: DefinitieSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixMetaDefinitieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixMetaDefinitieId = (
    id: string,
    definitieSchema: DefinitieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<DefinitieSchema>(
      {url: `/fix/meta/definitie/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: definitieSchema
    },
      options);
    }
  


export const getPutFixMetaDefinitieIdMutationOptions = <TError = ErrorType<PutFixMetaDefinitieId400 | PutFixMetaDefinitieId401 | PutFixMetaDefinitieId403 | PutFixMetaDefinitieId404 | PutFixMetaDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaDefinitieId>>, TError,{id: string;data: DefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixMetaDefinitieId>>, TError,{id: string;data: DefinitieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixMetaDefinitieId>>, {id: string;data: DefinitieSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixMetaDefinitieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixMetaDefinitieIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixMetaDefinitieId>>>
    export type PutFixMetaDefinitieIdMutationBody = DefinitieSchema
    export type PutFixMetaDefinitieIdMutationError = ErrorType<PutFixMetaDefinitieId400 | PutFixMetaDefinitieId401 | PutFixMetaDefinitieId403 | PutFixMetaDefinitieId404 | PutFixMetaDefinitieId500>

    /**
 * @summary Update an instance
 */
export const usePutFixMetaDefinitieId = <TError = ErrorType<PutFixMetaDefinitieId400 | PutFixMetaDefinitieId401 | PutFixMetaDefinitieId403 | PutFixMetaDefinitieId404 | PutFixMetaDefinitieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaDefinitieId>>, TError,{id: string;data: DefinitieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixMetaDefinitieId>>,
        TError,
        {id: string;data: DefinitieSchema},
        TContext
      > => {

      const mutationOptions = getPutFixMetaDefinitieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixMetaEmImIssie = (
    params: GetFixMetaEmImIssieParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixMetaEmImIssie200>(
      {url: `/fix/meta/em_im_issie`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixMetaEmImIssieQueryKey = (params: GetFixMetaEmImIssieParams,) => {
    return [`/fix/meta/em_im_issie`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixMetaEmImIssieInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaEmImIssie>>>, TError = ErrorType<GetFixMetaEmImIssie400 | GetFixMetaEmImIssie404 | GetFixMetaEmImIssie500>>(params: GetFixMetaEmImIssieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaEmImIssieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaEmImIssie>>> = ({ signal }) => getFixMetaEmImIssie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaEmImIssieInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaEmImIssie>>>
export type GetFixMetaEmImIssieInfiniteQueryError = ErrorType<GetFixMetaEmImIssie400 | GetFixMetaEmImIssie404 | GetFixMetaEmImIssie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaEmImIssieInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaEmImIssie>>>, TError = ErrorType<GetFixMetaEmImIssie400 | GetFixMetaEmImIssie404 | GetFixMetaEmImIssie500>>(
 params: GetFixMetaEmImIssieParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaEmImIssieInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaEmImIssieQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError = ErrorType<GetFixMetaEmImIssie400 | GetFixMetaEmImIssie404 | GetFixMetaEmImIssie500>>(params: GetFixMetaEmImIssieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaEmImIssieQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaEmImIssie>>> = ({ signal }) => getFixMetaEmImIssie(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaEmImIssieQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaEmImIssie>>>
export type GetFixMetaEmImIssieQueryError = ErrorType<GetFixMetaEmImIssie400 | GetFixMetaEmImIssie404 | GetFixMetaEmImIssie500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaEmImIssie = <TData = Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError = ErrorType<GetFixMetaEmImIssie400 | GetFixMetaEmImIssie404 | GetFixMetaEmImIssie500>>(
 params: GetFixMetaEmImIssieParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssie>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaEmImIssieQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixMetaEmImIssie = (
    emImIssieSchema: EmImIssieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<EmImIssieSchema>(
      {url: `/fix/meta/em_im_issie`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: emImIssieSchema
    },
      options);
    }
  


export const getPostFixMetaEmImIssieMutationOptions = <TError = ErrorType<PostFixMetaEmImIssie400 | PostFixMetaEmImIssie401 | PostFixMetaEmImIssie403 | PostFixMetaEmImIssie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaEmImIssie>>, TError,{data: EmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixMetaEmImIssie>>, TError,{data: EmImIssieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixMetaEmImIssie>>, {data: EmImIssieSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixMetaEmImIssie(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixMetaEmImIssieMutationResult = NonNullable<Awaited<ReturnType<typeof postFixMetaEmImIssie>>>
    export type PostFixMetaEmImIssieMutationBody = EmImIssieSchema
    export type PostFixMetaEmImIssieMutationError = ErrorType<PostFixMetaEmImIssie400 | PostFixMetaEmImIssie401 | PostFixMetaEmImIssie403 | PostFixMetaEmImIssie500>

    /**
 * @summary Create a new instance
 */
export const usePostFixMetaEmImIssie = <TError = ErrorType<PostFixMetaEmImIssie400 | PostFixMetaEmImIssie401 | PostFixMetaEmImIssie403 | PostFixMetaEmImIssie500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaEmImIssie>>, TError,{data: EmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixMetaEmImIssie>>,
        TError,
        {data: EmImIssieSchema},
        TContext
      > => {

      const mutationOptions = getPostFixMetaEmImIssieMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixMetaEmImIssieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<EmImIssieSchema>(
      {url: `/fix/meta/em_im_issie/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixMetaEmImIssieIdMutationOptions = <TError = ErrorType<DeleteFixMetaEmImIssieId400 | DeleteFixMetaEmImIssieId401 | DeleteFixMetaEmImIssieId403 | DeleteFixMetaEmImIssieId404 | DeleteFixMetaEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaEmImIssieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaEmImIssieId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixMetaEmImIssieId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixMetaEmImIssieId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixMetaEmImIssieIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixMetaEmImIssieId>>>
    
    export type DeleteFixMetaEmImIssieIdMutationError = ErrorType<DeleteFixMetaEmImIssieId400 | DeleteFixMetaEmImIssieId401 | DeleteFixMetaEmImIssieId403 | DeleteFixMetaEmImIssieId404 | DeleteFixMetaEmImIssieId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixMetaEmImIssieId = <TError = ErrorType<DeleteFixMetaEmImIssieId400 | DeleteFixMetaEmImIssieId401 | DeleteFixMetaEmImIssieId403 | DeleteFixMetaEmImIssieId404 | DeleteFixMetaEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaEmImIssieId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixMetaEmImIssieId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixMetaEmImIssieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixMetaEmImIssieId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<EmImIssieSchema>(
      {url: `/fix/meta/em_im_issie/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixMetaEmImIssieIdQueryKey = (id: string,) => {
    return [`/fix/meta/em_im_issie/${id}`] as const;
    }

    
export const getGetFixMetaEmImIssieIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>>, TError = ErrorType<GetFixMetaEmImIssieId404 | GetFixMetaEmImIssieId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaEmImIssieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>> = ({ signal }) => getFixMetaEmImIssieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaEmImIssieIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>>
export type GetFixMetaEmImIssieIdInfiniteQueryError = ErrorType<GetFixMetaEmImIssieId404 | GetFixMetaEmImIssieId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaEmImIssieIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>>, TError = ErrorType<GetFixMetaEmImIssieId404 | GetFixMetaEmImIssieId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaEmImIssieIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaEmImIssieIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError = ErrorType<GetFixMetaEmImIssieId404 | GetFixMetaEmImIssieId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaEmImIssieIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>> = ({ signal }) => getFixMetaEmImIssieId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaEmImIssieIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>>
export type GetFixMetaEmImIssieIdQueryError = ErrorType<GetFixMetaEmImIssieId404 | GetFixMetaEmImIssieId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaEmImIssieId = <TData = Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError = ErrorType<GetFixMetaEmImIssieId404 | GetFixMetaEmImIssieId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaEmImIssieId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaEmImIssieIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixMetaEmImIssieId = (
    id: string,
    emImIssieSchemaOptional: EmImIssieSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<EmImIssieSchema>(
      {url: `/fix/meta/em_im_issie/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: emImIssieSchemaOptional
    },
      options);
    }
  


export const getPatchFixMetaEmImIssieIdMutationOptions = <TError = ErrorType<PatchFixMetaEmImIssieId400 | PatchFixMetaEmImIssieId401 | PatchFixMetaEmImIssieId403 | PatchFixMetaEmImIssieId404 | PatchFixMetaEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaEmImIssieId>>, TError,{id: string;data: EmImIssieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaEmImIssieId>>, TError,{id: string;data: EmImIssieSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixMetaEmImIssieId>>, {id: string;data: EmImIssieSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixMetaEmImIssieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixMetaEmImIssieIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixMetaEmImIssieId>>>
    export type PatchFixMetaEmImIssieIdMutationBody = EmImIssieSchemaOptional
    export type PatchFixMetaEmImIssieIdMutationError = ErrorType<PatchFixMetaEmImIssieId400 | PatchFixMetaEmImIssieId401 | PatchFixMetaEmImIssieId403 | PatchFixMetaEmImIssieId404 | PatchFixMetaEmImIssieId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixMetaEmImIssieId = <TError = ErrorType<PatchFixMetaEmImIssieId400 | PatchFixMetaEmImIssieId401 | PatchFixMetaEmImIssieId403 | PatchFixMetaEmImIssieId404 | PatchFixMetaEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaEmImIssieId>>, TError,{id: string;data: EmImIssieSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixMetaEmImIssieId>>,
        TError,
        {id: string;data: EmImIssieSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixMetaEmImIssieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixMetaEmImIssieId = (
    id: string,
    emImIssieSchema: EmImIssieSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<EmImIssieSchema>(
      {url: `/fix/meta/em_im_issie/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: emImIssieSchema
    },
      options);
    }
  


export const getPutFixMetaEmImIssieIdMutationOptions = <TError = ErrorType<PutFixMetaEmImIssieId400 | PutFixMetaEmImIssieId401 | PutFixMetaEmImIssieId403 | PutFixMetaEmImIssieId404 | PutFixMetaEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaEmImIssieId>>, TError,{id: string;data: EmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixMetaEmImIssieId>>, TError,{id: string;data: EmImIssieSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixMetaEmImIssieId>>, {id: string;data: EmImIssieSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixMetaEmImIssieId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixMetaEmImIssieIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixMetaEmImIssieId>>>
    export type PutFixMetaEmImIssieIdMutationBody = EmImIssieSchema
    export type PutFixMetaEmImIssieIdMutationError = ErrorType<PutFixMetaEmImIssieId400 | PutFixMetaEmImIssieId401 | PutFixMetaEmImIssieId403 | PutFixMetaEmImIssieId404 | PutFixMetaEmImIssieId500>

    /**
 * @summary Update an instance
 */
export const usePutFixMetaEmImIssieId = <TError = ErrorType<PutFixMetaEmImIssieId400 | PutFixMetaEmImIssieId401 | PutFixMetaEmImIssieId403 | PutFixMetaEmImIssieId404 | PutFixMetaEmImIssieId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaEmImIssieId>>, TError,{id: string;data: EmImIssieSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixMetaEmImIssieId>>,
        TError,
        {id: string;data: EmImIssieSchema},
        TContext
      > => {

      const mutationOptions = getPutFixMetaEmImIssieIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixMetaOverwegingHoofdstuk = (
    params: GetFixMetaOverwegingHoofdstukParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixMetaOverwegingHoofdstuk200>(
      {url: `/fix/meta/overweging_hoofdstuk`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixMetaOverwegingHoofdstukQueryKey = (params: GetFixMetaOverwegingHoofdstukParams,) => {
    return [`/fix/meta/overweging_hoofdstuk`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixMetaOverwegingHoofdstukInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>>, TError = ErrorType<GetFixMetaOverwegingHoofdstuk400 | GetFixMetaOverwegingHoofdstuk404 | GetFixMetaOverwegingHoofdstuk500>>(params: GetFixMetaOverwegingHoofdstukParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaOverwegingHoofdstukQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>> = ({ signal }) => getFixMetaOverwegingHoofdstuk(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaOverwegingHoofdstukInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>>
export type GetFixMetaOverwegingHoofdstukInfiniteQueryError = ErrorType<GetFixMetaOverwegingHoofdstuk400 | GetFixMetaOverwegingHoofdstuk404 | GetFixMetaOverwegingHoofdstuk500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaOverwegingHoofdstukInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>>, TError = ErrorType<GetFixMetaOverwegingHoofdstuk400 | GetFixMetaOverwegingHoofdstuk404 | GetFixMetaOverwegingHoofdstuk500>>(
 params: GetFixMetaOverwegingHoofdstukParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaOverwegingHoofdstukInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaOverwegingHoofdstukQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError = ErrorType<GetFixMetaOverwegingHoofdstuk400 | GetFixMetaOverwegingHoofdstuk404 | GetFixMetaOverwegingHoofdstuk500>>(params: GetFixMetaOverwegingHoofdstukParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaOverwegingHoofdstukQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>> = ({ signal }) => getFixMetaOverwegingHoofdstuk(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaOverwegingHoofdstukQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>>
export type GetFixMetaOverwegingHoofdstukQueryError = ErrorType<GetFixMetaOverwegingHoofdstuk400 | GetFixMetaOverwegingHoofdstuk404 | GetFixMetaOverwegingHoofdstuk500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaOverwegingHoofdstuk = <TData = Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError = ErrorType<GetFixMetaOverwegingHoofdstuk400 | GetFixMetaOverwegingHoofdstuk404 | GetFixMetaOverwegingHoofdstuk500>>(
 params: GetFixMetaOverwegingHoofdstukParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstuk>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaOverwegingHoofdstukQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixMetaOverwegingHoofdstuk = (
    overwegingHoofdstukSchema: OverwegingHoofdstukSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<OverwegingHoofdstukSchema>(
      {url: `/fix/meta/overweging_hoofdstuk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: overwegingHoofdstukSchema
    },
      options);
    }
  


export const getPostFixMetaOverwegingHoofdstukMutationOptions = <TError = ErrorType<PostFixMetaOverwegingHoofdstuk400 | PostFixMetaOverwegingHoofdstuk401 | PostFixMetaOverwegingHoofdstuk403 | PostFixMetaOverwegingHoofdstuk500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaOverwegingHoofdstuk>>, TError,{data: OverwegingHoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixMetaOverwegingHoofdstuk>>, TError,{data: OverwegingHoofdstukSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixMetaOverwegingHoofdstuk>>, {data: OverwegingHoofdstukSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixMetaOverwegingHoofdstuk(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixMetaOverwegingHoofdstukMutationResult = NonNullable<Awaited<ReturnType<typeof postFixMetaOverwegingHoofdstuk>>>
    export type PostFixMetaOverwegingHoofdstukMutationBody = OverwegingHoofdstukSchema
    export type PostFixMetaOverwegingHoofdstukMutationError = ErrorType<PostFixMetaOverwegingHoofdstuk400 | PostFixMetaOverwegingHoofdstuk401 | PostFixMetaOverwegingHoofdstuk403 | PostFixMetaOverwegingHoofdstuk500>

    /**
 * @summary Create a new instance
 */
export const usePostFixMetaOverwegingHoofdstuk = <TError = ErrorType<PostFixMetaOverwegingHoofdstuk400 | PostFixMetaOverwegingHoofdstuk401 | PostFixMetaOverwegingHoofdstuk403 | PostFixMetaOverwegingHoofdstuk500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaOverwegingHoofdstuk>>, TError,{data: OverwegingHoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixMetaOverwegingHoofdstuk>>,
        TError,
        {data: OverwegingHoofdstukSchema},
        TContext
      > => {

      const mutationOptions = getPostFixMetaOverwegingHoofdstukMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixMetaOverwegingHoofdstukId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<OverwegingHoofdstukSchema>(
      {url: `/fix/meta/overweging_hoofdstuk/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixMetaOverwegingHoofdstukIdMutationOptions = <TError = ErrorType<DeleteFixMetaOverwegingHoofdstukId400 | DeleteFixMetaOverwegingHoofdstukId401 | DeleteFixMetaOverwegingHoofdstukId403 | DeleteFixMetaOverwegingHoofdstukId404 | DeleteFixMetaOverwegingHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaOverwegingHoofdstukId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaOverwegingHoofdstukId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixMetaOverwegingHoofdstukId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixMetaOverwegingHoofdstukId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixMetaOverwegingHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixMetaOverwegingHoofdstukId>>>
    
    export type DeleteFixMetaOverwegingHoofdstukIdMutationError = ErrorType<DeleteFixMetaOverwegingHoofdstukId400 | DeleteFixMetaOverwegingHoofdstukId401 | DeleteFixMetaOverwegingHoofdstukId403 | DeleteFixMetaOverwegingHoofdstukId404 | DeleteFixMetaOverwegingHoofdstukId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixMetaOverwegingHoofdstukId = <TError = ErrorType<DeleteFixMetaOverwegingHoofdstukId400 | DeleteFixMetaOverwegingHoofdstukId401 | DeleteFixMetaOverwegingHoofdstukId403 | DeleteFixMetaOverwegingHoofdstukId404 | DeleteFixMetaOverwegingHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaOverwegingHoofdstukId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixMetaOverwegingHoofdstukId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixMetaOverwegingHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixMetaOverwegingHoofdstukId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<OverwegingHoofdstukSchema>(
      {url: `/fix/meta/overweging_hoofdstuk/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixMetaOverwegingHoofdstukIdQueryKey = (id: string,) => {
    return [`/fix/meta/overweging_hoofdstuk/${id}`] as const;
    }

    
export const getGetFixMetaOverwegingHoofdstukIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>>, TError = ErrorType<GetFixMetaOverwegingHoofdstukId404 | GetFixMetaOverwegingHoofdstukId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaOverwegingHoofdstukIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>> = ({ signal }) => getFixMetaOverwegingHoofdstukId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaOverwegingHoofdstukIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>>
export type GetFixMetaOverwegingHoofdstukIdInfiniteQueryError = ErrorType<GetFixMetaOverwegingHoofdstukId404 | GetFixMetaOverwegingHoofdstukId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaOverwegingHoofdstukIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>>, TError = ErrorType<GetFixMetaOverwegingHoofdstukId404 | GetFixMetaOverwegingHoofdstukId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaOverwegingHoofdstukIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaOverwegingHoofdstukIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError = ErrorType<GetFixMetaOverwegingHoofdstukId404 | GetFixMetaOverwegingHoofdstukId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaOverwegingHoofdstukIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>> = ({ signal }) => getFixMetaOverwegingHoofdstukId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaOverwegingHoofdstukIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>>
export type GetFixMetaOverwegingHoofdstukIdQueryError = ErrorType<GetFixMetaOverwegingHoofdstukId404 | GetFixMetaOverwegingHoofdstukId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaOverwegingHoofdstukId = <TData = Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError = ErrorType<GetFixMetaOverwegingHoofdstukId404 | GetFixMetaOverwegingHoofdstukId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaOverwegingHoofdstukId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaOverwegingHoofdstukIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixMetaOverwegingHoofdstukId = (
    id: string,
    overwegingHoofdstukSchemaOptional: OverwegingHoofdstukSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<OverwegingHoofdstukSchema>(
      {url: `/fix/meta/overweging_hoofdstuk/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: overwegingHoofdstukSchemaOptional
    },
      options);
    }
  


export const getPatchFixMetaOverwegingHoofdstukIdMutationOptions = <TError = ErrorType<PatchFixMetaOverwegingHoofdstukId400 | PatchFixMetaOverwegingHoofdstukId401 | PatchFixMetaOverwegingHoofdstukId403 | PatchFixMetaOverwegingHoofdstukId404 | PatchFixMetaOverwegingHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaOverwegingHoofdstukId>>, TError,{id: string;data: OverwegingHoofdstukSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaOverwegingHoofdstukId>>, TError,{id: string;data: OverwegingHoofdstukSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixMetaOverwegingHoofdstukId>>, {id: string;data: OverwegingHoofdstukSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixMetaOverwegingHoofdstukId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixMetaOverwegingHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixMetaOverwegingHoofdstukId>>>
    export type PatchFixMetaOverwegingHoofdstukIdMutationBody = OverwegingHoofdstukSchemaOptional
    export type PatchFixMetaOverwegingHoofdstukIdMutationError = ErrorType<PatchFixMetaOverwegingHoofdstukId400 | PatchFixMetaOverwegingHoofdstukId401 | PatchFixMetaOverwegingHoofdstukId403 | PatchFixMetaOverwegingHoofdstukId404 | PatchFixMetaOverwegingHoofdstukId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixMetaOverwegingHoofdstukId = <TError = ErrorType<PatchFixMetaOverwegingHoofdstukId400 | PatchFixMetaOverwegingHoofdstukId401 | PatchFixMetaOverwegingHoofdstukId403 | PatchFixMetaOverwegingHoofdstukId404 | PatchFixMetaOverwegingHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaOverwegingHoofdstukId>>, TError,{id: string;data: OverwegingHoofdstukSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixMetaOverwegingHoofdstukId>>,
        TError,
        {id: string;data: OverwegingHoofdstukSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixMetaOverwegingHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixMetaOverwegingHoofdstukId = (
    id: string,
    overwegingHoofdstukSchema: OverwegingHoofdstukSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<OverwegingHoofdstukSchema>(
      {url: `/fix/meta/overweging_hoofdstuk/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: overwegingHoofdstukSchema
    },
      options);
    }
  


export const getPutFixMetaOverwegingHoofdstukIdMutationOptions = <TError = ErrorType<PutFixMetaOverwegingHoofdstukId400 | PutFixMetaOverwegingHoofdstukId401 | PutFixMetaOverwegingHoofdstukId403 | PutFixMetaOverwegingHoofdstukId404 | PutFixMetaOverwegingHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaOverwegingHoofdstukId>>, TError,{id: string;data: OverwegingHoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixMetaOverwegingHoofdstukId>>, TError,{id: string;data: OverwegingHoofdstukSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixMetaOverwegingHoofdstukId>>, {id: string;data: OverwegingHoofdstukSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixMetaOverwegingHoofdstukId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixMetaOverwegingHoofdstukIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixMetaOverwegingHoofdstukId>>>
    export type PutFixMetaOverwegingHoofdstukIdMutationBody = OverwegingHoofdstukSchema
    export type PutFixMetaOverwegingHoofdstukIdMutationError = ErrorType<PutFixMetaOverwegingHoofdstukId400 | PutFixMetaOverwegingHoofdstukId401 | PutFixMetaOverwegingHoofdstukId403 | PutFixMetaOverwegingHoofdstukId404 | PutFixMetaOverwegingHoofdstukId500>

    /**
 * @summary Update an instance
 */
export const usePutFixMetaOverwegingHoofdstukId = <TError = ErrorType<PutFixMetaOverwegingHoofdstukId400 | PutFixMetaOverwegingHoofdstukId401 | PutFixMetaOverwegingHoofdstukId403 | PutFixMetaOverwegingHoofdstukId404 | PutFixMetaOverwegingHoofdstukId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaOverwegingHoofdstukId>>, TError,{id: string;data: OverwegingHoofdstukSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixMetaOverwegingHoofdstukId>>,
        TError,
        {id: string;data: OverwegingHoofdstukSchema},
        TContext
      > => {

      const mutationOptions = getPutFixMetaOverwegingHoofdstukIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixMetaVoorschriftKoppeling = (
    params: GetFixMetaVoorschriftKoppelingParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixMetaVoorschriftKoppeling200>(
      {url: `/fix/meta/voorschrift_koppeling`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixMetaVoorschriftKoppelingQueryKey = (params: GetFixMetaVoorschriftKoppelingParams,) => {
    return [`/fix/meta/voorschrift_koppeling`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixMetaVoorschriftKoppelingInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>>, TError = ErrorType<GetFixMetaVoorschriftKoppeling400 | GetFixMetaVoorschriftKoppeling404 | GetFixMetaVoorschriftKoppeling500>>(params: GetFixMetaVoorschriftKoppelingParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaVoorschriftKoppelingQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>> = ({ signal }) => getFixMetaVoorschriftKoppeling(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaVoorschriftKoppelingInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>>
export type GetFixMetaVoorschriftKoppelingInfiniteQueryError = ErrorType<GetFixMetaVoorschriftKoppeling400 | GetFixMetaVoorschriftKoppeling404 | GetFixMetaVoorschriftKoppeling500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaVoorschriftKoppelingInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>>, TError = ErrorType<GetFixMetaVoorschriftKoppeling400 | GetFixMetaVoorschriftKoppeling404 | GetFixMetaVoorschriftKoppeling500>>(
 params: GetFixMetaVoorschriftKoppelingParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaVoorschriftKoppelingInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaVoorschriftKoppelingQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError = ErrorType<GetFixMetaVoorschriftKoppeling400 | GetFixMetaVoorschriftKoppeling404 | GetFixMetaVoorschriftKoppeling500>>(params: GetFixMetaVoorschriftKoppelingParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaVoorschriftKoppelingQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>> = ({ signal }) => getFixMetaVoorschriftKoppeling(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaVoorschriftKoppelingQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>>
export type GetFixMetaVoorschriftKoppelingQueryError = ErrorType<GetFixMetaVoorschriftKoppeling400 | GetFixMetaVoorschriftKoppeling404 | GetFixMetaVoorschriftKoppeling500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixMetaVoorschriftKoppeling = <TData = Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError = ErrorType<GetFixMetaVoorschriftKoppeling400 | GetFixMetaVoorschriftKoppeling404 | GetFixMetaVoorschriftKoppeling500>>(
 params: GetFixMetaVoorschriftKoppelingParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppeling>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaVoorschriftKoppelingQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixMetaVoorschriftKoppeling = (
    voorschriftKoppelingSchema: VoorschriftKoppelingSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftKoppelingSchema>(
      {url: `/fix/meta/voorschrift_koppeling`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftKoppelingSchema
    },
      options);
    }
  


export const getPostFixMetaVoorschriftKoppelingMutationOptions = <TError = ErrorType<PostFixMetaVoorschriftKoppeling400 | PostFixMetaVoorschriftKoppeling401 | PostFixMetaVoorschriftKoppeling403 | PostFixMetaVoorschriftKoppeling500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaVoorschriftKoppeling>>, TError,{data: VoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixMetaVoorschriftKoppeling>>, TError,{data: VoorschriftKoppelingSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixMetaVoorschriftKoppeling>>, {data: VoorschriftKoppelingSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixMetaVoorschriftKoppeling(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixMetaVoorschriftKoppelingMutationResult = NonNullable<Awaited<ReturnType<typeof postFixMetaVoorschriftKoppeling>>>
    export type PostFixMetaVoorschriftKoppelingMutationBody = VoorschriftKoppelingSchema
    export type PostFixMetaVoorschriftKoppelingMutationError = ErrorType<PostFixMetaVoorschriftKoppeling400 | PostFixMetaVoorschriftKoppeling401 | PostFixMetaVoorschriftKoppeling403 | PostFixMetaVoorschriftKoppeling500>

    /**
 * @summary Create a new instance
 */
export const usePostFixMetaVoorschriftKoppeling = <TError = ErrorType<PostFixMetaVoorschriftKoppeling400 | PostFixMetaVoorschriftKoppeling401 | PostFixMetaVoorschriftKoppeling403 | PostFixMetaVoorschriftKoppeling500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixMetaVoorschriftKoppeling>>, TError,{data: VoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixMetaVoorschriftKoppeling>>,
        TError,
        {data: VoorschriftKoppelingSchema},
        TContext
      > => {

      const mutationOptions = getPostFixMetaVoorschriftKoppelingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixMetaVoorschriftKoppelingId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftKoppelingSchema>(
      {url: `/fix/meta/voorschrift_koppeling/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixMetaVoorschriftKoppelingIdMutationOptions = <TError = ErrorType<DeleteFixMetaVoorschriftKoppelingId400 | DeleteFixMetaVoorschriftKoppelingId401 | DeleteFixMetaVoorschriftKoppelingId403 | DeleteFixMetaVoorschriftKoppelingId404 | DeleteFixMetaVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaVoorschriftKoppelingId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaVoorschriftKoppelingId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixMetaVoorschriftKoppelingId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixMetaVoorschriftKoppelingId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixMetaVoorschriftKoppelingIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixMetaVoorschriftKoppelingId>>>
    
    export type DeleteFixMetaVoorschriftKoppelingIdMutationError = ErrorType<DeleteFixMetaVoorschriftKoppelingId400 | DeleteFixMetaVoorschriftKoppelingId401 | DeleteFixMetaVoorschriftKoppelingId403 | DeleteFixMetaVoorschriftKoppelingId404 | DeleteFixMetaVoorschriftKoppelingId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixMetaVoorschriftKoppelingId = <TError = ErrorType<DeleteFixMetaVoorschriftKoppelingId400 | DeleteFixMetaVoorschriftKoppelingId401 | DeleteFixMetaVoorschriftKoppelingId403 | DeleteFixMetaVoorschriftKoppelingId404 | DeleteFixMetaVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixMetaVoorschriftKoppelingId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixMetaVoorschriftKoppelingId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixMetaVoorschriftKoppelingIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixMetaVoorschriftKoppelingId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<VoorschriftKoppelingSchema>(
      {url: `/fix/meta/voorschrift_koppeling/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixMetaVoorschriftKoppelingIdQueryKey = (id: string,) => {
    return [`/fix/meta/voorschrift_koppeling/${id}`] as const;
    }

    
export const getGetFixMetaVoorschriftKoppelingIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>>, TError = ErrorType<GetFixMetaVoorschriftKoppelingId404 | GetFixMetaVoorschriftKoppelingId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaVoorschriftKoppelingIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>> = ({ signal }) => getFixMetaVoorschriftKoppelingId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaVoorschriftKoppelingIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>>
export type GetFixMetaVoorschriftKoppelingIdInfiniteQueryError = ErrorType<GetFixMetaVoorschriftKoppelingId404 | GetFixMetaVoorschriftKoppelingId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaVoorschriftKoppelingIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>>, TError = ErrorType<GetFixMetaVoorschriftKoppelingId404 | GetFixMetaVoorschriftKoppelingId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaVoorschriftKoppelingIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixMetaVoorschriftKoppelingIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError = ErrorType<GetFixMetaVoorschriftKoppelingId404 | GetFixMetaVoorschriftKoppelingId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixMetaVoorschriftKoppelingIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>> = ({ signal }) => getFixMetaVoorschriftKoppelingId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixMetaVoorschriftKoppelingIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>>
export type GetFixMetaVoorschriftKoppelingIdQueryError = ErrorType<GetFixMetaVoorschriftKoppelingId404 | GetFixMetaVoorschriftKoppelingId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixMetaVoorschriftKoppelingId = <TData = Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError = ErrorType<GetFixMetaVoorschriftKoppelingId404 | GetFixMetaVoorschriftKoppelingId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixMetaVoorschriftKoppelingId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixMetaVoorschriftKoppelingIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixMetaVoorschriftKoppelingId = (
    id: string,
    voorschriftKoppelingSchemaOptional: VoorschriftKoppelingSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftKoppelingSchema>(
      {url: `/fix/meta/voorschrift_koppeling/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftKoppelingSchemaOptional
    },
      options);
    }
  


export const getPatchFixMetaVoorschriftKoppelingIdMutationOptions = <TError = ErrorType<PatchFixMetaVoorschriftKoppelingId400 | PatchFixMetaVoorschriftKoppelingId401 | PatchFixMetaVoorschriftKoppelingId403 | PatchFixMetaVoorschriftKoppelingId404 | PatchFixMetaVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaVoorschriftKoppelingId>>, TError,{id: string;data: VoorschriftKoppelingSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaVoorschriftKoppelingId>>, TError,{id: string;data: VoorschriftKoppelingSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixMetaVoorschriftKoppelingId>>, {id: string;data: VoorschriftKoppelingSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixMetaVoorschriftKoppelingId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixMetaVoorschriftKoppelingIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixMetaVoorschriftKoppelingId>>>
    export type PatchFixMetaVoorschriftKoppelingIdMutationBody = VoorschriftKoppelingSchemaOptional
    export type PatchFixMetaVoorschriftKoppelingIdMutationError = ErrorType<PatchFixMetaVoorschriftKoppelingId400 | PatchFixMetaVoorschriftKoppelingId401 | PatchFixMetaVoorschriftKoppelingId403 | PatchFixMetaVoorschriftKoppelingId404 | PatchFixMetaVoorschriftKoppelingId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixMetaVoorschriftKoppelingId = <TError = ErrorType<PatchFixMetaVoorschriftKoppelingId400 | PatchFixMetaVoorschriftKoppelingId401 | PatchFixMetaVoorschriftKoppelingId403 | PatchFixMetaVoorschriftKoppelingId404 | PatchFixMetaVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixMetaVoorschriftKoppelingId>>, TError,{id: string;data: VoorschriftKoppelingSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixMetaVoorschriftKoppelingId>>,
        TError,
        {id: string;data: VoorschriftKoppelingSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixMetaVoorschriftKoppelingIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixMetaVoorschriftKoppelingId = (
    id: string,
    voorschriftKoppelingSchema: VoorschriftKoppelingSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftKoppelingSchema>(
      {url: `/fix/meta/voorschrift_koppeling/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftKoppelingSchema
    },
      options);
    }
  


export const getPutFixMetaVoorschriftKoppelingIdMutationOptions = <TError = ErrorType<PutFixMetaVoorschriftKoppelingId400 | PutFixMetaVoorschriftKoppelingId401 | PutFixMetaVoorschriftKoppelingId403 | PutFixMetaVoorschriftKoppelingId404 | PutFixMetaVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaVoorschriftKoppelingId>>, TError,{id: string;data: VoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixMetaVoorschriftKoppelingId>>, TError,{id: string;data: VoorschriftKoppelingSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixMetaVoorschriftKoppelingId>>, {id: string;data: VoorschriftKoppelingSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixMetaVoorschriftKoppelingId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixMetaVoorschriftKoppelingIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixMetaVoorschriftKoppelingId>>>
    export type PutFixMetaVoorschriftKoppelingIdMutationBody = VoorschriftKoppelingSchema
    export type PutFixMetaVoorschriftKoppelingIdMutationError = ErrorType<PutFixMetaVoorschriftKoppelingId400 | PutFixMetaVoorschriftKoppelingId401 | PutFixMetaVoorschriftKoppelingId403 | PutFixMetaVoorschriftKoppelingId404 | PutFixMetaVoorschriftKoppelingId500>

    /**
 * @summary Update an instance
 */
export const usePutFixMetaVoorschriftKoppelingId = <TError = ErrorType<PutFixMetaVoorschriftKoppelingId400 | PutFixMetaVoorschriftKoppelingId401 | PutFixMetaVoorschriftKoppelingId403 | PutFixMetaVoorschriftKoppelingId404 | PutFixMetaVoorschriftKoppelingId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixMetaVoorschriftKoppelingId>>, TError,{id: string;data: VoorschriftKoppelingSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixMetaVoorschriftKoppelingId>>,
        TError,
        {id: string;data: VoorschriftKoppelingSchema},
        TContext
      > => {

      const mutationOptions = getPutFixMetaVoorschriftKoppelingIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixParagraaf = (
    params: GetFixParagraafParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixParagraaf200>(
      {url: `/fix/paragraaf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixParagraafQueryKey = (params: GetFixParagraafParams,) => {
    return [`/fix/paragraaf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixParagraafInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixParagraaf>>>, TError = ErrorType<GetFixParagraaf400 | GetFixParagraaf404 | GetFixParagraaf500>>(params: GetFixParagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixParagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixParagraaf>>> = ({ signal }) => getFixParagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixParagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixParagraafInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixParagraaf>>>
export type GetFixParagraafInfiniteQueryError = ErrorType<GetFixParagraaf400 | GetFixParagraaf404 | GetFixParagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixParagraafInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixParagraaf>>>, TError = ErrorType<GetFixParagraaf400 | GetFixParagraaf404 | GetFixParagraaf500>>(
 params: GetFixParagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixParagraafInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixParagraafQueryOptions = <TData = Awaited<ReturnType<typeof getFixParagraaf>>, TError = ErrorType<GetFixParagraaf400 | GetFixParagraaf404 | GetFixParagraaf500>>(params: GetFixParagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixParagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixParagraaf>>> = ({ signal }) => getFixParagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixParagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixParagraafQueryResult = NonNullable<Awaited<ReturnType<typeof getFixParagraaf>>>
export type GetFixParagraafQueryError = ErrorType<GetFixParagraaf400 | GetFixParagraaf404 | GetFixParagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixParagraaf = <TData = Awaited<ReturnType<typeof getFixParagraaf>>, TError = ErrorType<GetFixParagraaf400 | GetFixParagraaf404 | GetFixParagraaf500>>(
 params: GetFixParagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixParagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixParagraafQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixParagraaf = (
    paragraafSchema: ParagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafSchema>(
      {url: `/fix/paragraaf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: paragraafSchema
    },
      options);
    }
  


export const getPostFixParagraafMutationOptions = <TError = ErrorType<PostFixParagraaf400 | PostFixParagraaf401 | PostFixParagraaf403 | PostFixParagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixParagraaf>>, TError,{data: ParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixParagraaf>>, TError,{data: ParagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixParagraaf>>, {data: ParagraafSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixParagraaf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixParagraafMutationResult = NonNullable<Awaited<ReturnType<typeof postFixParagraaf>>>
    export type PostFixParagraafMutationBody = ParagraafSchema
    export type PostFixParagraafMutationError = ErrorType<PostFixParagraaf400 | PostFixParagraaf401 | PostFixParagraaf403 | PostFixParagraaf500>

    /**
 * @summary Create a new instance
 */
export const usePostFixParagraaf = <TError = ErrorType<PostFixParagraaf400 | PostFixParagraaf401 | PostFixParagraaf403 | PostFixParagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixParagraaf>>, TError,{data: ParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixParagraaf>>,
        TError,
        {data: ParagraafSchema},
        TContext
      > => {

      const mutationOptions = getPostFixParagraafMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixParagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafSchema>(
      {url: `/fix/paragraaf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixParagraafIdMutationOptions = <TError = ErrorType<DeleteFixParagraafId400 | DeleteFixParagraafId401 | DeleteFixParagraafId403 | DeleteFixParagraafId404 | DeleteFixParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixParagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixParagraafId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixParagraafId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixParagraafId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixParagraafId>>>
    
    export type DeleteFixParagraafIdMutationError = ErrorType<DeleteFixParagraafId400 | DeleteFixParagraafId401 | DeleteFixParagraafId403 | DeleteFixParagraafId404 | DeleteFixParagraafId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixParagraafId = <TError = ErrorType<DeleteFixParagraafId400 | DeleteFixParagraafId401 | DeleteFixParagraafId403 | DeleteFixParagraafId404 | DeleteFixParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixParagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixParagraafId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixParagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<ParagraafSchema>(
      {url: `/fix/paragraaf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixParagraafIdQueryKey = (id: string,) => {
    return [`/fix/paragraaf/${id}`] as const;
    }

    
export const getGetFixParagraafIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixParagraafId>>>, TError = ErrorType<GetFixParagraafId404 | GetFixParagraafId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixParagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixParagraafId>>> = ({ signal }) => getFixParagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixParagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixParagraafIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixParagraafId>>>
export type GetFixParagraafIdInfiniteQueryError = ErrorType<GetFixParagraafId404 | GetFixParagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixParagraafIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixParagraafId>>>, TError = ErrorType<GetFixParagraafId404 | GetFixParagraafId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixParagraafIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixParagraafIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixParagraafId>>, TError = ErrorType<GetFixParagraafId404 | GetFixParagraafId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixParagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixParagraafId>>> = ({ signal }) => getFixParagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixParagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixParagraafIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixParagraafId>>>
export type GetFixParagraafIdQueryError = ErrorType<GetFixParagraafId404 | GetFixParagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixParagraafId = <TData = Awaited<ReturnType<typeof getFixParagraafId>>, TError = ErrorType<GetFixParagraafId404 | GetFixParagraafId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixParagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixParagraafIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixParagraafId = (
    id: string,
    paragraafSchemaOptional: ParagraafSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafSchema>(
      {url: `/fix/paragraaf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: paragraafSchemaOptional
    },
      options);
    }
  


export const getPatchFixParagraafIdMutationOptions = <TError = ErrorType<PatchFixParagraafId400 | PatchFixParagraafId401 | PatchFixParagraafId403 | PatchFixParagraafId404 | PatchFixParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixParagraafId>>, TError,{id: string;data: ParagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixParagraafId>>, TError,{id: string;data: ParagraafSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixParagraafId>>, {id: string;data: ParagraafSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixParagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixParagraafId>>>
    export type PatchFixParagraafIdMutationBody = ParagraafSchemaOptional
    export type PatchFixParagraafIdMutationError = ErrorType<PatchFixParagraafId400 | PatchFixParagraafId401 | PatchFixParagraafId403 | PatchFixParagraafId404 | PatchFixParagraafId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixParagraafId = <TError = ErrorType<PatchFixParagraafId400 | PatchFixParagraafId401 | PatchFixParagraafId403 | PatchFixParagraafId404 | PatchFixParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixParagraafId>>, TError,{id: string;data: ParagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixParagraafId>>,
        TError,
        {id: string;data: ParagraafSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixParagraafId = (
    id: string,
    paragraafSchema: ParagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<ParagraafSchema>(
      {url: `/fix/paragraaf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: paragraafSchema
    },
      options);
    }
  


export const getPutFixParagraafIdMutationOptions = <TError = ErrorType<PutFixParagraafId400 | PutFixParagraafId401 | PutFixParagraafId403 | PutFixParagraafId404 | PutFixParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixParagraafId>>, TError,{id: string;data: ParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixParagraafId>>, TError,{id: string;data: ParagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixParagraafId>>, {id: string;data: ParagraafSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixParagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixParagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixParagraafId>>>
    export type PutFixParagraafIdMutationBody = ParagraafSchema
    export type PutFixParagraafIdMutationError = ErrorType<PutFixParagraafId400 | PutFixParagraafId401 | PutFixParagraafId403 | PutFixParagraafId404 | PutFixParagraafId500>

    /**
 * @summary Update an instance
 */
export const usePutFixParagraafId = <TError = ErrorType<PutFixParagraafId400 | PutFixParagraafId401 | PutFixParagraafId403 | PutFixParagraafId404 | PutFixParagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixParagraafId>>, TError,{id: string;data: ParagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixParagraafId>>,
        TError,
        {id: string;data: ParagraafSchema},
        TContext
      > => {

      const mutationOptions = getPutFixParagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixSubparagraaf = (
    params: GetFixSubparagraafParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixSubparagraaf200>(
      {url: `/fix/subparagraaf`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixSubparagraafQueryKey = (params: GetFixSubparagraafParams,) => {
    return [`/fix/subparagraaf`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixSubparagraafInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixSubparagraaf>>>, TError = ErrorType<GetFixSubparagraaf400 | GetFixSubparagraaf404 | GetFixSubparagraaf500>>(params: GetFixSubparagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixSubparagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixSubparagraaf>>> = ({ signal }) => getFixSubparagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixSubparagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixSubparagraafInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixSubparagraaf>>>
export type GetFixSubparagraafInfiniteQueryError = ErrorType<GetFixSubparagraaf400 | GetFixSubparagraaf404 | GetFixSubparagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixSubparagraafInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixSubparagraaf>>>, TError = ErrorType<GetFixSubparagraaf400 | GetFixSubparagraaf404 | GetFixSubparagraaf500>>(
 params: GetFixSubparagraafParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixSubparagraafInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixSubparagraafQueryOptions = <TData = Awaited<ReturnType<typeof getFixSubparagraaf>>, TError = ErrorType<GetFixSubparagraaf400 | GetFixSubparagraaf404 | GetFixSubparagraaf500>>(params: GetFixSubparagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixSubparagraafQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixSubparagraaf>>> = ({ signal }) => getFixSubparagraaf(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixSubparagraaf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixSubparagraafQueryResult = NonNullable<Awaited<ReturnType<typeof getFixSubparagraaf>>>
export type GetFixSubparagraafQueryError = ErrorType<GetFixSubparagraaf400 | GetFixSubparagraaf404 | GetFixSubparagraaf500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixSubparagraaf = <TData = Awaited<ReturnType<typeof getFixSubparagraaf>>, TError = ErrorType<GetFixSubparagraaf400 | GetFixSubparagraaf404 | GetFixSubparagraaf500>>(
 params: GetFixSubparagraafParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixSubparagraaf>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixSubparagraafQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixSubparagraaf = (
    subparagraafSchema: SubparagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafSchema>(
      {url: `/fix/subparagraaf`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subparagraafSchema
    },
      options);
    }
  


export const getPostFixSubparagraafMutationOptions = <TError = ErrorType<PostFixSubparagraaf400 | PostFixSubparagraaf401 | PostFixSubparagraaf403 | PostFixSubparagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixSubparagraaf>>, TError,{data: SubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixSubparagraaf>>, TError,{data: SubparagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixSubparagraaf>>, {data: SubparagraafSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixSubparagraaf(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixSubparagraafMutationResult = NonNullable<Awaited<ReturnType<typeof postFixSubparagraaf>>>
    export type PostFixSubparagraafMutationBody = SubparagraafSchema
    export type PostFixSubparagraafMutationError = ErrorType<PostFixSubparagraaf400 | PostFixSubparagraaf401 | PostFixSubparagraaf403 | PostFixSubparagraaf500>

    /**
 * @summary Create a new instance
 */
export const usePostFixSubparagraaf = <TError = ErrorType<PostFixSubparagraaf400 | PostFixSubparagraaf401 | PostFixSubparagraaf403 | PostFixSubparagraaf500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixSubparagraaf>>, TError,{data: SubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixSubparagraaf>>,
        TError,
        {data: SubparagraafSchema},
        TContext
      > => {

      const mutationOptions = getPostFixSubparagraafMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixSubparagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafSchema>(
      {url: `/fix/subparagraaf/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixSubparagraafIdMutationOptions = <TError = ErrorType<DeleteFixSubparagraafId400 | DeleteFixSubparagraafId401 | DeleteFixSubparagraafId403 | DeleteFixSubparagraafId404 | DeleteFixSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixSubparagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixSubparagraafId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixSubparagraafId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixSubparagraafId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixSubparagraafId>>>
    
    export type DeleteFixSubparagraafIdMutationError = ErrorType<DeleteFixSubparagraafId400 | DeleteFixSubparagraafId401 | DeleteFixSubparagraafId403 | DeleteFixSubparagraafId404 | DeleteFixSubparagraafId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixSubparagraafId = <TError = ErrorType<DeleteFixSubparagraafId400 | DeleteFixSubparagraafId401 | DeleteFixSubparagraafId403 | DeleteFixSubparagraafId404 | DeleteFixSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixSubparagraafId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixSubparagraafId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixSubparagraafId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<SubparagraafSchema>(
      {url: `/fix/subparagraaf/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixSubparagraafIdQueryKey = (id: string,) => {
    return [`/fix/subparagraaf/${id}`] as const;
    }

    
export const getGetFixSubparagraafIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixSubparagraafId>>>, TError = ErrorType<GetFixSubparagraafId404 | GetFixSubparagraafId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixSubparagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixSubparagraafId>>> = ({ signal }) => getFixSubparagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixSubparagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixSubparagraafIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixSubparagraafId>>>
export type GetFixSubparagraafIdInfiniteQueryError = ErrorType<GetFixSubparagraafId404 | GetFixSubparagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixSubparagraafIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixSubparagraafId>>>, TError = ErrorType<GetFixSubparagraafId404 | GetFixSubparagraafId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixSubparagraafIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixSubparagraafIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixSubparagraafId>>, TError = ErrorType<GetFixSubparagraafId404 | GetFixSubparagraafId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixSubparagraafIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixSubparagraafId>>> = ({ signal }) => getFixSubparagraafId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixSubparagraafId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixSubparagraafIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixSubparagraafId>>>
export type GetFixSubparagraafIdQueryError = ErrorType<GetFixSubparagraafId404 | GetFixSubparagraafId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixSubparagraafId = <TData = Awaited<ReturnType<typeof getFixSubparagraafId>>, TError = ErrorType<GetFixSubparagraafId404 | GetFixSubparagraafId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixSubparagraafId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixSubparagraafIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixSubparagraafId = (
    id: string,
    subparagraafSchemaOptional: SubparagraafSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafSchema>(
      {url: `/fix/subparagraaf/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: subparagraafSchemaOptional
    },
      options);
    }
  


export const getPatchFixSubparagraafIdMutationOptions = <TError = ErrorType<PatchFixSubparagraafId400 | PatchFixSubparagraafId401 | PatchFixSubparagraafId403 | PatchFixSubparagraafId404 | PatchFixSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixSubparagraafId>>, TError,{id: string;data: SubparagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixSubparagraafId>>, TError,{id: string;data: SubparagraafSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixSubparagraafId>>, {id: string;data: SubparagraafSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixSubparagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixSubparagraafId>>>
    export type PatchFixSubparagraafIdMutationBody = SubparagraafSchemaOptional
    export type PatchFixSubparagraafIdMutationError = ErrorType<PatchFixSubparagraafId400 | PatchFixSubparagraafId401 | PatchFixSubparagraafId403 | PatchFixSubparagraafId404 | PatchFixSubparagraafId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixSubparagraafId = <TError = ErrorType<PatchFixSubparagraafId400 | PatchFixSubparagraafId401 | PatchFixSubparagraafId403 | PatchFixSubparagraafId404 | PatchFixSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixSubparagraafId>>, TError,{id: string;data: SubparagraafSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixSubparagraafId>>,
        TError,
        {id: string;data: SubparagraafSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixSubparagraafId = (
    id: string,
    subparagraafSchema: SubparagraafSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<SubparagraafSchema>(
      {url: `/fix/subparagraaf/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: subparagraafSchema
    },
      options);
    }
  


export const getPutFixSubparagraafIdMutationOptions = <TError = ErrorType<PutFixSubparagraafId400 | PutFixSubparagraafId401 | PutFixSubparagraafId403 | PutFixSubparagraafId404 | PutFixSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixSubparagraafId>>, TError,{id: string;data: SubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixSubparagraafId>>, TError,{id: string;data: SubparagraafSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixSubparagraafId>>, {id: string;data: SubparagraafSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixSubparagraafId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixSubparagraafIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixSubparagraafId>>>
    export type PutFixSubparagraafIdMutationBody = SubparagraafSchema
    export type PutFixSubparagraafIdMutationError = ErrorType<PutFixSubparagraafId400 | PutFixSubparagraafId401 | PutFixSubparagraafId403 | PutFixSubparagraafId404 | PutFixSubparagraafId500>

    /**
 * @summary Update an instance
 */
export const usePutFixSubparagraafId = <TError = ErrorType<PutFixSubparagraafId400 | PutFixSubparagraafId401 | PutFixSubparagraafId403 | PutFixSubparagraafId404 | PutFixSubparagraafId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixSubparagraafId>>, TError,{id: string;data: SubparagraafSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixSubparagraafId>>,
        TError,
        {id: string;data: SubparagraafSchema},
        TContext
      > => {

      const mutationOptions = getPutFixSubparagraafIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getFixVoorschrift = (
    params: GetFixVoorschriftParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetFixVoorschrift200>(
      {url: `/fix/voorschrift`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFixVoorschriftQueryKey = (params: GetFixVoorschriftParams,) => {
    return [`/fix/voorschrift`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFixVoorschriftInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixVoorschrift>>>, TError = ErrorType<GetFixVoorschrift400 | GetFixVoorschrift404 | GetFixVoorschrift500>>(params: GetFixVoorschriftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixVoorschriftQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixVoorschrift>>> = ({ signal }) => getFixVoorschrift(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixVoorschrift>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixVoorschriftInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixVoorschrift>>>
export type GetFixVoorschriftInfiniteQueryError = ErrorType<GetFixVoorschrift400 | GetFixVoorschrift404 | GetFixVoorschrift500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixVoorschriftInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixVoorschrift>>>, TError = ErrorType<GetFixVoorschrift400 | GetFixVoorschrift404 | GetFixVoorschrift500>>(
 params: GetFixVoorschriftParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixVoorschriftInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixVoorschriftQueryOptions = <TData = Awaited<ReturnType<typeof getFixVoorschrift>>, TError = ErrorType<GetFixVoorschrift400 | GetFixVoorschrift404 | GetFixVoorschrift500>>(params: GetFixVoorschriftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixVoorschriftQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixVoorschrift>>> = ({ signal }) => getFixVoorschrift(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixVoorschrift>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixVoorschriftQueryResult = NonNullable<Awaited<ReturnType<typeof getFixVoorschrift>>>
export type GetFixVoorschriftQueryError = ErrorType<GetFixVoorschrift400 | GetFixVoorschrift404 | GetFixVoorschrift500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetFixVoorschrift = <TData = Awaited<ReturnType<typeof getFixVoorschrift>>, TError = ErrorType<GetFixVoorschrift400 | GetFixVoorschrift404 | GetFixVoorschrift500>>(
 params: GetFixVoorschriftParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixVoorschrift>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixVoorschriftQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postFixVoorschrift = (
    voorschriftSchema: VoorschriftSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftSchema>(
      {url: `/fix/voorschrift`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftSchema
    },
      options);
    }
  


export const getPostFixVoorschriftMutationOptions = <TError = ErrorType<PostFixVoorschrift400 | PostFixVoorschrift401 | PostFixVoorschrift403 | PostFixVoorschrift500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixVoorschrift>>, TError,{data: VoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postFixVoorschrift>>, TError,{data: VoorschriftSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFixVoorschrift>>, {data: VoorschriftSchema}> = (props) => {
          const {data} = props ?? {};

          return  postFixVoorschrift(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFixVoorschriftMutationResult = NonNullable<Awaited<ReturnType<typeof postFixVoorschrift>>>
    export type PostFixVoorschriftMutationBody = VoorschriftSchema
    export type PostFixVoorschriftMutationError = ErrorType<PostFixVoorschrift400 | PostFixVoorschrift401 | PostFixVoorschrift403 | PostFixVoorschrift500>

    /**
 * @summary Create a new instance
 */
export const usePostFixVoorschrift = <TError = ErrorType<PostFixVoorschrift400 | PostFixVoorschrift401 | PostFixVoorschrift403 | PostFixVoorschrift500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFixVoorschrift>>, TError,{data: VoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFixVoorschrift>>,
        TError,
        {data: VoorschriftSchema},
        TContext
      > => {

      const mutationOptions = getPostFixVoorschriftMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteFixVoorschriftId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftSchema>(
      {url: `/fix/voorschrift/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFixVoorschriftIdMutationOptions = <TError = ErrorType<DeleteFixVoorschriftId400 | DeleteFixVoorschriftId401 | DeleteFixVoorschriftId403 | DeleteFixVoorschriftId404 | DeleteFixVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixVoorschriftId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFixVoorschriftId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFixVoorschriftId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteFixVoorschriftId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFixVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFixVoorschriftId>>>
    
    export type DeleteFixVoorschriftIdMutationError = ErrorType<DeleteFixVoorschriftId400 | DeleteFixVoorschriftId401 | DeleteFixVoorschriftId403 | DeleteFixVoorschriftId404 | DeleteFixVoorschriftId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteFixVoorschriftId = <TError = ErrorType<DeleteFixVoorschriftId400 | DeleteFixVoorschriftId401 | DeleteFixVoorschriftId403 | DeleteFixVoorschriftId404 | DeleteFixVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFixVoorschriftId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFixVoorschriftId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteFixVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getFixVoorschriftId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<VoorschriftSchema>(
      {url: `/fix/voorschrift/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFixVoorschriftIdQueryKey = (id: string,) => {
    return [`/fix/voorschrift/${id}`] as const;
    }

    
export const getGetFixVoorschriftIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFixVoorschriftId>>>, TError = ErrorType<GetFixVoorschriftId404 | GetFixVoorschriftId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixVoorschriftIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixVoorschriftId>>> = ({ signal }) => getFixVoorschriftId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixVoorschriftId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixVoorschriftIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFixVoorschriftId>>>
export type GetFixVoorschriftIdInfiniteQueryError = ErrorType<GetFixVoorschriftId404 | GetFixVoorschriftId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixVoorschriftIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFixVoorschriftId>>>, TError = ErrorType<GetFixVoorschriftId404 | GetFixVoorschriftId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFixVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixVoorschriftIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFixVoorschriftIdQueryOptions = <TData = Awaited<ReturnType<typeof getFixVoorschriftId>>, TError = ErrorType<GetFixVoorschriftId404 | GetFixVoorschriftId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFixVoorschriftIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixVoorschriftId>>> = ({ signal }) => getFixVoorschriftId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFixVoorschriftId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFixVoorschriftIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFixVoorschriftId>>>
export type GetFixVoorschriftIdQueryError = ErrorType<GetFixVoorschriftId404 | GetFixVoorschriftId500>

/**
 * @summary Gets an instance.
 */
export const useGetFixVoorschriftId = <TData = Awaited<ReturnType<typeof getFixVoorschriftId>>, TError = ErrorType<GetFixVoorschriftId404 | GetFixVoorschriftId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFixVoorschriftId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFixVoorschriftIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchFixVoorschriftId = (
    id: string,
    voorschriftSchemaOptional: VoorschriftSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftSchema>(
      {url: `/fix/voorschrift/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftSchemaOptional
    },
      options);
    }
  


export const getPatchFixVoorschriftIdMutationOptions = <TError = ErrorType<PatchFixVoorschriftId400 | PatchFixVoorschriftId401 | PatchFixVoorschriftId403 | PatchFixVoorschriftId404 | PatchFixVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixVoorschriftId>>, TError,{id: string;data: VoorschriftSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFixVoorschriftId>>, TError,{id: string;data: VoorschriftSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFixVoorschriftId>>, {id: string;data: VoorschriftSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFixVoorschriftId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFixVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFixVoorschriftId>>>
    export type PatchFixVoorschriftIdMutationBody = VoorschriftSchemaOptional
    export type PatchFixVoorschriftIdMutationError = ErrorType<PatchFixVoorschriftId400 | PatchFixVoorschriftId401 | PatchFixVoorschriftId403 | PatchFixVoorschriftId404 | PatchFixVoorschriftId500>

    /**
 * @summary Update an instance
 */
export const usePatchFixVoorschriftId = <TError = ErrorType<PatchFixVoorschriftId400 | PatchFixVoorschriftId401 | PatchFixVoorschriftId403 | PatchFixVoorschriftId404 | PatchFixVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFixVoorschriftId>>, TError,{id: string;data: VoorschriftSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFixVoorschriftId>>,
        TError,
        {id: string;data: VoorschriftSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchFixVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putFixVoorschriftId = (
    id: string,
    voorschriftSchema: VoorschriftSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<VoorschriftSchema>(
      {url: `/fix/voorschrift/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: voorschriftSchema
    },
      options);
    }
  


export const getPutFixVoorschriftIdMutationOptions = <TError = ErrorType<PutFixVoorschriftId400 | PutFixVoorschriftId401 | PutFixVoorschriftId403 | PutFixVoorschriftId404 | PutFixVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixVoorschriftId>>, TError,{id: string;data: VoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putFixVoorschriftId>>, TError,{id: string;data: VoorschriftSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putFixVoorschriftId>>, {id: string;data: VoorschriftSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putFixVoorschriftId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutFixVoorschriftIdMutationResult = NonNullable<Awaited<ReturnType<typeof putFixVoorschriftId>>>
    export type PutFixVoorschriftIdMutationBody = VoorschriftSchema
    export type PutFixVoorschriftIdMutationError = ErrorType<PutFixVoorschriftId400 | PutFixVoorschriftId401 | PutFixVoorschriftId403 | PutFixVoorschriftId404 | PutFixVoorschriftId500>

    /**
 * @summary Update an instance
 */
export const usePutFixVoorschriftId = <TError = ErrorType<PutFixVoorschriftId400 | PutFixVoorschriftId401 | PutFixVoorschriftId403 | PutFixVoorschriftId404 | PutFixVoorschriftId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putFixVoorschriftId>>, TError,{id: string;data: VoorschriftSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putFixVoorschriftId>>,
        TError,
        {id: string;data: VoorschriftSchema},
        TContext
      > => {

      const mutationOptions = getPutFixVoorschriftIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary API health status
 */
export const getHealth = (
    
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetHealth200>(
      {url: `/health`, method: 'GET', signal
    },
      options);
    }
  

export const getGetHealthQueryKey = () => {
    return [`/health`] as const;
    }

    
export const getGetHealthInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getHealth>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHealthQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHealth>>> = ({ signal }) => getHealth(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHealthInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getHealth>>>
export type GetHealthInfiniteQueryError = ErrorType<unknown>

/**
 * @summary API health status
 */
export const useGetHealthInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getHealth>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHealthInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetHealthQueryOptions = <TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHealthQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHealth>>> = ({ signal }) => getHealth(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHealthQueryResult = NonNullable<Awaited<ReturnType<typeof getHealth>>>
export type GetHealthQueryError = ErrorType<unknown>

/**
 * @summary API health status
 */
export const useGetHealth = <TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHealthQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get all.
 */
export const getOthBesluitType = (
    
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<BesluitTypeSchema[]>(
      {url: `/oth/besluit_type`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOthBesluitTypeQueryKey = () => {
    return [`/oth/besluit_type`] as const;
    }

    
export const getGetOthBesluitTypeInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOthBesluitType>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthBesluitType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthBesluitTypeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthBesluitType>>> = ({ signal }) => getOthBesluitType(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthBesluitType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthBesluitTypeInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOthBesluitType>>>
export type GetOthBesluitTypeInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get all.
 */
export const useGetOthBesluitTypeInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOthBesluitType>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthBesluitType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthBesluitTypeInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOthBesluitTypeQueryOptions = <TData = Awaited<ReturnType<typeof getOthBesluitType>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthBesluitType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthBesluitTypeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthBesluitType>>> = ({ signal }) => getOthBesluitType(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOthBesluitType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthBesluitTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getOthBesluitType>>>
export type GetOthBesluitTypeQueryError = ErrorType<unknown>

/**
 * @summary Get all.
 */
export const useGetOthBesluitType = <TData = Awaited<ReturnType<typeof getOthBesluitType>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthBesluitType>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthBesluitTypeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get all.
 */
export const getOthGebruiker = (
    
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GebruikerSchema[]>(
      {url: `/oth/gebruiker`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOthGebruikerQueryKey = () => {
    return [`/oth/gebruiker`] as const;
    }

    
export const getGetOthGebruikerInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOthGebruiker>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthGebruiker>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthGebruikerQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthGebruiker>>> = ({ signal }) => getOthGebruiker(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthGebruiker>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthGebruikerInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOthGebruiker>>>
export type GetOthGebruikerInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get all.
 */
export const useGetOthGebruikerInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOthGebruiker>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthGebruiker>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthGebruikerInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOthGebruikerQueryOptions = <TData = Awaited<ReturnType<typeof getOthGebruiker>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthGebruiker>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthGebruikerQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthGebruiker>>> = ({ signal }) => getOthGebruiker(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOthGebruiker>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthGebruikerQueryResult = NonNullable<Awaited<ReturnType<typeof getOthGebruiker>>>
export type GetOthGebruikerQueryError = ErrorType<unknown>

/**
 * @summary Get all.
 */
export const useGetOthGebruiker = <TData = Awaited<ReturnType<typeof getOthGebruiker>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthGebruiker>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthGebruikerQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get all.
 */
export const getOthGrondslag = (
    
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GrondslagSchema[]>(
      {url: `/oth/grondslag`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOthGrondslagQueryKey = () => {
    return [`/oth/grondslag`] as const;
    }

    
export const getGetOthGrondslagInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOthGrondslag>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthGrondslag>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthGrondslagQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthGrondslag>>> = ({ signal }) => getOthGrondslag(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthGrondslag>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthGrondslagInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOthGrondslag>>>
export type GetOthGrondslagInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get all.
 */
export const useGetOthGrondslagInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOthGrondslag>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthGrondslag>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthGrondslagInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOthGrondslagQueryOptions = <TData = Awaited<ReturnType<typeof getOthGrondslag>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthGrondslag>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthGrondslagQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthGrondslag>>> = ({ signal }) => getOthGrondslag(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOthGrondslag>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthGrondslagQueryResult = NonNullable<Awaited<ReturnType<typeof getOthGrondslag>>>
export type GetOthGrondslagQueryError = ErrorType<unknown>

/**
 * @summary Get all.
 */
export const useGetOthGrondslag = <TData = Awaited<ReturnType<typeof getOthGrondslag>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthGrondslag>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthGrondslagQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get objects with optional filter and/or sort.
 */
export const getOthHoofdstukReservering = (
    params: GetOthHoofdstukReserveringParams,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<GetOthHoofdstukReservering200>(
      {url: `/oth/hoofdstuk_reservering`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOthHoofdstukReserveringQueryKey = (params: GetOthHoofdstukReserveringParams,) => {
    return [`/oth/hoofdstuk_reservering`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOthHoofdstukReserveringInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOthHoofdstukReservering>>>, TError = ErrorType<GetOthHoofdstukReservering400 | GetOthHoofdstukReservering404 | GetOthHoofdstukReservering500>>(params: GetOthHoofdstukReserveringParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthHoofdstukReserveringQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthHoofdstukReservering>>> = ({ signal }) => getOthHoofdstukReservering(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthHoofdstukReserveringInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOthHoofdstukReservering>>>
export type GetOthHoofdstukReserveringInfiniteQueryError = ErrorType<GetOthHoofdstukReservering400 | GetOthHoofdstukReservering404 | GetOthHoofdstukReservering500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetOthHoofdstukReserveringInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOthHoofdstukReservering>>>, TError = ErrorType<GetOthHoofdstukReservering400 | GetOthHoofdstukReservering404 | GetOthHoofdstukReservering500>>(
 params: GetOthHoofdstukReserveringParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthHoofdstukReserveringInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOthHoofdstukReserveringQueryOptions = <TData = Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError = ErrorType<GetOthHoofdstukReservering400 | GetOthHoofdstukReservering404 | GetOthHoofdstukReservering500>>(params: GetOthHoofdstukReserveringParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthHoofdstukReserveringQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthHoofdstukReservering>>> = ({ signal }) => getOthHoofdstukReservering(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthHoofdstukReserveringQueryResult = NonNullable<Awaited<ReturnType<typeof getOthHoofdstukReservering>>>
export type GetOthHoofdstukReserveringQueryError = ErrorType<GetOthHoofdstukReservering400 | GetOthHoofdstukReservering404 | GetOthHoofdstukReservering500>

/**
 * @summary Get objects with optional filter and/or sort.
 */
export const useGetOthHoofdstukReservering = <TData = Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError = ErrorType<GetOthHoofdstukReservering400 | GetOthHoofdstukReservering404 | GetOthHoofdstukReservering500>>(
 params: GetOthHoofdstukReserveringParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReservering>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthHoofdstukReserveringQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new instance
 */
export const postOthHoofdstukReservering = (
    hoofdstukReserveringSchema: HoofdstukReserveringSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukReserveringSchema>(
      {url: `/oth/hoofdstuk_reservering`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukReserveringSchema
    },
      options);
    }
  


export const getPostOthHoofdstukReserveringMutationOptions = <TError = ErrorType<PostOthHoofdstukReservering400 | PostOthHoofdstukReservering401 | PostOthHoofdstukReservering403 | PostOthHoofdstukReservering500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOthHoofdstukReservering>>, TError,{data: HoofdstukReserveringSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof postOthHoofdstukReservering>>, TError,{data: HoofdstukReserveringSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOthHoofdstukReservering>>, {data: HoofdstukReserveringSchema}> = (props) => {
          const {data} = props ?? {};

          return  postOthHoofdstukReservering(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostOthHoofdstukReserveringMutationResult = NonNullable<Awaited<ReturnType<typeof postOthHoofdstukReservering>>>
    export type PostOthHoofdstukReserveringMutationBody = HoofdstukReserveringSchema
    export type PostOthHoofdstukReserveringMutationError = ErrorType<PostOthHoofdstukReservering400 | PostOthHoofdstukReservering401 | PostOthHoofdstukReservering403 | PostOthHoofdstukReservering500>

    /**
 * @summary Create a new instance
 */
export const usePostOthHoofdstukReservering = <TError = ErrorType<PostOthHoofdstukReservering400 | PostOthHoofdstukReservering401 | PostOthHoofdstukReservering403 | PostOthHoofdstukReservering500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOthHoofdstukReservering>>, TError,{data: HoofdstukReserveringSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof postOthHoofdstukReservering>>,
        TError,
        {data: HoofdstukReserveringSchema},
        TContext
      > => {

      const mutationOptions = getPostOthHoofdstukReserveringMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete an instance.
 */
export const deleteOthHoofdstukReserveringId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukReserveringSchema>(
      {url: `/oth/hoofdstuk_reservering/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteOthHoofdstukReserveringIdMutationOptions = <TError = ErrorType<DeleteOthHoofdstukReserveringId400 | DeleteOthHoofdstukReserveringId401 | DeleteOthHoofdstukReserveringId403 | DeleteOthHoofdstukReserveringId404 | DeleteOthHoofdstukReserveringId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOthHoofdstukReserveringId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOthHoofdstukReserveringId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOthHoofdstukReserveringId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteOthHoofdstukReserveringId(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOthHoofdstukReserveringIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOthHoofdstukReserveringId>>>
    
    export type DeleteOthHoofdstukReserveringIdMutationError = ErrorType<DeleteOthHoofdstukReserveringId400 | DeleteOthHoofdstukReserveringId401 | DeleteOthHoofdstukReserveringId403 | DeleteOthHoofdstukReserveringId404 | DeleteOthHoofdstukReserveringId500>

    /**
 * @summary Delete an instance.
 */
export const useDeleteOthHoofdstukReserveringId = <TError = ErrorType<DeleteOthHoofdstukReserveringId400 | DeleteOthHoofdstukReserveringId401 | DeleteOthHoofdstukReserveringId403 | DeleteOthHoofdstukReserveringId404 | DeleteOthHoofdstukReserveringId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOthHoofdstukReserveringId>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteOthHoofdstukReserveringId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteOthHoofdstukReserveringIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets an instance.
 */
export const getOthHoofdstukReserveringId = (
    id: string,
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<HoofdstukReserveringSchema>(
      {url: `/oth/hoofdstuk_reservering/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOthHoofdstukReserveringIdQueryKey = (id: string,) => {
    return [`/oth/hoofdstuk_reservering/${id}`] as const;
    }

    
export const getGetOthHoofdstukReserveringIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>>, TError = ErrorType<GetOthHoofdstukReserveringId404 | GetOthHoofdstukReserveringId500>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthHoofdstukReserveringIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>> = ({ signal }) => getOthHoofdstukReserveringId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthHoofdstukReserveringIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>>
export type GetOthHoofdstukReserveringIdInfiniteQueryError = ErrorType<GetOthHoofdstukReserveringId404 | GetOthHoofdstukReserveringId500>

/**
 * @summary Gets an instance.
 */
export const useGetOthHoofdstukReserveringIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>>, TError = ErrorType<GetOthHoofdstukReserveringId404 | GetOthHoofdstukReserveringId500>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthHoofdstukReserveringIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOthHoofdstukReserveringIdQueryOptions = <TData = Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError = ErrorType<GetOthHoofdstukReserveringId404 | GetOthHoofdstukReserveringId500>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOthHoofdstukReserveringIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>> = ({ signal }) => getOthHoofdstukReserveringId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOthHoofdstukReserveringIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>>
export type GetOthHoofdstukReserveringIdQueryError = ErrorType<GetOthHoofdstukReserveringId404 | GetOthHoofdstukReserveringId500>

/**
 * @summary Gets an instance.
 */
export const useGetOthHoofdstukReserveringId = <TData = Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError = ErrorType<GetOthHoofdstukReserveringId404 | GetOthHoofdstukReserveringId500>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOthHoofdstukReserveringId>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOthHoofdstukReserveringIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update an instance
 */
export const patchOthHoofdstukReserveringId = (
    id: string,
    hoofdstukReserveringSchemaOptional: HoofdstukReserveringSchemaOptional,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukReserveringSchema>(
      {url: `/oth/hoofdstuk_reservering/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukReserveringSchemaOptional
    },
      options);
    }
  


export const getPatchOthHoofdstukReserveringIdMutationOptions = <TError = ErrorType<PatchOthHoofdstukReserveringId400 | PatchOthHoofdstukReserveringId401 | PatchOthHoofdstukReserveringId403 | PatchOthHoofdstukReserveringId404 | PatchOthHoofdstukReserveringId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchOthHoofdstukReserveringId>>, TError,{id: string;data: HoofdstukReserveringSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof patchOthHoofdstukReserveringId>>, TError,{id: string;data: HoofdstukReserveringSchemaOptional}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchOthHoofdstukReserveringId>>, {id: string;data: HoofdstukReserveringSchemaOptional}> = (props) => {
          const {id,data} = props ?? {};

          return  patchOthHoofdstukReserveringId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchOthHoofdstukReserveringIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchOthHoofdstukReserveringId>>>
    export type PatchOthHoofdstukReserveringIdMutationBody = HoofdstukReserveringSchemaOptional
    export type PatchOthHoofdstukReserveringIdMutationError = ErrorType<PatchOthHoofdstukReserveringId400 | PatchOthHoofdstukReserveringId401 | PatchOthHoofdstukReserveringId403 | PatchOthHoofdstukReserveringId404 | PatchOthHoofdstukReserveringId500>

    /**
 * @summary Update an instance
 */
export const usePatchOthHoofdstukReserveringId = <TError = ErrorType<PatchOthHoofdstukReserveringId400 | PatchOthHoofdstukReserveringId401 | PatchOthHoofdstukReserveringId403 | PatchOthHoofdstukReserveringId404 | PatchOthHoofdstukReserveringId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchOthHoofdstukReserveringId>>, TError,{id: string;data: HoofdstukReserveringSchemaOptional}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchOthHoofdstukReserveringId>>,
        TError,
        {id: string;data: HoofdstukReserveringSchemaOptional},
        TContext
      > => {

      const mutationOptions = getPatchOthHoofdstukReserveringIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update an instance
 */
export const putOthHoofdstukReserveringId = (
    id: string,
    hoofdstukReserveringSchema: HoofdstukReserveringSchema,
 options?: SecondParameter<typeof orvalAPI>,) => {
      
      
      return orvalAPI<HoofdstukReserveringSchema>(
      {url: `/oth/hoofdstuk_reservering/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hoofdstukReserveringSchema
    },
      options);
    }
  


export const getPutOthHoofdstukReserveringIdMutationOptions = <TError = ErrorType<PutOthHoofdstukReserveringId400 | PutOthHoofdstukReserveringId401 | PutOthHoofdstukReserveringId403 | PutOthHoofdstukReserveringId404 | PutOthHoofdstukReserveringId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putOthHoofdstukReserveringId>>, TError,{id: string;data: HoofdstukReserveringSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationOptions<Awaited<ReturnType<typeof putOthHoofdstukReserveringId>>, TError,{id: string;data: HoofdstukReserveringSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putOthHoofdstukReserveringId>>, {id: string;data: HoofdstukReserveringSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  putOthHoofdstukReserveringId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutOthHoofdstukReserveringIdMutationResult = NonNullable<Awaited<ReturnType<typeof putOthHoofdstukReserveringId>>>
    export type PutOthHoofdstukReserveringIdMutationBody = HoofdstukReserveringSchema
    export type PutOthHoofdstukReserveringIdMutationError = ErrorType<PutOthHoofdstukReserveringId400 | PutOthHoofdstukReserveringId401 | PutOthHoofdstukReserveringId403 | PutOthHoofdstukReserveringId404 | PutOthHoofdstukReserveringId500>

    /**
 * @summary Update an instance
 */
export const usePutOthHoofdstukReserveringId = <TError = ErrorType<PutOthHoofdstukReserveringId400 | PutOthHoofdstukReserveringId401 | PutOthHoofdstukReserveringId403 | PutOthHoofdstukReserveringId404 | PutOthHoofdstukReserveringId500>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putOthHoofdstukReserveringId>>, TError,{id: string;data: HoofdstukReserveringSchema}, TContext>, request?: SecondParameter<typeof orvalAPI>}
): UseMutationResult<
        Awaited<ReturnType<typeof putOthHoofdstukReserveringId>>,
        TError,
        {id: string;data: HoofdstukReserveringSchema},
        TContext
      > => {

      const mutationOptions = getPutOthHoofdstukReserveringIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Fetch the OpenAPI specification
 */
export const getSpec = (
    
 options?: SecondParameter<typeof orvalAPI>,signal?: AbortSignal
) => {
      
      
      return orvalAPI<void>(
      {url: `/spec`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSpecQueryKey = () => {
    return [`/spec`] as const;
    }

    
export const getGetSpecInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSpec>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSpecQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpec>>> = ({ signal }) => getSpec(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSpecInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSpec>>>
export type GetSpecInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Fetch the OpenAPI specification
 */
export const useGetSpecInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSpec>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSpecInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSpecQueryOptions = <TData = Awaited<ReturnType<typeof getSpec>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSpecQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpec>>> = ({ signal }) => getSpec(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSpecQueryResult = NonNullable<Awaited<ReturnType<typeof getSpec>>>
export type GetSpecQueryError = ErrorType<unknown>

/**
 * @summary Fetch the OpenAPI specification
 */
export const useGetSpec = <TData = Awaited<ReturnType<typeof getSpec>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData>>, request?: SecondParameter<typeof orvalAPI>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSpecQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




