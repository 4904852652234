import { useQuery } from '@tanstack/react-query';

import { useHistorischBesluitLink } from '../historischBesluit';

type HistorischBesluitLinkByLineageIdParams = {
  voorschriftLineageId: string;
};

const historischBesluitLinkByLineageIdOptions = ({
  voorschriftLineageId,
}: HistorischBesluitLinkByLineageIdParams) => {
  return useHistorischBesluitLink.getOptions(
    {
      page: 1,
      size: 1,
      filter: [
        {
          field: 'Voorschrift_lineage',
          op: 'eq',
          value: voorschriftLineageId,
        },
      ],
    },
    {
      select: ({ objects }) => objects?.[0],
    }
  );
};

const useHistorischBesluitLinkByLineageId = (props: HistorischBesluitLinkByLineageIdParams) =>
  useQuery(historischBesluitLinkByLineageIdOptions(props));
useHistorischBesluitLinkByLineageId.options = historischBesluitLinkByLineageIdOptions;

export { useHistorischBesluitLinkByLineageId };
