import { createFileRoute } from '@tanstack/react-router';

import { Error404 } from '@/components/templates/Error404.tsx';

/**
 * Route
 */
export const Route = createFileRoute('/$')({
  component: Error404,
});

/**
 * Route component
 */
