import { useInfiniteQuery } from '@tanstack/react-query';
import { FormEvent, Fragment, forwardRef } from 'react';

import { STDMapSchema } from '@/api';
import { Infinite, InfoCard, WizardCheckbox } from '@/components';
import { getStdMapTreeNavQueryOptions } from '@/routes/$bedrijfLineageId.editor.$besluitId/-components/StdMapTreeNav';

export interface StdInhoudelijkeOverwegingenFormProps {
  mapId: string;
  formId: string;
  onSubmit: (e: FormEvent) => void;
  onFormChange: () => void;
}

export const StdInhoudelijkeOverwegingenForm = forwardRef<
  HTMLFormElement,
  StdInhoudelijkeOverwegingenFormProps
>(({ mapId, formId, onSubmit, onFormChange }, ref) => {
  const secties = useInfiniteQuery(getStdMapTreeNavQueryOptions({ mapId }));

  const sectiesData =
    secties.data?.pages
      .map((page) => page.data.objects)
      .flat(1)
      .filter((map): map is STDMapSchema => !!map) ?? [];

  return (
    <form id={formId} ref={ref} onSubmit={onSubmit} onChange={onFormChange}>
      <Infinite
        hasMore={secties.hasNextPage}
        onLoadMore={secties.fetchNextPage}
        loaderClassName="mt-2"
      >
        {sectiesData.map((sectie) => {
          const inhoudelijkeOverweging = sectie.InhoudelijkeOverweging;
          const toelichting = sectie.Toelichting;

          let content: string;

          if (inhoudelijkeOverweging) {
            content = `<h3 style="margin-bottom: 16px;">${sectie.Naam}</h3>${inhoudelijkeOverweging}`;
          } else {
            content = `<h3>${sectie.Naam}</h3><i>Deze sectie bevat geen inhoudelijke overweging.</i>`;
          }

          return (
            <Fragment key={`checkbox-${sectie.ID}`}>
              {toelichting && <InfoCard description={toelichting} testId="test" />}

              <WizardCheckbox
                name="inhoudelijke-overweging"
                id={sectie.ID}
                value={content}
                content={content}
                type="voorschrift"
                disabled={!inhoudelijkeOverweging}
              />
            </Fragment>
          );
        })}
      </Infinite>
    </form>
  );
});
