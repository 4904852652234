import Tippy from '@tippyjs/react';

import { BedrijfSchema } from '@/api';
import { BesluitCard } from '@/components';
import { RegularFilePlus } from '@/icons/components';

import { BesluitCardNewButton } from './components/BesluitCardNewButton';

interface BesluitCardNewProps {
  bedrijf: BedrijfSchema;
  hasBesluitInOntwerp: boolean;
  hasVigerendBesluit: boolean;
}

export const BesluitCardNew = ({
  bedrijf,
  hasBesluitInOntwerp,
  hasVigerendBesluit,
}: BesluitCardNewProps) => {
  return (
    <>
      <BesluitCard icon={RegularFilePlus} dashedBorder className="flex-1">
        <BesluitCard.Body>
          <BesluitCard.Heading
            title="Nieuw besluit"
            subtitle={
              !hasVigerendBesluit
                ? 'Maak eenvoudig een nieuw oprichting of revisie besluit aan.'
                : 'Maak eenvoudig een wijzigingsbesluit aan.'
            }
          />

          {hasVigerendBesluit ? (
            <BesluitCard.ButtonRow>
              <BesluitCardNewButton
                bedrijfLineageId={bedrijf.Lineage}
                hasBesluitInOntwerp={hasBesluitInOntwerp}
                hasVigerendBesluit={hasVigerendBesluit}
              />
            </BesluitCard.ButtonRow>
          ) : null}
        </BesluitCard.Body>

        {!hasVigerendBesluit ? (
          <BesluitCard.Aside>
            <Tippy
              content={
                hasBesluitInOntwerp && !hasVigerendBesluit
                  ? 'Voordat er meerdere besluit in ontwerp kunnen zijn, moet er een geconsolideerd overzicht zijn. Rond eerst het andere ontwerp af.'
                  : undefined
              }
              disabled={!(hasBesluitInOntwerp && !hasVigerendBesluit)}
            >
              <span>
                <BesluitCardNewButton
                  bedrijfLineageId={bedrijf.Lineage}
                  hasBesluitInOntwerp={hasBesluitInOntwerp}
                  hasVigerendBesluit={hasVigerendBesluit}
                />
              </span>
            </Tippy>
          </BesluitCard.Aside>
        ) : null}
      </BesluitCard>
    </>
  );
};
