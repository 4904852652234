import { SubmitHandler, useForm } from 'react-hook-form';

import { InstallatieTypeSchema } from '@/api';
import { usePatchInstallatietypeId, usePostInstallatietype } from '@/api/queries/installatietype';
import { Modal, TextArea, useModalState } from '@/components';
import { PLACEHOLDER_UUID } from '@/constants/placeholder-uuid';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface BeheerInstallatietypeProps {
  updateInstallatietype?: InstallatieTypeSchema;
}

type FormValues = Pick<InstallatieTypeSchema, 'Installatie_Type'>;

export const BEHEER_INSTALLATIETYPE_ID = 'beheerInstallatietype';

export const BeheerInstallatietype = modal(BEHEER_INSTALLATIETYPE_ID, ({ data, props }) => {
  const { updateInstallatietype } = data;

  const modalState = useModalState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Installatie_Type: updateInstallatietype?.Installatie_Type,
    },
  });

  const postInstallatietype = usePostInstallatietype();
  const patchInstallatietype = usePatchInstallatietypeId();

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const userId = useUserStore.getState().user?.ID ?? '';
    const date = new Date().toISOString();

    modalState.action('save');

    if (updateInstallatietype) {
      patchInstallatietype.mutate(
        {
          id: updateInstallatietype.ID!,
          data: {
            Installatie_Type: formData.Installatie_Type,
            Modified_By: userId,
            Modified_Date: date,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    } else {
      postInstallatietype.mutate(
        {
          data: {
            Installatie_Type: formData.Installatie_Type,
            Created_By: userId,
            Modified_By: userId,
            Created_Date: date,
            Modified_Date: date,
            Status: 'Actief',
            Landelijk: 0,
            // Tenant will be replaced by BE
            Tenant: PLACEHOLDER_UUID,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    }
  };

  return (
    <Modal {...props} state={modalState.state} title="Installatietype">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          {...register('Installatie_Type', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
          error={errors.Installatie_Type}
          label="Titel"
          id="installatietype-title"
          placeholder="Voer een titel in"
          disableLineBreak
          required
        />
        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
            Opslaan
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
