import { useHoofdstukReservering } from '../useHoofdstukReservering';

export const useHoofdstukReserveringByLineageId = (hoofdstukLineageId?: string) =>
  useHoofdstukReservering(
    {
      page: 1,
      size: 1,
      filter: [
        {
          field: 'Reservering_lineage',
          op: 'eq',
          value: hoofdstukLineageId ?? '',
        },
      ],
    },
    {
      enabled: !!hoofdstukLineageId,
      select: ({ objects }) => objects?.[0],
    }
  );
