import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" ref={svgRef} {...props}><path d="M288 32a32 32 0 10-64 0 32 32 0 1064 0zm0 448a32 32 0 10-64 0 32 32 0 1064 0zm160-224a32 32 0 1064 0 32 32 0 10-64 0zM32 288a32 32 0 100-64 32 32 0 100 64zm43 149a32 32 0 1045.3-45.3A32 32 0 1075 437zm316.8 0a32 32 0 1045.2-45.2 32 32 0 10-45.2 45.2zM75 75a32 32 0 1045.3 45.3A32 32 0 1075 75z" /></svg>;
});
SVGIcon.displayName = "RegularSpinner";
export default SVGIcon;