import { LoaderContent } from '@/components';

interface PageHeaderProps {
  title: string;
  description?: string;
  isLoading?: boolean;
}

export const PageHeader = ({ title, description, isLoading }: PageHeaderProps) => (
  <header className="relative w-full bg-theme-blue text-white">
    <div className="mx-auto max-w-7xl py-20 sm:px-6 lg:px-8">
      {isLoading ? (
        <>
          <div>
            <LoaderContent w="w-20" h="h-2" />
          </div>
          <span>
            <LoaderContent w="w-20" h="h-2" />
          </span>
        </>
      ) : (
        <>
          <h1 className="block text-4xl font-semibold">{title}</h1>

          {description ? <p>{description}</p> : undefined}
        </>
      )}
    </div>
  </header>
);
