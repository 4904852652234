import { createFileRoute, notFound } from '@tanstack/react-router';

import { useHoofdstukId } from '@/api/queries/objects';
import { useOverwegingByHoofdstukLineageId } from '@/api/queries/overwegingHoofdstuk';

import { EditorContainer } from './-components/EditorContainer';
import { InhoudelijkeOverwegingenEditor } from './-components/InhoudelijkeOverwegingenEditor';
import { useEditorStore } from './-store/useEditorStore';

/**
 * Route
 */
export const Route = createFileRoute(
  '/$bedrijfLineageId/editor/$besluitId/inhoudelijke-overwegingen/$hoofdstukId'
)({
  loader: async ({ context: { queryClient }, params: { hoofdstukId, besluitId } }) => {
    const hoofdstuk = await queryClient.ensureQueryData(useHoofdstukId.getOptions(hoofdstukId));

    if (!hoofdstuk) throw notFound();

    await queryClient.ensureQueryData(
      useOverwegingByHoofdstukLineageId.queryOptions({
        hoofdstukLineageId: hoofdstuk.Lineage,
        besluitId,
      })
    );
  },
  component: InhoudelijkeOverwegingenComponent,
});

/**
 * Route component
 */
function InhoudelijkeOverwegingenComponent() {
  const { hoofdstukId, besluitId } = Route.useParams();

  const contentMinHeight = useEditorStore((state) => state.contentMinHeight);

  const hoofdstuk = useHoofdstukId(hoofdstukId);

  if (hoofdstuk.isLoading || !hoofdstuk.data) {
    return null;
  }

  return (
    <EditorContainer
      nummer={hoofdstuk.data.Nummer}
      ariaLabel={hoofdstuk.data.Titel}
      title={hoofdstuk.data.Titel}
      titleAsHtml
    >
      <InhoudelijkeOverwegingenEditor
        hoofdstukLineageId={hoofdstuk.data.Lineage}
        besluitId={besluitId}
        textEditorMinHeight={contentMinHeight}
      />
    </EditorContainer>
  );
}
