import { useNavigate } from '@tanstack/react-router';

import { useCreateObjects } from '@/api/queries/objects';
import { AddItemButton } from '@/components';
import { RegularFilePlus } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { useUserStore } from '@/stores/user';

interface EditorAddMenuVoorschriftenRouteProps {
  bedrijfLineageId: string;
  besluitId: string;
}

export const EditorAddMenuVoorschriftenRoute = ({
  bedrijfLineageId,
  besluitId,
}: EditorAddMenuVoorschriftenRouteProps) => {
  const navigate = useNavigate();
  const openModal = useOpenModal();

  const createObjects = useCreateObjects();

  return (
    <div className="-mt-8 mb-4 flex justify-center">
      <AddItemButton
        icon={RegularFilePlus}
        testId="add-hoofdstuk"
        label={
          <>
            Leeg <br /> hoofdstuk
          </>
        }
        isLoading={createObjects.isPending}
        onClick={() => {
          const userId = useUserStore.getState().user?.ID ?? '';

          createObjects.mutate(
            {
              type: 'hoofdstukken',
              besluitId,
              userId,
              hoofdstukken: [
                {
                  titel: '',
                },
              ],
            },
            {
              onSuccess: ({ hoofdstukResponses }) => {
                const hoofdstukId = hoofdstukResponses[0]?.hoofdstuk.ID;

                if (hoofdstukId) {
                  navigate({
                    to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
                    params: {
                      besluitId,
                      hoofdstukId,
                      bedrijfLineageId,
                    },
                  });
                }
              },
            }
          );
        }}
        className="mr-2 bg-white"
      />

      <AddItemButton
        icon={RegularFilePlus}
        testId="add-standaardtekst-hoofdstuk"
        onClick={() => {
          openModal('editorStdMappen', {
            besluitId,
            onSubmit: (newHoofdstuk) => {
              navigate({
                to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
                params: {
                  besluitId,
                  bedrijfLineageId,
                  hoofdstukId: newHoofdstuk.ID!,
                },
              });
            },
          });
        }}
        label={
          <>
            Standaardtekst <br /> hoofdstuk
          </>
        }
        className="bg-white"
      />
    </div>
  );
};
