import { BedrijfSchema, HoofdstukSchema } from '@/api';
import { LinkParagraaf, useParagraafId } from '@/api/queries/objects';
import { EditorParagraaf, Transitions } from '@/components';
import { MountWhenInView } from '@/components/shared/MountWhenInView';

import { Mode } from './Objects';
import { ObjectsSubparagraaf } from './ObjectsSubparagraaf';
import { getObjectStatus } from './utils/getObjectState';

interface ObjectsParagraafProps {
  hoofdstuk: HoofdstukSchema;
  bedrijf: BedrijfSchema;
  link: LinkParagraaf;
  mode: Mode;
}

export const ObjectsParagraaf = (props: ObjectsParagraafProps) => {
  return (
    <MountWhenInView placeholder={<Placeholder />}>
      <ObjectsParagraafInner {...props} />
    </MountWhenInView>
  );
};

/**
 * Paragraaf Row
 */
const ObjectsParagraafInner = ({ link, hoofdstuk, bedrijf, mode }: ObjectsParagraafProps) => {
  const paragraaf = useParagraafId(link.PHB.Paragraaf, { staleTime: Infinity });

  const isLoaded = !!(!paragraaf.isLoading && paragraaf.data);

  if (!isLoaded) return <Placeholder />;

  return (
    <Transitions.FadeIn show={isLoaded} appear unmount>
      <EditorParagraaf
        number={`${hoofdstuk.Nummer}.${paragraaf.data.Nummer}`}
        status={
          mode === 'besluit'
            ? getObjectStatus(paragraaf.data, link.PHB.Type === 'Eigen')
            : undefined
        }
        content={paragraaf.data.Titel}
        testId={`paragraaf-${paragraaf.data.ID}`}
        className="mb-2"
      />

      {link.subparagrafen.map((linkSubparagraaf) => {
        return (
          <ObjectsSubparagraaf
            bedrijf={bedrijf}
            hoofdstuk={hoofdstuk}
            link={linkSubparagraaf}
            paragraafNummer={paragraaf.data.Nummer}
            mode={mode}
            key={linkSubparagraaf.SPPB.Subparagraaf}
          />
        );
      })}
    </Transitions.FadeIn>
  );
};

const Placeholder = () => <div className="h-[42px] w-full" />;
