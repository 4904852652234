import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';
import { ErrorMessage } from '@/utils/errorMessage';

export interface saveFailedProps {
  tryAgain: () => Promise<unknown>;
}

export const SAVE_FAILED = 'saveFailed';

export const SaveFailed = modal(SAVE_FAILED, ({ data, props }) => {
  const modalState = useModalState();

  const { tryAgain } = data;

  const modalTryAgain = () => {
    modalState.action('save');
    tryAgain()
      .then(() => modalState.success('save'))
      .catch((error) => {
        const errorMessage = error instanceof Error && ErrorMessage.getLocalErrorMessage(error);

        if (errorMessage) {
          modalState.error('save', {
            title: 'Mislukt',
            description: errorMessage,
          });
        }
      });
  };

  return (
    <Modal {...props} state={modalState.state} title="Opslaan niet gelukt">
      <p>
        Het is niet gelukt om de inhoud op te slaan. Probeer het opnieuw, mocht deze fout opnieuw
        worden getoond, vernieuw dan de pagina. Let op, niet opgeslagen bewerkingen gaan verloren.
      </p>
      <Modal.Footer>
        <Modal.CancelButton />
        <Modal.ActionButton
          action="save"
          successLabel="Opgeslagen"
          onClick={() => {
            modalTryAgain();
          }}
        >
          Opnieuw proberen
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
