import { QueryClient, useQueries } from '@tanstack/react-query';

import { GetFixHoofdstuk200, HoofdstukSchema } from '@/api/generated/digiVAPI.schemas';
import { useHoofdstuk } from '@/api/queries/objects';

type QueryOptions = {
  refetchInterval?: number;
  staleTime?: number;
};

export const useHoofdstukkenWithEdits = (
  { besluitId }: { besluitId: string },
  options: QueryOptions = {}
) => {
  const hoofdstukkenWithEdits = useQueries({
    queries: [
      getQueryOptions(besluitId, 'HB', options),
      getQueryOptions(besluitId, 'PHB', options),
      getQueryOptions(besluitId, 'SPPB', options),
      getQueryOptions(besluitId, 'VSPB', options),
    ],
    combine: (responses) => {
      const successfulResponses = responses
        .map(({ data }) => data)
        .filter((data): data is GetFixHoofdstuk200 => !!data);

      return {
        data: filterHoofdstukRespones(successfulResponses),
        isLoading: responses.some((result) => result.isLoading),
      };
    },
  });

  return hoofdstukkenWithEdits;
};

export const ensureQueryDataHoofdstukkenWithEdits = async (
  { besluitId }: { besluitId: string },
  options: QueryOptions = {},
  queryClient: QueryClient
) => {
  const responses = await Promise.all([
    queryClient.ensureQueryData(getQueryOptions(besluitId, 'HB', options)),
    queryClient.ensureQueryData(getQueryOptions(besluitId, 'PHB', options)),
    queryClient.ensureQueryData(getQueryOptions(besluitId, 'SPPB', options)),
    queryClient.ensureQueryData(getQueryOptions(besluitId, 'VSPB', options)),
  ]);

  return filterHoofdstukRespones(responses);
};

export const fetchQueryDataHoofdstukkenWithEdits = async (
  { besluitId }: { besluitId: string },
  options: QueryOptions = {},
  queryClient: QueryClient
) => {
  const responses = await Promise.all([
    queryClient.fetchQuery(getQueryOptions(besluitId, 'HB', options)),
    queryClient.fetchQuery(getQueryOptions(besluitId, 'PHB', options)),
    queryClient.fetchQuery(getQueryOptions(besluitId, 'SPPB', options)),
    queryClient.fetchQuery(getQueryOptions(besluitId, 'VSPB', options)),
  ]);

  return filterHoofdstukRespones(responses);
};

const getQueryOptions = (
  besluitId: string,
  linkType: 'HB' | 'PHB' | 'SPPB' | 'VSPB',
  options: QueryOptions
) => {
  return useHoofdstuk.getOptions(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: linkType,
          field: 'Type',
          op: 'eq',
          value: 'Eigen',
        },
        {
          model: linkType,
          field: 'Besluit',
          value: besluitId,
          op: 'eq',
        },
      ],
    },
    options
  );
};

const filterHoofdstukRespones = (responses: GetFixHoofdstuk200[]) => {
  const hoofdstukkenWithEdits: HoofdstukSchema[] = [];

  responses.forEach((response) => {
    response.objects?.forEach((hoofdstuk) => {
      const exists = hoofdstukkenWithEdits.some(({ ID }) => ID === hoofdstuk.ID);

      if (!exists) {
        hoofdstukkenWithEdits.push(hoofdstuk);
      }
    });
  });

  const sortedHoofdstukken = hoofdstukkenWithEdits.sort((a, b) => a.Nummer - b.Nummer);

  return sortedHoofdstukken;
};
