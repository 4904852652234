import { useDebouncedCallback } from '@react-hookz/web';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { twJoin } from 'tailwind-merge';

import {
  getLastCreatedObject,
  useCreateVoorschriftInLineage,
  usePatchVoorschriftId,
  useSubparagraafId,
} from '@/api/queries/objects';
import { PillButton, TextEditor } from '@/components';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { RegularImage, RegularTrash, SolidClockRotateLeft } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from '../EditorNavigation';
import { ObjectRow } from '../ObjectRow/ObjectRow';
import { useVoorschriftContext } from './VoorschriftContext';

export const VoorschriftInput = ({
  dragHandleRef,
  hasError,
}: {
  dragHandleRef: ConnectDragSource;
  hasError?: boolean;
}) => {
  const { voorschrift, link, userHasReservering, nummer, objectState } = useVoorschriftContext();
  const id = voorschrift.ID!;

  const textEditorRef = useRef<Editor | null>(null);
  const [error, setError] = useState<string | undefined>();

  const openModal = useOpenModal();

  const { data: isParentIngetrokken } = useSubparagraafId(link.VSPB.Subparagraaf, {
    select: ({ Stop_Lineage }) => !!Stop_Lineage,
  });
  const patchVoorschriftId = usePatchVoorschriftId({ mutationKey: AUTOSAVE_MUTATION_KEY });
  const createVoorschriftInLineage = useCreateVoorschriftInLineage();

  /**
   * onEdit handler
   */
  const onEdit = () => {
    createVoorschriftInLineage.mutate(
      {
        link,
        voorschrift,
      },
      {
        onSuccess: () => setError(undefined),
        onError: (error) => setError(ErrorMessage.getLocalErrorMessage(error)),
      }
    );
  };

  /**
   * onUndo handler
   */
  const onUndo = () => {
    openModal('undoVoorschrift', {
      nummer,
      id,
    });
  };

  /**
   * Patch for the voorschrift
   */
  const patchVoorschriftIdDebounced = useDebouncedCallback(
    (value: string) => {
      if (
        value === voorschrift.Voorschrift ||
        !userHasReservering ||
        objectState.state === 'vigerend' ||
        objectState.state === 'deleted'
      )
        return;

      patchVoorschriftId.mutate(
        {
          id,
          data: {
            Voorschrift: value,
          },
        },
        {
          onSuccess: () => {
            setError(undefined);
          },
          onError: (error) => {
            setError(ErrorMessage.getLocalErrorMessage(error));
          },
        }
      );
    },
    [patchVoorschriftId, id, voorschrift.Voorschrift, userHasReservering, objectState.state],
    500
  );

  /**
   * Update TextEditor when the editor is not editable
   */
  useEffect(() => {
    if (textEditorRef.current && !userHasReservering) {
      textEditorRef.current.commands.setContent(voorschrift.Voorschrift ?? '');
    }
  }, [voorschrift.Voorschrift, userHasReservering]);

  /**
   * onDelete handler
   */
  const onDelete = useCallback(() => {
    openModal('deleteVoorschrift', {
      voorschriftLink: link,
      nummer,
    });
  }, [nummer, link, openModal]);

  const canUndo = userHasReservering && objectState.state === 'edited' && !objectState.slotState;
  const canIntrekken =
    objectState.state === 'vigerend' && !objectState.slotState && userHasReservering;
  const canBeDeleted =
    userHasReservering &&
    objectState.isEditable &&
    objectState.state === 'new' &&
    !objectState.slotState;
  const isDraggable =
    userHasReservering &&
    objectState.isEditable &&
    objectState.state === 'new' &&
    !objectState.slotState;

  return (
    <>
      <ObjectRow
        id={id}
        lineageId={voorschrift.Lineage}
        type="voorschrift"
        objectState={objectState}
        hasError={!!error || !!hasError}
      >
        {isDraggable && <ObjectRow.DragHandle dragHandleRef={dragHandleRef} />}

        <ObjectRow.State state={objectState.state} />

        <ObjectRow.Number>
          <span className={objectState.state === 'deleted' ? 'line-through' : undefined}>
            {nummer}
          </span>
        </ObjectRow.Number>

        <ObjectRow.Body>
          <div className={twJoin('w-full', objectState.state === 'deleted' && 'line-through')}>
            <TextEditor
              editorRef={textEditorRef}
              editable={objectState.isEditable}
              deleted={objectState.state === 'deleted'}
              menuOptions={[
                'superscript',
                'subscript',
                'bold',
                'italic',
                'underline',
                'table',
                'bulletList',
                'orderedList',
                'abcList',
                'highlight',
              ]}
              customOptions={[
                {
                  icon: RegularImage,
                  testId: 'texteditor-add-image',
                  ariaLabel: 'Afbeelding toevoegen',
                  onClick: () => {
                    const initialSelection =
                      textEditorRef.current?.isActive('tableCell') ||
                      textEditorRef.current?.isActive('paragraph')
                        ? textEditorRef.current?.view.state.selection.$anchor.pos
                        : null;
                    openModal('editorAddImage', {
                      onAddImage: (base64Image, alt) => {
                        textEditorRef.current
                          ?.chain()
                          .focus(initialSelection)
                          .setImage({ src: base64Image, alt })
                          .run();
                      },
                    });
                  },
                },
              ]}
              initialText={voorschrift.Voorschrift}
              id={id}
              testId="texteditor"
              variant="clean"
              placeholder="Geef de inhoud van het voorschrift op"
              classes={{
                default: twJoin(
                  'px-1 py-0.5 ring-inset transition-all duration-100 ease-in',
                  !error && 'hover:ring-1 hover:ring-gray-400',
                  error && 'ring-1 ring-digi-v-color-danger hover:ring-digi-v-color-danger'
                ),
                focused: 'ring-1 ring-gray-400 bg-white',
              }}
              onUpdate={patchVoorschriftIdDebounced}
              onCreate={({ editor }) => {
                const lastCreatedObject = getLastCreatedObject();

                const isNew = lastCreatedObject?.Lineage === voorschrift.Lineage;

                if (isNew && !voorschrift.Voorschrift) {
                  // There's race condition in TipTap where the editor is created but methods can't be called on it.
                  // This is a temporary workaround to focus the editor after it's created.
                  setTimeout(() => editor?.commands.focus(), 50);
                }
              }}
            />
          </div>
        </ObjectRow.Body>

        {canBeDeleted && (
          <ObjectRow.Icon
            icon={RegularTrash}
            className="text-digi-v-color-danger"
            onClick={onDelete}
            testId="delete"
          />
        )}

        {canUndo && <ObjectRow.Icon icon={SolidClockRotateLeft} onClick={onUndo} testId="undo" />}

        {userHasReservering && objectState.state === 'deleted' && !objectState.slotState && (
          <ObjectRow.ButtonOverlay>
            {isParentIngetrokken ? (
              <Tippy content="Het is niet mogelijk om het intrekken ongedaan te maken omdat bovenliggend object ook is ingetrokken">
                <div>
                  <PillButton variant="green" disabled icon={SolidClockRotateLeft}>
                    Intrekken ongedaan maken
                  </PillButton>
                </div>
              </Tippy>
            ) : (
              <PillButton
                variant="green"
                onClick={() => openModal('undoIntrekkenVoorschrift', { id, nummer })}
                icon={SolidClockRotateLeft}
              >
                Intrekken ongedaan maken
              </PillButton>
            )}
          </ObjectRow.ButtonOverlay>
        )}

        {canIntrekken && (
          <ObjectRow.ButtonOverlay>
            <PillButton
              isLoading={createVoorschriftInLineage.isPending}
              onClick={onEdit}
              className="shadow-md"
              variant="green"
              data-testid="edit-object"
            >
              Voorschrift bewerken
            </PillButton>
            <PillButton
              className="shadow-md"
              variant="white"
              onClick={() => {
                openModal('intrekkenVoorschrift', {
                  link,
                  voorschrift,
                  nummer,
                });
              }}
            >
              Voorschrift intrekken
            </PillButton>
          </ObjectRow.ButtonOverlay>
        )}
      </ObjectRow>

      {error && <WarningInline description={error} center />}
    </>
  );
};
