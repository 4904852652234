import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  patchFixLinkPhbId,
  patchFixLinkSppbId,
  patchFixLinkVspbId,
} from '@/api/generated/digiVAPI';
import { getRangBetween } from '@/utils/getRangBetween';

import { useLinksInHoofdstuk } from '../links';
import { LinkParagraaf, LinkSubparagraaf, LinkVoorschrift } from '../utils/getLinksInHoofdstuk';

/**
 * useMutation for ordering voorschriften
 */
export const useOrderVoorschriftLink = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      from,
      to,
      method,
      links,
    }: {
      from: [number, number, number];
      to: [number, number, number];
      method: 'prepend' | 'append';
      links: LinkParagraaf[];
    }) => {
      const voorschriftLink = links[from[0]]?.subparagrafen[from[1]]?.voorschriften[from[2]];

      const newIndex = method === 'prepend' ? to[2] - 1 : to[2];

      const newParagraafLink = links[to[0]];
      const newSubparagraafLink = newParagraafLink?.subparagrafen[to[1]];

      if (!voorschriftLink) {
        throw new Error('Voorschrift niet gevonden');
      }

      const newPreviousLink: LinkVoorschrift | null =
        newParagraafLink?.subparagrafen[to[1]]?.voorschriften[newIndex] ?? null;
      const newNextLink: LinkVoorschrift | null =
        newParagraafLink?.subparagrafen[to[1]]?.voorschriften[newIndex + 1] ?? null;

      if (
        newPreviousLink?.VSPB.ID === voorschriftLink.VSPB.ID ||
        newNextLink?.VSPB.ID === voorschriftLink.VSPB.ID
      ) {
        throw new Error('Voorschrift mag niet naar dezelfde positie verplaatst worden');
      }

      const newRang = getRangBetween(newPreviousLink?.VSPB.Rang, newNextLink?.VSPB.Rang);

      /**
       * Patch VSPB with new Vorig and Volgend prop
       */
      return await patchFixLinkVspbId(voorschriftLink.VSPB.ID!, {
        Rang: newRang,
        Subparagraaf: newSubparagraafLink?.SPPB.Subparagraaf,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key });
    },
  });
};

/**
 * useMutation for ordering voorschriften
 */
export const useOrderSubparagraafLink = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      from,
      to,
      method,
      links,
    }: {
      from: [number, number];
      to: [number, number];
      method: 'prepend' | 'append';
      links: LinkParagraaf[];
    }) => {
      const subparagraafLink = links[from[0]]?.subparagrafen[from[1]];

      const newIndex = method === 'prepend' ? to[1] - 1 : to[1];

      const newParagraafLink = links[to[0]];

      if (!subparagraafLink) {
        throw new Error('Subparagraaf niet gevonden');
      }

      const newPreviousLink: LinkSubparagraaf | null =
        newParagraafLink?.subparagrafen[newIndex] ?? null;
      const newNextLink: LinkSubparagraaf | null =
        newParagraafLink?.subparagrafen[newIndex + 1] ?? null;

      if (
        newPreviousLink?.SPPB.ID === subparagraafLink.SPPB.ID ||
        newNextLink?.SPPB.ID === subparagraafLink.SPPB.ID
      ) {
        throw new Error('Subparagraaf mag niet naar dezelfde positie verplaatst worden');
      }

      const newRang = getRangBetween(newPreviousLink?.SPPB.Rang, newNextLink?.SPPB.Rang);

      /**
       * Patch VSPB with new Vorig and Volgend prop
       */
      return await patchFixLinkSppbId(subparagraafLink.SPPB.ID!, {
        Rang: newRang,
        Paragraaf: newParagraafLink?.PHB.Paragraaf,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key });
    },
  });
};

/**
 * useMutation for ordering paragrafen
 */
export const useOrderParagraafLink = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      from,
      to,
      links,
      method,
    }: {
      from: number;
      to: number;
      method: 'prepend' | 'append';
      links: LinkParagraaf[];
    }) => {
      const newIndex = method === 'prepend' ? to - 1 : to;

      const paragraafLink = links[from];
      const newPreviousLink: LinkParagraaf | undefined = links[newIndex];
      const newNextLink: LinkParagraaf | undefined = links[newIndex + 1];

      if (!paragraafLink) {
        throw new Error('Paragraaf niet gevonden');
      }

      if (
        newPreviousLink?.PHB.ID === paragraafLink.PHB.ID ||
        newNextLink?.PHB.ID === paragraafLink.PHB.ID
      ) {
        throw new Error('Paragraaf mag niet naar dezelfde positie verplaatst worden');
      }

      const newRang = getRangBetween(newPreviousLink?.PHB.Rang, newNextLink?.PHB.Rang);

      /**
       * Patch PHB with new Vorig and Volgend prop
       */
      return await patchFixLinkPhbId(paragraafLink.PHB.ID!, {
        Rang: newRang,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key });
    },
  });
};
