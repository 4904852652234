import { useDebouncedCallback } from '@react-hookz/web';
import { useNavigate, useParams } from '@tanstack/react-router';
import { Editor } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { HoofdstukSchema } from '@/api';
import { Links, getLastCreatedObject, usePatchHoofdstukId } from '@/api/queries/objects';
import { PillButton, TextEditor } from '@/components';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { RegularTrash, SolidClockRotateLeft } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';
import { ObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from '../EditorNavigation';
import { ObjectRow } from '../ObjectRow/ObjectRow';

const unwrapValue = (value: string) => {
  const element = document.createElement('div');

  element.innerHTML = value;

  const inner = element.querySelector('p');

  return inner?.innerHTML;
};

interface HoofdstukInputProps {
  id: string;
  hoofdstuk: HoofdstukSchema;
  objectState: ObjectState;
  userHasReservering: boolean;
  links: Links;
  editorMode: EditorMode;
}

export const HoofdstukInput = ({
  id,
  hoofdstuk,
  objectState,
  userHasReservering,
  links,
  editorMode,
}: HoofdstukInputProps) => {
  const { besluitId, bedrijfLineageId } = useParams({
    from: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
  });
  const openModal = useOpenModal();
  const navigate = useNavigate();

  const textEditorRef = useRef<Editor | null>(null);

  const [nummer, setNummer] = useState<number | null>(hoofdstuk.Nummer);

  const [patchNummerError, setPatchNummerError] = useState<string | undefined>();
  const [validateNummerError, setValidateNummerError] = useState<string | undefined>();
  const [patchTitelError, setPatchTitelError] = useState<string | undefined>();

  const patchHoofdstuk = usePatchHoofdstukId({ mutationKey: AUTOSAVE_MUTATION_KEY });

  /**
   * Patch for the titel of the hoofdstuk
   */
  const patchTitelDebounced = useDebouncedCallback(
    (value: string) => {
      const unwrappedValue = unwrapValue(value);

      if (unwrappedValue === hoofdstuk.Titel) return;

      patchHoofdstuk.mutate(
        {
          id,
          data: {
            Titel: unwrappedValue ?? '',
          },
        },
        {
          onSuccess: () => setPatchTitelError(undefined),
          onError: (error) => setPatchTitelError(ErrorMessage.getLocalErrorMessage(error)),
        }
      );
    },
    [patchHoofdstuk, id, hoofdstuk.Titel],
    500
  );

  /**
   * Patch for the hoofdstuk nummer
   */
  const onChangeNummerDebounced = useDebouncedCallback(
    (nummer: number | null) => {
      // Check if the nummer is null, and show an error in that case
      if (nummer === null) {
        setValidateNummerError('Hoofdstuknummer is verplicht');
        return;
      }

      setValidateNummerError(undefined);

      // When the nummer is not null, patch the hoofdstuk
      patchHoofdstuk.mutate(
        {
          id,
          data: {
            Nummer: nummer,
          },
        },
        {
          onSuccess: () => setPatchNummerError(undefined),
          onError: (error) => setPatchNummerError(ErrorMessage.getLocalErrorMessage(error)),
        }
      );
    },
    [id, patchHoofdstuk],
    500
  );

  /**
   * Callback to delete the hoofdstuk
   */
  const onDelete = () => {
    if (!hoofdstuk.ID) return;

    openModal('deleteHoofdstuk', {
      hoofdstuk,
      links,
      besluitId,
      bedrijfLineageId,
    });
  };

  /**
   * Update TextEditor when the editor is not editable
   */
  useEffect(() => {
    if (textEditorRef.current && !userHasReservering) {
      textEditorRef.current.commands.setContent(hoofdstuk.Titel ?? '');
    }
  }, [hoofdstuk.Titel, userHasReservering]);

  /**
   * Check if hoofdstuk contains a slot object
   **/
  const containsSlotObject = links.some((paragraafLink) => {
    if (paragraafLink.PHB.Type === 'MBIO') return true;

    return paragraafLink.subparagrafen.some((subparagraafLink) => {
      if (subparagraafLink.SPPB.Type === 'MBIO') return true;

      return subparagraafLink.voorschriften.some(
        (voorschriftLink) => voorschriftLink.VSPB.Type === 'MBIO'
      );
    });
  });

  const canIntrekken =
    userHasReservering &&
    objectState.state === 'vigerend' &&
    !objectState.slotState &&
    !containsSlotObject;
  const canUndoIntrekken =
    userHasReservering && objectState.state === 'deleted' && !objectState.slotState;
  const canBeDeleted = userHasReservering && objectState.state === 'new' && !objectState.slotState;
  const hasNummerError = !!patchNummerError || !!validateNummerError;
  const hasError = !!patchNummerError || !!patchTitelError || !!validateNummerError;

  return (
    <>
      <ObjectRow
        id={id}
        lineageId={hoofdstuk.Lineage}
        type="hoofdstuk"
        objectState={objectState}
        hasError={hasError}
      >
        <ObjectRow.State state={objectState.state} />

        <ObjectRow.Number>
          <label htmlFor={id} className="sr-only">
            Hoofdstuk nummer
          </label>

          <input
            autoComplete="off"
            id={`hoofdstuk-nummer-${id}`}
            data-testid="number-input"
            disabled={!objectState.isEditable || editorMode !== 'extended'}
            className={twJoin(
              'numbering-input mr-4 mt-2 border-0 bg-transparent pr-1 text-right text-gray-500 outline-0',
              objectState.state === 'deleted' && 'line-through',
              objectState.isEditable &&
                editorMode === 'extended' &&
                'hover:ring-1 hover:ring-inset hover:ring-gray-400',
              'focus:bg-white',
              hasNummerError && 'ring-1 ring-inset ring-digi-v-color-danger',
              !hasNummerError && 'focus:ring-1 focus:ring-inset focus:ring-gray-400',
              objectState.slotState && 'bg-gray-200/25'
            )}
            value={`${Number.isInteger(nummer) ? nummer : ''}`}
            placeholder="-"
            type="text"
            pattern="\d*"
            maxLength={2}
            onChange={(e) => {
              // Filter numbers from value
              const value = e.target.value;
              const onlyNumbers = value.replace(/\D/g, '');
              const nummer = parseInt(onlyNumbers);

              // Check if the value is a number, if not, set the value to null
              const newNummer = Number.isInteger(nummer) ? nummer : null;

              setNummer(newNummer);
              onChangeNummerDebounced(newNummer);
            }}
            onFocus={(e) => e.target.select()}
          />
        </ObjectRow.Number>

        <ObjectRow.Body>
          <div className="grow-2 w-full">
            <TextEditor
              editorRef={textEditorRef}
              editable={objectState.isEditable}
              deleted={objectState.state === 'deleted'}
              menuOptions={['superscript', 'subscript', 'highlight']}
              initialText={hoofdstuk.Titel}
              id={id}
              testId="texteditor"
              variant="clean"
              placeholder="Voer de titel van het hoofdstuk in"
              classes={{
                default: twJoin(
                  'ring-inset transition-all duration-100 ease-in',
                  'px-1 py-0.5 text-2xl font-medium',
                  !patchTitelError && 'hover:ring-1 hover:ring-gray-400',
                  patchTitelError &&
                    'ring-1 ring-digi-v-color-danger hover:ring-digi-v-color-danger'
                ),
                focused: 'ring-1 ring-gray-400 bg-white',
              }}
              singleLine
              onUpdate={patchTitelDebounced}
              onCreate={({ editor }) => {
                const lastCreatedObject = getLastCreatedObject();

                const isNew = lastCreatedObject?.Lineage === hoofdstuk.Lineage;

                if (isNew && !hoofdstuk.Titel) {
                  // There's race condition in TipTap where the editor is created but methods can't be called on it.
                  // This is a temporary workaround to focus the editor after it's created.
                  setTimeout(() => editor?.commands.focus(), 50);
                }
              }}
            />
          </div>
        </ObjectRow.Body>

        {canBeDeleted && (
          <ObjectRow.Icon
            icon={RegularTrash}
            className="text-digi-v-color-danger"
            onClick={onDelete}
            testId="delete"
          />
        )}

        {canUndoIntrekken && (
          <ObjectRow.ButtonOverlay>
            <PillButton
              icon={SolidClockRotateLeft}
              onClick={() => {
                openModal('undoIntrekkenHoofdstuk', {
                  id,
                  nummer: hoofdstuk.Nummer,
                  onSuccess: () => {
                    navigate({
                      to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
                      replace: true,
                      params: {
                        bedrijfLineageId,
                        besluitId,
                        hoofdstukId: hoofdstuk.Vorig ?? '',
                      },
                    });
                  },
                });
              }}
            >
              Intrekken ongedaan maken
            </PillButton>
          </ObjectRow.ButtonOverlay>
        )}

        {canIntrekken && (
          <ObjectRow.ButtonOverlay>
            <PillButton
              variant="white"
              className="shadow-md"
              onClick={() => {
                openModal('intrekkenHoofdstuk', {
                  hoofdstuk,
                  besluitId,
                  onSuccess: (newHoofdstuk) => {
                    navigate({
                      to: '/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId',
                      replace: true,
                      params: {
                        bedrijfLineageId,
                        besluitId,
                        hoofdstukId: newHoofdstuk.ID!,
                      },
                    });
                  },
                });
              }}
            >
              Hoofdstuk intrekken
            </PillButton>
          </ObjectRow.ButtonOverlay>
        )}
      </ObjectRow>

      {patchNummerError && <WarningInline description={patchNummerError} center />}
      {patchTitelError && <WarningInline description={patchTitelError} center />}
      {validateNummerError && <WarningInline description={validateNummerError} center />}
    </>
  );
};
