import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postFixLinkPhb, postFixParagraaf } from '@/api/generated/digiVAPI';
import { PHBSchema, ParagraafSchema } from '@/api/generated/digiVAPI.schemas';
import { QueryError } from '@/utils/createQuery';
import { pick } from '@/utils/pick';

import { useLinksInHoofdstuk, usePHB } from '../links';
import { useParagraaf, useParagraafId } from '../paragraaf';
import { LinkParagraaf } from '../utils/getLinksInHoofdstuk';

/**
 * This mutation can be used to start editing a published paragraaf.
 *
 * When we want to edit a published paragraaf, we first need to create a new paragraaf in the lineage.
 */
export const useCreateParagraafInLineage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { PHB: PHBSchema; paragraaf: ParagraafSchema },
    QueryError,
    { paragraaf: ParagraafSchema; link: LinkParagraaf }
  >({
    mutationFn: async ({ paragraaf, link }) => {
      const newParagraaf = await postFixParagraaf({
        ...pick(paragraaf, ['Grondslag', 'Lineage', 'Nummer', 'Stop_Lineage', 'Titel']),
        Ontwerp: 1,
        Vorig: paragraaf.ID,
        Index: paragraaf.Index + 1,
      });

      const PHB = await postFixLinkPhb({
        ...pick(link.PHB, ['Besluit', 'Rang', 'Hoofdstuk']),
        Paragraaf: newParagraaf.ID!,
        Type: 'Eigen',
      });

      return {
        PHB,
        paragraaf: newParagraaf,
      };
    },
    onSuccess: async ({ paragraaf }) => {
      queryClient.setQueryData<ParagraafSchema>(useParagraafId.getKey(paragraaf.ID!), paragraaf);

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: useParagraaf.key }),
        queryClient.invalidateQueries({ queryKey: usePHB.key }),
        queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key }),
      ]);
    },
  });
};
