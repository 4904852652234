import { useEventListener, useIsomorphicLayoutEffect } from '@react-hookz/web';
import { useCallback, useState } from 'react';

export function useElementSize() {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const updateSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener(window, 'resize', updateSize);

  useIsomorphicLayoutEffect(() => {
    updateSize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size, updateSize] as const;
}
