import { useWindowSize } from '@react-hookz/web';
import { ReactNode, forwardRef, useCallback, useEffect, useMemo } from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  DndProvider,
  useDrag,
  useDragDropManager,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { twJoin } from 'tailwind-merge';

import { useRequestAnimationLoop } from '@/utils';

import { DraggableEditorContext, useDraggableEditorContext } from './DraggableEditorContext';

interface DraggableVoorschriftProps {
  path: [number, number, number];
  children: (props: {
    dragHandleRef: ConnectDragSource;
    dragPreviewRef: ConnectDragPreview;
  }) => ReactNode;
}

interface DraggableSubparagraafProps {
  path: [number, number];
  children: (props: {
    dragHandleRef: ConnectDragSource;
    dragPreviewRef: ConnectDragPreview;
  }) => ReactNode;
}

interface DraggableParagraafProps {
  index: number;
  children: (props: {
    dragHandleRef: ConnectDragSource;
    dragPreviewRef: ConnectDragPreview;
  }) => ReactNode;
}

interface DroppableParagraafProps {
  onDrop: (from: { index: number }) => void;
  method: 'prepend' | 'append';
  index: number;
}

interface DroppableSubparagraafProps {
  path: [number, number];
  onDrop: (from: [number, number]) => void;
  method: 'prepend' | 'append';
}

interface DroppableVoorschriftProps {
  path: [number, number, number];
  onDrop: (from: [number, number, number]) => void;
  method: 'prepend' | 'append';
}

interface DroppableContainerProps {
  isOver: boolean;
  show: boolean;
}

interface DraggableContainerProps {
  isDragging: boolean;
  children: ReactNode;
}

const getVoorschriftItemType = (
  paragraafIndex: number,
  subparagraafIndex: number,
  allowReparenting?: boolean
) => (allowReparenting ? 'voorschrift' : `voorschrift-${paragraafIndex}-${subparagraafIndex}`);
const getSubparagraafItemType = (paragraafIndex: number, allowReparenting?: boolean) =>
  allowReparenting ? 'subparagraaf' : `subparagraaf-${paragraafIndex}`;

export const DraggableEditor = ({
  children,
  allowReparenting,
}: {
  children: ReactNode;
  allowReparenting?: boolean;
}) => {
  const contextValue = useMemo(() => ({ allowReparenting }), [allowReparenting]);

  return (
    <DraggableEditorContext.Provider value={contextValue}>
      <DndProvider backend={HTML5Backend}>
        <ScrollManager />
        {children}
      </DndProvider>
    </DraggableEditorContext.Provider>
  );
};

export const DraggableParagraaf = ({ index, children }: DraggableParagraafProps) => {
  const [{ isDragging }, dragHandleRef, dragPreviewRef] = useDrag(
    () => ({
      type: 'paragraaf',
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [index]
  );

  return (
    <DraggableContainer isDragging={isDragging}>
      {children({ dragHandleRef, dragPreviewRef })}
    </DraggableContainer>
  );
};

export const DraggableSubparagraaf = ({ path, children }: DraggableSubparagraafProps) => {
  const { allowReparenting } = useDraggableEditorContext();

  const [{ isDragging }, dragHandleRef, dragPreviewRef] = useDrag(
    {
      type: getSubparagraafItemType(path[0], allowReparenting),
      item: () => path,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    },
    [path]
  );

  return (
    <DraggableContainer isDragging={isDragging}>
      {children({ dragHandleRef, dragPreviewRef })}
    </DraggableContainer>
  );
};

export const DraggableVoorschrift = ({ path, children }: DraggableVoorschriftProps) => {
  const { allowReparenting } = useDraggableEditorContext();

  const [{ isDragging }, dragHandleRef, dragPreviewRef] = useDrag(
    {
      type: getVoorschriftItemType(path[0], path[1], allowReparenting),
      item: () => path,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    },
    [path]
  );

  return (
    <DraggableContainer isDragging={isDragging}>
      {children({ dragHandleRef, dragPreviewRef })}
    </DraggableContainer>
  );
};

export const DroppableParagraaf = ({ index, onDrop, method }: DroppableParagraafProps) => {
  const [{ isOver, show }, drop] = useDrop(
    () => ({
      accept: 'paragraaf',
      drop: (from: { index: number }) => {
        onDrop(from);
      },
      collect: (monitor) => {
        /**
         * The droppable should only be shown, when a draggable is dragged that is not before / after the drop zone.
         */
        const draggableIndex = monitor.getItem()?.index;
        const newIndex = index + (method === 'append' ? 1 : 0);

        const isSibling = draggableIndex === newIndex || draggableIndex + 1 === newIndex;

        return {
          isOver: !!monitor.isOver(),
          show: monitor.getItemType() === 'paragraaf' && !isSibling,
        };
      },
    }),
    [index, onDrop]
  );

  return <DroppableContainer ref={drop} isOver={isOver} show={show} />;
};

export const DroppableSubparagraaf = ({ path, onDrop, method }: DroppableSubparagraafProps) => {
  const { allowReparenting } = useDraggableEditorContext();

  const itemType = useMemo(
    () => getSubparagraafItemType(path[0], allowReparenting),
    [path, allowReparenting]
  );

  const [{ isOver, show }, drop] = useDrop(
    () => ({
      accept: itemType,
      drop: (from: [number, number]) => {
        onDrop(from);
      },
      collect: (monitor) => {
        /**
         * The droppable should only be shown, when a draggable is dragged that is not before / after the drop zone.
         */
        const draggablePath = monitor.getItem() || [];
        const newIndex = path[1] + (method === 'append' ? 1 : 0);

        const isReparenting = draggablePath[0] !== path[0];
        const isSibling =
          !isReparenting && (draggablePath[1] === newIndex || draggablePath[1] + 1 === newIndex);

        return {
          isOver: !!monitor.isOver(),
          show: monitor.getItemType() === itemType && !isSibling,
        };
      },
    }),
    [path, itemType, onDrop]
  );

  return <DroppableContainer isOver={isOver} show={show} ref={drop} />;
};

export const DroppableVoorschrift = ({ path, onDrop, method }: DroppableVoorschriftProps) => {
  const { allowReparenting } = useDraggableEditorContext();

  const itemType = useMemo(
    () => getVoorschriftItemType(path[0], path[1], allowReparenting),
    [path, allowReparenting]
  );

  const [{ isOver, show }, drop] = useDrop(
    () => ({
      accept: itemType,
      drop: onDrop,
      collect: (monitor) => {
        /**
         * The droppable should only be shown, when a draggable is dragged that is not before / after the drop zone.
         */
        const fromPath = monitor.getItem() || [];
        const newIndex = path[2] + (method === 'append' ? 1 : 0);

        const isReparenting = path[0] !== fromPath[0] || path[1] !== fromPath[1];
        const isSibling =
          !isReparenting && (fromPath[2] === newIndex || fromPath[2] + 1 === newIndex);

        return {
          isOver: !!monitor.isOver(),
          show: monitor.getItemType() === itemType && !isSibling,
        };
      },
    }),
    [path, itemType, onDrop]
  );

  return <DroppableContainer isOver={isOver} show={show} ref={drop} />;
};

const DraggableContainer = ({ children, isDragging }: DraggableContainerProps) => (
  <div
    className={twJoin(
      'relative',
      isDragging &&
        "before:absolute before:inset-0 before:block before:bg-white before:ring-1 before:ring-theme-blue before:content-['']",
      isDragging && 'after:absolute after:inset-0 after:block after:bg-theme-blue/10'
    )}
  >
    <div className={twJoin(isDragging && 'opacity-0')}>{children}</div>
  </div>
);

const DroppableContainer = forwardRef<HTMLDivElement, DroppableContainerProps>(
  ({ isOver, show }, ref) => {
    return (
      <div className="-mt-4 h-4">
        <div
          ref={ref}
          className={twJoin(
            'relative z-[2] flex h-8 flex-col justify-center transition-opacity',
            show ? 'opacity-1' : 'pointer-events-none opacity-0'
          )}
        >
          <div
            className={twJoin(
              'pointer-events-none h-2 w-full bg-theme-blue/10',
              'border border-dashed',
              isOver ? 'border-theme-blue bg-theme-blue/50' : 'border-theme-blue/50'
            )}
          />
        </div>
      </div>
    );
  }
);

const ScrollManager = () => {
  const MARGIN = 100;
  const SPEED = 15;

  const size = useWindowSize();

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  const moveUp = useCallback(() => window.scrollBy(0, SPEED * -1), []);
  const moveDown = useCallback(() => window.scrollBy(0, SPEED), []);

  const moveUpLoop = useRequestAnimationLoop(moveUp);
  const moveDownLoop = useRequestAnimationLoop(moveDown);

  useEffect(() => {
    let offset = 0;

    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      offset = monitor.getSourceClientOffset()?.y as number;

      if (offset < MARGIN) {
        moveUpLoop.start();
      } else {
        moveUpLoop.end();
      }

      if (offset > size.height - MARGIN) {
        moveDownLoop.start();
      } else {
        moveDownLoop.end();
      }
    });

    return unsubscribe;
  }, [monitor, moveDownLoop, moveUpLoop, size.height]);

  return <></>;
};
