import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  DocumentTitle,
  HeadingLarge,
  Input,
  Modal,
  TextButton,
  Warning,
  WarningProps,
} from '@/components';
import Navbar from '@/components/shared/Navbar';
import { eStrings } from '@/constants/strings';
import { useUserStore } from '@/stores/user/useUserStore';

type FormValues = {
  email: string;
  password: string;
};

/**
 * Route
 */
export const Route = createFileRoute('/login')({
  component: LoginComponent,
});

/**
 * Route component
 */
function LoginComponent() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loginError, setLoginError] = useState<WarningProps>();
  const navigate = useNavigate();

  const { login, userIsLoading } = useUserStore(({ login, userIsLoading }) => ({
    login,
    userIsLoading,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async ({ email, password }) => {
    try {
      await login({ email, password });

      navigate({ to: '/', search: { p: 1 } });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.request.status === 401) {
        setLoginError({
          variant: 'danger',
          title: 'Inloggen mislukt',
          description:
            'Er ging iets mis bij het inloggen. Controleer het e-mailadres en wachtwoord',
          className: 'mb-3',
        });
      } else
        setLoginError({
          variant: 'danger',
          title: 'Er is een fout opgetreden, probeer het later opnieuw',
          size: 'small',
          className: 'mb-3',
        });
    }
  };

  return (
    <>
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Inloggen']} />

      <Navbar />

      <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <form onSubmit={handleSubmit(onSubmit)}>
            <HeadingLarge as="h1" value={eStrings.LBL_INLOGPAGINA_TITEL} />
            <p className="my-4">{eStrings.TXT_INLOGPAGINA_SUBTITEL}</p>
            {loginError && <Warning {...loginError} />}
            <Input
              {...register('email', { required: 'Dit veld is verplicht' })}
              id="login-email"
              label="E-mailadres"
              type="email"
              error={errors.email}
              required
              placeholder="Vul je e-mailadres in"
            />

            <Input
              {...register('password', { required: 'Dit veld is verplicht' })}
              id="login-password"
              label="Wachtwoord"
              type="password"
              error={errors.password}
              required
              placeholder="Geef je wachtwoord op"
              className="mt-3"
            />

            <div className="mt-6">
              <Button
                id="login-submit"
                type="submit"
                state={userIsLoading ? 'loading' : undefined}
                className="w-full justify-center"
              >
                Inloggen
              </Button>
            </div>
            <div className="mt-6 flex items-center justify-between">
              <TextButton
                variant="black"
                type="button"
                onClick={() => setForgotPassword(!forgotPassword)}
              >
                Wachtwoord vergeten?
              </TextButton>
            </div>
          </form>
        </div>

        <Modal
          open={forgotPassword}
          onClose={() => setForgotPassword(false)}
          title="Wachtwoord vergeten"
        >
          <p>
            Om een nieuw wachtwoord in te stellen kun je contact opnemen met {eStrings.LBL_EMAIL}.
            Daar word je verder geholpen.
          </p>

          <Modal.Footer>
            <Modal.CancelButton />

            <Button asChild>
              <a
                href={`mailto:${eStrings.LBL_EMAIL}?subject=Wachtwoord vergeten"`}
                rel="noreferrer"
                target="_blank"
              >
                Neem contact op
              </a>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
