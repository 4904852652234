import { useState } from 'react';

import { LinkParagraaf, useDeleteParagraafId } from '@/api/queries/objects';
import { Checkbox, Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface DeleteParagraafProps {
  paragraafLink: LinkParagraaf;
  nummer: string;
}

export const DELETE_PARAGRAAF_ID = 'deleteParagraaf';

export const DeleteParagraaf = modal(DELETE_PARAGRAAF_ID, ({ data, props }) => {
  const { nummer, paragraafLink } = data;

  const [isChecked, setIsChecked] = useState(false);

  const deleteParagraaf = useDeleteParagraafId();

  const modalState = useModalState();

  // Subtract 1 because of the transparent subparagraaf
  const hasChildren =
    paragraafLink.subparagrafen
      .map((subparagraaf) => [...subparagraaf.voorschriften, subparagraaf])
      .flat(2).length - 1;

  return (
    <Modal {...props} state={modalState.state} title={`Verwijderen paragraaf ${nummer}`}>
      {hasChildren ? (
        <>
          <p>Onderliggende onderdelen zullen ook worden verwijderd.</p>
          <Checkbox
            name="deleteChildrenCheckbox"
            id="deleteChildrenCheckbox"
            label={`Ik wil paragraaf ${nummer} inclusief onderliggende onderdelen verwijderen`}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mt-6"
            checked={isChecked}
          />
        </>
      ) : (
        <p>Je staat op het punt om paragraaf {nummer} te verwijderen.</p>
      )}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="delete"
          successLabel="Verwijderd"
          variant="red"
          disabled={!!hasChildren && !isChecked}
          onClick={() => {
            modalState.action('delete');

            deleteParagraaf.mutate(
              {
                id: paragraafLink.PHB.Paragraaf,
              },
              {
                onSuccess: () => {
                  modalState.success('delete');
                },
                onError: (error) => {
                  const errorMessage = ErrorMessage.getLocalErrorMessage(error);

                  if (errorMessage) {
                    modalState.error('delete', {
                      title: 'Mislukt!',
                      description: errorMessage,
                    });
                  }
                },
              }
            );
          }}
        >
          Verwijderen
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
