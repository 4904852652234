import { ReactNode, useEffect, useState } from 'react';

import { useUserStore } from '@/stores/user';

export type Allowed = 'Vergunningverlener' | 'Beheerder' | 'non-auth';

export const RoleBasedAccess = ({
  allowed = [],
  area,
  children,
}: {
  allowed: Allowed[];
  area: ('landelijk' | 'omgevingsdienst')[];
  children: ReactNode;
}) => {
  const {
    userRole,
    userIsLoading,
    isInitialised,
    area: userArea,
  } = useUserStore(({ userRole, userIsLoading, isInitialised, area }) => ({
    userRole,
    userIsLoading,
    isInitialised,
    area,
  }));

  const hasCorrectRole = userRole ? allowed.some((role) => userRole.includes(role)) : false;
  const hasCorrectArea = userArea ? area.includes(userArea) : false;

  if (userIsLoading || !isInitialised) {
    return null;
  } else if (!hasCorrectRole || !hasCorrectArea) {
    return null;
  } else {
    return <>{children}</>;
  }
};

export const useRoleBasedAccess = (allowed: Allowed[] = []) => {
  const { userRole, userIsLoading } = useUserStore(({ userRole, userIsLoading }) => ({
    userRole,
    userIsLoading,
  }));

  const userAllowed = userRole && allowed.some((role) => userRole.includes(role));

  const [hasAccess, setHasAccess] = useState(!userIsLoading && userAllowed);

  useEffect(() => {
    setHasAccess(!userIsLoading && userAllowed);
  }, [userIsLoading, userAllowed]);

  return hasAccess;
};
