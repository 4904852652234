import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { ModalIds, ModalProps } from '../config';

interface ModalState {
  openModal: <T extends ModalIds>(id: T, props: ModalProps[T]) => void;
  onClose: (uuid: string) => () => void;
  afterLeave: (uuid: string) => () => void;
  activeModals: {
    isOpen: boolean;
    id: ModalIds;
    uuid: string;
    data: ModalProps[ModalIds];
  }[];
  unmountModals: () => void;
}

const useModalStore = create<ModalState>()((set, get) => ({
  activeModals: [],
  openModal: (id, data) => {
    const { activeModals } = get();

    set(() => ({
      activeModals: [
        ...activeModals,
        {
          isOpen: true,
          id,
          data,
          uuid: uuidv4(),
        },
      ],
    }));
  },
  onClose: (uuid) => () => {
    set(() => {
      const { activeModals } = get();

      return {
        activeModals: activeModals.map((modal) => {
          if (modal.uuid === uuid) {
            modal.isOpen = false;
          }

          return modal;
        }),
      };
    });
  },
  afterLeave: (uuid) => () => {
    const { activeModals } = get();

    return set({
      activeModals: activeModals.filter((modal) => modal.uuid !== uuid),
    });
  },
  unmountModals: () => {
    set({
      activeModals: [],
    });
  },
}));

/**
 * Hook that will pass the props and state of a modal to the modal component.
 *
 * @param id
 * @returns
 */
export const useModal = <T extends ModalIds>(id: T, uuid: string) => {
  const activeModal = useModalStore(({ activeModals }) => {
    return activeModals.find((modal) => modal.id === id && modal.uuid === uuid);
  }, shallow);

  const handlers = useModalStore(
    ({ onClose, afterLeave }) => ({
      onClose: onClose(uuid),
      afterLeave: afterLeave(uuid),
    }),
    shallow
  );

  if (!activeModal) return;

  return {
    data: activeModal.data,
    props: {
      ...handlers,
      open: activeModal.isOpen,
    },
  } as {
    data: ModalProps[T];
    props: {
      open: boolean;
      onClose: () => void;
      afterLeave: () => void;
    };
  };
};

/**
 * Hook that will return a function to open modals
 */
export const useOpenModal = () => useModalStore((state) => state.openModal);

/**
 * Hook that will return the active modals, you generally don't want to use this hook.
 */
export const useActiveModals = () => useModalStore((state) => state.activeModals);

export const useUnmountModals = () => useModalStore((state) => state.unmountModals);
