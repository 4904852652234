import {
  FilterSchema,
  HoofdstukSchema,
  OverwegingHoofdstukSchema,
  ParagraafSchema,
  SubparagraafSchema,
  VoorschriftHistorischBesluitSchema,
  VoorschriftSchema,
} from '@/api';
import { useHistorischBesluitLink } from '@/api/queries/historischBesluit';
import {
  fetchQueryDataHoofdstukkenWithEdits,
  filterEigenLinks,
  filterMostRecentHoofdstukPerLineage,
  useHoofdstukkenWithOverweging,
  useLinksInHoofdstuk,
  useParagraaf,
  useSubparagraaf,
  useVoorschrift,
} from '@/api/queries/objects';
import { useOverwegingByHoofdstukLineageId } from '@/api/queries/overwegingHoofdstuk';
import { queryClient } from '@/services/queryClient';
import { chunk } from '@/utils/chunk/chunk';
import { createInString } from '@/utils/createInString';
import { fetchAllPages } from '@/utils/fetchAllPages';

export type DocxHoofdstuk = {
  hoofdstuk: HoofdstukSchema;
  overweging?: OverwegingHoofdstukSchema;
  paragrafen: DocxParagraaf[];
};

type DocxParagraaf = {
  paragraaf: ParagraafSchema;
  subparagrafen: DocxSubparagraaf[];
};

type DocxSubparagraaf = {
  subparagraaf: SubparagraafSchema;
  voorschriften: DocxVoorschrift[];
};

export type DocxVoorschrift = {
  voorschrift: VoorschriftSchema;
  historischBesluitLink: VoorschriftHistorischBesluitSchema | null;
};

const VSPBFilter: FilterSchema[] = [{ model: 'VSPB', field: 'Type', op: 'eq', value: 'Eigen' }];

export const getEntireVergunning = async (besluitId: string): Promise<DocxHoofdstuk[]> => {
  const [
    hoofdstukkenResponse,
    hoofdstukkenWithOverwegingResponse,
    paragrafenResponse,
    subparagraafResponse,
    voorschriftenResponse,
  ] = await Promise.all([
    fetchQueryDataHoofdstukkenWithEdits({ besluitId }, {}, queryClient),
    queryClient.fetchQuery(useHoofdstukkenWithOverweging.getOptions({ besluitId })),
    fetchAllParagrafen(besluitId),
    fetchAllSubparagrafen(besluitId),
    fetchAllVoorschriften(besluitId),
  ]);

  const voorschriftLineageIds = voorschriftenResponse.map(({ Lineage }) => Lineage);

  const historischBesluitLinks = await fetchHistorischBesluitLinks(voorschriftLineageIds);

  const mergedHoofdstukken = filterMostRecentHoofdstukPerLineage([
    ...(hoofdstukkenWithOverwegingResponse.objects ?? []),
    ...hoofdstukkenResponse,
  ]);

  const hoofdstukken: DocxHoofdstuk[] = await Promise.all(
    mergedHoofdstukken?.map(async (hoofdstuk) => {
      const overwegingResponse = await queryClient.fetchQuery(
        useOverwegingByHoofdstukLineageId.queryOptions({
          hoofdstukLineageId: hoofdstuk.Lineage,
          besluitId,
        })
      );

      const overweging = overwegingResponse.objects?.[0];

      const links = await queryClient.fetchQuery(
        useLinksInHoofdstuk.getOptions(
          { hoofdstukId: hoofdstuk.ID ?? '', besluitId, VSPBFilter },
          { staleTime: Infinity }
        )
      );

      const eigenLinks = filterEigenLinks(links);

      const paragrafen =
        eigenLinks
          .map((paragraafLink) => {
            const paragraaf = paragrafenResponse.find(
              (paragraaf) => paragraaf.ID === paragraafLink.PHB.Paragraaf
            );

            if (!paragraaf) return;

            const docxParagraaf: DocxParagraaf = {
              paragraaf,
              subparagrafen: [],
            };

            paragraafLink.subparagrafen.forEach((subparagraafLink) => {
              const subparagraaf = subparagraafResponse.find(
                ({ ID }) => ID === subparagraafLink.SPPB.Subparagraaf
              );

              if (!subparagraaf) return;

              docxParagraaf.subparagrafen.push({
                subparagraaf,
                voorschriften: subparagraafLink.voorschriften
                  .map(({ VSPB }) => {
                    const voorschrift = voorschriftenResponse.find(
                      ({ ID }) => ID === VSPB.Voorschrift
                    );

                    if (!voorschrift) return;

                    const historischBesluitLink = historischBesluitLinks?.find(
                      ({ Voorschrift_lineage }) => Voorschrift_lineage === voorschrift.Lineage
                    );

                    return {
                      voorschrift,
                      historischBesluitLink: historischBesluitLink ?? null,
                    };
                  })
                  .filter((val): val is DocxVoorschrift => Boolean(val)),
              });
            });

            return docxParagraaf;
          })
          .filter((val): val is DocxParagraaf => Boolean(val)) ?? [];

      return {
        hoofdstuk,
        paragrafen,
        overweging,
      };
    }) ?? []
  );

  return hoofdstukken;
};

const fetchHistorischBesluitLinks = async (voorschriftLineageIds: string[]) => {
  const chunkedIds = chunk(voorschriftLineageIds, 10);

  if (!chunkedIds.length) return null;

  const responses = await Promise.all(
    chunkedIds.map(async (ids) => {
      return queryClient.fetchQuery(
        useHistorischBesluitLink.getOptions({
          page: 1,
          size: 100,
          filter: [
            {
              model: 'VoorschriftHistorischBesluit',
              field: 'Voorschrift_lineage',
              op: 'in',
              value: createInString(ids),
            },
          ],
        })
      );
    })
  );

  const historischBesluitLinks = responses
    .flat(1)
    .map((link) => link.objects ?? [])
    .flat(1);

  return historischBesluitLinks;
};

const fetchAllParagrafen = (besluitId: string) => {
  return fetchAllPages((page) =>
    queryClient.fetchQuery(
      useParagraaf.getOptions({
        page,
        size: 100,
        filter: [
          {
            model: 'Besluit',
            field: 'ID',
            op: 'eq',
            value: besluitId,
          },
        ],
        sort: [
          {
            model: 'PHB',
            field: 'Rang',
            direction: 'asc',
          },
        ],
      })
    )
  );
};

const fetchAllVoorschriften = (besluitId: string) => {
  return fetchAllPages((page) =>
    queryClient.fetchQuery(
      useVoorschrift.getOptions({
        page,
        size: 100,
        filter: [
          {
            model: 'Besluit',
            field: 'ID',
            op: 'eq',
            value: besluitId,
          },
          ...VSPBFilter,
        ],
        sort: [
          {
            model: 'VSPB',
            field: 'Rang',
            direction: 'asc',
          },
        ],
      })
    )
  );
};

const fetchAllSubparagrafen = (besluitId: string) => {
  return fetchAllPages((page) => {
    return queryClient.fetchQuery(
      useSubparagraaf.getOptions({
        page,
        size: 100,
        filter: [
          {
            model: 'Besluit',
            field: 'ID',
            op: 'eq',
            value: besluitId,
          },
        ],
        sort: [
          {
            model: 'SPPB',
            field: 'Rang',
            direction: 'asc',
          },
        ],
      })
    );
  });
};
