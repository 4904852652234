import { useQueryClient } from '@tanstack/react-query';

import { usePatchFixParagraafId } from '@/api/generated/digiVAPI';
import { ParagraafSchema } from '@/api/generated/digiVAPI.schemas';

import { useParagraaf, useParagraafId } from '../paragraaf';

export function usePatchParagraafId({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return usePatchFixParagraafId({
    mutation: {
      mutationKey,
      /**
       * Optimistically update paragraaf, necessary for the UI to update immediately
       */
      onMutate: async (newParagraaf) => {
        const queryKey = useParagraafId.getKey(newParagraaf.id);

        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey });

        const previousParagraaf = queryClient.getQueryData<ParagraafSchema>(queryKey);

        if (previousParagraaf) {
          queryClient.setQueryData<ParagraafSchema>(queryKey, {
            ...previousParagraaf,
            ...newParagraaf.data,
          });
        }

        return { id: newParagraaf.id, previousParagraaf };
      },
      onError: (_, __, context) => {
        if (context?.previousParagraaf) {
          queryClient.setQueryData<ParagraafSchema>(
            useParagraafId.getKey(context.id),
            context.previousParagraaf
          );
        }
      },
      onSettled: (data) => {
        if (data?.ID) {
          queryClient.setQueryData(useParagraafId.getKey(data.ID), data);
        }

        return queryClient.invalidateQueries({ queryKey: useParagraaf.key });
      },
    },
  });
}
