// import { useEditorStore } from "@/stores/EditorStore";
// import { SIDEBAR_RIGHT_WIDTH } from "../SidebarRight";
import { useMediaQuery } from '@react-hookz/web';
import cx from 'classnames';
import { ReactNode, forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { LoaderSpinningIcon, Transitions } from '@/components';

import { useEditorStore } from '../-store/useEditorStore';

const CENTERING_THRESHOLD = 1440;
const SIDEBAR_RIGHT_WIDTH = 400;

interface EditorContainerProps {
  children: ReactNode;
  title?: string;
  titleAsHtml?: boolean;
  nummer?: string | number;
  pl?: `pl-${string}`;
  pr?: `pr-${string}`;
  ariaLabel?: string;
  isLoading?: boolean;
}

export const EditorContainer = forwardRef<HTMLDivElement, EditorContainerProps>(
  (
    { children, title, titleAsHtml, nummer, pl = 'pl-14', pr = 'pr-14', ariaLabel, isLoading },
    ref
  ) => {
    const isSidebarRightOpen = useEditorStore((state) => state.isSidebarRightOpen);
    const setContentMinHeight = useEditorStore((state) => state.setContentMinHeight);
    const contentMinHeight = useEditorStore((state) => state.contentMinHeight);

    const titleRef = useRef<HTMLHeadingElement>(null);

    const [offsetX, setOffsetX] = useState<number>(0);
    const [editorPadding, setEditorPadding] = useState<number | undefined>();

    // Generate minHeight property that ensures it always fills the page vertically
    useLayoutEffect(() => {
      const editorEl = document.getElementById('editor');
      const titleHeight = titleRef.current?.offsetHeight ?? 0;
      const editorOffsetTop = editorEl ? editorEl.offsetTop : 0;

      const editorNav = document.getElementById('editor-nav');
      const editorNavHeight = editorNav ? editorNav.offsetHeight : 0;

      const editorPadding = editorOffsetTop - editorNavHeight;

      const windowHeight = window.innerHeight;

      // If there is a addNewItem component provided we calculate in an extra margin for it
      const heightOfEditorContainer = windowHeight - editorOffsetTop - editorPadding - titleHeight;

      setContentMinHeight(heightOfEditorContainer);
      setEditorPadding(editorPadding);
    }, [setContentMinHeight]);

    const isBeyondCenteringThreshold = useMediaQuery(`(min-width: ${CENTERING_THRESHOLD}px)`);

    useEffect(() => {
      if (isSidebarRightOpen && isBeyondCenteringThreshold) {
        setOffsetX((SIDEBAR_RIGHT_WIDTH * -1) / 2);
      } else {
        setOffsetX(0);
      }
    }, [isSidebarRightOpen, isBeyondCenteringThreshold]);

    return (
      <main ref={ref} className="relative w-full" aria-label={ariaLabel}>
        <div
          id="editor-container"
          style={{
            marginBottom: `${editorPadding}px`,
            left: `${offsetX}px`,
          }}
          className={cx(
            'relative bg-white py-8 shadow-editor transition-all duration-300 ease-out',
            pl,
            pr,
            isLoading && 'pointer-events-none'
          )}
          // Apply HTML native inert property to prevent focus on elements]
          // It's typecasted because it's not yet in the typescript definitions of React
          {...({
            inert: isLoading ? '' : undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any)}
        >
          {(nummer || title) && (
            <div ref={titleRef} className="flex pb-5">
              {nummer && <span className={`w-14 pr-3 text-2xl text-gray-300`}>{nummer}</span>}

              {title && titleAsHtml && (
                <h1
                  className="w-full text-2xl font-semibold text-gray-800"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}

              {title && !titleAsHtml && (
                <h1 className="w-full text-2xl font-semibold text-gray-800">{title}</h1>
              )}
            </div>
          )}

          <div style={{ minHeight: `${contentMinHeight}px` }}>{children}</div>
        </div>

        <Transitions.FadeIn show={!!isLoading} unmount appear>
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/90">
            <LoaderSpinningIcon iconClassName="w-8 h-8" />
          </div>
        </Transitions.FadeIn>
      </main>
    );
  }
);
