import { useMatches } from '@tanstack/react-router';
import { useEffect } from 'react';

import { SidePanel } from '@/components';
import { RegularMemoCircleInfo } from '@/icons/components';
import { useEditorStore } from '@/routes/$bedrijfLineageId.editor.$besluitId/-store/useEditorStore';

export const SIDEBAR_RIGHT_WIDTH = 400;
const SIDEBAR_RIGHT_WIDTH_CLASS = 'w-[400px]';

export const SidebarRight = () => {
  const { isSidebarRightOpen, setIsSidebarRightOpen, selectedObject } = useEditorStore(
    ({ isSidebarRightOpen, setIsSidebarRightOpen, selectedObject }) => ({
      isSidebarRightOpen,
      setIsSidebarRightOpen,
      selectedObject,
    })
  );

  const isVoorschriftenPage = useMatches()
    .map((match) => match.routeId)
    .includes('/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId');

  const isEnabled = selectedObject && selectedObject.type === 'voorschrift' && isVoorschriftenPage;

  /**
   * Automatically close the sidebar when it's not a voorschriften page, or if there's no activeItem
   */
  useEffect(() => {
    if (!isEnabled) {
      setIsSidebarRightOpen(false);
    }
  }, [isEnabled, setIsSidebarRightOpen]);

  return (
    <>
      <SidePanel
        open={isSidebarRightOpen}
        onOpen={() => setIsSidebarRightOpen(true)}
        onClose={() => setIsSidebarRightOpen(false)}
        id="sidebar"
        xPosition="right"
        yPosition="bottom"
        height="h-[calc(100vh-theme(spacing.editor-navigation-height))]"
        buttonProps={{
          disabled: !isEnabled,
          testId: 'sidebar-right-toggle',
          openAriaLabel: 'Open metadata',
          closeAriaLabel: 'Sluit metadata',
          openIcon: RegularMemoCircleInfo,
        }}
        width={SIDEBAR_RIGHT_WIDTH_CLASS}
        testId="sidebar-right"
        ariaLabel="Metadata sidebar"
      >
        {/* To render content in the SidebarRight, use the <SidebarRightContent /> component. */}
        <div id="sidebar-right-portal" />
      </SidePanel>
    </>
  );
};
