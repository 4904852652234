import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { STDMapSchema } from '@/api';
import { StdObjects, StdParagraaf, StdSubparagraaf, useStdObjects } from '@/api/queries/std';
import { InfoCard, LoaderSpinningIcon, WizardCheckbox, WizardCheckboxStatic } from '@/components';

type FormValues = Record<string, boolean | undefined>;

export const StdObjectsForm = ({
  selectedSectie,
  formId,
  allow,
  onValuesChange,
  onSubmit,
  selectAllRef,
  deselectAllRef,
}: {
  formId: string;
  selectedSectie: {
    map: STDMapSchema;
    sectie: STDMapSchema;
  } | null;
  allow: 'hoofdstuk' | 'paragraaf' | 'voorschrift';
  onValuesChange: (values: FormValues) => void;
  onSubmit: (selectedObjects: StdObjects) => void;
  selectAllRef: React.MutableRefObject<() => void>;
  deselectAllRef: React.MutableRefObject<() => void>;
}) => {
  const [paragrafenWithSomeChecked, setParagrafenWithSomeChecked] = useState<string[]>([]);
  const [subparagrafenWithSomeChecked, setSubparagrafenWithSomeChecked] = useState<string[]>([]);

  const objects = useStdObjects(
    { sectieId: selectedSectie?.sectie.ID ?? '' },
    { enabled: !!selectedSectie?.sectie.ID }
  );

  const { register, handleSubmit, watch, reset, setValue, getValues } = useForm<FormValues>();

  /**
   * Call onValuesChange when the form values change
   */
  useEffect(() => {
    const sub = watch((values) => onValuesChange(values));

    return () => sub.unsubscribe();
  }, [watch, onValuesChange]);

  /**
   * Reset the form when the sectieID changes
   */
  useEffect(() => {
    reset();
  }, [selectedSectie?.sectie.ID, reset]);

  /**
   * Set the selectAll and deselectAll refs
   */
  selectAllRef.current = () => {
    const ids = Object.keys(getValues());
    ids.forEach((id) => setValue(id, true));

    setSubparagrafenWithSomeChecked([]);
    setParagrafenWithSomeChecked([]);
  };

  deselectAllRef.current = () => {
    const ids = Object.keys(getValues());
    ids.forEach((id) => setValue(id, false));

    setSubparagrafenWithSomeChecked([]);
    setParagrafenWithSomeChecked([]);
  };

  const getAllOrSomeCheckedStatus = (ids: string[]) => {
    const values = getValues(ids);

    const allChecked = !values.includes(undefined) && !values.includes(false);
    const noneChecked = !values.includes(true);
    const someChecked = !allChecked && !noneChecked;

    return { all: allChecked, some: someChecked };
  };

  /**
   * onChange handlers
   */
  const onParagraafChange = (paragraaf: StdParagraaf, isChecked: boolean) => {
    paragraaf.subparagrafen.forEach((subparagraaf) => {
      setValue(subparagraaf.data.ID!, isChecked);
      subparagraaf.voorschriften.forEach(({ ID }) => setValue(ID!, isChecked));
    });
  };

  const onSubparagraafChange = (
    paragraaf: StdParagraaf,
    subparagraaf: StdSubparagraaf,
    isChecked: boolean
  ) => {
    const subparagraafIds = paragraaf.subparagrafen.map((sp) => sp.data.ID!);

    const paragraafCheck = getAllOrSomeCheckedStatus(subparagraafIds);

    setValue(paragraaf.data.ID!, paragraafCheck.all || paragraafCheck.some);

    setParagrafenWithSomeChecked((ids) => {
      return paragraafCheck.some
        ? [...ids, paragraaf.data.ID!]
        : ids.filter((id) => id !== paragraaf.data.ID);
    });

    subparagraaf.voorschriften.forEach(({ ID }) => setValue(ID!, isChecked));
  };

  const onVoorschriftChange = (paragraaf: StdParagraaf, subparagraaf: StdSubparagraaf) => {
    // Check all voorschriften check status within paragraaf.
    const paragraafVoorschriftIds = paragraaf.subparagrafen.flatMap(({ voorschriften }) =>
      voorschriften.map((v) => v.ID!)
    );

    const paragraafCheck = getAllOrSomeCheckedStatus(paragraafVoorschriftIds);

    setValue(paragraaf.data.ID!, paragraafCheck.all || paragraafCheck.some);

    setParagrafenWithSomeChecked((paragraafIds) => {
      return paragraafCheck.some
        ? [...paragraafIds, paragraaf.data.ID!]
        : paragraafIds.filter((paragraafId) => paragraafId !== paragraaf.data.ID);
    });

    // Check all voorschriften check status within subparagraaf.
    const subparagraafVoorschriftIds = subparagraaf.voorschriften.map((v) => v.ID!);

    const subparagraafCheck = getAllOrSomeCheckedStatus(subparagraafVoorschriftIds);

    setValue(subparagraaf.data.ID!, subparagraafCheck.all || subparagraafCheck.some);

    setSubparagrafenWithSomeChecked((subparagraafIds) => {
      return subparagraafCheck.some
        ? [...subparagraafIds, subparagraaf.data.ID!]
        : subparagraafIds.filter((subparagraafId) => subparagraafId !== subparagraaf.data.ID);
    });
  };

  /**
   * Submit handler
   */
  const submit: SubmitHandler<FormValues> = (values) => {
    if (!objects.data) return;

    const selectedIds: string[] = [];

    for (const key in values) {
      if (values[key]) selectedIds.push(key);
    }

    /**
     * Filter out the selected objects
     */
    const selectedStdObjects: StdObjects = {
      paragrafen: [],
    };

    selectedStdObjects.paragrafen = objects.data.paragrafen
      .map((paragraaf) => ({
        ...paragraaf,
        subparagrafen: paragraaf.subparagrafen
          .map((subparagraaf) => ({
            ...subparagraaf,
            voorschriften: subparagraaf.voorschriften.filter((voorschrift) =>
              selectedIds.includes(voorschrift.ID!)
            ),
          }))
          .filter(
            (subparagraaf) =>
              subparagraaf.voorschriften.length > 0 || selectedIds.includes(subparagraaf.data.ID!)
          ),
      }))
      .filter(
        (paragraaf) =>
          paragraaf.subparagrafen.length > 0 || selectedIds.includes(paragraaf.data.ID!)
      );

    onSubmit(selectedStdObjects);
  };

  /**
   * Rendering logic
   */
  if (!selectedSectie) {
    return null;
  }

  if (objects.isLoading) {
    return (
      <div className="flex h-[300px] w-full items-center justify-center">
        <LoaderSpinningIcon />
      </div>
    );
  }

  if (!objects.data?.paragrafen.length) {
    return <p>Deze map is leeg</p>;
  }

  return (
    <form id={formId} onSubmit={handleSubmit(submit)}>
      {objects.data.paragrafen.map((paragraaf) => {
        const { ID: paragraafId, Toelichting } = paragraaf.data;

        if (!paragraafId) return null;

        const content = paragraaf.data.Titel || '';
        const type = 'paragraaf';

        return (
          <Fragment key={`wizard-checkbox-${paragraafId}`}>
            {Toelichting && (
              <InfoCard testId={`toelichting-${paragraafId}`} description={Toelichting} />
            )}

            {allow === 'voorschrift' ? (
              <WizardCheckboxStatic content={content} type={type} />
            ) : (
              <WizardCheckbox
                {...register(paragraafId, {
                  onChange: (e) => {
                    onParagraafChange(paragraaf, e.target.checked);
                  },
                })}
                id={paragraafId}
                content={content}
                type={type}
                minusIcon={paragrafenWithSomeChecked.includes(paragraafId)}
              />
            )}

            {paragraaf.subparagrafen.map((subparagraaf) => {
              if (!subparagraaf.data.ID) return null;

              return (
                <Fragment key={subparagraaf.data.ID}>
                  {subparagraaf.data.Toelichting && (
                    <InfoCard
                      testId={`toelichting-${subparagraaf.data.ID}`}
                      description={subparagraaf.data.Toelichting}
                    />
                  )}
                  {!subparagraaf.data.Transparent ? (
                    allow === 'voorschrift' ? (
                      <WizardCheckboxStatic content={subparagraaf.data.Titel} type="subparagraaf" />
                    ) : (
                      <WizardCheckbox
                        {...register(subparagraaf.data.ID, {
                          onChange: (e) =>
                            onSubparagraafChange(paragraaf, subparagraaf, e.target.checked),
                        })}
                        id={`wizard-checkbox-${subparagraaf.data.ID}`}
                        content={subparagraaf.data.Titel}
                        type="subparagraaf"
                        data-paragraaf={paragraafId}
                        minusIcon={subparagrafenWithSomeChecked.includes(subparagraaf.data.ID)}
                      />
                    )
                  ) : null}
                  {subparagraaf.voorschriften.map((voorschrift) => {
                    if (!voorschrift.ID) return null;

                    return (
                      <Fragment key={`wizard-checkbox-${voorschrift.ID}`}>
                        {voorschrift.Toelichting && (
                          <InfoCard
                            testId={`toelichting-${voorschrift.ID}`}
                            description={voorschrift.Toelichting}
                          />
                        )}

                        <WizardCheckbox
                          {...register(voorschrift.ID, {
                            onChange: () => onVoorschriftChange(paragraaf, subparagraaf),
                          })}
                          id={`wizard-checkbox-${voorschrift.ID}`}
                          content={voorschrift.Voorschrift || ''}
                          data-paragraaf={paragraafId}
                        />
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </form>
  );
};
