import { Transition } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';

interface TransitionProps {
  children: React.ReactNode;
  show: boolean;
  appear?: boolean;
  unmount?: boolean;
  duration?: `duration-${string}`;
}

export const Transitions = {
  FadeIn: function FadeIn({ duration = 'duration-300', ...props }: TransitionProps) {
    return (
      <Transition
        {...props}
        className={twJoin('transition-opacity', duration)}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      />
    );
  },
};
