import { createFileRoute, notFound } from '@tanstack/react-router';
import { Fragment, useState } from 'react';

import { STDMapSchema } from '@/api';
import {
  StdParagraaf,
  useOrderStdParagraaf,
  useOrderStdSubparagraaf,
  useOrderStdVoorschrift,
  usePatchStdMapId,
  useStdMapId,
  useStdObjects,
} from '@/api/queries/std';
import {
  AddItemButton,
  Button,
  DraggableEditor,
  DraggableParagraaf,
  DraggableSubparagraaf,
  DraggableVoorschrift,
  DroppableParagraaf,
  DroppableSubparagraaf,
  DroppableVoorschrift,
  EditorAdd,
} from '@/components';
import { RegularParagraph } from '@/icons/components';
import { useOpenModal } from '@/modals/utils/modalStore';

import { Paragraaf } from './-components/Paragraaf';
import { Subparagraaf } from './-components/Subparagraaf';
import { Voorschrift } from './-components/Voorschrift';
import { VoorschriftenLayout } from './-components/voorschriftenLayout';

/**
 * Route
 */
export const Route = createFileRoute('/beheer/standaardteksten/$mapId/$sectieId/voorschriften')({
  beforeLoad: ({ context, params }) => ({
    getTitle: () => {
      const sectie = context.queryClient.getQueryData<STDMapSchema>(
        useStdMapId.getKey(params.sectieId)
      );

      if (!sectie) return;

      return `${sectie?.Naam} (voorschriften)`;
    },
  }),
  loader: async ({ params, context: { queryClient } }) => {
    const [sectie, map] = await Promise.all([
      queryClient.ensureQueryData(useStdMapId.getOptions(params.sectieId)),
      queryClient.ensureQueryData(useStdMapId.getOptions(params.mapId)),
      queryClient.ensureQueryData(useStdObjects.getOptions({ sectieId: params.sectieId })),
    ]);

    if (!sectie || !map) throw notFound();
  },
  component: VoorschriftenComponent,
});

/**
 * Route component
 */
function VoorschriftenComponent() {
  const { sectieId, mapId } = Route.useParams();
  const openModal = useOpenModal();

  const map = useStdMapId(mapId);
  const sectie = useStdMapId(sectieId);
  const stdObjects = useStdObjects({ sectieId });

  const paragrafen = stdObjects.data?.paragrafen;

  const [isOpen, setIsOpen] = useState(!paragrafen?.length);

  const orderParagraaf = useOrderStdParagraaf();
  const orderSubparagraaf = useOrderStdSubparagraaf();
  const orderVoorschrift = useOrderStdVoorschrift();
  const patchMapId = usePatchStdMapId();

  if (!sectie.data || !stdObjects.data || !map.data || !paragrafen) return null;

  const onDropParagraaf = ({
    from,
    to,
    method,
  }: {
    from: number;
    to: number;
    method: 'prepend' | 'append';
  }) => {
    if (!paragrafen) return;

    orderParagraaf.mutate({
      from,
      to,
      method,
      paragrafen,
    });
  };

  const onDropSubparagraaf = ({
    from,
    to,
    method,
    subparagrafen,
  }: {
    from: number;
    to: number;
    method: 'prepend' | 'append';
    subparagrafen: StdParagraaf['subparagrafen'];
  }) => {
    orderSubparagraaf.mutate({
      from,
      to,
      method,
      subparagrafen,
    });
  };

  const onDropVoorschrift = ({
    from,
    to,
    method,
    subparagrafen,
  }: {
    from: {
      subparagraafIndex: number;
      voorschriftIndex: number;
    };
    to: {
      subparagraafIndex: number;
      voorschriftIndex: number;
    };
    method: 'prepend' | 'append';
    subparagrafen: StdParagraaf['subparagrafen'];
  }) => {
    if (!subparagrafen) return;

    orderVoorschrift.mutate({
      from,
      to,
      method,
      subparagrafen,
    });
  };

  return (
    <VoorschriftenLayout
      backLabel="Terug naar overzicht"
      testId={Route.fullPath}
      linkProps={{
        to: `/beheer/standaardteksten/$mapId`,
        params: { mapId },
      }}
      warning={
        map.data?.Status === 'Archief'
          ? {
              title: `Let op! De bovenliggende map “${map.data.Naam}” inclusief alle inhoud is onzichtbaar voor de gebruiker.`,
              variant: 'warning',
              size: 'small',
            }
          : undefined
      }
      isLoading={orderParagraaf.isPending || orderVoorschrift.isPending}
    >
      <DraggableEditor>
        <div className="px-8 pt-6">
          <div className="mb-6 flex justify-between">
            <h1 className="text-2xl font-bold text-theme-blue">
              {sectie.data?.Naam}
              {sectie.data?.Status === 'Archief' ? (
                <span className="font-normal">{` `}(Onzichtbaar voor gebruikers)</span>
              ) : (
                ''
              )}
            </h1>
            <Button
              variant="white"
              onClick={() => {
                patchMapId.mutate({
                  id: sectieId,
                  data: {
                    Status: sectie.data?.Status === 'Actief' ? 'Archief' : 'Actief',
                  },
                });
              }}
            >
              {sectie.data?.Status === 'Actief' ? 'Onzichtbaar maken' : 'Zichtbaar maken'}
            </Button>
          </div>
          <EditorAdd
            asSpacer={false}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
          >
            <AddItemButton
              data-testid="add-item-button-lege-paragraaf"
              icon={RegularParagraph}
              label="Paragraaf"
              onClick={() =>
                openModal('standaardtekstenToevoegenParagraaf', {
                  sectieId,
                  stdObjects: stdObjects.data!,
                  onSubmitSuccess: () => setIsOpen(false),
                })
              }
            />
          </EditorAdd>

          {paragrafen.map((paragraaf, paragraafIndex) => (
            <Fragment key={`paragraaf-${paragraaf.data.ID}`}>
              <DroppableParagraaf
                index={paragraafIndex}
                method="prepend"
                onDrop={(from) => {
                  onDropParagraaf({
                    from: from.index,
                    to: paragraafIndex,
                    method: 'prepend',
                  });
                }}
              />
              <DraggableParagraaf index={paragraafIndex}>
                {({ dragHandleRef, dragPreviewRef }) => (
                  <Paragraaf
                    ref={dragPreviewRef}
                    dragHandleRef={dragHandleRef}
                    paragraafIndex={paragraafIndex}
                    paragraaf={paragraaf.data}
                    stdObjects={stdObjects.data!}
                    sectieId={sectieId}
                  >
                    {paragraaf.subparagrafen.map((subparagraaf, subparagraafIndex) => (
                      <Fragment key={`subparagraaf-${subparagraaf.data.ID}`}>
                        {subparagraafIndex !== 0 && (
                          <DroppableSubparagraaf
                            method="prepend"
                            onDrop={(from) => {
                              onDropSubparagraaf({
                                from: from[1],
                                to: subparagraafIndex,
                                method: 'prepend',
                                subparagrafen: paragraaf.subparagrafen,
                              });
                            }}
                            path={[paragraafIndex, subparagraafIndex]}
                          />
                        )}
                        <DraggableSubparagraaf path={[paragraafIndex, subparagraafIndex]}>
                          {({ dragHandleRef, dragPreviewRef }) => (
                            <Subparagraaf
                              ref={dragPreviewRef}
                              dragHandleRef={dragHandleRef}
                              stdObjects={stdObjects.data}
                              sectieId={sectieId}
                              paragraafIndex={paragraafIndex}
                              subparagraafIndex={subparagraafIndex}
                              subparagraaf={subparagraaf.data}
                            >
                              {subparagraaf.voorschriften.map((voorschrift, voorschriftIndex) => (
                                <Fragment key={`voorschrift-${voorschrift.ID}`}>
                                  <DroppableVoorschrift
                                    method="prepend"
                                    onDrop={(from) => {
                                      onDropVoorschrift({
                                        from: {
                                          subparagraafIndex,
                                          voorschriftIndex: from[2],
                                        },
                                        to: {
                                          subparagraafIndex,
                                          voorschriftIndex,
                                        },
                                        method: 'prepend',
                                        subparagrafen: paragraaf.subparagrafen,
                                      });
                                    }}
                                    path={[paragraafIndex, subparagraafIndex, voorschriftIndex]}
                                  />

                                  <DraggableVoorschrift
                                    path={[paragraafIndex, subparagraafIndex, voorschriftIndex]}
                                  >
                                    {({ dragHandleRef, dragPreviewRef }) => (
                                      <Voorschrift
                                        ref={dragPreviewRef}
                                        dragHandleRef={dragHandleRef}
                                        key={`voorschrift-${voorschrift.ID}`}
                                        paragraafIndex={paragraafIndex}
                                        subparagraafIndex={subparagraafIndex}
                                        voorschriftIndex={voorschriftIndex}
                                        voorschrift={voorschrift}
                                        stdObjects={stdObjects.data!}
                                        sectieId={sectieId}
                                      />
                                    )}
                                  </DraggableVoorschrift>

                                  {voorschriftIndex ===
                                    (subparagraaf.voorschriften.length ?? 0) - 1 && (
                                    <DroppableVoorschrift
                                      method="append"
                                      onDrop={(from) => {
                                        onDropVoorschrift({
                                          from: {
                                            subparagraafIndex: from[1],
                                            voorschriftIndex: from[2],
                                          },
                                          to: {
                                            subparagraafIndex,
                                            voorschriftIndex,
                                          },
                                          method: 'append',
                                          subparagrafen: paragraaf.subparagrafen,
                                        });
                                      }}
                                      path={[paragraafIndex, subparagraafIndex, voorschriftIndex]}
                                    />
                                  )}
                                </Fragment>
                              ))}
                            </Subparagraaf>
                          )}
                        </DraggableSubparagraaf>
                        {subparagraafIndex === paragraaf.subparagrafen.length - 1 && (
                          <DroppableSubparagraaf
                            method="append"
                            onDrop={(from) => {
                              onDropSubparagraaf({
                                from: from[1],
                                to: subparagraafIndex,
                                method: 'append',
                                subparagrafen: paragraaf.subparagrafen,
                              });
                            }}
                            path={[paragraafIndex, subparagraafIndex]}
                          />
                        )}
                      </Fragment>
                    ))}
                  </Paragraaf>
                )}
              </DraggableParagraaf>
              {paragraafIndex === paragrafen.length - 1 && (
                <DroppableParagraaf
                  method="append"
                  index={paragraafIndex}
                  onDrop={(from) => {
                    onDropParagraaf({
                      from: from.index,
                      to: paragraafIndex,
                      method: 'append',
                    });
                  }}
                />
              )}
            </Fragment>
          ))}
        </div>
      </DraggableEditor>
    </VoorschriftenLayout>
  );
}
