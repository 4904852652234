import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" ref={svgRef} {...props}><path d="M280 360c0 13.3-10.7 24-24 24s-24-10.7-24-24V81.9l-95 95c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 7c9.4-9.4 24.6-9.4 33.9 0L409 143c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-95-95V360zm32-8v-48h136c35.3 0 64 28.7 64 64v80c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64v-80c0-35.3 28.7-64 64-64h136v48H64c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16h384c8.8 0 16-7.2 16-16v-80c0-8.8-7.2-16-16-16H312zm72 56a24 24 0 1148 0 24 24 0 11-48 0z" /></svg>;
});
SVGIcon.displayName = "RegularUpload";
export default SVGIcon;