/* eslint-disable @typescript-eslint/no-empty-function */
import { useEventListener, useUpdateEffect } from '@react-hookz/web';
import {
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StoreApi, UseBoundStore, create } from 'zustand';

export interface RovingTabIndexProps {
  children: ReactNode;
  totalItems: number;
}

export interface RovingTabIndexPropsItemProps {
  children: (props: {
    buttonRef: MutableRefObject<HTMLButtonElement | null>;
    inputRef: MutableRefObject<HTMLInputElement | null>;
    props: { tabIndex: HTMLAttributes<HTMLButtonElement>['tabIndex'] };
  }) => ReactNode;
  index: number;
}

const RovingTabContext = createContext<UseBoundStore<StoreApi<RovingTabStore>> | null>(null);

const useRovingTabContext = () => {
  const store = useContext(RovingTabContext);
  if (!store) {
    throw new Error('useRovingTabContext must be used within a RovingTabIndex');
  }
  return store;
};

interface RovingTabStore {
  action: {
    index: number;
    focus: boolean;
  };
  totalItems: number;
  next: () => void;
  previous: () => void;
  setTotalItems: (totalItems: number) => void;
}

const useCreateRovingTabStore = () => {
  // eslint-disable-next-line react/hook-use-state
  const [store] = useState(() =>
    create<RovingTabStore>((set, get) => ({
      action: {
        index: 0,
        focus: false,
      },
      totalItems: 0,
      next: () =>
        set(() => {
          const { action, totalItems } = get();

          let newIndex = action.index + 1;

          if (newIndex > totalItems - 1) {
            newIndex = totalItems - 1;
          }

          return {
            action: {
              index: newIndex,
              focus: true,
            },
          };
        }),
      previous: () =>
        set(() => {
          const { action } = get();

          let newIndex = action.index - 1;

          if (newIndex < 0) {
            newIndex = 0;
          }

          return {
            action: {
              index: newIndex,
              focus: true,
            },
          };
        }),
      setTotalItems: (totalItems) =>
        set(() => ({
          totalItems,
          action: {
            index: 0,
            focus: false,
          },
        })),
    }))
  );

  return store;
};

export const RovingTabIndex = ({ children, totalItems }: RovingTabIndexProps) => {
  const useRovingTab = useCreateRovingTabStore();
  const setTotalItems = useRovingTab((state) => state.setTotalItems);

  const prevTotalItems = useRef(0);

  useEffect(() => {
    if (totalItems !== prevTotalItems.current) {
      setTotalItems(totalItems);

      prevTotalItems.current = totalItems;
    }
  }, [totalItems, setTotalItems]);

  return <RovingTabContext.Provider value={useRovingTab}>{children}</RovingTabContext.Provider>;
};

export const RovingTabIndexItem = ({ children, index }: RovingTabIndexPropsItemProps) => {
  const useRovingTab = useRovingTabContext();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const next = useRovingTab((state) => state.next);
  const previous = useRovingTab((state) => state.previous);
  const action = useRovingTab((state) => state.action);

  const [isActive, setIsActive] = useState(index === action.index);

  useEventListener(buttonRef, 'keydown', (e: KeyboardEvent) => {
    if (!buttonRef.current) return;

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      previous();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      next();
    }
  });

  useEventListener(inputRef, 'keydown', (e: KeyboardEvent) => {
    if (!inputRef.current) return;

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      previous();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      next();
    }
  });

  useUpdateEffect(() => {
    if (action.index === index) {
      setIsActive(true);

      if (action.focus) {
        buttonRef.current?.focus();
        inputRef.current?.focus();
      }
    } else {
      setIsActive(false);
    }
  }, [action]);

  return (
    <>
      {children({
        buttonRef,
        inputRef,
        props: { tabIndex: isActive ? 0 : -1 },
      })}
    </>
  );
};
