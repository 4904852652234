import Tippy, { TippyProps } from '@tippyjs/react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import 'tippy.js/dist/tippy.css';

export interface TippyWhenOverflowingProps {
  children: ReactNode;
  placement?: TippyProps['placement'];
  content: string;
  /** Will render the content in tippy with `dangerouslySetInnerHTML` */
  asHTML?: boolean;
}

export const TippyWhenOverflowing = ({
  children,
  placement = 'bottom',
  content,
  asHTML,
}: TippyWhenOverflowingProps) => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  const containerRef = useRef<HTMLElement | null>(null);
  const textRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (containerRef.current && textRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const textWidth = textRef.current.getBoundingClientRect().width;

      setIsOverflowing(textWidth > containerWidth);
    }
  }, [content]);

  return (
    <Tippy
      disabled={!isOverflowing}
      placement={placement}
      content={
        asHTML ? (
          <span
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        ) : (
          content
        )
      }
    >
      <span ref={containerRef} className="block w-full truncate">
        <span ref={textRef}>{children}</span>
      </span>
    </Tippy>
  );
};
