import { useMountEffect, useUnmountEffect } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import { FormEvent, useState } from 'react';

import { FilterSchema, HoofdstukSchema, STDMapSchema } from '@/api';
import { CreateHoofdstukkenProps, useCreateObjects } from '@/api/queries/objects';
import { useStdMapInfinite, useStdObjects } from '@/api/queries/std';
import { Checkbox, Infinite, LoaderContent, Modal, Radio, useModalState } from '@/components';
import { useUserStore } from '@/stores/user';

import { modal } from '../../utils';
import { useSelectedMappen } from './utils/useSelectedMappen';

export interface StdMappenProps {
  besluitId: string;
  onSubmit?: (newHoofdstuk: HoofdstukSchema) => void;
}

export const STD_MAPPEN_ID = 'editorStdMappen';

export const StdMappen = modal(STD_MAPPEN_ID, ({ data, props }) => {
  const { besluitId, onSubmit } = data;

  const [includeInhoudelijkeOverwegingen, setIncludeInhoudelijkeOverwegingen] = useState(false);

  const organisatieNaam = useUserStore((state) => state.organisatieNaam);

  const resetStore = useSelectedMappen((state) => state.reset);
  const selectedMapId = useSelectedMappen((state) => state.map?.ID);

  const modalState = useModalState();
  const queryClient = useQueryClient();
  const createObjects = useCreateObjects();

  /**
   * Submit handler
   */
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();

    const userId = useUserStore.getState().user?.ID ?? '';

    const { map, secties } = useSelectedMappen.getState();

    if (!map || !secties) {
      return;
    }

    modalState.action('save');

    const stdSectieObjects = await Promise.all(
      secties.map((sectie) =>
        queryClient.fetchQuery(useStdObjects.getOptions({ sectieId: sectie.ID! }))
      )
    );

    const createObjectsProps: CreateHoofdstukkenProps = {
      type: 'hoofdstukken',
      besluitId,
      userId,
      hoofdstukken: [
        {
          titel: map.Naam,
          overweging: includeInhoudelijkeOverwegingen
            ? secties.map((sectie) => sectie.InhoudelijkeOverweging ?? '').join('')
            : undefined,
          paragrafen: stdSectieObjects
            .map((stdObjects) => {
              return stdObjects?.paragrafen ?? [];
            })
            .flat()
            .map((paragraaf) => {
              return {
                titel: paragraaf.data.Titel,
                subparagrafen: paragraaf.subparagrafen
                  .filter((subparagraaf) => !subparagraaf.data.Transparent)
                  .map((subparagraaf) => ({
                    titel: subparagraaf.data.Titel,
                    voorschriften: subparagraaf.voorschriften.map((voorschrift) => ({
                      voorschrift: voorschrift.Voorschrift,
                    })),
                  })),
                voorschriften: paragraaf.subparagrafen
                  .filter((subparagraaf) => subparagraaf.data.Transparent)
                  .map((subparagraaf) => subparagraaf.voorschriften)
                  .flat()
                  .map(({ Voorschrift }) => ({ voorschrift: Voorschrift })),
              };
            }),
        },
      ],
    };

    createObjects.mutate(createObjectsProps, {
      onSuccess: ({ hoofdstukResponses }) => {
        const newHoofdstuk = hoofdstukResponses[0]?.hoofdstuk;

        if (newHoofdstuk?.ID) {
          onSubmit?.(newHoofdstuk);
        }

        modalState.success('save');
      },
      onError: () => {
        modalState.error('save', {
          title: 'Mislukt!',
          description: 'Sommige onderdelen zijn niet toegevoegd.',
        });
      },
    });
  };

  /**
   * Fetch data
   */
  const landelijkMappen = useMappen([
    {
      field: 'Landelijk',
      op: 'eq',
      value: '1',
    },
  ]);

  const odMappen = useMappen([
    {
      field: 'Landelijk',
      op: 'eq',
      value: '0',
    },
  ]);

  return (
    <Modal
      {...props}
      title="Standaardteksten invoegen"
      afterLeave={() => {
        resetStore();
        props.afterLeave();
      }}
      state={modalState.state}
      isLoading={landelijkMappen.query.isLoading || odMappen.query.isLoading}
      size="medium"
    >
      <form onSubmit={submitHandler}>
        <p>
          Selecteer een map waarvan je de voorschriften (en eventueel overwegingen) wilt invoegen
        </p>

        <Mappen className="mb-2 mt-4" title="Landelijk" {...landelijkMappen} />

        <Mappen title={organisatieNaam ?? 'Omgevingsdienst'} {...odMappen} />

        <Checkbox
          name="inhoudelijke overwegingen"
          id="standaardtekst-inhoudelijke-overwegingen"
          className="mt-4"
          checked={includeInhoudelijkeOverwegingen}
          onChange={() => setIncludeInhoudelijkeOverwegingen((prev) => !prev)}
          label={
            <>
              <span className="block font-bold">Inhoudelijke overwegingen</span>
              <span className="mt-1 block">
                Voeg ook de inhoudelijke overwegingen van de geselecteerde sectie(s) toe.
              </span>
            </>
          }
          variant="withBackground"
        />

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="save"
            successLabel="Ingevoegd"
            type="submit"
            disabled={!selectedMapId}
          >
            Invoegen
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});

type UseMappenResult = {
  data: STDMapSchema[];
  query: ReturnType<typeof useStdMapInfinite>;
};

const useMappen = (filter: FilterSchema[]): UseMappenResult => {
  const query = useStdMapInfinite({
    page: 1,
    size: 20,
    filter: [
      {
        field: 'Map',
        op: 'is_null',
        value: '',
      },
      {
        field: 'Status',
        op: 'eq',
        value: 'Actief',
      },
      ...filter,
    ],
    sort: [
      {
        field: 'Naam',
        direction: 'asc',
      },
    ],
  });

  const data =
    query.data?.pages
      .map((page) => page.data.objects)
      .flat(1)
      .filter((map): map is STDMapSchema => !!map) ?? [];

  return {
    data,
    query,
  };
};

const Mappen = ({
  query,
  data,
  title,
  className,
}: UseMappenResult & {
  title: string;
  className?: string;
}) => {
  const selectedMapId = useSelectedMappen((state) => state.map?.ID);
  const setSelectedMap = useSelectedMappen((state) => state.setSelectedMap);

  /**
   * Fetch all mappen
   */
  if (!data.length) {
    return null;
  }

  return (
    <div className={className}>
      <h3 className="font-bold">{title}</h3>

      <div className="mt-2 space-y-2">
        <Infinite hasMore={query.hasNextPage} onLoadMore={query.fetchNextPage}>
          <ul className="flex flex-col gap-1">
            {data.map((map) => (
              <li key={`standaardtekst-map-${map.ID!}`}>
                <Radio
                  // There's a bug with react-hook-form and radio inputs, so we use the native onChange.
                  // If not, you will have to select the radio button twice to get the value.
                  onChange={() => setSelectedMap(map)}
                  name="map"
                  id={map.ID!}
                  value={map.ID!}
                  label={map.Naam}
                  checked={selectedMapId === map.ID}
                />

                {selectedMapId === map.ID && (
                  <div className="pl-6 pt-1">
                    <Secties map={map} />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </Infinite>
      </div>
    </div>
  );
};

const Secties = ({ map }: { map: STDMapSchema }) => {
  const secties = useStdMapInfinite({
    page: 1,
    size: 20,
    filter: [
      {
        field: 'Map',
        op: 'eq',
        value: map.ID ?? '',
      },
      {
        field: 'Status',
        op: 'eq',
        value: 'Actief',
      },
    ],
    sort: [
      {
        field: 'Naam',
        direction: 'asc',
      },
    ],
  });

  const sectiesData =
    secties.data?.pages
      .map((page) => page.data.objects)
      .flat(1)
      .filter((map): map is STDMapSchema => !!map) ?? [];

  if (secties.isLoading) {
    return <LoaderContent w="w-[160px]" h="h-6" />;
  }

  if (!sectiesData.length) {
    return <p className="italic text-gray-600">Deze map heeft geen secties</p>;
  }

  return (
    <Infinite hasMore={secties.hasNextPage} onLoadMore={secties.fetchNextPage}>
      <ul className="flex flex-col gap-1">
        {sectiesData.map((sectie) => (
          <li key={`standaardtekst-sectie-${sectie.ID!}`}>
            <SectieCheckbox sectie={sectie} />
          </li>
        ))}
      </ul>
    </Infinite>
  );
};

const SectieCheckbox = ({ sectie }: { sectie: STDMapSchema }) => {
  const addSelectedSectie = useSelectedMappen((state) => state.addSelectedSectie);
  const removeSelectedSectie = useSelectedMappen((state) => state.removeSelectedSectie);

  const isSelected = useSelectedMappen(
    ({ secties }) => !!secties?.some(({ ID }) => sectie.ID === ID)
  );

  useMountEffect(() => addSelectedSectie(sectie));
  useUnmountEffect(() => removeSelectedSectie(sectie));

  return (
    <Checkbox
      name="sectie"
      id={sectie.ID!}
      label={sectie.Naam}
      textSize="text-base"
      value={sectie.ID}
      onChange={() => {
        if (isSelected) {
          removeSelectedSectie(sectie);
        } else {
          addSelectedSectie(sectie);
        }
      }}
      checked={isSelected}
    />
  );
};
