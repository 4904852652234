import { ButtonHTMLAttributes, MutableRefObject, ReactNode, SyntheticEvent } from 'react';
import { twJoin } from 'tailwind-merge';

import { InfoCard, Modal, ModalProps, TextButton } from '@/components';

export interface WizardProps
  extends Pick<ModalProps, 'title' | 'afterLeave' | 'onClose' | 'isLoading' | 'state' | 'open'> {
  titleInfoCard?: string | null;
  onSubmit?: (e: SyntheticEvent) => void;
  children?: ReactNode;
  sidebar: ReactNode;
  sidebarTitle?: string;
  bodyTitle?: string;
  submitAttributes?: ButtonHTMLAttributes<HTMLButtonElement>;
  placeholder?: string;
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
  footer: ReactNode;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}

const SCROLL_BODY_CLASSNAMES = '-mt-4 -mb-5 max-h-[600px]';

export const Wizard = ({
  titleInfoCard,
  children,
  sidebar,
  sidebarTitle,
  bodyTitle,
  placeholder,
  scrollContainerRef,
  onSelectAll,
  onDeselectAll,
  footer,
  ...props
}: WizardProps) => {
  return (
    <Modal size="xlarge" {...props}>
      <div className="flex min-h-[400px] w-full">
        <div
          className={twJoin(
            'w-[292px] shrink-0 grow-0 overflow-y-auto overflow-x-visible pt-4',
            SCROLL_BODY_CLASSNAMES
          )}
        >
          <div className="min-h-full pb-4 pr-9">
            {sidebarTitle && <h4 className="mb-4 font-bold">{sidebarTitle}</h4>}

            {sidebar}
          </div>
        </div>

        <div
          className={twJoin('w-full overflow-auto pb-4 pl-8 pt-4', SCROLL_BODY_CLASSNAMES)}
          ref={scrollContainerRef}
        >
          {!children && placeholder && (
            <div className="flex h-full items-center justify-center px-6 text-center text-gray-700">
              {placeholder}
            </div>
          )}

          {children && (
            <>
              <div className="mb-2 flex justify-end gap-2">
                <TextButton variant="black" size="small" onClick={onSelectAll}>
                  Selecteer alles
                </TextButton>
                <TextButton variant="black" size="small" onClick={onDeselectAll}>
                  Deselecteer alles
                </TextButton>
              </div>

              {titleInfoCard && (
                <InfoCard
                  className="mb-2"
                  description={titleInfoCard}
                  testId="wizard-toelichting"
                />
              )}

              {bodyTitle && <h4 className="mb-4 text-2xl font-semibold">{bodyTitle}</h4>}

              {children}
            </>
          )}
        </div>
      </div>

      {footer}
    </Modal>
  );
};
