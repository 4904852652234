import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDMapSchema } from '@/api';
import { usePatchStdMapId } from '@/api/queries/std';
import { FormLabel, Input, Modal, TextEditor, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';

export interface StandaardtekstenEditSectieProps {
  sectie: STDMapSchema;
}

type FormValues = Pick<STDMapSchema, 'Naam' | 'Toelichting'>;

export const STANDAARDTEKSTEN_EDIT_SECTIE_ID = 'standaardtekstenEditSectie';

export const StandaardtekstenEditSectie = modal(
  STANDAARDTEKSTEN_EDIT_SECTIE_ID,
  ({ data, props }) => {
    const {
      sectie: { Naam, Toelichting, ID },
    } = data;

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>();

    const modalState = useModalState();

    const patchStdSectie = usePatchStdMapId();

    const onSubmit: SubmitHandler<FormValues> = (formData) => {
      const userId = useUserStore.getState().user?.ID;

      modalState.action('save');
      patchStdSectie.mutate(
        {
          data: {
            ...formData,
            Modified_By: userId,
            Modified_Date: new Date().toISOString(),
          },
          id: ID!,
        },
        {
          onSuccess: () => {
            modalState.success('save');
          },
          onError: () => {
            modalState.error('save');
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Sectie wijzigen">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Naam', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
            error={errors.Naam}
            label="Titel"
            id="bewerken-sectie-naam"
            defaultValue={Naam}
            maxLength={100}
            required
            className="mb-6"
          />
          <FormLabel htmlFor="bewerken-sectie-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            defaultValue={Toelichting}
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'orderedList',
                  'abcList',
                  'bulletList',
                  'subscript',
                  'superscript',
                  'highlight',
                  'table',
                ]}
                initialText={Toelichting}
                id="bewerken-sectie-toelichting"
                testId="edit-object-sectie-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />

          <Modal.Footer>
            <Modal.CancelButton />

            <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
              Opslaan
            </Modal.ActionButton>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
