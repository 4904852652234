import { Link, LinkProps } from '@tanstack/react-router';
import { Fragment } from 'react';
import { twJoin } from 'tailwind-merge';

import { OptionsMenu, OptionsMenuOption, TextButton } from '@/components';
import { RegularAngleRight } from '@/icons/components';

export interface BreadCrumbsProps {
  path: { linkProps?: LinkProps; title: string }[];
  size?: 'small' | 'medium';
  maxLength?: number;
}

interface TruncateDropdownMenuProps {
  truncatedItems: OptionsMenuOption[];
}

export const BreadCrumbs = ({ path, size = 'small', maxLength }: BreadCrumbsProps) => {
  const containsLink = path.some((item) => item.linkProps);
  const WrapperElement = containsLink ? 'nav' : 'div';
  const truncatedItems: OptionsMenuOption[] = [];

  // If maxLength is set, truncate the path
  if (maxLength && path.length >= maxLength + 1) {
    const length = path.length - (maxLength + 1);

    // Add all items to truncatedItems
    for (let base = 1; base < length + 2; base++) {
      //Add item to truncatedItems
      truncatedItems.push({
        variant: 'link',
        title: path[base]!.title,
        linkProps: path[base]!.linkProps,
      });
    }

    // Remove items from path
    path.splice(1, length);

    // Add "..." to path
    path[1]!.title = '...';
  }

  return (
    <WrapperElement
      className={twJoin(
        'flex items-center text-gray-500',
        size === 'small' && 'text-sm',
        size === 'medium' && 'text-base'
      )}
      aria-label={containsLink ? 'Breadcrumbs' : undefined}
    >
      {path.map((item, breadCrumbIndex) => (
        <Fragment key={`breadcrumb-item-${item.title}`}>
          {breadCrumbIndex > 0 && <RegularAngleRight className="mx-2" />}

          {truncatedItems.length && breadCrumbIndex === 1 ? (
            <TruncateDropdownMenu truncatedItems={truncatedItems} />
          ) : item.linkProps && breadCrumbIndex !== path.length - 1 ? (
            <TextButton
              asChild
              excludeActiveStyling
              className="inline-block h-full max-w-[250px] truncate"
              title={item.title}
            >
              <Link {...item.linkProps}>{item.title}</Link>
            </TextButton>
          ) : (
            <span className="inline-block max-w-[250px] truncate" title={item.title}>
              {item.title}
            </span>
          )}
        </Fragment>
      ))}
    </WrapperElement>
  );
};

const TruncateDropdownMenu = ({ truncatedItems }: TruncateDropdownMenuProps) => {
  return (
    <>
      <OptionsMenu>
        <OptionsMenu.Button>
          <TextButton aria-label="Bekijk overige links">...</TextButton>
        </OptionsMenu.Button>

        <OptionsMenu.Items options={truncatedItems} />
      </OptionsMenu>
    </>
  );
};
