import { ReactNode, useId } from 'react';
import { twJoin } from 'tailwind-merge';

import { PillButton } from '@/components';
import { SolidPlus } from '@/icons/components';

export interface EditorAddProps {
  pillLabel?: { labelOpen?: string; labelClosed?: string };
  customOpacity?: `opacity-${string}`;
  testId?: string;
  // TODO: Remove this property in favor of EditorAdd.Spacer
  asSpacer?: boolean;
  children: ReactNode;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const EditorAdd = ({
  pillLabel,
  asSpacer,
  customOpacity,
  testId,
  children,
  open,
  onOpen,
  onClose,
}: EditorAddProps) => {
  const id = useId();

  const buttonId = `${id}-button`;
  const panelId = `${id}-panel`;

  const pillLabelClosed = pillLabel?.labelClosed ?? 'Nieuw';
  const pillLabelOpen = pillLabel?.labelOpen ?? 'Annuleren';

  const opacity = open ? 'opacity-100' : customOpacity ?? 'opacity-0';

  if (asSpacer)
    return <div data-testid={`${testId ?? 'editor-add'}-spacer`} className="h-[28px]" />;

  return (
    <div className="relative py-1">
      <button
        className={twJoin(
          'relative flex h-5 w-full items-center justify-center',
          'transition-opacity duration-200',
          'before:absolute before:inset-x-0 before:top-[50%] before:block before:h-[1px] before:w-full before:bg-theme-blue',
          'hover:opacity-100 focus-visible:opacity-100',
          opacity
        )}
        id={buttonId}
        data-testid={testId}
        aria-expanded={open}
        aria-controls={panelId}
        onClick={() => {
          if (open) {
            onClose();
          } else {
            onOpen();
          }
        }}
        onKeyDown={(e) => e.key === 'Escape' && onClose()}
      >
        <PillButton
          asChild
          variant="blue"
          icon={SolidPlus}
          iconClassName={open ? 'rotate-45' : undefined}
        >
          <span>{open ? pillLabelOpen : pillLabelClosed}</span>
        </PillButton>
      </button>

      <div
        className={twJoin(
          !open && 'hidden',
          'relative flex justify-center gap-2 py-2',
          'before:absolute before:inset-x-0 before:bottom-0 before:block before:h-[1px] before:w-full before:bg-theme-blue'
        )}
        aria-labelledby={buttonId}
        id={panelId}
      >
        {open ? children : null}
      </div>
    </div>
  );
};

EditorAdd.Spacer = function EditorAddSpacer({ testId }: { testId?: string }) {
  return <div data-testid={testId} className="h-[28px]" />;
};
