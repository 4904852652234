import { ReactNode } from 'react';
import { InView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';

import { RegularSpinner } from '@/icons/components';

export interface InfiniteProps {
  children: ReactNode;
  onLoadMore: () => void;
  hasMore: boolean;
  loaderClassName?: string;
}

export const Infinite = ({ children, onLoadMore, hasMore, loaderClassName }: InfiniteProps) => {
  return (
    <div>
      {children}

      {hasMore && (
        <InView
          onChange={(inView) => {
            if (inView) onLoadMore();
          }}
        >
          <div
            className={twMerge(
              'flex h-8 w-full items-center justify-center rounded bg-gray-100',
              loaderClassName
            )}
          >
            <RegularSpinner className="rotating text-black" size={16} />
          </div>
        </InView>
      )}
    </div>
  );
};
