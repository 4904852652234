import { twJoin } from 'tailwind-merge';

import { TextEditorOptionButton } from '@/components/shared/TextEditor/config/config';

export const TextEditorMenuButton = ({
  label,
  icon,
  iconColor,
  onClick,
  ariaLabel,
  isActive,
  isDisabled,
  width,
  testId,
}: Omit<TextEditorOptionButton, 'option' | 'dropdown'> & { width?: `w-${string}` }) => {
  const IconComponent = icon;

  return (
    <button
      aria-label={ariaLabel}
      onClick={onClick}
      type="button"
      data-testid={testId}
      className={twJoin(
        'flex h-9 items-center whitespace-nowrap rounded-md text-left text-white',
        'hover:bg-black',
        'focus:outline-none focus:ring-2 focus:ring-digi-v-color-info',
        isActive?.() && 'bg-digi-v-color-info',
        !isActive?.() && 'bg-white/15',
        isDisabled?.() && 'pointer-events-none opacity-50',
        !label && 'w-9 justify-center',
        label && 'justify-start px-3',
        width
      )}
    >
      {IconComponent && <IconComponent size={15} className={iconColor} />}

      {label && <span className={twJoin(icon && 'ml-[10px]')}>{label}</span>}
    </button>
  );
};
