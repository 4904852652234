import { SubmitHandler, useForm } from 'react-hook-form';

import { STDMapSchema } from '@/api';
import { usePostStdMap } from '@/api/queries/std';
import { Input, Modal, useModalState } from '@/components';
import { PLACEHOLDER_UUID } from '@/constants/placeholder-uuid';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';

export interface StandaardtekstenToevoegenMapProps {}

type FormValues = Pick<STDMapSchema, 'Naam'>;

export const STANDAARDTEKSTEN_TOEVOEGEN_MAP_ID = 'standaardtekstenToevoegenMap';

export const StandaardtekstenToevoegenMap = modal(
  STANDAARDTEKSTEN_TOEVOEGEN_MAP_ID,
  ({ props }) => {
    const modalState = useModalState();

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<FormValues>();

    const postStdMap = usePostStdMap();

    const onSubmit: SubmitHandler<FormValues> = ({ Naam }) => {
      const userId = useUserStore.getState().user?.ID ?? '';

      modalState.action('save');
      postStdMap.mutate(
        {
          data: {
            Naam,
            Created_By: userId,
            Created_Date: new Date().toISOString(),
            Modified_By: userId,
            Modified_Date: new Date().toISOString(),
            Status: 'Actief',
            Landelijk: 0,
            // Tenant will be replaced by BE
            Tenant: PLACEHOLDER_UUID,
          },
        },
        {
          onSuccess: () => {
            modalState.success('save');
          },
          onError: () => {
            modalState.error('save');
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Map aanmaken">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Naam', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
            error={errors.Naam}
            label="Titel"
            id="nieuwe-map-title"
            required
            maxLength={100}
          />
          <Modal.Footer>
            <Modal.CancelButton />

            <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
              Opslaan
            </Modal.ActionButton>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
