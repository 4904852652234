import { useCallback } from 'react';

export const useWarningBeforeClose = ({
  onClose,
  warn,
  message,
}: {
  onClose: () => void;
  warn: boolean;
  message: string;
}) => {
  const handleOnClose = useCallback(() => {
    if (warn) {
      if (window.confirm(message)) onClose();
    } else {
      onClose();
    }
  }, [onClose, warn, message]);

  return handleOnClose;
};
