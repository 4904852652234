import { getDynActiviteitCategorie, getDynActiviteitCategorieId } from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useActiviteitCategorie = createQuery({
  key: ['activiteitCategorie'],
  queryFn: getDynActiviteitCategorie,
});

export const useActiviteitCategorieId = createQuery({
  key: ['activiteitCategorieId'],
  queryFn: getDynActiviteitCategorieId,
});
