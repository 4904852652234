import * as RadixSwitch from '@radix-ui/react-switch';
import { useId } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

export interface SwitchProps {
  className?: string;
  label: string;
  enabled: boolean;
  onChange: () => void;
}

export const Switch = ({ enabled, className, label, onChange }: SwitchProps) => {
  const id = useId();

  return (
    <form className={twMerge('flex items-center', className)}>
      <label className="mr-2 text-sm" htmlFor={id}>
        {label}
      </label>

      <RadixSwitch.Root
        checked={enabled}
        onCheckedChange={onChange}
        id={id}
        className={twJoin(
          'group relative inline-flex h-5 w-9 shrink-0 items-center rounded-full transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-green focus-visible:ring-offset-2',
          enabled ? 'bg-theme-green' : 'bg-gray-400'
        )}
      >
        <RadixSwitch.Thumb
          className={twJoin(
            'inline-block size-4 rounded-full bg-white transition-transform group-hover:bg-gray-100 group-active:bg-gray-200',
            enabled ? 'translate-x-[18px]' : 'translate-x-[2px]'
          )}
        />
      </RadixSwitch.Root>
    </form>
  );
};
