import { Disclosure } from '@headlessui/react';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import {
  RegularChevronDown,
  RegularSpinner,
  SolidCircleCheck,
  SolidCircleX,
} from '@/icons/components';

export const ValidateRow = ({
  isFetching,
  isValid,
  title,
  accordionBody,
}: {
  isFetching?: boolean;
  isValid?: boolean;
  title: string;
  accordionBody?: ReactNode;
}) => {
  let className = '';
  let icon = null;

  if (isFetching) {
    className = 'bg-white ring-gray-100';
    icon = <div className="size-[18px] rounded-full ring-2 ring-inset ring-black" />;
  } else if (isValid) {
    className = 'bg-theme-green/5 ring-theme-green';
    icon = <SolidCircleCheck size={18} className="text-theme-green" />;
  } else {
    className = 'bg-digi-v-color-danger/5 ring-digi-v-color-danger';
    icon = <SolidCircleX size={18} className="text-digi-v-color-danger" />;
  }

  if (accordionBody) {
    return (
      <div className={twJoin('rounded-md text-sm ring-1', className)}>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-center px-4 py-2 text-left hover:underline">
                {icon}

                <span className="grow-2 ml-4 w-full">{title}</span>

                <RegularChevronDown size={18} className={open ? 'rotate-180' : undefined} />
              </Disclosure.Button>

              <Disclosure.Panel as="div" className="pb-2 pl-12">
                {accordionBody}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  }

  return (
    <div className={twJoin('flex items-center rounded-md px-4 py-2 text-sm ring-1', className)}>
      {icon}

      <p className="grow-2 ml-4 w-full">{title}</p>

      {isFetching && <RegularSpinner size={18} className="rotating w-4" />}
    </div>
  );
};
