import { useState } from 'react';

import { HoofdstukSchema } from '@/api';
import { useHoofdstuk, useHoofdstukHB } from '@/api/queries/objects';
import { useCreateHoofdstukInLineage } from '@/api/queries/objects/mutations/useCreateHoofdstukInLineage';
import { Checkbox, Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface IntrekkenHoofdstukProps {
  hoofdstuk: HoofdstukSchema;
  besluitId: string;
  onSuccess?: (newHoofdstuk: HoofdstukSchema) => void;
}

export const INTREKKEN_HOOFDSTUK_ID = 'intrekkenHoofdstuk';

export const IntrekkenHoofdstuk = modal(INTREKKEN_HOOFDSTUK_ID, ({ data, props }) => {
  const { hoofdstuk, besluitId, onSuccess } = data;

  const hoofdstukId = hoofdstuk.ID!;

  const modalState = useModalState();

  const [ontwerpValue, setOntwerpValue] = useState(false);

  const createHoofdstukInLineage = useCreateHoofdstukInLineage();

  const hb = useHoofdstukHB({ hoofdstukId, besluitId });

  const containsParagraafInOntwerp = useHoofdstuk(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Hoofdstuk',
          field: 'ID',
          op: 'eq',
          value: hoofdstukId,
        },
        {
          model: 'Paragraaf',
          field: 'Ontwerp',
          op: 'eq',
          value: '1',
        },
      ],
    },
    {
      select: ({ num_objects }) => !!num_objects,
    }
  );

  const containsSubparagraafInOntwerp = useHoofdstuk(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Hoofdstuk',
          field: 'ID',
          op: 'eq',
          value: hoofdstukId,
        },
        {
          model: 'Subparagraaf',
          field: 'Ontwerp',
          op: 'eq',
          value: '1',
        },
      ],
    },
    {
      select: ({ num_objects }) => !!num_objects,
    }
  );

  const containsVoorschriftInOntwerp = useHoofdstuk(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Hoofdstuk',
          field: 'ID',
          op: 'eq',
          value: hoofdstukId,
        },
        {
          model: 'Voorschrift',
          field: 'Ontwerp',
          op: 'eq',
          value: '1',
        },
      ],
    },
    {
      select: ({ num_objects }) => !!num_objects,
    }
  );

  const intrekken = () => {
    modalState.action('save');

    if (!hb.data) return;

    createHoofdstukInLineage.mutate(
      {
        hb: hb.data,
        hoofdstuk: {
          ...hoofdstuk,
          Stop_Lineage: 1,
        },
      },
      {
        onSuccess: ({ hoofdstuk }) => {
          modalState.success('save');

          onSuccess?.(hoofdstuk);
        },
        onError: (error) => {
          const errorMessage = ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan';

          modalState.error('save', {
            title: 'Mislukt',
            description: errorMessage,
          });
        },
      }
    );
  };

  const containsOntwerpObject =
    containsParagraafInOntwerp.data ||
    containsSubparagraafInOntwerp.data ||
    containsVoorschriftInOntwerp.data;

  const isLoading =
    hb.isLoading ||
    containsParagraafInOntwerp.isLoading ||
    containsSubparagraafInOntwerp.isLoading ||
    containsVoorschriftInOntwerp.isLoading;

  return (
    <Modal
      state={modalState.state}
      isLoading={isLoading}
      title={`Intrekken hoofdstuk ${hoofdstuk.Nummer}`}
      {...props}
    >
      <p>
        Je staat op het punt om <strong>hoofdstuk {hoofdstuk.Nummer}</strong> in te trekken. Alle
        onderliggende paragrafen, subparagrafen en voorschriften zullen worden ingetrokken.
      </p>

      {containsOntwerpObject && (
        <form className="mt-4">
          <Checkbox
            id="contains-ontwerp-object"
            name="contains-ontwerp-object"
            label="Ik begrijp dat bewerkingen onder dit object zullen worden verwijderd."
            checked={ontwerpValue}
            onChange={() => setOntwerpValue(!ontwerpValue)}
          />
        </form>
      )}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          disabled={containsOntwerpObject && !ontwerpValue}
          action="save"
          successLabel="Hoofdstuk ingetrokken"
          onClick={intrekken}
        >
          Hoofdstuk intrekken
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
