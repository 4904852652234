import { LinkProps, Navigate, Outlet, createFileRoute, useMatches } from '@tanstack/react-router';

import { BreadCrumbs, BreadCrumbsProps, DocumentTitle, PageHeader } from '@/components';
import Navbar from '@/components/shared/Navbar';
import { eStrings } from '@/constants/strings';
import { useUserStore } from '@/stores/user';
import { isUserAuthenticatedForRoute } from '@/stores/user/isUserAuthenticatedForRoute';
import { useIsExactMatch } from '@/utils/hooks/useIsExactMatch';

/**
 * Route
 */
export const Route = createFileRoute('/beheer')({
  beforeLoad: async ({ preload }) => {
    await isUserAuthenticatedForRoute({ preload, allowed: ['Beheerder'] });
  },
  component: BeheerComponent,
});

/**
 * Route component
 */
function BeheerComponent() {
  const { organisatieNaam, area } = useUserStore(({ organisatieNaam, area }) => ({
    organisatieNaam,
    area,
  }));

  const matches = useMatches();

  const breadcrumbsPath: BreadCrumbsProps['path'] = [
    {
      linkProps: {
        to: '/',
        search: {
          p: 1,
        },
      },
      title: eStrings.LBL_DIGI_V,
    },
  ];

  breadcrumbsPath.push(
    ...matches
      .filter((match) => !!match.context?.getTitle)
      .map((match) => ({
        linkProps: {
          to: match.routeId,
          params: match.params,
          search: match.search,
        } as LinkProps,
        title: match.context.getTitle?.() ?? '',
      }))
  );

  /**
   * Because /beheer is not an actual route, navigate to /beheer/labels if it's an exact match
   */
  const isExactMatch = useIsExactMatch(Route.id);

  if (isExactMatch) {
    return <Navigate to="/beheer/standaardteksten" search={{ p: 1 }} />;
  }

  return (
    <>
      <Navbar />
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Beheer']} />

      {area === 'landelijk' && (
        <PageHeader title="Landelijk beheer" description={eStrings.LBL_DIGI_V} />
      )}

      {area === 'omgevingsdienst' && (
        <PageHeader title="Beheer" description={organisatieNaam ?? undefined} />
      )}

      <div className="mx-auto max-w-7xl py-5 sm:px-6 lg:px-8">
        <BreadCrumbs size="small" path={breadcrumbsPath} />
      </div>

      <Outlet />
    </>
  );
}
