import {
  getDynLabel,
  getDynLabelId,
  getDynLabelMap,
  getDynLabelMapId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useLabel = createQuery({
  key: ['label'],
  queryFn: getDynLabel,
});

export const useLabelId = createQuery({
  key: ['labelId'],
  queryFn: getDynLabelId,
});

export const useLabelMap = createQuery({
  key: ['labelMap'],
  queryFn: getDynLabelMap,
});

export const useLabelMapId = createQuery({
  key: ['labelMapId'],
  queryFn: getDynLabelMapId,
});
