import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchDynStdVoorschriftId } from '@/api/generated/digiVAPI';
import { getRangBetween } from '@/utils/getRangBetween';

import { useStdObjects } from '../stdObjects';
import { StdParagraaf } from '../utils/getStdObjects';

/**
 * useMutation for ordering voorschriften
 */
export const useOrderStdVoorschrift = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      from,
      to,
      method,
      subparagrafen,
    }: {
      from: {
        subparagraafIndex: number;
        voorschriftIndex: number;
      };
      to: {
        subparagraafIndex: number;
        voorschriftIndex: number;
      };
      method: 'prepend' | 'append';
      subparagrafen: StdParagraaf['subparagrafen'];
    }) => {
      const voorschrift =
        subparagrafen[from.subparagraafIndex]?.voorschriften[from.voorschriftIndex];

      if (!voorschrift) {
        throw new Error('Voorschrift niet gevonden');
      }

      const newIndex = method === 'prepend' ? to.voorschriftIndex - 1 : to.voorschriftIndex;

      const newSubparagraaf = subparagrafen[to.subparagraafIndex];

      const newPrevious = newSubparagraaf?.voorschriften[newIndex] ?? null;
      const newNext = newSubparagraaf?.voorschriften[newIndex + 1] ?? null;

      if (newPrevious?.ID === voorschrift.ID || newNext?.ID === voorschrift.ID) {
        throw new Error('Voorschrift mag niet naar dezelfde positie verplaatst worden');
      }

      const newRang = getRangBetween(newPrevious?.Rang, newNext?.Rang);

      /**
       * Patch VSPB with new Vorig and Volgend prop
       */
      return await patchDynStdVoorschriftId(voorschrift.ID!, {
        Rang: newRang,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useStdObjects.key });
    },
  });
};
