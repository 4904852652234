import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDMapSchema } from '@/api';
import { usePostStdMap } from '@/api/queries/std';
import { FormLabel, Input, Modal, TextEditor, useModalState } from '@/components';
import { PLACEHOLDER_UUID } from '@/constants/placeholder-uuid';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';

export interface StandaardtekstenToevoegenSectieProps {
  mapId: STDMapSchema['Map'];
}

type FormValues = Pick<STDMapSchema, 'Naam' | 'Toelichting'>;

export const STANDAARDTEKSTEN_TOEVOEGEN_SECTIE_ID = 'standaardtekstenToevoegenSectie';

export const StandaardtekstenToevoegenSectie = modal(
  STANDAARDTEKSTEN_TOEVOEGEN_SECTIE_ID,
  ({ data, props }) => {
    const { mapId } = data;

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>();

    const modalState = useModalState();

    const postMap = usePostStdMap();

    const onSubmit: SubmitHandler<FormValues> = ({ Naam, Toelichting }) => {
      const userId = useUserStore.getState().user?.ID ?? '';

      modalState.action('save');
      postMap.mutate(
        {
          data: {
            Naam,
            Toelichting,
            Map: mapId,
            Created_By: userId,
            Created_Date: new Date().toISOString(),
            Modified_By: userId,
            Modified_Date: new Date().toISOString(),
            Status: 'Actief',
            Landelijk: 0,
            // Tenant will be replaced by BE
            Tenant: PLACEHOLDER_UUID,
          },
        },
        {
          onSuccess: () => {
            modalState.success('save');
          },
          onError: () => {
            modalState.error('save');
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Sectie toevoegen">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('Naam', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
            error={errors.Naam}
            label="Titel"
            id="toevoegen-sectie-naam"
            maxLength={100}
            required
          />
          <FormLabel htmlFor="toevoegen-sectie-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'bold',
                  'italic',
                  'underline',
                  'orderedList',
                  'abcList',
                  'bulletList',
                  'subscript',
                  'superscript',
                  'highlight',
                  'table',
                ]}
                initialText={Toelichting}
                id="toevoegen-sectie-toelichting"
                testId="add-object-sectie-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />
          <Modal.Footer>
            <Modal.CancelButton />

            <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
              Opslaan
            </Modal.ActionButton>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
