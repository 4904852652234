import { Link, createLink } from '@tanstack/react-router';
import { ReactNode } from 'react';

import { RegularAngleLeft, RegularAngleRight } from '@/icons/components';

export const ChapterNavigation = ({
  next,
  previous,
}: {
  next?: ReactNode;
  previous?: ReactNode;
}) => {
  return (
    <nav
      data-testid="chapter-nav-viewer"
      aria-label="Navigatie tussen hoofdstukken"
      className={`mt-6 flex w-full border-t border-gray-200 pt-6 ${
        !previous ? 'justify-end' : !next ? 'justify-start' : 'justify-between'
      }`}
    >
      {previous}

      {next}
    </nav>
  );
};

ChapterNavigation.Previous = createLink(
  ({ htmlTitle, nummer, ...props }: { htmlTitle: string; nummer: string | number }) => {
    return (
      <Link
        {...props}
        className="flex items-center rounded px-4 py-2 transition-colors duration-100 ease-in hover:bg-gray-50"
        aria-labelledby="previous-chapter-label-id"
      >
        <span className="bg-theme-blue-lightest mr-2 flex items-center text-theme-blue">
          <span className="inline-block h-5">
            <RegularAngleLeft size={18} />
          </span>
        </span>

        <span className="ml-2 flex h-full flex-col justify-between text-sm text-gray-700">
          <span id="previous-chapter-label-id">Vorig hoofdstuk</span>
          <span className="text-gray-9 font-semibold">
            <span className="mr-1">{nummer}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: htmlTitle,
              }}
            />
          </span>
        </span>
      </Link>
    );
  }
);

ChapterNavigation.Next = createLink(
  ({ htmlTitle, nummer, ...props }: { htmlTitle: string; nummer: string | number }) => (
    <Link
      {...props}
      className="flex items-center px-4 py-2 transition-colors duration-100 ease-in hover:bg-gray-50"
      aria-labelledby="next-chapter-label-id"
    >
      <span className="mr-2 flex h-full flex-col justify-between text-right text-sm text-gray-700">
        <span id="next-chapter-label-id">Volgend hoofdstuk</span>
        <span className="font-semibold text-gray-900">
          <span className="mr-1">{nummer}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: htmlTitle,
            }}
          />
        </span>
      </span>

      <span className="bg-theme-blue-lightest ml-2 flex items-center text-theme-blue">
        <span className="inline-block h-5">
          <RegularAngleRight size={18} />
        </span>
      </span>
    </Link>
  )
);
