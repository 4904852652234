import { QueryClient } from '@tanstack/react-query';

import {
  ensureQueryDataHoofdstukkenWithEdits,
  filterMostRecentHoofdstukPerLineage,
  useHoofdstukkenWithEdits,
  useHoofdstukkenWithOverweging,
} from '@/api/queries/objects';

/**
 * Returns lists of hoofdstukken.
 *
 * voorschriftenHoofdstukken: includes all hoofdstukken with edits in child objects (P/S/V).
 * overwegingHoofdstukken: includes all hoofdstukken with edits in child objects (P/S/V) and hoofdstukken with an overweging.
 * getSiblings: Returns the siblings for a given hoofdstukId
 * isLoading: Whether one of the queries is still loading
 */
export const useViewerBesluitHoofdstukken = ({ besluitId }: { besluitId: string }) => {
  /**
   * Get voorschriften hoofdstukken
   */
  const voorschriftHoofdstukken = useHoofdstukkenWithEdits({ besluitId });

  /**
   * Get overweging hoofdstukken
   */
  const hoofdstukkenWithOverwegingen = useHoofdstukkenWithOverweging({ besluitId });

  /**
   * Combine hoofdstukken with overwegingen with the regular hoofdstukken
   */
  const overwegingHoofdstukken = filterMostRecentHoofdstukPerLineage([
    ...(hoofdstukkenWithOverwegingen.data ?? []),
    ...voorschriftHoofdstukken.data,
  ]);

  return {
    getSiblings: ({
      hoofdstukId,
      type,
    }: {
      hoofdstukId: string;
      type: 'overwegingen' | 'voorschriften';
    }) => {
      const hoofdstukken =
        type === 'overwegingen' ? overwegingHoofdstukken : voorschriftHoofdstukken.data;
      const currentHoofdstuk = hoofdstukken.find(({ ID }) => ID === hoofdstukId);

      if (!currentHoofdstuk) return {};

      const currentIndex = hoofdstukken.indexOf(currentHoofdstuk);

      if (currentIndex === -1) return {};

      return {
        nextHoofdstuk: hoofdstukken[currentIndex + 1],
        previousHoofdstuk: hoofdstukken[currentIndex - 1],
      };
    },
    voorschriftHoofdstukken: voorschriftHoofdstukken.data,
    overwegingHoofdstukken,
    isLoading: voorschriftHoofdstukken.isLoading || hoofdstukkenWithOverwegingen.isLoading,
  };
};

export const ensureQueryDataViewerBesluitHoofdstukken = async ({
  besluitId,
  queryClient,
}: {
  besluitId: string;
  queryClient: QueryClient;
}) => {
  const [voorschriftHoofdstukkenResponse, overwegingHoofdstukkenResponse] = await Promise.all([
    ensureQueryDataHoofdstukkenWithEdits({ besluitId }, {}, queryClient),
    queryClient.ensureQueryData(useHoofdstukkenWithOverweging.getOptions({ besluitId })),
  ]);

  return {
    voorschriftHoofdstukken: voorschriftHoofdstukkenResponse,
    overwegingHoofdstukken: filterMostRecentHoofdstukPerLineage([
      ...(voorschriftHoofdstukkenResponse ?? []),
      ...(overwegingHoofdstukkenResponse.objects ?? []),
    ]),
  };
};
