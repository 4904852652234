import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512" ref={svgRef} {...props}><path d="M320 48H64c-8.8 0-16 7.2-16 16v384c0 8.8 7.2 16 16 16h220.5c12 18.4 27.4 34.5 45.3 47.3-3.2.5-6.4.7-9.7.7H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0h256c35.3 0 64 28.7 64 64v134.6c-17.2 4.9-33.4 12.3-48 21.8V64c0-8.8-7.2-16-16-16zM96 152c0-13.3 10.7-24 24-24h144c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24zm24 72h144c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 96h48c13.3 0 24 10.7 24 24s-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24s10.7-24 24-24zm312-96a144 144 0 110 288 144 144 0 110-288zm0 96a24 24 0 100-48 24 24 0 100 48zm-32 48c0 8.8 7.2 16 16 16v48c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16v-64c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16z" /></svg>;
});
SVGIcon.displayName = "RegularMemoCircleInfo";
export default SVGIcon;