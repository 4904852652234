import { forwardRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';

import { STDVoorschriftSchema } from '@/api';
import { StdObjects } from '@/api/queries/std';
import { AddItemButton, EditorAdd, EditorVoorschrift, InfoCard } from '@/components';
import {
  RegularAlignLeft,
  RegularHeading,
  RegularParagraph,
  RegularPenToSquare,
} from '@/icons/components';
import { useOpenModal } from '@/modals/utils/modalStore';

interface VoorschriftProps {
  voorschriftIndex: number;
  paragraafIndex: number;
  subparagraafIndex: number;
  voorschrift: STDVoorschriftSchema;
  stdObjects: StdObjects;
  sectieId: string;
  dragHandleRef?: ConnectDragSource;
}

export const Voorschrift = forwardRef<HTMLInputElement, VoorschriftProps>(
  (
    {
      voorschriftIndex,
      paragraafIndex,
      subparagraafIndex,
      voorschrift,
      stdObjects,
      sectieId,
      dragHandleRef,
    },
    ref
  ) => {
    const openModal = useOpenModal();
    const [isOpen, setIsOpen] = useState(false);

    const paragraaf = stdObjects.paragrafen[paragraafIndex];

    if (!paragraaf) {
      throw new Error('No paragraaf found');
    }

    const subparagrafen = paragraaf?.subparagrafen;
    const voorschriften =
      stdObjects.paragrafen[paragraafIndex]?.subparagrafen[subparagraafIndex]?.voorschriften;
    const isLastSubparagraaf = subparagraafIndex === subparagrafen.length - 1;
    const isLastVoorschrift = voorschriftIndex === (voorschriften && voorschriften.length - 1);

    const subparagraafId =
      stdObjects.paragrafen[paragraafIndex]?.subparagrafen[subparagraafIndex]?.data.ID;

    return (
      <div ref={ref}>
        <InfoCard
          description={voorschrift.Toelichting || 'Geen toelichting'}
          testId={`toelichting-${paragraafIndex}-${subparagraafIndex}-${voorschriftIndex}`}
        />

        <EditorVoorschrift
          number={voorschrift.Nummer}
          htmlContent={voorschrift.Voorschrift}
          dragHandleRef={dragHandleRef}
          buttonProps={{
            icon: RegularPenToSquare,
            variant: 'white',
            ariaLabel: `Vorschrift ${voorschrift.Nummer} bewerken`,
            onClick: () => {
              openModal('standaardtekstenEditVoorschriftId', {
                voorschrift,
              });
            },
          }}
        />

        <EditorAdd
          asSpacer={false}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          <AddItemButton
            data-testid="add-item-button-lege-voorschrift"
            icon={RegularAlignLeft}
            label="Voorschrift"
            onClick={() =>
              openModal('standaardtekstenToevoegenVoorschrift', {
                subparagraafId: subparagraafId!,
                voorschriftIndex: voorschriftIndex + 1,
                voorschriften,
                onSubmitSuccess: () => setIsOpen(false),
              })
            }
          />
          {isLastVoorschrift && (
            <>
              <AddItemButton
                data-testid="add-item-button-lege-subparagraaf"
                icon={RegularHeading}
                label="Subparagraaf"
                onClick={() =>
                  openModal('standaardtekstenToevoegenSubparagraaf', {
                    paragraafId: paragraaf.data.ID!,
                    subparagraafIndex,
                    subparagrafen: subparagrafen!,
                    onSubmitSuccess: () => setIsOpen(false),
                  })
                }
              />
              {isLastSubparagraaf && (
                <AddItemButton
                  data-testid="add-item-button-lege-paragraaf"
                  icon={RegularParagraph}
                  label="Paragraaf"
                  onClick={() =>
                    openModal('standaardtekstenToevoegenParagraaf', {
                      sectieId,
                      paragraafIndex,
                      stdObjects,
                      onSubmitSuccess: () => setIsOpen(false),
                    })
                  }
                />
              )}
            </>
          )}
        </EditorAdd>
      </div>
    );
  }
);
