import { useParams } from '@tanstack/react-router';

import { Links, useHoofdstukHB, useHoofdstukId } from '@/api/queries/objects';
import { getObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';

import { HoofdstukInput } from './HoofdstukInput';

interface HoofdstukProps {
  id: string;
  userHasReservering: boolean;
  editorMode: EditorMode;
  links: Links;
}

export const Hoofdstuk = ({ id, links, userHasReservering, editorMode }: HoofdstukProps) => {
  const { besluitId } = useParams({ from: '/$bedrijfLineageId/editor/$besluitId' });

  const hoofdstuk = useHoofdstukId(id);

  /**
   * Get HB
   */
  const HB = useHoofdstukHB({ besluitId, hoofdstukId: id });

  /**
   * Check if hoofdstuk is editable
   */
  const objectState = getObjectState({
    object: hoofdstuk.data,
    linkType: HB.data?.Type,
    userHasReservering,
  });

  /**
   * Return loading state when hoofdstuk is loading or not available
   */
  if (hoofdstuk.isLoading || !hoofdstuk.data) {
    return <div>Loading...</div>;
  }

  return (
    <div
      // Add a key to force a rerender when the hoofdstuk is changed
      key={id}
      data-testid="hoofdstuk"
    >
      <HoofdstukInput
        id={id}
        links={links}
        hoofdstuk={hoofdstuk.data}
        objectState={objectState}
        userHasReservering={userHasReservering}
        editorMode={editorMode}
      />
    </div>
  );
};
