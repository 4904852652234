import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512" ref={svgRef} {...props}><path d="M88 80c-4.4 0-8 3.6-8 8v320c0 13.3 10.7 24 24 24h368c13.3 0 24-10.7 24-24V155.9l-139.1 89c-7.4 4.7-16.8 5-24.5.8s-12.5-12.3-12.5-21.1v-70.9l-139.8 81.7c-7.4 4.3-16.6 4.4-24 .1s-12-12.2-12-20.8V88c0-4.4-3.6-8-8-8H88zm-56 8c0-30.9 25.1-56 56-56h48c30.9 0 56 25.1 56 56v84.9l103.8-60.6c32-18.7 72.2 4.4 72.2 41.5v27l102.1-65.4c32-20.4 73.9 2.5 73.9 40.5V408c0 39.8-32.2 72-72 72H104c-39.8 0-72-32.2-72-72V88z" /></svg>;
});
SVGIcon.displayName = "RegularIndustry";
export default SVGIcon;