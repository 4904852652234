import { BesluitSchema } from '@/api';
import { useDeleteBesluitId } from '@/api/queries/besluit';
import { Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface BesluitVerwijderenProps {
  besluit: BesluitSchema;
}

export const BESLUIT_VERWIJDEREN_ID = 'besluitVerwijderen';

export const BesluitVerwijderen = modal(BESLUIT_VERWIJDEREN_ID, ({ data, props }) => {
  const { besluit } = data;

  const modalState = useModalState();

  const deleteBesluit = useDeleteBesluitId();

  const verwijderBesluit = () => {
    modalState.action('delete');
    deleteBesluit.mutate(
      {
        id: besluit.ID!,
      },
      {
        onSuccess: () => {
          modalState.success('delete');
        },
        onError: (error) => {
          modalState.error('delete', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
          });
        },
      }
    );
  };

  return (
    <Modal {...props} state={modalState.state} title="Ontwerp verwijderen">
      <p className="text-gray-800">
        Je staat op het punt om het ontwerp van dit besluit te verwijderen. Hiermee verwijder je
        alle wijzigingen die in dit ontwerp zijn gemaakt. Weet je zeker dat je het ontwerp wil
        verwijderen?
      </p>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="delete"
          successLabel="Verwijderd"
          variant="red"
          onClick={verwijderBesluit}
        >
          Verwijderen
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
