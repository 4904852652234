import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { SolidTriangleExclamation } from '@/icons/components';

import { LoaderSpinningIcon } from '../Loader';
import { TextButton } from '../TextButton';

export interface WarningInlineProps {
  description: string;
  tryAgain?: () => Promise<unknown>;
  center?: boolean;
}

export const WarningInline = ({ description, center, tryAgain }: WarningInlineProps) => {
  const [isTryingAgain, setIsTryingAgain] = useState(false);

  return (
    <p
      className={twJoin(
        'flex w-full items-center gap-2 py-2 text-sm text-digi-v-color-danger',
        center && 'justify-center px-2'
      )}
    >
      <SolidTriangleExclamation />

      <span>{description}</span>

      {tryAgain && (
        <TextButton
          className="inline-block whitespace-nowrap"
          onClick={() => {
            setIsTryingAgain(true);

            tryAgain().finally(() => {
              setIsTryingAgain(false);
            });
          }}
          type="button"
        >
          Probeer opnieuw{' '}
          {isTryingAgain && (
            <LoaderSpinningIcon iconSize={14} className="ml-1 inline-block text-theme-blue" />
          )}
        </TextButton>
      )}
    </p>
  );
};
