import { useClickOutside } from '@react-hookz/web';
import { useEffect, useRef, useState } from 'react';

import { TextEditorOptionDropdown } from '@/components/shared/TextEditor/config/config';
import { useTextEditor } from '@/components/shared/TextEditor/context/useTextEditor';

import { TextEditorMenuButton } from '../TextEditorMenuButton';

export const TextEditorMenuDropdown = ({
  buttons,
  label,
  icon,
  ariaLabel,
}: TextEditorOptionDropdown) => {
  const { setDropdownIsOpen } = useTextEditor();

  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setDropdownIsOpen(isOpen);

    return () => setDropdownIsOpen(false);
  }, [isOpen, setDropdownIsOpen]);

  useClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  return (
    <div ref={containerRef} className="relative">
      <TextEditorMenuButton
        icon={icon}
        label={label}
        ariaLabel={ariaLabel}
        onClick={() => {
          setIsOpen(true);
        }}
      />

      {isOpen && (
        <div className="absolute -left-1 top-full flex flex-col items-start gap-1 rounded-md bg-gray-900 p-1">
          {buttons.map((button) => (
            <TextEditorMenuButton
              key={`text-editor-menu-dropdown-item-${button.ariaLabel}`}
              {...button}
              width={icon && !label ? undefined : 'w-full'}
            />
          ))}
        </div>
      )}
    </div>
  );
};
