import { useQuery } from '@tanstack/react-query';

import { useHistorischBesluit } from '../historischBesluit';

type HistorischBesluitByLineageIdParams = {
  voorschriftLineageId: string;
};

const getHistorischBesluitByLineageIdQueryOptions = ({
  voorschriftLineageId,
}: HistorischBesluitByLineageIdParams) => {
  return useHistorischBesluit.getOptions(
    {
      page: 1,
      size: 1,
      filter: [
        {
          field: 'Voorschrift_lineage',
          op: 'eq',
          value: voorschriftLineageId,
          model: 'VoorschriftHistorischBesluit',
        },
      ],
    },
    {
      select: ({ objects }) => objects?.[0],
    }
  );
};

const useHistorischBesluitByLineageId = (props: HistorischBesluitByLineageIdParams) =>
  useQuery(getHistorischBesluitByLineageIdQueryOptions(props));
useHistorischBesluitByLineageId.options = getHistorischBesluitByLineageIdQueryOptions;

export { useHistorischBesluitByLineageId };
