import { Link } from '@tanstack/react-router';

import { VoorschriftSchema } from '@/api';
import { useHoofdstuk, useVoorschrift } from '@/api/queries/objects';
import { createInString } from '@/utils/createInString';

import { TextButton } from '../../TextButton';
import { ValidateBesluitProps } from '../ValidateBesluit.types';
import { ValidateAccordionSkeleton } from './ValidateAccordionSkeleton';
import { ValidateRow } from './ValidateRow';

/**
 * Check if there are empty voorschriften
 */
export const useValidateVoorschrift = (besluitId: string) => {
  return useVoorschrift(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'Besluit',
          field: 'ID',
          op: 'eq',
          value: besluitId,
        },
        {
          model: 'Voorschrift',
          field: 'Voorschrift',
          op: 'eq',
          value: '',
        },
        {
          model: 'VSPB',
          field: 'Type',
          op: 'eq',
          value: 'Eigen',
        },
      ],
    },
    {
      select: ({ num_objects, objects }) => ({ isValid: num_objects === 0, objects }),
      gcTime: 0,
      staleTime: 0,
    }
  );
};

export const ValidateVoorschrift = ({ validate }: ValidateBesluitProps) => {
  const { isFetching, data } = validate.queries.validateVoorschiften;
  const voorschriften = data?.objects ?? [];

  if (isFetching || !data) {
    return <ValidateRow isFetching title="Lege voorschriften" />;
  }

  if (!data.isValid) {
    return (
      <ValidateRow
        isValid={false}
        title={`${voorschriften.length} ${voorschriften.length > 1 ? 'lege voorschriften' : 'leeg voorschrift'}`}
        accordionBody={
          <ErrorBody
            voorschriften={data.objects ?? []}
            besluitId={validate.besluitId}
            bedrijfLineageId={validate.bedrijfLineageId}
          />
        }
      />
    );
  }

  return <ValidateRow isValid title="Geen lege voorschriften" />;
};

const ErrorBody = ({
  voorschriften,
  besluitId,
  bedrijfLineageId,
}: {
  voorschriften: VoorschriftSchema[];
  besluitId: string;
  bedrijfLineageId: string;
}) => {
  const hoofdstukken = useHoofdstuk({
    page: 1,
    size: 100,
    filter: [
      {
        model: 'Voorschrift',
        field: 'ID',
        op: 'in',
        value: createInString(voorschriften.map(({ ID }) => ID ?? '')),
      },
    ],
    sort: [{ model: 'Hoofdstuk', field: 'Nummer', direction: 'asc' }],
  });

  if (hoofdstukken.isLoading) return <ValidateAccordionSkeleton />;

  return (
    <ul className="list-inside list-disc">
      {hoofdstukken.data?.objects?.map((hoofdstuk) => (
        <li key={hoofdstuk.ID}>
          <TextButton size="small" asChild>
            <Link
              to="/$bedrijfLineageId/editor/$besluitId/voorschriften/$hoofdstukId"
              params={{
                bedrijfLineageId,
                besluitId,
                hoofdstukId: hoofdstuk.ID ?? '',
              }}
            >
              Hoofdstuk {hoofdstuk.Nummer} bevat één of meerdere lege voorschriften.
            </Link>
          </TextButton>
        </li>
      ))}
    </ul>
  );
};
