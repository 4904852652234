import React, { InputHTMLAttributes, ReactNode, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';

import { FormError } from '../FormError';
import { FormLabel } from '../FormLabel';

export interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  id: string;
  name: string;
  label: string;
  subtitle?: string;
  hideLabel?: boolean;
  required?: boolean;
  labelClasses?: string;
  inputClasses?: string;
  /** Error coming from `react-hook-form` */
  error?: FieldError;
  disabled?: boolean;
  horizontal?: boolean;
  children: ReactNode;
  ref?: React.Ref<HTMLSelectElement>;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      name,
      label,
      hideLabel,
      className,
      labelClasses,
      inputClasses,
      required,
      error,
      disabled,
      horizontal,
      children,
      onChange,
      subtitle,
      ...props
    },
    ref
  ) => {
    const [hasValue, setHasValue] = useState<boolean>(!!props.defaultValue);

    const errorId = `${id}-error`;

    return (
      <div
        className={twMerge(
          twJoin(horizontal && 'grid w-full grid-cols-3', !horizontal && 'flex flex-col'),
          className
        )}
      >
        <div className={twJoin(hideLabel && 'sr-only', horizontal && 'col-span-1 pr-2 pt-1')}>
          <FormLabel htmlFor={id} className={labelClasses} required={required}>
            {label}
          </FormLabel>

          {subtitle ? <p className="mb-2 text-sm leading-5 text-gray-700">{subtitle}</p> : null}
        </div>

        <div className={twJoin('w-full', horizontal && 'col-span-2')}>
          <select
            id={id}
            name={name}
            ref={ref}
            disabled={disabled}
            aria-invalid={error ? true : undefined}
            onChange={(e) => {
              setHasValue(!!e.target.value);
              onChange?.(e);
            }}
            aria-describedby={error ? errorId : undefined}
            className={twJoin(
              'form-select-custom w-full rounded-md shadow-sm disabled:cursor-not-allowed',
              error &&
                'border-6 border-solid border-digi-v-color-danger focus:border-digi-v-color-danger focus:ring focus:ring-digi-v-color-danger/30',
              !error &&
                'border-gray-300 focus:border-blue-500/60 focus:ring focus:ring-blue-500/30',
              !hasValue && 'text-gray-500',
              hasValue && 'text-black',
              inputClasses
            )}
            {...props}
          >
            {children}
          </select>

          {error && <FormError id={errorId}>{error.message}</FormError>}
        </div>
      </div>
    );
  }
);
Select.displayName = 'Select';
