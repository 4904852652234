import { HoofdstukSchema } from '@/api/generated/digiVAPI.schemas';

/**
 * Merge two lists of hoofdstukken. Returns a single hoofdstuk per Lineage with the highest Index.
 */
export const filterMostRecentHoofdstukPerLineage = (hoofdstukken: HoofdstukSchema[]) => {
  const map = new Map<string, HoofdstukSchema>();

  hoofdstukken
    // Sort by Index descending, to get the most recent hoofdstuk first.
    .sort((a, b) => b.Index - a.Index)
    // To get a single hoofdstuk per Lineage, we filter out duplicates in a Lineage.
    .forEach((hoofdstuk) => map.set(hoofdstuk.Lineage!, hoofdstuk));

  return [...map.values()].sort((a, b) => a.Nummer - b.Nummer);
};
