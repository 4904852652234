import { MODAL_COMPONENTS } from './config';
import { useActiveModals } from './utils/modalStore';

export const Modals = () => {
  const activeModals = useActiveModals();

  return (
    <>
      {activeModals.map((modal) => {
        const Modal = MODAL_COMPONENTS[modal.id];

        return <Modal key={modal.uuid} uuid={modal.uuid} />;
      })}
    </>
  );
};
