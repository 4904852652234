import { useHB } from '@/api/queries/objects';

export const useHoofdstukHB = (
  {
    besluitId,
    hoofdstukId,
  }: {
    besluitId?: string;
    hoofdstukId?: string;
  },
  options: { staleTime?: number; enabled?: boolean } = { enabled: true }
) => {
  return useHB(
    {
      page: 1,
      size: 1,
      filter: [
        {
          field: 'Besluit',
          value: besluitId ?? '',
          op: 'eq',
        },
        {
          field: 'Hoofdstuk',
          value: hoofdstukId ?? '',
          op: 'eq',
        },
      ],
    },
    {
      enabled: !!besluitId && !!hoofdstukId && options.enabled,
      select: ({ objects }) => {
        return objects?.[0];
      },
      ...options,
    }
  );
};
