import { Link } from '@tanstack/react-router';

import { BedrijfSchema, BesluitSchema } from '@/api';
import { usePatchBesluitId } from '@/api/queries/besluit';
import { useBesluitTypeId } from '@/api/queries/besluitType';
import { BesluitCard, BesluitCardHeadingProps, TextButton } from '@/components';
import { RegularFilePen } from '@/icons/components';
import { useOpenModal } from '@/modals/utils';

interface BesluitCardInOntwerpProps {
  besluit: BesluitSchema;
  bedrijf: BedrijfSchema;
  headingAs?: BesluitCardHeadingProps['headingAs'];
  hasVigerendBesluit: boolean;
}

export const BesluitCardInOntwerp = ({
  besluit,
  bedrijf,
  headingAs,
  hasVigerendBesluit,
}: BesluitCardInOntwerpProps) => {
  const openModal = useOpenModal();

  const patchBesluitId = usePatchBesluitId();
  const besluitType = useBesluitTypeId(besluit.Type);

  if (!besluit.ID) return null;

  return (
    <BesluitCard icon={RegularFilePen}>
      <BesluitCard.Body>
        <BesluitCard.Heading
          title={`${besluitType.data?.Naam ?? ''} ${besluit.Zaaknummer ?? ''}`}
          tag={besluit.Ontwerp_Beschikking === 0 ? 'Beschikking' : 'Ontwerpbeschikking'}
          headingAs={headingAs}
        />

        {besluit.Korte_Omschrijving && (
          <BesluitCard.Description>{besluit.Korte_Omschrijving}</BesluitCard.Description>
        )}

        <BesluitCard.ButtonRow>
          <TextButton size="small" asChild>
            <Link
              to="/$bedrijfLineageId/editor/$besluitId/voorschriften/hoofdstuk"
              params={{ besluitId: besluit.ID, bedrijfLineageId: bedrijf.Lineage }}
            >
              Bewerken
            </Link>
          </TextButton>
          <TextButton
            size="small"
            onClick={() =>
              openModal('besluitExporteren', { besluit, bedrijfLineageId: bedrijf.Lineage })
            }
          >
            Exporteren
          </TextButton>
          <TextButton
            size="small"
            onClick={() =>
              openModal('besluitPubliceren', { besluit, bedrijfLineageId: bedrijf.Lineage })
            }
          >
            Afronden
          </TextButton>
          <TextButton
            size="small"
            onClick={() => {
              if (besluitType.data?.Wijziging_zonder_besluit) {
                openModal('wijziging', {
                  title: 'Eigenschappen van wijziging',
                  bedrijfLineageId: bedrijf.Lineage,
                  submitLabel: 'Opslaan',
                  submitSuccessLabel: 'Opgeslagen',
                  disabledWijzigingType: true,
                  defaultValues: {
                    Type: besluit.Type,
                    Korte_Omschrijving: besluit.Korte_Omschrijving,
                  },
                  onSubmit: async (formValues) => {
                    if (besluit.ID) {
                      try {
                        await patchBesluitId.mutateAsync({
                          data: {
                            ...besluit,
                            ...formValues,
                          },
                          id: besluit.ID,
                        });
                      } catch {
                        throw new Error('Besluit eigenschappen aanpassen mislukt');
                      }
                    }
                  },
                });
              } else {
                openModal('besluitEigenschappen', {
                  title: 'Eigenschappen van besluit',
                  submitLabel: 'Opslaan',
                  submitSuccessLabel: 'Opgeslagen',
                  hasVigerendBesluit,
                  disabledBesluitType: true,
                  defaultValues: {
                    Type: besluit.Type,
                    Ontwerp_Beschikking: besluit.Ontwerp_Beschikking,
                    Korte_Omschrijving: besluit.Korte_Omschrijving,
                    OLO_Nummer: besluit.OLO_Nummer,
                    Ons_Kenmerk: besluit.Ons_Kenmerk,
                    Zaaknummer: besluit.Zaaknummer,
                  },
                  onSubmit: async (formValues) => {
                    if (besluit.ID) {
                      try {
                        await patchBesluitId.mutateAsync({
                          data: {
                            ...besluit,
                            ...formValues,
                          },
                          id: besluit.ID,
                        });
                      } catch {
                        throw new Error('Besluit eigenschappen aanpassen mislukt');
                      }
                    }
                  },
                });
              }
            }}
          >
            Eigenschappen
          </TextButton>
          <TextButton
            size="small"
            variant="danger"
            onClick={() => {
              openModal('besluitVerwijderen', {
                besluit,
              });
            }}
          >
            Ontwerp verwijderen
          </TextButton>
        </BesluitCard.ButtonRow>
      </BesluitCard.Body>
    </BesluitCard>
  );
};

export default BesluitCardInOntwerp;
