import { ReactNode } from 'react';

import { EditorRow, EditorRowProps } from '../EditorRow';

export interface EditorSubparagraafProps
  extends Pick<
    EditorRowProps,
    'buttonProps' | 'testId' | 'status' | 'dragHandleRef' | 'dragPreviewRef' | 'className'
  > {
  content?: string;
  children?: ReactNode;
}

export const EditorSubparagraaf = ({ content, children, ...props }: EditorSubparagraafProps) => {
  return (
    <EditorRow
      {...props}
      fontClasses="text-lg text-gray-800 italic"
      buttonTestId="editor-subpargraaf"
      content={
        <h4
          className="text-left text-lg italic text-gray-800"
          dangerouslySetInnerHTML={{ __html: content ?? '' }}
        />
      }
    >
      {children}
    </EditorRow>
  );
};
