import { SubmitHandler, useForm } from 'react-hook-form';

import { LabelSchema } from '@/api';
import { usePatchLabelId, usePostLabel } from '@/api/queries/label';
import { Input, Modal, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export type BeheerLabelProps = {
  createLabel?: {
    mapId: string;
  };
  updateLabel?: {
    label: LabelSchema;
  };
};

type FormValues = Pick<LabelSchema, 'Label'>;

export const BEHEER_LABEL_ID = 'beheerLabel';

export const BeheerLabel = modal(BEHEER_LABEL_ID, ({ data, props }) => {
  const { createLabel, updateLabel } = data;

  const modalState = useModalState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: updateLabel?.label,
  });

  const postLabel = usePostLabel();
  const patchLabel = usePatchLabelId();

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const userId = useUserStore.getState().user?.ID ?? '';
    const date = new Date().toISOString();

    modalState.action('save');

    if (createLabel) {
      postLabel.mutate(
        {
          data: {
            Label: formData.Label,
            Created_By: userId,
            Modified_By: userId,
            Created_Date: date,
            Modified_Date: date,
            Status: 'Actief',
            Map: createLabel.mapId,
            Landelijk: 0,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    }

    if (updateLabel) {
      patchLabel.mutate(
        {
          id: updateLabel.label.ID!,
          data: {
            Label: formData.Label,
            Modified_By: userId,
            Modified_Date: date,
          },
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              modalState.error('save', { title: 'Mislukt', description: errorMessage });
            }
          },
        }
      );
    }
  };

  return (
    <Modal {...props} state={modalState.state} title="Label">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('Label', { required: eStrings.TXT_VERPLICHT_INPUT_VELD })}
          error={errors.Label}
          label="Titel"
          id="label-title"
          placeholder="Voer een titel in"
          required
          maxLength={200}
        />
        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
            Opslaan
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
