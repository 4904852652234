import { useQueryClient } from '@tanstack/react-query';

import { usePatchDynActiviteitCategorieId } from '@/api/generated/digiVAPI';
import { ActiviteitCategorieSchema } from '@/api/generated/digiVAPI.schemas';

import { useActiviteitCategorie, useActiviteitCategorieId } from '../activiteitCategorie';

export function usePatchActiviteitCategorieId() {
  const queryClient = useQueryClient();

  return usePatchDynActiviteitCategorieId({
    mutation: {
      onSuccess: async (activiteitCategorie) => {
        await queryClient.invalidateQueries({ queryKey: useActiviteitCategorie.key });

        queryClient.setQueryData<ActiviteitCategorieSchema>(
          useActiviteitCategorieId.getKey(activiteitCategorie.ID!),
          activiteitCategorie
        );
      },
    },
  });
}
