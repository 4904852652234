import { useState } from 'react';

import { ParagraafSchema } from '@/api';
import { LinkParagraaf, useParagraaf } from '@/api/queries/objects';
import { useCreateParagraafInLineage } from '@/api/queries/objects/mutations/useCreateParagraafInLineage';
import { Checkbox, Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface IntrekkenParagraafProps {
  nummer: string;
  paragraaf: ParagraafSchema;
  link: LinkParagraaf;
}

export const INTREKKEN_PARAGRAAF_ID = 'intrekkenParagraaf';

export const IntrekkenParagraaf = modal(INTREKKEN_PARAGRAAF_ID, ({ data, props }) => {
  const { nummer, link, paragraaf } = data;

  const modalState = useModalState();
  const createParagraafInLineage = useCreateParagraafInLineage();

  const [ontwerpValue, setOntwerpValue] = useState(false);

  const intrekken = () => {
    modalState.action('save');

    createParagraafInLineage.mutate(
      {
        paragraaf: {
          ...paragraaf,
          Stop_Lineage: 1,
        },
        link,
      },
      {
        onSuccess: () => {
          modalState.success('save');
        },
        onError: (error) => {
          modalState.error('save', {
            title: 'Mislukt',
            description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
          });
        },
      }
    );
  };

  const containsNewVoorschrift = useParagraaf(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Paragraaf',
          field: 'ID',
          op: 'eq',
          value: link.PHB.Paragraaf,
        },
        {
          model: 'Voorschrift',
          field: 'Ontwerp',
          op: 'eq',
          value: '1',
        },
      ],
    },
    {
      select: (data) => !!data.num_objects,
    }
  );

  const containsNewSubparagraaf = useParagraaf(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Paragraaf',
          field: 'ID',
          op: 'eq',
          value: link.PHB.Paragraaf,
        },
        {
          model: 'Subparagraaf',
          field: 'Ontwerp',
          op: 'eq',
          value: '1',
        },
        {
          model: 'Subparagraaf',
          field: 'Transparent',
          op: 'eq',
          value: '0',
        },
      ],
    },
    {
      select: (data) => !!data.num_objects,
    }
  );

  const containsOntwerpObject = containsNewSubparagraaf.data || containsNewVoorschrift.data;

  return (
    <Modal
      state={modalState.state}
      title={`Intrekken paragraaf ${nummer}`}
      isLoading={containsNewSubparagraaf.isLoading || containsNewVoorschrift.isLoading}
      {...props}
    >
      <p>
        Je staat op het punt om <strong>paragraaf {nummer}</strong> in te trekken. Alle
        onderliggende subparagrafen en voorschriften zullen worden ingetrokken.
      </p>

      {containsOntwerpObject && (
        <form className="mt-4">
          <Checkbox
            id="contains-ontwerp-object"
            name="contains-ontwerp-object"
            label="Ik begrijp dat bewerkingen onder dit object zullen worden verwijderd."
            checked={ontwerpValue}
            onChange={() => setOntwerpValue(!ontwerpValue)}
          />
        </form>
      )}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          disabled={containsOntwerpObject && !ontwerpValue}
          action="save"
          successLabel="Paragraaf ingetrokken"
          onClick={intrekken}
        >
          Paragraaf intrekken
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
