import { useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { useHoofdstukId } from '@/api/queries/objects';
import { eStrings } from '@/constants/strings';
import { RegularChevronDown } from '@/icons/components';

import { InhoudelijkeOverwegingenEditor } from './InhoudelijkeOverwegingenEditor';

interface InhoudelijkeOverwegingenPanelProps {
  hoofdstukId: string;
  besluitId: string;
}

export const InhoudelijkeOverwegingenPanel = ({
  hoofdstukId,
  besluitId,
}: InhoudelijkeOverwegingenPanelProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const hoofdstukLineageId = useHoofdstukId(hoofdstukId, {
    select: (data) => data.Lineage,
  });

  return (
    <div className="fixed bottom-0 left-0 z-10 w-screen pr-3">
      <div
        className={`${isOpen ? 'w-full bg-gray-50' : 'max-w-5xl px-4 sm:px-6 lg:px-8 2xl:mx-auto'}`}
      >
        <button
          className={twJoin(
            'inline-block border border-gray-200 bg-gray-100 px-4 py-2 transition-colors duration-150 ease-in ',
            'hover:border-gray-300 hover:bg-gray-200',
            isOpen ? 'w-full' : 'border-b-0'
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={twJoin(
              'flex items-center',
              isOpen && 'max-w-5xl px-4 text-left sm:px-6 lg:px-8 2xl:mx-auto'
            )}
          >
            <RegularChevronDown
              size={16}
              className={twJoin('mr-3 text-gray-500', !isOpen && 'rotate-180')}
            />
            <span className="text-gray-700">{eStrings.LBL_CONSIDERANS}</span>
          </div>
        </button>

        {hoofdstukLineageId.data && isOpen && (
          <Popup hoofdstukLineageId={hoofdstukLineageId.data} besluitId={besluitId} />
        )}
      </div>
    </div>
  );
};

const Popup = ({
  hoofdstukLineageId,
  besluitId,
}: {
  hoofdstukLineageId: string;
  besluitId: string;
}) => {
  const overwegingRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom when vertical scrollbar kicks in on the container
  const scrollHeight = overwegingRef.current ? overwegingRef.current.scrollHeight : null;

  useEffect(() => {
    if (scrollHeight === null) return;
    const el = overwegingRef.current;
    if (!el) return;
    const hasScrollbar = el.scrollHeight > el.clientHeight;
    if (!hasScrollbar) return;
    el.scrollTop = scrollHeight;
  }, [scrollHeight]);

  return (
    <div
      data-testid="inhoudelijke-overwegingen-pop-up"
      className="max-w-5xl overflow-auto px-4 sm:px-6 lg:px-8 2xl:mx-auto"
      style={{
        maxHeight: '50vh',
        minHeight: '25vh',
      }}
    >
      <div
        className="bg-white pl-16 pr-8 pt-5 shadow sm:py-6"
        style={{ minHeight: '25vh' }}
        id="inhoudelijke-overwegingen-inline"
        ref={overwegingRef}
      >
        <div
          style={{
            minHeight: '200px',
            padding: '50px 2px 0px',
            marginBottom: '50px',
          }}
        >
          <InhoudelijkeOverwegingenEditor
            hoofdstukLineageId={hoofdstukLineageId}
            besluitId={besluitId}
          />
        </div>
      </div>
    </div>
  );
};
