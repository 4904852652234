import { useCallback, useEffect, useMemo, useRef } from 'react';

/**
 * Hook to run a function in a request animation frame loop running at 60fps.
 * @param fn
 * @returns { start: () => void, end: () => void}
 * @example
 * const { start, end } = useRequestAnimationLoop(() => {
 *  // do something
 * });
 *
 * useEffect(() => {
 *    start();
 *    return () => {
 *      end();
 *   };
 * });
 */
export const useRequestAnimationLoop = (fn: () => void) => {
  const isRunning = useRef<boolean>(false);
  const lastTimestamp = useRef(0);

  const recursiveAnimation = useCallback(
    (timestamp: number) => {
      const timeDiff = timestamp - lastTimestamp.current;

      if (!isRunning.current) {
        return;
      }

      if (timeDiff >= 1000 / 60) {
        fn();
        lastTimestamp.current = timestamp;

        requestAnimationFrame(recursiveAnimation);
      } else {
        requestAnimationFrame(recursiveAnimation);
      }
    },
    [fn]
  );

  useEffect(() => {
    return () => {
      isRunning.current = false;
    };
  });

  return useMemo(
    () => ({
      start: () => {
        if (!isRunning.current) {
          isRunning.current = true;
          requestAnimationFrame(recursiveAnimation);
        }
      },
      end: () => {
        isRunning.current = false;
      },
    }),
    [recursiveAnimation]
  );
};
