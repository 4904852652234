import { Slot } from '@radix-ui/react-slot';
import { ReactNode, forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

export interface TextButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'black' | 'theme-blue' | 'danger' | 'digi-v-gray-light' | 'white' | 'inherit';
  underline?: boolean;
  size?: 'small' | 'default';
  active?: boolean;
  excludeActiveStyling?: boolean;
  className?: string;
  children?: ReactNode;
  asChild?: boolean;
}

export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  (
    {
      variant = 'theme-blue',
      underline = true,
      size = 'default',
      active,
      excludeActiveStyling,
      children,
      className,
      asChild,
      ...props
    },
    ref
  ) => {
    const Component = asChild ? Slot : 'button';

    let defaultActiveColor = '';
    let textColor = '';
    let activeTextColor = '';

    switch (variant) {
      case 'black':
        defaultActiveColor = '[&.active]:text-theme-blue';
        textColor = 'text-black';
        activeTextColor = 'text-theme-blue';
        break;
      case 'theme-blue':
        defaultActiveColor = '[&.active]:text-black';
        textColor = 'text-theme-blue';
        activeTextColor = 'text-black';
        break;
      case 'danger':
        defaultActiveColor = '[&.active]:text-digi-v-color-danger';
        textColor = 'text-digi-v-color-danger';
        activeTextColor = 'text-digi-v-color-danger';
        break;
      case 'digi-v-gray-light':
        defaultActiveColor = '[&.active]:text-black';
        textColor = 'text-digi-v-gray-light';
        activeTextColor = 'text-black';
        break;
      case 'white':
        defaultActiveColor = '[&.active]:text-white';
        textColor = 'text-white';
        activeTextColor = 'text-white';
        break;
      case 'inherit':
        defaultActiveColor = '[&.active]:text-inherit';
        textColor = 'text-inherit';
        activeTextColor = 'text-inherit';
        break;
    }

    return (
      <Component
        {...props}
        ref={ref}
        className={twMerge(
          twJoin(
            'text-left disabled:cursor-not-allowed',
            !excludeActiveStyling && defaultActiveColor,
            active && !excludeActiveStyling ? activeTextColor : textColor,
            underline ? 'underline hover:no-underline' : 'hover:underline',
            size === 'small' && 'text-sm',
            size === 'default' && 'text-md'
          ),
          className
        )}
      >
        {children}
      </Component>
    );
  }
);
