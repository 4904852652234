import { Transition } from '@headlessui/react';
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext,
  useLocation,
  useRouterState,
} from '@tanstack/react-router';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { DevTools } from '@/components/shared/DevTools';
import { SolidSpinner } from '@/icons/components';
import { Modals } from '@/modals/Modals';
import { useUnmountModals } from '@/modals/utils';
import { queryClient } from '@/services/queryClient';

export const Route = createRootRouteWithContext<{
  queryClient: typeof queryClient;
  getTitle?: () => string;
}>()({
  component: function Root() {
    const resolvedPathname = useRouterState({ select: (state) => state.resolvedLocation.pathname });

    /**
     * Because modals are rendered outside of each route, we have to make sure to unmount them when the route changes
     */
    const unmountModals = useUnmountModals();

    useLayoutEffect(() => {
      unmountModals();
    }, [resolvedPathname, unmountModals]);

    return (
      // whitespace-pre-wrap is needed to show double spaces
      <div className="whitespace-pre-wrap">
        <Outlet />

        <PageLoader />
        <ScrollRestoration />
        <Modals />
        <DevTools />
      </div>
    );
  },
});

const PageLoader = () => {
  const pathname = useLocation({ select: (location) => location.pathname });

  /**
   * Loading state
   */
  const previousRoute = useRef<string | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const isLoading = useRouterState({
    select: (s) => s.status === 'pending',
  });

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(false);

    if (previousRoute.current !== pathname) {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setShowLoading(true);
      }, 500);
      previousRoute.current = pathname;
    }

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [pathname]);

  if (!showLoading) {
    return null;
  }

  return (
    <Transition
      show={showLoading && isLoading}
      appear
      className="absolute left-1/2 top-[50px] z-30 -ml-[40px] flex size-[80px] items-center justify-center rounded-full bg-black/80 text-white shadow-lg transition-all duration-300"
      enter="ease-out"
      enterFrom="-translate-y-[40px] opacity-0"
      enterTo="translate-y-0 opacity-1"
      leave="delay-300 ease-in"
      leaveFrom="translate-y-0 opacity-1"
      leaveTo="-translate-y-[40px]  opacity-0"
    >
      <SolidSpinner size={32} className="rotating" />
    </Transition>
  );
};
