import { Helmet } from 'react-helmet-async';

interface DocumentTitleProps {
  sections: (string | undefined)[];
}

/**
 * Adds a <title> tag to the document head, separated by "•". In webclient.
 */
export const DocumentTitle = ({ sections }: DocumentTitleProps) => (
  <Helmet>
    <title>{sections.filter(Boolean).join(' • ')}</title>
  </Helmet>
);
