import { twMerge } from 'tailwind-merge';

import { Pagination } from '../Pagination/Pagination';

export interface PaginationButtonsProps {
  /** Current selected page */
  currentPage: number;
  /** Total pages */
  totalLength: number;
  /** Callback when a new page is selected. State must be manage outside of PaginationButtons */
  onSelect: (page: number) => void;
  /** Optional extra className */
  className?: string;
}

export const PaginationButtons = ({
  currentPage,
  totalLength,
  onSelect,
  className,
}: PaginationButtonsProps) => {
  const firstPage = 1;
  const lastPage = totalLength;

  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;

  return (
    <nav
      role="navigation"
      aria-label="Besluiten navigatie"
      className={twMerge('flex gap-1', className)}
    >
      <Pagination.Button
        ariaLabel="Vorige pagina"
        disabled={currentPage === firstPage}
        onClick={() => {
          onSelect(currentPage - 1);
        }}
      >
        <Pagination.PreviousIcon />
      </Pagination.Button>

      {firstPage !== currentPage && (
        <Pagination.Button ariaLabel={`Pagina ${firstPage}`} onClick={() => onSelect(firstPage)}>
          {firstPage}
        </Pagination.Button>
      )}

      {previousPage > 2 && <Pagination.Truncate testId="pagination-truncate-previous" />}

      {previousPage !== firstPage && previousPage > 0 && (
        <Pagination.Button
          ariaLabel={`Pagina ${previousPage}`}
          onClick={() => onSelect(previousPage)}
        >
          {previousPage}
        </Pagination.Button>
      )}

      <Pagination.Button ariaLabel={`Pagina ${currentPage}`} active>
        {currentPage}
      </Pagination.Button>

      {nextPage < lastPage && (
        <Pagination.Button ariaLabel={`Pagina ${nextPage}`} onClick={() => onSelect(nextPage)}>
          {nextPage}
        </Pagination.Button>
      )}

      {lastPage - nextPage > 1 && <Pagination.Truncate testId="pagination-truncate-next" />}

      {lastPage !== currentPage && (
        <Pagination.Button ariaLabel={`Pagina ${lastPage}`} onClick={() => onSelect(lastPage)}>
          {lastPage}
        </Pagination.Button>
      )}

      <Pagination.Button
        ariaLabel="Volgende pagina"
        disabled={currentPage === lastPage}
        onClick={() => {
          onSelect(currentPage + 1);
        }}
      >
        <Pagination.NextIcon />
      </Pagination.Button>
    </nav>
  );
};
