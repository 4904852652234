import { useQuery } from '@tanstack/react-query';
import { Link, createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { SyntheticEvent } from 'react';
import { z } from 'zod';

import { FilterSchema } from '@/api';
import { useBedrijf } from '@/api/queries/bedrijf';
import { Button, DocumentTitle, Input, PageHeader } from '@/components';
import Navbar from '@/components/shared/Navbar';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { Result } from '@/components/shared/Result';
import { eStrings } from '@/constants/strings';

const PAGE_SIZE = 10;

const searchSchema = z.object({
  query: z.string().optional(),
  p: z.number().optional().default(1),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const bedrijfQueryOptions = (search: SearchParams) => {
  const filter: FilterSchema[] = [
    {
      field: 'Volgend',
      op: 'is_null',
      value: '',
    },
  ];

  if (search.query) {
    //Replace space(s) with single wildcard character
    const searchValue = `%${search.query.replace(/\s+/g, '%')}%`;

    filter.push({
      field: 'Naam',
      op: 'like',
      value: searchValue,
    });
  }

  return useBedrijf.getOptions({
    page: search.p,
    size: PAGE_SIZE,
    filter,
    sort: [
      {
        direction: 'asc',
        field: 'Naam',
      },
    ],
  });
};

/**
 * Route
 */
export const Route = createFileRoute('/')({
  validateSearch: searchSchema.parse,
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { queryClient }, deps }) => {
    try {
      const bedrijven = await queryClient.ensureQueryData(bedrijfQueryOptions(deps.search));

      if (!bedrijven.num_pages) return;

      if (bedrijven.num_pages < deps.search.p) {
        throw redirect({ to: '/', search: { p: bedrijven.num_pages } });
      }
    } catch {}
  },
  component: IndexComponent,
});

/**
 * Route component
 */
function IndexComponent() {
  const search = Route.useSearch();
  const navigate = useNavigate();
  const bedrijven = useQuery(bedrijfQueryOptions(search));

  function handleSubmit(e: SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    const { value: query } = e.currentTarget['search-for-company'] as HTMLInputElement;

    navigate({
      to: '/',
      search: {
        query: query.length > 0 ? query : undefined,
        p: 1,
      },
    });
  }

  return (
    <>
      <DocumentTitle sections={[eStrings.LBL_DIGI_V, 'Zoeken']} />

      <Navbar />

      <PageHeader
        title="Zoek een bedrijf"
        description="Zoek naar bedrijven binnen Digi-V® om de informatie en besluiten van het bedrijf in te zien"
      />

      <div className="relative mx-auto -mt-8 h-[60px] max-w-7xl sm:px-6 lg:px-8">
        <form className="relative" onSubmit={handleSubmit}>
          <Input
            inputClasses="h-[60px] pl-4 border-0 shadow-xxl text-base"
            type="search"
            id="search-for-company"
            name="search-for-company"
            autoComplete="off"
            defaultValue={search.query || ''}
            label="Zoek voor bedrijven"
            placeholder="Zoek op bedrijfsnaam of adres"
            hideLabel
          />

          <div className="absolute right-3 top-0 flex h-full items-center">
            <Button
              type="submit"
              variant="green"
              state={bedrijven.isFetching && search.query ? 'loading' : undefined}
            >
              Zoeken
            </Button>
          </div>
        </form>
      </div>

      <div className="mx-auto mt-12 max-w-7xl pb-24 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="inline-block min-w-full align-middle sm:rounded-lg">
              <h3 className="mb-2 text-lg font-medium leading-6 text-theme-blue">Bedrijven</h3>

              <ul>
                {!bedrijven.data?.objects?.length ? (
                  <li className="border-t pt-2">Er zijn geen resultaten</li>
                ) : (
                  <>
                    {bedrijven.data.objects.map((bedrijf) => {
                      const {
                        ID,
                        Naam,
                        Plaatsnaam,
                        Locatienummer,
                        Postcode,
                        Straatnaam,
                        Huisnummer,
                      } = bedrijf;

                      if (!ID) return null;

                      return (
                        <Result
                          key={bedrijf.ID}
                          title={Naam || ''}
                          description={`${Straatnaam} ${Huisnummer}, ${Postcode} ${Plaatsnaam}`}
                          number={Locatienummer}
                          to="/$bedrijfLineageId/bedrijf/overzicht"
                          params={{
                            bedrijfLineageId: bedrijf.Lineage,
                          }}
                        />
                      );
                    })}
                    {(bedrijven.data?.num_pages ?? 0) > 1 ? (
                      <div className="mt-6 flex justify-center">
                        <PaginationLinks
                          renderLink={({ children, page }) => (
                            <Link to="/" search={{ p: page, query: search.query }}>
                              {children}
                            </Link>
                          )}
                          currentPage={search.p}
                          totalPages={bedrijven.data?.num_pages ?? 0}
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
