import { ReactNode } from 'react';

import { LabelMapSchema } from '@/api';
import { useLabel, useLabelMap } from '@/api/queries/label';
import { Explorer, OptionsMenuOption } from '@/components';
import { useOpenModal } from '@/modals/utils';

interface ExplorerButtonLabelMapProps {
  asChild?: boolean;
  children: ReactNode;
  map: LabelMapSchema;
}

export const ExplorerButtonLabelMap = ({ asChild, children, map }: ExplorerButtonLabelMapProps) => {
  const openModal = useOpenModal();

  const containsLabel = useLabel(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'Label',
          field: 'Map',
          value: map.ID!,
          op: 'eq',
        },
      ],
    },
    {
      select: (labels) => !!labels.objects?.length,
    }
  );

  const containsMap = useLabelMap(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'LabelMap',
          field: 'Map',
          value: map.ID!,
          op: 'eq',
        },
      ],
    },
    {
      select: (maps) => !!maps.objects?.length,
    }
  );

  const isEmpty = !containsLabel.data && !containsMap.data;

  const options: OptionsMenuOption[] = [
    {
      title: 'Wijzigen',
      variant: 'button',
      onClick: () => {
        openModal('beheerLabelsMap', {
          updateMap: map,
        });
      },
    },
  ];

  if (isEmpty) {
    options.push({
      title: 'Verwijderen',
      variant: 'button',
      onClick: () => {
        openModal('beheerLabelMapDelete', {
          map,
        });
      },
    });
  }

  return (
    <Explorer.Button asChild={asChild} icon="folder" options={options}>
      {children}
    </Explorer.Button>
  );
};
