import { MutationKey, useQueryClient } from '@tanstack/react-query';

import { useDeleteDynMetaVoorschriftHistorischBesluitId } from '@/api/generated/digiVAPI';
import { ErrorType } from '@/services/axios';

import { useHistorischBesluit, useHistorischBesluitLink } from '../historischBesluit';

export function useDeleteHistorischBesluitLink({
  mutationKey,
  onSuccess,
  onError,
}: {
  mutationKey: MutationKey;
  onSuccess?: () => void;
  onError?: (
    error: ErrorType<string>,
    variables: NonNullable<
      ReturnType<typeof useDeleteDynMetaVoorschriftHistorischBesluitId>['variables']
    >
  ) => void;
}) {
  const queryClient = useQueryClient();

  return useDeleteDynMetaVoorschriftHistorischBesluitId({
    mutation: {
      mutationKey,
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: useHistorischBesluitLink.key }),
          queryClient.invalidateQueries({ queryKey: useHistorischBesluit.key }),
        ]);

        onSuccess?.();
      },
      onError,
    },
  });
}
