import { useQuery } from '@tanstack/react-query';
import { Link, createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { useBesluit } from '@/api/queries/besluit';
import { HeadingLarge } from '@/components';
import { PaginationLinks } from '@/components/shared/PaginationLinks';
import { eStrings } from '@/constants/strings';

import { BesluitCardVigerend } from './-components/BesluitCardVigerend';

const searchSchema = z.object({
  p: z.number().optional().default(1),
});

type SearchParams = z.infer<typeof searchSchema>;

/**
 * Query options
 */
const vigerendQueryOptions = (search: SearchParams, lineageId?: string) => {
  return useBesluit.getOptions(
    {
      page: search.p,
      size: 10,
      filter: [
        {
          model: 'Besluit',
          field: 'Status',
          op: 'eq',
          value: 'Publicatie',
        },
        {
          model: 'Bedrijf',
          field: 'Lineage',
          op: 'eq',
          value: lineageId ?? '',
        },
      ],
      sort: [
        {
          model: 'Besluit',
          field: 'Besluit_Datum',
          direction: 'desc',
        },
      ],
    },
    {
      enabled: !!lineageId,
    }
  );
};

const latestBedrijfQueryOptions = (lineageId: string) => useLatestBedrijf.getOptions(lineageId);

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/bedrijf/_layout/besluiten')({
  validateSearch: searchSchema.parse,
  beforeLoad: () => ({
    getTitle: () => 'Besluiten en wijzigingen',
  }),
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ params, context: { queryClient }, deps }) => {
    const bedrijf = await queryClient.ensureQueryData(
      latestBedrijfQueryOptions(params.bedrijfLineageId)
    );
    const vigerendBesluiten = await queryClient.ensureQueryData(
      vigerendQueryOptions(deps.search, bedrijf.objects?.[0]?.Lineage)
    );

    if (!vigerendBesluiten.num_pages) return;

    if (vigerendBesluiten.num_pages < deps.search.p) {
      throw redirect({
        to: '/$bedrijfLineageId/bedrijf/besluiten',
        search: { p: vigerendBesluiten.num_pages },
        params,
      });
    }
  },
  component: BesluitenComponent,
});

/**
 * Route Component
 */
function BesluitenComponent() {
  const { bedrijfLineageId } = Route.useParams();
  const search = Route.useSearch();

  const bedrijf = useQuery(latestBedrijfQueryOptions(bedrijfLineageId));
  const vigerendBesluiten = useQuery(vigerendQueryOptions(search, bedrijfLineageId));

  if (!bedrijf.data) return null;

  return (
    <section className="pb-16" data-testid="bedrijfdasboard-tab-besluiten">
      {vigerendBesluiten.data?.objects && vigerendBesluiten.data.objects.length > 0 ? (
        <>
          <HeadingLarge className="mb-4" as="h2" value={eStrings.LBL_BESLUITEN_EN_WIJZIGINGEN} />

          <ul className="flex flex-col gap-4">
            {vigerendBesluiten.data.objects.map((besluit) => {
              return (
                <li key={besluit.ID}>
                  <BesluitCardVigerend headingAs="h3" bedrijf={bedrijf.data!} besluit={besluit} />
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <div className="my-6">
          <span className="italic text-gray-500">{eStrings.TXT_GEEN_BESLUITEN_EN_WIJZIGINGEN}</span>
        </div>
      )}

      {(vigerendBesluiten.data?.num_pages ?? 0) > 1 ? (
        <div className="mt-6 flex justify-center">
          <PaginationLinks
            renderLink={({ children, page }) => (
              <Link
                to="/$bedrijfLineageId/bedrijf/besluiten"
                params={{ bedrijfLineageId }}
                search={{ p: page }}
              >
                {children}
              </Link>
            )}
            currentPage={search.p}
            totalPages={vigerendBesluiten.data?.num_pages ?? 0}
          />
        </div>
      ) : null}
    </section>
  );
}
