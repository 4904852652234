import { LinkVoorschrift, useDeleteVoorschriftId } from '@/api/queries/objects';
import { Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface DeleteVoorschriftProps {
  voorschriftLink: LinkVoorschrift;
  nummer: string;
  onDeleted?: () => void;
}

export const DELETE_VOORSCHRIFT_ID = 'deleteVoorschrift';

export const DeleteVoorschrift = modal(DELETE_VOORSCHRIFT_ID, ({ data, props }) => {
  const { voorschriftLink, nummer } = data;

  const deleteVoorschriftId = useDeleteVoorschriftId();

  const modalState = useModalState();

  return (
    <Modal {...props} state={modalState.state} title={`Verwijderen voorschrift ${nummer}`}>
      <p>Je staat op het punt om voorschrift {nummer} te verwijderen.</p>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="delete"
          successLabel="Verwijderd"
          variant="red"
          onClick={() => {
            modalState.action('delete');

            deleteVoorschriftId.mutate(
              {
                id: voorschriftLink.VSPB.Voorschrift,
              },
              {
                onSuccess: () => modalState.success('delete'),
                onError: (error) => {
                  const errorMessage = ErrorMessage.getLocalErrorMessage(error);

                  if (errorMessage) {
                    modalState.error('delete', {
                      title: 'Mislukt!',
                      description: errorMessage,
                    });
                  }
                },
              }
            );
          }}
        >
          Verwijderen
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
