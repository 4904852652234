import { HTMLAttributes, ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { SolidCircleInfo, SolidTriangleExclamation } from '@/icons/components';
import { IconComponent } from '@/icons/iconTypes';

export interface WarningProps {
  title: string;
  className?: string;
  attributes?: HTMLAttributes<HTMLDivElement>;
  children?: ReactNode;
  description?: string;
  variant?: 'warning' | 'danger' | 'success' | 'info';
  size?: 'small' | 'default';
  hideIcon?: boolean;
}

export function Warning({
  title,
  children,
  className = '',
  variant = 'warning',
  size = 'default',
  description,
  hideIcon,
  attributes = {},
}: WarningProps) {
  let borderColor = '';
  let textColor = '';
  let backgroundColor = '';
  let iconColor = '';
  let Icon: IconComponent | undefined;

  switch (variant) {
    case 'danger':
      borderColor = 'border-digi-v-color-danger';
      textColor = 'text-digi-v-color-danger';
      backgroundColor = 'bg-digi-v-color-danger/6';
      Icon = SolidTriangleExclamation;
      break;
    case 'warning':
      borderColor = 'border-black/10';
      textColor = 'text-black';
      backgroundColor = 'bg-digi-v-color-warning/10';
      iconColor = 'text-digi-v-color-warning';
      Icon = SolidTriangleExclamation;
      break;
    case 'success':
      borderColor = 'border-digi-v-color-success';
      textColor = 'text-digi-v-color-success';
      backgroundColor = 'bg-digi-v-color-success/6';
      Icon = SolidCircleInfo;
      break;
    case 'info':
      borderColor = 'border-theme-blue';
      textColor = 'text-theme-blue';
      backgroundColor = 'bg-theme-blue/6';
      Icon = SolidCircleInfo;
      break;
  }

  return (
    <div
      className={twMerge(
        twJoin(
          'flex rounded-md border ',
          size === 'default' && 'px-5 py-4',
          size === 'small' && 'px-4 py-2',
          borderColor,
          textColor,
          backgroundColor
        ),
        className
      )}
      {...attributes}
    >
      {!hideIcon && Icon && (
        <Icon
          size={size === 'default' ? 24 : 16}
          className={twJoin(
            size === 'default' && 'mr-5 mt-1',
            size === 'small' && 'mr-2',
            iconColor
          )}
        />
      )}

      <div>
        <h2
          className={twJoin(size === 'default' && 'mb-1 font-bold', size === 'small' && 'text-sm')}
        >
          {title}
        </h2>
        <div className={twJoin(size === 'small' && 'text-sm')}>
          {description && <p className="pt-1">{description}</p>}
          {children}
        </div>
      </div>
    </div>
  );
}
