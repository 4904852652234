import { useQueryClient } from '@tanstack/react-query';

import { usePatchDynStdParagraafId } from '@/api/generated/digiVAPI';

import { useStdObjects } from '../stdObjects';

export function usePatchStdParagraafId({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return usePatchDynStdParagraafId({
    mutation: {
      mutationKey,
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: useStdObjects.key });
      },
    },
  });
}
