import { ReactNode } from 'react';

export interface DescriptionListItem {
  label: string;
  value: string | ReactNode;
}

export interface DescriptionListProps {
  items?: DescriptionListItem[];
}

export function DescriptionList({ items = [] }: DescriptionListProps) {
  return (
    <div className="mt-5 border-gray-200">
      <dl data-testid="description-list-testId">
        {items.map((item) => {
          return (
            <div
              className="mt-8 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
              key={item.label}
            >
              <dt className="text-sm font-medium leading-5 text-gray-500">{item.label}</dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:col-span-2 sm:mt-0">
                {item.value}
              </dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
}
