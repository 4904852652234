import { Link, createFileRoute } from '@tanstack/react-router';
import { isBefore } from 'date-fns';
import { z } from 'zod';

import { HoofdstukSchema } from '@/api';
import { useLatestBedrijf } from '@/api/queries/bedrijf';
import { Links } from '@/api/queries/objects';
import { DocumentTitle, TextButton, TreeNav, TreeNavItem, Warning } from '@/components';
import Navbar from '@/components/shared/Navbar';
import { Error404 } from '@/components/templates/Error404.tsx';
import { eStrings } from '@/constants/strings';
import { DateUtils } from '@/utils/dateUtils';

import { Body } from '../-components/Body';
import { ChapterNavigation } from '../-components/ChapterNavigation';
import { Objects } from '../-components/Objects/Objects';
import { VigerendHeader } from './-components/VigerendHeader';
import { useVigerendData } from './-utils/useVigerendData';

/**
 * Search schema
 */
const searchSchema = z.object({
  datum: z.string().optional(),
  hoofdstuk: z.string().optional(),
});

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/viewer/vigerend/')({
  validateSearch: searchSchema.parse,
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ params, context: { queryClient } }) => {
    return queryClient.ensureQueryData(useLatestBedrijf.getOptions(params.bedrijfLineageId));
  },
  component: VigerendComponent,
});

/**
 * Route component
 */
function VigerendComponent() {
  const { bedrijfLineageId } = Route.useParams();
  const { datum, hoofdstuk: hoofdstukId } = Route.useSearch();
  const datumObject = datum ? new Date(datum) : new Date();

  const bedrijf = useLatestBedrijf(bedrijfLineageId);

  const hoofdstukken = useVigerendData({
    date: DateUtils.formatForInput(datumObject),
    bedrijfLineageId,
  });

  /**
   * Get current, next and previous hoofdstuk
   */
  let currentHoofdstuk:
    | undefined
    | {
        hoofdstuk: HoofdstukSchema;
        links: Links;
      };
  let nextHoofdstuk:
    | undefined
    | {
        hoofdstuk: HoofdstukSchema;
        links: Links;
      };
  let previousHoofdstuk:
    | undefined
    | {
        hoofdstuk: HoofdstukSchema;
        links: Links;
      };

  if (hoofdstukken.data?.length) {
    currentHoofdstuk = hoofdstukId
      ? hoofdstukken.data.find(({ hoofdstuk }) => hoofdstuk.ID === hoofdstukId)
      : hoofdstukken.data[0];

    if (hoofdstukId && !currentHoofdstuk) {
      return <Error404 />;
    }

    if (currentHoofdstuk) {
      nextHoofdstuk = hoofdstukken.data[hoofdstukken.data.indexOf(currentHoofdstuk) + 1];
      previousHoofdstuk = hoofdstukken.data[hoofdstukken.data.indexOf(currentHoofdstuk) - 1];
    }
  }

  if (!bedrijf.data) return null;

  return (
    <>
      <DocumentTitle
        sections={[eStrings.LBL_DIGI_V, bedrijf.data.Naam ?? '', 'Vigerende situatie']}
      />

      <Navbar />

      <VigerendHeader bedrijf={bedrijf.data} defaultDate={datumObject} />

      <Body>
        <Body.Sidebar>
          <h2
            className="mb-2 block text-sm font-medium leading-5 text-theme-blue"
            id="inhoudsopgave-title"
          >
            Inhoudsopgave
          </h2>

          {hoofdstukken.data?.length ? (
            <TreeNav aria-labelledby="inhoudsopgave-title">
              {hoofdstukken.data.map(({ hoofdstuk }) => {
                return (
                  <TreeNavItem
                    role="menuitem"
                    asChild
                    key={`vigerend-hoofdstuk-${hoofdstuk.ID}`}
                    active={hoofdstuk.ID === currentHoofdstuk?.hoofdstuk.ID}
                  >
                    <Link
                      to="/$bedrijfLineageId/viewer/vigerend"
                      params={{
                        bedrijfLineageId,
                      }}
                      search={{
                        hoofdstuk: hoofdstuk.ID!,
                        datum,
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<span>${hoofdstuk.Nummer}</span> ${hoofdstuk.Titel}`,
                        }}
                      />
                    </Link>
                  </TreeNavItem>
                );
              })}
            </TreeNav>
          ) : null}
        </Body.Sidebar>

        <Body.Content>
          <h2 className="mb-4 border-b border-gray-200 pb-6 text-lg font-bold">
            Vigerende situatie op {DateUtils.formatReadable(datumObject)}
          </h2>

          {isBefore(datumObject, new Date().setHours(0, 0, 0, 0)) ? (
            <Warning title="Vigerende situatie" variant="info" className="mb-2">
              Het getoonde overzicht is van {DateUtils.formatReadable(datumObject)}, ga naar{' '}
              <TextButton asChild>
                <Link
                  to={Route.fullPath}
                  from={Route.fullPath}
                  search={{ datum: DateUtils.formatForInput(new Date()) }}
                >
                  vandaag
                </Link>
              </TextButton>{' '}
              om de actuele vigerende situatie te bekijken.
            </Warning>
          ) : null}

          {currentHoofdstuk ? (
            <Objects
              bedrijf={bedrijf.data}
              hoofdstuk={currentHoofdstuk.hoofdstuk}
              links={currentHoofdstuk.links}
              mode="vigerend"
            />
          ) : null}

          <ChapterNavigation
            previous={
              previousHoofdstuk?.hoofdstuk.ID && (
                <ChapterNavigation.Previous
                  nummer={previousHoofdstuk.hoofdstuk.Nummer}
                  htmlTitle={previousHoofdstuk.hoofdstuk.Titel}
                  to="/$bedrijfLineageId/viewer/vigerend"
                  params={{
                    bedrijfLineageId,
                  }}
                  search={{
                    hoofdstuk: previousHoofdstuk.hoofdstuk.ID!,
                    datum,
                  }}
                />
              )
            }
            next={
              nextHoofdstuk?.hoofdstuk.ID && (
                <ChapterNavigation.Next
                  nummer={nextHoofdstuk.hoofdstuk.Nummer}
                  htmlTitle={nextHoofdstuk.hoofdstuk.Titel}
                  to="/$bedrijfLineageId/viewer/vigerend"
                  params={{
                    bedrijfLineageId,
                  }}
                  search={{
                    hoofdstuk: nextHoofdstuk.hoofdstuk.ID!,
                    datum,
                  }}
                />
              )
            }
          />
        </Body.Content>
      </Body>
    </>
  );
}
