export const bevoegdGezag = [
  'Provincie Drenthe',
  'Provincie Flevoland',
  'Provincie Friesland',
  'Provincie Gelderland',
  'Provincie Groningen',
  'Provincie Limburg',
  'Provincie Noord-Brabant',
  'Provincie Noord-Holland',
  'Provincie Overijssel',
  'Provincie Utrecht',
  'Provincie Zeeland',
  'Provincie Zuid-Holland',
] as const;
