import { HoofdstukReserveringSchema } from '@/api';
import { useGebruiker } from '@/api/queries/gebruiker/useGebruiker';
import { useHoofdstukReservering } from '@/api/queries/hoofdstukReservering';
import { useHoofdstuk } from '@/api/queries/objects';
import { useUserStore } from '@/stores/user';
import { createInString } from '@/utils/createInString';

import { ValidateBesluitProps } from '../ValidateBesluit.types';
import { ValidateAccordionSkeleton } from './ValidateAccordionSkeleton';
import { ValidateRow } from './ValidateRow';

export const useValidateBesluitReservering = (besluitId: string) => {
  /**
   * Check if there are hoofdstukken reserved by other users
   */
  const userId = useUserStore(({ user }) => user?.ID);
  const hasLockedChapter = useHoofdstukReservering(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'Besluit',
          field: 'ID',
          op: 'eq',
          value: besluitId,
        },
        {
          model: 'Gebruiker',
          field: 'ID',
          op: 'ne',
          value: userId ?? '',
        },
      ],
    },
    {
      enabled: !!besluitId && !!userId,
      select: ({ objects = [] }) => ({ isValid: objects.length === 0, objects }),
    }
  );

  return hasLockedChapter;
};

export const ValidateReservering = ({ validate }: ValidateBesluitProps) => {
  const { isFetching, data } = validate.queries.validateBesluitReservering;

  if (isFetching || !data) {
    return <ValidateRow isFetching title="Reserveringen nalopen" />;
  }

  if (data.isValid) {
    return <ValidateRow isValid title="Alle hoofdstukken zijn beschikbaar" />;
  }

  return (
    <ValidateRow
      isValid={false}
      accordionBody={<ErrorBody reserveringen={data.objects} besluitId={validate.besluitId} />}
      title="Niet alle hoofdstukken zijn te reserveren"
    />
  );
};

const ErrorBody = ({
  reserveringen,
  besluitId,
}: {
  reserveringen: HoofdstukReserveringSchema[];
  besluitId: string;
}) => {
  const hoofdstukken = useHoofdstuk({
    page: 1,
    size: 100,
    filter: [
      {
        model: 'Besluit',
        field: 'ID',
        op: 'eq',
        value: besluitId,
      },
      {
        model: 'Hoofdstuk',
        field: 'Lineage',
        op: 'in',
        value: createInString(reserveringen.map((res) => res.Reservering_lineage)),
      },
      {
        model: 'Hoofdstuk',
        field: 'Volgend',
        op: 'is_null',
        value: '',
      },
    ],
    sort: [
      {
        model: 'Hoofdstuk',
        field: 'Nummer',
        direction: 'asc',
      },
    ],
  });

  const gebruikers = useGebruiker.get();

  if (hoofdstukken.isLoading || gebruikers.isLoading) return <ValidateAccordionSkeleton />;

  return (
    <ul>
      {hoofdstukken.data?.objects?.map((hoofdstuk) => {
        const reservering = reserveringen.find(
          ({ Reservering_lineage }) => Reservering_lineage === hoofdstuk.Lineage
        );
        const gebruiker = gebruikers.data?.find(({ ID }) => ID === reservering?.Gebruiker);

        return (
          <li key={hoofdstuk.ID} className="list-inside list-disc">
            Hoofdstuk {hoofdstuk.Nummer} is gereserveerd door {gebruiker?.Gebruikersnaam}
          </li>
        );
      })}
    </ul>
  );
};
