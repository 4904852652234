import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postFixLinkVspb, postFixVoorschrift } from '@/api/generated/digiVAPI';
import { VSPBSchema, VoorschriftSchema } from '@/api/generated/digiVAPI.schemas';
import { QueryError } from '@/utils/createQuery';
import { pick } from '@/utils/pick';

import { useLinksInHoofdstuk, useVSPB } from '../links';
import { LinkVoorschrift } from '../utils/getLinksInHoofdstuk';
import { useVoorschrift, useVoorschriftId } from '../voorschrift';

/**
 * This mutation can be used to start editing a published voorschrift.
 *
 * When we want to edit a published voorschrift, we first need to create a new voorschrift in the lineage.
 */
export const useCreateVoorschriftInLineage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { VSPB: VSPBSchema; voorschrift: VoorschriftSchema },
    QueryError,
    { voorschrift: VoorschriftSchema; link: LinkVoorschrift }
  >({
    mutationFn: async ({ voorschrift, link }) => {
      const newVoorschrift = await postFixVoorschrift({
        ...pick(voorschrift, [
          'Grondslag',
          'Lineage',
          'Beroep_Ingediend',
          'Nummer',
          'Stop_Lineage',
          'Voorlopige_Voorziening',
          'Voorschrift',
        ]),
        Ontwerp: 1,
        Vorig: voorschrift.ID,
        Index: voorschrift.Index + 1,
      });

      const VSPB = await postFixLinkVspb({
        ...pick(link.VSPB, ['Besluit', 'Rang', 'Subparagraaf']),
        Voorschrift: newVoorschrift.ID!,
        Type: 'Eigen',
      });

      return {
        VSPB,
        voorschrift: newVoorschrift,
      };
    },
    onSuccess: async ({ voorschrift }) => {
      queryClient.setQueryData<VoorschriftSchema>(
        useVoorschriftId.getKey(voorschrift.ID!),
        voorschrift
      );

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: useVoorschrift.key }),
        queryClient.invalidateQueries({ queryKey: useVSPB.key }),
        queryClient.invalidateQueries({ queryKey: useLinksInHoofdstuk.key }),
      ]);
    },
  });
};
