/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ButtonHTMLAttributes, ReactNode, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { IconButton } from '@/components';
import { RegularXmark } from '@/icons/components';

type EditorMetaProps = {
  children: ReactNode;
};

type EditorMetaButtonProps = {
  children: ({
    isOpen,
    props,
  }: {
    isOpen: boolean;
    props: Pick<
      ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'aria-expanded' | 'aria-controls' | 'id'
    >;
  }) => ReactNode;
};

type EditorMetaRowProps = {
  children: ReactNode;
};

type EditorMetaPanelProps = {
  children: ReactNode;
  closeAriaLabel: string;
};

type EditorMetaContext = [boolean, React.Dispatch<React.SetStateAction<boolean>>, string];

const EditorMetaContext = React.createContext<EditorMetaContext>([false, () => {}, '']);
const useEditorMetaContext = () => {
  const context = React.useContext(EditorMetaContext);

  if (!context) {
    throw new Error('EditorMetaContext is not available, wrap EditorMeta.Component in EditorMeta');
  }

  return context;
};

const panelId = (id: string) => `editor-meta-panel-${id}`;
const buttonId = (id: string) => `editor-meta-button-${id}`;

export const EditorMeta = ({ children }: EditorMetaProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const id = useRef(uuidv4()).current;

  const contextValue = useMemo<EditorMetaContext>(() => [isOpen, setIsOpen, id], [isOpen, id]);

  return <EditorMetaContext.Provider value={contextValue}>{children}</EditorMetaContext.Provider>;
};

EditorMeta.Button = function Button({ children }: EditorMetaButtonProps) {
  const [isOpen, setIsOpen, id] = useEditorMetaContext();

  return (
    <>
      {children({
        isOpen: isOpen,
        props: {
          onClick: () => setIsOpen(!isOpen),
          'aria-expanded': isOpen,
          'aria-controls': panelId(id),
          id: buttonId(id),
        },
      })}
    </>
  );
};

EditorMeta.Panel = function Panel({ children, closeAriaLabel }: EditorMetaPanelProps) {
  const [isOpen, setIsOpen, id] = useEditorMetaContext();

  if (!isOpen) return null;

  return (
    // Disable eslint because we need to use onKeyDown to close the panel when pressing escape
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <section
      id={panelId(id)}
      aria-labelledby={buttonId(id)}
      className="relative bg-theme-blue-light py-6 pl-[100px] pr-6"
      data-testid="voorschrift-meta-data"
      onKeyDown={(event) => {
        if (event.key === 'Escape' || event.key === 'Esc') {
          setIsOpen(false);
        }
      }}
    >
      <IconButton
        icon={RegularXmark}
        ariaLabel={closeAriaLabel}
        onClick={() => setIsOpen(false)}
        className="absolute right-4 top-4"
      />

      {children}
    </section>
  );
};

EditorMeta.Row = function Row({ children }: EditorMetaRowProps) {
  return <div>{children}</div>;
};
