import { getDynStdMap, getDynStdMapId } from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';
import { createInfiniteQuery } from '@/utils/createQuery/createInifiniteQuery';

export const useStdMap = createQuery({
  key: ['stdMap'],
  queryFn: getDynStdMap,
});

export const useStdMapInfinite = createInfiniteQuery({
  key: ['stdMap', 'infinite'],
  queryFn: getDynStdMap,
});

export const useStdMapId = createQuery({
  key: ['stdMapId'],
  queryFn: getDynStdMapId,
});
