import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { STDSubparagraafSchema } from '@/api';
import { usePatchStdSubparagraafId } from '@/api/queries/std/mutations/usePatchStdSubparagraafId';
import { Button, FormLabel, Modal, TextEditor, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { useOpenModal } from '@/modals/utils';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface StandaardtekstenEditSubparagraafProps {
  subparagraaf: STDSubparagraafSchema;
}

type FormValues = Pick<STDSubparagraafSchema, 'Titel' | 'Toelichting'>;

export const STANDAARDTEKSTEN_EDIT_SUBPARAGRAAF_ID = 'standaardtekstenEditSubparagraafId';

export const StandaardtekstenEditSubparagraaf = modal(
  STANDAARDTEKSTEN_EDIT_SUBPARAGRAAF_ID,
  ({ data, props }) => {
    const modalState = useModalState();
    const openModal = useOpenModal();

    const {
      subparagraaf: { ID, Titel, Toelichting },
      subparagraaf,
    } = data;

    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<FormValues>({
      defaultValues: {
        Titel,
        Toelichting,
      },
    });

    const patchStdSubparagraaf = usePatchStdSubparagraafId();

    const onSubmit: SubmitHandler<FormValues> = (formData) => {
      const userId = useUserStore.getState().user?.ID ?? '';

      modalState.action('save');
      patchStdSubparagraaf.mutate(
        {
          data: {
            ...formData,
            Modified_By: userId,
            Modified_Date: new Date().toISOString(),
          },
          id: ID!,
        },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) => {
            modalState.error('save', {
              title: 'Mislukt',
              description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets mis gegaan',
            });
          },
        }
      );
    };

    return (
      <Modal {...props} state={modalState.state} title="Subparagraaf bewerken">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel htmlFor="bewerken-subparagraaf-toelichting">Toelichting</FormLabel>
          <Controller
            control={control}
            name="Toelichting"
            render={({ field: { onChange, value: Toelichting } }) => (
              <TextEditor
                menuOptions={[
                  'subscript',
                  'superscript',
                  'italic',
                  'underline',
                  'bulletList',
                  'orderedList',
                  'abcList',
                ]}
                initialText={Toelichting}
                id="bewerken-subparagraaf-toelichting"
                testId="edit-object-subparagraaf-toelichting"
                variant="input"
                minHeight={88}
                onUpdate={onChange}
              />
            )}
          />
          <FormLabel htmlFor="bewerken-std-subparagraaf-title" required>
            Titel (titel van de subparagraaf)
          </FormLabel>
          <Controller
            control={control}
            name="Titel"
            rules={{ required: { value: true, message: eStrings.TXT_VERPLICHT_INPUT_VELD } }}
            render={({ field: { onChange, value: Titel } }) => (
              <TextEditor
                menuOptions={['superscript', 'subscript', 'highlight']}
                error={errors.Titel}
                initialText={Titel}
                id="bewerken-std-subparagraaf-title"
                testId="edit-object-std-subparagraaf-title"
                variant="input"
                singleLine
                onUpdate={onChange}
              />
            )}
          />
          <Modal.Footer>
            <div className="flex w-full justify-between">
              <Button
                variant="white"
                disabled={modalState.state?.type === 'action'}
                onClick={() => {
                  openModal('standaardtekstenVerwijderenSubparagraaf', { subparagraaf });

                  props.onClose();
                }}
              >
                Subparagraaf verwijderen
              </Button>
              <div>
                <Modal.CancelButton />

                <Modal.ActionButton action="save" successLabel="Opgeslagen" type="submit">
                  Opslaan
                </Modal.ActionButton>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
);
