import { FC } from 'react';

import { ModalIds, ModalProps } from '../config';
import { useModal } from './modalStore';

export const modal =
  <Id extends ModalIds>(
    id: Id,
    ModalComponent: FC<{
      data: ModalProps[Id];
      props: { open: boolean; onClose: () => void; afterLeave: () => void };
    }>
  ) =>
  ({ uuid }: { uuid: string }) => {
    const modal = useModal(id, uuid);

    if (!modal) return null;

    return <ModalComponent data={modal.data} props={modal.props} />;
  };
