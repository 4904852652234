import { useQuery } from '@tanstack/react-query';

import { useBesluitType } from '../besluitType';

const besluitTypeIdOptions = (id?: string | null) => {
  return useBesluitType.getOptions(
    {},
    {
      enabled: !!id,
      select: (besluitTypes) => {
        return besluitTypes.find((besluitType) => besluitType.ID === id);
      },
    }
  );
};

const useBesluitTypeId = (id?: string | null) => useQuery(besluitTypeIdOptions(id));
useBesluitTypeId.getOptions = besluitTypeIdOptions;

export { useBesluitTypeId };
