import { MutationKey, useQueryClient } from '@tanstack/react-query';

import { usePatchFixHoofdstukId } from '@/api/generated/digiVAPI';

import { useHoofdstuk, useHoofdstukId } from '../hoofdstuk';

export function usePatchHoofdstukId({ mutationKey }: { mutationKey?: MutationKey }) {
  const queryClient = useQueryClient();

  return usePatchFixHoofdstukId({
    mutation: {
      mutationKey,
      onSuccess: (hoofdstuk) => {
        queryClient.setQueryData(useHoofdstukId.getKey(hoofdstuk.ID!), hoofdstuk);

        return queryClient.invalidateQueries({ queryKey: useHoofdstuk.key });
      },
    },
  });
}
