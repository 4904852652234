import { useEffect, useRef, useState } from 'react';

type ImageResizerStatus = 'idle' | 'resizing' | 'error';

export const useResizeImage = (settings: {
  maxWidth: number;
  maxHeight: number;
  quality: number;
}): {
  resize: (image: File) => Promise<string | undefined>;
  status: ImageResizerStatus;
} => {
  const [status, setStatus] = useState<ImageResizerStatus>('idle');

  const abortController = useRef<AbortController>(new AbortController());
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imageFileResizer = useRef<any>();

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      abortController.current.abort();
    };
  }, []);

  const resizeImage = async (image: File) => {
    // Lazyload image resize package for improved performance
    if (!imageFileResizer.current) {
      imageFileResizer.current = (
        await import('react-image-file-resizer')
      ).default.imageFileResizer;
    }

    return new Promise<string>((resolve, reject) => {
      imageFileResizer.current(
        image,
        settings.maxWidth,
        settings.maxHeight,
        'JPEG',
        settings.quality,
        0,
        (uri: string) => {
          resolve(uri);
        },
        'base64'
      );

      // Reject promise when the abortController is aborted
      abortController.current.signal.addEventListener('abort', () => {
        reject('aborted');
      });
    });
  };

  return {
    status,
    resize: async (image) => {
      setStatus('resizing');

      try {
        const resizedBase64 = await resizeImage(image);

        setStatus('idle');

        return resizedBase64;
      } catch (error) {
        if (error !== 'aborted') {
          setStatus('error');
        } else {
          console.log('aborted');
        }
      }
    },
  };
};
