import { ReactNode } from 'react';

import { Button, ButtonProps, HeadingLarge } from '@/components';

import { ExplorerButton } from './components/ExplorerButton';
import { ExplorerItemHeading } from './components/ExplorerItemHeading';

export type ExplorerProps = {
  children: ReactNode;
  className?: string;
  title: string | ReactNode;
  buttonProps?: ButtonProps;
  showButton?: boolean;
};

export const Explorer = ({
  children,
  title,
  buttonProps,
  showButton,
  className,
}: ExplorerProps) => (
  <div className={className}>
    <div className="mb-4 flex justify-between">
      {title && <HeadingLarge as="h3" className="text-2xl font-bold" value={title} />}
      {showButton && buttonProps && <Button {...buttonProps} />}
    </div>

    <div>{children}</div>
  </div>
);

Explorer.Placeholder = function ExplorerPlaceholder({ children }: { children: ReactNode }) {
  return <p className="mt-4 italic text-gray-600">{children}</p>;
};

Explorer.ItemHeading = ExplorerItemHeading;
Explorer.Button = ExplorerButton;
