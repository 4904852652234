import { ReactNode } from 'react';

export const MetaList = ({ children }: { children: ReactNode }) => {
  return <dl className="flex flex-col gap-2">{children}</dl>;
};

MetaList.Row = function MetaListRow({ label, children }: { label: string; children: ReactNode }) {
  return (
    <div className="pr-5 text-gray-800 xl:flex">
      <dt className="font-semibold xl:w-1/4 xl:font-normal">{label}</dt>
      <dd className="xl:w-3/4">{children}</dd>
    </div>
  );
};
