import { useState } from 'react';

import { BesluitSchema } from '@/api';
import { useBedrijfId } from '@/api/queries/bedrijf';
import { useBesluitTypeId } from '@/api/queries/besluitType';
import { Button, Checkbox, Modal, useModalState } from '@/components';
import { ValidateBesluit, useValidateBesluit } from '@/components/shared/ValidateBesluit';
import { queryClient } from '@/services/queryClient';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../../utils';
import { docExport } from './utils/docExport';

export interface BesluitExporterenProps {
  besluit: BesluitSchema;
  bedrijfLineageId: string;
}

export const BESLUIT_EXPORTEREN_ID = 'besluitExporteren';

export const BesluitExporteren = modal(BESLUIT_EXPORTEREN_ID, ({ data, props }) => {
  const modalState = useModalState();
  const [isValidated, setIsValidated] = useState(false);

  return (
    <Modal {...props} state={modalState.state} title="Document exporteren">
      {!isValidated ? (
        <ValidationBody {...data} onContinue={() => setIsValidated(true)} />
      ) : (
        <ExportBody {...data} modalState={modalState} />
      )}
    </Modal>
  );
});

const ValidationBody = ({
  besluit,
  bedrijfLineageId,
  onContinue,
}: BesluitExporterenProps & { onContinue: () => void }) => {
  const validateBesluit = useValidateBesluit({ besluitId: besluit.ID ?? '', bedrijfLineageId });

  return (
    <>
      <ValidateBesluit validate={validateBesluit} />

      <Modal.Footer>
        <Modal.CancelButton />

        <Button disabled={validateBesluit.isFetching} onClick={onContinue} className="shrink-0">
          Doorgaan naar exporteren
        </Button>
      </Modal.Footer>
    </>
  );
};

const ExportBody = ({
  besluit,
  modalState,
}: BesluitExporterenProps & { modalState: ReturnType<typeof useModalState> }) => {
  const besluitType = useBesluitTypeId(besluit.Type);

  const [includeHistorisch, setIncludeHistorisch] = useState(false);

  const exporteren = async () => {
    modalState.action('other');

    try {
      const { Packer } = await import('docx');
      const { saveAs } = (await import('file-saver')).default;

      const bedrijf = await queryClient.fetchQuery(useBedrijfId.getOptions(besluit.Bedrijf));

      const doc = docExport({
        bedrijf,
        besluit,
        besluitType: besluitType.data,
        includeHistorisch,
      });

      const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

      Packer.toBlob(await doc).then((blob) => {
        const docblob = blob.slice(0, blob.size, mimeType);
        saveAs(
          docblob,
          `${besluitType.data?.Naam} ${besluit.Ons_Kenmerk ? ' - ' + besluit.Ons_Kenmerk : ''} - ${bedrijf.Naam}.docx`
        );
        modalState.success('other');
      });
    } catch (error) {
      const errorMessage = error instanceof Error && ErrorMessage.getLocalErrorMessage(error);

      if (errorMessage)
        modalState.error('other', {
          title: 'Mislukt',
          description: errorMessage,
        });
    }
  };

  return (
    <>
      <p className="mt-4 text-gray-800">
        Je staat op het punt om het document te exporteren als Word-document.
      </p>

      <form className="mt-4">
        <Checkbox
          name="include-historisch"
          label="Toon historische voorschriftnummers in export"
          id="include-historisch"
          checked={includeHistorisch}
          onChange={() => setIncludeHistorisch(!includeHistorisch)}
        />
      </form>

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton action="other" successLabel="Gedownload" onClick={exporteren}>
          Downloaden
        </Modal.ActionButton>
      </Modal.Footer>
    </>
  );
};
