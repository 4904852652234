import { useState } from 'react';
import { ConnectDragSource } from 'react-dnd';

import {
  LinkVoorschrift,
  Links,
  useHoofdstukId,
  useParagraafId,
  useVoorschriftId,
} from '@/api/queries/objects';
import { EditorAdd, LoaderContent } from '@/components';
import { MountWhenInView } from '@/components/shared/MountWhenInView';
import { WarningInline } from '@/components/shared/WarningInline/WarningInline';
import { getObjectState } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/getObjectState';
import { EditorMode } from '@/routes/$bedrijfLineageId.editor.$besluitId/-utils/useEditorMode';

import { VoorschriftAddBar } from './VoorschriftAddBar';
import { VoorschriftContext } from './VoorschriftContext';
import { VoorschriftInput } from './VoorschriftInput';
import { SidebarError, VoorschriftSidebar } from './VoorschriftSidebar';

interface VoorschriftProps {
  link: LinkVoorschrift;
  links: Links;
  userHasReservering: boolean;
  dragHandleRef: ConnectDragSource;
  editorMode: EditorMode;
  paragraafIndex: number;
  subparagraafIndex: number;
  voorschriftIndex: number;
}

export const Voorschrift = (props: VoorschriftProps) => {
  return (
    <MountWhenInView placeholder={<Skeleton />}>
      <VoorschriftInner {...props} />
    </MountWhenInView>
  );
};

const VoorschriftInner = ({
  link,
  links,
  userHasReservering,
  dragHandleRef,
  editorMode,
  paragraafIndex,
  subparagraafIndex,
  voorschriftIndex,
}: VoorschriftProps) => {
  const id = link.VSPB.Voorschrift;

  const voorschrift = useVoorschriftId(id);

  /**
   * Sidebar errors need to be stored outside of the sidebar because
   * the sidebar is unmounted/mounted when switching between voorschriften
   * When the sidebar is unmounted, the errors are lost.
   * By storing them outside of the sidebar, we can make sure the errors are still shown when switching between voorschriften
   */
  const [sidebarErrors, setSidebarErrors] = useState<SidebarError[]>([]);

  /**
   * Create voorschrift nummer
   */
  const hoofdstukNummer = useHoofdstukId(link.paragraaf.PHB.Hoofdstuk, {
    select: ({ Nummer }) => Nummer,
  });

  const paragraafNummer = useParagraafId(link.paragraaf.PHB.Paragraaf, {
    select: ({ Nummer }) => Nummer,
  });

  const nummer = [
    hoofdstukNummer.data || '-',
    paragraafNummer.data || '-',
    voorschrift.data?.Nummer,
  ].join('.');

  /**
   * Return loading state when voorschrift is loading or not available
   */
  if (
    hoofdstukNummer.isLoading ||
    paragraafNummer.isLoading ||
    voorschrift.isLoading ||
    !voorschrift.data?.ID
  ) {
    return <Skeleton />;
  }

  if (voorschrift.data?.Stop_Lineage && link.VSPB.Type === 'Publicatie') {
    return null;
  }

  /**
   * Check if voorschrift is editable
   */
  const objectState = getObjectState({
    object: voorschrift.data,
    linkType: link.VSPB.Type,
    userHasReservering,
  });

  return (
    <div data-testid={`voorschrift-${paragraafIndex}-${subparagraafIndex}-${voorschriftIndex}`}>
      <VoorschriftContext.Provider
        value={{
          link,
          links,
          nummer,
          objectState,
          voorschrift: voorschrift.data,
          userHasReservering,
          editorMode,
        }}
      >
        <VoorschriftInput dragHandleRef={dragHandleRef} hasError={!!sidebarErrors.length} />

        {sidebarErrors.map(({ request, message, tryAgain }) =>
          message ? (
            <WarningInline
              key={`${voorschrift.data.ID}-${request}`}
              description={message}
              tryAgain={tryAgain}
              center
            />
          ) : null
        )}

        {/* Note: make sure to always render VoorschriftSidebar. It keeps track of errors inside the sidebar while not rendered or opened. */}
        <VoorschriftSidebar
          errors={sidebarErrors}
          onSuccess={(request) => {
            setSidebarErrors((errors) => errors.filter((error) => error.request !== request));
          }}
          onError={(sidebarError) => {
            setSidebarErrors((errors) => [
              ...errors.filter((error) => error.request !== sidebarError.request),
              sidebarError,
            ]);
          }}
        />
      </VoorschriftContext.Provider>

      <VoorschriftAddBar
        subparagraafLink={link.subparagraaf}
        userHasReservering={userHasReservering}
        previous={link}
        next={link.subparagraaf.voorschriften[voorschriftIndex + 1]}
        paragraafIndex={paragraafIndex}
        subparagraafIndex={subparagraafIndex}
        voorschriftIndex={voorschriftIndex + 1}
      />
    </div>
  );
};

const Skeleton = () => (
  <>
    <div className="flex h-8 w-full items-center">
      <LoaderContent w="w-20" h="h-6" className="ml-[68px] mr-[14px] block" />
      <LoaderContent w="w-full" h="h-[28px]" className="block" />
    </div>

    <EditorAdd.Spacer />
  </>
);
