import { Link, createLink } from '@tanstack/react-router';
import { twJoin } from 'tailwind-merge';

interface ResultProps {
  title: string;
  description: string;
  number?: string | null;
}

export const Result = createLink(({ title, description, number, ...props }: ResultProps) => {
  return (
    <li className="group border-b first:border-t">
      <Link
        className={twJoin(
          'relative -mx-3 -mb-[1px] -mt-[1px] block rounded px-3 py-2',
          'transition-colors group-hover:bg-gray-100'
        )}
        {...props}
      >
        <p className="pb-1 font-medium">{title}</p>
        <p className="text-digi-v-gray-light">{description}</p>

        {number && <span className="absolute right-2 top-2">{number}</span>}
      </Link>
    </li>
  );
});
