import { ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { IconComponent } from '@/icons/iconTypes';

export interface BesluitCardHeadingProps {
  title: string;
  subtitle?: string;
  tag?: string;
  headingAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export interface BesluitCardProps {
  icon: IconComponent;
  dashedBorder?: boolean;
  testId?: string;
  className?: string;
  children?: ReactNode;
}

export const BesluitCard = ({
  dashedBorder,
  icon,
  testId,
  className,
  children,
}: BesluitCardProps) => {
  const IconComponent = icon;

  return (
    <article
      className={twMerge(
        'flex rounded-md border border-gray-300 bg-white px-5 py-4',
        dashedBorder && 'border-dashed',
        className
      )}
      data-testid={testId}
    >
      <div className="mt-1 flex shrink-0 grow-0 basis-9">
        <IconComponent size={16} data-testid={`${testId}-icon`} />
      </div>
      {children}
    </article>
  );
};

BesluitCard.Heading = function BesluitCardHeading({
  title,
  subtitle,
  tag,
  headingAs = 'h4',
}: BesluitCardHeadingProps) {
  const HeadingAs = headingAs;
  return (
    <>
      <header className="flex">
        <HeadingAs className="font-medium leading-5 text-gray-900">{title}</HeadingAs>

        {tag && (
          <span className="-mt-[2px] h-0">
            <span
              className={twJoin(
                'ml-2 rounded bg-theme-blue-light p-1 px-2 text-xs font-bold leading-5 text-theme-blue'
              )}
            >
              {tag}
            </span>
          </span>
        )}
      </header>

      {subtitle && <p className="mt-1 w-full text-sm leading-5 text-gray-500">{subtitle}</p>}
    </>
  );
};

BesluitCard.Description = function BesluitCardDescription({ children }: { children: ReactNode }) {
  return (
    <p className="mt-3 w-full rounded-md bg-gray-100 px-5 py-4 text-sm leading-5">{children}</p>
  );
};

BesluitCard.Body = function BesluitCardBody({ children }: { children: ReactNode }) {
  return <div className="flex w-full flex-col">{children}</div>;
};

BesluitCard.ButtonRow = function BesluitCardButtonRow({ children }: { children: ReactNode }) {
  return <div className="mt-3 flex w-full grow items-end gap-4">{children}</div>;
};

BesluitCard.Aside = function BesluitCardAside({ children }: { children: ReactNode }) {
  return (
    <aside className="flex shrink-0 grow items-end justify-end self-start pl-4">{children}</aside>
  );
};
