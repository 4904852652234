import { SubmitHandler, useForm } from 'react-hook-form';

import { File, Input, Modal, useModalState } from '@/components';
import { useResizeImage } from '@/utils';

import { modal } from '../utils/modal';

export interface EditorAddImageProps {
  onAddImage: (base64Image: string, alt: string) => void;
}

interface FormValues {
  image: FileList;
  alt: string;
}

export const EDITOR_ADD_IMAGE_ID = 'editorAddImage';

const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg'];

export const EditorAddImage = modal(EDITOR_ADD_IMAGE_ID, ({ data, props }) => {
  const { onAddImage } = data;

  const modalState = useModalState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { resize } = useResizeImage({
    maxWidth: 1240,
    maxHeight: 1752,
    quality: 75,
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ image, alt }) => {
    modalState.action('save');

    const resizedImage = image[0] && (await resize(image[0]));

    if (resizedImage) {
      onAddImage(resizedImage, alt);
      modalState.success('save');
    } else {
      modalState.error('save', {
        title: 'Mislukt!',
        description:
          'Er is iets misgegaan bij het uploaden van de afbeelding. Probeer het opnieuw.',
      });
    }
  };

  return (
    <Modal {...props} state={modalState.state} title="Voeg afbeelding toe">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="mb-4">
          Selecteer een afbeelding van je computer en geef een alt tekst op. De alt-tekst beschrijft
          wat er op de afbeelding te zien is.
        </p>

        <File
          {...register('image', {
            required: 'Dit veld is vereist',
            validate: (files) =>
              [...files].some((file) => !ACCEPTED_FILE_TYPES.includes(file.type))
                ? 'Alleen .jpg en .png zijn toegestaan'
                : true,
          })}
          label="Afbeelding"
          id="editor-add-image-file-input"
          accept={ACCEPTED_FILE_TYPES.join(',')}
          error={errors.image}
          placeholder="Selecteer een afbeelding"
          className="mb-4"
          required
        />

        <Input
          {...register('alt', { required: 'Dit veld is verplicht' })}
          label="Alt-tekst"
          placeholder="Bijvoorbeeld: Plattegrond van de inrichting van bedrijf"
          id="editor-add-image-alt-input"
          error={errors.alt}
          required
        />

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton action="save" type="submit" successLabel="toegevoegd">
            Toevoegen
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
