import { useMutation, useQueryClient } from '@tanstack/react-query';

import { putOthHoofdstukReserveringId } from '@/api/generated/digiVAPI';
import { HoofdstukReserveringSchema } from '@/api/generated/digiVAPI.schemas';
import { ErrorType } from '@/services/axios';

import { useHoofdstukReservering } from '../useHoofdstukReservering';

export function useExtendHoofdstukReservering() {
  const queryClient = useQueryClient();

  return useMutation<HoofdstukReserveringSchema, ErrorType<string>, string>({
    mutationFn: async (hoofdstukLineageId) => {
      /**
       * When extending a reservering, all reservering by the current user should be extended.
       * This is similar to the behavior before the datamodel was implemented.
       */
      const { objects: [hoofdstukReservering] = [] } = await queryClient.fetchQuery(
        useHoofdstukReservering.getOptions({
          page: 1,
          size: 1,
          filter: [
            {
              field: 'Reservering_lineage',
              op: 'eq',
              value: hoofdstukLineageId,
            },
          ],
        })
      );

      if (!hoofdstukReservering) {
        throw new Error('Geen hoofdstuk reservering gevonden met lineageId: ' + hoofdstukLineageId);
      }

      const responses = await putOthHoofdstukReserveringId(hoofdstukReservering.ID!, {
        ...hoofdstukReservering,
      });

      return responses;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useHoofdstukReservering.key });
    },
  });
}
