import { Link } from '@tanstack/react-router';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import { VoorschriftSchema } from '@/api';
import { useBesluit } from '@/api/queries/besluit';
import {
  useHistorischBesluitByLineageId,
  useHistorischBesluitLinkByLineageId,
} from '@/api/queries/historischBesluit';
import { useLabel } from '@/api/queries/label';
import { BesluitCard, LoaderSpinningIcon, TextButton, Warning } from '@/components';
import { RegularFileImport } from '@/icons/components';
import { BesluitCardVigerend } from '@/routes/$bedrijfLineageId.bedrijf/-components/BesluitCardVigerend';
import { DateUtils } from '@/utils/dateUtils';

import { MetaList } from '../MetaList';
import { ObjectsVoorschriftProps } from './ObjectsVoorschrift';

type ObjectsVoorschriftMetaProps = ObjectsVoorschriftProps & { voorschrift: VoorschriftSchema };

export const ObjectsVoorschriftMeta = ({
  voorschrift,
  link,
  hoofdstuk,
  bedrijf,
  mode,
}: ObjectsVoorschriftMetaProps) => {
  const labels = useLabel({
    page: 1,
    size: 100,
    filter: [
      {
        model: 'VoorschriftLabel',
        field: 'Voorschrift_lineage',
        value: voorschrift.Lineage,
        op: 'eq',
      },
    ],
    sort: [
      {
        model: 'Label',
        field: 'Label',
        direction: 'asc',
      },
    ],
  });

  /** Fetch besluit when mode is vigerend */
  const besluit = useBesluit(
    {
      page: 1,
      size: 1,
      filter: [
        {
          model: 'VSPB',
          field: 'Voorschrift',
          value: link.VSPB.Voorschrift,
          op: 'eq',
        },
        {
          model: 'VSPB',
          field: 'Type',
          value: 'Eigen',
          op: 'eq',
        },
      ],
    },
    { staleTime: Infinity, enabled: mode === 'vigerend', select: ({ objects }) => objects?.[0] }
  );

  /** Fetch historisch besluit */
  const historischBesluitLink = useHistorischBesluitLinkByLineageId({
    voorschriftLineageId: voorschrift.Lineage,
  });

  const historischBesluit = useHistorischBesluitByLineageId({
    voorschriftLineageId: voorschrift.Lineage,
  });

  if (
    labels.isLoading ||
    besluit.isLoading ||
    historischBesluit.isLoading ||
    historischBesluitLink.isLoading
  ) {
    return (
      <div className="flex h-[100px] items-center justify-center">
        <LoaderSpinningIcon />
      </div>
    );
  }

  return (
    <>
      <MetaList>
        <MetaList.Row label="Labels">
          {labels.data?.objects?.length ? (
            <p>{labels.data.objects.map(({ Label }) => Label).join(', ')}</p>
          ) : (
            <p>Geen labels</p>
          )}
        </MetaList.Row>

        {!historischBesluitLink.data && (
          <>
            <MetaList.Row label="Inhoudelijke overwegingen">
              <TextButton asChild>
                <Link
                  to="/$bedrijfLineageId/viewer/besluit/$besluitId/inhoudelijke-overwegingen/$hoofdstukId"
                  params={{
                    bedrijfLineageId: bedrijf.Lineage,
                    besluitId: link.VSPB.Besluit,
                    hoofdstukId: hoofdstuk.ID!,
                  }}
                  target="_blank"
                >
                  Bekijk in nieuwe tab
                </Link>
              </TextButton>
            </MetaList.Row>

            <MetaList.Row label="Inwerkingtreding">
              <p>
                {voorschrift.Begin_Geldigheid &&
                  format(new Date(voorschrift.Begin_Geldigheid), 'd MMMM yyyy', {
                    locale: nl,
                  })}
              </p>
            </MetaList.Row>
          </>
        )}

        {historischBesluitLink.data?.Origineel_voorschriftnummer && (
          <MetaList.Row label="Origineel nummer">
            <p>{historischBesluitLink.data.Origineel_voorschriftnummer}</p>
          </MetaList.Row>
        )}
      </MetaList>

      {mode === 'vigerend' && besluit.data ? (
        <div className="mt-4">
          <BesluitCardVigerend
            hoofdstukId={hoofdstuk.ID}
            bedrijf={bedrijf}
            besluit={besluit.data}
            headingAs="h3"
          />
        </div>
      ) : null}

      {historischBesluit.data && (
        <BesluitCard className="mt-4" icon={RegularFileImport}>
          <BesluitCard.Body>
            <BesluitCard.Heading
              title={historischBesluit.data.Besluit_Nummer}
              subtitle={
                historischBesluit.data.Besluit_Datum
                  ? DateUtils.formatReadable(new Date(historischBesluit.data.Besluit_Datum))
                  : undefined
              }
              headingAs="h3"
            />
            <BesluitCard.Description>
              {historischBesluit.data.Besluit_Omschrijving}
            </BesluitCard.Description>
          </BesluitCard.Body>
        </BesluitCard>
      )}

      {voorschrift.Voorlopige_Voorziening ? (
        <Warning
          size="small"
          variant="info"
          title="Dit voorschrift is een voorlopige voorziening"
          className="mt-4"
        />
      ) : null}

      {voorschrift.Beroep_Ingediend ? (
        <Warning
          size="small"
          variant="info"
          title="Voor dit voorschrift is een beroep ingediend"
          className="mt-2"
        />
      ) : null}
    </>
  );
};
