import { useDeleteSubparagraafId } from '@/api/queries/objects';
import { Modal, useModalState } from '@/components';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface UndoIntrekkenSubparagraafProps {
  title: string;
  id: string;
}

export const UNDO_INTREKKEN_SUBPARAGRAAF_ID = 'undoIntrekkenSubparagraaf';

export const UndoIntrekkenSubparagraaf = modal(
  UNDO_INTREKKEN_SUBPARAGRAAF_ID,
  ({ data, props }) => {
    const { title, id } = data;

    const deleteSubparagraafId = useDeleteSubparagraafId();

    const modalState = useModalState();

    const handleUndo = () => {
      modalState.action('save');

      deleteSubparagraafId.mutate(
        { id },
        {
          onSuccess: () => modalState.success('save'),
          onError: (error) =>
            modalState.error('save', {
              title: 'Mislukt',
              description: ErrorMessage.getLocalErrorMessage(error) ?? 'Er is iets misgegaan',
            }),
        }
      );
    };

    return (
      <Modal state={modalState.state} title="Intrekken ongedaan maken" {...props}>
        <p>
          <strong>
            Subparagraaf <span dangerouslySetInnerHTML={{ __html: title }} />
          </strong>{' '}
          is ingetrokken, wil je deze handeling ongedaan maken?
        </p>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="save"
            onClick={handleUndo}
            successLabel="Intrekken ongedaan gemaakt"
          >
            Intrekken ongedaan maken
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
