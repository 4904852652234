import { forwardRef } from 'react';
import { IconProps } from '../iconTypes';
const SVGIcon = forwardRef<SVGSVGElement, IconProps>(({
  size = 14,
  ...props
}, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" ref={svgRef} {...props}><path d="M6.1 18.9l-.2-.1.2.1zM7.3 7.6l-.7-1.7L4.5.8h-.2.2c0-.1-.2-.3-.3-.3-.1 0-.3-.1-.4-.1-.2 0-.3 0-.4.1-.1 0-.2.2-.3.3S1 5.9 1 5.9L.3 7.6v.6h.2-.2c0 .3.2.4.4.5h.5c.2 0 .3-.2.4-.4h-.2.2l.5-1.2h3.2l.5 1.2c0 .2.2.3.4.4h.5c.2 0 .3-.2.4-.4v-.5zM4.8 5.4H2.9l1-2.3 1 2.3zm1 9.8c.3-.4.4-.9.5-1.4h-.2.2c0-.7-.2-1.3-.7-1.7h-.1v.1l.1-.1c-.5-.5-1.1-.7-1.7-.7H2.2c-.4 0-.7.1-.9.4-.2.2-.4.6-.4.9h.2-.2v5.6c0 .4.1.7.4.9.2.2.6.4.9.4v-.2.2h2.3c.7 0 1.3-.2 1.7-.7h-.1c0-.1.1 0 .1 0 .5-.5.7-1.1.7-1.7h-.2.2c0-.4-.1-.9-.3-1.2-.2-.3-.4-.5-.7-.7zm-1.5 2.9h-2v-1.8h2c.3 0 .5.1.7.3s.3.4.3.6c0 .3-.1.5-.3.6s-.4.3-.7.3zm-.5-5.2c.3 0 .5.1.7.3s.3.4.3.6c0 .3-.1.5-.3.6s-.4.3-.7.3H2.3v-1.8h1.5zm6.7-7.7H19c.5 0 .7-.3.8-.8 0-.5-.3-.7-.8-.8h-8.5c-.5 0-.7.3-.8.8 0 .5.3.7.8.8zm8.5 8.5h-8.5c-.5 0-.7.3-.8.8 0 .5.3.7.8.8H19c.5 0 .7-.3.8-.8 0-.5-.3-.7-.8-.8zm0-5h-8.5c-.5 0-.7.3-.8.8 0 .5.3.7.8.8H19c.5 0 .7-.3.8-.8 0-.5-.3-.7-.8-.8z" /></svg>;
});
SVGIcon.displayName = "CustomAbc";
export default SVGIcon;