import { LabelSchema, LabelSchemaStatus } from '@/api';
import { usePatchLabelId } from '@/api/queries/label';
import { Modal, useModalState } from '@/components';
import { eStrings } from '@/constants/strings';
import { modal } from '@/modals/utils/modal';
import { useUserStore } from '@/stores/user';
import { ErrorMessage } from '@/utils/errorMessage';

export interface BeheerLabelStatusProps {
  newStatus: LabelSchemaStatus;
  label: LabelSchema;
}

export const BEHEER_LABEL_STATUS_ID = 'beheerLabelStatus';

export const BeheerLabelStatus = modal(BEHEER_LABEL_STATUS_ID, ({ data, props }) => {
  const { newStatus, label } = data;

  const userId = useUserStore((state) => state.user?.ID);

  const modalState = useModalState();

  const patchLabel = usePatchLabelId();

  const updateStatus = () => {
    const date = new Date().toISOString();

    modalState.action('save');

    patchLabel.mutate(
      {
        id: label.ID!,
        data: {
          Status: newStatus,
          Modified_By: userId,
          Modified_Date: date,
        },
      },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) => {
          const errorMessage = ErrorMessage.getLocalErrorMessage(error);

          if (errorMessage) {
            modalState.error('save', { title: 'Mislukt', description: errorMessage });
          }
        },
      }
    );
  };

  return (
    <Modal
      {...props}
      state={modalState.state}
      title={newStatus === 'Actief' ? 'Herstellen' : 'Archiveren'}
    >
      {newStatus === 'Actief' ? (
        <p>
          Je staat op het punt om het label <strong>{label.Label}</strong> te herstellen uit het
          archief. Dit onderdeel zal weer standaard zichtbaar worden voor gebruikers van{' '}
          {eStrings.LBL_DIGI_V}
        </p>
      ) : (
        <p>
          Je staat op het punt om het label <strong>{label.Label}</strong> te archiveren. Zolang dit
          onderdeel gearchiveerd is, zal het niet standaard zichtbaar zijn voor gebruikers van{' '}
          {eStrings.LBL_DIGI_V}
        </p>
      )}

      <Modal.Footer>
        <Modal.CancelButton />

        <Modal.ActionButton
          action="save"
          variant={newStatus === 'Actief' ? 'green' : 'red'}
          successLabel={newStatus === 'Actief' ? 'Herstelt' : 'Gearchiveerd'}
          onClick={updateStatus}
        >
          {newStatus === 'Actief' ? 'Herstellen' : 'Archiveren'}
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
});
