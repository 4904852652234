import { FormEvent, useEffect, useRef, useState } from 'react';

import { STDMapSchema } from '@/api/generated/digiVAPI.schemas';
import { Button, Modal, Wizard } from '@/components';
import { StdMapTreeNav } from '@/routes/$bedrijfLineageId.editor.$besluitId/-components/StdMapTreeNav';

import { modal } from '../../utils';
import { StdInhoudelijkeOverwegingenForm } from './components/StdInhoudelijkeOverwegingenForm';

export type StdInhoudelijkeOverwegingen = {
  onSubmit: (inhoudelijkeOverwegingen: string) => void;
};

export const STD_INHOUDELIJKE_OVERWEGINGEN = 'editorStdInhoudelijkeOverwegingen';
const FORM_ID = 'LRSO-form';

export const StdInhoudelijkeOverwegingen = modal(
  STD_INHOUDELIJKE_OVERWEGINGEN,
  ({ data, props }) => {
    const { onSubmit } = data;

    const formRef = useRef<HTMLFormElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    const [selectedMap, setSelectedMap] = useState<{ map: STDMapSchema; scrollTo?: string }>();
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
      if (!selectedMap?.scrollTo) return;
      const input = document.getElementById(selectedMap.scrollTo);

      input?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedMap?.scrollTo]);

    useEffect(() => {
      setCanSubmit(false);
    }, [selectedMap]);

    const toggleCheckboxes = (checked: boolean) => {
      if (!formRef.current) return;

      if (!checked) setCanSubmit(false);

      formRef.current.querySelectorAll('input').forEach((input) => {
        if (!input.disabled) {
          input.checked = checked;

          if (checked) setCanSubmit(true);
        }
      });
    };

    const getInputs = () => (formRef.current ? [...formRef.current.querySelectorAll('input')] : []);

    const handleSubmit = (e: FormEvent) => {
      e.preventDefault();

      if (!canSubmit) return;

      const checkedInputs = getInputs().filter((input) => input.checked);
      const inhoudelijkeOverwegingen = checkedInputs.map((input) => input.value).join('');

      onSubmit(inhoudelijkeOverwegingen);
      props.onClose();
    };

    const handleOnChange = () => {
      const checkedInputs = getInputs().filter((input) => input.checked);

      setCanSubmit(checkedInputs.length > 0);
    };

    return (
      <Wizard
        {...props}
        title="Standaardteksten invoegen"
        sidebarTitle="Overwegingen"
        placeholder="Navigeer via het menu aan de linkerkant naar het hoofdstuk waarvan je standaardteksten wilt invoegen.
    Selecteer daarna de onderderdelen om in te voegen."
        bodyTitle={selectedMap?.map.Naam}
        scrollContainerRef={scrollContainerRef}
        footer={
          <Modal.Footer className="mt-0">
            <Modal.CancelButton />
            <Button form={FORM_ID} type="submit" disabled={!canSubmit}>
              Invoegen
            </Button>
          </Modal.Footer>
        }
        sidebar={
          <StdMapTreeNav
            onClickMap={({ map }) => setSelectedMap({ map })}
            onClickSectie={({ map, sectie }) => setSelectedMap({ map, scrollTo: sectie.ID! })}
          />
        }
        onSelectAll={() => toggleCheckboxes(true)}
        onDeselectAll={() => toggleCheckboxes(false)}
      >
        {!selectedMap?.map.ID ? null : (
          <StdInhoudelijkeOverwegingenForm
            ref={formRef}
            mapId={selectedMap.map.ID}
            onSubmit={handleSubmit}
            onFormChange={handleOnChange}
            formId={FORM_ID}
          />
        )}
      </Wizard>
    );
  }
);
