import {
  getFixMetaOverwegingHoofdstuk,
  getFixMetaOverwegingHoofdstukId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useOverwegingHoofdstuk = createQuery({
  key: ['overwegingHoofdstuk'],
  queryFn: getFixMetaOverwegingHoofdstuk,
});

export const useOverwegingHoofdstukId = createQuery({
  key: ['overwegingHoofdstukId'],
  queryFn: getFixMetaOverwegingHoofdstukId,
});
