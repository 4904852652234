import { Disclosure } from '@headlessui/react';
import { ReactNode, memo } from 'react';

import { TextButton } from '@/components';
import { RegularSquareMinus, RegularSquarePlus } from '@/icons/components';

export interface TreeNavListProps {
  label: string | ReactNode;
  children: ReactNode;
  buttonId?: string;
  listTestId?: string;
  defaultOpen?: boolean;
  disabled?: boolean;
  onClick?: (open: boolean) => void;
  unmount?: boolean;
}

const TreeNavListImplementation = ({
  defaultOpen,
  label,
  children,
  buttonId,
  listTestId,
  disabled,
  onClick,
  unmount = true,
}: TreeNavListProps) => {
  return (
    <Disclosure as="li" defaultOpen={defaultOpen}>
      <Disclosure.Button as="div" className="TreeNav-Button">
        {({ open }) => {
          return (
            <TreeNavListButton
              onClick={() => onClick?.(open)}
              id={buttonId}
              label={label}
              open={open}
              disabled={disabled}
            />
          );
        }}
      </Disclosure.Button>
      <Disclosure.Panel
        unmount={unmount}
        as="ul"
        data-testid={listTestId}
        className="TreeNav-Panel block py-1 pl-3"
      >
        {children}
      </Disclosure.Panel>
    </Disclosure>
  );
};

const TreeNavListButton = ({
  open,
  label,
  id,
  onClick,
  disabled,
}: {
  open: boolean;
  label: string | ReactNode;
  id?: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <TextButton
      className="mb-1 flex w-full items-center justify-between"
      variant="black"
      underline={false}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      {label}

      {open ? <RegularSquareMinus size={14} /> : <RegularSquarePlus size={14} />}
    </TextButton>
  );
};

export const TreeNavList = memo(TreeNavListImplementation);
