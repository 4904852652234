import {
  getFixBedrijf,
  getFixBedrijfId,
  getFixLineageBedrijf,
  getFixLineageBedrijfId,
} from '@/api/generated/digiVAPI';
import { createQuery } from '@/utils/createQuery';

export const useBedrijf = createQuery({
  key: ['bedrijf'],
  queryFn: getFixBedrijf,
});

export const useBedrijfId = createQuery({
  key: ['bedrijfId'],
  queryFn: getFixBedrijfId,
});

export const useLineageBedrijf = createQuery({
  key: ['lineageBedrijf'],
  queryFn: getFixLineageBedrijf,
});

export const useLineageBedrijfId = createQuery({
  key: ['lineageBedrijfId'],
  queryFn: getFixLineageBedrijfId,
});
