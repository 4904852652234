import { ButtonHTMLAttributes } from 'react';
import { twJoin } from 'tailwind-merge';

import { IconComponent } from '@/icons/iconTypes';

export interface SquareButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className' | 'aria-label'> {
  label: string;
  icon: IconComponent;
  variant?: 'white' | 'theme-blue';
}

export const SquareButton = ({ label, icon, variant, disabled, ...props }: SquareButtonProps) => {
  const IconComponent = icon;

  return (
    <button
      {...props}
      className={twJoin(
        'flex h-10 w-10 items-center justify-center',
        'transition-colors duration-200 ease-out',
        !disabled && variant === 'white' && 'bg-white text-black hover:bg-gray-200',
        !disabled &&
          variant === 'theme-blue' &&
          'bg-theme-blue text-white hover:bg-theme-blue-dark',
        disabled && 'cursor-not-allowed bg-gray-200 text-black hover:bg-gray-200'
      )}
      aria-label={label}
      disabled={disabled}
    >
      <IconComponent size={16} />
    </button>
  );
};
