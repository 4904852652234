import { isAfter } from 'date-fns';
import { SubmitHandler, useForm } from 'react-hook-form';

import { VoorschriftSchema } from '@/api';
import { LinkVoorschrift, usePatchVoorschriftId } from '@/api/queries/objects';
import { Checkbox, Input, Modal, useModalState } from '@/components';
import { DateUtils } from '@/utils/dateUtils';
import { ErrorMessage } from '@/utils/errorMessage';

import { modal } from '../utils';

export interface VoorschriftGeldigheidProps {
  voorschrift: VoorschriftSchema;
  link: LinkVoorschrift;
}

interface FormValues {
  inwerking: boolean;
  inwerkingDate?: string;
  uitwerking: boolean;
  uitwerkingDate?: string;
}

export const VOORSCHRIFT_GELDIGHEID_ID = 'voorschriftGeldigheid';

export const VoorschriftGeldigheid = modal(VOORSCHRIFT_GELDIGHEID_ID, ({ data, props }) => {
  const { voorschrift, link } = data;

  const modalState = useModalState();
  const patchVoorschriftId = usePatchVoorschriftId();

  const isVoorschriftEigen = link.VSPB.Type === 'Eigen';

  const defaultValues = isVoorschriftEigen
    ? {
        inwerking: !!voorschrift.Begin_Geldigheid,
        inwerkingDate: voorschrift.Begin_Geldigheid
          ? DateUtils.formatForInput(new Date(voorschrift.Begin_Geldigheid))
          : undefined,
        uitwerking: !!voorschrift.Eind_Geldigheid,
        uitwerkingDate: voorschrift.Eind_Geldigheid
          ? DateUtils.formatForInput(new Date(voorschrift.Eind_Geldigheid))
          : undefined,
      }
    : {};

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  });

  const inwerkingCheckbox = watch('inwerking');
  const uitwerkingCheckbox = watch('uitwerking');

  const onSubmit: SubmitHandler<FormValues> = ({
    inwerking,
    inwerkingDate,
    uitwerking,
    uitwerkingDate,
  }) => {
    modalState.action('save');

    const inwerkingDateObject = inwerking && inwerkingDate ? new Date(inwerkingDate) : undefined;
    const uitwerkingDateObject =
      uitwerking && uitwerkingDate ? new Date(uitwerkingDate) : undefined;

    if (
      uitwerkingDateObject &&
      inwerkingDateObject &&
      !isAfter(uitwerkingDateObject, inwerkingDateObject)
    ) {
      modalState.error('save', {
        title: 'Foutmelding',
        description:
          "De 'inwerkingtreding' datum moet plaatsvinden vóór de 'uitwerkingtreding' datum",
      });
      return;
    }

    patchVoorschriftId.mutate(
      {
        id: voorschrift.ID!,
        data: {
          Begin_Geldigheid: inwerkingDateObject ? DateUtils.formatForBE(inwerkingDateObject) : null,
          Eind_Geldigheid: uitwerkingDateObject
            ? DateUtils.formatForBE(uitwerkingDateObject)
            : null,
        },
      },
      {
        onSuccess: () => modalState.success('save'),
        onError: (error) => {
          const errorMessage = ErrorMessage.getLocalErrorMessage(error);

          if (errorMessage) {
            modalState.error('save', {
              title: 'Foutmelding',
              description: errorMessage,
            });
          }
        },
      }
    );
  };

  return (
    <Modal {...props} state={modalState.state} title="Geldigheid aanpassen">
      <p className="mb-6">
        Deze optie maakt het mogelijk om een voorschrift op een later tijdstip (dan het besluit)
        inwerking te laten treden, of om een afwijkende uitwerkingtreding mee te geven.
      </p>

      <form data-testid="popup-geldigheid" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2 p-4 ring-1 ring-gray-200">
          <Checkbox
            {...register('inwerking')}
            id="popup-geldigheid-inwerking"
            label={
              <span>
                Dit voorschrift heeft een andere <strong>inwerkingtredingsdatum</strong> dan het
                besluit
              </span>
            }
          />

          {inwerkingCheckbox && (
            <div className="mt-2 flex">
              <Input
                {...register('inwerkingDate', {
                  required: 'Dit veld is vereist',
                })}
                type="date"
                label="Afwijkende inwerkingtredingsdatum"
                id="popup-geldigheid-inwerking-date"
                error={errors.inwerkingDate}
                required
              />
            </div>
          )}
        </div>

        <div className="p-4 ring-1 ring-gray-200">
          <Checkbox
            {...register('uitwerking')}
            id="popup-geldigheid-uitwerking"
            label={
              <span>
                Dit voorschrift heeft een <strong>uitwerkingtredingsdatum</strong>
              </span>
            }
          />

          {uitwerkingCheckbox && (
            <div className="mt-2 flex">
              <Input
                {...register('uitwerkingDate', {
                  required: 'Dit veld is vereist',
                })}
                type="date"
                label="Uitwerkingtredingsdatum"
                id="popup-geldigheid-uitwerking-date"
                error={errors.uitwerkingDate}
                required
              />
            </div>
          )}
        </div>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton type="submit" action="save" successLabel="Opgeslagen">
            Opslaan
          </Modal.ActionButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
