import { Modal, useModalState } from '@/components';
import { modal } from '@/modals/utils/modal';

export type StandaardtekstenDeactiverenMapProps = {
  mapNaam?: string;
};

export const STANDAARDTEKSTEN_DEACTIVEREN_MAP_ID = 'standaardtekstenDeactiverenMap';

export const StandaardtekstenDeactiverenMap = modal(
  STANDAARDTEKSTEN_DEACTIVEREN_MAP_ID,
  ({ data, props }) => {
    const modalState = useModalState();

    const { mapNaam } = data;

    const deactiveerMap = () => {
      console.log('map gedeactiveerd');
    };

    return (
      <Modal {...props} state={modalState.state} title="Map deactiveren">
        <p className="text-gray-800">
          Je staat op het punt om de map {mapNaam} te deactiveren. Zolang dit onderdeel
          gedeactiveerd is, zal het niet zichtbaar zijn voor vergunningverleners.
        </p>

        <Modal.Footer>
          <Modal.CancelButton />

          <Modal.ActionButton
            action="delete"
            successLabel="Gedeactiveerd"
            variant="green"
            onClick={deactiveerMap}
          >
            Map deactiveren
          </Modal.ActionButton>
        </Modal.Footer>
      </Modal>
    );
  }
);
