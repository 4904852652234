import { ReactNode } from 'react';

export const Body = ({ children }: { children: ReactNode }) => {
  return (
    <div className="hide-texteditor-highlights -mt-32">
      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-white shadow">
          <div className="flex">{children}</div>
        </div>
      </div>
    </div>
  );
};

Body.Sidebar = function Sidebar({ children }: { children: ReactNode }) {
  return (
    <aside
      aria-label="Besluit navigatie en filter"
      className="w-64 shrink-0 rounded-l-lg bg-gray-50 px-5 py-6"
    >
      {children}
    </aside>
  );
};

Body.Content = function Content({ children }: { children: ReactNode }) {
  return (
    <main className="flex w-full max-w-[calc(100%_-_16rem)] flex-col justify-start py-6 pl-10 pr-5">
      {children}
    </main>
  );
};
