import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

class DateUtilsSingleton {
  /**
   * Checks if the string is a valid date
   */
  isValid(date: string) {
    const parsedDate = Date.parse(date);
    return !isNaN(parsedDate);
  }

  /**
   * Formats a date into a readable date: 12 november 2023
   */
  formatReadable(date: Date) {
    return format(date, 'd MMMM yyyy', {
      locale: nl,
    });
  }

  /**
   * Formats a date for a date input: 2023-11-12, excluding the time
   */
  formatForInput(date: Date, includeTime = false) {
    return format(date, includeTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
  }

  /**
   * Formats a date for the BE
   */
  formatForBE(date: Date, includeTime = true) {
    return date.toISOString().slice(0, includeTime ? 19 : 10);
  }
}

export const DateUtils = new DateUtilsSingleton();
