import { useQueryClient } from '@tanstack/react-query';

import { usePatchFixMetaOverwegingHoofdstukId } from '@/api/generated/digiVAPI';

import { useOverwegingHoofdstuk, useOverwegingHoofdstukId } from '../overwegingHoofdstuk';

export function usePatchOverwegingHoofdstukId({ mutationKey }: { mutationKey?: string[] } = {}) {
  const queryClient = useQueryClient();

  return usePatchFixMetaOverwegingHoofdstukId({
    mutation: {
      mutationKey,
      onSuccess: (overwegingHoofdstuk) => {
        if (overwegingHoofdstuk.ID) {
          queryClient.setQueryData(
            useOverwegingHoofdstukId.getKey(overwegingHoofdstuk.ID),
            overwegingHoofdstuk
          );
        }

        return queryClient.invalidateQueries({ queryKey: useOverwegingHoofdstuk.key });
      },
    },
  });
}
