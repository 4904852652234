import { useQueryClient } from '@tanstack/react-query';

import { useDeleteDynLabelMapId } from '@/api/generated/digiVAPI';

import { useLabelMap, useLabelMapId } from '../label';

export function useDeleteLabelMapId() {
  const queryClient = useQueryClient();

  return useDeleteDynLabelMapId({
    mutation: {
      onSuccess: async (labelMap) => {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: useLabelMap.key }),
          queryClient.invalidateQueries({ queryKey: useLabelMapId.getKey(labelMap.ID!) }),
        ]);
      },
    },
  });
}
