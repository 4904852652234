import { create } from 'zustand';

import { STDMapSchema } from '@/api';

interface SelectedMappenState {
  map?: STDMapSchema;
  secties?: STDMapSchema[];
}

interface SelectedMappenActions {
  setSelectedMap: (map: STDMapSchema) => void;
  addSelectedSectie: (secties: STDMapSchema) => void;
  removeSelectedSectie: (secties: STDMapSchema) => void;
  reset: () => void;
}

const initialState: SelectedMappenState = {
  map: undefined,
  secties: undefined,
};

export const useSelectedMappen = create<SelectedMappenState & SelectedMappenActions>((set) => ({
  ...initialState,
  setSelectedMap: (map) => {
    return set({ map });
  },
  addSelectedSectie: (sectie) => {
    return set((state) => {
      return {
        secties: (state.secties ?? []).concat(sectie),
      };
    });
  },
  removeSelectedSectie: (sectie) => {
    return set((state) => {
      return {
        secties: state.secties?.filter(({ ID }) => sectie.ID !== ID),
      };
    });
  },
  reset: () => {
    return set({ ...initialState });
  },
}));
