import { useQueries } from '@tanstack/react-query';
import { isBefore } from 'date-fns';

import { useBesluit } from '@/api/queries/besluit';
import { LinkParagraaf, useHoofdstuk, useLinksInHoofdstuk, useVSPB } from '@/api/queries/objects';

export const useVigerendData = ({
  date,
  bedrijfLineageId,
}: {
  date: string;
  bedrijfLineageId: string;
}) => {
  /**
   * Get last besluit in range
   */
  const besluitQuery = useBesluit(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'Bedrijf',
          field: 'Lineage',
          op: 'eq',
          value: bedrijfLineageId,
        },
        {
          model: 'Besluit',
          field: 'Status',
          op: 'eq',
          value: 'Publicatie',
        },
        {
          model: 'Besluit',
          field: 'Begin_Geldigheid',
          op: 'le',
          value: date ?? '',
        },
      ],
      sort: [
        {
          model: 'Besluit',
          field: 'Besluit_Datum',
          direction: 'desc',
        },
      ],
    },
    {
      enabled: !!date,
    }
  );

  const besluitenInRange = besluitQuery.data?.objects?.filter((besluit) => {
    if (!besluit.Eind_Geldigheid) return true;

    return isBefore(new Date(date), new Date(besluit.Eind_Geldigheid));
  });

  const lastBesluitInRange = besluitenInRange?.[0];

  /**
   * Get all VSPB's that are inwerking at the given date
   */
  const EigenVSPB = useVSPB(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'VSPB',
          field: 'Besluit',
          op: 'eq',
          value: lastBesluitInRange?.ID ?? '',
        },
        {
          model: 'VSPB',
          field: 'Type',
          op: 'eq',
          value: 'Eigen',
        },
        {
          model: 'Voorschrift',
          field: 'Begin_Geldigheid',
          op: 'le',
          value: date,
        },
        {
          model: 'Voorschrift',
          field: 'Eind_Geldigheid',
          op: 'ge',
          value: date,
        },
      ],
    },
    {
      enabled: !!lastBesluitInRange?.ID,
    }
  );

  const PublicatieVSPB = useVSPB(
    {
      page: 1,
      size: 100,
      filter: [
        {
          model: 'VSPB',
          field: 'Besluit',
          op: 'eq',
          value: lastBesluitInRange?.ID ?? '',
        },
        {
          model: 'VSPB',
          field: 'Type',
          op: 'eq',
          value: 'Publicatie',
        },
        {
          model: 'Voorschrift',
          field: 'Volgend',
          op: 'is_null',
          value: '',
        },
        {
          model: 'Voorschrift',
          field: 'Begin_Geldigheid',
          op: 'le',
          value: date,
        },
        {
          model: 'Voorschrift',
          field: 'Eind_Geldigheid',
          op: 'ge',
          value: date,
        },
      ],
    },
    {
      enabled: !!lastBesluitInRange?.ID,
    }
  );

  const VSPBIds = [
    ...(EigenVSPB.data?.objects?.map((VSPB) => VSPB.ID) ?? []),
    ...(PublicatieVSPB.data?.objects?.map((VSPB) => VSPB.ID) ?? []),
  ];

  /**
   * Get all hoofdstukken in the last besluit
   */
  const hoofdstukkenQuery = useHoofdstuk(
    {
      page: 1,
      size: 100,
      sort: [{ field: 'Nummer', model: 'Hoofdstuk', direction: 'asc' }],
      filter: [
        {
          model: 'Besluit',
          field: 'ID',
          op: 'eq',
          value: lastBesluitInRange?.ID ?? '',
        },
      ],
    },
    {
      enabled: !!lastBesluitInRange?.ID,
      select: ({ objects }) => objects ?? [],
    }
  );

  /**
   * Get all links for each hoofdstuk
   */
  const linksQuery = useQueries({
    queries:
      hoofdstukkenQuery.data?.map((hoofdstuk) =>
        useLinksInHoofdstuk.getOptions({
          besluitId: lastBesluitInRange?.ID ?? '',
          hoofdstukId: hoofdstuk.ID ?? '',
        })
      ) ?? [],
  });

  /**
   * Return loading state
   */
  if (
    besluitQuery.isLoading ||
    EigenVSPB.isLoading ||
    PublicatieVSPB.isLoading ||
    hoofdstukkenQuery.isLoading ||
    linksQuery.some((query) => query.isLoading)
  ) {
    return {
      isLoading: true,
    };
  }

  /**
   * Construct filtered data
   **/
  const filteredLinks = linksQuery
    .map(({ data }) => data)
    .flat()
    .filter((paragraafLink): paragraafLink is LinkParagraaf => {
      if (!paragraafLink) return false;

      paragraafLink.subparagrafen = paragraafLink.subparagrafen
        ?.map((subparagraaf) => {
          subparagraaf.voorschriften = subparagraaf.voorschriften?.filter((voorschrift) => {
            if (!voorschrift.VSPB.ID) return false;

            return VSPBIds.includes(voorschrift.VSPB.ID);
          });

          return subparagraaf;
        })
        .filter((subparagraaf) => !!subparagraaf.voorschriften.length);

      return !!paragraafLink.subparagrafen.length;
    });

  const vigerendData = hoofdstukkenQuery.data
    ?.map((hoofdstuk) => {
      const paragraafLinks = filteredLinks.filter(
        (paragraafLink) => paragraafLink.PHB.Hoofdstuk === hoofdstuk.ID
      );

      return {
        hoofdstuk,
        links: paragraafLinks,
      };
    })
    .filter((hoofdstuk) => hoofdstuk.links.length);

  return {
    isLoading: false,
    data: vigerendData,
  };
};
