import { Fragment } from 'react';

import { BedrijfSchema, HBSchema, HoofdstukSchema } from '@/api';
import { LinkParagraaf } from '@/api/queries/objects';
import { EditorHoofdstuk } from '@/components';

import { ObjectsParagraaf } from './ObjectsParagraaf';
import { getObjectStatus } from './utils/getObjectState';

interface ObjectsProps {
  HB?: HBSchema;
  hoofdstuk: HoofdstukSchema;
  bedrijf: BedrijfSchema;
  links: LinkParagraaf[];
  mode: Mode;
}

export type Mode = 'besluit' | 'vigerend';

export const Objects = ({ hoofdstuk, HB, bedrijf, mode, links }: ObjectsProps) => {
  return (
    <>
      <EditorHoofdstuk
        number={`${hoofdstuk.Nummer}`}
        content={hoofdstuk.Titel}
        testId={`hoofdstuk-${hoofdstuk.Nummer}`}
        status={mode === 'besluit' ? getObjectStatus(hoofdstuk, HB?.Type === 'Eigen') : undefined}
        className="mb-2"
      />

      {links.map((linkParagraaf) => (
        <Fragment key={`${mode}-paragraaf-${linkParagraaf.PHB.Paragraaf}`}>
          <ObjectsParagraaf
            hoofdstuk={hoofdstuk}
            bedrijf={bedrijf}
            link={linkParagraaf}
            mode={mode}
          />
        </Fragment>
      ))}
    </>
  );
};
