import { useDebouncedCallback } from '@react-hookz/web';
import { createFileRoute } from '@tanstack/react-router';

import { useBesluitId, usePatchBesluitId } from '@/api/queries/besluit';
import { useOpenModal } from '@/modals/utils';
import { ErrorMessage } from '@/utils/errorMessage';

import { AUTOSAVE_MUTATION_KEY } from './-components/EditorNavigation';
import { ContentTemplate } from './-template/ContentTemplate';

/**
 * Route
 */
export const Route = createFileRoute('/$bedrijfLineageId/editor/$besluitId/besluittekst')({
  loader: async ({ context: { queryClient }, params: { besluitId } }) => {
    await queryClient.ensureQueryData(useBesluitId.getOptions(besluitId));
  },
  component: BesluittekstComponent,
});

/**
 * Route component
 */
function BesluittekstComponent() {
  const { besluitId } = Route.useParams();

  const openModal = useOpenModal();

  const besluit = useBesluitId(besluitId);
  const patchBesluit = usePatchBesluitId({ mutationKey: AUTOSAVE_MUTATION_KEY });

  const onUpdateDebounced = useDebouncedCallback(
    (value: string) => {
      patchBesluit.mutate(
        {
          id: besluitId,
          data: {
            Besluit_Tekst: value,
          },
        },
        {
          onError: (error) => {
            const errorMessage = ErrorMessage.getLocalErrorMessage(error);

            if (errorMessage) {
              openModal('saveFailed', {
                tryAgain: () =>
                  patchBesluit.mutateAsync({
                    id: besluitId,
                    data: {
                      Besluit_Tekst: value,
                    },
                  }),
              });
            }
          },
        }
      );
    },
    [patchBesluit, besluitId],
    300
  );

  if (!besluit.data) {
    return null;
  }

  return (
    <ContentTemplate
      id="page-besluittekst-editor"
      title="Besluittekst"
      defaultValue={besluit.data.Besluit_Tekst ?? ''}
      onUpdate={onUpdateDebounced}
    />
  );
}
