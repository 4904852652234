import { ReactNode, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { RegularAngleRight } from '@/icons/components';

export interface TreeProps {
  /** Depth of the tree */
  depth?: number;
  /** Button label */
  label: string;
  /** When true, tree is opened initially */
  defaultIsOpen?: boolean;
  children: ReactNode;
  unmount?: boolean;
}

const DEFAULT_PADDING = 10;
const LEVEL_PADDING = 25;

const getPadding = (depth: number) => `${LEVEL_PADDING * depth + DEFAULT_PADDING}px`;

/**
 * A collapsable component that can be nested like a tree
 */
export const Tree = ({ depth = 0, label, defaultIsOpen = false, children, unmount }: TreeProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  return (
    <>
      <button
        className="flex w-full items-center border-b py-2 text-left"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        style={{ paddingLeft: getPadding(depth) }}
      >
        <RegularAngleRight
          className={twJoin(
            'mr-4 transition duration-100 ease-in',
            isOpen ? 'rotate-90' : 'rotate-0'
          )}
        />

        {label}
      </button>

      {unmount ? (
        <>{isOpen && children}</>
      ) : (
        <div className={`${isOpen ? 'block' : 'hidden'}`}>{children}</div>
      )}
    </>
  );
};

/**
 * A component for displaying a component inside of a tree with the proper padding.
 */
export const TreeItem = ({
  depth = 0,
  children,
}: {
  /** Depth of the tree */
  depth: number;
  children: ReactNode;
}) => (
  <div className={`flex w-full border-b py-2`} style={{ paddingLeft: getPadding(depth) }}>
    {children}
  </div>
);
